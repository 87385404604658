import { IShopCooktop } from "api/types/shop_quote/cooktop";

export enum ActionType {
  CREATE_LOCAL_SHOP_COOKTOP = "CREATE_LOCAL_SHOP_COOKTOP",
  UPDATE_LOCAL_SHOP_COOKTOP = "UPDATE_LOCAL_SHOP_COOKTOP",
  UPDATE_AND_MOVE_SHOP_COOKTOP = "UPDATE_AND_MOVE_SHOP_COOKTOP",
  DELETE_LOCAL_SHOP_COOKTOP = "DELETE_LOCAL_SHOP_COOKTOP",
}

interface ICreateLocalShopCooktop {
  type: ActionType.CREATE_LOCAL_SHOP_COOKTOP,
  payload: IShopCooktop
}

interface IUpdateLocalShopCooktop {
  type: ActionType.UPDATE_LOCAL_SHOP_COOKTOP,
  payload: IShopCooktop
}

interface IUpdateAndMoveShopCooktop {
  type: ActionType.UPDATE_AND_MOVE_SHOP_COOKTOP,
  old_counter_uuid: string
  payload: IShopCooktop,
}

interface IDeleteLocalShopCooktop {
  type: ActionType.DELETE_LOCAL_SHOP_COOKTOP,
  payload: IShopCooktop
}

export type Action =
  ICreateLocalShopCooktop |
  IUpdateLocalShopCooktop |
  IUpdateAndMoveShopCooktop |
  IDeleteLocalShopCooktop;
