/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// STYLES ///
import { row_header_style } from "styles/universal/row";
import { row_text_item_style } from "styles/universal/row";

export default function MarketPlHeader() {
  return (
    <Fragment>
      <Grid item xs={5} sx={row_header_style}>
        <Typography variant="body2" sx={row_text_item_style}>
          <b>Name</b>
        </Typography>
      </Grid>
      <Grid item xs={5} sx={row_header_style}>
        <Typography variant="body2" sx={row_text_item_style}>
          <b>Region</b>
        </Typography>
      </Grid>
      <Grid item xs={2} sx={row_header_style}>
        <Typography variant="body2" sx={row_text_item_style}>
          <b>Active</b>
        </Typography>
      </Grid>
    </Fragment>
  )
}
