/// HOOKS ///
import useQuoteTotals from "hooks/quote/totals";
/// TYPES ///
import { Order } from "api/types/order";
import { OrderArea } from "api/types/order";
import { SelfOrder } from "api/types/order";
import { SelfOrderArea } from "api/types/order";
import { QuoteTotals } from "api/types/quote";
/// REACT FRAGMENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import QuoteSummaryHeader from "../quote/summary_header";
import QuoteSummaryRow from "../quote/summary_row";
/// LODASH ///
import { filter } from "lodash";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";

interface Props {
  order: Order | SelfOrder,
  areas: OrderArea[] | SelfOrderArea[],
}

export default function OrderSummary({ order, areas }: Props) {
  const totals: QuoteTotals = useQuoteTotals(order);

  return (
    <Fragment>
      <Grid item xs={12} sx={{ marginTop: "20px" }}>
        <Typography variant="h6" sx={item_style(false)}>
          Summary
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      {
        areas.map(area =>
          <Fragment key={area.id}>
            <QuoteSummaryHeader name={area?.name ?? ""} />
            {
              filter(area.line_items, { "customer_line_item": true }).map(item =>
                <QuoteSummaryRow key={item.id} item={item} />
              )
            }
          </Fragment>
        )
      }
      {
        order?.addons?.length > 0 ?
          <Fragment>
            <Grid item xs={12}>
              <Typography variant="body2" sx={item_style()}>
                <b>Addons</b>
              </Typography>
            </Grid>
            {
              order.addons.map(addon => {
                return (
                  <Fragment>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={item_style()}>
                        {addon?.name ?? ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" align="right" sx={item_style()}>
                        ${addon?.price ?? 0}
                      </Typography>

                    </Grid>
                  </Fragment>
                )
              })
            }
          </Fragment> :
          null
      }
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>Subtotal</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${totals.subtotal.toFixed(2)}
        </Typography>
      </Grid>
      {
        order?.discount && order?.total ?
          <Fragment>
            <Grid item xs={8}>
              <Typography variant="body2" align="right" sx={item_style()}>
                <b>Discount</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" align="right" sx={item_style()}>
                ${totals.discount.toFixed(2)}
              </Typography>
            </Grid>
          </Fragment> : null
      }
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>{order?.tax_name ?? "Tax"}</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${totals.tax1.toFixed(2)}
        </Typography>
      </Grid>
      {
        order?.second_tax ?
          <Fragment>
            <Grid item xs={8}>
              <Typography variant="body2" align="right" sx={item_style()}>
                <b>{order?.second_tax_name ?? "Tax2"}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" align="right" sx={item_style()}>
                ${totals.tax2.toFixed(2)}
              </Typography>
            </Grid>
          </Fragment> : null
      }
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>Total</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${(totals.subtotal + totals.tax1 + totals.tax2 - totals.discount).toFixed(2)}
        </Typography>
      </Grid>
    </Fragment>
  )
}

