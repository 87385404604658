/// PDF ///
import { pdf } from "@react-pdf/renderer";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { emailQuote } from "api/actions/quotes/quote";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoadingPage from "components/universal/loading_page";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import QuotePrintPreviewBody from "components/quote/print/print_preview";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";
/// VALIDATE ///
import { validateEmail } from "functions/value";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void
}

export default function StoreEmailQuote({ open, setOpen }: Props) {
  const dispatch = useAppDispatch();
  const {
    areas,
    areas_loaded,
    quote,
    quote_loaded
  } = useAppSelector((state) => state.quote);
  const {
    counters,
    counters_loaded,
    labels
  } = useAppSelector((state) => state.sketch);
  const { user } = useAppSelector((state) => state.authentication);
  const [logo, setLogo] = useState<string | null>("");
  const [email, setEmail] = useState<string | null>(null);
  const [error, setError] = useState<string>("");
  const [logo_read, setLogoRead] = useState<boolean>(true);

  useEffect(() => {
    if (user?.logo && typeof (user?.logo) === "string") {
      fetch(user?.logo).then(response => {
        return response.blob();
      }).then(blob => {
        const blob_url: string = URL.createObjectURL(blob);
        setLogo(blob_url);
        setLogoRead(true);
      }).catch(() => {
        setLogoRead(true);
      })
    }
    else {
      setLogoRead(true);
    }
  }, [user?.logo]);

  const sendQuote = () => {
    let send_to_email: string = "";

    if (email) {
      if (validateEmail(email)) {
        send_to_email = email;
      }
      else {
        setError("Please enter a valid email");
        return;
      }
    }
    else {
      send_to_email = quote.customer_data.email;
    }

    const element = <QuotePrintPreviewBody areas={areas} counters={counters} labels={labels} quote={quote} user={user} logo={logo} />;
    const myPdf = pdf(element);
    myPdf.updateContainer(element);

    dispatch(emailQuote(quote, myPdf, send_to_email));
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Email Quote</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <LoadingPage loading={!logo_read || !areas_loaded || !quote_loaded || !counters_loaded}>
          <Stack>
            {
              !quote || !quote.customer ?
                <Typography variant="body2" color="error">
                  Must enter a customer for this quote before sending an email
                </Typography> :
                <Fragment>
                  <Typography variant="body2">
                    <b>Send to this customer?</b>
                  </Typography>
                  <Typography variant="body2">
                    {`${quote.customer_data.first_name} ${quote.customer_data.last_name}`}
                  </Typography>
                  <TextField
                    helperText={error}
                    error={error !== ""}
                    defaultValue={quote.customer_data.email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email" />
                </Fragment>
            }
          </Stack>
        </LoadingPage>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} size="small">Cancel</Button>
        <Button onClick={() => sendQuote()} disabled={!logo_read} size="small">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}
