export enum ActionType {
  SKETCH_ERROR = "SKETCH_ERROR",
  COUNTERS_ERROR = "COUNTERS_ERROR",
  SHAPES_ERROR = "SHAPES_ERROR"
}

interface ISketchError {
  type: ActionType.SKETCH_ERROR,
  payload: string
}

interface ICountersError {
  type: ActionType.COUNTERS_ERROR,
  payload: string
}

interface IShapesError {
  type: ActionType.SHAPES_ERROR,
  payload: string
}

export type Action = ISketchError | ICountersError | IShapesError;
