/// TYPES ///
import { Counter } from "api/types/sketch";
import { OrderCounter } from "api/types/order";
import { SelfOrderCounter } from "api/types/order";
import { IShopCounter } from "api/types/shop_quote/counter";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { adjustVectorToScale } from "../draw/scale";

export function getCounterByUuid(uuid: string, counters: Counter[]): Counter | null {
  for (let i = 0; i < counters.length; i++) {
    if (counters[i].uuid === uuid) {
      return counters[i];
    }
  }
  return null;
}

export function getShopCounterByUuid(uuid: string, counters: IShopCounter[]): IShopCounter | null {
  for (let i = 0; i < counters.length; i++) {
    if (counters[i].uuid === uuid) {
      return counters[i];
    }
  }
  return null;
}

export function getCenterOfCounter(counter: Counter | OrderCounter | SelfOrderCounter | IShopCounter, zoom: number = 1): Vector2 {
  let X: number = 0;
  let Y: number = 0;

  for (let i = 0; i < counter.corners.length; i++) {
    const adjusted_vector: Vector2 = adjustVectorToScale(counter.corners[i].location, zoom);
    X += adjusted_vector.X;
    Y += adjusted_vector.Y;
  }
  return { X: X / counter.corners.length, Y: Y / counter.corners.length };
}
