/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopPoint } from "api/types/shop_quote/point";

export function updateShopWidth(
  counter: IShopCounter,
  point_uuid: string,
  new_width: number
): IShopCounter {
  let points: IShopPoint[] = [...counter.points];
  for (let j = 0; j < points.length; j++) {
    if (point_uuid === points[j].uuid) {
      const point: IShopPoint = { ...points[j] };
      point.width = new_width * 4;
      points[j] = point;
      if (j === points.length - 1 && points[j - 1]) {
        const second_last_point: IShopPoint = { ...points[j - 1] };
        second_last_point.width = new_width * 4;
        points[j - 1] = second_last_point;
      }
      if (j === 0 && points.length === 2) {
        const next_point: IShopPoint = { ...points[j + 1] };
        next_point.width = new_width * 4;
        points[j + 1] = next_point;
      }
      break;
    }
  }
  return { ...counter, points: points };
}
