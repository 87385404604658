import axios from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/fabricator";
import { ActionType } from "api/action_types/fabricator";
import { AxiosError } from "axios";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { url_prefix } from "settings";
import { FabricatorStoneCountertopRemoval } from "api/types/fabricator";
import { FabricatorLaminateCountertopRemoval } from "api/types/fabricator";

const fabricator_stone_countertop_removal_url: string = "fabricator_stone_countertop_removal";
const fabricator_laminate_countertop_removal_url: string = "fabricator_laminate_countertop_removal";

export const getFabricatorStoneCountertopRemovals = (fabricator_price_list: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATOR_PRICE_LIST_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${fabricator_stone_countertop_removal_url}/?fabricator_price_list=${fabricator_price_list}&`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_FABRICATOR_STONE_COUNTERTOP_REMOVALS_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const updateFabricatorStoneCountertopRemoval = (
  fabricator_stone_countertop_removal: FabricatorStoneCountertopRemoval,
  id: number
) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATOR_PRICE_LIST_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${fabricator_stone_countertop_removal_url}/${id}/`;

    try {
      const { data } = await axios.patch(url, fabricator_stone_countertop_removal, config);

      dispatch({
        type: ActionType.UPDATE_FABRICATOR_STONE_COUNTERTOP_REMOVAL_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const getFabricatorLaminateCountertopRemovals = (fabricator_price_list: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATOR_PRICE_LIST_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${fabricator_laminate_countertop_removal_url}/?fabricator_price_list=${fabricator_price_list}&`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_FABRICATOR_LAMINATE_COUNTERTOP_REMOVALS_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const updateFabricatorLaminateCountertopRemoval = (
  fabricator_laminate_countertop_removal: FabricatorLaminateCountertopRemoval,
  id: number
) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATOR_PRICE_LIST_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${fabricator_laminate_countertop_removal_url}/${id}/`;

    try {
      const { data } = await axios.patch(url, fabricator_laminate_countertop_removal, config);

      dispatch({
        type: ActionType.UPDATE_FABRICATOR_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
