import { SelfOrderCounter } from "api/types/order/self_order";

export enum ActionType {
  COUNTERS_LOADING = "SELF_ORDER_COUNTERS_LOADING",
  GET_SELF_ORDER_COUNTERS_SUCCESS = "GET_SELF_ORDER_COUNTERS_SUCCESS"
}

interface ISelfOrderCountersLoading {
  type: ActionType.COUNTERS_LOADING
}

interface IGetSelfOrderCountersSuccess {
  type: ActionType.GET_SELF_ORDER_COUNTERS_SUCCESS,
  payload: SelfOrderCounter[]
}

export type Action = ISelfOrderCountersLoading | IGetSelfOrderCountersSuccess;
