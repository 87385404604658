/// TYPES ///
import { Counter } from "api/types/sketch";
import { Seam } from "api/types/sketch";
/// MUI ///
import Grid from "@mui/material/Grid";
import Typography from "@mui//material/Typography";
import Stack from "@mui/material/Stack";
/// ICONS ///
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  selected_counter: Counter | null,
  setSelectedCounter: (selected_counter: Counter | null) => void
}

export default function StoreSeamDrawer({ selected_counter, setSelectedCounter }: Props) {
  function getSeams(counter: Counter): Seam[] {
    const seams: Seam[] = [];
    for (let i = 0; i < counter.points.length; i++) {
      for (let j = 0; j < counter.points[i].seams.length; j++) {
        seams.push(counter.points[i].seams[j]);
      }
    }
    return seams;
  }

  return (
    <Stack sx={{ paddingLeft: 1 }}>
      <Typography variant="h4">Seams</Typography>
      {
        selected_counter ?
          getSeams(selected_counter).map((seam, index) => {
            return (
              <Stack direction="row" justifyContent="space-between" alignItems="center" key={index}>
                <Typography variant="body1">Seam #{index + 1} </Typography>
                <IconButton
                  onClick={() => setSelectedCounter({
                    ...selected_counter, points:
                      [...selected_counter.points.map(point => point.uuid === seam.point_uuid ?
                        { ...point, seams: [...point.seams.filter(s => s.uuid !== seam.uuid)] } :
                        point
                      )]
                  })}>
                  <DeleteIcon sx={{ width: 20, height: 20, marginBottom: 0.5 }} />
                </IconButton>
              </Stack>
            )
          }) :
          <Grid item xs={12}></Grid>
      }
    </Stack>
  );
}
