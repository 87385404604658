/// EVENTS ///
import { KeyboardEvent } from "react";
import { MouseEvent } from "react";
/// HOOKS  ///
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
/// MUI ///
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
/// ICONS ///
import CloseIcon from "@mui/icons-material/Close";
/// STYLES ///
import { drawerGridContainer } from "styles/drawer";
import { drawerGridSaveButtonAreaStyle } from "styles/drawer";
import { drawerIconBarStyle } from "styles/drawer";
import { drawerTitleStyle } from "styles/drawer";

interface Props {
  open: boolean,
  error: string,
  setOpen: (open: boolean) => void,
  save: (name: string) => void,
  close: () => void
}

export default function CreateArea({ open, error, setOpen, save, close }: Props) {
  const [name, setName] = useState<string>("");
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref !== null && ref.current !== null) {
        ref.current.focus();
        ref.current.select();
      }

    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  const toggleDrawer = (event: KeyboardEvent | MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  const keyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      save(name);
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      close();
    }
  }

  return (
    <SwipeableDrawer
      BackdropProps={{ invisible: true }}
      disableAutoFocus
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      onKeyDown={keyDown}
      anchor="right"
      open={open}>
      <Grid container sx={drawerGridContainer}>
        <Grid item xs={12} sx={drawerIconBarStyle} justifyContent="end">
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={drawerTitleStyle}>
          <Typography variant="h4" align="center">
            Create Area
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              inputRef={ref}
              fullWidth
              error={error !== ""}
              helperText={error}
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="Name" />
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={drawerGridSaveButtonAreaStyle} justifyContent="center">
          <Button onClick={() => save(name)}>
            Save
          </Button>
        </Grid>
      </Grid>
    </SwipeableDrawer>

  )
}
