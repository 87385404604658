import { Dispatch } from "redux";
import { Action } from "api/action_types/order";
import { ActionType } from "api/action_types/order";

export const resetMarketOrderSuccess = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_ORDER_SUCCESS
    });
  }
}
