/// TYPES ///
import { Counter } from "api/types/sketch";
import { Point } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { getAngle } from "../math";

export function reverseCounter(counter: Counter): Counter {
  const corners: Corner[] = [...counter.corners].reverse();

  const middle_index: number = corners.length / 2;
  const left_corners: Corner[] = [];
  const right_corners: Corner[] = [];
  for (let i = 0; i < corners.length; i++) {
    if (i < middle_index) {
      left_corners.push(corners[i]);
    }
    else {
      right_corners.push(corners[i]);
    }
  }

  const reversed_corners: Corner[] = [...left_corners.reverse(), ...right_corners.reverse()];
  const points_copy: Point[] = [...counter.points];
  const reversed_points: Point[] = [...points_copy.reverse()];

  const points: Point[] = [];
  for (let i = 0; i < reversed_points.length; i++) {
    const new_point: Point = { ...reversed_points[i] };

    if (i === reversed_points.length - 1) {
      points.push(new_point);
      continue;
    }
    const current_location: Vector2 = new_point.location;
    const next_location: Vector2 = reversed_points[i + 1].location;
    const angle: number = getAngle(current_location, next_location) + 90;
    points.push({ ...new_point, angle: angle });
  }

  return { ...counter, corners: reversed_corners, points: points };
}
