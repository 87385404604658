/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
/// MUI COMPONENTS ///
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from "@mui/icons-material/Logout";
/// STYLES ///
import { mini_logout_btn_style } from "styles/drawer";
import { mini_ico_style } from "styles/drawer";

interface Props {
  setOpen: (open: boolean) => void
}

export default function MiniLogout({ setOpen }: Props) {
  const { user } = useAppSelector((state) => state.authentication);

  if (!user.id) {
    return null;
  }

  return (
    <ListItemButton onClick={() => setOpen(true)} sx={mini_logout_btn_style}>
      <LogoutIcon sx={mini_ico_style(false)} />
    </ListItemButton>
  );
}
