import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { createStripeSession } from "api/actions/billing";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingPage from "components/universal/loading_page";
import { miniDrawerWidth } from "styles/drawer";

export default function PaymentForm() {
  const dispatch = useAppDispatch();
  const [load, setLoad] = useState<boolean>(false);
  const { customer } = useAppSelector((state) => state.billing);
  const { price } = useAppSelector((state) => state.billing);
  const { session } = useAppSelector((state) => state.billing);

  const handleCreateSession = () => {
    setLoad(true);
    if (customer !== "" && price !== "") {
      dispatch(createStripeSession(customer, price));
    }
  }

  if (session && session.session_url) {
    window.location.href = session.session_url;
  }

  return (
    <Box sx={{ padding: `calc(15% + ${miniDrawerWidth}px)`, paddingTop: "10%", paddingBottom: "10%" }}>
      <LoadingPage loading={load}>
        <Stack>
          <Typography variant="body1">
            <b>Payment</b>
          </Typography>
          <Typography variant="body2" sx={{ paddingTop: 2, paddingBottom: 4.5 }}>
            Proceed to our secure payment portal to complete your subscription.
            You will not be charged for 1 month during the free trial period.
            After completing the sign up, fabricators will be contacted in the next 24-72 hours by the contact information on your profile.
            This is to ensure only valid fabricator business are able to to receive orders from merchants.
            Quoting will still be available, but the marketplace will not send fabricators orders until the account is verified.
          </Typography>
          {
            !customer ?
              <Stack direction="row" sx={{ width: "100%", justifyContent: "center" }} alignItems="center" spacing={6}>
                <Typography variant="body2" color="primary">
                  <b>Loading</b>
                </Typography>
                <CircularProgress size={24} />
              </Stack> :
              <Button onClick={handleCreateSession}>
                Proceed to Payment
              </Button>
          }
        </Stack>
      </LoadingPage>
    </Box>
  );
}
