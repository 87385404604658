/// TYPES ///
import { Color } from "api/types/material";
import { Material } from "api/types/material";
/// ACTIONS ///
import { Action } from "api/action_types/material/fabricator/market";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/material/fabricator/market";

interface State {
  materials: Material[],
  material: Material,
  colors: Color[],
  loading: boolean,
  error: string | null
}

const initialState = {
  materials: [],
  material: {},
  colors: [],
  loading: false,
  error: null
}

export const marketMaterialReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_MARKET_MATERIALS:
      return {
        ...state,
        materials: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.GET_MARKET_MATERIAL:
      return {
        ...state,
        material: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.GET_FABRICATOR_COLORS:
      return {
        ...state,
        colors: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_LOCAL_MARKET_MATERIAL:
      return {
        ...state,
        materials: [...state.materials.map(mat => mat.id === action.material.id ? { ...mat, ...action.material } : mat)]
      }
    case ActionType.UPDATE_LOCAL_MARKET_COLOR:
      return {
        ...state,
        colors: [...state.colors.map(col => col.id === action.color.id ? { ...col, ...action.color } : col)]
      }
    case ActionType.CLEAR_MARKET_MATERIALS:
      return {
        ...state,
        materials: [],
      }
    case ActionType.MARKET_MATERIAL_LOADING:
      return {
        ...state,
        loading: true
      }
    case ActionType.MARKET_MATERIAL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}



