/// HOOKS //
import { useEffect } from "react";
import { useState } from "react";
/// MUI ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  original_discount: number,
  error: string,
  has_error: boolean,
  setOpen: (open: boolean) => void,
  update: (discount: number) => void
}

export default function SetDiscount({ open, original_discount, error, has_error, setOpen, update }: Props) {
  const [discount, setDiscount] = useState<number>(0);

  useEffect(() => {
    if (open) {
      setDiscount(original_discount);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Apply Discount</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        {
          open ?
            <TextField
              fullWidth
              type="number"
              error={has_error}
              helperText={error}
              size="small"
              id="discount"
              label="Discount"
              value={discount}
              onChange={(e) => setDiscount(Number(e.target.value))} /> :
            null
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={() => update(discount)}>Apply</Button>
      </DialogActions>
    </Dialog>
  )
}
