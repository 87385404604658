import { FabricatorQuote } from "api/types/market";
import { Action } from "api/action_types/market";
import { ActionType } from "api/action_types/market";

interface State {
  quotes: FabricatorQuote[],
  quote: FabricatorQuote,
  loading: boolean,
  updating: boolean,
  open_confirm_order: boolean,
  error: string
}

const initialState = {
  quotes: [],
  quote: {},
  loading: false,
  updating: false,
  open_confirm_order: false,
  error: ""
}

export const marketReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_FABRICATOR_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.GET_FABRICATOR_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_QUOTE_SUCCESS:
      return {
        ...state,
        updating: false,
        quotes: action.payload,
        error: ""
      }
    case ActionType.CREATE_FABRICATOR_QUOTE_SUCCESS:
      return {
        ...state,
        updating: false,
        error: "",
        quotes: action.payload
      }
    case ActionType.OPEN_CONFIRM_ORDER:
      return {
        ...state,
        quote: action.payload,
        open_confirm_order: true
      }
    case ActionType.CLOSE_CONFIRM_ORDER:
      return {
        ...state,
        open_confirm_order: false
      }
    case ActionType.FABRICATOR_QUOTE_LOADING:
      return {
        ...state,
        loading: true
      }
    case ActionType.FABRICATOR_QUOTE_UPDATING:
      return {
        ...state,
        updating: true
      }
    case ActionType.FABRICATOR_QUOTE_ERROR:
      return {
        ...state,
        updating: false,
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}



