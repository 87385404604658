import axios from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/feedback";
import { ActionType } from "api/action_types/feedback";
import { AxiosError } from "axios";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { Feedback } from "api/types/feedback";
import { url_prefix } from "settings";

const feedback_url: string = "feedback";

export const createFeedback = (feedback: Feedback) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FEEDBACK_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${feedback_url}/`;

    try {
      const { data } = await axios.post(url, { ...feedback, reported_by: user.id }, config);

      dispatch({
        type: ActionType.CREATE_FEEDBACK_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FEEDBACK_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

