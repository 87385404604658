import { SelfOrder } from "api/types/order";
import { IGetOrderArgs } from "api/types/order/order";

export enum ActionType {
  GET_SELF_ORDERS_SUCCESS = "GET_SELF_ORDERS_SUCCESS",
  GET_SELF_ORDER_SUCCESS = "GET_SELF_ORDER_SUCCESS",
  CREATE_SELF_ORDER_SUCCESS = "CREATE_SELF_ORDER_SUCCESS",
  RESET_SELF_ORDER_SUCCESS = "RESET_SELF_ORDER_SUCCESS",
  FILTER_SELF_ORDER = "FILTER_SELF_ORDER"
}

interface IGetSelfOrdersSuccess {
  type: ActionType.GET_SELF_ORDERS_SUCCESS,
  payload: SelfOrder[]
}

interface IGetSelfOrderSuccess {
  type: ActionType.GET_SELF_ORDER_SUCCESS,
  payload: SelfOrder
}

interface ICreateSelfOrderSuccess {
  type: ActionType.CREATE_SELF_ORDER_SUCCESS,
  payload: SelfOrder
}

interface IResetSelfOrderSuccess {
  type: ActionType.RESET_SELF_ORDER_SUCCESS
}

interface IFilterSelfOrder {
  type: ActionType.FILTER_SELF_ORDER,
  filter: IGetOrderArgs
}

export type Action = IGetSelfOrdersSuccess | IGetSelfOrderSuccess | ICreateSelfOrderSuccess | IResetSelfOrderSuccess | IFilterSelfOrder;
