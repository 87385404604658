/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { filterWOs } from "api/actions/wo/wo";
import { getWOs } from "api/actions/wo/wo";
import { setBreadcrumbs } from "api/actions/ui/set";
/// EVENTS ///
import { ChangeEvent } from "react";
/// TYPES ///
import { Order } from "api/types/order";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import { Grid } from "@mui/material";
import { Pagination } from "@mui/material";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import List from "components/universal/list";
import PaginationSelector from "components/universal/ui/pagination_selector";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// CUSTOMER COMPONENTS ///
import WOsHeader from "./header";
import WOsItem from "./row";
// FA COMPONENTS ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { row_header_style } from "styles/universal/row";
import { title_style } from "styles/quote_preview/section";
/// VALUES ///
import { default_pagination_length } from "styles/table";
/// LODASH ///
import { filter } from "lodash";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { filter_url } from "components/navigation/endpoints";
import { wo_url } from "components/navigation/endpoints";
/// VALIDATE ///
import { JSONIsEmpty } from "functions/value";
/// BREADCRUMBS ///
import { wo_list } from "values/breadcrumbs/wo";

export default function WOs() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    filter: wo_filter,
    orders
  } = useAppSelector((state) => state.order);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [filt_wos, setFiltWos] = useState<Order[]>([]);
  const [num_per_page, setNumPerPage] = useState<number>(default_pagination_length);

  useEffect(() => {
    setFiltWos(filter(orders, function(o: Order) {
      if (o.name.toLowerCase().includes(search.toLowerCase()) ||
        o.order_number.toLowerCase().includes(search.toLowerCase())
      ) {
        return o;
      }
    }) as Order[]);
  }, [orders, search]);

  useEffect(() => {
    dispatch(getWOs(wo_filter));
  }, [dispatch, wo_filter]);

  useEffect(() => {
    dispatch(setBreadcrumbs([wo_list]));
  }, [dispatch]);


  const changePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const searchKeyDown = (e: any) => {
    if (e.key === "Escape") {
      e.preventDefault();
      setSearch("");
    }
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title="Work Orders" endProps={
          <Box display="flex" alignItems="center" flexDirection="row">
            {!JSONIsEmpty(wo_filter) ? <BaseButton text="Clear" icon={faEraser} clickEvent={() => dispatch(filterWOs({}))} /> : null}
            <BaseButton text="Filter" icon={faFilter} clickEvent={() => navigate(`/${wo_url}/${filter_url}`)} />
          </Box>
        } />
        < Grid container item xs={12} >
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6} sx={row_header_style}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <Typography variant="h6" sx={title_style}>
                {filt_wos.length} results
              </Typography>
              {
                orders.length === 2000 ?
                  <Typography variant="body2" sx={{ marginLeft: "5px" }} color="error">
                    Limit of 2000 reached. Use filter to narrow down results.
                  </Typography> :
                  null
              }
            </Box>
          </Grid>
          <Grid item xs={6} sx={row_header_style}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <TextField
                label="Search Within Results"
                onKeyDown={searchKeyDown}
                size="small"
                value={search}
                sx={{ height: "30px", marginRight: "20px" }}
                InputProps={{
                  style: { height: "30px", },
                  endAdornment: <InputAdornment position="end">
                    {

                      search.length > 0 ?
                        <IconButton onClick={() => setSearch("")}>
                          <FontAwesomeIcon icon={faXmark} size="xs" />
                        </IconButton> :
                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xs" />
                    }
                  </InputAdornment>
                }}
                onChange={e => setSearch(e.target.value)} />
            </Box>
          </Grid>
          <WOsHeader />
          <List
            Item={WOsItem}
            items={filt_wos.slice((page - 1) * num_per_page, page * num_per_page)} />
        </Grid >
        <Grid item xs={1}></Grid>
        <Grid item xs={10} sx={{ marginTop: 3 }}>
          <Box display="flex" justifyContent="center">
            <Pagination
              onChange={changePage}
              count={
                orders.length > num_per_page ?
                  Math.ceil(orders.length / num_per_page) :
                  1
              } shape="rounded" />
          </Box>
        </Grid>
        <Grid item xs={1} sx={{ marginTop: 3 }}>
          <PaginationSelector num_per_page={num_per_page} setNumPerPage={(setNumPerPage)} />
        </Grid>
      </Grid>
    </motion.div>
  )
}


