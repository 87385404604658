/// TYPES ///
import { CSSProperties } from "react";
// FM ///
import { TargetAndTransition } from "framer-motion";
/// STYLES ///
import { dark_grey_color } from "styles/style";
import { grey_color } from "styles/style";

export const tile_btn_style: CSSProperties = {
  border: "none",
  backgroundColor: "var(--mui-palette-background-paper)",
  color: dark_grey_color,
  borderTopWidth: 0,
  borderBottomWidth: 0,
  borderRightWidth: 0,
  borderStyle: "solid",
  borderLeftWidth: "2px",
  borderColor: grey_color,
  paddingTop: "0px",
  paddingBottom: "0px",
  marginTop: "10px",
  marginBottom: "10px",
  marginLeft: "7px"
}

export const tile_ico_style: CSSProperties = {
  height: "21px",
  width: "21px"
}

export const tile_animate_style: TargetAndTransition = {
  color: "var(--mui-palette-primary-main)",
  borderLeftWidth: "15px",
  borderColor: "var(--mui-palette-primary-main)",
  transition: {
    ease: "easeIn",
    duration: 0.2,
    type: "tween"
  }
}

export const tile_text_style: CSSProperties = {
  fontSize: "0.9rem",
  textTransform: "uppercase"
}
