/// TYPES ///
import { CutShape } from "api/types/sketch";
import { CutCorner } from "api/types/sketch";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { adjustVectorToScale } from "../draw/scale";
/// VALUES ///
import { SLAB_ZOOM } from "values/values";

export function getCenterOfCutShape(cut_shape: CutShape): Vector2 {
  let X: number = 0;
  let Y: number = 0;

  for (let i = 0; i < cut_shape.cut_corners.length; i++) {
    X += cut_shape.cut_corners[i].location.X;
    Y += cut_shape.cut_corners[i].location.Y;
  }

  return { X: Math.round(X / cut_shape.cut_corners.length), Y: Math.round(Y / cut_shape.cut_corners.length) };
}

export function checkCutShapeClicked(
  cut_shapes: CutShape[],
  mouse: Vector2
): CutShape | null {
  for (let i = 0; i < cut_shapes.length; i++) {
    if (pointInCutShape(mouse, cut_shapes[i].cut_corners)) {
      return cut_shapes[i];
    }
  }
  return null;
}

export function checkShopCutShapeClicked(
  cut_shapes: IShopCutShape[],
  mouse: Vector2
): IShopCutShape | null {
  for (let i = 0; i < cut_shapes.length; i++) {
    if (pointInCutShape(mouse, cut_shapes[i].cut_corners)) {
      return cut_shapes[i];
    }
  }
  return null;
}

function pointInCutShape(point: Vector2, cut_corners: CutCorner[]) {
  var inside = false;
  for (let i = 0, j = cut_corners.length - 1; i < cut_corners.length; j = i++) {
    const loc_i: Vector2 = adjustVectorToScale(
      {
        X: cut_corners[i].location.X,
        Y: cut_corners[i].location.Y
      },
      SLAB_ZOOM
    );
    const loc_j: Vector2 = adjustVectorToScale(
      {
        X: cut_corners[j].location.X,
        Y: cut_corners[j].location.Y
      },
      SLAB_ZOOM
    );

    const xi = loc_i.X, yi = loc_i.Y;
    const xj = loc_j.X, yj = loc_j.Y;
    const intersect = ((yi > point.Y) !== (yj > point.Y))
      && (point.X < (xj - xi) * (point.Y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }
  return inside;
}
