import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { StepInterface } from "values/steps";

interface Props {
    current_step: number,
    steps: StepInterface[]
}

export default function Steppers({current_step, steps}: Props) {
    return (
        <Stepper orientation="vertical" activeStep={current_step}>
            {steps.map(step => (
                <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                        <Typography variant="body2">
                            {step.description}
                        </Typography>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
}