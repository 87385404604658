import { Vector2 } from "api/types/sketch";
import { Text } from "api/types/sketch";
import { ANGLE } from "values/text";

export function degreeText(
    end_vertex: Vector2, 
    angle: number,
    point_uuid: string,
    counter_uuid: string
): Text {
    const text: string = "\u00B0";
    return {
        location: end_vertex,
        value: angle,
        real_value: angle,
        text: text,
        corner_uuid: "",
        point_uuid: point_uuid,
        counter_uuid: counter_uuid,
        type: ANGLE,
        angle: angle,
        mirror: false
    };
}