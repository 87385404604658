/// REACT-ROUTER ///
import { BrowserRouter } from "react-router-dom";
/// COMPONENTS ///
import NavBar from "components/navigation/navbar";
import MiniDrawer from "components/drawer";
import MainRoutes from "./routes";
import ScrollBar from "./scroll";
import Breadcrumbs from "./breadcrumbs";

export default function MainRouter() {
  return (
    <BrowserRouter>
      <NavBar />
      <MiniDrawer />
      <ScrollBar />
      <MainRoutes />
      <Breadcrumbs />
    </BrowserRouter >
  )
}
