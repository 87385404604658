/// HOOKS ///
import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
/// LODASH ///
import { debounce } from "lodash";

export default function useAddressPredictions(input) {
  const autocomplete = useRef();
  const [predictions, setPredictions] = useState([]);

  if (autocomplete && !autocomplete.current) {
    autocomplete.current = new window.google.maps.places.AutocompleteService();
  }

  function getPlacePredictions(input) {
    autocomplete.current.getPlacePredictions(
      {
        input,
        componentRestrictions: { country: "ca" },
        types: ["geocode"]
      },
      predictions => {
        setPredictions(predictions);
      }
    );
  }

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    []
  );

  useEffect(() => {
    debouncedGetPlacePredictions(input);
  }, [input, debouncedGetPlacePredictions]);

  return predictions;
}
