/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
/// URLS ///
import { url_prefix } from "settings";
import { shop_area_url } from "../../endpoints";

export const updateShopArea = (area: IShopArea, id?: number | string, local: boolean = true) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.SHOP_QUOTE_UPDATING
    });

    if (local) {
      dispatch({
        type: ActionType.UPDATE_LOCAL_SHOP_AREA,
        payload: area
      });

    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${shop_area_url}/${id ? id : 0}/?uuid=${area.uuid}&`;

    try {
      const { data } = await axios.patch(url, area, config);

      dispatch({
        type: ActionType.SHOP_QUOTE_ACTION_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SHOP_QUOTE_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
