import { CornerData } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { drawSecondHalfCutRadiusCorner } from "./radius_corner";
import { drawSecondHalfCutInsideRadiusCorner } from "./inside_radius_corner";
import { drawSecondHalfCutNotchedCorner } from "./notched_corner";
import { drawSecondHalfCutClippedCorner } from "./clipped_corner";
import { drawSecondHalfCutDiagonalCorner } from "./diagonal_corner";
import { drawSecondHalfCutBumpoutCorner } from "./bumpout_corner";
import { drawSecondHalfCutRecessedDiagonalCorner } from "./recessed_diagonal_corner";
import { drawCutStandardCorner } from "../cut_corner";
import { RADIUS } from "values/quote";
import { INNER_RADIUS } from "values/quote";
import { NOTCH } from "values/quote";
import { BUMPOUT } from "values/quote";
import { CLIP } from "values/quote";
import { RECESSED_DIAGONAL } from "values/quote";
import { DIAGONAL } from "values/quote";

export function drawSecondHalfCutCorner(
    i: number,
    path: Path2D,
    corner_type: string,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    zoom: number,
    radius: number,
    length: number,
    depth: number
): CornerData | null {
    let corner_data: CornerData | null = null;
    if(corner_type === RADIUS){
        corner_data = drawSecondHalfCutRadiusCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            radius,
            zoom
        );
    }
    else if(corner_type === INNER_RADIUS){
        corner_data = drawSecondHalfCutInsideRadiusCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            radius,
            zoom
        );
    }
    else if(corner_type === NOTCH){
        corner_data = drawSecondHalfCutNotchedCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else if(corner_type === CLIP){
        corner_data = drawSecondHalfCutClippedCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            zoom
        );
    }
    else if(corner_type === DIAGONAL){
        corner_data = drawSecondHalfCutDiagonalCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            zoom
        );
    }
    else if(corner_type === BUMPOUT){
        corner_data = drawSecondHalfCutBumpoutCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else if(corner_type === RECESSED_DIAGONAL){
        corner_data = drawSecondHalfCutRecessedDiagonalCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else{
        corner_data = drawCutStandardCorner(
            path,
            i,
            current_location
        )
    }
    return corner_data;
}
