import { IShopArea } from "api/types/shop_quote/area";

export enum ActionType {
  GET_SHOP_AREAS = "GET_SHOP_AREAS",
  CREATE_SHOP_AREA = "CREATE_SHOP_AREA",
  CREATE_LOCAL_SHOP_AREA = "CREATE_LOCAL_SHOP_AREA",
  UPDATE_LOCAL_SHOP_AREA = "UPDATE_LOCAL_SHOP_AREA",
  DELETE_LOCAL_SHOP_AREA = "DELETE_LOCAL_SHOP_AREA"
}

interface IGetShopAreasSuccess {
  type: ActionType.GET_SHOP_AREAS,
  payload: IShopArea[]
}

interface ICreateShopArea {
  type: ActionType.CREATE_SHOP_AREA,
  payload: IShopArea
}

interface ICreateLocalShopArea {
  type: ActionType.CREATE_LOCAL_SHOP_AREA,
  payload: IShopArea
}

interface IUpdateLocalShopArea {
  type: ActionType.UPDATE_LOCAL_SHOP_AREA,
  payload: IShopArea
}

interface IDeleteLocalShopArea {
  type: ActionType.DELETE_LOCAL_SHOP_AREA,
  payload: string
}

export type Action = IGetShopAreasSuccess | ICreateShopArea | ICreateLocalShopArea | IUpdateLocalShopArea | IDeleteLocalShopArea;
