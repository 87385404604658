/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getQuote } from "api/actions/quotes/quote";
import { updateLocalQuote } from "api/actions/quotes/quote";
import { updateQuote } from "api/actions/quotes/quote";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { QuoteErrors } from "api/types/quote";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
/// PAGE COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { preview_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_preview } from "values/breadcrumbs/quote";
import { quote_update } from "values/breadcrumbs/quote";

interface Props {
  base_url: string
}

export default function UpdateQuote({ base_url }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { quote } = useAppSelector((state) => state.quote);
  const { id } = useParams();
  const [errors, setErrors] = useState<QuoteErrors>({});

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_list.url.replace("base_url", base_url) },
      { ...quote_preview, url: quote_preview.url.replace("base_url", base_url).replace("id", id) },
      { ...quote_update, url: quote_update.url.replace("base_url", base_url).replace("id", id) }
    ]));
  }, [dispatch, id, base_url]);

  useEffect(() => {
    if (!quote?.id || quote.id.toString() !== id) {
      dispatch(getQuote(id));
    }
  }, [id, dispatch, quote]);

  const update = () => {
    if (quote?.name === "") {
      setErrors({ name_error: "name must not be blank", name_has_error: true });
      return;
    }

    dispatch(updateQuote({ name: quote.name, estimate_number: quote.estimate_number }, id, false));
    navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${preview_url}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Update Quote" size_lg={6} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${preview_url}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={update} text="Update" icon={faSave} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} lg={6}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <TextField
              value={quote?.name ?? ""}
              onChange={e => dispatch(updateLocalQuote({ ...quote, name: e.target.value }))}
              error={errors.name_has_error}
              helperText={errors.name_error}
              label="Quote Name"
              size="small" />
            <TextField
              value={quote?.estimate_number ?? ""}
              onChange={e => dispatch(updateLocalQuote({ ...quote, estimate_number: e.target.value }))}
              label="Quote Number"
              size="small" />
          </Box>
        </Grid>
      </Grid>
    </motion.div >
  )
}
