/// TYPES ///
import { Area } from "api/types/quote";
import { CutShape } from "api/types/sketch";
import { IShopArea } from "api/types/shop_quote/area";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// UNI COMPONENTS ///
import DiagramSlab from "./slab";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";

interface Props {
  areas: Area[] | IShopArea[],
  cut_shapes: CutShape[] | IShopCutShape[],
  shapes_loaded: boolean,
}

export default function DiagramShapeAreas({ areas, cut_shapes, shapes_loaded }: Props) {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={title_style}>
          Slabs
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      {areas.map(area => {
        return (
          <Grid key={area.id} container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="body2" color="primary" sx={item_style(false)}>
                <b>{area.name}:</b> {area?.material_name ?? ""} {area?.color_name ?? ""} {area?.material_thickness ?? ""}
              </Typography>
            </Grid>
            {area.slabs.map((slab, index) => {
              return (
                <DiagramSlab index={index} key={index} area={area} shapes_loaded={shapes_loaded} slab={slab} cut_shapes={cut_shapes.filter(cut_shape => cut_shape.area_uuid === area.uuid)} />
              )
            })
            }
          </Grid>
        )
      })}
    </Grid>
  )
}
