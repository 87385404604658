/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useState } from "react";
/// TYPES ///
import { IFabricationJob } from "api/types/customer";
import { ICustomer } from "api/types/customer";
import { IShopQuote } from "api/types/shop_quote/quote";
/// ACTIONS ///
import { createFabricationJob } from "api/actions/end_customer";
import { updateFabricationJob } from "api/actions/end_customer";
import { updateShopQuote } from "api/actions/shop_quote";
/// COMPONENTS ///
import QuoteDetail from "components/quote/tabs/quote_details/info";

interface Props {
  quote: IShopQuote,
}

export default function ShopQuoteInfo({ quote }: Props) {
  const dispatch = useAppDispatch();
  const [stone_open, setStoneOpen] = useState<boolean>(false);
  const [laminate_open, setLaminateOpen] = useState<boolean>(false);

  const chooseCustomer = (customer: ICustomer) => {
    if (customer?.id) {
      dispatch(updateShopQuote({ customer: customer.id }, quote.id, false));
    }
    else {
      dispatch(updateShopQuote({ customer: null }, quote.id, false));
    }
  }

  const saveJob = (job: IFabricationJob) => {
    if (quote?.job_data) {
      dispatch(updateFabricationJob({ ...job }, job.id));
    }
    else {
      dispatch(createFabricationJob({ ...job, end_customer: quote.customer }, null, quote.id));
    }
  }

  const saveStoneRemoval = (amount: number) => {
    dispatch(updateShopQuote({ stone_removal_sq_ft: amount, changed: true }, quote.id, false));
    setStoneOpen(false);
  }

  const saveLaminateRemoval = (amount: number) => {
    dispatch(updateShopQuote({ laminate_removal_sq_ft: amount, changed: true }, quote.id, false));
    setLaminateOpen(false);
  }

  return (
    <QuoteDetail
      customer={quote?.customer_data}
      job={quote?.job_data}
      stone_open={stone_open}
      setStoneOpen={setStoneOpen}
      laminate_open={laminate_open}
      setLaminateOpen={setLaminateOpen}
      material_area={quote?.material_area ?? 0}
      stone_removal_sq_ft={quote?.stone_removal_sq_ft ?? 0}
      laminate_removal_sq_ft={quote?.laminate_removal_sq_ft ?? 0}
      distance={quote?.distance ?? 0}
      fitted={quote?.fitted ?? false}
      saveStoneRemoval={saveStoneRemoval}
      saveLaminateRemoval={saveLaminateRemoval}
      saveJob={saveJob}
      chooseCustomer={chooseCustomer} />
  )
}
