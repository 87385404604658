/// HOOKS ///
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import useDrawDiagramOrderCounters from "hooks/draw/diagram_order_counters";
/// TYPES ///
import { OrderCounter } from "api/types/order/order";
import { SelfOrderCounter } from "api/types/order/self_order";
import { Vector2 } from "api/types/sketch";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
/// VALUE FUNCTIONS ///
import { getCanvas } from "functions/sketch/context";
import { getDiagramDimensions } from "functions/sketch/get/diagram";
/// STYLES ///
import { counter_container_style } from "styles/quote_preview/sketch";

interface Props {
  area_uuid: string,
  counters: OrderCounter[] | SelfOrderCounter[],
  counters_loaded: boolean
}

export default function DiagramOrderCounters({ area_uuid, counters, counters_loaded }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [load_drawing, setLoadDrawing] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState<Vector2>({ X: 0, Y: 0 });
  const [drawing_dimensions, setDrawingDimensions] = useState<Vector2>({ X: 0, Y: 0 });
  const [have_drawing_dimensions, setHaveDrawingDimensions] = useState<boolean>(false);

  useDrawDiagramOrderCounters(area_uuid, counters, dimensions, counters_loaded && load_drawing && have_drawing_dimensions);

  useEffect(() => {
    setDrawingDimensions(getDiagramDimensions(counters.filter(counter => counter.area_uuid === area_uuid)));
    setHaveDrawingDimensions(true);
  }, [counters, area_uuid]);

  useEffect(() => {
    if (ref && ref.current && have_drawing_dimensions) {
      const height: number = ref.current.clientHeight;
      const width: number = ref.current.clientWidth;
      const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById(`diagram_ac_${area_uuid}`));

      if (canvas) {
        canvas.width = width;
        canvas.height = height;
      }
      setDimensions({ X: width, Y: height });
      setLoadDrawing(true);
    }
  }, [ref, counters.length, area_uuid, have_drawing_dimensions]);

  if (!counters || !(counters.length > 0)) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Box sx={counter_container_style(drawing_dimensions.Y)} ref={ref}>
        <canvas id={`diagram_ac_${area_uuid}`} />
      </Box>
    </Grid>
  )

}
