/// TYPES ///
import { Counter } from "api/types/sketch";
import { OrderCounter } from "api/types/order/order";
import { SelfOrderCounter } from "api/types/order/self_order";
import { Vector2 } from "api/types/sketch";

export function getOptimalZoom(counter: Counter | OrderCounter | SelfOrderCounter, canvas_dimensions: Vector2): number {
  let width: number = 0;
  let height: number = 0;
  let top: Vector2 | null = null;
  let bottom: Vector2 | null = null;
  let left: Vector2 | null = null;
  let right: Vector2 | null = null;

  for (let i = 0; i < counter.corners.length; i++) {
    const corner: Vector2 = counter.corners[i].location;
    if (!top || corner.Y < top.Y) {
      top = corner;
    }
    if (!bottom || corner.Y > bottom.Y) {
      bottom = corner;
    }
    if (!left || corner.X < left.X) {
      left = corner;
    }
    if (!right || corner.X > right.X) {
      right = corner;
    }
  }

  if (top && bottom && left && right) {
    width = right.X - left.X;
    height = bottom.Y - top.Y;
  }

  const object_ratio: number = width / height;
  const canvas_ratio: number = canvas_dimensions.X / canvas_dimensions.Y;

  const new_dimensions: Vector2 = object_ratio > canvas_ratio ?
    { X: canvas_dimensions.X, Y: canvas_dimensions.X * object_ratio } :
    { X: canvas_dimensions.Y * object_ratio, Y: canvas_dimensions.Y };

  return new_dimensions.X / width * .9;
}

export function getOptimalZoomMultiple(counters: Counter[] | OrderCounter[] | SelfOrderCounter[], canvas_dimensions: Vector2): number {
  let width: number = 0;
  let height: number = 0;
  let top: Vector2 | null = null;
  let bottom: Vector2 | null = null;
  let left: Vector2 | null = null;
  let right: Vector2 | null = null;

  for (let j = 0; j < counters.length; j++) {
    for (let i = 0; i < counters[j].corners.length; i++) {
      const corner: Vector2 = counters[j].corners[i].location;
      if (!top || corner.Y < top.Y) {
        top = corner;
      }
      if (!bottom || corner.Y > bottom.Y) {
        bottom = corner;
      }
      if (!left || corner.X < left.X) {
        left = corner;
      }
      if (!right || corner.X > right.X) {
        right = corner;
      }
    }
  }

  if (top && bottom && left && right) {
    width = right.X - left.X;
    height = bottom.Y - top.Y;
  }

  const object_ratio: number = width / height;
  const canvas_ratio: number = canvas_dimensions.X / canvas_dimensions.Y;

  const new_dimensions: Vector2 = object_ratio > canvas_ratio ?
    { X: canvas_dimensions.X, Y: canvas_dimensions.X * object_ratio } :
    { X: canvas_dimensions.Y * object_ratio, Y: canvas_dimensions.Y };

  return new_dimensions.X / width * .9;
}

export function getOptimalSlabZoom(width: number, height: number, canvas_dimensions: Vector2): number {
  const object_ratio: number = width / height;
  const canvas_ratio: number = canvas_dimensions.X / canvas_dimensions.Y;

  const new_dimensions: Vector2 = object_ratio > canvas_ratio ?
    { X: canvas_dimensions.X, Y: canvas_dimensions.X * object_ratio } :
    { X: canvas_dimensions.Y * object_ratio, Y: canvas_dimensions.Y };

  return new_dimensions.X / width * .9;
}
