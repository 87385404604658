export enum ActionType {
    SELF_ORDER_LOADING="SELF_ORDER_LOADING",
    SELF_ORDER_UPDATING="SELF_ORDER_UPDATING"
}

interface ISelfOrderLoading {
    type: ActionType.SELF_ORDER_LOADING
}

interface ISelfOrderUpdating {
    type: ActionType.SELF_ORDER_UPDATING
}

export type Action = ISelfOrderLoading | ISelfOrderUpdating;