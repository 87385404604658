/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getStripeCustomer } from "api/actions/billing";
import { updateStripeCustomer } from "api/actions/billing";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { IBillingCx } from "api/types/billing";
import { IBillingCxError } from "api/types/billing";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import Loading from "components/universal/loading";
import LoadingPage from "components/universal/loading_page";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// URLS ///
import { billing_url } from "components/navigation/endpoints";
import { org_url } from "components/navigation/endpoints";
/// VALIDATE ///
import { validateEmail } from "functions/value";
/// BREADCRUMBS ///
import { org } from "values/breadcrumbs/organization";
import { org_billing } from "values/breadcrumbs/organization";
import { org_billing_update } from "values/breadcrumbs/organization";

export default function OrgUpdate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [bill_cx, setBillCx] = useState<IBillingCx>({});
  const [errors, setErrors] = useState<IBillingCxError>({});

  const {
    loading,
    updating,
    billing_customer
  } = useAppSelector((state) => state.billing);

  useEffect(() => {
    dispatch(setBreadcrumbs([org, org_billing, org_billing_update]));
  }, [dispatch]);

  useEffect(() => {
    if (!(billing_customer?.id)) {
      dispatch(getStripeCustomer());
    }
    setBillCx(billing_customer);
  }, [dispatch, billing_customer]);

  const save = () => {
    if (!validateEmail(bill_cx.email)) {
      setErrors({ email_error: "Enter a valid email", email_has_error: true });
      return;
    }

    dispatch(updateStripeCustomer({ ...billing_customer, ...bill_cx }));
    navigate(`/${org_url}/${billing_url}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={loading}>
        <Grid container alignItems="flex-start">
          <Grid container item xs={12} lg={6}>
            <PageTitle title="Organization" endProps={
              <Box>
                <BaseButton text="Cancel" icon={faXmark} clickEvent={() => navigate(`/${org_url}/${billing_url}`)} />
                <BaseButton text="Save" icon={faSave} clickEvent={save} />
              </Box>
            } />
            <Grid item xs={12}>
              <Box sx={header_style}></Box>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "15px" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "flex-start" }}>
                <TextField
                  value={bill_cx?.name ?? ""}
                  onChange={e => setBillCx({ ...bill_cx, name: e.target.value })}
                  label="Name" />
                <TextField
                  value={bill_cx?.email ?? ""}
                  onChange={e => setBillCx({ ...bill_cx, email: e.target.value })}
                  error={errors?.email_has_error}
                  helperText={errors?.email_error ?? ""}
                  label="Email" />
                <TextField
                  value={bill_cx?.phone ?? ""}
                  onChange={e => setBillCx({ ...bill_cx, phone: e.target.value })}
                  label="Phone" />
                <TextField
                  value={bill_cx?.address?.line1 ?? ""}
                  onChange={e => setBillCx({ ...bill_cx, address: { ...bill_cx.address, line1: e.target.value } })}
                  label="Address" />
                <TextField
                  value={bill_cx?.address?.line2 ?? ""}
                  onChange={e => setBillCx({ ...bill_cx, address: { ...bill_cx.address, line2: e.target.value } })}
                  label="Address 2" />
                <TextField
                  value={bill_cx?.address?.city ?? ""}
                  onChange={e => setBillCx({ ...bill_cx, address: { ...bill_cx.address, city: e.target.value } })}
                  label="City" />
                <TextField
                  value={bill_cx?.address?.state ?? ""}
                  onChange={e => setBillCx({ ...bill_cx, address: { ...bill_cx.address, state: e.target.value } })}
                  label="State/Province" />
                <TextField
                  value={bill_cx?.address?.country ?? ""}
                  onChange={e => setBillCx({ ...bill_cx, address: { ...bill_cx.address, country: e.target.value } })}
                  label="Country" />
                <TextField
                  value={bill_cx?.address?.postal_code ?? ""}
                  onChange={e => setBillCx({ ...bill_cx, address: { ...bill_cx.address, postal_code: e.target.value } })}
                  label="Postal Code" />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Loading loading={updating} />
      </LoadingPage>
    </motion.div >
  )
}

