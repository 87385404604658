/// HOOKS //
import { useAppDispatch } from "api/hooks/apiHook";
import { useState } from "react";
/// ACTIONS ///
import { updateQuote } from "api/actions/quotes/quote";
/// TYPES ///
import { Quote } from "api/types/quote";
/// COMPONENTS ///
import SetDiscount from "components/quote/tabs/quote_details/set_discount";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  quote: Quote
}

export default function SetStoreDiscount({ open, setOpen, quote }: Props) {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string>("");
  const [has_error, setHasError] = useState<boolean>(false);

  const update = (discount: number) => {
    if (isNaN(discount) || discount < 0) {
      setError("Must be a positive number");
      setHasError(true);
    }
    if (quote && quote.id) {
      dispatch(updateQuote({ ...quote, discount: discount, changed: true }, quote.id, false));
    }
    setOpen(false);
  }

  return (
    <SetDiscount
      open={open}
      original_discount={quote?.discount ?? 0}
      error={error}
      has_error={has_error}
      setOpen={setOpen}
      update={update} />
  )
}
