/// TYPES ///
import { Variants } from "framer-motion"

export const measurement_motion: Variants = {
  measurement: {
    x: 0,
    visibility: "visible",
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    }
  },
  angles: {
    x: 200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  edges: {
    x: 200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  appliances: {
    x: 200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  material: {
    x: 200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  slabs: {
    x: 200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  quote: {
    x: 200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  initial: {
    x: 200,
  }
}

export const angles_motion: Variants = {
  measurement: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  angles: {
    x: 0,
    visibility: "visible",
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
  },
  edges: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  appliances: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  material: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  slabs: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  quote: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  initial: {
    x: -200,
  }
}

export const edges_motion: Variants = {
  measurement: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  angles: {
    x: -200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  edges: {
    x: 0,
    visibility: "visible",
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
  },
  appliances: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  material: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  slabs: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  quote: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  initial: {
    x: -200,
  }
}

export const appliances_motion: Variants = {
  measurement: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  angles: {
    x: -200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  edges: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  appliances: {
    x: 0,
    visibility: "visible",
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
  },
  material: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  slabs: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  quote: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  initial: {
    x: -200,
  }
}

export const material_motion: Variants = {
  measurement: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  angles: {
    x: -200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  edges: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  appliances: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  material: {
    x: 0,
    visibility: "visible",
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
  },
  slabs: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  quote: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  initial: {
    x: -200,
  }
}

export const slabs_motion: Variants = {
  measurement: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  angles: {
    x: -200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  edges: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  appliances: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  material: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  slabs: {
    x: 0,
    visibility: "visible",
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
  },
  quote: {
    x: 200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  initial: {
    x: -200,
  }
}

export const quote_motion: Variants = {
  measurement: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  angles: {
    x: -200,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  edges: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  appliances: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  material: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  slabs: {
    x: -200,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
    transitionEnd: {
      visibility: "hidden"
    }
  },
  quote: {
    x: 0,
    visibility: "visible",
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    },
  },
  initial: {
    x: -200,
  }
}
