/// MUI ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// TYPES ///
import { IFabricationJob } from "api/types/customer";
/// STYLES ///
import { row_text_item_style } from "styles/universal/row";
import { row_btn_style } from "styles/universal/row";

interface Props {
  job: IFabricationJob
}

export default function CxJobItem({ job }: Props) {
  if (job?.geo_address === "") {
    return null;
  }

  return (
    <Grid container item xs={12} sx={row_btn_style}>
      <Grid item xs={12}>
        <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
          {job?.geo_address ?? ""}
        </Typography>
      </Grid>
    </Grid>
  )
}

