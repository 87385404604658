/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// ICONS ///
import { faShop } from "@fortawesome/free-solid-svg-icons";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
/// URLS ///
import { shops_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { shops_list } from "values/breadcrumbs/shop";
import { shops_add } from "values/breadcrumbs/shop";

export default function AddShop() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setBreadcrumbs([shops_list, shops_add]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start">
        <Grid container item xs={12} lg={6}>
          <PageTitle title="Add Shop" endProps={
            <Box>
              <BaseButton text="View Shops" icon={faShop} clickEvent={() => navigate(`/${shops_url}`)} />
            </Box>
          } />
          <Grid item xs={12}>
            <Typography variant="body2">
              Coming soon! The ability to add new fabrication shops to a single company, and create additional user accounts.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </motion.div>
  )
}
