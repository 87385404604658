import { Point } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";

export function getPointByUuid(uuid: string, points: Point[]): Point | null {
    for(let i = 0; i < points.length; i++){
        if(points[i].uuid === uuid){
            return points[i];
        }
    }
    return null;
}

export function getNextPoint(point_from: Vector2, angle: number, dimensions: Vector2): Vector2 {
    return {
        X: Math.round(point_from.X + Math.sin(angle * Math.PI / 180.0) * Math.abs(dimensions.X)),
        Y: Math.round(point_from.Y - Math.cos(angle * Math.PI / 180.0) * Math.abs(dimensions.Y))
    }
}