/// REDUX ///
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useState } from "react";
/// COMPONENTS ///
import ShopAreaBar from "../bar/area";
import ShopColorOptionsArea from "./area";
import Loading from "components/universal/loading";
/// MUI ///
import Box from "@mui/material/Box";

interface Props {
  current_area: number,
  setCurrentArea: (area: number) => void
}

export default function ShopColorOptions({ current_area, setCurrentArea }: Props) {
  const [loading, setLoading] = useState<number[]>([]);
  const { areas } = useAppSelector((state) => state.shop_quote);

  const updateLoading = (is_loading: boolean, id: number) => {
    if (is_loading) {
      setLoading([...loading.filter((i: number) => i !== id), id]);
    }
    else {
      setLoading([...loading.filter((i: number) => i! += id)]);
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <ShopAreaBar current_area={current_area} setArea={setCurrentArea} />
      <Box sx={{ marginTop: "30px", padding: "20px", paddingBottom: "100px", width: "calc(100% - 250px)" }}>
        <ShopColorOptionsArea area={areas[current_area]} updateLoading={updateLoading} />
        <Loading loading={loading.length > 0} />
      </Box>
    </div>
  )
}
