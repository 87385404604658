/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { delete_url, end_customer_url } from "components/navigation/endpoints";
import { create_url } from "components/navigation/endpoints";
import { filter_url } from "components/navigation/endpoints";
import { detail_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";

export const cx_list: IBreadcrumb = {
  id: 0,
  label: "Customers",
  url: `/${end_customer_url}`
}

export const cx_create: IBreadcrumb = {
  id: 1,
  label: "Create Customer",
  url: `/${end_customer_url}/${create_url}`
}

export const cx_filter: IBreadcrumb = {
  id: 2,
  label: "Filter Customers",
  url: `/${end_customer_url}/${filter_url}`
}

export const cx: IBreadcrumb = {
  id: 3,
  label: "Customer",
  url: `/${end_customer_url}/${detail_url}/id`
}

export const cx_update: IBreadcrumb = {
  id: 4,
  label: "Update",
  url: `/${end_customer_url}/${detail_url}/id/${update_url}`
}

export const cx_delete: IBreadcrumb = {
  id: 5,
  label: "Delete",
  url: `/${end_customer_url}/${detail_url}/id/${delete_url}`
}
