/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { createShopMaterial } from "api/actions/shop_material";
import { resetCreateShopMaterialSuccess } from "api/actions/shop_material";
/// TYPES ///
import { IShopMaterial } from "api/types/shop_material";
import { IShopMaterialError } from "api/types/shop_material";
/// MUI ///
import Box from "@mui/material/Box";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import StyledRadio from "components/universal/styled_radio";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";
/// VALUES ///
import { QUARTZ } from "values/material";
import { NATURAL_STONE } from "values/material";
import { ULTRA_COMPACT } from "values/material";
import { CM12 } from "values/material";
import { CM2 } from "values/material";
import { CM3 } from "values/material";
import { CM1 } from "values/material";
import { CM60 } from "values/material";
import { CM08 } from "values/material";
import { MM6 } from "values/material";
import { MM8 } from "values/material";
import { MM12 } from "values/material";
import { MM20 } from "values/material";
import { MM30 } from "values/material";
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  setCurrent: (id: number) => void
}

export default function ShopCreateMaterial({ open, setOpen, setCurrent }: Props) {
  const dispatch = useAppDispatch();
  const { material: created_material } = useAppSelector(state => state.shop_material);
  const { create_success } = useAppSelector(state => state.shop_material);
  const [errors, setErrors] = useState<IShopMaterialError>({});
  const [material, setMaterial] = useState<IShopMaterial>({ taxable: true });

  useEffect(() => {
    if (create_success) {
      dispatch(resetCreateShopMaterialSuccess());
      setCurrent(created_material?.id ?? 0);
      setOpen(false);
    }

  }, [dispatch, setCurrent, setOpen, create_success, created_material?.id]);

  const save = () => {
    let error: boolean = false;
    let material_errors: IShopMaterialError = {};

    if (!material?.name || material.name === "") {
      material_errors.name_error = "Enter a Material Name";
      material_errors.name_has_error = true;
      error = true;
    }

    if (!material?.material_type || material.material_type === "") {
      material_errors.material_type_error = "Choose a Material Typ";
      material_errors.material_type_has_error = true;
      error = true;
    }

    if (!material?.thickness || material.thickness === "") {
      material_errors.thickness_error = "Choose a Thickness Error";
      material_errors.thickness_has_error = true;
      error = true;
    }

    setErrors(material_errors);

    if (error) {
      return;
    }

    dispatch(createShopMaterial(material));
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
      <DialogTitle>Add Material</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Box display="flex" flexDirection="column" sx={{ gap: "10px", paddingTop: "10px" }}>
          <TextField
            label="Material Name"
            fullWidth
            value={material?.name ?? ""}
            error={errors?.name_has_error}
            helperText={errors?.name_error}
            onChange={e => setMaterial({ ...material, name: e.target.value })} />
          <TextField
            fullWidth
            label="Material Type"
            select
            value={material?.material_type ?? ""}
            error={errors?.material_type_has_error}
            helperText={errors?.material_type_error}
            onChange={e => setMaterial({ ...material, name: e.target.value })}
          >
            <MenuItem key="" value="">
            </MenuItem>
            <MenuItem key={QUARTZ} value={QUARTZ}>
              Quartz
            </MenuItem>
            <MenuItem key={NATURAL_STONE} value={NATURAL_STONE}>
              Natural Stone
            </MenuItem>
            <MenuItem key={ULTRA_COMPACT} value={ULTRA_COMPACT}>
              Ultra Compact
            </MenuItem>
          </TextField>
          <TextField
            fullWidth
            label="Thickness"
            select
            value={material?.thickness ?? ""}
            error={errors?.thickness_has_error}
            helperText={errors?.thickness_error}
            onChange={e => setMaterial({ ...material, thickness: e.target.value })}
          >
            <MenuItem key="" value="">
            </MenuItem>
            <MenuItem key={CM12} value={CM12}>
              {getMaterialHeightDisplay(CM12)}
            </MenuItem>
            <MenuItem key={CM2} value={CM2}>
              {getMaterialHeightDisplay(CM2)}
            </MenuItem>
            <MenuItem key={CM3} value={CM3}>
              {getMaterialHeightDisplay(CM3)}
            </MenuItem>
            <MenuItem key={CM1} value={CM1}>
              {getMaterialHeightDisplay(CM1)}
            </MenuItem>
            <MenuItem key={CM60} value={CM60}>
              {getMaterialHeightDisplay(CM60)}
            </MenuItem>
            <MenuItem key={CM08} value={CM08}>
              {getMaterialHeightDisplay(CM08)}
            </MenuItem>
            <MenuItem key={MM6} value={MM6}>
              {getMaterialHeightDisplay(MM6)}
            </MenuItem>
            <MenuItem key={MM8} value={MM8}>
              {getMaterialHeightDisplay(MM8)}
            </MenuItem>
            <MenuItem key={MM12} value={MM12}>
              {getMaterialHeightDisplay(MM12)}
            </MenuItem>
            <MenuItem key={MM20} value={MM20}>
              {getMaterialHeightDisplay(MM20)}
            </MenuItem>
            <MenuItem key={MM30} value={MM30}>
              {getMaterialHeightDisplay(MM30)}
            </MenuItem>
          </TextField>
          <FormControl sx={{ paddingLeft: "5px", marginTop: "10px" }}>
            <FormLabel id="add-customer-radios">
              <Typography variant="body2">
                Taxable
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              value={material?.taxable ? "true" : "false"}
              aria-labelledby="taxable-radios"
              name="taxable-radios"
              onChange={e => setMaterial({ ...material, taxable: e.target.value === "true" ? true : false })}
            >
              <FormControlLabel
                value={"true"}
                control={<StyledRadio />}
                label={
                  <Typography variant="body2">
                    Yes
                  </Typography>
                } />
              <FormControlLabel
                value={"false"}
                control={<StyledRadio />}
                label={
                  <Typography variant="body2">
                    No
                  </Typography>
                } />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <BaseButton text="Cancel" icon={faTimes} clickEvent={() => setOpen(false)} />
        <BaseButton text="Add" icon={faPlus} clickEvent={save} />
      </DialogActions>
    </Dialog>
  )
}
