export const progress_line_style = {
  backgroundColor: "var(--mui-palette-action-disabledBackground)",
  width: "2px",
  height: "20px",
  marginRight: "18px"
}

export const empty_line_style = {
  backgroundColor: "#fff",
  width: "2px",
  height: "20px",
  marginRight: "18px"
}

export const progress_ind_style = {
  borderRadius: "50%",
  width: "25px",
  height: "25px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "none",
  marginTop: "30px"
}
