/// TYPES ///
import { IShopMaterial } from "api/types/shop_material";
/// MUI COMPONENTS ///
import { Stack } from "@mui/material";
/// COMPONENTS ///
import DashboardTile from "components/universal/tiles/dashboard";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  mat: IShopMaterial
}

export default function ShopMaterialRow({ index, mat }: Props) {
  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      <DashboardTile
        key={mat.id}
        title={`${mat?.name} ${getMaterialHeightDisplay(mat?.thickness)} `}
        index={index}
        url={`${mat.id} `} />
    </Stack>

  )
}
