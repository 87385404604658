/// MUI COMPONENTS ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  name: string,
  open: boolean,
  setOpen: (open: boolean) => void,
  remove: () => void
}

export default function DeleteArea({ name, open, setOpen, remove }: Props) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Delete {name}</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Typography variant="body2">
          Are you sure you want to delete {name}? It will be permanently deleted.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={remove}>Delete</Button>
      </DialogActions>
    </Dialog>
  )
}
