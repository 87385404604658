import axios from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/sketch";
import { ActionType } from "api/action_types/sketch";
import { AxiosError } from "axios";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { Bump } from "api/types/sketch";
import { url_prefix } from "settings";
import { bump_url } from "../endpoint";

export const deleteBump = (bump: Bump, id?: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        const { user } = getState().authentication;

        dispatch({
            type: ActionType.SKETCH_UPDATING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${bump_url}/${id ? id : 0}/?uuid=${bump.uuid}&`;

        try
        {
            await axios.delete(url, config);

            dispatch({
                type: ActionType.SKETCH_ACTION_SUCCESS
            })
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.SKETCH_ERROR,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}
