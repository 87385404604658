import { CornerData } from "api/types/sketch";
import { CornerRadius } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { makeAnglePositive } from "functions/sketch/math";
import { getAngle } from "functions/sketch/math";
import { toRadians } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { getAngleOnSecondHalfCornerSeam } from "../seam";
import { constructHalfInwardRadiusCorner } from "../../corner/construct";
import { describeArc } from "functions/sketch/path";

export function drawSecondHalfPrintCutInsideRadiusCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    radius: number,
    zoom: number
): CornerData {
    const cut_angle: number = Math.round(makeAnglePositive((getAngle(previous_location, current_location) + 90), false) * 100) / 100;
    const next_angle: number = Math.round(makeAnglePositive((getAngle(current_location, next_location) + 90), false) * 100) / 100;
    const angle: number = Math.round(makeAnglePositive(getAngleOnSecondHalfCornerSeam(cut_angle, next_angle), false));

    const corner_radius: CornerRadius = constructHalfInwardRadiusCorner(
        angle,
        current_location,
        next_angle,
        cut_angle,
        radius * zoom
    );
    let first_point: Vector2 | null = null;

    const start_point: Vector2 = getDistantPoint(corner_radius.center_point, cut_angle - 180, radius * 4);

    if(i === 0){
        first_point = start_point;
        painter.moveTo(start_point.X, start_point.Y);
    }
    else{
        painter.lineTo(start_point.X, start_point.Y);
    }

    const circle_path: string = describeArc(
        corner_radius.center_point.X,
        corner_radius.center_point.Y,
        corner_radius.radius,
        corner_radius.radius,
        toRadians(next_angle),
        toRadians(cut_angle -270)
    );

    painter.path(circle_path);

    return {
        first_point: first_point,
        last_point: corner_radius.end_point
    }
}