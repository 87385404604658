/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// TYPES ///
import { FabricatorFinishedEdge } from "api/types/fabricator";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import { Slider } from "@mui/material";
import Typography from "@mui/material/Typography";
/// LODASH ///
import { debounce } from "lodash";
/// STYLES ///
import { gridSpacing } from "styles/style";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";
import { updateFabricatorFinishedEdge } from "api/actions/fabricator";
import { slider_marks } from "values/price";

interface Props {
  index: number,
  item: FabricatorFinishedEdge,
  updateLoading: (is_loading: boolean, id: number) => void
}

export default function FabricatorFinishedEdgeItem({ index, item, updateLoading }: Props) {
  const dispatch = useAppDispatch();
  const [current_eased_weight, setCurrentEasedWeight] = useState<number>(item?.eased_weight ?? 0);
  const [current_bevel_weight, setCurrentBevelWeight] = useState<number>(item?.bevel_weight ?? 0);
  const [current_bullnose_weight, setCurrentBullnoseWeight] = useState<number>(item?.bullnose_weight ?? 0);
  const [current_spec_weight, setCurrentSpecWeight] = useState<number>(item?.specialty_weight ?? 0);

  useEffect(() => {
    setCurrentEasedWeight(item?.eased_weight ?? 0);
    setCurrentBevelWeight(item?.bevel_weight ?? 0);
    setCurrentBullnoseWeight(item?.bullnose_weight ?? 0);
    setCurrentSpecWeight(item?.specialty_weight ?? 0);
  }, [item.eased_weight, item.bevel_weight, item.bullnose_weight, item.specialty_weight]);

  const update = useCallback(debounce(function(eased_weight: number, bevel_weight: number, bullnose_weight: number, spec_weight: number) {
    dispatch(updateFabricatorFinishedEdge({
      eased_weight: eased_weight,
      bevel_weight: bevel_weight,
      bullnose_weight: bullnose_weight,
      specialty_weight: spec_weight
    }, item.id));
    if (item.id) {
      updateLoading(false, item.id);
    }
  }, 1000), []);

  const handleEasedPrice = (weight: number) => {
    setCurrentEasedWeight(weight);
    update(weight, current_bevel_weight, current_bullnose_weight, current_spec_weight);
    if (item?.id) {
      updateLoading(true, item.id);
    }
  }

  const handleBevelPrice = (weight: number) => {
    setCurrentBevelWeight(weight);
    update(current_eased_weight, weight, current_bullnose_weight, current_spec_weight);
    if (item?.id) {
      updateLoading(true, item.id);
    }
  }

  const handleBullnosePrice = (weight: number) => {
    setCurrentBullnoseWeight(weight);
    update(current_eased_weight, current_bevel_weight, weight, current_spec_weight);
    if (item?.id) {
      updateLoading(true, item.id);
    }
  }

  const handleSpecialtyPrice = (weight: number) => {
    setCurrentSpecWeight(weight);
    update(current_eased_weight, current_bevel_weight, current_bullnose_weight, weight);
    if (item?.id) {
      updateLoading(true, item.id);
    }
  }

  return (
    <Grid container item alignItems="center" sx={{ padding: 1 }}>
      <Grid item xs={12} sx={gridSpacing}>
        <Typography variant="body2">
          <b>{
            item.finished_edge_data ?
              `${getMaterialHeightDisplay(item.finished_edge_data.material_thickness)}` : ""
          }</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        Eased
      </Grid>
      <Grid item xs={4}>
        ${(current_eased_weight / 100 * (item?.finished_edge_data.eased_price ?? 0) + (item?.finished_edge_data?.eased_price ?? 0)).toFixed(2)} LIN FT
      </Grid>
      <Grid item xs={4}>
        <Slider
          marks={slider_marks}
          size="small"
          min={-30}
          max={30}
          aria-label="Eased"
          value={current_eased_weight}
          onChange={(e, v) => handleEasedPrice(v as number)} />
      </Grid>
      <Grid item xs={4}>
        Bevel
      </Grid>
      <Grid item xs={4}>
        ${(current_bevel_weight / 100 * (item?.finished_edge_data.bevel_price ?? 0) + (item?.finished_edge_data?.bevel_price ?? 0)).toFixed(2)} LIN FT
      </Grid>
      <Grid item xs={4}>
        <Slider
          marks={slider_marks}
          size="small"
          min={-30}
          max={30}
          aria-label="Bevel"
          value={current_bevel_weight}
          onChange={(e, v) => handleBevelPrice(v as number)} />
      </Grid>
      <Grid item xs={4}>
        Bullnose
      </Grid>
      <Grid item xs={4}>
        ${(current_bullnose_weight / 100 * (item?.finished_edge_data.bullnose_price ?? 0) + (item?.finished_edge_data?.bullnose_price ?? 0)).toFixed(2)} LIN FT
      </Grid>
      <Grid item xs={4}>
        <Slider
          marks={slider_marks}
          size="small"
          min={-30}
          max={30}
          aria-label="Bullnose"
          value={current_bullnose_weight}
          onChange={(e, v) => handleBullnosePrice(v as number)} />
      </Grid>
      <Grid item xs={4}>
        Specialty
      </Grid>
      <Grid item xs={4}>
        ${(current_spec_weight / 100 * (item?.finished_edge_data.specialty_price ?? 0) + (item?.finished_edge_data?.specialty_price ?? 0)).toFixed(2)} LIN FT
      </Grid>
      <Grid item xs={4}>
        <Slider
          marks={slider_marks}
          size="small"
          min={-30}
          max={30}
          aria-label="Specialty"
          value={current_spec_weight}
          onChange={(e, v) => handleSpecialtyPrice(v as number)} />
      </Grid>
    </Grid>
  )
}
