import { CornerBumpOut } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { constructBumpoutCorner } from "../../corner/construct";

export function drawFirstHalfPrintCutBumpoutCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number
){
    const cut_angle: number = Math.round((getAngle(current_location, next_location) + 90) * 100) / 100;

    const corner_bumpout: CornerBumpOut = constructBumpoutCorner(
        previous_location,
        current_location,
        next_location,
        cut_angle - 180,
        corner_length * zoom,
        corner_depth * zoom
    );

    if(i === 0){
        painter.moveTo(corner_bumpout.A.X, corner_bumpout.A.Y);
    }
    else{
        painter.lineTo(corner_bumpout.A.X, corner_bumpout.A.Y);
    }
    
    painter.lineTo(corner_bumpout.B.X, corner_bumpout.B.Y);
    painter.lineTo(corner_bumpout.middle.X, corner_bumpout.middle.Y);
    
    return {
        first_point: corner_bumpout.A,
        last_point: corner_bumpout.middle
    }
}