import { Point } from "api/types/sketch";
import { starter_width } from "values/sketch";

export const empty_point: Point = {
    uuid: "",
    location: {X: 0, Y: 0},
    angle: 0,
    width: starter_width,
    seams: [],
    position: 0
}