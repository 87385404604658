import { FabricatorQuote } from "api/types/market";

export enum ActionType {
    CREATE_FABRICATOR_QUOTE_SUCCESS="CREATE_FABRICATOR_QUOTE_SUCCESS",
    UPDATE_FABRICATOR_QUOTE_SUCCESS="UPDATE_FABRICATOR_QUOTE_SUCCESS",
    GET_FABRICATOR_QUOTE_SUCCESS="GET_FABRICATOR_QUOTE_SUCCESS",
    GET_FABRICATOR_QUOTES_SUCCESS="GET_FABRICATOR_QUOTES_SUCCESS",
    OPEN_CONFIRM_ORDER="OPEN_CONFIRM_ORDER",
    CLOSE_CONFIRM_ORDER="CLOSE_CONFIRM_ORDER",
    FABRICATOR_QUOTE_LOADING="FABRICATOR_QUOTE_LOADING",
    FABRICATOR_QUOTE_UPDATING="FABRICATOR_QUOTE_UPDATING",
    FABRICATOR_QUOTE_ERROR="FABRICATOR_QUOTE_ERROR"
}

interface ICreateFabricatorQuoteSuccess {
    type: ActionType.CREATE_FABRICATOR_QUOTE_SUCCESS,
    payload: FabricatorQuote[]
}

interface IUpdateFabricatorQuoteSuccess {
    type: ActionType.UPDATE_FABRICATOR_QUOTE_SUCCESS,
    payload: FabricatorQuote[]
}

interface IGetFabricatorQuoteSuccess {
    type: ActionType.GET_FABRICATOR_QUOTE_SUCCESS,
    payload: FabricatorQuote
}

interface IGetFabricatorQuotesSuccess {
    type: ActionType.GET_FABRICATOR_QUOTES_SUCCESS,
    payload: FabricatorQuote[]
}

interface IOpenConfirmOrder {
    type: ActionType.OPEN_CONFIRM_ORDER,
    payload: FabricatorQuote
}

interface ICloseConfirmOrder {
    type: ActionType.CLOSE_CONFIRM_ORDER
}

interface IFabricatorQuoteLoading {
    type: ActionType.FABRICATOR_QUOTE_LOADING
}

interface IFabrictorQuoteUpdating {
    type: ActionType.FABRICATOR_QUOTE_UPDATING
}

interface IFabricatorQuoteError {
    type: ActionType.FABRICATOR_QUOTE_ERROR,
    payload: string
}

export type Action=
    ICreateFabricatorQuoteSuccess |
    IUpdateFabricatorQuoteSuccess |
    IGetFabricatorQuoteSuccess |
    IGetFabricatorQuotesSuccess |
    IOpenConfirmOrder |
    ICloseConfirmOrder |
    IFabricatorQuoteError |
    IFabricatorQuoteLoading |
    IFabrictorQuoteUpdating;