import { Cooktop } from "api/types/sketch";

export const empty_cooktop: Cooktop = {
    uuid: "",
    location: {X: 0, Y: 0},
    num_burners: 4,
    length: 30,
    width: 20,
    angle: 0,
    counter_uuid: ""
}