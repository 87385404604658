/// HOOKS ///
import { useNavigate } from "react-router-dom";
/// FM ///
import { motion } from "framer-motion";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
/// TYPES ///
import { FabricatorPriceList } from "api/types/fabricator";
/// URLS ///
import { market_url } from "components/navigation/endpoints";
import { price_list_url } from "components/navigation/endpoints";
/// STYLES ///
import { active_icn_style } from "styles/price_list";
import { inactive_icn_style } from "styles/price_list";
import { row_btn_style } from "styles/universal/row";
import { row_text_item_style } from "styles/universal/row";
/// MOTION ///
import { row_text_motion } from "styles/universal/row";

interface Props {
  item: FabricatorPriceList
}

export default function MarketPlItem({ item }: Props) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12}>
      <motion.button
        initial="rest"
        whileHover="hover"
        animate="rest"
        variants={row_text_motion}
        onClick={() => navigate(`/${market_url}/${price_list_url}/${item.id}`)}
        style={row_btn_style}>
        <Grid container>
          <Grid item xs={5}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {item.price_list_data?.name}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {item.price_list_data?.region_data?.name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" sx={{ width: "100%", paddingLeft: "18px" }}>
              {
                item?.enabled && item?.price_list_data?.status ?
                  <FontAwesomeIcon icon={faCircleCheck} style={active_icn_style} /> :
                  <FontAwesomeIcon icon={faCircleXmark} style={inactive_icn_style} />
              }
            </Box>
          </Grid>
        </Grid>
      </motion.button>
    </Grid>
  )
}
