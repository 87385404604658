export const primary_color: string = "#0063fe";
export const secondary_color: string = "#f6be41";
export const third_color: string = "#00693e";
export const error_color: string = "#ec503e";
export const well_color: string = "#f6f8fa";
export const marketing_well_color: string = "#e9effe";
export const text_primary: string = "#333333";
export const text_secondary: string = "#202528";
export const white_color: string = "#ffffff";
export const grey_color: string = "#888888";
export const dark_grey_color: string = "#757575";
export const light_grey_color: string = "#D4D4D4";

export const mainContentAreaStyle = {
  marginLeft: 7.5,
  paddingLeft: 4.5,
  paddingRight: 4.5,
  paddingTop: 12,
  paddingBottom: 2,
  minHeight: "100vh"
}

export const toolBarStyle = {
  paddingLeft: "13px",
  paddingRight: "13px",
  height: "50px",
  backgroundColor: white_color
}

export const gridSpacing = {
  marginTop: 0.75,
  marginBottom: 0.75,
  paddingRight: 1
}

export const accordionIconStyle = {
  "& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root:hover": { backgroundColor: "#DADADA" }
}
