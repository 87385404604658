/// HOOKS ///
import { useEffect } from "react";
/// TYPES ///
import { Counter } from "api/types/sketch";
import { DiagramProps } from "api/types/sketch";
import { DrawOptions } from "api/types/sketch";
import { Label } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
/// SKETCH FUNCTIONS ///
import { drawCounters } from "functions/sketch";
import { getCanvas } from "functions/sketch/context";
import { getContext } from "functions/sketch/context";
import { getDiagramProps } from "functions/sketch/get/diagram";
/// CANVAS ///
import { initializeCanvas } from "functions/sketch/context";
/// FUNCTION ///
import { getCounterColor } from "functions/sketch";

const empty_path: Path2D = new Path2D();

export function useDrawDiagramCounters(area_uuid: string, counters: Counter[], labels: Label[], dimensions: Vector2, draw_options: DrawOptions, counters_loaded: boolean) {
  useEffect(() => {
    const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById(`diagram_ac_${area_uuid}`));
    const context: CanvasRenderingContext2D | null = getContext(canvas);

    if (canvas && context && counters_loaded) {
      initializeCanvas(canvas, context);
      const props: DiagramProps = getDiagramProps(counters, dimensions);

      drawCounters(
        context,
        counters,
        empty_path,
        props.zoom,
        props.offset,
        { X: 0, Y: 0 },
        { ...draw_options, show_degrees: true, show_edges: true }
      );

      for (let i = 0; i < labels.length; i++) {
        context.fillStyle = getCounterColor(labels[i]?.area_position - 1 ?? 0);
        context.font = `${labels[i].size}px Arial`;
        context.fillText(labels[i].text, labels[i].location.X * props.zoom + props.offset.X, labels[i].location.Y * props.zoom + props.offset.Y);
      }
    }
  }, [counters, labels, area_uuid, counters_loaded, dimensions, draw_options]);
}
