/// TYPES ///
import { CSSProperties } from "react";
/// COLORS ///
import { dark_grey_color } from "styles/style";
import { light_grey_color } from "styles/style";

export const miniDrawerWidth = 50;

export const mini_draw_style = {
  width: miniDrawerWidth,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    width: miniDrawerWidth,
    boxSizing:
      "border-box",
    marginTop: `${miniDrawerWidth}px`,
    paddingTop: "29px"
  },
}

export const mini_logout_btn_style: CSSProperties = {
  position: "fixed",
  bottom: 0,
  left: 0,
  width: miniDrawerWidth
}

export const mini_btn_style = (current_url: boolean) => ({
  width: miniDrawerWidth,
  height: 40,
  paddingLeft: "13px",
  paddingRight: "13px",
  marginTop: 0,
  marginBottom: 0,
  backgroundColor: current_url ? "var(--mui-palette-primary-main)" : "var(--mui-palette-background-paper)",
  "&:hover": {
    backgroundColor: current_url ? "var(--mui-palette-primary-dark)" : light_grey_color,
  }
});

export const mini_ico_style = (current_url: boolean): CSSProperties => ({
  width: "20px",
  height: "20px",
  paddingLeft: "2px",
  textAlign: "center",
  justifyContent: "center",
  color: current_url ? "var(--mui-palette-background-paper)" : dark_grey_color
});

export const drawerGridContainer = {
  width: 400,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2
}

export const drawerTitleStyle = {
  paddingTop: 2,
  paddingBottom: 1
}

export const drawerIconBarStyle = {
  width: '100%',
  display: 'flex',
  paddingTop: 10
}

export const drawerGridFormAreaStyle = {
  marginTop: 1,
  alignContent: 'center',
  paddingLeft: 1,
  paddingRight: 1,
  paddingTop: 1,
  paddingBottom: 1
}

export const drawerGridSaveButtonAreaStyle = {
  width: '100%',
  display: 'flex',
  position: 'absolute',
  bottom: 20
}
