export enum ActionType {
  QUOTE_ERROR = "QUOTE_ERROR",
  AREAS_ERROR = "AREAS_ERROR"
}

interface IQuoteError {
  type: ActionType.QUOTE_ERROR,
  payload: string
}

interface IAreaError {
  type: ActionType.AREAS_ERROR,
  payload: string
}

export type Action = IQuoteError | IAreaError;
