import { OrderArea } from "api/types/order";

export enum ActionType {
  AREAS_LOADING = "ORDER_AREAS_LOADING",
  GET_ORDER_AREAS_SUCCESS = "GET_ORDER_AREAS_SUCCESS"
}

interface IGetOrderAreasSuccess {
  type: ActionType.GET_ORDER_AREAS_SUCCESS,
  payload: OrderArea[]
}

interface IAreasLoading {
  type: ActionType.AREAS_LOADING
}

export type Action = IGetOrderAreasSuccess | IAreasLoading;
