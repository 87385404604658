/// TYPES ///
import { IShopQuoteFilter } from "api/types/shop_quote/filter";
/// URLS ///
import { url_prefix } from "settings";
import { shop_quote_url } from "api/actions/shop_quote/endpoints";

export function constructGetQuotesUrl(args: IShopQuoteFilter): string {
  let url = `${url_prefix}${shop_quote_url}/?`;

  if (args?.name && args?.name !== "") {
    url += `quote_name=${args.name}&`;
  }

  if (args?.customer_name && args?.customer_name !== "") {
    url += `customer_name=${args.customer_name}&`;
  }

  if (args?.start_date && args?.start_date !== "") {
    url += `start_date=${args.start_date}&`;
  }

  if (args?.end_date && args?.end_date !== "") {
    url += `end_date=${args.end_date}&`;
  }

  return url;
}
