import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";

export enum ActionType {
    UNDO="UNDO",
    REDO="REDO",
    CREATE_HISTORY_POINT="CREATE_HISTORY_POINT",
    SET_COUNTERS="SET_COUNTERS",
    SET_CUT_SHAPES="SET_CUT_SHAPES",
    COUNTERS_LOADING="COUNTERS_LOADING",
    CUT_SHAPES_LOADING="CUT_SHAPES_LOADING",
    HISTORY_ACTION_SUCCESS="HISTORY_ACTION_SUCCESS",
    HISTORY_UPDATING="HISTORY_UPDATING",
    HISTORY_FAIL="HISTORY_FAIL",
    REPLACE_RESTORE_POINT="REPLACE_RESTORE_POINT",
    SET_HISTORY_WARNING="SET_HISTORY_WARNING",
    CLEAR_HISTORY="CLEAR_HISTORY"
}

interface IUndo {
    type: ActionType.UNDO
}

interface IRedo {
    type: ActionType.REDO
}

interface ISetCounters {
    type: ActionType.SET_COUNTERS,
    payload: Counter[]
}

interface ISetCutShapes {
    type: ActionType.SET_CUT_SHAPES,
    payload: CutShape[]
}

interface ICountersLoading {
    type: ActionType.COUNTERS_LOADING
}

interface ICutShapesLoading {
    type: ActionType.CUT_SHAPES_LOADING
}

interface ICreateRestorePoint {
    type: ActionType.CREATE_HISTORY_POINT,
    counters: Counter[],
    cut_shapes: CutShape[],
    description: string
}

interface IHistoryActionSuccess {
    type: ActionType.HISTORY_ACTION_SUCCESS
}

interface IHistoryUpdating {
    type: ActionType.HISTORY_UPDATING
}

interface IHistoryFail {
    type: ActionType.HISTORY_FAIL,
    payload: string
}

interface IReplaceRestorePoint {
    type: ActionType.REPLACE_RESTORE_POINT,
    cut_shapes: CutShape[]
}

interface ISetHistoryWarning {
    type: ActionType.SET_HISTORY_WARNING,
    open: boolean
}

interface IClearHistory {
    type: ActionType.CLEAR_HISTORY
}

export type Action =
    IUndo |
    IRedo |
    ISetCounters |
    ISetCutShapes |
    ICountersLoading |
    ICutShapesLoading |
    ICreateRestorePoint |
    IHistoryActionSuccess |
    IHistoryUpdating |
    IHistoryFail |
    IReplaceRestorePoint |
    ISetHistoryWarning |
    IClearHistory;