/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getAreas } from "api/actions/quotes/area";
import { getCounters } from "api/actions/sketch/counter";
import { getCutShapes } from "api/actions/sketch/cut_shape";
import { getFabricatorQuote } from "api/actions/market";
import { resetMarketOrderSuccess } from "api/actions/order/market_order";
import { setBreadcrumbs } from "api/actions/ui/set";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import DiagramAreaCounters from "components/universal/diagrams/counter/areas";
import DiagramShapeAreas from "components/universal/diagrams/cut_shapes/areas";
import Loading from "components/universal/loading";
import LoadingPage from "components/universal/loading_page";
import MarketConfirmOrder from "./dialog/confirm_order";
import PageTitle from "components/universal/page/title";
import MarketQuoteSummary from "./sections/summary";
import SignatureArea from "components/universal/signature";
import WOHeader from "components/universal/order/wo_header";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// ICONS ///
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { market_url } from "components/navigation/endpoints";
import { print_url } from "components/navigation/endpoints";
import { store_url } from "components/navigation/endpoints";
import { wo_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_preview } from "values/breadcrumbs/quote";
import { quote_draw } from "values/breadcrumbs/quote";
import { market_quotes } from "values/breadcrumbs/quote";
import { market_quote as mq_breadcrumb } from "values/breadcrumbs/quote";

export default function MarketQuote() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { market_id } = useParams();
  const {
    loading,
    quote: market_quote
  } = useAppSelector((state) => state.market);
  const {
    areas,
    areas_loaded,
  } = useAppSelector((state) => state.quote);
  const {
    counters,
    counters_loaded,
    cut_shapes,
    labels,
    shapes_loaded
  } = useAppSelector((state) => state.sketch);

  const { user } = useAppSelector((state) => state.authentication);
  const {
    order,
    order_success,
    updating: order_updating
  } = useAppSelector((state) => state.order);
  const [confirm_open, setConfirmOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_list.url.replace("base_url", store_url) },
      { ...quote_preview, url: quote_preview.url.replace("base_url", store_url).replace("id", id) },
      { ...quote_draw, url: quote_draw.url.replace("base_url", store_url).replace("id", id) },
      { ...market_quotes, url: market_quotes.url.replace("id", id) },
      { ...mq_breadcrumb, url: mq_breadcrumb.url.replace("id", id).replace("market_id", market_id) }
    ]));
  }, [dispatch, id, market_id]);

  useEffect(() => {
    dispatch(getFabricatorQuote(market_id));
    dispatch(getAreas(id));
    dispatch(getCounters(id));
    dispatch(getCutShapes(id));
  }, [dispatch, id, market_id]);


  if (order_success && order?.id) {
    dispatch(resetMarketOrderSuccess());
    navigate(`/${wo_url}/${detail_url}/${order.id}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={!market_quote.id || !areas_loaded || !counters_loaded || !shapes_loaded}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <PageTitle title="Order Preview" endProps={
              <Box>
                <BaseButton text="Cancel" icon={faXmark} clickEvent={() => navigate(`/${market_url}/${id}`)} />
                <BaseButton text="Print" icon={faPrint} clickEvent={() => window.open(`/${market_url}/${id}/${detail_url}/${market_id}/${print_url}`, '_blank')} />
                <BaseButton text="Place Order" icon={faFileInvoiceDollar} clickEvent={() => setConfirmOpen(true)} disabled={market_quote?.out_of_range} />
              </Box>
            } />
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "30px" }}>
            <Box sx={header_style}></Box>
          </Grid>
          <WOHeader
            invoice_number={market_quote?.estimate_number ?? ""}
            dealer={user ?? {}}
            fabricator={market_quote?.user ?? {}}
            job={market_quote?.fabrication_job_data ?? {}}
            created={market_quote?.quote_data?.date_created ?? ""} />
          <MarketQuoteSummary quote={market_quote} areas={areas} />
          <DiagramAreaCounters areas={areas} counters={counters} labels={labels} counters_loaded={counters_loaded} />
          <DiagramShapeAreas areas={areas} cut_shapes={cut_shapes} shapes_loaded={shapes_loaded} />
          <SignatureArea />
        </Grid>
        <MarketConfirmOrder open={confirm_open} setOpen={setConfirmOpen} market_quote={market_quote} />
      </LoadingPage>
      <Loading loading={loading || order_updating} />
    </motion.div >
  )
}
