import type { Action as AddonAction } from "./addon";
import type { Action as AreaAction } from "./area";
import type { Action as BumpAction } from "./bump";
import type { Action as ColorAction } from "./color_option";
import type { Action as CooktopAction } from "./cooktop";
import type { Action as CornerAction } from "./corner";
import type { Action as CounterAction } from "./counter";
import type { Action as CutBumpAction } from "./cut_bump";
import type { Action as CutShapeAction } from "./cut_shape";
import type { Action as ErrorAction } from "./error";
import type { Action as HistoryAction } from "./history";
import type { Action as LabelAction } from "./label";
import type { Action as LoadingAction } from "./loading";
import type { Action as QuoteAction } from "./quote";
import type { Action as QuoteLineItemAction } from "./quote_line_item";
import type { Action as SinkAction } from "./sink";
import type { Action as SlabAction } from "./slab";

import { ActionType as AddonActionType } from "./addon";
import { ActionType as AreaActionType } from "./area";
import { ActionType as BumpActionType } from "./bump";
import { ActionType as ColorActionType } from "./color_option";
import { ActionType as CooktopActionType } from "./cooktop";
import { ActionType as CornerActionType } from "./corner";
import { ActionType as CounterActionType } from "./counter";
import { ActionType as CutBumpActionType } from "./cut_bump";
import { ActionType as CutShapeActionType } from "./cut_shape";
import { ActionType as ErrorActionType } from "./error";
import { ActionType as HistoryActionType } from "./history";
import { ActionType as LabelActionType } from "./label";
import { ActionType as LoadingActionType } from "./loading";
import { ActionType as QuoteActionType } from "./quote";
import { ActionType as QuoteLineItemActionType } from "./quote_line_item";
import { ActionType as SinkActionType } from "./sink";
import { ActionType as SlabActionType } from "./slab";

export const ActionType = {
  ...AddonActionType,
  ...AreaActionType,
  ...BumpActionType,
  ...ColorActionType,
  ...CooktopActionType,
  ...CornerActionType,
  ...CounterActionType,
  ...CutBumpActionType,
  ...CutShapeActionType,
  ...ErrorActionType,
  ...HistoryActionType,
  ...LabelActionType,
  ...LoadingActionType,
  ...QuoteActionType,
  ...QuoteLineItemActionType,
  ...SinkActionType,
  ...SlabActionType,
}

export type Action =
  AddonAction |
  AreaAction |
  BumpAction |
  ColorAction |
  CooktopAction |
  CornerAction |
  CounterAction |
  CutBumpAction |
  CutShapeAction |
  ErrorAction |
  HistoryAction |
  LabelAction |
  LoadingAction |
  QuoteAction |
  QuoteLineItemAction |
  SinkAction |
  SlabAction;

