import { Counter } from "api/types/sketch";
import { Dispatch } from "redux";
import { Action } from "api/action_types/sketch";
import { ActionType } from "api/action_types/sketch";

export const updateLocalCounter = (counter: Counter) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_COUNTER,
            payload: counter
        });
    }
}