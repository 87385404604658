/// TYPES ///
import { IGetFabricationJobs } from "api/types/customer";
/// URLS ///
import { url_prefix } from "settings";
import { fabrication_job_url } from "../../../endpoint";

export function constructGetFabricationJobsUrl(args: IGetFabricationJobs): string {
  let url = `${url_prefix}${fabrication_job_url}/?`;

  if (args) {
    if (args.name && args.name !== "") {
      url += `name=${args.name}&`;
    }
    if (args?.end_customer && args?.end_customer !== "") {
      url += `customer=${args.end_customer}&`;
    }
    if (args.address && args.address !== "") {
      url += `address=${args.address}&`;
    }
    if (args.city && args.city !== "") {
      url += `city=${args.city}&`;
    }
    if (args.province && args.province !== "") {
      url += `province=${args.province}&`;
    }
    if (args.email && args.email !== "") {
      url += `email=${args.email}&`;
    }
    if (args.phone && args.phone !== "") {
      url += `phone_number=${args.phone}&`;
    }
    if (args.postal && args.postal !== "") {
      url += `postal=${args.postal}&`;
    }
    if (args.any) {
      url += "any=true&";
    }
    if (args.limit) {
      url += `limit=${args.limit}&`;
    }
  }

  return url;
}
