/// REACT ///
import { useState } from "react";
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateOrderEvent } from "api/actions/event";
/// MUI ///
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
/// LUXON ///
import { DateTime } from "luxon";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  id: number
}

export default function BookOrderEvent({ open, setOpen, id }: Props) {
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<DateTime>(DateTime.now());

  const bookEvent = () => {
    dispatch(updateOrderEvent({ booked: true, time: date.toLocal().toISO() }, id));
    setOpen(false);
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Book Event</DialogTitle>
      <DialogContent>
        <div style={{ paddingTop: "15px" }}>
          <DatePicker
            slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
            label="Date"
            onChange={e => setDate(e)} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button onClick={bookEvent}>
          Book
        </Button>
      </DialogActions>
    </Dialog>
  )
}
