/// REACT ///
import { useEffect } from "react";
/// TYPES ///
import { Area } from "api/types/quote";
import { CutShape } from "api/types/sketch";
import { Slab } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopSlab } from "api/types/shop_quote/slab";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { getOptimalSlabZoom } from "functions/sketch";
import { drawPreviewCutShapes } from "functions/sketch";
import { getCanvas } from "functions/sketch/context";
import { initializeSlabCanvas } from "functions/sketch/context";
import { slabCanvas } from "functions/sketch/context";
import { getContext } from "functions/sketch/context";
import { cutShapeCanvas } from "functions/sketch/context";
import { getSlabStartPoint } from "functions/sketch";
/// VALUES ///
import { SLAB_ZOOM } from "values/values";

const base_offset: Vector2 = { X: 10, Y: 10 };

function useDrawSlab(
  index: number,
  area: Area | IShopArea,
  cut_shapes: CutShape[] | IShopCutShape[],
  slab: Slab | IShopSlab,
  load_drawing: boolean
) {
  useEffect(() => {
    const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById(`slab_canvas_${slab.uuid}`));
    const context: CanvasRenderingContext2D | null = getContext(canvas);
    if (canvas && context && load_drawing) {
      initializeSlabCanvas(canvas, context);
      const width: number = area?.material_width * 4 ?? 0;
      const height: number = area?.material_length * 4 ?? 0;
      const zoom: number = getOptimalSlabZoom(
        width + base_offset.X,
        height + base_offset.Y,
        { X: canvas.width, Y: canvas.height }
      );
      const slab_start: Vector2 = getSlabStartPoint(height / 4, index);
      slab_start.X = slab_start.X / SLAB_ZOOM
      slab_start.Y = slab_start.Y / SLAB_ZOOM;

      const slab_path = new Path2D();
      slab_path.moveTo(base_offset.X, base_offset.Y);
      slab_path.lineTo(base_offset.X + width * zoom, base_offset.Y);
      slab_path.lineTo(base_offset.X + width * zoom, base_offset.Y + height * zoom);
      slab_path.lineTo(base_offset.X, base_offset.Y + height * zoom);
      slab_path.lineTo(base_offset.X, base_offset.Y);

      slabCanvas(context);
      context.fill(slab_path);
      context.stroke(slab_path);
      cutShapeCanvas(context);

      drawPreviewCutShapes(context, cut_shapes, slab, zoom, base_offset, slab_start);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load_drawing]);
};

export default useDrawSlab;
