import { IGetEventArgs } from "api/types/event";

export const event_url: string = "event";
export const order_event_url: string = "order_event";
export const self_order_event_url: string = "self_order_event";
export const sibling_event_details_url: string = "sibling_event_details";

export function getEventsUrl(url: string, args: IGetEventArgs): string {

  if (args.start_time) {
    url += `start_time=${args.start_time}&`;
  }

  if (args.end_time) {
    url += `end_time=${args.end_time}&`;
  }

  if (args.order_id) {
    url += `order_id=${args.order_id}&`;
  }

  if (args.self_order_id) {
    url += `self_order_id=${args.self_order_id}&`;
  }

  return url;
}
