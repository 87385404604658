import { Vector2 } from "api/types/sketch";
import { getDistantPoint } from "../math";

export function drawArrowPath(mouse: Vector2, angle: number, zoom: number): Path2D {
  const path = new Path2D();
  const arrow_start: Vector2 = getDistantPoint(mouse, angle, 25);

  const left_base: Vector2 = getDistantPoint(arrow_start, angle - 90, 25 * zoom);
  const right_base: Vector2 = getDistantPoint(arrow_start, angle + 90, 25 * zoom);

  const base_end_left: Vector2 = getDistantPoint(left_base, angle, 40 * zoom);
  const base_end_right: Vector2 = getDistantPoint(right_base, angle, 40 * zoom);
  const point: Vector2 = getDistantPoint(arrow_start, angle, 90 * zoom);
  const left_tip: Vector2 = getDistantPoint(base_end_left, angle - 90, 25 * zoom);
  const right_tip: Vector2 = getDistantPoint(base_end_right, angle + 90, 25 * zoom);

  path.moveTo(left_base.X, left_base.Y);
  path.lineTo(base_end_left.X, base_end_left.Y);
  path.lineTo(left_tip.X, left_tip.Y);
  path.lineTo(point.X, point.Y);
  path.lineTo(right_tip.X, right_tip.Y);
  path.lineTo(base_end_right.X, base_end_right.Y);
  path.lineTo(right_base.X, right_base.Y);
  path.lineTo(left_base.X, left_base.Y);
  return path;
}
