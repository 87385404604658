/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/event";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/event";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// TYPES ///
import { IOrderEvent } from "api/types/event";
/// URLS ///
import { order_event_url } from "../../endpoint";
import { url_prefix } from "settings";

export const updateOrderEvent = (event: IOrderEvent, id: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.EVENT_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${order_event_url}/${id}/?`;

    try {
      const { data } = await axios.patch(url, event, config);

      dispatch({
        type: ActionType.UPDATE_ORDER_EVENT,
        order_event: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.EVENT_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
