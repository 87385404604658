/// TYPES ///
import { OrderArea } from "api/types/order/order";
import { OrderCounter } from "api/types/order/order";
import { SelfOrderArea } from "api/types/order";
import { SelfOrderCounter } from "api/types/order";
/// COMPONENTS ///
import OrderPrintArea from "./area";
/// PDF COMPONENTS ///
import { View } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
/// STYLES ///
import { print_header } from "styles/print";
import { subtitle } from "styles/print";
/// FUNCTIONS ///
import { getDiagramDimensions } from "functions/sketch/get/diagram";

interface Props {
  areas: OrderArea[] | SelfOrderArea[],
  counters: OrderCounter[] | SelfOrderCounter[],
}

export default function OrderPrintAreas({ areas, counters }: Props) {
  return (
    <View style={{ marginTop: "25px" }} break>
      <View style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <Text style={{ ...subtitle, textTransform: "uppercase", marginBottom: "2px" }}>
          Counters
        </Text>
      </View>
      <View style={print_header}></View>
      {
        areas.map((area: OrderArea | SelfOrderArea, index: number) => {
          return (
            <OrderPrintArea
              key={index}
              index={index}
              area={area}
              counters={counters}
              labels={[]}
              dimensions={getDiagramDimensions(counters.filter(c => c.area_uuid === area.uuid))} />
          )
        })
      }
    </View>
  );
}
