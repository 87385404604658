export enum ActionType {
  WO_ERROR = "WO_ERROR"
}

interface IWOError {
  type: ActionType.WO_ERROR,
  payload: string
}

export type Action = IWOError;
