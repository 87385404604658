/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopCooktop } from "api/types/shop_quote/cooktop";

export const updateLocalShopCooktop = (cooktop: IShopCooktop) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_LOCAL_SHOP_COOKTOP,
      payload: cooktop
    });
  }
}
