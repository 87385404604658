/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { billing_url } from "components/navigation/endpoints";
import { org_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";
import { terms_url } from "components/navigation/endpoints";

export const org: IBreadcrumb = {
  id: 0,
  label: "Organization",
  url: `/${org_url}`
}

export const org_update: IBreadcrumb = {
  id: 1,
  label: "Update",
  url: `/${org_url}/${update_url}`
}

export const org_billing: IBreadcrumb = {
  id: 2,
  label: "Billing",
  url: `/${org_url}/${billing_url}`
}

export const org_billing_update: IBreadcrumb = {
  id: 3,
  label: "Update",
  url: `/${org_url}/${billing_url}/${update_url}`
}

export const org_terms: IBreadcrumb = {
  id: 4,
  label: "Terms & Conditions",
  url: `/${org_url}/${billing_url}/${terms_url}`
}
