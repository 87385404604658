import { useState } from "react";
import { useEffect } from "react";
import { Counter } from "api/types/sketch";
import { Seam } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { MouseData } from "types/mouse_position";
import { drawSeamCounter } from "functions/sketch";
import { getCanvas } from "functions/sketch/context";
import { getContext } from "functions/sketch/context";
import { initializeCanvas } from "functions/sketch/context";
import { errorCanvas } from "functions/sketch/context";

function useDrawSeams(
  mouse_data: MouseData,
  selected_counter: Counter | null,
  open: boolean,
  focus_count: number,
  offset: Vector2
): Seam | null {
  const [active_seam, setActiveSeam] = useState<Seam | null>(null);

  useEffect(() => {
    const mouse: Vector2 = { X: mouse_data.screen_position.X - offset.X, Y: mouse_data.screen_position.Y - offset.Y };

    if (open) {
      const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById("dialog_canvas"));
      const context: CanvasRenderingContext2D | null = getContext(canvas);
      if (canvas && context) {
        initializeCanvas(canvas, context);
        const path: Path2D = new Path2D();
        const edge_path: Path2D = new Path2D();
        const seam_path: Path2D = new Path2D();
        const seam_highlight: Path2D = new Path2D();

        if (selected_counter) {
          const returned_seam: Seam | null = drawSeamCounter(
            path,
            edge_path,
            seam_path,
            seam_highlight,
            selected_counter,
            canvas.width,
            canvas.height,
            mouse
          );

          context.beginPath();
          context.stroke(path);
          context.stroke(edge_path);
          errorCanvas(context);
          context.stroke(seam_path);
          setActiveSeam(returned_seam);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouse_data.position, focus_count]);

  return active_seam;
};

export default useDrawSeams;
