/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { createShopQuote } from "api/actions/shop_quote";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { ICustomer } from "api/types/customer";
import { ICustomerErrors } from "api/types/customer";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
/// PAGE COMPONENTS ///
import BaseButton from "components/universal/button/base";
import CallbackDialog from "components/universal/callback_dialog";
import CreateQuoteCustomer from "components/quote/create_customer";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { shop_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_create } from "values/breadcrumbs/quote";
/// VALUES CHECK ///
import { validateEmail } from "functions/value";
/// VALUES ///
import { NONE } from "components/quote/create_customer/radio/values";
import { NEW } from "components/quote/create_customer/radio/values";
import { EXISTING } from "components/quote/create_customer/radio/values";

export default function CreateShopQuote() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [customer, setCustomer] = useState<ICustomer>({});
  const [job_use_cx, setJobUseCx] = useState<boolean>(true);
  const [open_confirm, setOpenConfirm] = useState<boolean>(false);
  const [showing, setShowing] = useState<string>("none");
  const [name, setName] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [cx_errors, setCxErrors] = useState<ICustomerErrors>({});
  const [no_cx, setNoCx] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_list.url.replace("base_url", shop_url) },
      { ...quote_create, url: quote_create.url.replace("base_url", shop_url) }]));
  }, [dispatch]);

  const verifyCreate = () => {
    let has_error: boolean = false;
    let cx_errors: ICustomerErrors = {};
    if (name === "") {
      setError("Enter a name for this quote");
      has_error = true;
    }
    else {
      setError("");
    }

    if (showing === NEW) {
      if (!customer.first_name || customer.first_name === "") {
        cx_errors.first_name_error = "Enter a first name";
        cx_errors.first_name_has_error = true;
        has_error = true;
      }

      if (!customer.last_name || customer.last_name === "") {
        cx_errors.last_name_error = "Enter a last name";
        cx_errors.last_name_has_error = true;
        has_error = true;
      }

      if (!customer.email || !validateEmail(customer.email)) {
        cx_errors.email_has_error = true;
        cx_errors.email_error = "Enter a valid email";
        has_error = true;
      }
    }

    if (showing === EXISTING) {
      if (!customer?.id) {
        setNoCx(true);
        has_error = true;
      }
    }

    if (showing === NEW || showing === EXISTING) {
      if (isNaN(customer.lat) || isNaN(customer.lng) || customer.lat === 0 || customer.lng === 0) {
        cx_errors.geo_has_error = true;
        cx_errors.geo_error = "Unable to retreive coordinates for this address";
        has_error = true;
      }
    }

    if (has_error) {
      setCxErrors(cx_errors);
      return;
    }

    create();
  }

  const create = () => {
    if (showing === NONE) {
      dispatch(createShopQuote({ name }));
    }
    else if (showing === EXISTING) {
      dispatch(createShopQuote({ name, customer: customer.id, job_use_cx: job_use_cx }));
    }
    else if (showing === NEW) {
      dispatch(createShopQuote({ name, customer_data: customer, job_use_cx: job_use_cx }));
    }
    navigate(`/${shop_url}/${quote_url}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Create Quote" endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${shop_url}/${quote_url}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={verifyCreate} text="Save" icon={faSave} />
          </Box>
        } />
        <Grid item xs={12}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <TextField
              onChange={e => setName(e.target.value)}
              error={error !== ""}
              helperText={error}
              label="Quote Name"
              size="small" />
          </Box>
        </Grid>
        <CreateQuoteCustomer
          showing={showing}
          setShowing={setShowing}
          customer={customer}
          setCustomer={setCustomer}
          cx_errors={cx_errors}
          setCxErrors={setCxErrors}
          no_cx={no_cx}
          setJobUseCx={setJobUseCx} />
      </Grid>
      <CallbackDialog
        title="Confirm Create Quote"
        open={open_confirm}
        close={() => setOpenConfirm(false)}
        confirm_text="Create"
        body="Are you sure you want to create? You entered text into the customer field, but didn't choose a customer. The quote will start with no customer assigned."
        callback={create} />
    </motion.div>
  )
}
