/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { clearErrors } from "api/actions/authentication";
import { login } from "api/actions/authentication";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
/// VALIDATION ///
import { validateEmail } from "functions/value";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { sm_txt_btn_style } from "styles/universal/button";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { forgot_password_url } from "components/navigation/endpoints";

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [show_pass, setShowPass] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.authentication);
  const { error } = useAppSelector((state) => state.authentication);
  const { loading } = useAppSelector((state) => state.authentication);
  const [email, setEmail] = useState<string>("");
  const [email_error, setEmailError] = useState<string>("");
  const [password_error, setPasswordError] = useState<string>("");
  const [email_has_error, setEmailHasError] = useState<boolean>(false);
  const [password_has_error, setPasswordHasError] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setBreadcrumbs([]));
  }, [dispatch]);

  useEffect(() => {
    if (user.id) {
      return navigate("/");
    }
  }, [user, navigate]);

  const inputEmail = (input: string) => {
    setEmail(input);
    setEmailError("");
    setEmailHasError(false);
  }

  const inputPass = (input: string) => {
    setPassword(input);
    setPasswordError("");
    setPasswordHasError(false);
  }

  const startLogin = () => {
    let error: boolean = false;
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      setEmailHasError(true);
      error = true;
    }

    if (password === "" || !password) {
      setPasswordError("Please enter a password");
      setPasswordHasError(true);
      error = true;
    }

    if (error) {
      return;
    }

    dispatch(login({
      email: email,
      password: password
    }));
  }

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      startLogin();
    }
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style} onKeyDown={keyDown}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
        <Typography variant="h6" sx={{ marginLeft: "5px", marginBottom: "5px" }}>
          Login
        </Typography>
        <TextField
          autoCapitalize="none"
          type="email"
          size="small"
          sx={{ width: "318px" }}
          error={email_has_error}
          helperText={email_error}
          onChange={(e) => inputEmail(e.target.value)}
          label="Email" />
        <TextField
          autoCapitalize="none"
          type={show_pass ? "text" : "password"}
          size="small"
          error={password_has_error || error !== ""}
          helperText={error ? error : password_error}
          onChange={(e) => inputPass(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={`${show_pass ? "Hide" : "Show"} Password`}>
                  <IconButton size="small" onClick={() => setShowPass(prev => !prev)}>
                    <FontAwesomeIcon icon={show_pass ? faEyeSlash : faEye} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
          label="Password" />
        <Stack direction="row" justifyContent="space-between" sx={{ width: "318px", paddingLeft: "3px" }}>
          <Button sx={sm_txt_btn_style} onClick={() => navigate(`/${forgot_password_url}`)}>
            Forgot Password?
          </Button>
          {loading ? <CircularProgress size={"24px"} /> : <BaseButton text="Login" icon={faRightToBracket} clickEvent={startLogin} />}
        </Stack>
      </Box>
    </motion.div>
  );
}
