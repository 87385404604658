/// TYPES ///
import { Variants } from "framer-motion";

export const line_item_motion: Variants = {
  show: {
    opacity: 1
  },
  no_show: {
    opacity: 0
  }
}
