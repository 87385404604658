/// AXIOS ///
import axios from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/authentication";
/// TYPES ///
import { User } from "api/types/authentication";
/// URLS ///
import { url_prefix } from "settings";
import { profile_url } from "../endpoint";

export const updateProfile = (profile: User, id: number, distance?: boolean) => {
  return async (dispatch: Dispatch<Action>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.UPDATE_PROFILE_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${profile_url}/${id}/?`;

    if (distance) {
      url += "distance=true&";
    }

    try {
      const { data } = await axios.patch(url, profile, config);

      dispatch({
        type: ActionType.UPDATE_PROFILE_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: ActionType.UPDATE_PROFILE_FAIL,
          payload: err.message
        });
      }
    }
  }
}
