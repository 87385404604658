/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/quote";
/// TYPES ///
import { Quote } from "api/types/quote";
/// LODASH ///
import { orderBy } from "lodash";
/// SORT VALUES ///
import { NAME } from "values/sort/quote";
import { ADDRESS } from "values/sort/quote";
import { NUMBER } from "values/sort/quote";
import { EMAIL } from "values/sort/quote";
import { DATE } from "values/sort/quote";

function sort(quotes: Quote[], sort_type: number, sort_up: boolean): Quote[] {
  let sort_field: string = "name";

  switch (sort_type) {
    case NAME:
      sort_field = "name";
      break;
    case ADDRESS:
      sort_field = "address";
      break;
    case EMAIL:
      sort_field = "email";
      break;
    case DATE:
      sort_field = "date";
      break;
    case NUMBER:
      sort_field = "estimate_number";
      break;
    default:
      sort_field = "name";
  };

  return orderBy(quotes, sort_field, sort_up ? "asc" : "desc");
}

export const sortQuotes = (sort_type: number, sort_up: boolean) => {
  return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const { quotes } = getState().quote;

    dispatch({
      type: ActionType.SORT_QUOTES,
      payload: sort(quotes, sort_type, sort_up)
    });
  }
}
