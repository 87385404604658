import { Action } from "api/action_types/signature";
import { ActionType } from "api/action_types/signature";

interface State {
  message_open: boolean
}

const initialState = {
  message_open: false
}

export const signatureReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.OPEN_MESSAGE:
      return {
        ...state,
        message_open: action.open
      }
    default:
      return state;
  }
}
