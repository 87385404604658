import { Order } from "api/types/order";

export enum ActionType {
  SORT_WOS = "SORT_WOS"
}

interface ISortWOs {
  type: ActionType.SORT_WOS,
  payload: Order[]
}

export type Action = ISortWOs;
