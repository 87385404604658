import { SelfOrderArea } from "api/types/order/self_order";

export enum ActionType {
  AREAS_LOADING = "SELF_ORDER_AREAS_LOADING",
  GET_SELF_ORDER_AREAS_SUCCESS = "GET_SELF_ORDER_AREAS_SUCCESS"
}

interface ISelfOrderAreasLoading {
  type: ActionType.AREAS_LOADING
}

interface IGetSelfOrderAreasSuccess {
  type: ActionType.GET_SELF_ORDER_AREAS_SUCCESS,
  payload: SelfOrderArea[]
}

export type Action = ISelfOrderAreasLoading | IGetSelfOrderAreasSuccess;
