/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { clearHistory } from "api/actions/history/local/clear";
import { clearStoreMaterials } from "api/actions/material";
import { getQuote } from "api/actions/quotes/quote";
import { getCounters } from "api/actions/sketch/counter";
import { getAreas } from "api/actions/quotes/area";
import { getCutShapes } from "api/actions/sketch/cut_shape";
import { getStoreSketchMaterials } from "api/actions/material";
import { getStoreSketchColors } from "api/actions/material/store/api/gets_color";
import { getLabels } from "api/actions/sketch/label";
import { markQuoteChanged } from "api/actions/quotes/quote";
import { undo } from "api/actions/history";
import { redo } from "api/actions/history";
import { restore } from "api/actions/sketch/history";
import { updateHistory } from "api/actions/history";
import { setBreadcrumbs } from "api/actions/ui/set";
/// COMPONENTS ///
import StoreQuoteTabs from "./tabs";
import LoadingPage from "components/universal/loading_page";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_preview } from "values/breadcrumbs/quote";
import { quote_draw } from "values/breadcrumbs/quote";
/// URL ///
import { shop_url } from "components/navigation/endpoints";

export default function StoreQuote() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { areas_loaded } = useAppSelector((state) => state.quote);
  const { quote } = useAppSelector((state) => state.quote);
  const { quote_loaded } = useAppSelector((state) => state.quote);
  const { counters_loaded } = useAppSelector(state => state.sketch);
  const { shapes_loaded } = useAppSelector(state => state.sketch);
  const { counter_history } = useAppSelector((state) => state.history);
  const { cut_shape_history, } = useAppSelector((state) => state.history);
  const { history_position } = useAppSelector((state) => state.history);


  const { loading: material_loading } = useAppSelector((state) => state.shop_material);

  const handleHistory = (position: number) => {
    dispatch(updateHistory(quote.id, counter_history[position], cut_shape_history[position]));
    dispatch(restore(counter_history[position], cut_shape_history[position]));
  }
  const [current_area, setCurrentArea] = useState<number>(0);

  const handleUndo = () => {
    if (quote && quote.id && history_position > 0) {
      if (history_position > 0) {
        const position: number = history_position - 1;
        handleHistory(position);
        dispatch(undo());
        dispatch(markQuoteChanged());
      }
    }
  }

  const handleRedo = () => {
    if (quote && quote.id && history_position < counter_history.length - 1) {
      if (history_position < counter_history.length - 1) {
        const position: number = history_position + 1;
        handleHistory(position);
        dispatch(redo());
        dispatch(markQuoteChanged());
      }
    }
  }

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_list.url.replace("base_url", shop_url) },
      { ...quote_preview, url: quote_preview.url.replace("base_url", shop_url).replace("id", id) },
      { ...quote_draw, url: quote_draw.url.replace("base_url", shop_url).replace("id", id) }

    ]));
  }, [dispatch, id]);

  useEffect(() => {
    if (id && isNaN(Number(id)) === false) {
      dispatch(getQuote(Number(id)));
      dispatch(getCounters(id));
      dispatch(getAreas(id));
      dispatch(getCutShapes(id));
      dispatch(getLabels(id));
      dispatch(getStoreSketchMaterials());
      dispatch(getStoreSketchColors());
    }
    return () => {
      dispatch(clearHistory());
      dispatch(clearStoreMaterials());
    }
  }, [dispatch, id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans}>
      <LoadingPage loading={!quote_loaded || !areas_loaded || !counters_loaded || !shapes_loaded || material_loading}>
        <StoreQuoteTabs undo={handleUndo} redo={handleRedo} quote={quote} current_area={current_area} setCurrentArea={setCurrentArea} />
      </LoadingPage>
    </motion.div>
  )
}
