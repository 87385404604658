/// TYPES ///
import { Area } from "api/types/quote";
import { Quote } from "api/types/quote";
import { QuoteFilter } from "api/types/quote";
import { QuoteLineItem } from "api/types/quote";
/// ACTIONS ///
import { Action } from "api/action_types/quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/quote";

interface State {
  quote: Quote,
  quotes: Quote[],
  filter: QuoteFilter,
  areas: Area[],
  quote_loaded: boolean,
  areas_loaded: boolean,
  open_message: boolean,
  updating: boolean,
  selected_line_item: QuoteLineItem | null,
  edit_line_item_open: boolean,
  error: string | null
}

const initialState = {
  quote: {},
  quotes: [],
  filter: {},
  areas: [],
  open_message: false,
  quote_loaded: false,
  areas_loaded: false,
  updating: false,
  selected_line_item: null,
  edit_line_item_open: false,
  error: null
}

export const quoteReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        quotes: [action.payload, ...state.quotes],
        updating: false,
        error: ""
      }
    case ActionType.CLEAR_QUOTES:
      return {
        ...state,
        quotes: []
      }
    case ActionType.CLEAR_QUOTE:
      return {
        ...state,
        quote: {},
        areas: [],
        quote_loaded: false,
        areas_loaded: false
      }
    case ActionType.MARK_QUOTE_CHANGE:
      return {
        ...state,
        quote: { ...state.quote, changed: true }
      }
    case ActionType.SET_QUOTE_FILTER:
      return {
        ...state,
        filter: action.filter
      }
    case ActionType.GET_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.payload,
        quote_loaded: true,
        error: ""
      }
    case ActionType.GET_AREAS_SUCCESS:
      return {
        ...state,
        areas: action.payload,
        areas_loaded: true
      }
    case ActionType.CREATE_AREA:
      return {
        ...state,
        areas: [...state.areas, action.payload],
        updating: false
      }
    case ActionType.GET_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: action.payload,
        quote_loaded: true,
        error: ""
      }
    case ActionType.UPDATE_QUOTE_SUCCESS:
      return {
        ...state,
        updating: false,
        quote: action.payload,
        error: ""
      }
    case ActionType.DELETE_QUOTE_SUCCESS:
      return {
        ...state,
        quotes: [...state.quotes.filter(item => item.id !== action.payload)],
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_LOCAL_QUOTE:
      return {
        ...state,
        quote: action.payload
      }
    case ActionType.SET_END_CUSTOMER_ON_QUOTE:
      return {
        ...state,
        quote: { ...state.quote, customer_data: action.customer, customer: action.customer.id }
      }
    case ActionType.SET_FABRICATION_JOB_ON_QUOTE:
      return {
        ...state,
        quote: {
          ...state.quote,
          job: action.job ? action.job.id : null,
          job_data: action.job
        }
      }
    case ActionType.CREATE_LOCAL_AREA:
      return {
        ...state,
        areas: [...state.areas, action.payload],
      }
    case ActionType.UPDATE_LOCAL_AREA:
      return {
        ...state,
        areas: [...state.areas.map(area => area.uuid === action.payload.uuid ? { ...area, ...action.payload } : area)],
      }
    case ActionType.DELETE_LOCAL_AREA:
      return {
        ...state,
        areas: [...state.areas.filter(area => area.uuid !== action.payload)],
      }
    case ActionType.CREATE_SLAB_SUCCESS:
      return state;
    case ActionType.DELETE_SLAB_SUCCESS:
      return state;
    case ActionType.CREATE_LOCAL_SLAB:
      return {
        ...state,
        quote: { ...state.quote, changed: true },
        areas: [...state.areas.map(area => area.uuid === action.payload.area_uuid ?
          { ...area, slabs: [...area.slabs, action.payload] }
          : area)]
      }
    case ActionType.DELETE_LOCAL_SLAB:
      return {
        ...state,
        quote: { ...state.quote, changed: true },
        areas: [...state.areas.map(area => area.uuid === action.payload.area_uuid ?
          { ...area, slabs: [...area.slabs.filter(slab => slab.uuid !== action.payload.uuid)] } : area)]
      }
    case ActionType.CREATE_LOCAL_COLOR_OPTION:
      return {
        ...state,
        areas: [...state.areas.map(area => area.uuid === action.payload.area_uuid ?
          { ...area, color_options: [...area.color_options, action.payload] } :
          area
        )]
      }
    case ActionType.UPDATE_LOCAL_COLOR_OPTION:
      return {
        ...state,
        areas: [...state.areas.map(area => area.uuid === action.payload.area_uuid ?
          {
            ...area, color_options: [...area.color_options.map(color_option => color_option.uuid === action.payload.uuid ?
              action.payload :
              color_option
            )]
          } :
          area
        )]
      }
    case ActionType.DELETE_LOCAL_COLOR_OPTION:
      return {
        ...state,
        areas: [...state.areas.map(area => area.uuid === action.payload.area_uuid ?
          { ...area, color_options: [...area.color_options.filter(color_option => color_option.uuid !== action.payload.uuid)] } :
          area
        )]
      }
    case ActionType.CREATE_ADDON:
      return {
        ...state,
        quote: {
          ...state.quote,
          addons: [...state.quote.addons, action.addon]
        }
      }
    case ActionType.UPDATE_ADDON:
      return {
        ...state,
        quote: {
          ...state.quote,
          addons: [...state.quote.addons.map(adn => adn.id === action.addon.id ? action.addon : adn)]
        }
      }
    case ActionType.DELETE_ADDON:
      return {
        ...state,
        quote: {
          ...state.quote,
          addons: [...state.quote.addons.filter(adn => adn.id !== action.addon.id)]
        }
      }
    case ActionType.SET_SELECTED_LINE_ITEM:
      return {
        ...state,
        selected_line_item: action.payload
      }
    case ActionType.OPEN_MESSAGE:
      return {
        ...state,
        open_message: action.open
      }
    case ActionType.QUOTE_UPDATING:
      return {
        ...state,
        updating: true
      }
    case ActionType.QUOTE_ACTION_SUCCESS:
      return {
        ...state,
        quote: { ...state.quote, changed: true },
        updating: false,
        error: ""
      }
    case ActionType.QUOTE_ERROR:
      return {
        ...state,
        updating: false,
        error: action.payload
      }
    default:
      return state;
  }
}
