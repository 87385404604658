import { Signature } from "api/types/signature";

export enum ActionType {
    CREATE_SIGNATURE_SUCCESS="CREATE_SIGNATURE_SUCCESS",
    SIGNATURE_PENDING="SIGNATURE_PENDING",
    SIGNATURE_ERROR="SIGNATURE_ERROR",
    OPEN_MESSAGE="OPEN_SIGNATURE_MESSAGE"
}

interface ICreateSignature {
    type: ActionType.CREATE_SIGNATURE_SUCCESS,
    signature: Signature
}

interface ISignaturePending {
    type: ActionType.SIGNATURE_PENDING
}

interface ISignatureError {
    type: ActionType.SIGNATURE_ERROR,
    error: string
}

interface IOpenMessage {
    type: ActionType.OPEN_MESSAGE,
    open: boolean
}

export type Action = ICreateSignature | ISignaturePending | ISignatureError | IOpenMessage;