/// TYPES ///
import { CSSProperties } from "react";

export const header_style: CSSProperties = {
  backgroundColor: "var(--mui-palette-primary-main)",
  height: "11px",
  width: "100%",
  marginBottom: "5px",
  marginTop: "5px"
}
