import { ColorOption } from "api/types/quote";

export enum ActionType {
    CREATE_LOCAL_COLOR_OPTION="CREATE_LOCAL_COLOR_OPTION",
    UPDATE_LOCAL_COLOR_OPTION="UPDATE_LOCAL_COLOR_OPTION",
    DELETE_LOCAL_COLOR_OPTION="DELETE_LOCAL_COLOR_OPTION"
}

interface ICreateLocalColorOption {
    type: ActionType.CREATE_LOCAL_COLOR_OPTION,
    payload: ColorOption
}

interface IUpdateLocalColorOption {
    type: ActionType.UPDATE_LOCAL_COLOR_OPTION,
    payload: ColorOption
}

interface IDeleteLocalColorOption {
    type: ActionType.DELETE_LOCAL_COLOR_OPTION,
    payload: ColorOption
}

export type Action = ICreateLocalColorOption | IUpdateLocalColorOption | IDeleteLocalColorOption;