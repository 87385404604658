import { Vector2 } from "api/types/sketch";

export function checkIndicatorSelected(
  location: Vector2,
  mouse: Vector2,
  selectable: boolean,
  rect: number = 15
): boolean {
  if (
    selectable &&
    mouse.X > location.X - rect &&
    mouse.X < location.X + rect &&
    mouse.Y > location.Y - rect &&
    mouse.Y < location.Y + rect
  ) {
    return true;
  }
  return false;
}
