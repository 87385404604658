/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getQuote } from "api/actions/quotes/quote";
import { createFabricationJob, updateFabricationJob } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
import { updateLocalQuote } from "api/actions/quotes/quote";
/// TYPES ///
import { IFabricationJob } from "api/types/customer";
import { IFabricationJobError } from "api/types/customer";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
/// PAGE COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// COMPONENTS ///
import AddressAutocomplete from "components/universal/autocomplete/geo";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_preview } from "values/breadcrumbs/quote";
import { quote_update_job } from "values/breadcrumbs/quote";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { preview_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
/// VALIDATE ///
import { validateEmail } from "functions/value";

interface Props {
  base_url: string
}

export default function UpdateQuoteJob({ base_url }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, customer_id } = useParams();
  const { quote } = useAppSelector((state) => state.quote);
  const [job, setJob] = useState<IFabricationJob>({});
  const [errors, setErrors] = useState<IFabricationJobError>({});

  useEffect(() => {
    setJob(quote?.job_data ?? {});
  }, [quote?.job_data]);

  useEffect(() => {
    if (!quote?.id || quote.id.toString() !== id) {
      dispatch(getQuote(id));
    }
  }, [dispatch, id, quote?.id]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_preview.url.replace("base_url", base_url) },
      { ...quote_preview, url: quote_preview.url.replace("base_url", base_url).replace("id", id) },
      { ...quote_update_job, url: quote_update_job.url.replace("base_url", base_url).replace("id", id) }
    ]));
  }, [dispatch, id, base_url]);

  const saveString = (key: string, value: string) => {
    setJob({ ...job, [key]: value });
    setErrors({ ...errors, [`${key}_error`]: "", [`${key}_has_error`]: false });
  }

  const updateGeo = (label: string, lat: number, lng: number, address: string, city: string, province: string) => {
    setJob({
      ...job,
      geo_address: label,
      lat: lat,
      lng: lng,
      address: job?.address && job.address !== "" ? job.address : address,
      city: job?.city && job.city !== "" ? job.city : city,
      province: job?.province && job.province !== "" ? job.province : province
    });
  }

  const geoError = () => {
    setErrors({ ...errors, geo_error: "Unable to retrieve coordinates of this address.", geo_has_error: true });
  }

  const update = () => {
    const errors: IFabricationJobError = {};

    if (!job?.lat || job?.lat === 0 || !job?.lng || job?.lng === 0) {
      errors.geo_error = "Could not find coordinates for this address. Please retype the address and select it from the dropdown options.";
      errors.geo_has_error = true;
    }

    if (!validateEmail(job?.email)) {
      errors.email_error = "Must enter a valid email";
      errors.email_has_error = true;
    }

    setErrors(errors);
    if (errors?.email_has_error || errors?.geo_has_error) {
      return;
    }

    if (quote?.job) {
      dispatch(updateFabricationJob(job, quote.job));
    }
    else {
      dispatch(createFabricationJob({ ...job, end_customer: Number(customer_id) }, quote.id));
    }

    dispatch(updateLocalQuote({ ...quote, job_data: job }));
    navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${preview_url}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Update Job" size_lg={6} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${preview_url}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={update} text="Update" icon={faSave} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} lg={6}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <TextField
              value={job.name ?? ""}
              onChange={e => saveString("name", e.target.value)}
              error={errors?.name_has_error}
              helperText={errors?.name_error ?? ""}
              label="Name" />
            <TextField
              value={job?.email ?? ""}
              onChange={e => saveString("email", e.target.value)}
              error={errors?.email_has_error}
              helperText={errors?.email_error ?? ""}
              label="Email" />
            <TextField
              value={job?.phone_number ?? ""}
              onChange={e => saveString("phone_number", e.target.value)}
              label="Phone Number" />
            <AddressAutocomplete
              input_label="Site Location"
              label={job?.geo_address}
              error={errors?.geo_error ?? ""}
              has_error={errors?.geo_has_error}
              geoError={geoError}
              updateGeo={updateGeo} />
            <TextField
              value={job?.entry_notes ?? ""}
              onChange={e => saveString("entry_notes", e.target.value)}
              label="Notes" />
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}
