/// TYPES ///
import { IShopSink } from "api/types/shop_quote/sink";
import { Sink } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { getDistance } from "../math";
import { getDistantPoint } from "../math";
import { getAngle } from "../math";

export function moveSink(selected_sink: Sink, lift_point: Vector2, mouse: Vector2): Sink {
  const distance: number = getDistance(lift_point, mouse);
  const angle: number = getAngle(lift_point, mouse) + 90;

  const new_center: Vector2 = getDistantPoint(lift_point, angle, distance);

  return { ...selected_sink, location: new_center };
}

export function moveShopSink(selected_sink: IShopSink, lift_point: Vector2, mouse: Vector2): IShopSink {
  const distance: number = getDistance(lift_point, mouse);
  const angle: number = getAngle(lift_point, mouse) + 90;

  const new_center: Vector2 = getDistantPoint(lift_point, angle, distance);

  return { ...selected_sink, location: new_center };
}
