/// TYPES ///
import { OrderCounter } from "api/types/order";
import { DiagramProps } from "api/types/sketch";
import { Label } from "api/types/sketch";
/// PDF COMPONENTS ///
import { Canvas } from "@react-pdf/renderer";
/// STYLES ///
import { drawPrintOrderCounter } from "functions/sketch";
import { drawPrintOrderSink } from "functions/sketch";
import { drawPrintOrderCooktop } from "functions/sketch";
/// VALUES ///
import { getDiagramProps } from "functions/sketch/get/diagram";

interface Props {
  counters: OrderCounter[],
  labels: Label[]
}

export default function OrderPrintCounters({ counters }: Props) {
  const paintCanvas = (painter: any, availableWidth: number, availableHeight: number) => {
    const props: DiagramProps = getDiagramProps(counters, { X: Math.round(availableWidth), Y: availableHeight });

    painter.fontSize(7.5);

    for (let i = 0; i < counters.length; i++) {
      drawPrintOrderCounter(
        counters[i].corners,
        counters[i].points,
        painter,
        props.offset,
        props.zoom
      );

      drawPrintOrderCooktop(painter, counters[i], props.offset, props.zoom);
      drawPrintOrderSink(painter, counters[i], props.offset, props.zoom);
    }

    painter.stroke("#666666");
    return null;
  }
  return (
    <Canvas
      style={{ height: "100%", width: "100%" }}
      paint={paintCanvas} />
  );
}
