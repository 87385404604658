import { Vector2 } from "api/types/sketch";
import { Text } from "api/types/sketch";
import { TextLocation } from "api/types/sketch";
import { createTextLocation } from "./location";
import { getEdgeText } from "values/text/edge";
import { EDGE } from "values/text";
import { MITER } from "values/values";
import { SPLASH } from "values/values";
import { WATERFALL } from "values/values";

export function edgeText(
  midway_point: Vector2,
  distance: number,
  angle: number,
  edge_type: string,
  corner_uuid: string,
  counter_uuid: string,
  point_uuid: string,
  splash_height: number,
  finished_edge_type: string,
  show_edges_inside: boolean
): Text {

  let real_value = 0;
  if (edge_type === SPLASH || edge_type === MITER || edge_type === WATERFALL) {
    real_value = splash_height;
  }

  const text_location: TextLocation = createTextLocation(midway_point, angle, show_edges_inside);

  return {
    location: text_location.text_location,
    value: distance / 4,
    real_value: real_value,
    text: getEdgeText(edge_type, finished_edge_type),
    corner_uuid: corner_uuid,
    counter_uuid: counter_uuid,
    point_uuid: point_uuid,
    type: EDGE,
    angle: angle,
    mirror: false
  };
}
