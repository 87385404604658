/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// TYPES ///
import { Order } from "api/types/order";
import { SelfOrder } from "api/types/order";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
/// ICONS ///
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
/// STYLES///
import { header_style } from "styles/universal/section_header";
import { item_style } from "styles/quote_preview/section";
import { title_style } from "styles/quote_preview/section";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { wo_url } from "components/navigation/endpoints";

interface Props {
  order: Order | SelfOrder,
  title?: string,
  hideWO?: boolean
}

export default function EventOrder({ order, title, hideWO }: Props) {
  const navigate = useNavigate();

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item xs={6}>
        <Typography variant="h6" sx={title_style}>
          {title ?? "Work Order"}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {
            !hideWO ?
              <BaseButton text="View WO" icon={faClipboardCheck} clickEvent={() => navigate(`/${wo_url}/${detail_url}/${order?.id}`)} /> :
              null
          }
        </div>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Location</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          {order?.fabrication_job_data?.geo_address ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Site Number</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          {order?.fabrication_job_data?.phone_number ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Contact Email</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          {order?.fabrication_job_data?.email ?? ""}
        </Typography>
      </Grid>
    </Grid>
  )
}
