import { highlightCanvas } from "functions/sketch/context";

export function drawMeasurementIndicator(
    context: CanvasRenderingContext2D,
    color: string,
    path: Path2D,
    highlight: boolean
){
    if(highlight){
        highlightCanvas(context);
    }
    else{
        context.strokeStyle = color;
    }
    context.stroke(path);
}