/// MUI ///
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
/// ICONS ///
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
/// ICONS ///
import { Vector2 } from "api/types/sketch";
/// STYLES /// 
import { menuItemStyle } from "styles/menu";

interface Props {
  open: boolean,
  menu_location: Vector2,
  openProperties: () => void,
  handleRotateSink: (rotation: number) => void,
  duplicateSink: () => void,
  deleteSink: () => void
}

export default function SinkCutoutMenu(
  {
    open,
    menu_location,
    openProperties,
    handleRotateSink,
    duplicateSink,
    deleteSink
  }: Props
) {
  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{ top: menu_location.Y, left: menu_location.X }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={open}>
      <List>
        <ListItemButton onClick={openProperties} sx={menuItemStyle}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Properties
        </ListItemButton>
        <ListItemButton onClick={() => handleRotateSink(-22.5)} sx={menuItemStyle} id="menu_rotate_sink_left">
          <ListItemIcon id="menu_rotate_sink_left_icon">
            <RotateLeftIcon id="menu_rotate_sink_left_svg_icon" />
          </ListItemIcon>
          Rotate Sink Left
        </ListItemButton>
        <ListItemButton onClick={() => handleRotateSink(22.5)} sx={menuItemStyle} id="menu_rotate_sink_right">
          <ListItemIcon id="menu_rotate_sink_right_icon">
            <RotateRightIcon id="menu_rotate_sink_right_svg_icon" />
          </ListItemIcon>
          Rotate Sink Right
        </ListItemButton>
        <ListItemButton onClick={duplicateSink} sx={menuItemStyle} id="menu_duplicate_sink">
          <ListItemIcon id="menu_duplicate_sink_icon">
            <ContentCopyIcon id="menu_duplicate_sink_svg_icon" />
          </ListItemIcon>
          Duplicate Sink
        </ListItemButton>
        <ListItemButton onClick={deleteSink} sx={menuItemStyle} id="menu_delete_sink">
          <ListItemIcon id="menu_delete_sink_icon">
            <DeleteIcon id="menu_delete_sink_svg_icon" />
          </ListItemIcon>
          Delete Sink
        </ListItemButton>
      </List>
    </Popover>
  )
}
