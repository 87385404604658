/// TYPES ///
import { Area } from "api/types/quote";
import { Counter } from "api/types/sketch";
import { Label } from "api/types/sketch";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import DiagramCounterAreas from "./counter";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";

interface Props {
  areas: Area[],
  counters: Counter[],
  labels: Label[],
  counters_loaded: boolean
}

export default function DiagramAreasCounter({ areas, counters, labels, counters_loaded }: Props) {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={title_style}>
          Counters
        </Typography>
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
      </Grid>
      {areas.map(area => {
        return (
          <Grid key={area.id} container item xs={12} sx={{ padding: "5px" }} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2" color="primary" sx={item_style(false)}>
                <b>{area.name}:</b> {area?.material_name ?? ""} {area?.color_name ?? ""} {area?.material_thickness ?? ""}
              </Typography>
            </Grid>
            <DiagramCounterAreas
              area_uuid={area.uuid}
              counters={
                counters.filter((count: Counter) => count.area_uuid === area.uuid)
              }
              labels={labels.filter(label => label.area_uuid === area.uuid)}
              counters_loaded={counters_loaded} />
          </Grid>
        )
      })}
    </Grid>
  )
}
