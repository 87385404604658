import { Slab } from "api/types/quote";

export enum ActionType {
  CREATE_SLAB_SUCCESS = "CREATE_SLAB_SUCCESS",
  DELETE_SLAB_SUCCESS = "DELETE_SLAB_SUCCESS",
  CREATE_LOCAL_SLAB = "CREATE_LOCAL_SLAB",
  DELETE_LOCAL_SLAB = "DELETE_LOCAL_SLAB"
}

interface ICreateSlabSuccess {
  type: ActionType.CREATE_SLAB_SUCCESS,
  payload: Slab
}

interface IDeleteSlabSuccess {
  type: ActionType.DELETE_SLAB_SUCCESS,
  payload: Slab
}

interface ICreateLocalSlab {
  type: ActionType.CREATE_LOCAL_SLAB,
  payload: Slab
}

interface IDeleteLocalSlab {
  type: ActionType.DELETE_LOCAL_SLAB,
  payload: Slab
}

export type Action =
  ICreateSlabSuccess |
  IDeleteSlabSuccess |
  ICreateLocalSlab |
  IDeleteLocalSlab;
