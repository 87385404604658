export enum ActionType {
  CLEAR_WO = "CLEAR_WO",
  CLEAR_WOS = "CLEAR_WOS"
}

interface IClearWO {
  type: ActionType.CLEAR_WO
}

interface IClearWOs {
  type: ActionType.CLEAR_WOS
}

export type Action = IClearWO | IClearWOs;
