import { Order } from "api/types/order";
import { IGetOrderArgs } from "api/types/order/order";

export enum ActionType {
  GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS",
  GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS",
  CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS",
  UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS",
  UPDATE_LOCAL_ORDER = "UPDATE_LOCAL_ORDER",
  RESET_ORDER_SUCCESS = "RESET_ORDER_SUCCESS",
  FILTER_ORDERS = "FILTER_ORDERS"
}

interface IGetOrdersSuccess {
  type: ActionType.GET_ORDERS_SUCCESS,
  payload: Order[]
}

interface IGetOrderSuccess {
  type: ActionType.GET_ORDER_SUCCESS,
  payload: Order
}

interface ICreateOrderSuccess {
  type: ActionType.CREATE_ORDER_SUCCESS,
  payload: Order
}

interface IUpdateOrderSuccess {
  type: ActionType.UPDATE_ORDER_SUCCESS
}

interface IUpdateLocalOrder {
  type: ActionType.UPDATE_LOCAL_ORDER,
  payload: Order
}

interface IResetOrderSuccess {
  type: ActionType.RESET_ORDER_SUCCESS
}

interface IFilterOrders {
  type: ActionType.FILTER_ORDERS,
  filter: IGetOrderArgs
}

export type Action = IGetOrdersSuccess | IGetOrderSuccess | ICreateOrderSuccess | IUpdateOrderSuccess | IUpdateLocalOrder | IResetOrderSuccess | IFilterOrders;
