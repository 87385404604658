/// TYPES ///
import { IShopBump } from "api/types/shop_quote/bump";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCorner } from "api/types/shop_quote/corner";

export function updateShopBump(counter: IShopCounter, bumps: IShopBump[]): IShopCounter {
  const new_corners: IShopCorner[] = [...counter.corners];
  if (bumps.length < 1) {
    return counter;
  }
  const corner_uuid: string = bumps[0].corner_uuid;

  for (let i = 0; i < new_corners.length; i++) {
    if (new_corners[i].uuid !== corner_uuid) {
      continue;
    }

    const corner: IShopCorner = { ...new_corners[i] };
    const new_bumps: IShopBump[] = [
      ...corner.bumps.filter(bump => {
        for (let j = 0; j < bumps.length; j++) {
          if (bumps[j].uuid === bump.uuid) {
            return false;
          }
        }
        return true;
      }),
      ...bumps
    ]
    new_corners[i] = { ...corner, bumps: new_bumps };
    return { ...counter, corners: new_corners };
  }
  return counter;
}
