/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// TYPES ///
import { ICustomer } from "api/types/customer";
/// ACTIONS ///
import { filterCustomers } from "api/actions/end_customer";
import { getEndCustomers } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
/// EVENTS ///
import { ChangeEvent } from "react";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import { Grid } from "@mui/material";
import { Pagination } from "@mui/material";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import List from "components/universal/list";
import PaginationSelector from "components/universal/ui/pagination_selector";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// CUSTOMER COMPONENTS ///
import CustomerHeader from "./header";
import CustomerItem from "./row";
// FA COMPONENTS ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// FA ICONS ///
import { faEraser, faFilter } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { row_header_style } from "styles/universal/row";
import { title_style } from "styles/quote_preview/section";
/// VALUES ///
import { default_pagination_length } from "styles/table";
/// LODASH ///
import { filter } from "lodash";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { create_url } from "components/navigation/endpoints";
import { end_customer_url } from "components/navigation/endpoints";
import { filter_url } from "components/navigation/endpoints";
/// VALIDATE ///
import { JSONIsEmpty } from "functions/value";
/// BREADCRUMBS ///
import { cx_list } from "values/breadcrumbs/customer";

export default function CustomersList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { end_customers } = useAppSelector((state) => state.end_customer);
  const { filter: customer_filter } = useAppSelector((state => state.end_customer));
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [num_per_page, setNumPerPage] = useState<number>(default_pagination_length);
  const [filt_cxs, setFiltCxs] = useState<ICustomer[]>([]);

  useEffect(() => {
    setFiltCxs(
      filter(end_customers, function(cx: ICustomer) {
        if (
          cx.first_name.toLowerCase().includes(search.toLowerCase()) ||
          cx.last_name.toLowerCase().includes(search.toLowerCase()) ||
          cx.address.toLowerCase().includes(search.toLowerCase()) ||
          cx.city.toLowerCase().includes(search.toLowerCase()) ||
          cx.phone_number.toLowerCase().includes(search.toLowerCase()) ||
          cx.email.toLowerCase().includes(search.toLowerCase())
        ) {
          return cx;
        }
      }) as ICustomer[])
  }, [search, end_customers]);

  const changePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const searchKeyDown = (e: any) => {
    if (e.key === "Escape") {
      e.preventDefault();
      setSearch("");
    }
  }

  useEffect(() => {
    dispatch(setBreadcrumbs([cx_list]));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEndCustomers(customer_filter));
  }, [dispatch, customer_filter]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title="Customers" endProps={
          <Box display="flex" alignItems="center" flexDirection="row">
            {!JSONIsEmpty(customer_filter) ? <BaseButton text="Clear" icon={faEraser} clickEvent={() => dispatch(filterCustomers({}))} /> : null}
            <BaseButton text="Create" icon={faPlus} clickEvent={() => navigate(`/${end_customer_url}/${create_url}`)} />
            <BaseButton text="Filter" icon={faFilter} clickEvent={() => navigate(`/${end_customer_url}/${filter_url}`)} />
          </Box>
        } />
        < Grid container item xs={12}>
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={4} sx={row_header_style}>
            <Typography variant="h6" sx={{ ...title_style, paddingTop: "8px" }}>
              {filt_cxs.length} results
            </Typography>
            {
              end_customers.length === 2000 ?
                <Typography variant="body2" sx={{ marginLeft: "5px", marginBottom: "5px" }} color="error">
                  Limit of 2000 reached. Use filter to narrow down results.
                </Typography> :
                null
            }
          </Grid>
          <Grid item xs={8} sx={row_header_style}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <TextField
                label="Search Within Results"
                onKeyDown={searchKeyDown}
                value={search}
                size="small"
                sx={{ height: "30px" }}
                InputProps={{
                  style: { height: "30px" },
                  endAdornment: <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")}>
                      <FontAwesomeIcon size="xs" icon={search.length > 0 ? faXmark : faMagnifyingGlass} />
                    </IconButton>
                  </InputAdornment>
                }}
                onChange={e => setSearch(e.target.value)} />
            </Box>
          </Grid>
          <CustomerHeader />
          <List
            Item={CustomerItem}
            items={filt_cxs.slice((page - 1) * num_per_page, page * num_per_page)
            } />
        </Grid >
        <Grid item xs={1}></Grid>
        <Grid item xs={10} sx={{ marginTop: 3 }}>
          <Box display="flex" justifyContent="center">
            <Pagination
              onChange={changePage}
              count={
                filt_cxs.length > num_per_page ?
                  Math.ceil(filt_cxs.length / num_per_page) :
                  1
              } shape="rounded" />
          </Box>
        </Grid>
        <Grid item xs={1} sx={{ marginTop: 3 }}>
          <PaginationSelector num_per_page={num_per_page} setNumPerPage={(setNumPerPage)} />
        </Grid>
      </Grid>
    </motion.div>
  )
}

