import { Dispatch } from "redux";
import { Action } from "api/action_types/sketch";
import { ActionType } from "api/action_types/sketch";
import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";

export const restore = (counters: Counter[], cut_shapes: CutShape[]) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.RESTORE,
            counters,
            cut_shapes
        });
    }
}