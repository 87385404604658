/// HOOKS ///
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import useDrawSlab from "hooks/draw_slab";
/// TYPES ///
import { Area } from "api/types/quote";
import { CutShape } from "api/types/sketch";
import { Slab } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopSlab } from "api/types/shop_quote/slab";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
/// SKETCH FUNCTIONS ///
import { getCanvas } from "functions/sketch/context";
/// STYLES ///
import { shape_container_style } from "styles/quote_preview/sketch";

interface Props {
  index: number,
  area: Area | IShopArea,
  cut_shapes: CutShape[] | IShopCutShape[],
  slab: Slab | IShopSlab,
  shapes_loaded: boolean
}

export default function DiagramSlab({ index, area, cut_shapes, slab, shapes_loaded }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const canvas_id = `slab_canvas_${slab.uuid}`;
  const [load_drawing, setLoadDrawing] = useState<boolean>(false);

  useDrawSlab(index, area, cut_shapes, slab, load_drawing && shapes_loaded);

  useEffect(() => {
    if (ref && ref.current) {
      const height: number = ref.current.clientHeight;
      const width: number = ref.current.clientWidth;
      const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById(canvas_id));

      if (canvas) {
        canvas.width = width;
        canvas.height = height;
      }

      setLoadDrawing(true);
    }
  }, [ref, canvas_id]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Box sx={shape_container_style} ref={ref}>
        <canvas id={canvas_id} />
      </Box>
    </Grid>
  )
}
