import { Dispatch } from "redux";
import { Action } from "api/action_types/quote";
import { ActionType } from "api/action_types/quote";

export const clearQuote = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_QUOTE
    });
  }
}

export const clearQuotes = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_QUOTES
    });
  }
}
