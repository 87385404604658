import { CornerData } from "api/types/sketch";
import { CornerRecessedDiagonal } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { makeAnglePositive } from "functions/sketch/math";
import { constructRecessedDiagonalCorner } from "../../corner/construct";

export function drawPrintCutRecessedDiagonalCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number
): CornerData {
    const angle_A: number = makeAnglePositive(getAngle(previous_location, current_location) - 180, false);
    const angle_B: number = makeAnglePositive(getAngle(current_location, next_location) - 180, false);
    const inward_angle: number = Math.round((angle_A + angle_B) / 2 - (angle_A < angle_B ? 180 : 0));
    const corner_recessed_diagonal: CornerRecessedDiagonal = constructRecessedDiagonalCorner(
        previous_location,
        current_location,
        next_location,
        inward_angle,
        corner_length * zoom,
        corner_depth * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_recessed_diagonal.A;
        painter.moveTo(corner_recessed_diagonal.A.X, corner_recessed_diagonal.A.Y);
    }
    else{
        painter.lineTo(corner_recessed_diagonal.A.X, corner_recessed_diagonal.A.Y);
    }

    painter.lineTo(corner_recessed_diagonal.B.X, corner_recessed_diagonal.B.Y);
    painter.lineTo(corner_recessed_diagonal.C.X, corner_recessed_diagonal.C.Y);
    painter.lineTo(corner_recessed_diagonal.D.X, corner_recessed_diagonal.D.Y);
    
    return {
        first_point: first_point,
        last_point: corner_recessed_diagonal.D
    }
}