/// TYPES ///
import { Area } from "api/types/quote";
import { CutShape } from "api/types/sketch";
/// COMPONENTS ///
import QuotePrintSlabs from "./slabs";
/// PDF ///
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
/// STYLES ///
import { print_header } from "styles/print";
import { subtitle } from "styles/print";

interface Props {
  areas: Area[],
  cut_shapes: CutShape[]
}

export default function QuotePrintShapeAreas({ areas, cut_shapes }: Props) {
  return (
    <View style={{ width: "100%" }} break>
      <View style={{ paddingLeft: "10px" }}>
        <Text style={{ ...subtitle, textTransform: "uppercase" }}>
          Slabs
        </Text>
      </View>
      <View style={print_header}></View>
      {areas.map((area, index) => <QuotePrintSlabs key={index} shapes={cut_shapes} area={area} />)}
    </View>
  );
}
