/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { deleteArea } from "api/actions/quotes/area";
import { updateLocalCutShapesByArea } from "api/actions/sketch/cut_shape";
/// TYPES ///
import { Area } from "api/types/quote";
import { CutShape } from "api/types/sketch";
/// COMPONENTS ///
import DeleteArea from "components/quote/dialog/area/delete";

interface Props {
  area: Area,
  open: boolean,
  setOpen: (open: boolean) => void,
  current_area: number,
  setCurrentArea: (area: number) => void
}

export default function DeleteStoreArea({ area, open, setOpen, current_area, setCurrentArea }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.quote);
  const { cut_shapes } = useAppSelector((state) => state.sketch);

  const remove = () => {
    setOpen(false);
    if (current_area === areas?.length - 1) {
      setCurrentArea(0);
    }

    if (areas[0] && areas[0]?.slabs[0]) {
      const update_shapes: CutShape[] = [];
      for (let i = 0; i < cut_shapes.length; i++) {
        if (cut_shapes[i]?.area_uuid === area?.uuid) {
          update_shapes.push({
            ...cut_shapes[i],
            area_uuid: areas[0]?.uuid,
            slab_uuid: areas[0]?.slabs[0]?.uuid
          });
        }
      }
      dispatch(updateLocalCutShapesByArea(update_shapes, area?.uuid));
    }
    dispatch(deleteArea(area));
  }

  return (
    <DeleteArea
      name={area?.name ?? ""}
      open={open}
      setOpen={setOpen}
      remove={remove} />
  )
}
