import { Dispatch } from "redux";
import { Action } from "api/action_types/sketch";
import { ActionType } from "api/action_types/sketch";
import { Bump } from "api/types/sketch";
import { CutBump } from "api/types/sketch";

export const createLocalCutBump = (bump: Bump, cut_bump: CutBump) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CREATE_LOCAL_CUT_BUMP,
      payload: cut_bump,
      bump
    });
  }
}
