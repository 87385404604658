import { CutShape } from "api/types/sketch";
import { CutCorner } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { CornerData } from "api/types/sketch";
import { drawCutCorner } from "../cut_corner";
import { drawCutBumps } from "../bumps";
import { adjustVectorToScale } from "../scale";
import { drawFirstHalfCutCorner } from "../cut_corner";
import { drawSecondHalfCutCorner } from "../cut_corner";
import { getAngle } from "../../math";
import { SLAB_ZOOM } from "values/values";
import { getCounterColor } from "functions/sketch/get";
import { error_color } from "styles/style";

export function drawCutShapes(
  context: CanvasRenderingContext2D,
  color: number,
  cut_shapes: CutShape[]
) {
  for (let i = 0; i < cut_shapes.length; i++) {
    drawCutShape(context, color, cut_shapes[i]);
  }
}

function drawCutShape(
  context: CanvasRenderingContext2D,
  color: number,
  cut_shape: CutShape
) {
  const fitted: boolean = cut_shape.fitted;
  let first_location: Vector2 = { X: 0, Y: 0 };
  const path: Path2D = new Path2D();

  for (let i = 0; i < cut_shape.cut_corners.length; i++) {
    const previous_corner: CutCorner =
      cut_shape.cut_corners[i - 1] ?
        cut_shape.cut_corners[i - 1] :
        cut_shape.cut_corners[cut_shape.cut_corners.length - 1];
    const corner: CutCorner = cut_shape.cut_corners[i];
    const next_corner: CutCorner =
      cut_shape.cut_corners[i + 1] ? cut_shape.cut_corners[i + 1] : cut_shape.cut_corners[0];
    const previous_location: Vector2 =
      adjustVectorToScale({ X: previous_corner.location.X, Y: previous_corner.location.Y }, SLAB_ZOOM);
    const location: Vector2 =
      adjustVectorToScale({ X: corner.location.X, Y: corner.location.Y }, SLAB_ZOOM);
    const next_location: Vector2 =
      adjustVectorToScale({ X: next_corner.location.X, Y: next_corner.location.Y }, SLAB_ZOOM);

    let corner_data: CornerData | null = null;
    if (!corner.is_corner_seam) {
      corner_data = drawCutCorner(
        i,
        path,
        corner.corner_type,
        previous_location,
        location,
        next_location,
        SLAB_ZOOM,
        corner.corner_radius,
        corner.corner_length,
        corner.corner_depth
      );
    }
    else {
      if (corner.corner_before_seam) {
        corner_data = drawFirstHalfCutCorner(
          i,
          path,
          corner.corner_type,
          previous_location,
          location,
          next_location,
          SLAB_ZOOM,
          corner.corner_radius,
          corner.corner_length,
          corner.corner_depth
        );
      }
      else {
        corner_data = drawSecondHalfCutCorner(
          i,
          path,
          corner.corner_type,
          previous_location,
          location,
          next_location,
          SLAB_ZOOM,
          corner.corner_radius,
          corner.corner_length,
          corner.corner_depth
        )
      }
    }

    if (i === 0) {
      if (corner_data && corner_data.first_point) {
        first_location = corner_data.first_point;
      }
      else {
        first_location = location;
      }
    }

    drawCutBumps(
      path,
      cut_shape.cut_corners[i].cut_bumps,
      location,
      next_location,
      getAngle(location, next_location),
      SLAB_ZOOM
    );
  }
  path.lineTo(first_location.X, first_location.Y);
  context.strokeStyle = getCounterColor(color);
  context.lineWidth = 2;

  if (fitted) {
    context.fillStyle = getCounterColor(color);
  }
  else {
    context.fillStyle = error_color;
  }
  context.fill(path);
  context.stroke(path);

}
