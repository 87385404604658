import { OrderCounter } from "api/types/order";

export enum ActionType {
  COUNTERS_LOADING = "ORDER_COUNTERS_LOADING",
  GET_ORDER_COUNTERS_SUCCESS = "GET_ORDER_COUNTERS_SUCCESS"
}

interface ICountersLoading {
  type: ActionType.COUNTERS_LOADING
}

interface IGetOrderCountersSuccess {
  type: ActionType.GET_ORDER_COUNTERS_SUCCESS,
  payload: OrderCounter[]
}

export type Action = ICountersLoading | IGetOrderCountersSuccess;
