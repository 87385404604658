import { CornerData } from "api/types/sketch";
import { CornerRecessedDiagonal } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { constructRecessedDiagonalCorner } from "../../corner/construct";

export function drawSecondHalfCutRecessedDiagonalCorner(
    path: Path2D,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number
): CornerData {
    const cut_angle: number = Math.round((getAngle(previous_location, current_location) + 90) * 100) / 100;

    const corner_recessed_diagonal: CornerRecessedDiagonal = constructRecessedDiagonalCorner(
        previous_location,
        current_location,
        next_location,
        cut_angle - 180,
        corner_length * zoom,
        corner_depth * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_recessed_diagonal.middle;
        path.moveTo(corner_recessed_diagonal.middle.X, corner_recessed_diagonal.middle.Y);
    }
    else{
        path.lineTo(corner_recessed_diagonal.middle.X, corner_recessed_diagonal.middle.Y);
    }

    path.lineTo(corner_recessed_diagonal.C.X, corner_recessed_diagonal.C.Y);
    path.lineTo(corner_recessed_diagonal.D.X, corner_recessed_diagonal.D.Y);
    
    return {
        first_point: first_point,
        last_point: corner_recessed_diagonal.D
    }
}