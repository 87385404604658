import { Vector2 } from "api/types/sketch";
import { Text } from "api/types/sketch";
import { highlightCanvas } from "functions/sketch/context";
import { getTextDisplay } from "values/text/corner";
import { checkIndicatorSelected } from "functions/sketch/check/indicator";

export function drawText(
    context: CanvasRenderingContext2D,
    color: string,
    text: Text,
    highlight: boolean
){
    const distance_height: number = 9;
    const location: Vector2 = {
        X: Math.round(text.location.X),
        Y: Math.round(text.location.Y + distance_height / 2)
    }
    if(highlight){
        highlightCanvas(context);
        context.fillText(getTextDisplay(text), location.X, location.Y);
        context.strokeStyle = color;
        context.fillStyle = color;
    }
    else{
        context.strokeStyle = color;
        context.fillStyle = color;
        context.fillText(getTextDisplay(text), location.X, location.Y);
    }
}

export function drawTexts(
    context: CanvasRenderingContext2D,
    color: string,
    texts: Text[],
    mouse: Vector2,
    can_highlight: boolean
): Text | null {
    let has_highlight: boolean = false;
    let highlighted_text: Text | null = null;

    for(let i = 0; i < texts.length; i++){
        let selected: boolean = false;
        if(!has_highlight && can_highlight){
            selected = checkIndicatorSelected(texts[i].location, mouse, can_highlight);
        }
        drawText(
            context,
            color,
            texts[i],
            selected
        )
        if(selected){
            highlighted_text = texts[i];
        }
    }
    return highlighted_text;
}