import { CornerData } from "api/types/sketch";
import { CornerDiagonal } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { constructDiagonalCorner } from "../../corner/construct";

export function drawCutDiagonalCorner(
    path: Path2D,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    zoom: number
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_diagonal: CornerDiagonal = constructDiagonalCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom
    );
    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_diagonal.start_point;
        path.moveTo(corner_diagonal.start_point.X, corner_diagonal.start_point.Y);
    }
    else{
        path.lineTo(corner_diagonal.start_point.X, corner_diagonal.start_point.Y);
    }

    path.lineTo(corner_diagonal.end_point.X, corner_diagonal.end_point.Y);

    return {
        first_point: first_point,
        last_point: corner_diagonal.end_point
    }
}