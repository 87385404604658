/// TYPES ///
import { Bump } from "api/types/sketch";
/// COMPONENTS ///
import UpdateBumpRow from "components/quote/drawer/update_bump/row";

interface Props {
  index: number,
  expanded: boolean,
  toggle: (index: number) => void,
  bump: Bump,
  cancel: () => void,
  close: () => void,
  save: () => void,
  add: () => void,
  update: (bump: Bump, index: number) => void,
  remove: (index: number) => void
}

export default function UpdateStoreBumpRow({
  index,
  expanded,
  toggle,
  bump,
  cancel,
  close,
  save,
  add,
  update,
  remove
}: Props) {
  return (
    <UpdateBumpRow
      index={index}
      expanded={expanded}
      toggle={toggle}
      bump_type={bump?.bump_type ?? ""}
      setBumpType={bump_type => update({ ...bump, bump_type }, index)}
      distance={bump?.distance ?? 0}
      setDistance={distance => update({ ...bump, distance }, index)}
      width={bump?.width ?? 0}
      setWidth={width => update({ ...bump, width }, index)}
      depth={bump?.depth ?? 0}
      setDepth={depth => update({ ...bump, depth }, index)}
      left_side_angle={bump?.left_side_angle ?? 0}
      setLeftSideAngle={left_side_angle => update({ ...bump, left_side_angle }, index)}
      right_side_angle={bump?.right_side_angle ?? 0}
      setRightSideAngle={right_side_angle => update({ ...bump, right_side_angle }, index)}
      cancel={cancel}
      close={close}
      save={save}
      add={add}
      remove={remove}
    />
  );
}
