/// MUI ///
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
/// TYPES ///
import { Vector2 } from "api/types/sketch";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import OutletIcon from "@mui/icons-material/Outlet";
import { faSink } from "@fortawesome/free-solid-svg-icons";
import { faFireBurner } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { menuItemStyle } from "styles/menu";

interface Props {
  open: boolean,
  menu_location: Vector2,
  deleteCounter: () => void,
  duplicateCounter: () => void,
  addSinkCutout: () => void,
  addCooktopCutout: () => void,
  addOutletCutout: () => void
}

export default function AppliancesMenu(
  {
    open,
    menu_location,
    deleteCounter,
    duplicateCounter,
    addSinkCutout,
    addCooktopCutout,
    addOutletCutout
  }: Props
) {
  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{ top: menu_location.Y, left: menu_location.X }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={open}>
      <List>
        <ListItemButton onClick={addSinkCutout} sx={menuItemStyle} id="menu_add_sink_cutout">
          <ListItemIcon id="menu_add_sink_cutout_icon">
            <FontAwesomeIcon icon={faSink} size="lg" style={{ marginLeft: "2px" }} />
          </ListItemIcon>
          Add Sink Cutout
        </ListItemButton>
        <ListItemButton onClick={addCooktopCutout} sx={menuItemStyle} id="menu_add_cooktop_cutout">
          <ListItemIcon id="menu_add_cooktop_cutout">
            <ListItemIcon id="menu_add_sink_cutout_icon">
              <FontAwesomeIcon icon={faFireBurner} style={{ marginLeft: "2px" }} />
            </ListItemIcon>
          </ListItemIcon>
          Add Cooktop Cutout
        </ListItemButton>
        <ListItemButton onClick={addOutletCutout} sx={menuItemStyle} id="menu_add_outlet_cutout">
          <ListItemIcon id="menu_add_outlet_cutout_icon">
            <OutletIcon id="menu_add_outlet_cutout_svg_icon" />
          </ListItemIcon>
          Add Outlet Cutout
        </ListItemButton>
        <ListItemButton onClick={duplicateCounter} sx={menuItemStyle} id="menu_duplicate_counter">
          <ListItemIcon id="menu_duplicate_counter_icon">
            <ContentCopyIcon id="menu_duplicate_counter_svg_icon" />
          </ListItemIcon>
          Duplicate Counter
        </ListItemButton>
        <ListItemButton onClick={deleteCounter} sx={menuItemStyle} id="menu_delete_counter">
          <ListItemIcon id="menu_delete_counter_icon">
            <DeleteIcon id="menu_delete_counter_svg_icon" />
          </ListItemIcon>
          Delete Counter
        </ListItemButton>
      </List>
    </Popover>
  )
}
