import { OrderSlab } from "api/types/order";
import { SelfOrderSlab } from "api/types/order";
import { Vector2 } from "api/types/sketch";
import { drawPrintPreviewCutShape } from "./print";

export function drawOrderPrintCutShapes(
    painter: any,
    slab: OrderSlab | SelfOrderSlab,
    zoom: number,
    base_offset: Vector2,
    offset: Vector2
){
    for(let i = 0; i < slab.cut_shapes.length; i++){
        drawPrintPreviewCutShape(painter, slab.cut_shapes[i], zoom, base_offset, offset);
    }
}
