import { OrderCounter } from "api/types/order";
import { SelfOrderCounter } from "api/types/order";
import { Counter } from "api/types/sketch";
import { OrderSink } from "api/types/order/order";
import { SelfOrderSink } from "api/types/order/self_order";
import { Sink } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getBottomLeftVertex } from "../../math";
import { getTopRightVertex } from "../../math";
import { getTopLeftVertex } from "../../math";
import { getBottomRightVertex } from "../../math";
import { isPointInRectangle } from "../../math";
import { pointInQuad } from "../../math";
import { getDistantPoint } from "../../math";
import { describeOval } from "functions/sketch/path";
import { RECTANGLE } from "values/values";
import { OVAL } from "values/values";
import { DOUBLE } from "values/values";
import { THIRTY_OVER_SEVENTY } from "values/values";
import { SEVENTY_OVER_THIRTY } from "values/values";
import { SIXTY_OVER_FORTY } from "values/values";
import { FORTY_OVER_SIXTY } from "values/values";
import { UNDERMOUNT } from "values/values";
import { FARMER } from "values/values";
import { DROP_IN } from "values/values";
import { FAUCET_RADIUS } from "./data";
import { FAUCET_BETWEEN_WIDTH } from "./data";
import { Start } from "./data";

export function drawPrintOrderSink(
  painter: any,
  counter: OrderCounter | SelfOrderCounter | Counter,
  offset: Vector2,
  zoom: number
) {
  for (let j = 0; j < counter.sinks.length; j++) {
    const sink: OrderSink | SelfOrderSink | Sink = counter.sinks[j];
    const location: Vector2 = {
      X: Math.round(sink.location.X * zoom) + offset.X,
      Y: Math.round(sink.location.Y * zoom) + offset.Y
    }

    if (sink.shape === RECTANGLE) {
      createPrintRectangleSink(
        painter,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === OVAL) {
      createPrintOvalSink(
        painter,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === DOUBLE) {
      createPrintDoubleSink(
        painter,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === THIRTY_OVER_SEVENTY) {
      createPrintThirtySeventySink(
        painter,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === SEVENTY_OVER_THIRTY) {
      createPrintSeventyThirtySink(
        painter,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === FORTY_OVER_SIXTY) {
      createPrintFortySixtySink(
        painter,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === SIXTY_OVER_FORTY) {
      createPrintSixtyFortySink(
        painter,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
  }
}

function createPrintRectangle(
  painter: any,
  location: Vector2,
  angle: number,
  width: number,
  length: number,
  mouse: Vector2,
  zoom: number,
  has_selected: boolean,
  already_highlighted: boolean
): boolean {
  const start_point: Vector2 = getBottomLeftVertex(location, angle, width * 4 * zoom, length * 4 * zoom);
  const top_left: Vector2 = getTopLeftVertex(location, angle, width * 4 * zoom, length * 4 * zoom);
  const top_right: Vector2 = getTopRightVertex(location, angle, width * 4 * zoom, length * 4 * zoom);
  const bottom_right: Vector2 = getBottomRightVertex(location, angle, width * 4 * zoom, length * 4 * zoom);

  painter.moveTo(start_point.X, start_point.Y);
  painter.lineTo(top_left.X, top_left.Y);
  painter.lineTo(top_right.X, top_right.Y);
  painter.lineTo(bottom_right.X, bottom_right.Y);
  painter.lineTo(start_point.X, start_point.Y);

  return !has_selected && !already_highlighted &&
    pointInQuad(
      start_point,
      top_left,
      top_right,
      bottom_right,
      mouse
    );
}

function createPrintOval(
  painter: any,
  location: Vector2,
  angle: number,
  width: number,
  length: number,
  mouse: Vector2,
  zoom: number,
  has_selected: boolean,
  already_highlighted: boolean
): boolean {
  const adjusted_length: number = length * 2 * zoom;
  const adjusted_width: number = width * 2 * zoom;

  const oval_path: string = describeOval(
    location.X,
    location.Y,
    adjusted_length,
    adjusted_width,
    angle,
    angle + 89,
    angle + 90
  );

  painter.path(oval_path);

  return !has_selected && !already_highlighted &&
    isPointInRectangle(
      location,
      angle + 90,
      adjusted_length,
      adjusted_width,
      mouse
    );
}

function createPrintRectangleSink(
  painter: any,
  sink_type: string,
  location: Vector2,
  angle: number,
  width: number,
  length: number,
  faucet_hole_count: number,
  mouse: Vector2,
  zoom: number,
  has_selected: boolean,
  already_highlighted: boolean
): boolean {
  const highlight: boolean = createPrintRectangle(painter, location, angle, width, length, mouse, zoom, has_selected, already_highlighted);
  if (sink_type === UNDERMOUNT) {
    createPrintFaucet(painter, getDistantPoint(location, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }
  else if (sink_type === DROP_IN) {
    const faucet_location: Vector2 = getDistantPoint(location, angle + 90, width / 3);
    createPrintRectangle(painter, faucet_location, angle, width / 1.5, length / 1.15, mouse, zoom, has_selected, already_highlighted);
    createPrintFaucet(painter, location, zoom);
  }
  else if (sink_type === FARMER) {
    createPrintFaucet(painter, getDistantPoint(location, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);

    const start_inset: Vector2 = getBottomLeftVertex(location, angle, (width - 2.25) * 4 * zoom, length * 4 * zoom);
    const end_inset: Vector2 = getTopLeftVertex(location, angle, (width - 2.25) * 4 * zoom, length * 4 * zoom);
    painter.moveTo(start_inset.X, start_inset.Y);
    painter.lineTo(end_inset.X, end_inset.Y);
  }

  return highlight;
}

function createPrintOvalSink(
  painter: any,
  sink_type: string,
  location: Vector2,
  angle: number,
  width: number,
  length: number,
  faucet_hole_count: number,
  mouse: Vector2,
  zoom: number,
  has_selected: boolean,
  already_highlighted: boolean
): boolean {
  const highlight: boolean = createPrintOval(painter, location, angle, width, length, mouse, zoom, has_selected, already_highlighted);
  if (sink_type === UNDERMOUNT) {
    createPrintFaucet(painter, getDistantPoint(location, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }
  else if (sink_type === DROP_IN) {
    const faucet_location: Vector2 = getDistantPoint(location, angle + 90, width / 3);
    const circle_start: Vector2 = getDistantPoint(faucet_location, 90, length / 1.15 * 2 * zoom);
    painter.moveTo(circle_start.X, circle_start.Y);
    createPrintOval(painter, faucet_location, angle, width / 1.5, length / 1.15, mouse, zoom, has_selected, already_highlighted);
    createPrintFaucet(painter, location, zoom);
  }

  return highlight;
}

function createPrintDoubleSink(
  painter: any,
  sink_type: string,
  location: Vector2,
  angle: number,
  width: number,
  length: number,
  faucet_hole_count: number,
  mouse: Vector2,
  zoom: number,
  has_selected: boolean,
  already_highlighted: boolean
): boolean {
  const highlight: boolean = createPrintRectangle(painter, location, angle, width, length, mouse, zoom, has_selected, already_highlighted);

  if (sink_type === UNDERMOUNT) {
    const location_A: Vector2 = getDistantPoint(location, angle, length * zoom);
    const location_B: Vector2 = getDistantPoint(location, angle - 180, length * zoom);
    createPrintRectangle(painter, location_A, angle, width, length / 2, mouse, zoom, false, true);
    createPrintRectangle(painter, location_B, angle, width, length / 2, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }
  else if (sink_type === DROP_IN) {
    const location_A: Vector2 = getDistantPoint(location, angle, length * zoom);
    const location_B: Vector2 = getDistantPoint(location, angle - 180, length * zoom);

    const A: Vector2 = getDistantPoint(location_A, angle + 90, width / 3);
    const B: Vector2 = getDistantPoint(location_B, angle + 90, width / 3);
    createPrintRectangle(painter, A, angle, width / 1.5, length / 2 / 1.15, mouse, zoom, false, true);
    createPrintRectangle(painter, B, angle, width / 1.5, length / 2 / 1.15, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(B, angle + 90, width * 0.4), zoom);
  }
  else if (sink_type === FARMER) {
    let location_A: Vector2 = getDistantPoint(location, angle, (length + 2) * zoom);
    location_A = getDistantPoint(location_A, angle + 90, 2);
    let location_B: Vector2 = getDistantPoint(location, angle - 180, (length + 2) * zoom);
    location_B = getDistantPoint(location_B, angle + 90, 2);
    createPrintRectangle(painter, location_A, angle, (width - 1.5), (length / 2) - 1, mouse, zoom, false, true);
    createPrintRectangle(painter, location_B, angle, (width - 1.5), (length / 2) - 1, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }

  return highlight;
}

function createPrintSixtyFortySink(
  painter: any,
  sink_type: string,
  location: Vector2,
  angle: number,
  width: number,
  length: number,
  faucet_hole_count: number,
  mouse: Vector2,
  zoom: number,
  has_selected: boolean,
  already_highlighted: boolean
): boolean {
  const location_A: Vector2 = getDistantPoint(location, 0, 0);
  const location_B: Vector2 = getDistantPoint(location, 0, 0);
  const left_highlight: boolean = createPrintRectangle(painter, location_A, angle, width, length, mouse, zoom, has_selected, already_highlighted);
  const right_highlight: boolean = createPrintRectangle(painter, location_B, angle, width, length, mouse, zoom, has_selected, already_highlighted);

  if (sink_type === UNDERMOUNT) {
    const location_A: Vector2 = getDistantPoint(location, angle, length * 0.85 * zoom);
    const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 1.1 * zoom);

    const A: Vector2 = getDistantPoint(location_A, angle + 90, width / 2);
    const B: Vector2 = getDistantPoint(location_B, angle + 90, -width / 2);
    createPrintRectangle(painter, location_A, angle, width * 0.9, length * 0.5, mouse, zoom, false, true);
    createPrintRectangle(painter, B, angle, width * 0.65, length * 0.4, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(B, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }
  else if (sink_type === DROP_IN) {
    const location_A: Vector2 = getDistantPoint(location, angle, length * 0.85 * zoom);
    const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 1.1 * zoom);

    const A: Vector2 = getDistantPoint(location_A, angle + 90, width / 2);
    const B: Vector2 = getDistantPoint(location_B, angle + 90, -width / 2);
    createPrintRectangle(painter, location_A, angle, width * 0.9, length * 0.5, mouse, zoom, false, true);
    createPrintRectangle(painter, B, angle, width * 0.65, length * 0.4, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(B, angle + 90, width * 0.4), zoom);
  }
  else if (sink_type === FARMER) {
    let location_A: Vector2 = getDistantPoint(location, angle, (length + 4) * zoom);
    location_A = getDistantPoint(location_A, angle + 90, 2);
    let location_B: Vector2 = getDistantPoint(location, angle - 180, (length - 2) * zoom);
    location_B = getDistantPoint(location_B, angle + 90, 2);
    createPrintRectangle(painter, location_A, angle, (width - 1.5), (length / 2) - 2, mouse, zoom, false, true);
    createPrintRectangle(painter, location_B, angle, (width - 1.5), (length / 2) + 1, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }

  return left_highlight || right_highlight;
}

function createPrintFortySixtySink(
  painter: any,
  sink_type: string,
  location: Vector2,
  angle: number,
  width: number,
  length: number,
  faucet_hole_count: number,
  mouse: Vector2,
  zoom: number,
  has_selected: boolean,
  already_highlighted: boolean
): boolean {
  const location_A: Vector2 = getDistantPoint(location, 0, 0);
  const location_B: Vector2 = getDistantPoint(location, 0, 0);
  const left_highlight: boolean = createPrintRectangle(painter, location_A, angle, width, length, mouse, zoom, has_selected, already_highlighted);
  const right_highlight: boolean = createPrintRectangle(painter, location_B, angle, width, length, mouse, zoom, has_selected, already_highlighted);

  if (sink_type === UNDERMOUNT) {
    const location_A: Vector2 = getDistantPoint(location, angle, length * 1.1 * zoom);
    const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 0.85 * zoom);

    const A: Vector2 = getDistantPoint(location_A, angle - 90, width / 2);
    createPrintRectangle(painter, A, angle, width * 0.65, length * 0.4, mouse, zoom, false, true);
    createPrintRectangle(painter, location_B, angle, width * 0.9, length * 0.5, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }
  else if (sink_type === DROP_IN) {
    const location_A: Vector2 = getDistantPoint(location, angle, length * 1.1 * zoom);
    const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 0.85 * zoom);

    const A: Vector2 = getDistantPoint(location_A, angle - 90, width / 2);
    createPrintRectangle(painter, A, angle, width * 0.65, length * 0.4, mouse, zoom, false, true);
    createPrintRectangle(painter, location_B, angle, width * 0.9, length * 0.5, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
  }
  else if (sink_type === FARMER) {
    let location_A: Vector2 = getDistantPoint(location, angle, (length - 2) * zoom);
    location_A = getDistantPoint(location_A, angle + 90, 2);
    let location_B: Vector2 = getDistantPoint(location, angle - 180, (length + 4) * zoom);
    location_B = getDistantPoint(location_B, angle + 90, 2);
    createPrintRectangle(painter, location_A, angle, (width - 1.5), (length / 2) + 1, mouse, zoom, false, true);
    createPrintRectangle(painter, location_B, angle, (width - 1.5), (length / 2) - 2, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }

  return left_highlight || right_highlight;
}

function createPrintSeventyThirtySink(
  painter: any,
  sink_type: string,
  location: Vector2,
  angle: number,
  width: number,
  length: number,
  faucet_hole_count: number,
  mouse: Vector2,
  zoom: number,
  has_selected: boolean,
  already_highlighted: boolean
): boolean {
  const location_A: Vector2 = getDistantPoint(location, 0, 0);
  const location_B: Vector2 = getDistantPoint(location, 0, 0);
  const left_highlight: boolean = createPrintRectangle(painter, location_A, angle, width, length, mouse, zoom, has_selected, already_highlighted);
  const right_highlight: boolean = createPrintRectangle(painter, location_B, angle, width, length, mouse, zoom, has_selected, already_highlighted);

  if (sink_type === UNDERMOUNT) {
    const location_A: Vector2 = getDistantPoint(location, angle, length * 0.65 * zoom);
    const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 1.25 * zoom);

    const A: Vector2 = getDistantPoint(location_A, angle + 90, width / 2);
    const B: Vector2 = getDistantPoint(location_B, angle + 90, -width / 2);
    createPrintRectangle(painter, location_A, angle, width * 0.9, length * 0.6, mouse, zoom, false, true);
    createPrintRectangle(painter, B, angle, width * 0.65, length * 0.3, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(B, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }
  else if (sink_type === DROP_IN) {
    const location_A: Vector2 = getDistantPoint(location, angle, length * 0.65 * zoom);
    const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 1.25 * zoom);

    const A: Vector2 = getDistantPoint(location_A, angle + 90, width / 2);
    const B: Vector2 = getDistantPoint(location_B, angle + 90, -width / 2);
    createPrintRectangle(painter, location_A, angle, width * 0.9, length * 0.6, mouse, zoom, false, true);
    createPrintRectangle(painter, B, angle, width * 0.65, length * 0.3, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(B, angle + 90, width * 0.4), zoom);
  }
  else if (sink_type === FARMER) {
    let location_A: Vector2 = getDistantPoint(location, angle, (length + 8) * zoom);
    location_A = getDistantPoint(location_A, angle + 90, 2);
    let location_B: Vector2 = getDistantPoint(location, angle - 180, (length - 6) * zoom);
    location_B = getDistantPoint(location_B, angle + 90, 2);
    createPrintRectangle(painter, location_A, angle, (width - 1.5), (length / 2) - 4, mouse, zoom, false, true);
    createPrintRectangle(painter, location_B, angle, (width - 1.5), (length / 2) + 3, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }
  return left_highlight || right_highlight;
}

function createPrintThirtySeventySink(
  painter: any,
  sink_type: string,
  location: Vector2,
  angle: number,
  width: number,
  length: number,
  faucet_hole_count: number,
  mouse: Vector2,
  zoom: number,
  has_selected: boolean,
  already_highlighted: boolean
): boolean {
  const location_A: Vector2 = getDistantPoint(location, 0, 0);
  const location_B: Vector2 = getDistantPoint(location, 0, 0);
  const left_highlight: boolean = createPrintRectangle(painter, location_A, angle, width, length, mouse, zoom, has_selected, already_highlighted);
  const right_highlight: boolean = createPrintRectangle(painter, location_B, angle, width, length, mouse, zoom, has_selected, already_highlighted);

  if (sink_type === UNDERMOUNT) {
    const location_A: Vector2 = getDistantPoint(location, angle, length * 1.25 * zoom);
    const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 0.65 * zoom);

    const A: Vector2 = getDistantPoint(location_A, angle - 90, width / 2);
    createPrintRectangle(painter, A, angle, width * 0.65, length * 0.3, mouse, zoom, false, true);
    createPrintRectangle(painter, location_B, angle, width * 0.9, length * 0.6, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }
  else if (sink_type === DROP_IN) {
    const location_A: Vector2 = getDistantPoint(location, angle, length * 1.25 * zoom);
    const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 0.65 * zoom);

    const A: Vector2 = getDistantPoint(location_A, angle - 90, width / 2);
    createPrintRectangle(painter, A, angle, width * 0.65, length * 0.3, mouse, zoom, false, true);
    createPrintRectangle(painter, location_B, angle, width * 0.9, length * 0.6, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
  }
  else if (sink_type === FARMER) {
    let location_A: Vector2 = getDistantPoint(location, angle, (length - 6) * zoom);
    location_A = getDistantPoint(location_A, angle + 90, 2);
    let location_B: Vector2 = getDistantPoint(location, angle - 180, (length + 8) * zoom);
    location_B = getDistantPoint(location_B, angle + 90, 2);
    createPrintRectangle(painter, location_A, angle, (width - 1.5), (length / 2) + 3, mouse, zoom, false, true);
    createPrintRectangle(painter, location_B, angle, (width - 1.5), (length / 2) - 4, mouse, zoom, false, true);
    createPrintFaucet(painter, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
    createPrintFaucet(painter, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
    createPrintFaucets(painter, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
  }

  return left_highlight || right_highlight;
}

function createPrintFaucets(painter: any, location: Vector2, angle: number, width: number, num_holes: number, start_side: string, zoom: number) {
  let start_location: Vector2 = { X: 0, Y: 0 };
  if (start_side === Start.CENTER) {
    start_location = getDistantPoint(location, angle + 90, width / 2 * 4 * zoom + 8);
  }
  else if (start_side === Start.LEFT) {

  }
  else if (start_side === Start.RIGHT) {

  }

  const TO_NEXT: number = FAUCET_BETWEEN_WIDTH + FAUCET_RADIUS;
  const start: Vector2 = getDistantPoint(start_location, angle - 180, (TO_NEXT * (num_holes - 1) * zoom) / 2);

  for (let i = 0; i < num_holes; i++) {
    createPrintFaucet(painter, getDistantPoint(start, angle, i * TO_NEXT * zoom), zoom);
  }
}

function createPrintFaucet(painter: any, center: Vector2, zoom: number) {
  const adjust_faucet_radius: number = FAUCET_RADIUS * zoom;
  const circle_start: Vector2 = getDistantPoint(center, 90, adjust_faucet_radius);
  painter.moveTo(circle_start.X, circle_start.Y);
  painter.circle(center.X, center.Y, adjust_faucet_radius);
}
