/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";

export const clearShopQuote = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_SHOP_QUOTE
    });
  }
}

export const clearShopQuotes = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_SHOP_QUOTES
    });
  }
}
