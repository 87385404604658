/// TYPES ///
import { CSSProperties } from "react";

const icn_style: CSSProperties = {
  height: "16px",
  width: "16px",
  marginTop: "5px"
}

export const active_icn_style: CSSProperties = {
  ...icn_style,
  color: "var(--mui-palette-primary-main)",
}

export const inactive_icn_style: CSSProperties = {
  ...icn_style,
  color: "var(--mui-palette-error-main)",
}
