/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/quote";
/// TYPES ///
import { QuoteFilter } from "api/types/quote";

export const filterQuotes = (filter: QuoteFilter) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_QUOTE_FILTER,
      filter
    });
  }
}
