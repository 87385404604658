/// EVENTS ///
import { KeyboardEvent } from "react";
import { MouseEvent } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// VALUES ///
import { INNER_RADIUS } from "values/quote";
import { RECESSED_DIAGONAL } from "values/quote";
import { DIAGONAL } from "values/quote";
import { STANDARD } from "values/quote";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  corner_type: string,
  setCornerType: (corner_type: string) => void,
  corner_radius: number,
  setCornerRadius: (corner_radius: number) => void,
  radius_error: string,
  radius_has_error: boolean,
  corner_length: number,
  setCornerLength: (corner_length: number) => void,
  length_error: string,
  length_has_error: boolean,
  corner_depth: number,
  setCornerDepth: (corner_depth: number) => void,
  depth_error: string,
  depth_has_error: boolean,
  save: () => void,
  cancel: () => void
}

export default function UpdateInnerCorner({
  open,
  setOpen,
  corner_type,
  setCornerType,
  corner_radius,
  setCornerRadius,
  radius_error,
  radius_has_error,
  corner_length,
  setCornerLength,
  length_error,
  length_has_error,
  corner_depth,
  setCornerDepth,
  depth_error,
  depth_has_error,
  save,
  cancel
}: Props) {

  const toggleDrawer = () =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(!open);
    };

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      save();
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      cancel();
    }
  }

  return (
    <SwipeableDrawer
      BackdropProps={{ invisible: true }}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      onKeyDown={keyDown}
      anchor="right"
      open={open}>
      <Box display="flex" flexDirection="column" justifyContent="center" sx={{ paddingTop: "25px", paddingLeft: "50px", paddingRight: "50px", width: "300px", gap: "10px" }}>
        <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
          <IconButton onClick={cancel} onTouchStart={cancel}>
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Box>
        <Typography variant="h4" align="center" sx={{ marginTop: "50px" }}>
          Corner
        </Typography>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <RadioGroup
            sx={{ paddingLeft: 1, paddingRight: 1 }}
            aria-labelledby="edge-type-selection"
            value={corner_type}
            name="edge-type-selection"
            onChange={(e) => setCornerType(e.target.value)}>
            <FormControlLabel value={STANDARD} control={<Radio />} label="Standard" />
            <FormControlLabel value={INNER_RADIUS} control={<Radio />} label="Inner Radius" />
            <FormControlLabel value={RECESSED_DIAGONAL} control={<Radio />} label="Recessed Diagonal" />
            <FormControlLabel value={DIAGONAL} control={<Radio />} label="Diagonal" />
          </RadioGroup>
        </FormControl>
        {
          corner_type === INNER_RADIUS ?
            <TextField
              fullWidth
              label="Inner Radius"
              error={radius_has_error}
              helperText={radius_error ?? ""}
              type="number"
              onChange={e => setCornerRadius(Number(e.target.value))}
              value={corner_radius > 0 ? corner_radius : ""} /> :
            null
        }
        {
          corner_type === RECESSED_DIAGONAL || corner_type === DIAGONAL ?
            <TextField
              fullWidth
              sx={{ marginTop: "50px" }}
              label="Length (inches)"
              type="number"
              error={length_has_error}
              helperText={length_error ?? ""}
              onChange={e => setCornerLength(Number(e.target.value))}
              value={corner_length > 0 ? corner_length : ""} /> :
            null
        }
        {
          corner_type === RECESSED_DIAGONAL ?
            <TextField
              fullWidth
              sx={{ marginTop: "50px" }}
              label="Depth (inches)"
              error={depth_has_error}
              helperText={depth_error ?? ""}
              type="number"
              onChange={e => setCornerDepth(Number(e.target.value))}
              value={corner_depth ? corner_depth : ""} /> :
            null
        }
        <Button
          onClick={save}>
          Save
        </Button>
      </Box>
    </SwipeableDrawer>
  );
}
