/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// TYPES ///
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
/// URLS ///
import { url_prefix } from "settings";
import { shop_bulk_cut_shape_url } from "../../endpoints";

export const createBulkShopCutShapes = (cut_shapes: IShopCutShape[]) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.SHOP_QUOTE_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${shop_bulk_cut_shape_url}/`;

    try {
      await axios.post(url, cut_shapes, config);

      dispatch({
        type: ActionType.SHOP_QUOTE_ACTION_SUCCESS
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SHOP_QUOTE_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
