import { CornerData } from "api/types/sketch";
import { CornerClip } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { getDistance } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { getNextAngleOnFirstHalfCornerSeam } from "../seam";
import { constructClipCorner } from "../../corner/construct";

export function drawFirstHalfPrintCutClippedCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    zoom: number
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const cut_angle: number = getAngle(current_location, next_location) + 90;
    const next_angle: number = getNextAngleOnFirstHalfCornerSeam(cut_angle, angle);
    const corner_clip: CornerClip = constructClipCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_clip.start_point;
        painter.moveTo(corner_clip.start_point.X, corner_clip.start_point.Y)
    }
    else{
        painter.lineTo(corner_clip.start_point.X, corner_clip.start_point.Y);
    }

    const diagonal_angle: number = getAngle(corner_clip.start_point, corner_clip.end_point) + 90;
    const distance: number = getDistance(corner_clip.start_point, corner_clip.end_point);
    const halfway_point: Vector2 = getDistantPoint(corner_clip.start_point, diagonal_angle, distance / 2);

    painter.lineTo(halfway_point.X, halfway_point.Y);
    
    return {
        first_point: first_point,
        last_point: halfway_point
    }
}