/// TYPES ///
import { CSSProperties } from "react";
import { Variants } from "framer-motion";
import { TargetAndTransition } from "framer-motion";

export const act_btn_style: CSSProperties = {
  height: "30px",
  paddingLeft: "8px",
  paddingRight: "8px",
  borderRadius: 5,
  fontSize: "0.8rem"
}

export const act_btn_icon_style: CSSProperties = {
  marginBottom: "2px",
  height: "15px",
  width: "15px"
}

export const sort_btn_style: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  border: "none",
  alignItems: "center",
  backgroundColor: "var(--mui-palette-background-paper)",
  paddingLeft: "5px",
  paddingRight: 0,
  paddingBottom: "1px",
  paddingTop: "3px"
}

export const icon_style: CSSProperties = {
  color: "var(--mui-palette-text-primary)"
}

export const sort_btn_motion: Variants = {
  rest: {
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.2,
      type: "tween"
    }
  },
  hover: {
    opacity: 1,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeIn"
    }
  }
}

export const press_motion: TargetAndTransition = {
  scale: 0.9
}

export const sm_txt_btn_style: CSSProperties = {
  textTransform: "none",
  fontSize: 12,
  padding: 0,
  minWidth: 0,
  color: "var(--mui-palette-primary-light)"
}
