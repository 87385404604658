/// TYPES ///
import { Cooktop } from "api/types/sketch";
import { IShopCooktop } from "api/types/shop_quote/cooktop";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { getDistance } from "../math";
import { getDistantPoint } from "../math";
import { getAngle } from "../math";

export function moveCooktop(selected_cooktop: Cooktop, lift_point: Vector2, mouse: Vector2): Cooktop {
  const distance: number = getDistance(lift_point, mouse);
  const angle: number = getAngle(lift_point, mouse) + 90;

  const new_center: Vector2 = getDistantPoint(lift_point, angle, distance);

  return { ...selected_cooktop, location: new_center };
}

export function moveShopCooktop(selected_cooktop: IShopCooktop, lift_point: Vector2, mouse: Vector2): IShopCooktop {
  const distance: number = getDistance(lift_point, mouse);
  const angle: number = getAngle(lift_point, mouse) + 90;

  const new_center: Vector2 = getDistantPoint(lift_point, angle, distance);

  return { ...selected_cooktop, location: new_center };
}
