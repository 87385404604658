import { Vector2 } from "api/types/sketch";
import { Text } from "api/types/sketch";
import { clockwise } from "functions/sketch/math";
import { getAngle } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { CORNER } from "values/text";
import { INNER_RADIUS, RADIUS } from "values/quote";

export function cornerText(
  previous_vertex: Vector2,
  vertex: Vector2,
  next_vertex: Vector2,
  corner_type: string,
  corner_uuid: string,
  counter_uuid: string,
  point_uuid: string,
  length: number,
  depth: number,
  radius: number
): Text {
  const angle: number = getAngle(previous_vertex, vertex) - 180;
  const next_angle: number = getAngle(vertex, next_vertex) - 180;
  let text_location: Vector2 = getDistantPoint(vertex, angle, 10);
  text_location = getDistantPoint(text_location, next_angle, 10);
  let value = 0;
  let real_value = 0;

  if (corner_type === RADIUS || corner_type === INNER_RADIUS) {
    value = radius;
  }
  else {
    value = length;
    real_value = depth;
  }

  return {
    location: text_location,
    value: value,
    real_value: real_value,
    text: corner_type,
    corner_uuid: corner_uuid,
    counter_uuid: counter_uuid,
    point_uuid: point_uuid,
    type: CORNER,
    angle: angle,
    mirror: clockwise(angle, next_angle)
  };
}
