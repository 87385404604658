/// MUI ///
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
/// ICONS ///
import DeleteIcon from '@mui/icons-material/Delete';
/// TYPES ///
import { Vector2 } from "api/types/sketch";
/// STYLES ///
import { menuItemStyle } from "styles/menu";

interface Props {
  open: boolean,
  menu_location: Vector2,
  num_slabs: number,
  deleteSlab: () => void
}

export default function SlabMenu({
  open,
  menu_location,
  num_slabs,
  deleteSlab
}: Props) {
  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{ top: menu_location.Y, left: menu_location.X }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}>
      <List>
        <ListItemButton onClick={e => deleteSlab()} sx={menuItemStyle} id="menu_delete_slab" disabled={num_slabs < 2}>
          <ListItemIcon id="menu_delete_slab_icon">
            <DeleteIcon id="menu_delete_slab_svg_icon" />
          </ListItemIcon>
          {num_slabs > 1 ? "Delete Slab" : "Area must have 1 Slab"}
        </ListItemButton>
      </List>
    </Popover>
  )
}
