import { IShopQuote } from "api/types/shop_quote/quote";
import { IShopQuoteFilter } from "api/types/shop_quote/filter";
import { ICustomer } from "api/types/customer";
import { IFabricationJob } from "api/types/customer";

export enum ActionType {
  GET_SHOP_QUOTE = "GET_SHOP_QUOTE",
  GET_SHOP_QUOTES = "GET_SHOP_QUOTES",
  CREATE_SHOP_QUOTE = "CREATE_SHOP_QUOTE",
  UPDATE_SHOP_QUOTE = "UPDATE_SHOP_QUOTE",
  DELETE_SHOP_QUOTE = "DELETE_SHOP_QUOTE",
  UPDATE_LOCAL_SHOP_QUOTE = "UPDATE_LOCAL_SHOP_QUOTE",
  SEND_SHOP_QUOTE = "SEND_SHOP_QUOTE",
  CLEAR_SHOP_QUOTES = "CLEAR_SHOP_QUOTES",
  CLEAR_SHOP_QUOTE = "CLEAR_SHOP_QUOTE",
  MARK_SHOP_QUOTE_CHANGE = "MARK_SHOP_QUOTE_CHANGED",
  SET_SHOP_QUOTE_FILTER = "SET_SHOP_QUOTE_FILTER",
  SET_CUSTOMER_ON_SHOP_QUOTE = "SET_CUSTOMER_ON_SHOP_QUOTE",
  SET_JOB_ON_SHOP_QUOTE = "SET_JOB_ON_SHOP_QUOTE",
  SORT_SHOP_QUOTES = "SORT_SHOP_QUOTES"
}

interface IClearShopQuotes {
  type: ActionType.CLEAR_SHOP_QUOTES
}

interface IClearShopQuote {
  type: ActionType.CLEAR_SHOP_QUOTE
}

interface IGetShopQuoteSuccess {
  type: ActionType.GET_SHOP_QUOTE,
  payload: IShopQuote
}

interface IGetShopQuotesSuccess {
  type: ActionType.GET_SHOP_QUOTES,
  payload: IShopQuote[]
}

interface ICreateShopQuoteSuccess {
  type: ActionType.CREATE_SHOP_QUOTE,
  payload: IShopQuote
}

interface IUpdateShopQuoteSuccess {
  type: ActionType.UPDATE_SHOP_QUOTE,
  payload: IShopQuote
}

interface IDeleteShopQuoteSuccess {
  type: ActionType.DELETE_SHOP_QUOTE,
  payload: number
}

interface IUpdateLocalShopQuote {
  type: ActionType.UPDATE_LOCAL_SHOP_QUOTE,
  payload: IShopQuote
}

interface ISendShopQuoteSuccess {
  type: ActionType.SEND_SHOP_QUOTE
}

interface IMarkShopQuoteChanged {
  type: ActionType.MARK_SHOP_QUOTE_CHANGE
}

interface ISetShopQuoteFilter {
  type: ActionType.SET_SHOP_QUOTE_FILTER,
  filter: IShopQuoteFilter
}

interface ISetCustomerOnShopQuote {
  type: ActionType.SET_CUSTOMER_ON_SHOP_QUOTE,
  customer: ICustomer
}

interface ISetJobOnShopQuote {
  type: ActionType.SET_JOB_ON_SHOP_QUOTE,
  job: IFabricationJob
}

interface ISortShopQuotes {
  type: ActionType.SORT_SHOP_QUOTES,
  payload: IShopQuote[]
}

export type Action =
  IClearShopQuotes |
  IClearShopQuote |
  IGetShopQuoteSuccess |
  IGetShopQuotesSuccess |
  ICreateShopQuoteSuccess |
  IUpdateShopQuoteSuccess |
  IDeleteShopQuoteSuccess |
  IUpdateLocalShopQuote |
  ISendShopQuoteSuccess |
  IMarkShopQuoteChanged |
  ISetShopQuoteFilter |
  ISetCustomerOnShopQuote |
  ISetJobOnShopQuote |
  ISortShopQuotes;
