/// TYPES ///
import { ICustomer } from "api/types/customer";
import { ICustomerErrors } from "api/types/customer";
/// VALIDATE ///
import { validateEmail } from "functions/value";

export function checkSaveCustomer(customer: ICustomer): ICustomerErrors {
  let errors: ICustomerErrors = {};

  if (!customer) {
    return errors;
  }

  if (!customer.first_name || customer.first_name === "") {
    errors.first_name_error = "Enter a first name";
    errors.first_name_has_error = true;
  }

  if (!customer.last_name || customer.last_name === "") {
    errors.last_name_error = "Enter a last name";
    errors.last_name_has_error = true;
  }

  if (!customer.lat || !customer.lng) {
    errors.geo_error = "Could not geolocate this address. Please re-enter and choose from the dropdown options.";
    errors.geo_has_error = true;
  }

  if (!customer.email || !validateEmail(customer.email)) {
    errors.email_error = "Enter a valid email";
    errors.email_has_error = true;
  }

  return errors;
}
