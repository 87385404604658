/// FM ///
import { motion } from "framer-motion";
/// IMAGES ///
import LoadingAnim from "static/loading.gif";
/// STYLES ///
import { loading_container_style } from "styles/loading";
/// MOTION ///
import { loading_ico_motion } from "styles/loading";

interface Props {
  loading?: boolean
}

export default function Loading({ loading = true }: Props) {
  return (
    <motion.div style={loading_container_style} animate={loading ? "show" : "hide"} initial="initial" variants={loading_ico_motion}>
      <img src={LoadingAnim} width="40" height="40" alt="Loading..." />
    </motion.div>
  )

}
