/// TYPES ///
import { Area } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";

export function getAreaById(areas: Area[], area_id: number): Area | null {
  for (let i = 0; i < areas.length; i++) {
    if (areas[i].id === area_id) {
      return areas[i];
    }
  }
  return null;
}

export function getShopAreaById(areas: IShopArea[], area_id: number): IShopArea | null {
  for (let i = 0; i < areas.length; i++) {
    if (areas[i].id === area_id) {
      return areas[i];
    }
  }
  return null;
}
