/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useLocation } from "react-router-dom";
/// MUI ///
import Box from "@mui/material/Box";
/// FM ///
import { motion } from "framer-motion";
import { useScroll } from "framer-motion";
import { useSpring } from "framer-motion";
/// STYLES ///
import { scroll_color } from "styles/scroll";

export default function ScrollBar() {
  const location = useLocation();
  const [visible, setVisible] = useState<boolean>(true);
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  useEffect(() => {
    if (window?.innerHeight === document?.documentElement?.clientHeight) {
      setVisible(false);
    }
    else {
      setVisible(true);
    }

    /*window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });*/
  }, [location]);

  return (
    <motion.div
      style={{
        position: "fixed",
        height: "8px",
        top: 50,
        left: 50,
        right: 0,
        zIndex: 1000,
        scaleX
      }}>
      <Box sx={visible ? scroll_color : { width: "100%", height: "100%", backgroundColor: "#ffffff00" }}></Box>
    </motion.div>
  )
}
