/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getFabricatorColors } from "api/actions/material";
import { getMarketMaterial } from "api/actions/material";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { Pagination } from "@mui/material";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import ColorHeader from "components/material/header/color";
import Loading from "components/universal/loading";
import MarketColorItem from "./row/color";
import PageTitle from "components/universal/page/title";
import PaginationSelector from "components/universal/ui/pagination_selector";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// FM ///
import { motion } from "framer-motion";
/// ICON ///
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// TYPES ///
import { FabricatorColor } from "api/types/fabricator";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { row_header_style } from "styles/universal/row";
import { title_style } from "styles/universal/page";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// LODASH ///
import { filter } from "lodash";
/// VALUES ///
import { default_pagination_length } from "styles/table";
import { getMaterialHeightDisplay } from "values/material";
import { getMaterialTypeDisplay } from "values/material";
/// BREADCRUMBS ///
import { material_list } from "values/breadcrumbs/materials/market";
import { material_type } from "values/breadcrumbs/materials/market";
import { c_material } from "values/breadcrumbs/materials/market";

export default function MarketMaterial() {
  const dispatch = useAppDispatch();
  const { id, type } = useParams();
  const { colors, material } = useAppSelector((state) => state.market_material);
  const [search, setSearch] = useState<string>("");
  const [filt_colors, setFiltColors] = useState<FabricatorColor[]>([]);
  const [page, setPage] = useState<number>(1);
  const [page_count, setPageCount] = useState<number>(1);
  const [loading, setLoading] = useState<number[]>([]);
  const [num_per_page, setNumPerPage] = useState<number>(default_pagination_length);

  useEffect(() => {
    if (!material.id || material.id.toString() !== id) {
      dispatch(getMarketMaterial(id));
    }
  }, [dispatch, id, material]);

  useEffect(() => {
    dispatch(getFabricatorColors(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (material?.name) {
      dispatch(setBreadcrumbs([
        material_list,
        { ...material_type, label: getMaterialTypeDisplay(type), url: material_type.url.replace("material_type", type) },
        {
          ...c_material,
          label: `${material?.name} ${getMaterialHeightDisplay((material?.thickness ?? ""))}`,
          url: c_material.url.replace("material_type", type).replace("id", id)
        }
      ]));
    }
  }, [type, dispatch, id, material]);

  const updateLoading = (is_loading: boolean, updating: number) => {
    if (is_loading) {
      setLoading([...loading.filter(id => id !== updating), updating]);
    }
    else {
      setLoading([...loading.filter(id => id !== updating)]);
    }
  }

  useEffect(() => {
    const cols: any = filter(colors, function(col: FabricatorColor) {
      if (col.name.toLowerCase().includes(search.toLowerCase())) {
        return col;
      }
    }).slice((page - 1) * num_per_page, page * num_per_page);

    setFiltColors(cols);
    setPageCount(Math.ceil(cols?.length / num_per_page) ?? 1);
  }, [page, search, colors, num_per_page]);

  const searchKeyDown = (e: any) => {
    if (e.key === "Escape") {
      e.preventDefault();
      setSearch("");
    }
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <Grid container item xs={12} lg={6}>
          <PageTitle title={`${material?.name} ${getMaterialHeightDisplay((material?.thickness ?? ""))}`} endProps={<></>} />
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6} sx={{ ...row_header_style, paddingTop: "5px" }}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <Typography variant="h6" sx={title_style}>
                {filt_colors?.length} results
              </Typography>
              {
                colors?.length === 2000 ?
                  <Typography variant="body2" sx={{ marginLeft: "5px" }} color="error">
                    Limit of 2000 reached. Use filter to narrow down results.
                  </Typography> :
                  null
              }
            </Box>
          </Grid>
          <Grid item xs={6} sx={row_header_style}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <TextField
                label="Search Within Results"
                onKeyDown={searchKeyDown}
                value={search}
                placeholder="Name"
                size="small"
                sx={{ height: "30px", marginRight: "20px" }}
                InputProps={{
                  style: { height: "30px", },
                  endAdornment: <InputAdornment position="end">
                    {
                      search.length > 0 ?
                        <IconButton onClick={() => setSearch("")}>
                          <FontAwesomeIcon icon={faXmark} size="xs" />
                        </IconButton> :
                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xs" />
                    }
                  </InputAdornment>
                }}
                onChange={e => setSearch(e.target.value)} />
            </Box>
          </Grid>
          <ColorHeader />
          {filt_colors.map((color, index) => <MarketColorItem index={index} color={color} updateLoading={updateLoading} />)}
          <Grid item xs={10} sx={{ marginTop: 3 }}>
            <Box display="flex" justifyContent="center">
              <Pagination onChange={(e, v) => setPage(v)} count={page_count} shape="rounded" />
            </Box>
          </Grid>
          <Grid item xs={1} sx={{ marginTop: 3 }}>
            <PaginationSelector num_per_page={num_per_page} setNumPerPage={(setNumPerPage)} />
          </Grid>
        </Grid>
      </Grid>
      <Loading loading={loading.length > 0} />
    </motion.div>
  )
}
