import { Sink } from "api/types/sketch";
import { UNDERMOUNT } from "values/values";
import { RECTANGLE } from "values/values";

export const empty_sink: Sink = {
  location: { X: 0, Y: 0 },
  uuid: "",
  sink_type: UNDERMOUNT,
  shape: RECTANGLE,
  faucet_hole_count: 1,
  length: 30,
  width: 16,
  angle: 0,
  counter_uuid: ""
}
