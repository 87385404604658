import { Dispatch } from "redux";
import { Action } from "api/action_types/sketch";
import { ActionType } from "api/action_types/sketch";
import { Bump } from "api/types/sketch";

export const deleteLocalBump = (bump: Bump) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.DELETE_LOCAL_BUMP,
            payload: bump
        });
    }
}