/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { deleteShopQuote } from "api/actions/shop_quote";
/// MUI ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  post_del_url: string
}

export default function DeleteShopQuote({ open, setOpen, post_del_url }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { quote } = useAppSelector((state) => state.shop_quote);

  const handleDelete = () => {
    if (quote && quote.id) {
      dispatch(deleteShopQuote(quote.id));
      setOpen(false);
      navigate(post_del_url);
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Delete Quote</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Typography variant="body2">
          Are you sure you want to delete this quote? All of it"s data will be permanently removed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={() => setOpen(false)}>Cancel</Button>
        <Button size="small" onClick={handleDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  )
}
