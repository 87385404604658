import { Dispatch } from "redux";
import { Action } from "api/action_types/order";
import { ActionType } from "api/action_types/order";

export const clearMarketOrder = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_ORDER
    });
  }
}

export const clearMarketOrders = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_ORDERS
    });
  }
}
