/// UUID ///
import { v4 } from "uuid";
/// TYPES ///
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopPoint } from "api/types/shop_quote/point";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { getBottomLeftVertex } from "../math";
import { getLateralLeftVertex } from "../math";
import { getLateralRightVertex } from "../math";
import { getBottomRightVertex } from "../math";
import { getLineIntersection } from "../math";
import { getDistantPoint } from "../math";
/// VALUES ///
import { STANDARD } from "values/quote";
import { EASED } from "values/values";
import { FINISHED } from "values/values";
/// EMPTY ///
import { empty_corner } from "values/empty/quote/corner";

export function createShopCorners(points: IShopPoint[], corners: IShopCorner[], counter_uuid: string): IShopCorner[] {
  const left_side_corners: IShopCorner[] = [];
  const right_side_corners: IShopCorner[] = []
  for (let i = 0; i < points.length; i++) {
    let left_side_position: Vector2 = { X: 0, Y: 0 };
    let right_side_position: Vector2 = { X: 0, Y: 0 };
    if (i === 0) {
      left_side_position = getLateralLeftVertex(points[i].location, points[i].angle, points[i].width);
      right_side_position = getLateralRightVertex(points[i].location, points[i].angle, points[i].width);
    }
    else if (i > 0 && i < points.length - 1) {
      const last_left_corner: Vector2 = left_side_corners[left_side_corners.length - 1].location;
      left_side_position = getLineIntersection(
        last_left_corner,
        getDistantPoint(last_left_corner, points[i - 1].angle, 2000),
        getBottomLeftVertex(points[i].location, points[i].angle, points[i].width, points[i].width),
        getBottomLeftVertex(points[i + 1].location, points[i].angle, points[i].width, points[i].width)
      );
      const last_right_corner: Vector2 = right_side_corners[0].location;
      right_side_position = getLineIntersection(
        last_right_corner,
        getDistantPoint(last_right_corner, points[i - 1].angle, 2000),
        getBottomRightVertex(points[i].location, points[i].angle, points[i].width, points[i].width),
        getBottomRightVertex(points[i + 1].location, points[i].angle, points[i].width, points[i].width)
      );
    }
    else {
      left_side_position = getLateralLeftVertex(points[i].location, points[i - 1].angle, points[i].width);
      right_side_position = getLateralRightVertex(points[i].location, points[i - 1].angle, points[i].width);
    }

    const left_side_corner: IShopCorner = createShopCorner(
      left_side_position,
      i,
      points[i].uuid,
      counter_uuid,
      i <= corners.length / 2 - 1 ? corners[i] : null,
      !(corners.length < points.length * 2 && i === corners.length / 2 - 2 && corners.length > 4),
      corners[i - 1],
      corners[i + 1] ? corners[i + 1] : null,
      points.length * 2 < corners.length,
      points.length - 1 === i
    );
    left_side_corners.push(left_side_corner);
    const right_side_corner: IShopCorner = createShopCorner(
      right_side_position,
      points.length * 2 - i - 1,
      points[i].uuid,
      counter_uuid,
      corners.length / 2 - 1 >= i ? corners[corners.length - 1 - i] : null,
      !(corners.length < points.length * 2 && i === corners.length / 2 - 2 && corners.length > 4),
      corners[corners.length - 1 - i + 1],
      corners[corners.length - i - 2] ? corners[corners.length - i - 2] : null,
      points.length * 2 < corners.length,
      points.length - 1 === i
    );
    right_side_corners.unshift(right_side_corner);
  }
  return [...left_side_corners, ...right_side_corners];
}

function createShopCorner(
  location: Vector2,
  position: number,
  point_uuid: string,
  counter_uuid: string,
  prev_corner: IShopCorner | null,
  copy: boolean,
  last_corner: IShopCorner,
  next_corner: IShopCorner | null,
  popped: boolean, last_point: boolean
): IShopCorner {
  const uuid: string = v4();
  if (popped && next_corner && last_point) {
    return {
      ...empty_corner,
      location: location,
      point_uuid: point_uuid,
      counter_uuid: counter_uuid,
      edge_type: next_corner.edge_type,
      splash_height: next_corner.splash_height,
      finished_edge_type: next_corner.finished_edge_type,
      corner_type: next_corner.corner_type,
      corner_length: next_corner.corner_length,
      corner_depth: next_corner.corner_depth,
      corner_radius: next_corner.corner_radius,
      bumps: next_corner.bumps.map(item => { return ({ ...item, corner_uuid: uuid }) }),
      uuid: uuid,
      position: position
    }
  }
  if (!prev_corner && last_corner) {
    return {
      ...empty_corner,
      location: location,
      point_uuid: point_uuid,
      counter_uuid: counter_uuid,
      edge_type: last_corner.edge_type,
      splash_height: last_corner.splash_height,
      finished_edge_type: last_corner.finished_edge_type,
      corner_type: last_corner.corner_type,
      corner_length: last_corner.corner_length,
      corner_depth: last_corner.corner_depth,
      corner_radius: last_corner.corner_radius,
      bumps: last_corner.bumps.map(item => { return ({ ...item, corner_uuid: uuid }) }),
      uuid: uuid,
      position: position
    }
  }
  return {
    ...empty_corner,
    location: location,
    point_uuid: point_uuid,
    counter_uuid: counter_uuid,
    edge_type: copy && prev_corner ? prev_corner.edge_type : FINISHED,
    splash_height: copy && prev_corner ? prev_corner.splash_height : 4,
    finished_edge_type: copy && prev_corner ? prev_corner.finished_edge_type : EASED,
    corner_type: copy && prev_corner ? prev_corner.corner_type : STANDARD,
    corner_length: copy && prev_corner ? prev_corner.corner_length : 0,
    corner_depth: copy && prev_corner ? prev_corner.corner_depth : 0,
    corner_radius: copy && prev_corner ? prev_corner.corner_radius : 0,
    bumps: copy && prev_corner ? prev_corner.bumps.map(item => { return ({ ...item, corner_uuid: uuid }) }) : [],
    uuid: uuid,
    position: position
  }
}
