/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopBump } from "api/types/shop_quote/bump";
import { IShopCutBump } from "api/types/shop_quote/cut_bump";

export const createLocalShopCutBump = (bump: IShopBump, cut_bump: IShopCutBump) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CREATE_LOCAL_SHOP_CUT_BUMP,
      payload: cut_bump,
      bump
    });
  }
}
