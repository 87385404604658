import { SelfOrderSlab } from "api/types/order/self_order";

export enum ActionType {
  SLABS_LOADING = "SELF_ORDER_SLABS_LOADING",
  GET_SELF_ORDER_SLABS_SUCCESS = "GET_SELF_ORDER_SLABS_SUCCESS"
}

interface ISelfOrderSlabsLoading {
  type: ActionType.SLABS_LOADING
}

interface IGetSelfOrderSlabsSuccess {
  type: ActionType.GET_SELF_ORDER_SLABS_SUCCESS,
  payload: SelfOrderSlab[]
}

export type Action = ISelfOrderSlabsLoading | IGetSelfOrderSlabsSuccess;
