/// CALLBACK ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  close(open: boolean): void,
  callback(): void,
  body: string,
  title: string,
  confirm_text: string,
  warning?: boolean
}

export default function CallbackDialog(
  {
    open,
    close,
    callback,
    body,
    title,
    confirm_text,
    warning = false
  }: Props) {

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={() => close(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Typography variant="body2">
          {body}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(false)}>Cancel</Button>
        <Button onClick={callback} color={warning ? "secondary" : "primary"}>{confirm_text}</Button>
      </DialogActions>
    </Dialog>
  )
}
