/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { filter_url } from "components/navigation/endpoints";
import { order_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";

export const order_list: IBreadcrumb = {
  id: 0,
  label: "Orders",
  url: `/${shop_url}/${order_url}`
}

export const order_filter: IBreadcrumb = {
  id: 1,
  label: "Filter",
  url: `/${shop_url}/${order_url}/${filter_url}`
}

export const order: IBreadcrumb = {
  id: 2,
  label: "Order",
  url: `/${shop_url}/${order_url}/id`
}
