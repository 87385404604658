import { secondary_color } from "./style";
import { white_color } from "./style";
import { text_primary } from "./style";

export const menuItemStyle = {
  backgroundColor: white_color,
  color: text_primary,
  '&:hover': {
    backgroundColor: secondary_color
  }
}

