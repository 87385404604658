/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// FM ///
import { motion } from "framer-motion";
/// TYPES ///
import { IOrderEvent } from "api/types/event";
/// STYLES ///
import {
  dayEventItemStyle
} from "styles/calendar/day";
/// URLS ///
import { event_url } from "components/navigation/endpoints";
import { market_url } from "components/navigation/endpoints";

interface Props {
  order_event: IOrderEvent
}

export default function OrderDayItem({ order_event }: Props) {
  const navigate = useNavigate();

  return (
    <motion.button
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.05 }}
      onClick={() => navigate(`/${event_url}/${market_url}/${order_event?.order}`)}
      style={dayEventItemStyle(order_event?.color)}>
      {order_event?.title ?? ""}
    </motion.button>
  )
}
