import { Vector2 } from "api/types/sketch";
import { DrawOptions } from "api/types/sketch";
import { isPointInRectangle } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { getMidpoint } from "functions/sketch/math";
import { getDistance } from "functions/sketch/math";
import { drawLineHighlight } from "../highlight";

export function getLineHighlight(
    path: Path2D | null,
    current_location: Vector2,
    next_location: Vector2,
    angle: number,
    is_end: boolean,
    mouse: Vector2,
    options: DrawOptions
): boolean {
    if((path && (options.lines_selectable || (options.ends_selectable && is_end))) && 
        isPointInRectangle(
            getDistantPoint(getMidpoint(current_location, next_location), angle + 90, 10),
            angle,
            getDistance(current_location, next_location),
            10,   
            mouse
        )
    ){
        drawLineHighlight(path, current_location, next_location, angle);
        return true;
    }
    return false
}