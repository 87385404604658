/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// TYPES ///
import { IShopQuote } from "api/types/shop_quote/quote";
/// ACTIONS ///
import { updateShopQuote } from "api/actions/shop_quote";
import { resetSelfOrderSuccess } from "api/actions/self_order/self_order";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import ShopColorOptionsTable from "components/shop_quote/quote_preview/color_options";
import ConfirmShopOrder from "./dialog/confirm_order";
import Loading from "components/universal/loading";
import ShopQuoteDetail from "./info";
import ShopQuoteSummary from "./summary";
import SetShopDiscount from "components/shop_quote/quote/quote_details/set_discount";
/// ICONS ///
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faPercent } from "@fortawesome/free-solid-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { order_url } from "components/navigation/endpoints";
import { print_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";
/// FUNCTIONS ///
import { confirmCutShapesFitted } from "functions/sketch/check/fit";
import { checkQuoteLineItemErrors } from "functions/sketch/check/quote";

interface Props {
  index: number,
  quote: IShopQuote
}

export default function ShopQuoteDetails({ index, quote }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { areas } = useAppSelector((state) => state.shop_quote);
  const { updating } = useAppSelector((state) => state.shop_quote);
  const { order_success } = useAppSelector((state) => state.self_order);
  const { success_id } = useAppSelector((state) => state.self_order);
  const { cut_shapes } = useAppSelector((state) => state.shop_quote);
  const [error, setError] = useState<boolean>(false);
  const [fitted, setFitted] = useState<boolean>(true);
  const [open_discount, setOpenDiscount] = useState<boolean>(false);
  const [open_confirm, setOpenConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (index === 6) {
      const is_fitted: boolean = confirmCutShapesFitted(cut_shapes, areas);
      const has_errors: boolean = checkQuoteLineItemErrors(areas);
      setFitted(is_fitted);
      setError(has_errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, areas]);

  const generateQuote = () => {
    const updated_quote: IShopQuote = {
      generated: true
    }
    if (quote.id) {
      dispatch(updateShopQuote(updated_quote, quote.id, true, false));
    }
  }

  if (order_success) {
    dispatch(resetSelfOrderSuccess());
    navigate(`/${shop_url}/${order_url}/${success_id}`, { replace: false });
  }

  return (
    <Grid container alignItems="center" sx={{ paddingTop: "20px", paddingBottom: "80px", paddingLeft: "25px", paddingRight: "25px" }}>
      <Grid item xs={2}>
        <Typography variant="h6" sx={item_style(false)}>
          Custom Quote
        </Typography>
      </Grid>
      <Grid item xs={10} textAlign="right">
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <BaseButton text="Print" icon={faPrint} clickEvent={() => window.open(`/${shop_url}/${quote_url}/${detail_url}/${id}/${print_url}/`, "_blank")} />
          <BaseButton text="Get Quote" icon={faFileInvoiceDollar} clickEvent={generateQuote} disabled={!fitted || !quote.changed || !quote?.customer_data || !quote?.job_data} />
          <BaseButton text="Discount" icon={faPercent} clickEvent={() => setOpenDiscount(true)} />
          <BaseButton text="Create Order" icon={faCartShopping} clickEvent={() => setOpenConfirm(true)} disabled={!fitted || !quote.generated || quote.changed} />
          <SetShopDiscount open={open_discount} setOpen={setOpenDiscount} quote={quote} />
          <ConfirmShopOrder open={open_confirm} setOpen={setOpenConfirm} all_items_found={!error} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <ShopQuoteDetail quote={quote} />
      <ShopColorOptionsTable areas={areas} />
      <ShopQuoteSummary areas={areas} quote={quote} is_fitted={fitted} generateQuote={generateQuote} />
      <Loading loading={updating} />
    </Grid>
  )
}
