/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/end_customer";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/end_customer";
/// TYPES ///
import { ICustomer } from "api/types/customer";
/// LODASH ///
import { orderBy } from "lodash";
/// SORT VALUES ///
import { NAME } from "values/sort/customer";
import { ADDRESS } from "values/sort/customer";
import { EMAIL } from "values/sort/customer";
import { CITY } from "values/sort/customer";
import { PHONE } from "values/sort/customer";

function sort(cxs: ICustomer[], sort_type: number, sort_up: boolean): ICustomer[] {
  let sort_field: string = "name";

  switch (sort_type) {
    case NAME:
      sort_field = "last_name";
      break;
    case ADDRESS:
      sort_field = "address";
      break;
    case EMAIL:
      sort_field = "email";
      break;
    case CITY:
      sort_field = "city";
      break;
    case PHONE:
      sort_field = "phone";
      break;
    default:
      sort_field = "name";

  };

  return orderBy(cxs, sort_field, sort_up ? "asc" : "desc");
}

export const sortCustomers = (sort_type: number, sort_up: boolean) => {
  return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const { end_customers } = getState().end_customer;

    dispatch({
      type: ActionType.SORT_CUSTOMERS,
      customers: sort(end_customers, sort_type, sort_up)
    });
  }
}
