import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopCounter } from "api/types/shop_quote/counter";

export enum ActionType {
  SHOP_RESTORE = "SHOP_RESTORE"
}

interface IShopRestore {
  type: ActionType.SHOP_RESTORE,
  counters: IShopCounter[],
  cut_shapes: IShopCutShape[]
}

export type Action = IShopRestore;
