/// HOOKS ///
import { useEffect } from "react";
import { useState } from "react";
/// COMPONENTS ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Fragment } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  default_email: string,
  customer: number | null,
  sendQuote: (email: string) => void,
  error: boolean,
  exists?: boolean
}

export default function EmailSignatureRequest({
  open,
  setOpen,
  default_email,
  customer,
  error,
  exists,
  sendQuote
}: Props) {
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    setEmail(default_email);
  }, [default_email]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Request Signature</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Stack sx={{ paddingTop: "10px" }}>
          {
            exists ?
              <Typography variant="body2" color="error">
                Signature has already been received, are you sure you want to send again?
              </Typography> :
              null
          }
          {
            !customer ?
              <Typography variant="body2" color="error">
                Must enter a customer for this quote before sending an email
              </Typography> :
              <Fragment>
                <TextField
                  error={error}
                  helperText={error ? "Please enter a valid email" : ""}
                  variant="outlined"
                  label="Email"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </Fragment>
          }
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} size="small">Cancel</Button>
        <Button onClick={() => sendQuote(email)} disabled={!customer} size="small">Send</Button>
      </DialogActions>
    </Dialog>
  )
}
