/// TYPES ///
import { CSSProperties } from "react";

export const container_style: CSSProperties = {
  marginLeft: "73px"
}

export const label_style: CSSProperties = {
  color: "var(--mui-palette-primary-main)",
  marginLeft: "10px"
}

export const checked_ico_style: CSSProperties = {
  paddingLeft: "5px",
  height: "15px"
}

export const unchecked_ico_style: CSSProperties = {
  paddingLeft: "15px",
  height: "15px"
}
