import axios from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/self_order";
import { ActionType } from "api/action_types/self_order";
import { AxiosError } from "axios";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { url_prefix } from "settings";
import { self_order_area_url } from "../endpoint";

export const getSelfOrderAreas = (self_order_id: string | number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.AREAS_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${self_order_area_url}/?order=${self_order_id}&`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_SELF_ORDER_AREAS_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SELF_ORDER_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}
