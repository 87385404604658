/// TYPES ///
import { Counter } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCorner } from "api/types/shop_quote/corner";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { adjustVectorToScale } from "../draw/scale";
/// VALUES ///
import { SLAB_ZOOM } from "values/values";

export function checkIfCornerIsBeginning(counter: Counter, selected_corner: Corner): boolean {
  for (let i = 0; i < counter.corners.length; i++) {
    if (counter.corners[i].uuid !== selected_corner.uuid) {
      continue;
    }
    if (i === counter.corners.length - 1) {
      return true;
    }
    return false;
  }
  return false;
}

export function checkCounterClicked(counters: Counter[], mouse: Vector2): Counter | null {
  for (let i = 0; i < counters.length; i++) {
    const vertices: Vector2[] = [];
    for (let j = 0; j < counters[i].corners.length; j++) {
      const zoom_location: Vector2 = adjustVectorToScale(counters[i].corners[j].location, SLAB_ZOOM);
      vertices.push(zoom_location);
    }
    if (pointInCounter(mouse, counters[i].corners)) {
      return counters[i];
    }
  }
  return null;
}

export function checkShopCounterClicked(counters: IShopCounter[], mouse: Vector2): IShopCounter | null {
  for (let i = 0; i < counters.length; i++) {
    const vertices: Vector2[] = [];
    for (let j = 0; j < counters[i].corners.length; j++) {
      const zoom_location: Vector2 = adjustVectorToScale(counters[i].corners[j].location, SLAB_ZOOM);
      vertices.push(zoom_location);
    }
    if (pointInCounter(mouse, counters[i].corners)) {
      return counters[i];
    }
  }
  return null;
}

export function pointInCounter(point: Vector2, corners: Corner[] | IShopCorner[]) {
  var inside = false;
  for (var i = 0, j = corners.length - 1; i < corners.length; j = i++) {
    const loc_i: Vector2 = corners[i].location;
    const loc_j: Vector2 = corners[j].location;
    const xi = loc_i.X, yi = loc_i.Y;
    const xj = loc_j.X, yj = loc_j.Y;

    const intersect = ((yi > point.Y) !== (yj > point.Y))
      && (point.X < (xj - xi) * (point.Y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }
  return inside;
};
