/// MUI THEME ///
import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
/// COLORS ///
import { error_color } from "styles/style";
import { primary_color } from "styles/style";
import { text_primary } from "styles/style";
import { text_secondary } from "styles/style";
import { third_color } from "styles/style";
import { secondary_color } from "styles/style";
import { white_color } from "styles/style";
import { light_grey_color } from "styles/style";

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: primary_color,
        },
        secondary: {
          main: secondary_color,
        },
        error: {
          main: error_color,
        },
        text: {
          primary: text_primary
        },
      }
    },
    dark: {
      palette: {
        primary: {
          main: third_color,
          contrastText: white_color
        },
        secondary: {
          main: secondary_color,
        },
        error: {
          main: error_color,
        },
        text: {
          primary: text_primary,
          secondary: "#00000099",
          disabled: "#00000061",
        },
        action: {
          active: "#0000008a",
          hover: "#0000000a",
          selected: "#00000014",
          disabled: "#00000042",
          disabledBackground: "#0000001f",
        },
        background: {
          default: white_color,
          paper: white_color
        },
        AppBar: {
          darkColor: white_color
        },
        divider: light_grey_color
      },
    }
  },
  typography: {
    fontFamily: [
      "Roboto"
    ].join(","),
    h1: {
      fontSize: "2.1rem",
      fontFamily: "Manrope",
      fontWeight: "500",
      color: text_primary
    },
    h2: {
      fontSize: "2rem",
      fontFamily: "Roboto"
    },
    h3: {
      fontSize: "1.5rem",
      fontFamily: "Manrope",
      fontWeight: "500",
      textTransform: "uppercase",
      color: text_primary
    },
    h4: {
      fontSize: "1.2rem",
      fontFamily: "Roboto",
      color: text_primary,
      textTransform: "uppercase",
      fontWeight: "bold"
    },
    h5: {
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: text_primary
    },
    h6: {
      fontSize: "0.9rem",
      fontWeight: "bold",
      textTransform: "uppercase"
    },
    body1: {
      fontFamily: "Manrope",
      fontSize: "1rem",
      color: text_primary,
      whiteSpace: "pre-line",
      wordWrap: "break-word"
    },
    body2: {
      fontFamily: "Manrope",
      fontSize: "0.875rem",
      color: text_secondary,
      whiteSpace: "pre-line",
      wordWrap: "break-word"
    },
    button: {
      fontSize: "0.8rem",
      fontWeight: 600,
      fontFamily: "Roboto"
    }
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        valueLabelLabel: {
          fontSize: "0.75rem",
          marginTop: 0
        },
        markLabel: {
          fontSize: "0.75rem",
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: "black",
          "&::before": {
            borderColor: "black",
          }
        },
        notchedOutline: {
          borderColor: light_grey_color
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: "100px"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingTop: "10px",
          paddingBottom: "12px",
          paddingLeft: 24,
          lineHeight: 1,
          fontWeight: 600,
          fontFamily: "Roboto",
          color: "var(--mui-palette-background-paper)",
          backgroundColor: "var(--mui-palette-primary-main)",
          height: "10px"
        },
      },
      defaultProps: {
        height: 18,
      }
    },
    MuiTextField: {
      defaultProps: {
        style: {
          marginTop: "6px",
        },
        size: "small",
        inputProps: {
          style: {
            fontSize: "0.875rem",
            padding: "0.5rem",
            minWidth: "250px",
          }
        },
        InputProps: {
          style: {
            fontSize: "0.875rem",
            fontFamily: "Manrope",
          }
        },
        InputLabelProps: {
          shrink: true,
          style: {
            fontSize: "0.875rem",
            fontFamily: "Manrope"
          }
        }
      }
    },
    MuiMenuItem: {
      defaultProps: {
        style: {
          fontSize: 14
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.875rem"
        }
      }
    }
  }
});

