import { Dispatch } from "redux";
import { Action } from "api/action_types/end_customer";
import { ActionType } from "api/action_types/end_customer";
import { ICustomer } from "api/types/customer";

export const setEndCustomer = (customer: ICustomer) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_END_CUSTOMER,
      payload: customer
    });
  }
}
