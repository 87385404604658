/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { updateQuote } from "api/actions/quotes/quote";
import { setBreadcrumbs } from "api/actions/ui/set";
/// COMPONENTS ///
import AssignCustomer from "components/quote/assign";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { preview_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_preview } from "values/breadcrumbs/quote";
import { quote_assign } from "values/breadcrumbs/quote";

interface Props {
  base_url: string
}

export default function StoreAssignCustomer({ base_url }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_list.url.replace("base_url", base_url) },
      { ...quote_preview, url: quote_preview.url.replace("base_url", base_url).replace("id", id) },
      { ...quote_assign, url: quote_assign.url.replace("base_url", base_url).replace("id", id) }
    ]));
  }, [dispatch, id, base_url]);

  const update = (customer_id: number) => {
    dispatch(updateQuote({ customer: customer_id }, id, false));
    navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${preview_url}`);
  }

  return (
    <AssignCustomer
      base_url={base_url}
      update={update} />
  )
}
