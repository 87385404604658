/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getQuote, updateLocalQuote } from "api/actions/quotes/quote";
import { updateEndCustomer } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { ICustomer } from "api/types/customer";
import { ICustomerErrors } from "api/types/customer";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
/// PAGE COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// COMPONENTS ///
import AddressAutocomplete from "components/universal/autocomplete/geo";
import ProvinceSelector from "components/universal/select_field/province";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// VALIDATE ///
import { checkSaveCustomer } from "values/check/customer";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_preview } from "values/breadcrumbs/quote";
import { quote_update_cx } from "values/breadcrumbs/quote";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { preview_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";

interface Props {
  base_url: string
}

export default function UpdateQuoteCustomer({ base_url }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, customer_id } = useParams();
  const { quote } = useAppSelector((state) => state.quote);
  const [customer, setCustomer] = useState<ICustomer>({});
  const [errors, setErrors] = useState<ICustomerErrors>({});

  useEffect(() => {
    setCustomer(quote?.customer_data ?? {});
  }, [quote?.customer_data]);

  useEffect(() => {
    if (!quote?.id || quote.id.toString() !== id) {
      dispatch(getQuote(id));
    }
  }, [dispatch, id, quote?.id]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_preview.url.replace("base_url", base_url) },
      { ...quote_preview, url: quote_preview.url.replace("base_url", base_url).replace("id", id) },
      { ...quote_update_cx, url: quote_update_cx.url.replace("base_url", base_url).replace("id", id).replace("customer_id", customer_id) }

    ]));
  }, [dispatch, id, customer_id, base_url]);

  const saveString = (key: string, value: string) => {
    setCustomer({ ...customer, [key]: value });
    setErrors({ ...errors, [`${key}_error`]: "", [`${key}_has_error`]: false });
  }

  const updateGeo = (label: string, lat: number, lng: number, address: string, city: string, province: string) => {
    setCustomer({
      ...customer,
      geo_address: label,
      lat: lat,
      lng: lng,
      address: customer?.address && customer.address !== "" ? customer.address : address,
      city: customer?.city && customer.city !== "" ? customer.city : city,
      province: customer?.province && customer.province !== "" ? customer.province : province
    });
  }

  const geoError = () => {
    setErrors({ ...errors, geo_error: "Unable to retrieve coordinates of this address.", geo_has_error: true });
  }

  const update = () => {
    const errors: ICustomerErrors = checkSaveCustomer(customer);

    if (errors.email_has_error || errors.first_name_has_error || errors.last_name_has_error || errors.geo_has_error) {
      setErrors(errors);
      return;
    }

    dispatch(updateEndCustomer(customer, customer_id));
    dispatch(updateLocalQuote({ ...quote, customer_data: customer }));
    navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${preview_url}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Update Customer" size_lg={6} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${preview_url}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={update} text="Update" icon={faSave} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} lg={6}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <Stack direction="row" spacing={1}>
              <TextField
                value={customer?.first_name ?? ""}
                onChange={e => saveString("first_name", e.target.value)}
                error={errors?.first_name_has_error}
                helperText={errors?.first_name_error ?? ""}
                label="First Name" />
              <TextField
                value={customer?.last_name ?? ""}
                onChange={e => saveString("last_name", e.target.value)}
                error={errors?.last_name_has_error}
                helperText={errors?.last_name_error ?? ""}
                label="Last Name" />
            </Stack>
            <TextField
              value={customer?.email ?? ""}
              onChange={e => saveString("email", e.target.value)}
              error={errors?.email_has_error}
              helperText={errors?.email_error ?? ""}
              label="Email" />
            <TextField
              value={customer?.phone_number ?? ""}
              onChange={e => saveString("phone_number", e.target.value)}
              label="Phone Number" />
            <AddressAutocomplete
              input_label="Service Address"
              label={customer?.geo_address}
              error={errors?.geo_error ?? ""}
              has_error={errors?.geo_has_error}
              geoError={geoError}
              updateGeo={updateGeo} />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px", marginTop: "30px" }}>
            <TextField
              value={customer?.address ?? ""}
              onChange={e => saveString("address", e.target.value)}
              label="Billing Address" />
            <TextField
              value={customer?.city ?? ""}
              onChange={e => saveString("city", e.target.value)}
              label="Billing City" />
            <ProvinceSelector
              province={customer?.province ?? ""}
              setProvince={province => saveString("province", province)} />
            <TextField
              value={customer?.postal_code ?? ""}
              onChange={e => saveString("postal_code", e.target.value)}
              label="Billing Postal Code" />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px", marginTop: "30px" }}>
            <TextField
              sx={{ width: "540px" }}
              multiline={process.env.NODE_ENV === "development" ? false : true}
              minRows={3}
              maxRows={3}
              onChange={e => saveString("entry_notes", e.target.value)}
              value={customer?.entry_notes ?? ""}
              label="Notes" />
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}
