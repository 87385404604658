import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";

export enum ActionType {
    RESTORE="RESTORE"
}

interface IRestore {
    type: ActionType.RESTORE,
    counters: Counter[],
    cut_shapes: CutShape[]
}

export type Action = IRestore;