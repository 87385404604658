import { Text } from "api/types/sketch";
import { EDGE } from "values/text";
import { CORNER } from "values/text";

export function getTextDisplay(text: Text){
    if(text.type === CORNER || text.type === EDGE){
        return text.text;
    }
    if(text.value % 1 !== 0){
        return text.value.toFixed(2) + text.text;
    }  
    else{
        return text.value.toString() + text.text;
    }
}