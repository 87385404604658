/// HOOKS ///
import { useNavigate } from "react-router-dom";
import useQuoteTotals from "hooks/quote/totals";
/// MUI ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// FM ///
import { motion } from "framer-motion";
/// TYPES ///
import { Order } from "api/types/order";
/// LUXON ///
import { DateTime } from "luxon";
/// STYLES ///
import { row_text_motion } from "styles/universal/row";
import { row_btn_style } from "styles/universal/row";
import { row_text_item_style } from "styles/universal/row";
/// URLS ///
import { store_url } from "components/navigation/endpoints";
import { order_url } from "components/navigation/endpoints";

interface Props {
  order: Order
}

export default function CxOrderItem({ order }: Props) {
  const navigate = useNavigate();
  const totals = useQuoteTotals(order);

  const goTo = () => {
    navigate(`/${store_url}/${order_url}/${order?.id ?? 0}`)
  }

  return (
    <Grid item xs={12}>
      <motion.button
        initial="rest"
        whileHover="hover"
        animate="rest"
        variants={row_text_motion}
        onClick={goTo}
        style={row_btn_style}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {DateTime.fromISO(order?.created ?? "").toLocal().toFormat("LLL dd yyyy")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {order?.name ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" align="right" noWrap sx={row_text_item_style}>
              ${(totals.subtotal + totals.tax1 + totals.tax2 + totals.discount).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </motion.button>
    </Grid>
  )
}
