export enum ActionType {
  WO_LOADING = "WO_LOADING",
  WO_UPDATING = "WO_UPDATING"
}

interface IWOLoading {
  type: ActionType.WO_LOADING
}

interface IWOUpdating {
  type: ActionType.WO_UPDATING
}

export type Action = IWOLoading | IWOUpdating;
