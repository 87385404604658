/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { market_url } from "components/navigation/endpoints";
import { price_list_url } from "components/navigation/endpoints";
import { installation_url } from "components/navigation/endpoints";
import { fabrication_url } from "components/navigation/endpoints";
import { miter_url } from "components/navigation/endpoints";
import { splash_url } from "components/navigation/endpoints";
import { finished_url } from "components/navigation/endpoints";
import { appliance_url } from "components/navigation/endpoints";
import { clipped_url } from "components/navigation/endpoints";
import { notched_url } from "components/navigation/endpoints";
import { bumped_out_url } from "components/navigation/endpoints";
import { radius_url } from "components/navigation/endpoints";
import { inside_radius_url } from "components/navigation/endpoints";
import { recessed_diagonal_url } from "components/navigation/endpoints";
import { inside_diagonal_url } from "components/navigation/endpoints";
import { bump_out_url } from "components/navigation/endpoints";
import { bump_in_url } from "components/navigation/endpoints";
import { bump_in_arc_url } from "components/navigation/endpoints";
import { bump_out_arc_url } from "components/navigation/endpoints";
import { full_arc_url } from "components/navigation/endpoints";
import { cooktop_url } from "components/navigation/endpoints";
import { sink_url } from "components/navigation/endpoints";
import { faucet_url } from "components/navigation/endpoints";
import { outlet_url } from "components/navigation/endpoints";
import { removal_url } from "components/navigation/endpoints";

export const list: IBreadcrumb = {
  id: 0,
  label: "Price Lists",
  url: `/${market_url}/${price_list_url}`
}

export const overview: IBreadcrumb = {
  id: 1,
  label: "Overview",
  url: `/${market_url}/${price_list_url}/id`
}

export const installation: IBreadcrumb = {
  id: 2,
  label: "Installation",
  url: `/${market_url}/${price_list_url}/id/${installation_url}`
}

export const fabrication: IBreadcrumb = {
  id: 3,
  label: "Fabrication",
  url: `/${market_url}/${price_list_url}/id/${fabrication_url}`
}

export const miter: IBreadcrumb = {
  id: 4,
  label: "Miter",
  url: `/${market_url}/${price_list_url}/id/${miter_url}`
}

export const splash: IBreadcrumb = {
  id: 5,
  label: "Splash",
  url: `/${market_url}/${price_list_url}/id/${splash_url}`
}

export const finished_edge: IBreadcrumb = {
  id: 6,
  label: "Finished Edge",
  url: `/${market_url}/${price_list_url}/id/${finished_url}`
}

export const appliance_edge: IBreadcrumb = {
  id: 7,
  label: "Appliance Edge",
  url: `/${market_url}/${price_list_url}/id/${appliance_url}`
}

export const clipped_corner: IBreadcrumb = {
  id: 8,
  label: "Clipped Corner",
  url: `/${market_url}/${price_list_url}/id/${clipped_url}`
}

export const notched_corner: IBreadcrumb = {
  id: 9,
  label: "Notched Corner",
  url: `/${market_url}/${price_list_url}/id/${notched_url}`
}

export const bumped_out_corner: IBreadcrumb = {
  id: 10,
  label: "Bumped Out Corner",
  url: `/${market_url}/${price_list_url}/id/${bumped_out_url}`
}

export const radius_corner: IBreadcrumb = {
  id: 11,
  label: "Radius Corner",
  url: `/${market_url}/${price_list_url}/id/${radius_url}`
}

export const inside_radius_corner: IBreadcrumb = {
  id: 12,
  label: "Inside Radius Corner",
  url: `/${market_url}/${price_list_url}/id/${inside_radius_url}`
}

export const recessed_diagonal_corner: IBreadcrumb = {
  id: 13,
  label: "Recessed Diagonal Corner",
  url: `/${market_url}/${price_list_url}/id/${recessed_diagonal_url}`
}

export const inside_diagonal_corner: IBreadcrumb = {
  id: 14,
  label: "Inside Diagonal Corner",
  url: `/${market_url}/${price_list_url}/id/${inside_diagonal_url}`

}

export const bump_out: IBreadcrumb = {
  id: 15,
  label: "Bump Out",
  url: `/${market_url}/${price_list_url}/id/${bump_out_url}`
}

export const bump_in: IBreadcrumb = {
  id: 16,
  label: "Bump In",
  url: `/${market_url}/${price_list_url}/id/${bump_in_url}`
}

export const bump_in_arc: IBreadcrumb = {
  id: 17,
  label: "Bump In Arc",
  url: `/${market_url}/${price_list_url}/id/${bump_in_arc_url}`
}

export const bump_out_arc: IBreadcrumb = {
  id: 18,
  label: "Bump Out Arc",
  url: `/${market_url}/${price_list_url}/id/${bump_out_arc_url}`
}

export const full_arc: IBreadcrumb = {
  id: 19,
  label: "Full Arc",
  url: `/${market_url}/${price_list_url}/id/${full_arc_url}`
}

export const cooktop: IBreadcrumb = {
  id: 20,
  label: "Cooktop Cutout",
  url: `/${market_url}/${price_list_url}/id/${cooktop_url}`
}

export const sink: IBreadcrumb = {
  id: 21,
  label: "Sink Cutout",
  url: `/${market_url}/${price_list_url}/id/${sink_url}`
}

export const faucet: IBreadcrumb = {
  id: 22,
  label: "Faucet Cutout",
  url: `/${market_url}/${price_list_url}/id/${faucet_url}`
}

export const outlet: IBreadcrumb = {
  id: 23,
  label: "Outlet Cutout",
  url: `/${market_url}/${price_list_url}/id/${outlet_url}`
}

export const removal: IBreadcrumb = {
  id: 24,
  label: "Countertop Removal",
  url: `/${market_url}/${price_list_url}/id/${removal_url}`
}
