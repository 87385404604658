/// REACT ///
import { Fragment } from "react";
/// TYPES ///
import { IShopColor } from "api/types/shop_material";
import { IShopColorError } from "api/types/shop_material";
/// MUI ///
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";

interface Props {
  index: number,
  color: IShopColor,
  setColor: (index: number, color: IShopColor) => void,
  errors: IShopColorError,
  disabled?: boolean
}

export default function CreateColorRow({ index, color, setColor, errors, disabled = false }: Props) {
  return (
    <Fragment>
      <Grid item xs={3}>
        <TextField
          disabled={disabled}
          value={color?.name ?? ""}
          onChange={e => setColor(index, { ...color, name: e.target.value })}
          error={errors?.name_has_error}
          helperText={errors?.name_error ?? ""}
          size="small" />
      </Grid>
      <Grid item xs={3}>
        <TextField
          disabled={disabled}
          type="number"
          value={color?.price && color.price > 0 ? color.price : ""}
          onChange={e => setColor(index, { ...color, price: Number(e.target.value) })}
          error={errors?.price_has_error}
          helperText={errors?.price_error ?? ""}
          size="small" />
      </Grid>
      <Grid item xs={2}>
        <TextField
          disabled={disabled}
          type="number"
          value={color?.length && color.length > 0 ? color.length : ""}
          onChange={e => setColor(index, { ...color, length: Number(e.target.value) })}
          error={errors?.length_has_error}
          helperText={errors?.length_error ?? ""}
          size="small" />
      </Grid>
      <Grid item xs={2}>
        <TextField
          disabled={disabled}
          type="number"
          value={color?.width && color.width > 0 ? color.width : ""}
          onChange={e => setColor(index, { ...color, width: Number(e.target.value) })}
          error={errors?.width_has_error}
          helperText={errors?.width_error ?? ""}
          size="small" />
      </Grid>
      <Grid item xs={2}>
        <TextField
          disabled={disabled}
          type="number"
          value={color?.amount && color.amount > 0 ? color.amount : ""}
          onChange={e => setColor(index, {
            ...color,
            amount: Number(e.target.value),
            infinite: Number(e.target.value) > 0 ? false : true
          })}
          size="small" />
      </Grid>
    </Fragment>

  )
}
