import type { Action as AddonAction } from "./addon";
import type { Action as AreaAction } from "./area";
import type { Action as ColorAction } from "./color_option";
import type { Action as EndCustomerAction } from "./end_customer";
import type { Action as ErrorAction } from "./error";
import type { Action as LoadingAction } from "./loading";
import type { Action as MessageAction } from "./message";
import type { Action as QuoteAction } from "./quote";
import type { Action as QuoteLineItemAction } from "./quote_line_item";
import type { Action as SlabAction } from "./slab";
import type { Action as SortAction } from "./sort";

import { ActionType as AddonActionType } from "./addon";
import { ActionType as AreaActionType } from "./area";
import { ActionType as ColorActionType } from "./color_option";
import { ActionType as EndCustomerActionType } from "./end_customer";
import { ActionType as ErrorActionType } from "./error";
import { ActionType as LoadingActionType } from "./loading";
import { ActionType as MessageActionType } from "./message";
import { ActionType as QuoteActionType } from "./quote";
import { ActionType as QuoteLineItemActionType } from "./quote_line_item";
import { ActionType as SlabActionType } from "./slab";
import { ActionType as SortActionType } from "./sort";

export const ActionType = {
  ...AddonActionType,
  ...AreaActionType,
  ...ColorActionType,
  ...EndCustomerActionType,
  ...ErrorActionType,
  ...LoadingActionType,
  ...MessageActionType,
  ...QuoteActionType,
  ...QuoteLineItemActionType,
  ...SlabActionType,
  ...SortActionType
}

export type Action =
  AddonAction |
  AreaAction |
  ColorAction |
  EndCustomerAction |
  ErrorAction |
  LoadingAction |
  MessageAction |
  QuoteAction |
  QuoteLineItemAction |
  SlabAction |
  SortAction;

