export enum ActionType {
  SKETCH_UPDATING = "SKETCH_UPDATING",
  SKETCH_LOADING = "SKETCH_LOADING",
  SKETCH_ACTION_SUCCESS = "SKETCH_ACTION_SUCCESS",
  COUNTERS_LOADING = "COUNTERS_LOADING",
  SHAPES_LOADING = "SHAPES_LOADING"
}

interface ISketchUpdating {
  type: ActionType.SKETCH_UPDATING
}

interface ISketchLoading {
  type: ActionType.SKETCH_LOADING,
  payload: string
}

interface ISketchActionSuccess {
  type: ActionType.SKETCH_ACTION_SUCCESS
}

interface ICountersLoading {
  type: ActionType.COUNTERS_LOADING
}

interface IShapesLoading {
  type: ActionType.SHAPES_LOADING
}

export type Action = ISketchLoading | ISketchUpdating | ISketchActionSuccess | ICountersLoading | IShapesLoading;
