import { CutShape } from "api/types/sketch";
import { CutCornerAndShape } from "api/types/sketch";

export function getCutCornerByCornerUuid(
    counter_uuid: string,
    corner_uuid: string,
    cut_shapes: CutShape[]
): CutCornerAndShape | null {
    for(let i = 0; i < cut_shapes.length; i++){
        if(counter_uuid === cut_shapes[i].counter_uuid){
            for(let j = 0; j < cut_shapes[i].cut_corners.length; j++){
                if(corner_uuid === cut_shapes[i].cut_corners[j].corner_uuid){
                    return {
                        cut_corner: cut_shapes[i].cut_corners[j],
                        cut_shape: cut_shapes[i]
                    }
                }
            }
        }
    }
    return null;
}
