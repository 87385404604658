/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { setSelfOrderEvent } from "api/actions/event";
/// TYPES ///
import { ISelfOrderEvent } from "api/types/event";
/// COMPONENTS ///
import ProgressColumn from ".";

interface Props {
  order_event: ISelfOrderEvent,
  next_event?: ISelfOrderEvent,
  selected_event_id: string | number,
  has_line: boolean,
  index: number
}

export default function SelfOrderProgressColumn({ order_event, next_event, selected_event_id, index, has_line }: Props) {
  const dispatch = useAppDispatch();

  return (
    <ProgressColumn
      index={index}
      event={order_event}
      next_event={next_event}
      setEvent={() => dispatch(setSelfOrderEvent(order_event))}
      selected_event_id={selected_event_id}
      has_line={has_line} />
  )

}

