/// TYPES ///
import { IGetOrderArgs } from "api/types/order/order";
/// URLS ///
import { url_prefix } from "settings";
import { self_order_url } from "../../endpoint";

export function constructGetShopOrderUrl(args: IGetOrderArgs): string {
  let url = `${url_prefix}${self_order_url}/?`;

  if (args) {
    if (args.order_name && args.order_name !== "") {
      url += `order_name=${args.order_name}&`;
    }
    if (args.customer_name && args.customer_name !== "") {
      url += `customer_name=${args.customer_name}&`;
    }
    if (args.start_date && args.start_date !== "") {
      url += `start_date=${args.start_date}&`;
    }
    if (args.end_date && args.end_date !== "") {
      url += `end_date=${args.end_date}&`;
    }
  }

  return url;
}
