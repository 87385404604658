/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// ACTIONS ///
import { Action } from "api/action_types/ui";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/ui";

interface State {
  breadcrumbs: IBreadcrumb[]
}

const initialState = {
  breadcrumbs: []
}

export const uiReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_CRUMBS:
      return {
        ...state,
        breadcrumbs: action.crumbs
      }
    default:
      return state;
  }
}
