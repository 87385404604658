import { Vector2 } from "api/types/sketch";
import { BumpArcConstruct } from "api/types/sketch";
import { constructFullArc } from "../construct";

export function drawCutFullWidth(
    path: Path2D,
    start: Vector2, 
    end: Vector2, 
    depth: number, 
    angle: number,
    zoom: number
){
    const bump_construct: BumpArcConstruct = constructFullArc(start, end, angle, depth, zoom);
    path.lineTo(bump_construct.start.X, bump_construct.start.Y);
    path.quadraticCurveTo(bump_construct.top.X, bump_construct.top.Y, bump_construct.end.X, bump_construct.end.Y);
}