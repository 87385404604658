/// REACT ///
import { ReactNode } from "react";
/// MUI COMPONENTS ///
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { act_btn_style } from "styles/universal/button";
import { act_btn_icon_style } from "styles/universal/button";
/// COLOR ///
import { error_color } from "styles/style";

interface Props {
  text: string,
  icon: IconDefinition,
  disabled?: boolean,
  hide?: boolean,
  children?: ReactNode,
  clickEvent?: () => void,
  label?: boolean,
  error?: boolean
}

export default function BaseButton({ text, icon, clickEvent, disabled = false, hide = false, children, label = false, error = false }: Props) {
  if (hide) {
    return null;
  }

  return (
    <Button sx={{ ...act_btn_style, color: error ? error_color : "default" }} disabled={disabled} onClick={clickEvent} component={label ? "label" : "button"}>
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.6}>
        <Typography variant="button">
          {text}
        </Typography>
        <FontAwesomeIcon icon={icon} style={act_btn_icon_style} />
        {children}
      </Stack>
    </Button>
  )
}
