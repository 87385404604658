/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
/// TYPES ///
import { Location } from "react-router-dom";
/// MUI ///
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
/// DATETIME ///
import { DateTime } from "luxon";
/// EVENT VALUES ///
import { MONTH } from "values/event";
import { DAY } from "values/event";
import { WEEK } from "values/event";
/// URLS ///
import { event_url } from "components/navigation/endpoints";
import { month_url } from "components/navigation/endpoints";
import { week_url } from "components/navigation/endpoints";
import { day_url } from "components/navigation/endpoints";

interface Props {
  changeDate: (month: number, day: number) => void,
  event_view_type: string
}

export default function CalendarBar({ changeDate, event_view_type }: Props) {
  const location: Location<any> = useLocation();
  const navigate = useNavigate();
  const { nav_date } = useAppSelector((state) => state.event);

  const handleDateDown = () => {
    if (event_view_type === MONTH) {
      changeDate(-1, 0);
    }
    else if (event_view_type === WEEK) {
      changeDate(0, -7);
    }
    else {
      changeDate(0, -1);
    }
  }

  const handleDateUp = () => {
    if (event_view_type === MONTH) {
      changeDate(1, 0);
    }
    else if (event_view_type === WEEK) {
      changeDate(0, 7);
    }
    else {
      changeDate(0, 1);
    }
  }

  const getEventViewDisplay = (): string => {
    if (event_view_type === MONTH) {
      return `${DateTime.fromISO(nav_date).monthLong} ${DateTime.fromISO(nav_date).year}`;
    }
    else if (event_view_type === DAY) {
      return `${DateTime.fromISO(nav_date).monthLong} ${DateTime.fromISO(nav_date).day}`;
    }
    else if (event_view_type === WEEK) {
      return `${DateTime.fromISO(nav_date).startOf("week").toFormat("EEE d")} - ${DateTime.fromISO(nav_date).endOf("week").toFormat("EEE d")}`;
    }
    return "";
  }

  return (<Toolbar disableGutters={true} variant="dense">
    <Stack direction="row" alignItems="center">
      <Typography variant="h5" sx={{ fontSize: 15, paddingLeft: "10px", marginRight: "15px" }}>
        {getEventViewDisplay()}
      </Typography>
      <IconButton onClick={handleDateDown} size="small">
        <FontAwesomeIcon icon={faChevronLeft} size="xs" />
      </IconButton>
      <IconButton onClick={handleDateUp} size="small">
        <FontAwesomeIcon icon={faChevronRight} size="xs" />
      </IconButton>
    </Stack>
    <div style={{ flexGrow: 1 }}>
    </div>
    <div style={{ gap: "10px", display: "flex", flexDirection: "row", alignItems: "center", paddingRight: "10px" }}>
      <Button
        onClick={() => navigate(`/${event_url}/${month_url}`)}
        variant={location.pathname.includes(month_url) ? "contained" : "outlined"}
        size="small">
        Month
      </Button>
      <Button
        onClick={() => navigate(`/${event_url}/${week_url}`)}
        variant={location.pathname.includes(week_url) ? "contained" : "outlined"}
        size="small">
        Week
      </Button>
      <Button
        onClick={() => navigate(`/${event_url}/${day_url}`)}
        variant={location.pathname.includes(day_url) ? "contained" : "outlined"}
        size="small">
        Day
      </Button>
    </div>
  </Toolbar>
  )
}
