import { Counter } from "api/types/sketch";
import { Corner } from "api/types/sketch";

export function updateCorner(counter: Counter, corner: Corner): Counter {
    const new_corners: Corner[] = [...counter.corners];
    for(let i = 0; i < new_corners.length; i++){
        if(new_corners[i].uuid === corner.uuid){
            new_corners[i] = corner;
            break;
        }
    }
    return {...counter, corners: new_corners};
}