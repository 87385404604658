import { CornerData } from "api/types/sketch";
import { CornerNotch } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { getLineIntersection } from "functions/sketch/math";
import { getNextAngleOnFirstHalfCornerSeam } from "../seam";
import { constructNotchCorner } from "../../corner/construct";

export function drawFirstHalfPrintCutNotchedCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const cut_angle: number = Math.round((getAngle(current_location, next_location) + 90) * 100) / 100;
    const next_angle: number = getNextAngleOnFirstHalfCornerSeam(cut_angle, angle);

    const corner_notched: CornerNotch = constructNotchCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom,
        corner_depth * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_notched.start_point;
        painter.moveTo(corner_notched.start_point.X, corner_notched.start_point.Y);
    }
    else{
        painter.lineTo(corner_notched.start_point.X, corner_notched.start_point.Y); 
    }

    painter.lineTo(corner_notched.inner_corner.X, corner_notched.inner_corner.Y);
    const end_point: Vector2 = getLineIntersection(corner_notched.inner_corner, corner_notched.end_point, current_location, next_location);
    painter.lineTo(end_point.X, end_point.Y);

    return {
        first_point: first_point,
        last_point: end_point
    }
}