import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/fabricator';
import { ActionType } from 'api/action_types/fabricator';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { url_prefix } from 'settings';
import { FabricatorBumpIn } from "api/types/fabricator";

const fabricator_bump_in_url: string = "fabricator_bump_in";

export const getFabricatorBumpIns = (fabricator_price_list: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATOR_PRICE_LIST_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${fabricator_bump_in_url}/?fabricator_price_list=${fabricator_price_list}&`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_FABRICATOR_BUMP_INS_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const updateFabricatorBumpIn = (fabricator_bump_in: FabricatorBumpIn, id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATOR_PRICE_LIST_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${fabricator_bump_in_url}/${id}/`;

    try {
      const { data } = await axios.patch(url, fabricator_bump_in, config);

      dispatch({
        type: ActionType.UPDATE_FABRICATOR_BUMP_IN_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
