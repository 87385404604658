/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_material";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_material";

export const resetCreateShopMaterialSuccess = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_CREATE_SHOP_MATERIAL_SUCCESS
    });
  }
}
