/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getEvent } from "api/actions/event";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import Grid from "@mui/material/Grid";
/// COMPONENTS ///
import CalendarPageBar from "../calendar_bar/page";
import CalendarSidebar from "../side_bar";
import CustomEventDetails from "./details/custom";
import Loading from "components/universal/loading";
import LoadingPage from "components/universal/loading_page";
import PageTitle from "components/universal/page/title";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { calendar_content_style } from "styles/universal/page";

export default function CustomEvent() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { custom_event, updating } = useAppSelector((state) => state.event);

  useEffect(() => {
    if (id) {
      dispatch(getEvent(id));
    }
  }, [dispatch, id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={calendar_content_style}>
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <CalendarPageBar />
        <LoadingPage loading={!custom_event?.id}>
          <Grid container alignItems="flex-start" spacing={3} sx={{ paddingTop: "25px", paddingRight: "75px" }}>
            <PageTitle title={custom_event?.title ?? "Event"} />
            <CustomEventDetails event={custom_event} />
          </Grid>
        </LoadingPage>
      </div>
      <CalendarSidebar />
      <Loading loading={updating} />
    </motion.div >
  )
}
