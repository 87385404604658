import { Vector2 } from "api/types/sketch";
import { Text } from "api/types/sketch";
import { toRadians } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math"
import { WIDTH } from "values/text";
import { HEIGHT } from "values/text";

export function measurementText(
  midway_point: Vector2,
  distance: number,
  angle: number,
  measurement_distance: number,
  corner_uuid: string,
  counter_uuid: string,
  point_uuid: string,
  height: boolean,
  mirror: boolean
): Text {
  const text: string = '"';
  const display_text: string = (measurement_distance / 4).toString() + text;

  const adjusted_location: Vector2 = getDistantPoint(midway_point, angle - 90, 10 * Math.abs(Math.sin(toRadians(angle))));
  const width_adjusted_location: Vector2 = getDistantPoint(adjusted_location, angle - 90, display_text.length * 4 * Math.abs(Math.sin(toRadians(angle - 90))));

  return {
    location: width_adjusted_location,
    value: measurement_distance / 4,
    real_value: distance / 4,
    text: text,
    corner_uuid: corner_uuid,
    counter_uuid: counter_uuid,
    point_uuid: point_uuid,
    type: height ? HEIGHT : WIDTH,
    angle: angle,
    mirror: mirror
  };
}
