/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
/// ACTIONS ///
import { updateCounter } from "api/actions/sketch/counter";
import { updateLocalCounter } from "api/actions/sketch/counter";
import { createRestorePoint } from "api/actions/history";
import { updateShopCounter } from "api/actions/shop_quote";
import { updateLocalShopCounter } from "api/actions/shop_quote";
/// FUNCTIONS ///
import { getCenterOfCounter } from "functions/sketch";
import { rotateCounter } from "functions/sketch";
import { rotateShopCounter } from "functions/sketch/rotate/counter";

export function handleRotateCounter(
  dispatch: (({ ...any }) => void),
  setSelectedCounter: (counter: Counter | null) => void,
  counter: Counter,
  counters: Counter[],
  cut_shapes: CutShape[],
  rotation: number
) {
  const axis: Vector2 = getCenterOfCounter(counter);
  const rotated_counter: Counter = rotateCounter(counter, rotation, axis);
  dispatch(updateLocalCounter(rotated_counter));
  dispatch(updateCounter(rotated_counter, true));
  dispatch(createRestorePoint(
    [...counters.filter(c => c.uuid !== rotated_counter.uuid), rotated_counter],
    [...cut_shapes],
    "Rotate Counter"
  ));
  setSelectedCounter(rotated_counter);
}

export function handleRotateShopCounter(
  dispatch: (({ ...any }) => void),
  setSelectedCounter: (counter: IShopCounter | null) => void,
  counter: IShopCounter,
  counters: IShopCounter[],
  cut_shapes: IShopCutShape[],
  rotation: number
) {
  const axis: Vector2 = getCenterOfCounter(counter);
  const rotated_counter: IShopCounter = rotateShopCounter(counter, rotation, axis);
  dispatch(updateLocalShopCounter(rotated_counter));
  dispatch(updateShopCounter(rotated_counter, true));
  dispatch(createRestorePoint(
    [...counters.filter(c => c.uuid !== rotated_counter.uuid), rotated_counter],
    [...cut_shapes],
    "Rotate Counter"
  ));
  setSelectedCounter(rotated_counter);
}
