/// TYPES ///
import { Area } from "api/types/quote";
import { Counter } from "api/types/sketch";
import { Label } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
/// COMPONENTS ///
import QuotePrintCounters from "./counter";
/// PDF COMPONENTS ///
import { View } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  area: Area,
  labels: Label[],
  counters: Counter[],
  dimensions: Vector2
}

export default function QuotePrintArea({ index, area, labels, counters, dimensions }: Props) {
  return (
    <View break={index > 0} style={{ paddingTop: "5px" }}>
      <View style={{ paddingLeft: "10px" }}>
        <Text style={{ fontFamily: "Montserrat", fontWeight: 400, fontSize: 10.5 }}>
          {area?.name ?? ""}: {`${area?.material_name ?? ""} ${area?.color_name ?? ""} ${getMaterialHeightDisplay(area?.material_thickness ?? "")}`}
        </Text>
      </View>
      <View style={{ marginTop: "15px", height: dimensions.Y, backgroundColor: "#fafafa", width: "100%" }}>
        <QuotePrintCounters counters={counters} labels={[]} />
      </View>
    </View>
  )
}
