/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { create_url } from "components/navigation/endpoints";
import { detail_url } from "components/navigation/endpoints";
import { stores_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";

export const stores_list: IBreadcrumb = {
  id: 0,
  label: "Stores",
  url: `/${stores_url}`
}

export const stores_add: IBreadcrumb = {
  id: 1,
  label: "Add",
  url: `/${stores_url}/${create_url}`
}

export const store: IBreadcrumb = {
  id: 2,
  label: "Store",
  url: `/${stores_url}/${detail_url}/id`
}

export const store_update: IBreadcrumb = {
  id: 3,
  label: "Update",
  url: `/${stores_url}/${detail_url}/id/${update_url}`
}

