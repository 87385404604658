/// UUID ///
import { v4 } from "uuid";
/// TYPES ///
import { Bump } from "api/types/sketch";
import { Counter } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { Point } from "api/types/sketch";
import { IShopBump } from "api/types/shop_quote/bump";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopPoint } from "api/types/shop_quote/point";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { getDistantPoint } from "../math";
import { getCenterOfCounter } from "../get";

function getDirection(center: Vector2): number {
  if (center.X < 200 && center.Y < 200) {
    return 135;
  }
  else if (center.X > 200 && center.Y > 200) {
    return -45;
  }
  else if (center.X < 200 && center.Y > 200) {
    return 45;
  }
  else if (center.X > 200 && center.Y < 200) {
    return -135;
  }
  return 45;
}

export function duplicateCounter(counter: Counter): Counter {
  const center: Vector2 = getCenterOfCounter(counter);
  const angle_change: number = getDirection(center);
  const new_counter_uuid: string = v4();

  const points: Point[] = [];
  for (let i = 0; i < counter.points.length; i++) {
    const location: Vector2 = getDistantPoint(counter.points[i].location, angle_change, 200);
    const point: Point = { ...counter.points[i], uuid: v4(), location: location };
    delete point['id'];
    points.push(point);
  }

  const corners: Corner[] = [];
  for (let i = 0; i < counter.corners.length; i++) {
    const location: Vector2 = getDistantPoint(counter.corners[i].location, angle_change, 200);
    const point_position: number = i >= counter.corners.length / 2 ? counter.corners.length - i - 1 : i;
    const corner: Corner = { ...counter.corners[i], uuid: v4(), location: location, counter_uuid: new_counter_uuid, point_uuid: points[point_position].uuid };

    const bumps: Bump[] = [];
    for (let j = 0; j < counter.corners[i].bumps.length; j++) {
      const bump: Bump = { ...counter.corners[i].bumps[j], uuid: v4() };
      delete bump["id"];
      bumps.push(bump);
    }
    corner.bumps = bumps;
    delete corner["id"];
    corners.push(corner);
  }

  const new_counter: Counter = {
    ...counter,
    uuid: new_counter_uuid,
    drawing_color: counter.drawing_color,
    points: points,
    corners: corners,
    cooktops: counter.cooktops.map(({ id, ...cooktop }) => {
      return (
        { ...cooktop, uuid: v4(), location: getDistantPoint(cooktop.location, angle_change, 200), counter_uuid: new_counter_uuid }
      )
    }),
    sinks: counter.sinks.map(({ id, ...sink }) => {
      return (
        { ...sink, uuid: v4(), location: getDistantPoint(sink.location, angle_change, 200), counter_uuid: new_counter_uuid }
      )
    })
  };
  delete new_counter["id"];
  return new_counter;
}

export function duplicateShopCounter(counter: IShopCounter): IShopCounter {
  const center: Vector2 = getCenterOfCounter(counter);
  const angle_change: number = getDirection(center);
  const new_counter_uuid: string = v4();

  const points: IShopPoint[] = [];
  for (let i = 0; i < counter.points.length; i++) {
    const location: Vector2 = getDistantPoint(counter.points[i].location, angle_change, 200);
    const point: IShopPoint = { ...counter.points[i], uuid: v4(), location: location };
    delete point['id'];
    points.push(point);
  }

  const corners: IShopCorner[] = [];
  for (let i = 0; i < counter.corners.length; i++) {
    const location: Vector2 = getDistantPoint(counter.corners[i].location, angle_change, 200);
    const point_position: number = i >= counter.corners.length / 2 ? counter.corners.length - i - 1 : i;
    const corner: IShopCorner = { ...counter.corners[i], uuid: v4(), location: location, counter_uuid: new_counter_uuid, point_uuid: points[point_position].uuid };

    const bumps: IShopBump[] = [];
    for (let j = 0; j < counter.corners[i].bumps.length; j++) {
      const bump: IShopBump = { ...counter.corners[i].bumps[j], uuid: v4() };
      delete bump["id"];
      bumps.push(bump);
    }
    corner.bumps = bumps;
    delete corner["id"];
    corners.push(corner);
  }

  const new_counter: IShopCounter = {
    ...counter,
    uuid: new_counter_uuid,
    drawing_color: counter.drawing_color,
    points: points,
    corners: corners,
    cooktops: counter.cooktops.map(({ id, ...cooktop }) => {
      return (
        { ...cooktop, uuid: v4(), location: getDistantPoint(cooktop.location, angle_change, 200), counter_uuid: new_counter_uuid }
      )
    }),
    sinks: counter.sinks.map(({ id, ...sink }) => {
      return (
        { ...sink, uuid: v4(), location: getDistantPoint(sink.location, angle_change, 200), counter_uuid: new_counter_uuid }
      )
    })
  };
  delete new_counter["id"];
  return new_counter;
}
