/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { header_style } from "styles/universal/section_header";

interface Props {
  payment_terms: string,
  title?: string
}

export default function TermsConditions({ payment_terms, title = "Terms & Conditions" }: Props) {
  return (
    <Fragment>
      <Grid item xs={12} sx={{ marginTop: "40px", paddingLeft: "5px" }}>
        <Typography variant="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <Grid item xs={12}>
        <Stack sx={{ paddingLeft: "5px", paddingRight: "5px" }}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            This quote is subject to the following terms and conditions:
          </Typography>
          <Typography variant="body2">
            {payment_terms}
          </Typography>
        </Stack>
      </Grid>
    </Fragment>
  )
}
