/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/material/dealer";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/material/dealer";

export const clearStoreMaterials = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_STORE_MATERIALS
    });
  }
}
