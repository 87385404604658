import { Vector2 } from "api/types/sketch";

export function getLateralLeftVertex(vertex: Vector2, angle: number, width: number): Vector2 {
  return getDistantPoint(vertex, angle - 90, width / 2);
}

export function getLateralRightVertex(vertex: Vector2, angle: number, width: number): Vector2 {
  return getDistantPoint(vertex, angle + 90, width / 2);
}

export function getBottomLeftVertex(vertex: Vector2, angle: number, width: number, height: number): Vector2 {
  return {
    X: Math.round(vertex.X - (width / 2) * Math.cos(angle * Math.PI / 180) - (height / 2) * Math.sin(angle * Math.PI / 180)),
    Y: Math.round(vertex.Y - (width / 2) * Math.sin(angle * Math.PI / 180) + (height / 2) * Math.cos(angle * Math.PI / 180))
  }
}

export function getTopLeftVertex(vertex: Vector2, angle: number, width: number, height: number): Vector2 {
  return {
    X: Math.round(vertex.X - (width / 2) * Math.cos(angle * Math.PI / 180) + (height / 2) * Math.sin(angle * Math.PI / 180)),
    Y: Math.round(vertex.Y - (width / 2) * Math.sin(angle * Math.PI / 180) - (height / 2) * Math.cos(angle * Math.PI / 180))
  }
}

export function getBottomRightVertex(vertex: Vector2, angle: number, width: number, height: number): Vector2 {
  return {
    X: Math.round(vertex.X + (width / 2) * Math.cos(angle * Math.PI / 180) - (height / 2) * Math.sin(angle * Math.PI / 180)),
    Y: Math.round(vertex.Y + (width / 2) * Math.sin(angle * Math.PI / 180) + (height / 2) * Math.cos(angle * Math.PI / 180))
  }
}

export function getTopRightVertex(vertex: Vector2, angle: number, width: number, height: number): Vector2 {
  return {
    X: Math.round(vertex.X + (width / 2) * Math.cos(angle * Math.PI / 180) + (height / 2) * Math.sin(angle * Math.PI / 180)),
    Y: Math.round(vertex.Y + (width / 2) * Math.sin(angle * Math.PI / 180) - (height / 2) * Math.cos(angle * Math.PI / 180))
  }
}

export function getDistantPoint(vertex: Vector2, angle: number, distance: number): Vector2 {
  return {
    X: Math.round(vertex.X + distance * Math.sin(angle * Math.PI / 180)),
    Y: Math.round(vertex.Y - distance * Math.cos(angle * Math.PI / 180))
  }
}

export function getDistance(start_vertex: Vector2, end_vertex: Vector2): number {
  return Math.round(Math.sqrt(Math.pow(end_vertex.X - start_vertex.X, 2) + Math.pow(end_vertex.Y - start_vertex.Y, 2)));
}

export function getAngle(start_vertex: Vector2, end_vertex: Vector2): number {
  return Math.atan2(end_vertex.Y - start_vertex.Y, end_vertex.X - start_vertex.X) * 180 / Math.PI;
}

export function getDimensions(start_vertex: Vector2, mouse_pointer: Vector2): Vector2 {
  return {
    Y: mouse_pointer.Y - start_vertex.Y,
    X: mouse_pointer.X - start_vertex.X
  }
}

export function getLineIntersection(a: Vector2, b: Vector2, c: Vector2, d: Vector2): Vector2 {
  return {
    X: Math.round(-1 * ((a.X - b.X) * (c.X * d.Y - d.X * c.Y) - (d.X - c.X) * (b.X * a.Y - a.X * b.Y)) / ((c.Y - d.Y) * (a.X - b.X) - (d.X - c.X) * (b.Y - a.Y))),
    Y:
      Math.round(-1 *
        (c.X * d.Y * a.Y - c.X * d.Y * b.Y - d.X * c.Y * a.Y + d.X * c.Y * b.Y - c.Y * a.X * b.Y +
          c.Y * b.X * a.Y + d.Y * a.X * b.Y - d.Y * b.X * a.Y)
        / (-1 * c.X * a.Y + c.X * b.Y + d.X * a.Y - d.X * b.Y + c.Y * a.X - c.Y * b.X - d.Y * a.X + d.Y * b.X))
  }
}

export function getHypoteneuse(A: number, B: number) {
  return Math.sqrt(Math.pow(A, 2) + Math.pow(B, 2));
}

export function makeAnglePositive(angle: number, zero_adjustment: boolean): number {
  if (zero_adjustment) {
    if (angle <= 0) {
      angle += 360;
      if (angle <= 0) {
        makeAnglePositive(angle, zero_adjustment);
      }
      else {
        return angle;
      }
    }
  }
  else {
    if (angle < 0) {
      angle += 360;
      if (angle < 0) {
        makeAnglePositive(angle, zero_adjustment);
      }
      else {
        return angle;
      }
    }
  }
  return Math.round(angle);
}

export function clockwise(a: number, b: number): boolean {

  let clockwise, diff
  if (b > a) {
    diff = b - a
    clockwise = diff >= 0 && diff <= 180
  } else {
    diff = a - b
    clockwise = diff >= 180
  }
  return clockwise;
}

export function getMidpoint(a: Vector2, b: Vector2): Vector2 {
  return { X: (a.X + b.X) / 2, Y: (a.Y + b.Y) / 2 };
}

export function isPointInRectangle(point: Vector2, angle: number, width: number, height: number, test_point: Vector2): boolean {
  const A: Vector2 = getBottomLeftVertex(point, angle, width, height);
  const B: Vector2 = getTopLeftVertex(point, angle, width, height);
  const C: Vector2 = getTopRightVertex(point, angle, width, height);
  const D: Vector2 = getBottomRightVertex(point, angle, width, height);

  return pointInQuad(A, B, C, D, test_point);
}

export function pointInQuad(A: Vector2, B: Vector2, C: Vector2, D: Vector2, test_point: Vector2): boolean {
  const AB: Vector2 = vector(A, B);
  const AD: Vector2 = vector(A, D);
  const AM: Vector2 = vector(A, test_point);
  const dotAMAB: number = dot(AM, AB);
  const dotABAB: number = dot(AB, AB);
  const dotAMAD: number = dot(AM, AD);
  const dotADAD: number = dot(AD, AD);

  return 0 <= dotAMAB && dotAMAB <= dotABAB && 0 <= dotAMAD && dotAMAD <= dotADAD;
}

function vector(p1: Vector2, p2: Vector2): Vector2 {
  return {
    X: (p2.X - p1.X),
    Y: (p2.Y - p1.Y)
  };
}

export function dot(u: Vector2, v: Vector2): number {
  return u.X * v.X + u.Y * v.Y;
}

export function toRadians(degrees: number): number {
  return degrees * Math.PI / 180;
}

export function toDegrees(radians: number): number {
  return radians * 180 / Math.PI;
}

export function getAverageAngle(A: number, B: number) {
  if (A > B) {
    const temp: number = A;
    A = B;
    B = temp;
  }

  if (B - A > 180) {
    B -= 360;
  }

  let final_angle: number = (B + A) / 2;
  if (final_angle < 0) {
    final_angle += 360;
  }

  return final_angle;
}
