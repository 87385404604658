/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/end_customer";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/end_customer";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// URLS ///
import { url_prefix } from "settings";
import { end_customer_url } from "../../endpoint";

export const deleteCustomer = (id: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.DELETE_END_CUSTOMER_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${end_customer_url}/${id}/`;

    try {
      const { data } = await axios.delete(url, config);

      dispatch({
        type: ActionType.DELETE_END_CUSTOMER_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.DELETE_END_CUSTOMER_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
