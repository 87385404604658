import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Steppers from "components/universal/stepper";
import { payment_setup_steps } from "values/steps";
import { mainContentAreaStyle } from "styles/style";
  
export default function SubscriptionSetupCancellation() {
    return (
        <Box sx={mainContentAreaStyle}>
            <Grid container sx={{width: "100%", paddingLeft: 3.5, paddingRight: 3.5}}>
                <Grid item xs={10}>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <b>Something went wrong</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{marginTop: 1}}>
                            It looks like your payment setup is incomplete. You will need to set up payment in order to activate your account.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{marginTop: 1}}>
                                Once payment is set up, you will receive 1 month free trial and will not be charged until the trial is complete.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Steppers current_step={-1} steps={payment_setup_steps}/>
                </Grid>
            </Grid>
        </Box>
    );
}