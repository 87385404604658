/// REACT ///
import { KeyboardEvent } from "react";
import { useEffect } from "react";
import { useRef } from "react";
/// MUI ///
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// ICONS ///
import CloseIcon from "@mui/icons-material/Close";
/// STYLES ///
import { drawerGridContainer } from "styles/drawer";
import { drawerGridFormAreaStyle } from "styles/drawer";
import { drawerTitleStyle } from "styles/drawer";
import { drawerGridSaveButtonAreaStyle } from "styles/drawer";
import { drawerIconBarStyle } from "styles/drawer";

interface Props {
  open: boolean,
  close: () => void,
  save: () => void,
  outlet_cutout_count: number,
  setOutletCutoutCount: (amount: number) => void
}

export default function UpdateOutletsDrawer({
  open,
  close,
  save,
  outlet_cutout_count,
  setOutletCutoutCount
}: Props) {
  const ref = useRef<HTMLInputElement | null>(null);

  const keyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      save();
    }
    else if (event.key === "Escape") {
      event.preventDefault();
      close();
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref !== null && ref.current !== null) {
        ref.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  return (
    <SwipeableDrawer
      BackdropProps={{ invisible: true }}
      onClose={close}
      onOpen={() => void 0}
      onKeyDown={e => keyDown(e)}
      anchor="right"
      open={open}>
      <Grid container sx={drawerGridContainer}>
        <Grid item xs={12} sx={drawerIconBarStyle} justifyContent="end">
          <IconButton
            onClick={close}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={drawerTitleStyle}>
          <Typography variant="h4" align="center">
            Outlet Count
          </Typography>
        </Grid>
        <Grid item xs={12} sx={drawerGridFormAreaStyle}>
          <TextField
            fullWidth
            type="number"
            value={outlet_cutout_count > 0 ? outlet_cutout_count : ""}
            onChange={(e) => setOutletCutoutCount(Number(e.target.value))}
            label="Number Of Outlets" />
        </Grid>
        <Grid item xs={12} sx={drawerGridSaveButtonAreaStyle} justifyContent="center">
          <Button
            onClick={save}>
            Save
          </Button>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
}
