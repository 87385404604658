import { IShopLabel } from "api/types/shop_quote/label";

export enum ActionType {
  CREATE_SHOP_LABEL = "CREATE_SHOP_LABEL",
  DELETE_SHOP_LABEL = "DELETE_SHOP_LABEL",
  GET_SHOP_LABELS = "GET_SHOP_LABELS",
  UPDATE_SHOP_LABEL = "UPDATE_SHOP_LABEL",
  UPDATE_LOCAL_SHOP_LABEL = "UPDATE_LOCAL_SHOP_LABEL"
}

interface IShopCreateLabel {
  type: ActionType.CREATE_SHOP_LABEL,
  label: IShopLabel
}

interface IShopDeleteLabel {
  type: ActionType.DELETE_SHOP_LABEL,
  id: number
}

interface IShopGetLabels {
  type: ActionType.GET_SHOP_LABELS,
  labels: IShopLabel[]
}

interface IShopUpdateLabel {
  type: ActionType.UPDATE_SHOP_LABEL
}

interface IShopUpdateLocalLabel {
  type: ActionType.UPDATE_LOCAL_SHOP_LABEL,
  label: IShopLabel
}

export type Action =
  IShopCreateLabel |
  IShopDeleteLabel |
  IShopGetLabels |
  IShopUpdateLabel |
  IShopUpdateLocalLabel;
