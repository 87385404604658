/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { item_style } from "styles/quote_preview/section";
/// URLS ///
import { org_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";
import { billing_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { org } from "values/breadcrumbs/organization";

export default function OrgProfile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    loading,
    user
  } = useAppSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(setBreadcrumbs([org]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start">
        <Grid container item xs={12} lg={6}>
          <PageTitle title="Organization" endProps={
            <Box>
              <BaseButton text="Billing" icon={faCreditCard} clickEvent={(() => navigate(`/${org_url}/${billing_url}`))} />
              <BaseButton text="Edit" icon={faEdit} clickEvent={() => navigate(`/${org_url}/${update_url}`)} />
            </Box>
          } />
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Name</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user.business_name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style(false)}>
              <b>Logo</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              {
                user.logo ?
                  <img
                    style={{ objectFit: "contain" }}
                    src={user.logo as string}
                    width="200px"
                    height="100px"
                    alt="Logo Not Found" /> :
                  null
              }
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style(false)}>
              <b>Logo Icon</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              {
                user.logo_icon ?
                  <img
                    style={{ objectFit: "contain" }}
                    src={user.logo_icon as string}
                    width="40px"
                    height="40px"
                    alt="Logo Icon Not Found" /> :
                  null
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Loading loading={loading} />
    </motion.div>
  )
}
