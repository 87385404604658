/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { createCustomEvent } from "api/actions/event";
import { createOrderEvent } from "api/actions/event";
import { createSelfOrderEvent } from "api/actions/event";
import { getMarketOrders } from "api/actions/order/market_order";
import { getWOs } from "api/actions/wo/wo";
import { getShopOrders } from "api/actions/order/shop_order";
import { resetActionSuccess } from "api/actions/event/local/reset_action_success";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
import StyledRadio from "components/universal/styled_radio";
/// TYPES ///
import { ICustomEvent } from "api/types/event";
import { IOrderEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event";
import { IEventError } from "api/types/event";
/// FM ///
import { motion } from "framer-motion";
/// LUXON ///
import { DateTime } from "luxon";
/// MUI ///
import Box from "@mui/material/Box";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { calendar_content_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// VALUES ///
import { SERVICE_CALL } from "values/event";
import { INSTALL } from "values/event";
import { TEMPLATE } from "values/event";
import { FABRICATION } from "values/event";
import { ORDER_MATERIAL } from "values/event";
import { SERVICE_COLOR } from "values/event";
import { getEventColor } from "values/event";
import { getEventType } from "values/event";

const NONE: string = "NONE";
const ORDER: string = "ORDER";
const SELF_ORDER: string = "SELF_ORDER";

export default function CreateEvent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [event, setEvent] = useState<ICustomEvent | IOrderEvent | ISelfOrderEvent>({ time: DateTime.now().toISO(), color: SERVICE_COLOR, event_type: SERVICE_CALL });
  const [order, setOrder] = useState<number>(0);
  const [self_order, setSelfOrder] = useState<number>(0);
  const [errors, setErrors] = useState<IEventError>({});
  const { user } = useAppSelector((state) => state.authentication);
  const { action_success } = useAppSelector((state) => state.event);
  const { nav_date } = useAppSelector((state) => state.event);
  const { updating } = useAppSelector((state) => state.event);
  const { orders } = useAppSelector((state) => state.order);
  const { self_orders } = useAppSelector((state) => state.self_order);
  const [event_type, setEventType] = useState<string>(NONE);

  useEffect(() => {
    if (action_success) {
      dispatch(resetActionSuccess());
      navigate(-1);
    }
  }, [action_success, dispatch, navigate]);

  useEffect(() => {
    setEvent(prev => {
      return { ...prev, time: nav_date };
    });
    if (user.is_fabricator) {
      dispatch(getWOs({
        start_date: DateTime.fromISO(nav_date).plus({ month: -3 }).endOf("month").endOf("day").toISO(),
        end_date: DateTime.fromISO(nav_date).plus({ month: 3 }).endOf("month").endOf("day").toISO()
      }));
      dispatch(getShopOrders({
        start_date: DateTime.fromISO(nav_date).plus({ month: -3 }).endOf("month").endOf("day").toISO(),
        end_date: DateTime.fromISO(nav_date).plus({ month: 3 }).endOf("month").endOf("day").toISO()
      }));

    }
    else {
      dispatch(getMarketOrders({
        start_date: DateTime.fromISO(nav_date).plus({ month: -3 }).endOf("month").endOf("day").toISO(),
        end_date: DateTime.fromISO(nav_date).plus({ month: 3 }).endOf("month").endOf("day").toISO()
      }));
    }
  }, [dispatch, nav_date, user]);

  const createEvent = () => {
    let errors: IEventError = {};
    let error: boolean = false;

    if (!event?.title || event?.title === "") {
      errors.title_error = "Must have a title";
      errors.title_has_error = true;
      error = true;
    }

    if (!event?.time) {
      errors.time_error = "Must enter a date";
      errors.time_has_error = true;
      error = true;
    }

    if (event_type === NONE) {
      setErrors(errors);
      if (error) {
        return;
      }
      dispatch(createCustomEvent(event as ICustomEvent));
    }
    else if (event_type === ORDER) {
      if (!order || order === 0) {
        errors.order_error = "Must assign an order";
        errors.order_has_error = true;
        error = true;
      }
      setErrors(errors);
      if (error) {
        return;
      }
      dispatch(createOrderEvent({ ...event, order: order } as IOrderEvent));
    }
    else if (event_type === SELF_ORDER) {
      if (!self_order || self_order === 0) {
        errors.self_order_error = "Must assign an order";
        errors.self_order_has_error = true;
        error = true;
      }
      setErrors(errors);
      if (error) {
        return;
      }
      dispatch(createSelfOrderEvent({ ...event, self_order: self_order } as ISelfOrderEvent));
    }
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={calendar_content_style}>
      <Grid container sx={{ paddingTop: "25px", paddingRight: "75px" }}>
        <PageTitle title="Create Calendar Event" endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(-1)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={createEvent} text="Create" icon={faSave} />
          </Box>
        } />
        <Grid item xs={12}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <TextField
            sx={{ width: "400px" }}
            onChange={e => setEvent({ ...event, title: e.target.value })}
            error={errors?.title_has_error ?? false}
            helperText={errors?.title_error ?? ""}
            label="Name"
            size="small" />
        </Grid>
        <Grid item xs={12}>
          <DateTimePicker
            sx={{ width: "400px" }}
            value={DateTime.fromISO(event?.time ?? DateTime.now().toISO())}
            slotProps={{ textField: { size: "small", InputLabelProps: { shrink: true }, fullWidth: true } }}
            label="Date"
            onChange={e => setEvent({ ...event, time: e.toISO() })} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            defaultValue={SERVICE_CALL}
            sx={{ width: "400px" }}
            onChange={e => setEvent({ ...event, event_type: e.target.value, color: getEventColor(e.target.value) })}
            label="Type"          >
            <MenuItem id={SERVICE_CALL} value={SERVICE_CALL}>
              {getEventType(SERVICE_CALL)}
            </MenuItem>
            <MenuItem id={TEMPLATE} value={TEMPLATE}>
              {getEventType(TEMPLATE)}
            </MenuItem>
            <MenuItem id={INSTALL} value={INSTALL}>
              {getEventType(INSTALL)}
            </MenuItem>
            <MenuItem id={ORDER_MATERIAL} value={ORDER_MATERIAL}>
              {getEventType(ORDER_MATERIAL)}
            </MenuItem>
            <MenuItem id={FABRICATION} value={FABRICATION}>
              {getEventType(FABRICATION)}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={{ width: "400px", marginTop: "10px" }}
            defaultValue={event.description}
            multiline
            minRows={3}
            label="Description"
            onChange={(e) => setEvent({ ...event, description: e.target.value })} />
        </Grid>
        <FormControl sx={{ paddingLeft: "5px", marginTop: "10px" }}>
          <FormLabel id="add-customer-radios">
            <Typography variant="body2">
              Attach Order
            </Typography>
          </FormLabel>
          <RadioGroup row defaultValue={NONE} aria-labelledby="event-radios" name="event-radios" onChange={e => setEventType(e.target.value)}>
            <FormControlLabel
              value={NONE}
              control={<StyledRadio />}
              label={
                <Typography variant="body2">
                  None
                </Typography>
              } />
            <FormControlLabel
              value={ORDER}
              control={<StyledRadio />}
              label={
                <Typography variant="body2">
                  Market Order
                </Typography>
              } />
            {
              user?.is_fabricator ?
                <FormControlLabel
                  value={SELF_ORDER}
                  control={<StyledRadio />}
                  label={
                    <Typography variant="body2">
                      Shop Order
                    </Typography>
                  } /> :
                null
            }
          </RadioGroup>
        </FormControl>
        {
          event_type === ORDER ?
            <Grid item xs={12}>
              <TextField
                style={{ width: "400px", marginTop: "10px" }}
                SelectProps={{
                  MenuProps: {
                    sx: { height: "400px" },
                  },
                }}
                defaultValue=""
                select
                error={errors?.order_has_error ?? false}
                helperText={errors?.order_error ?? ""}
                label="Market Order"
                onChange={(e) => setOrder(Number(e.target.value))}>
                <MenuItem key="" value="">
                </MenuItem>
                {
                  orders.map(order => {
                    return (
                      <MenuItem key={order?.id} value={order?.id}>
                        {order?.name}
                      </MenuItem>
                    )
                  })
                }
              </TextField>
            </Grid> :
            null
        }
        {
          event_type === SELF_ORDER ?
            <Grid item xs={12}>
              <TextField
                style={{ width: "400px", marginTop: "10px" }}
                SelectProps={{
                  MenuProps: {
                    sx: { height: "400px" },
                  },
                }}
                defaultValue=""
                select
                label="Shop"
                error={errors?.self_order_has_error ?? false}
                helperText={errors?.self_order_error ?? ""}
                onChange={(e) => setSelfOrder(Number(e.target.value))}>
                <MenuItem key="" value="">
                </MenuItem>
                {
                  self_orders.map(so => {
                    return (
                      <MenuItem key={so?.id} value={so?.id}>
                        {so?.name}
                      </MenuItem>
                    )
                  })
                }
              </TextField>
            </Grid> :
            null
        }
      </Grid>
      <Loading loading={updating} />
    </motion.div>
  )
}
