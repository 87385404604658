import { Bump } from "api/types/sketch";
import { CutBump } from "api/types/sketch";

export enum ActionType {
  CREATE_LOCAL_CUT_BUMP = "CREATE_LOCAL_CUT_BUMP",
  UPDATE_LOCAL_CUT_BUMP = "UPDATE_LOCAL_CUT_BUMP",
  DELETE_LOCAL_CUT_BUMP = "DELETE_LOCAL_CUT_BUMP"
}

interface ICreateLocalCutBump {
  type: ActionType.CREATE_LOCAL_CUT_BUMP,
  payload: CutBump,
  bump: Bump
}

interface IUpdateLocalCutBump {
  type: ActionType.UPDATE_LOCAL_CUT_BUMP,
  payload: CutBump,
  bump: Bump
}

interface IDeleteLocalCutBump {
  type: ActionType.DELETE_LOCAL_CUT_BUMP,
  payload: Bump
}

export type Action =
  ICreateLocalCutBump |
  IUpdateLocalCutBump |
  IDeleteLocalCutBump;
