import { Vector2 } from "api/types/sketch";
import { BumpArcConstruct } from "api/types/sketch";
import { constructBumpOutArc } from "../construct";

export function drawPrintBumpOutArc(
    painter: any,
    start: Vector2,
    end: Vector2,
    distance: number,
    width: number,
    depth: number,
    angle: number,
    zoom: number
){
    const bump_construct: BumpArcConstruct = constructBumpOutArc(start, end, angle, distance, width, depth, zoom);

    painter.lineTo(bump_construct.start.X, bump_construct.start.Y);
    painter.quadraticCurveTo(bump_construct.top.X, bump_construct.top.Y, bump_construct.end.X, bump_construct.end.Y);
}