export const shape_container_style = {
  width: "100%",
  height: "250px",
  backgroundColor: "#fafafa",
  marginTop: "5px"
}

export const counter_container_style = (height: number = 300) => ({
  width: "100%",
  height: `${height}px`,
  backgroundColor: "#fafafa",
  marginTop: "5px",
});
