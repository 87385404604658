/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/authentication";
/// TYPES ///
import { ChangePassword } from "api/types/authentication";
/// URLS ///
import { url_prefix } from "settings";
import { change_password_url } from "../endpoint";

export const changePassword = (change_password: ChangePassword) => {
  return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const { user } = getState().authentication;
    dispatch({
      type: ActionType.CHANGE_PASSWORD_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${change_password_url}/`;

    try {
      const { data } = await axios.post(url, change_password, config);

      dispatch({
        type: ActionType.CHANGE_PASSWORD,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError && err.response && (err.response.status === 400 || err.response.status === 401)) {
        let error: string = "";
        if (err.response.data.old_password) {
          error = err.response.data.old_password;
        }
        else if (err.response.data.password) {
          error = err.response.data.password;
        }
        else if (err.response.data.password2) {
          error = err.response.data.password2;
        }
        else {
          error = err.response.data;
        }

        dispatch({
          type: ActionType.CHANGE_PASSWORD_FAIL,
          payload: error
        });
      }
      else {
        dispatch({
          type: ActionType.CHANGE_PASSWORD_FAIL,
          payload: "An unknown error has occurred please contact the administrator"
        });
      }
    }
  }
}
