import { IShopQuoteLineItem } from "api/types/shop_quote/quote_line_item";

export enum ActionType {
  SET_SELECTED_SHOP_LINE_ITEM = "SET_SELECTED_SHOP_LINE_ITEM"
}

interface ISetSelectedLineItem {
  type: ActionType.SET_SELECTED_SHOP_LINE_ITEM,
  payload: IShopQuoteLineItem
}

export type Action = ISetSelectedLineItem;
