/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getFabricatorPriceList } from "api/actions/fabricator";
import { getFabricatorFinishedEdges } from "api/actions/fabricator/finished_edge";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import FabricatorFinishedEdgeItem from "./row";
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { title_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { list } from "values/breadcrumbs/market/pl";
import { overview } from "values/breadcrumbs/market/pl";
import { finished_edge } from "values/breadcrumbs/market/pl";

export default function FabricatorFinishedEdges() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    fabricator_price_list: pl,
    fabricator_finished_edges
  } = useAppSelector((state) => state.fabricator);
  const [loading, setLoading] = useState<number[]>([]);

  const updateLoading = (is_loading: boolean, id: number) => {
    if (is_loading) {
      setLoading([...loading, id]);
    }
    else {
      setLoading([...loading.filter(i => i !== id)]);
    }
  }

  useEffect(() => {
    if (!(pl?.id) || pl.id.toString() !== id) {
      dispatch(getFabricatorPriceList(id));
    }
    if (id && isNaN(Number(id)) === false) {
      dispatch(getFabricatorFinishedEdges(Number(id)));
    }
    dispatch(setBreadcrumbs([list, { ...overview, url: overview.url.replace("id", id) }, { ...finished_edge, url: finished_edge.url.replace("id", id) }]));
  }, [id, dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title={pl?.price_list_data?.name ?? ""} endProps={<div></div>} />
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Finished Edge
            </Typography>
          </Grid>
          {fabricator_finished_edges.map((item, index) => <FabricatorFinishedEdgeItem key={index} index={index} item={item} updateLoading={updateLoading} />)}
        </Grid>
      </Grid>
      <Loading loading={loading.length > 0} />
    </motion.div>
  )
}
