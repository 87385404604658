import { Counter } from "api/types/sketch";
import { Text } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { DrawOptions } from "api/types/sketch";
import { degreeText } from "../text";
import { widthText } from "../text";
import { drawText } from "../text";
import { getMidpoint } from "functions/sketch/math";
import { dark_grey_color } from "styles/style";
import { grey_color } from "styles/style";
import { constructWidthMeasurementIndicator } from "functions/sketch/create";
import { checkIndicatorSelected } from "functions/sketch/check/indicator";
import { drawMeasurementIndicator } from "../indicator";

export function drawDegrees(
    context: CanvasRenderingContext2D,
    counter: Counter,
    mouse: Vector2,
    zoom: number,
    offset: Vector2,
    options: DrawOptions
): Text | null {
    let highlight: Text | null = null;
    if(options.show_degrees){
        context.strokeStyle = grey_color;
        context.lineWidth = 1;
        for(let j = 0; j < counter.points.length; j++){
            if(j !== 0 && j !== counter.points.length - 1){
                const current_location: Vector2 = {
                    X: Math.round(counter.points[j].location.X * zoom) + offset.X,
                    Y: Math.round(counter.points[j].location.Y * zoom) + offset.Y
                };
                const next_location: Vector2 = {
                    X: Math.round(counter.points[j+1].location.X * zoom) + offset.X,
                    Y: Math.round(counter.points[j+1].location.Y * zoom) + offset.Y
                };
                let degrees: number = Math.abs(counter.points[j].angle - counter.points[j - 1].angle);
                if(degrees > 180){ degrees -= 360; }

                const degree_text: Text = degreeText(
                    current_location,
                    Math.abs(degrees),
                    counter.points[j].uuid,
                    counter.uuid
                );

                const degree_selected: boolean = checkIndicatorSelected(
                    current_location,
                    mouse,
                    options.text_selectable
                );

                drawText(context, dark_grey_color, degree_text, degree_selected);

                if(degree_selected){ highlight = degree_text; }

                if(j !== counter.points.length - 2){
                    const text_location: Vector2 = getMidpoint(current_location, next_location);

                    const width_text: Text = widthText(
                        text_location,
                        counter.points[j].width,
                        counter.points[j].uuid,
                        counter.uuid
                    );

                    const selected: boolean = checkIndicatorSelected(text_location, mouse, options.text_selectable);
                    drawText(context, dark_grey_color, width_text, selected);

                    if(selected){
                        highlight = width_text;
                    }

                    if(counter.points[j].width / 4 > 20){
                        const width_measurement: Path2D = constructWidthMeasurementIndicator(
                            text_location,
                            counter.points[j].width / 4,
                            counter.points[j].angle + 90
                        );

                        drawMeasurementIndicator(context, dark_grey_color, width_measurement, selected);
                    }
                }
            }
        }
    }
    return highlight;
}