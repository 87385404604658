/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { filter_url } from "components/navigation/endpoints";
import { order_url } from "components/navigation/endpoints";
import { store_url } from "components/navigation/endpoints";

export const store_order_list: IBreadcrumb = {
  id: 0,
  label: "Orders",
  url: `/${store_url}/${order_url}`
}

export const store_order_filter: IBreadcrumb = {
  id: 1,
  label: "Filter",
  url: `/${store_url}/${order_url}/${filter_url}`
}

export const store_order: IBreadcrumb = {
  id: 2,
  label: "Order",
  url: `/${store_url}/${order_url}/id`
}
