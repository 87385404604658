import { Vector2 } from "api/types/sketch";
import { Bump } from "api/types/sketch";
import { OrderBump } from "api/types/order/order";
import { SelfOrderBump } from "api/types/order/self_order";
import { drawPrintFullWidth } from "../";
import { drawPrintBumpIn } from "../";
import { drawPrintBumpOut } from "../";
import { drawPrintBumpInArc } from "../";
import { drawPrintBumpOutArc } from "../";
import { NO_BUMP } from "values/values";
import { BUMP_IN } from "values/values";
import { BUMP_OUT } from "values/values";
import { BUMP_IN_ARC } from "values/values";
import { BUMP_OUT_ARC } from "values/values";
import { FULL_ARC } from "values/values";
import { adjustMeasurementToScale } from "../../scale";
import { inch_to_pixel } from "values/values";

export function drawPrintBumps(
  painter: any,
  unsorted_bumps: Bump[],
  current_location: Vector2,
  next_location: Vector2,
  angle: number,
  zoom: number
) {
  let is_full_arc: boolean = false;
  const bumps: Bump[] = [...unsorted_bumps];
  bumps.sort(function (a, b) {
    if (a.distance < b.distance) {
      return -1;
    }
    else if (a.distance > b.distance) {
      return 1;
    }
    else {
      return 0;
    }
  });

  for (let j = 0; j < bumps.length; j++) {
    if (bumps[j].bump_type === FULL_ARC) {
      is_full_arc = true;
      drawPrintFullWidth(
        painter,
        current_location,
        next_location,
        bumps[j].depth * 4,
        angle,
        zoom
      );
    }
  }

  if (!is_full_arc) {
    for (let j = 0; j < bumps.length; j++) {
      if (bumps[j].bump_type === NO_BUMP) {
        continue;
      }
      else if (bumps[j].bump_type === BUMP_IN) {
        drawPrintBumpIn(
          painter,
          current_location,
          next_location,
          bumps[j].distance * inch_to_pixel,
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          bumps[j].left_side_angle,
          bumps[j].right_side_angle,
          zoom
        );
      }
      else if (bumps[j].bump_type === BUMP_OUT) {
        drawPrintBumpOut(
          painter,
          current_location,
          next_location,
          bumps[j].distance * inch_to_pixel,
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          bumps[j].left_side_angle,
          bumps[j].right_side_angle,
          zoom
        );
      }
      else if (bumps[j].bump_type === BUMP_IN_ARC) {
        drawPrintBumpInArc(
          painter,
          current_location,
          next_location,
          bumps[j].distance * inch_to_pixel,
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          zoom
        );
      }
      else if (bumps[j].bump_type === BUMP_OUT_ARC) {
        drawPrintBumpOutArc(
          painter,
          current_location,
          next_location,
          bumps[j].distance * inch_to_pixel,
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          zoom
        );
      }
    }
  }
}

export function drawOrderPrintBumps(
  painter: any,
  unsorted_bumps: OrderBump[] | SelfOrderBump[],
  current_location: Vector2,
  next_location: Vector2,
  angle: number,
  zoom: number
) {
  let is_full_arc: boolean = false;
  const bumps: OrderBump[] | SelfOrderBump[] = [...unsorted_bumps];
  bumps.sort(function (a, b) {
    if (a.distance < b.distance) {
      return -1;
    }
    else if (a.distance > b.distance) {
      return 1;
    }
    else {
      return 0;
    }
  });

  for (let j = 0; j < bumps.length; j++) {
    if (bumps[j].bump_type === FULL_ARC) {
      is_full_arc = true;
      drawPrintFullWidth(
        painter,
        current_location,
        next_location,
        bumps[j].depth * 4,
        angle,
        zoom
      );
    }
  }

  if (!is_full_arc) {
    for (let j = 0; j < bumps.length; j++) {
      if (bumps[j].bump_type === NO_BUMP) {
        continue;
      }
      else if (bumps[j].bump_type === BUMP_IN) {
        drawPrintBumpIn(
          painter,
          current_location,
          next_location,
          bumps[j].distance * inch_to_pixel,
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          bumps[j].left_side_angle,
          bumps[j].right_side_angle,
          zoom
        );
      }
      else if (bumps[j].bump_type === BUMP_OUT) {
        drawPrintBumpOut(
          painter,
          current_location,
          next_location,
          bumps[j].distance * inch_to_pixel,
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          bumps[j].left_side_angle,
          bumps[j].right_side_angle,
          zoom
        );
      }
      else if (bumps[j].bump_type === BUMP_IN_ARC) {
        drawPrintBumpInArc(
          painter,
          current_location,
          next_location,
          bumps[j].distance * inch_to_pixel,
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          zoom
        );
      }
      else if (bumps[j].bump_type === BUMP_OUT_ARC) {
        drawPrintBumpOutArc(
          painter,
          current_location,
          next_location,
          bumps[j].distance * inch_to_pixel,
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          zoom
        );
      }
    }
  }
}
