import { Dispatch } from "redux";
import { Action } from "api/action_types/self_order";
import { ActionType } from "api/action_types/self_order";

export const resetSelfOrderSuccess = () => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.RESET_SELF_ORDER_SUCCESS
        });
    }
}