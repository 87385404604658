import { Dispatch } from "redux";
import { Action } from "api/action_types/history";
import { ActionType } from "api/action_types/history";

export const redo = () => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.REDO
        });
    }
}