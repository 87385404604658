import { Counter } from "api/types/sketch";
import { Seam } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getCenterOfCounter } from "functions/sketch";
import { drawSeamLines } from "../outline";
import { getOptimalZoom } from "functions/sketch";

export function drawSeamCounter(
  path: Path2D,
  edge_path: Path2D,
  seam_path: Path2D,
  seam_highlight: Path2D,
  counter: Counter,
  width: number,
  height: number,
  mouse: Vector2
): Seam | null {
  let zoom: number = getOptimalZoom(counter, { X: width - 100, Y: height - 100 });

  if (zoom > 1) {
    zoom = 1;
  }

  const center_of_counter: Vector2 = getCenterOfCounter(counter, zoom);

  const seam: Seam | null = drawSeamLines(
    counter,
    counter.corners,
    path,
    edge_path,
    seam_path,
    seam_highlight,
    center_of_counter,
    { X: width / 2, Y: height / 2 },
    zoom,
    mouse
  );
  return seam;
}
