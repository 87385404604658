import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { print_header } from "styles/print";
import { subtitle } from "styles/print";



export default function PrintSummaryHeader() {
  return (
    <View style={{ marginTop: "25px", display: "flex" }}>
      <View style={{ paddingLeft: "10px" }}>
        <Text style={{ ...subtitle, textTransform: "uppercase" }}>
          Summary
        </Text>
      </View>
      <View style={print_header}></View>
    </View>
  );
}
