/// TYPES ///
import { Area } from "api/types/quote";
import { CutShape } from "api/types/sketch";
/// COMPONENTS ///
import QuotePrintSlab from "./slab";
/// PDF ///
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
/// STYLES ///
import { subtitle } from "styles/print";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  area: Area,
  shapes: CutShape[]
}

export default function QuotePrintSlabs({ area, shapes }: Props) {
  return (
    <View style={{ display: "flex", flexWrap: "wrap", marginTop: "10px", flexDirection: "row", justifyContent: "space-between" }}>
      <View style={{ paddingLeft: "10px", width: "100%", paddingBottom: "10px" }}>
        <Text style={subtitle}>
          {`${area?.material_name ?? ""} ${area?.color_name ?? ""} ${getMaterialHeightDisplay(area?.material_thickness ?? "")}`}
        </Text>
      </View>

      {area.slabs.map((slab, index) => <QuotePrintSlab key={index} index={index} area={area} slab={slab} shapes={shapes.filter(sh => sh.slab_uuid === slab.uuid)} />)}
    </View>
  )
}
