/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// TYPES ///
import { FabricatorMiter } from "api/types/fabricator";
/// ACTIONS ///
import { Action } from "api/action_types/fabricator";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/fabricator";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// URLS ///
import { url_prefix } from "settings";
const fabricator_miter_url: string = "fabricator_miter";

export const getFabricatorMiters = (fabricator_price_list: number | string) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.FABRICATOR_PRICE_LIST_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${fabricator_miter_url}/?fabricator_price_list=${fabricator_price_list}&`;

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_FABRICATOR_MITERS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const updateFabricatorMiter = (fabricator_miter: FabricatorMiter, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.FABRICATOR_PRICE_LIST_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${fabricator_miter_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, fabricator_miter, config);

            dispatch({
                type: ActionType.UPDATE_FABRICATOR_MITER_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
        }
    }
}