import { CutShape } from "api/types/sketch";
import { CutCorner } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { adjustVectorToScale } from "../scale";
import { getCornerPoints } from "../cut_corner";
import { getBumpPoints } from "../bumps";
import { getAngle } from "../../math";
import { SLAB_ZOOM } from "values/values";

export function getCutShapePoints(
  cut_shape: CutShape
): number[][] {
  const points: number[][] = [];
  let last_point: Vector2 = { X: 0, Y: 0 };

  for (let i = 0; i < cut_shape.cut_corners.length; i++) {
    const previous_corner: CutCorner =
      cut_shape.cut_corners[i - 1] ?
        cut_shape.cut_corners[i - 1] :
        cut_shape.cut_corners[cut_shape.cut_corners.length - 1];
    const corner: CutCorner = cut_shape.cut_corners[i];
    const next_corner: CutCorner =
      cut_shape.cut_corners[i + 1] ? cut_shape.cut_corners[i + 1] : cut_shape.cut_corners[0];
    const previous_location: Vector2 =
      adjustVectorToScale({ X: previous_corner.location.X, Y: previous_corner.location.Y }, SLAB_ZOOM);
    const location: Vector2 =
      adjustVectorToScale({ X: corner.location.X, Y: corner.location.Y }, SLAB_ZOOM);
    const next_location: Vector2 =
      adjustVectorToScale({ X: next_corner.location.X, Y: next_corner.location.Y }, SLAB_ZOOM);

    const first_point: Vector2 = getCornerPoints(
      points,
      corner.corner_type,
      previous_location,
      location,
      next_location,
      SLAB_ZOOM,
      corner.corner_radius,
      corner.corner_length,
      corner.corner_depth
    );

    if (i === 0) {
      last_point = first_point;
    }

    getBumpPoints(
      points,
      cut_shape.cut_corners[i].cut_bumps,
      location,
      next_location,
      getAngle(location, next_location),
      SLAB_ZOOM
    );
  }

  points.push([last_point.X, last_point.Y]);

  return points;
}
