import { Vector2 } from "api/types/sketch";
import { TextLocation } from "api/types/sketch";
import { getDistantPoint } from "functions/sketch/math";

export function createTextLocation(location: Vector2, angle: number, show_inside?: boolean): TextLocation {
  const vertical_offset_point: Vector2 = show_inside ? getDistantPoint(location, angle - 90, -10) : getDistantPoint(location, angle - 90, 10);
  return {
    text_location: vertical_offset_point,
    indicator_location: location
  }
}
