export enum ActionType {
  OPEN_MESSAGE = "OPEN_WO_MESSAGE"
}

interface IOpenMessage {
  type: ActionType.OPEN_MESSAGE,
  open: boolean
}

export type Action = IOpenMessage;
