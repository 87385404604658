import { FabricatorColor } from "api/types/fabricator";
import { FabricatorMaterial } from "api/types/fabricator";

export enum ActionType {
  GET_MARKET_MATERIALS = "GET_MARKET_MATERIALS",
  GET_MARKET_MATERIAL = "GET_MARKET_MATERIAL",
  GET_FABRICATOR_COLORS = "GET_FABRICATOR_COLOR",
  UPDATE_LOCAL_MARKET_MATERIAL = "UPDATE_LOCAL_MARKET_MATERIAL",
  UPDATE_LOCAL_MARKET_COLOR = "UPDATE_LOCAL_MARKET_COLOR",
  MARKET_MATERIAL_LOADING = "MARKET_MATERIAL_LOADING",
  MARKET_MATERIAL_ERROR = "MARKET_MATERIAL_ERROR",
  CLEAR_MARKET_MATERIALS = "CLEAR_MARKET_MATERIALS"
}

interface IUpdateLocalMarketMaterial {
  type: ActionType.UPDATE_LOCAL_MARKET_MATERIAL,
  material: FabricatorMaterial
}

interface IUpdateLocalMarketColor {
  type: ActionType.UPDATE_LOCAL_MARKET_COLOR,
  color: FabricatorColor
}

interface IClearMaterial {
  type: ActionType.CLEAR_MARKET_MATERIALS
}

interface IGetMaterialsSuccess {
  type: ActionType.GET_MARKET_MATERIALS,
  payload: FabricatorMaterial[]
}

interface IGetMaterialSuccess {
  type: ActionType.GET_MARKET_MATERIAL,
  payload: FabricatorMaterial
}

interface IGetFabricatorColor {
  type: ActionType.GET_FABRICATOR_COLORS,
  payload: FabricatorColor[]
}

interface IMaterialLoading {
  type: ActionType.MARKET_MATERIAL_LOADING
}

interface IMaterialError {
  type: ActionType.MARKET_MATERIAL_ERROR,
  payload: string
}

export type Action =
  IUpdateLocalMarketMaterial |
  IUpdateLocalMarketColor |
  IClearMaterial |
  IGetMaterialsSuccess |
  IGetMaterialSuccess |
  IMaterialLoading |
  IMaterialError |
  IGetFabricatorColor;
