/// HOOKS ///
import { useNavigate } from "react-router-dom";
/// TYPES ///
import { QuoteTotals } from "api/types/quote";
/// FM ///
import { motion } from "framer-motion";
/// TIME ///
import { DateTime } from "luxon";
/// MUI COMPONENTS ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// TYPES ///
import { Order } from "api/types/order";
/// URLS ///
import { store_url } from "components/navigation/endpoints";
import { order_url } from "components/navigation/endpoints";
/// STYLES ///
import { row_btn_style } from "styles/universal/row";
import { row_text_item_style } from "styles/universal/row";
/// MOTION ///
import { row_text_motion } from "styles/universal/row";
import useQuoteTotals from "hooks/quote/totals";

interface Props {
  item: Order
}

export default function StoreOrderItem({ item }: Props) {
  const navigate = useNavigate();
  const totals: QuoteTotals = useQuoteTotals(item);

  return (
    <Grid item xs={12}>
      <motion.button
        initial="rest"
        whileHover="hover"
        animate="rest"
        variants={row_text_motion}
        onClick={() => navigate(`/${store_url}/${order_url}/${item.id}`)}
        style={row_btn_style}>
        <Grid container>
          <Grid item xs={2.5}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {DateTime.fromISO(item.created).toLocal().toFormat("LLLL dd, yyyy h:mm a")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {item.name}
            </Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              {item?.customer_email ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
              ${(totals.subtotal + totals.tax1 + totals.tax2 - totals.discount).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </motion.button>
    </Grid>
  )
}
