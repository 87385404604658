import { CutShape } from "api/types/sketch";
import { CutCorner } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getDistance } from "../math";
import { getDistantPoint } from "../math";
import { getAngle } from "../math";
import { getCenterOfCutShape } from "../check";

export function rotateCutShape(cut_shape: CutShape, angle: number): CutShape {
    const axis: Vector2 = getCenterOfCutShape(cut_shape);
    const rotation: number = (cut_shape.rotation + angle) % 360;
    const cut_corners: CutCorner[] = [...cut_shape.cut_corners];
    for(let i = 0; i < cut_corners.length; i++){
        const cut_corner: CutCorner = {...cut_corners[i]};
        const corner_angle: number = getAngle(axis, cut_corner.location);
        const corner_distance: number = getDistance(axis, cut_corner.location);
        const new_angle: number = angle + corner_angle + 90;
        const new_location: Vector2 = getDistantPoint(axis, new_angle, corner_distance);
        cut_corner.location = {...cut_corner.location, X: new_location.X, Y: new_location.Y};
        cut_corners[i] = cut_corner;
    }

    return {...cut_shape, cut_corners: cut_corners, rotation: rotation};
}
