import { User } from "api/types/authentication";
import { ActionType } from "api/action_types/authentication";
import { socket_url } from "settings";

export const socketMiddleware = (socket) => (params) => (next) => (action) => {
    const { dispatch, getState } = params;
    const { type } = action;
    const { user } = getState().authentication;
  
    switch (type) {
        case "socket/connect":
            socket.connect(`${socket_url}ws/user/?access_token=${user.token}`);
    
            socket.on("open", () => {});
            socket.on("message", (data) => {
                const json_data: any = JSON.parse(data.data);
        
                const updated_user: User = JSON.parse(json_data.message.user);
                dispatch({
                    type: ActionType.UPDATE_LOCAL_USER,
                    payload: {...user, ...updated_user}
                });
            });
            socket.on("close", () => {});
            break;
  
        case "socket/disconnect":
            socket.disconnect();
            break;
        default:
            break;
    }
  
    return next(action);
  }