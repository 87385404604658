/// REACT ///
import { MouseEvent as ReactMouseEvent } from "react";
import { Fragment } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import useDrawSeams from "hooks/draw_seams";
/// TYPES ///
import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { Slab } from "api/types/quote";
import { Seam } from "api/types/sketch";
import { MouseData } from "types/mouse_position";
import { Vector2 } from "api/types/sketch";
/// ACTIONS ///
import { createBulkCutShapes } from "api/actions/sketch/cut_shape";
import { updateLocalCutShapes } from "api/actions/sketch/cut_shape";
import { updateLocalCounter } from "api/actions/sketch/counter";
import { updateCounter } from "api/actions/sketch/counter";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ShopSeamDrawer from "./drawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
/// FUNCTIONS ///
import { createCutShapes } from "functions/sketch";
import { getFirstSlabInAreas } from "functions/sketch/get/slab";
import { getCanvas } from "functions/sketch/context";
/// STYLES ///
import { canvasStyle } from "styles/sketch";
import { sketchAreaStyle } from "styles/sketch";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  mouse_data: MouseData,
  selected_counter: Counter | null,
  setSelectedCounter: (counter: Counter | null) => void
}

export default function StoreDefineSeams({
  open,
  setOpen,
  mouse_data,
  selected_counter,
  setSelectedCounter
}: Props) {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const { cut_shapes } = useAppSelector((state) => state.sketch);
  const { areas } = useAppSelector((state) => state.quote);
  const [cutting, setCutting] = useState<boolean>(false);
  const [focus_count, setFocusCount] = useState<number>(0);
  const [offset, setOffset] = useState<Vector2>({ X: 0, Y: 0 });

  const active_seam: Seam | null = useDrawSeams(
    mouse_data,
    selected_counter,
    open,
    focus_count,
    offset
  );

  useEffect(() => {
    if (cutting) {
      setSelectedCounter(null);
      setCutting(false);
      setOpen(false);
    }
  }, [cut_shapes, cutting, setOpen, setSelectedCounter]);

  const makeSeams = () => {
    setCutting(true);
    if (selected_counter) {
      const counter: Counter = { ...selected_counter };
      const slab: Slab | null = getFirstSlabInAreas(areas, counter.area_uuid);
      let slab_uuid: string = slab ? slab.uuid : areas[0].slabs[0].uuid;
      const cut_shapes: CutShape[] = createCutShapes(
        counter,
        counter.area_uuid,
        slab_uuid,
        counter.points,
        counter.corners,
        [] //offsets
      );
      dispatch(updateLocalCutShapes(cut_shapes, counter.uuid));
      dispatch(createBulkCutShapes(cut_shapes));
      dispatch(updateLocalCounter(counter));
      dispatch(updateCounter(counter));
    }
  }

  const handleFocus = () => {
    if (ref && ref.current) {
      const width = ref.current.scrollWidth;
      const height = ref.current.scrollHeight;
      setOffset({ X: ref.current.getBoundingClientRect().x, Y: ref.current.getBoundingClientRect().y });
      const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById("dialog_canvas"));

      if (canvas) {
        canvas.width = width;
        canvas.height = height;
      }
      setFocusCount(prev => prev + 1);
    }
  }

  const handleEvent = (event: ReactMouseEvent) => {
    if (event.type === "mousedown" && event.button === 0) {
      if (active_seam && selected_counter) {
        setSelectedCounter({
          ...selected_counter, points:
            [...selected_counter.points.map(point => point.uuid === active_seam.point_uuid ?
              {
                ...point, seams: [...point.seams, active_seam].sort(function (a, b) {
                  let x: number | undefined = 0;
                  let y: number | undefined = 0;
                  var i = 0;
                  while (i < point.seams.length) {
                    x = a.distance_from_point;
                    y = b.distance_from_point;
                    i++;
                  }
                  if (x && y) {
                    if (x < y) {
                      return -1;
                    } else if (x > y) {
                      return 1;
                    }
                  }
                  return 0;
                })
              } :
              point
            )]
        });
      }
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xl" onFocus={handleFocus}>
      <DialogTitle>Define Seams</DialogTitle>
      <DialogContent sx={{ marginTop: 2 }}>
        <Grid container>
          <Grid item xs={10.5}>
            <Box
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              sx={[sketchAreaStyle, { height: "75vh" }]}
              onMouseDown={(event: ReactMouseEvent) => handleEvent(event)}
              onMouseUp={(event: ReactMouseEvent) => handleEvent(event)}
              ref={ref}>
              <canvas
                id="dialog_canvas"
                className={JSON.stringify(canvasStyle)}
                style={{ backgroundColor: "#fafafa" }} />
            </Box>
          </Grid>
          <Grid item xs={1.5}>
            <ShopSeamDrawer
              selected_counter={selected_counter}
              setSelectedCounter={setSelectedCounter} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          cutting === false ?
            <Fragment>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button onClick={makeSeams}>Save</Button>
            </Fragment> :
            <CircularProgress size={22} sx={{ padding: 1, marginRight: 2 }} />
        }
      </DialogActions>
    </Dialog>
  )
}
