import { Dispatch } from "redux";
import { Action } from "api/action_types/sketch";
import { ActionType } from "api/action_types/sketch";

export const deleteLocalCounter = (uuid: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.DELETE_LOCAL_COUNTER,
            payload: uuid
        });
    }
}