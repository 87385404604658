/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCorner } from "api/types/shop_quote/corner";

export function updateShopCorner(counter: IShopCounter, corner: IShopCorner): IShopCounter {
  const new_corners: IShopCorner[] = [...counter.corners];
  for (let i = 0; i < new_corners.length; i++) {
    if (new_corners[i].uuid === corner.uuid) {
      new_corners[i] = corner;
      break;
    }
  }
  return { ...counter, corners: new_corners };
}
