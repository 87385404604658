import { OrderSlab } from "api/types/order";

export enum ActionType {
  GET_WO_SLABS_SUCCESS = "GET_WO_SLABS_SUCCESS"
}

interface IGetWOSlabsSuccess {
  type: ActionType.GET_WO_SLABS_SUCCESS,
  payload: OrderSlab[]
}

export type Action = IGetWOSlabsSuccess;
