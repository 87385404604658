/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// HANDLERS ///
import { handleUpdateShopCounter } from "handler/shop_counter/update";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopSlab } from "api/types/shop_quote/slab";
import { CornerError } from "api/types/quote/error";
/// ACTIONS ///
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { shopRestore } from "api/actions/shop_quote/history/local";
/// COMPONENTS ///
import UpdateEdge from "components/quote/drawer/update_edge";
/// FUNCTIONS ///
import { updateShopCorner } from "functions/sketch/update/shop_corner";
import { getFirstShopSlabInAreas } from "functions/sketch/get/shop_slab";
/// VALUES ///
import { EASED } from "values/values";
import { FINISHED } from "values/values";
import { MITER } from "values/values";
import { SPLASH } from "values/values";
import { WATERFALL } from "values/values";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  original_corner: IShopCorner | null,
  selected_counter: IShopCounter | null
}

export default function UpdateShopEdge({ open, setOpen, original_corner, selected_counter }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.shop_quote);
  const { cut_shape_history } = useAppSelector((state) => state.history);
  const { counter_history } = useAppSelector((state) => state.history);
  const { history_position } = useAppSelector((state) => state.history);
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { cut_shapes } = useAppSelector((state) => state.shop_quote);
  const [corner, setCorner] = useState<IShopCorner>({});
  const [error, setError] = useState<CornerError>({});

  useEffect(() => {
    setCorner(original_corner);
  }, [open, original_corner]);

  const cancel = () => {
    dispatch(shopRestore(counter_history[history_position], cut_shape_history[history_position]));
    setOpen(false);
  }

  const save = () => {
    if (corner?.edge_type === SPLASH || corner?.edge_type === MITER || corner?.edge_type === WATERFALL) {
      if (corner?.splash_height <= 0) {
        setError({ ...error, height_error: "Height must be greater than 0", height_has_error: true });
        return;
      }
    }

    if (selected_counter) {
      const counter: IShopCounter = updateShopCorner(selected_counter, corner);
      const slab: IShopSlab | null = getFirstShopSlabInAreas(areas, counter.area_uuid);
      handleUpdateShopCounter(
        dispatch,
        counter,
        counters,
        cut_shapes,
        slab.uuid,
        "Change Counter Edge"
      );
    }
    setOpen(false);
  }

  const update = (corner: IShopCorner) => {
    const counter: IShopCounter = updateShopCorner(selected_counter, corner);
    if (counter) {
      dispatch(updateLocalShopCounter(counter));
    }
    setCorner(corner);
  }

  return (
    <UpdateEdge
      open={open}
      edge_type={corner?.edge_type ?? FINISHED}
      setEdgeType={edge_type => update({ ...corner, edge_type })}
      finished_edge_type={corner?.finished_edge_type ?? EASED}
      setFinishedEdgeType={finished_edge_type => update({ ...corner, finished_edge_type })}
      splash_height={corner?.splash_height ?? 0}
      setSplashHeight={splash_height => update({ ...corner, splash_height })}
      height_error={error?.height_error}
      height_has_error={error?.height_has_error}
      save={save}
      cancel={cancel} />
  );
}
