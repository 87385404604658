/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { filter_url } from "components/navigation/endpoints";
import { wo_url } from "components/navigation/endpoints";

export const wo_list: IBreadcrumb = {
  id: 0,
  label: "Work Orders",
  url: `/${wo_url}`
}

export const wo_filter: IBreadcrumb = {
  id: 1,
  label: "Filter",
  url: `/${wo_url}/${filter_url}`
}

export const wo: IBreadcrumb = {
  id: 2,
  label: "Work Order",
  url: `/${wo_url}/${detail_url}/id`
}
