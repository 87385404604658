import { User } from "api/types/authentication";

export enum ActionType {
  LOGIN_PENDING = "LOGIN_PENDING",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAIL = "LOGIN_FAIL",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL",
  CHANGE_PASSWORD_PENDING = "CHANGE_PASSWORD_PENDING",
  CLEAR_ERRORS = "CLEAR_ERRORS",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  UPDATE_PROFILE_PENDING = "UPDATE_PROFLIE_PENDING",
  UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL",
  UPDATE_LOCAL_USER = "UPDATE_LOCAL_USER",
  AUTH_FAILED = "AUTH_FAILED",
  SET_PAYMENT_INITIALIZED = "SET_PAYMENT_INITIALIZED",
  SET_SHOP_VIEW = "SET_SHOP_VIEW"
}

interface loginPending {
  type: ActionType.LOGIN_PENDING
}

interface loginSuccess {
  type: ActionType.LOGIN_SUCCESS,
  payload: User
}

interface loginFail {
  type: ActionType.LOGIN_FAIL,
  payload: string
}

interface changePasswordSuccess {
  type: ActionType.CHANGE_PASSWORD
}

interface changePasswordFail {
  type: ActionType.CHANGE_PASSWORD_FAIL,
  payload: string
}

interface changePasswordPending {
  type: ActionType.CHANGE_PASSWORD_PENDING
}

interface clearErrors {
  type: ActionType.CLEAR_ERRORS
}

interface logoutSuccess {
  type: ActionType.LOGOUT_SUCCESS
}

interface updateProfilePending {
  type: ActionType.UPDATE_PROFILE_PENDING
}

interface updateProfileSuccess {
  type: ActionType.UPDATE_PROFILE_SUCCESS,
  payload: User
}

interface updateProfileFail {
  type: ActionType.UPDATE_PROFILE_FAIL,
  payload: string
}

interface updateLocalUser {
  type: ActionType.UPDATE_LOCAL_USER,
  payload: User
}

interface authFailed {
  type: ActionType.AUTH_FAILED
}

interface setPaymentInitialized {
  type: ActionType.SET_PAYMENT_INITIALIZED
}

interface ISetShopView {
  type: ActionType.SET_SHOP_VIEW,
  shop_view: boolean
}

export type Action =
  loginPending |
  loginSuccess |
  loginFail |
  changePasswordSuccess |
  changePasswordFail |
  changePasswordPending |
  clearErrors |
  logoutSuccess |
  updateProfileFail |
  updateProfilePending |
  updateProfileSuccess |
  updateLocalUser |
  authFailed |
  setPaymentInitialized |
  ISetShopView;
