/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useState } from "react";
/// TYPES ///
import { IShopLabel } from "api/types/shop_quote/label";
/// ACTIONS ///
import { createShopLabel } from "api/actions/shop_quote";
/// COMPONENTS ///
import LabelDialog from "components/quote/dialog/label";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  current_area: number
}

export default function CreateStoreLabel({ open, setOpen, current_area }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector(state => state.quote);
  const { quote } = useAppSelector(state => state.quote);
  const [label, setLabel] = useState<IShopLabel>({ size: 14 });
  const [error, setError] = useState<boolean>(false);

  const save = () => {
    let error: boolean = false;

    if (!(label?.text !== "")) {
      setError(true);
      error = true;
    }
    else {
      setError(false);
    }

    if (error) {
      return;
    }

    dispatch(createShopLabel({ ...label, location: { X: 500, Y: 500 }, quote: quote.id, area: areas[current_area]?.id ?? areas[0]?.id ?? 0 }));
    close();
  }

  const close = () => {
    setOpen(false);
    setLabel({ size: 14 });
    setError(false);
  }


  return (
    <LabelDialog
      save={save}
      title="Create Label"
      text={label?.text ?? ""}
      setText={text => setLabel({ ...label, text: text })}
      size={label?.size ?? 14}
      setSize={size => setLabel({ ...label, size: size })}
      error={error}
      close={close}
      open={open} />
  )
}
