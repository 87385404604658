/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
/// TYPES ///
import { ICustomEvent } from "api/types/event";
import { IOrderEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event";
/// COMPONENTS ///
import Box from "@mui/material/Box";
import List from "@mui/material/List";
/// CUSTOM COMPONENTS ///
import DayHeader from "./header";
import CustomDayItem from "./item/custom";
import OrderDayItem from "./item/order";
import SelfOrderDayItem from "./item/self_order";
/// LUXON ///
import { DateTime } from "luxon";
/// STYLES ///
import { dayBoxStyle } from "styles/calendar/day";
import { day_inner_style } from "styles/calendar/day";
import { scrollableBoxStyle } from "styles/calendar/day";

interface Props {
  day: number,
  index: number,
  nav_height: number,
  top?: boolean,
  end?: boolean,
  order_events: IOrderEvent[],
  self_order_events: ISelfOrderEvent[],
  custom_events: ICustomEvent[]
}

export default function CalendarDay({ day, index, nav_height, top, end, order_events, custom_events, self_order_events }: Props) {
  const { nav_date } = useAppSelector((state) => state.event);

  return (
    <List sx={dayBoxStyle(top, end, nav_height)}>
      <div style={day_inner_style(DateTime.fromISO(nav_date).toLocal().day === day)}>
        <DayHeader day={day} index={index} nav_date={nav_date} />
        <Box sx={scrollableBoxStyle}>
          {
            order_events?.map((event, i) => <OrderDayItem key={i} order_event={event} />)
          }
          {
            self_order_events?.map((event, i) => <SelfOrderDayItem key={i} self_order_event={event} />)
          }
          {
            custom_events?.map((event, i) => <CustomDayItem key={i} custom_event={event} />)
          }
        </Box>
      </div>
    </List>
  )
}
