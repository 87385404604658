/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/event";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/event";

export const setNavDate = (date: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_NAV_DATE,
            date
        })
    }
}