/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
import { row_text_motion } from "styles/universal/row";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { row_btn_style } from "styles/universal/row";
import { row_header_style } from "styles/universal/row";
import { row_text_item_style } from "styles/universal/row";
/// URLS ///
import { create_url } from "components/navigation/endpoints";
import { detail_url } from "components/navigation/endpoints";
import { stores_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { stores_list } from "values/breadcrumbs/store";

export default function Stores() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(setBreadcrumbs([stores_list]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start" sx={list_container_style}>
        <Grid container item xs={12} lg={6}>
          <PageTitle title="Stores" endProps={
            <Box>
              <BaseButton text="Add" icon={faPlus} clickEvent={() => navigate(`/${stores_url}/${create_url}`)} />
            </Box>
          } />
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6} sx={row_header_style}>
            <Typography variant="body2" sx={row_text_item_style}>
              <b>Region</b>
            </Typography>
          </Grid>
          <Grid item xs={6} sx={row_header_style}>
            <Typography variant="body2" sx={row_text_item_style}>
              <b>Location</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <motion.button
              initial="rest"
              whileHover="hover"
              animate="rest"
              variants={row_text_motion}
              onClick={() => navigate(`/${stores_url}/${detail_url}/${user.id}`)}
              style={row_btn_style}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
                    {user.region_data?.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" align="left" noWrap sx={row_text_item_style}>
                    {user.geo_address}
                  </Typography>
                </Grid>
              </Grid>
            </motion.button>
          </Grid>

        </Grid>
      </Grid>
    </motion.div>
  )
}


