import { Label } from "api/types/sketch";

export enum ActionType {
    CREATE_LABEL="CREATE_LABEL",
    DELETE_LABEL="DELETE_LABEL",
    GET_LABELS="GET_LABELS",
    UPDATE_LABEL="UPDATE_LABEL",
    UPDATE_LOCAL_LABEL="UPDATE_LOCAL_LABEL"
}

interface ICreateLabel {
    type: ActionType.CREATE_LABEL,
    label: Label
}

interface IDeleteLabel {
    type: ActionType.DELETE_LABEL,
    id: number
}

interface IGetLabels {
    type: ActionType.GET_LABELS,
    labels: Label[]
}

interface IUpdateLabel {
    type: ActionType.UPDATE_LABEL
}

interface IUpdateLocalLabel {
    type: ActionType.UPDATE_LOCAL_LABEL,
    label: Label
}

export type Action =
    ICreateLabel |
    IDeleteLabel |
    IGetLabels |
    IUpdateLabel |
    IUpdateLocalLabel;