/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
/// ACTIONS ///
import { getEndCustomers } from "api/actions/end_customer";
/// TYPES ///
import { ICustomer } from "api/types/customer";
/// REACT COMPONENT ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import { TextField } from "@mui/material";
/// GEO COMPONENTS ///
import AutocompleteResults from "./results";
/// LODASH ///
import { debounce } from "lodash";

interface Props {
  chooseCustomer: (customer: ICustomer) => void,
  width?: number,
  height?: number | string,
  input_label?: string,
  fullWidth?: boolean,
  has_error?: boolean,
  error?: string
}

export default function CustomerAutocomplete({
  chooseCustomer, width = 540,
  input_label = "Search Customer",
  fullWidth = false,
  has_error = false,
  error = "Must search and select a customer"
}: Props) {
  const dispatch = useAppDispatch();
  const [res_open, setResOpen] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const ref = useRef();

  useEffect(() => {
    if (ref) {
      setAnchor(ref.current);
    }
  }, [ref]);

  const updateCustomer = (customer: ICustomer) => {
    setInput(`${customer.last_name} - ${customer.email}`);
    chooseCustomer(customer);
  }

  function query(input: string) {
    dispatch(getEndCustomers({ last_name: input, email: input, address: input, any: true, limit: "5" }));
  }

  const debounced = useCallback(
    debounce(query, 500),
    []
  );

  useEffect(() => {
    debounced(input);
  }, [input, dispatch, debounced]);

  const updateQuery = (input: string) => {
    setInput(input);
    setResOpen(true);
  }

  const keyDown = (e: any) => {
    if (e.key === "Escape") {
      e.preventDefault();
      setResOpen(false);
    }
  }

  return (
    <Fragment>
      <TextField
        sx={{ width: fullWidth ? "100%" : `${width}px`, padding: 0 }}
        onKeyDown={keyDown}
        ref={ref}
        value={input}
        size="small"
        error={has_error}
        helperText={has_error ? error : ""}
        placeholder="Search Existing Customer's Last Name, Email or Address"
        label={input_label}
        onChange={e => updateQuery(e.target.value)} />
      <AutocompleteResults
        anchorEl={anchor}
        open={res_open}
        setOpen={setResOpen}
        chooseCustomer={updateCustomer} />
    </Fragment>
  )
}
