import { CornerData } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { drawFirstHalfPrintCutRadiusCorner } from "./radius_corner";
import { drawFirstHalfPrintCutInsideRadiusCorner } from "./inside_radius_corner";
import { drawFirstHalfPrintCutNotchedCorner } from "./notched_corner";
import { drawFirstHalfPrintCutClippedCorner } from "./clipped_corner";
import { drawFirstHalfPrintCutDiagonalCorner } from "./diagonal_corner";
import { drawFirstHalfPrintCutRecessedDiagonalCorner } from "./recessed_diagonal_corner";
import { drawFirstHalfPrintCutBumpoutCorner } from "./bumpout_corner";
import { drawPrintCutStandardCorner } from "../print";
import { RADIUS } from "values/quote";
import { INNER_RADIUS } from "values/quote";
import { NOTCH } from "values/quote";
import { BUMPOUT } from "values/quote";
import { CLIP } from "values/quote";
import { RECESSED_DIAGONAL } from "values/quote";
import { DIAGONAL } from "values/quote";

export function drawFirstHalfPrintCutCorner(
    i: number,
    painter: any,
    corner_type: string,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    zoom: number,
    radius: number,
    length: number,
    depth: number
): CornerData | null {
    let corner_data: CornerData | null = null;
    if(corner_type === RADIUS){
        corner_data = drawFirstHalfPrintCutRadiusCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            radius,
            zoom
        );
    }
    else if(corner_type === INNER_RADIUS){
        corner_data = drawFirstHalfPrintCutInsideRadiusCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            radius,
            zoom
        );
    }
    else if(corner_type === NOTCH){
        corner_data = drawFirstHalfPrintCutNotchedCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else if(corner_type === CLIP){
        corner_data = drawFirstHalfPrintCutClippedCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            zoom
        );
    }
    else if(corner_type === DIAGONAL){
        corner_data = drawFirstHalfPrintCutDiagonalCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            zoom
        );
    }
    else if(corner_type === BUMPOUT){
        corner_data = drawFirstHalfPrintCutBumpoutCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else if(corner_type === RECESSED_DIAGONAL){
        corner_data = drawFirstHalfPrintCutRecessedDiagonalCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else{
        corner_data = drawPrintCutStandardCorner(
            painter,
            i,
            current_location
        )
    }
    return corner_data;
}


