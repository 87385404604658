import { IBreadcrumb } from "api/types/ui";

export enum ActionType {
  SET_CRUMBS = "SET_CRUMBS",
}

interface ISetCrumbs {
  type: ActionType.SET_CRUMBS,
  crumbs: IBreadcrumb[]
}

export type Action = ISetCrumbs;
