/// TYPES ///
import { Area } from "api/types/quote";
import { ColorOption } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";
import { IShopColorOption } from "api/types/shop_quote/color_option";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";


interface Props {
  area: IShopArea | Area,
  option: IShopColorOption | ColorOption
}

export default function ColorOptsRow({ area, option }: Props) {
  return (
    <Fragment>
      <Grid item xs={3}>
        <Typography variant="body2" sx={item_style()}>
          {area?.name ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          {option?.material_name ?? ""} {option?.color_name ?? ""} {option?.material_thickness ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${option?.subtotal?.toFixed(2) ?? "0.00"}
        </Typography>
      </Grid>
    </Fragment>
  )
}
