/// HOOKS  ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useState } from "react";
/// UUID ///
import { v4 } from "uuid";
/// ACTIONS ///
import { createArea } from "api/actions/quotes/area";
/// TYPES ///
import { Slab } from "api/types/quote";
/// COMPONENTS ///
import CreateArea from "components/quote/dialog/area/create";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void
}

export default function CreateStoreArea({ open, setOpen }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.shop_quote);
  const { quote } = useAppSelector((state) => state.shop_quote);
  const [error, setError] = useState<string>("");

  const save = (name: string) => {
    if (name === "") {
      setError("Name must not be blank.");
      return;
    }

    const area_uuid: string = v4();
    const slab: Slab = {
      area_uuid: area_uuid,
      number: 1,
      uuid: v4(),
      material_color: areas[0]?.material_name ?? ""
    };

    dispatch(createArea({
      subtotal: 0,
      number: areas.length + 1,
      name: name,
      quote: quote.id,
      uuid: area_uuid,
      material: areas[0].material,
      color: areas[0].color,
      material_name: areas[0]?.material_name ?? "",
      material_type: areas[0]?.material_type ?? "",
      material_width: areas[0]?.material_width ?? 0,
      material_length: areas[0]?.material_length ?? 0,
      drawing_color: 0,
      slabs: [slab],
      cut_shapes: [],
      color_options: [],
      line_items: []
    }));
    close();
  }

  const close = () => {
    setError("");
    setOpen(false);
  }

  return (
    <CreateArea
      open={open}
      error={error}
      setOpen={setOpen}
      save={save}
      close={close} />
  )
}
