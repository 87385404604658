import { Vector2 } from "api/types/sketch";
import { getDistance } from "../math";
import { getDistantPoint } from "../math";
import { getAngle } from "../math";

export function moveVector(lift_point: Vector2, mouse: Vector2): Vector2 {
    const distance: number = getDistance(lift_point, mouse);
    const angle: number = getAngle(lift_point, mouse) + 90;

    return getDistantPoint(lift_point, angle, distance);
}