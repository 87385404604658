/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
/// ACTIONS ///
import { clearFabricatorPriceLists } from "api/actions/fabricator/price_list";
import { getFabricatorPriceLists } from "api/actions/fabricator";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import List from "components/universal/list";
import MarketPlHeader from "./header";
import MarketPlItem from "./row";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { row_header_style } from "styles/universal/row";
import { title_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { list } from "values/breadcrumbs/market/pl";


export default function MarketPls() {
  const dispatch = useAppDispatch();
  const { fabricator_price_lists } = useAppSelector((state) => state.fabricator);

  useEffect(() => {
    dispatch(getFabricatorPriceLists());
    dispatch(setBreadcrumbs([list]));
    return () => {
      dispatch(clearFabricatorPriceLists());
    }
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title="Price Lists" endProps={<div></div>} />
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={12} sx={row_header_style}>
            <Typography variant="h6" sx={{ ...title_style, paddingTop: "1px" }}>
              {fabricator_price_lists.length} results
            </Typography>
          </Grid>
          <MarketPlHeader />
          <List
            Item={MarketPlItem}
            items={fabricator_price_lists} />
        </Grid>
      </Grid>
    </motion.div>
  )
}

