import { Counter } from "api/types/sketch";

export enum ActionType {
  CREATE_LOCAL_COUNTER = "CREATE_LOCAL_COUNTER",
  DELETE_LOCAL_COUNTER = "DELETE_LOCAL_COUNTER",
  GET_COUNTERS_SUCCESS = "GET_COUNTERS_SUCCESS",
  SET_COUNTERS = "SET_COUNTERS",
  UPDATE_LOCAL_COUNTER = "UPDATE_LOCAL_COUNTER",
}

interface ICreateLocalCounter {
  type: ActionType.CREATE_LOCAL_COUNTER,
  payload: Counter
}

interface IDeleteLocalCounter {
  type: ActionType.DELETE_LOCAL_COUNTER,
  payload: string
}

interface IGetCountersSuccess {
  type: ActionType.GET_COUNTERS_SUCCESS,
  payload: Counter[]
}

interface ISetCounters {
  type: ActionType.SET_COUNTERS,
  payload: Counter[]
}

interface IUpdateLocalCounter {
  type: ActionType.UPDATE_LOCAL_COUNTER,
  payload: Counter
}

export type Action =
  ICreateLocalCounter |
  IDeleteLocalCounter |
  IGetCountersSuccess |
  ISetCounters |
  IUpdateLocalCounter;
