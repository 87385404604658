/// COMPONENTS ///
import { Image } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";

interface Props {
  signature: string | null,
  name: string | null,
  date: string | null
}

export default function PrintSignature({ signature, name, date }: Props) {
  const styles = StyleSheet.create({
    body2: {
      fontFamily: "Montserrat",
      fontSize: 10
    }
  });

  return (
    <View style={{ width: "100%", paddingLeft: "10px", paddingRight: "10px" }} wrap={false}>
      <View style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: "50px" }}>
        <View style={{ width: "160px" }}>
          <Text style={styles.body2}>
            Accepted By:
          </Text>
        </View>
        <View style={{ width: "160px" }}>
          <Text style={styles.body2}>
            Signature:
          </Text>
        </View>
        <View style={{ width: "160px" }}>
          <Text style={styles.body2}>
            Date:
          </Text>
        </View>
      </View>
      <View style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: signature ? "5px" : "75px" }}>
        <View style={{ width: "140px", borderBottom: '1px solid black' }}>
          {
            signature ?
              <Text style={{ fontSize: 14 }}>{name}</Text> :
              null
          }
        </View>
        <View style={{ width: "140px", borderBottom: '1px solid black', marginLeft: "20px" }}>
          {
            signature ?
              <Image src={signature} style={{ width: "140px", height: "auto" }} /> :
              null
          }
        </View>
        <View style={{ width: "140px", borderBottom: '1px solid black', marginLeft: "20px" }}>
          {
            signature ?
              <Text style={{ fontSize: 14 }}>{date}</Text> :
              null
          }
        </View>
      </View>
    </View>
  );
}
