/// REACT ///
import { ReactNode } from "react";
/// EVENT ///
import { KeyboardEvent } from "react";
import { MouseEvent } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  cancel: () => void,
  save: () => void,
  add: () => void,
  children?: ReactNode
}

export default function UpdateBump({
  open,
  setOpen,
  cancel,
  save,
  add,
  children
}: Props) {
  const toggleDrawer = () =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpen(!open);
    };

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      save();
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      cancel();
    }
  }

  return (
    <SwipeableDrawer
      BackdropProps={{ invisible: true }}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      onKeyDown={keyDown}
      anchor="right"
      open={open}>
      <Box display="flex" flexDirection="column" justifyContent="center" sx={{ paddingTop: "25px", paddingLeft: "50px", paddingRight: "50px", width: "300px" }}>
        <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
          <IconButton onClick={add}>
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
          <IconButton onClick={cancel}>
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Box>
        <Typography variant="h4" align="center" sx={{ padding: "25px" }}>
          Bump & Arc
        </Typography>
        {children}
        <Button
          onClick={save}>
          Save
        </Button>
      </Box>
    </SwipeableDrawer>
  );
}

