/// HOOKS ///
import useQuoteTotals from "hooks/quote/totals";
/// TYPES ///
import { Quote } from "api/types/quote";
import { IShopQuote } from "api/types/shop_quote/quote";
import { QuoteTotals } from "api/types/quote";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";

interface Props {
  quote: Quote | IShopQuote
}

export default function SummaryTotal({ quote }: Props) {
  const totals: QuoteTotals = useQuoteTotals(quote);

  return (
    <Fragment>
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>Subtotal</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${(totals.subtotal).toFixed(2)}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>{quote?.tax_name ?? "Tax"}</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${(totals.tax1).toFixed(2)}
        </Typography>
      </Grid>
      {
        quote?.second_tax ?
          <Fragment>
            <Grid item xs={8}>
              <Typography variant="body2" align="right" sx={item_style()}>
                <b>{quote?.second_tax_name ?? "Tax2"}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" align="right" sx={item_style()}>
                ${(totals.tax2).toFixed(2)}
              </Typography>
            </Grid>
          </Fragment> :
          null
      }
      {
        quote?.discount ?
          <Fragment>
            <Grid item xs={8}>
              <Typography variant="body2" align="right" sx={item_style()}>
                <b>Discount</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {quote.discount}%
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" align="right" sx={item_style()}>
                <b>Discount Amount</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" align="right" sx={item_style()}>
                ${(totals.discount).toFixed(2)}
              </Typography>
            </Grid>
          </Fragment> :
          null
      }
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>Total</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${(totals.subtotal - totals.discount + totals.tax1 + totals.tax2).toFixed(2)}
        </Typography>
      </Grid>
    </Fragment>
  )
}
