import { IShopCounter } from "api/types/shop_quote/counter";

export enum ActionType {
  CREATE_LOCAL_SHOP_COUNTER = "CREATE_LOCAL_SHOP_COUNTER",
  DELETE_LOCAL_SHOP_COUNTER = "DELETE_LOCAL_SHOP_COUNTER",
  GET_SHOP_COUNTERS = "GET_SHOP_COUNTERS",
  SET_SHOP_COUNTERS = "SET_SHOP_COUNTERS",
  UPDATE_LOCAL_SHOP_COUNTER = "UPDATE_LOCAL_SHOP_COUNTER",
}

interface ICreateLocalShopCounter {
  type: ActionType.CREATE_LOCAL_SHOP_COUNTER,
  payload: IShopCounter
}

interface IDeleteLocalShopCounter {
  type: ActionType.DELETE_LOCAL_SHOP_COUNTER,
  payload: string
}

interface IGetShopCountersSuccess {
  type: ActionType.GET_SHOP_COUNTERS,
  payload: IShopCounter[]
}

interface ISetShopCounters {
  type: ActionType.SET_SHOP_COUNTERS,
  payload: IShopCounter[]
}

interface IUpdateLocalShopCounter {
  type: ActionType.UPDATE_LOCAL_SHOP_COUNTER,
  payload: IShopCounter
}

export type Action =
  ICreateLocalShopCounter |
  IDeleteLocalShopCounter |
  IGetShopCountersSuccess |
  ISetShopCounters |
  IUpdateLocalShopCounter;
