/// TYPES ///
import { CSSProperties } from "react";
import { TargetAndTransition } from "framer-motion";
import { primary_color } from "styles/style";
import { miniDrawerWidth } from "styles/drawer";
import { area_drawer_padding } from "values/sketch";
import { area_drawer_width } from "values/sketch";

export const sketch_color_amount: number = 17;
export const sketch_color_1: string = primary_color;
export const sketch_color_2: string = "#42A13E";
export const sketch_color_3: string = "#9D3385";
export const sketch_color_4: string = "#e9967a";
export const sketch_color_5: string = "#ffa500";
export const sketch_color_6: string = "#C1003F";
export const sketch_color_7: string = "#562736";
export const sketch_color_8: string = "#30DEFF";
export const sketch_color_9: string = "#2f4f4f";
export const sketch_color_10: string = "#00fa9a";
export const sketch_color_11: string = "#ff00ff";
export const sketch_color_12: string = "#f0e68c";
export const sketch_color_13: string = "#ee82ee";
export const sketch_color_14: string = "#808080";
export const sketch_color_15: string = "#800000";
export const sketch_color_16: string = "#00bfff";

export const sketchAreaStyle = {
  width: `calc(100% - ${miniDrawerWidth}px - ${area_drawer_padding}px - ${area_drawer_padding}px - ${area_drawer_width}px)`,
  backgroundColor: "#fafafa",
  minHeight: "calc(100vh - 100px)",
}

export const sketch_style = (cursor_mode: string = "normal") => ({
  backgroundColor: "#fafafa",
  position: "absolute",
  top: 35,
  left: 0,
  cursor: cursor_mode,
  right: area_drawer_padding + area_drawer_padding + area_drawer_width,
  bottom: 0
});

export const canvasStyle = {
  width: "100%",
  height: "100%",
  touchEvent: "none"
}

export const actionBarStyle = {
  gap: "10px",
  display: "flex",
  justifyContent: "center",
  left: 0,
  right: 200,
  position: "absolute",
  bottom: 0,
  paddingLeft: 0,
  height: miniDrawerWidth
}

export const sketch_tab_btn: CSSProperties = {
  border: "none",
  textTransform: "uppercase",
  fontFamily: "Roboto",
  overflow: "hidden",
  height: "100%",
  fontSize: "0.9rem",
  fontWeight: 500,
  backgroundColor: "var(--mui-palette-primary-main)",
  color: "var(--mui-palette-background-paper)",
  paddingTop: "9px",
  paddingBottom: 0,
  flexGrow: 1,
  width: "auto"
}

export const sketch_btn_anim: TargetAndTransition = {
  backgroundColor: "var(--mui-palette-primary-dark)",
  transition: {
    duration: 0.4,
    color: "1.5 ease",
    backgroundColor: "1.5s ease"
  }
}

export const tab_underline_style: CSSProperties = {
  backgroundColor: "var(--mui-palette-background-paper)",
  width: "70%",
  height: "5px",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "3px"
}

export const col_opt_icn_style: CSSProperties = {
  color: "red",
  height: "14px",
  width: "14px"
}

export const col_opt_icn_btn_style: CSSProperties = {
  height: "14px",
  width: "14px"
}

export const edit_icn_btn_style: CSSProperties = {
  marginLeft: "25px",
  height: "14px",
  width: "14px",
  color: "var(--mui-palette-primary-main)"
}

export const edit_icn_style: CSSProperties = {
  height: "14px",
  width: "14px"
}

export const del_icn_btn_style: CSSProperties = {
  marginLeft: "10px",
  height: "14px",
  width: "14px",
  color: "var(--mui-palette-primary-main)"
}

export const del_icn_style: CSSProperties = {
  height: "18px",
  width: "18px"
}

export const area_bar_style: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  position: "fixed",
  right: 0,
  top: 85,
  bottom: 0,
  height: "calc(100% - 120px)",
  width: `${area_drawer_width}px`,
  gap: "15px",
  paddingTop: `${area_drawer_padding}px`,
  paddingRight: `${area_drawer_padding}px`,
  paddingLeft: `${area_drawer_padding}px`,
  backgroundColor: "#fff",
}

export const btn_area_style: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  gap: "15px"
}

export const area_btn_style = (color: string, selected: boolean) => ({
  width: "100%",
  fontWeight: 500,
  borderRadius: 0,
  backgroundColor: selected ? color : "#fff",
  color: selected ? "#fff" : color,
  border: selected ? "1px solid #fff" : `1px solid ${color}`,
  "&:hover": {
    backgroundColor: selected ? `${color}F2` : `${color}21`,
  }
});

export const area_add_style: CSSProperties = {
  fontWeight: 500,
  borderRadius: 0,
  width: "100%"
}
