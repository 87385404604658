import { Slab } from "api/types/quote";
import { CutShape } from "api/types/sketch";
import { CutCorner } from "api/types/sketch";
import { OrderCutShape } from "api/types/order/order";
import { OrderCutCorner } from "api/types/order/order";
import { OrderCutBump } from "api/types/order/order";
import { SelfOrderCutCorner } from "api/types/order/self_order";
import { SelfOrderCutShape } from "api/types/order/self_order";
import { Vector2 } from "api/types/sketch";
import { CornerData } from "api/types/sketch";
import { adjustVectorToScale } from "../scale";
import { drawPrintCutCorner } from "../cut_corner";
import { drawFirstHalfPrintCutCorner } from "../cut_corner/print_first_half_cut_corner";
import { drawSecondHalfPrintCutCorner } from "../cut_corner/print_second_half_cut_corner";
import { drawPrintCutBumps } from "../bumps/bumps/print_cut_bumps";
import { getAngle } from "../../math";

export function drawPrintPreviewCutShape(
  painter: any,
  cut_shape: CutShape | OrderCutShape | SelfOrderCutShape,
  zoom: number,
  base_offset: Vector2,
  offset: Vector2
) {
  let first_location: Vector2 = { X: 0, Y: 0 };
  const circles: string[] = [];

  for (let i = 0; i < cut_shape.cut_corners.length; i++) {
    const previous_corner: CutCorner | OrderCutCorner | SelfOrderCutCorner =
      cut_shape.cut_corners[i - 1] ?
        cut_shape.cut_corners[i - 1] :
        cut_shape.cut_corners[cut_shape.cut_corners.length - 1];
    const corner: CutCorner | OrderCutCorner | SelfOrderCutCorner = cut_shape.cut_corners[i];
    const next_corner: CutCorner | OrderCutCorner | SelfOrderCutCorner =
      cut_shape.cut_corners[i + 1] ? cut_shape.cut_corners[i + 1] : cut_shape.cut_corners[0];

    const zoom_adjusted_previous_location: Vector2 =
      adjustVectorToScale(
        {
          X: previous_corner.location.X - offset.X,
          Y: previous_corner.location.Y - offset.Y
        },
        zoom
      );
    const zoom_adjusted_location: Vector2 =
      adjustVectorToScale(
        {
          X: corner.location.X - offset.X,
          Y: corner.location.Y - offset.Y
        },
        zoom
      );
    const zoom_adjusted_next_location: Vector2 =
      adjustVectorToScale(
        {
          X: next_corner.location.X - offset.X,
          Y: next_corner.location.Y - offset.Y
        },
        zoom
      );
    const previous_location: Vector2 = {
      X: zoom_adjusted_previous_location.X + base_offset.X,
      Y: zoom_adjusted_previous_location.Y + base_offset.Y
    };
    const location: Vector2 = {
      X: zoom_adjusted_location.X + base_offset.X,
      Y: zoom_adjusted_location.Y + base_offset.Y
    };
    const next_location: Vector2 = {
      X: zoom_adjusted_next_location.X + base_offset.X,
      Y: zoom_adjusted_next_location.Y + base_offset.Y
    };

    let corner_data: CornerData | null = null;

    if (!corner.is_corner_seam) {
      corner_data = drawPrintCutCorner(
        i,
        painter,
        corner.corner_type,
        previous_location,
        location,
        next_location,
        zoom,
        corner.corner_radius,
        corner.corner_length,
        corner.corner_depth
      );

      if (corner_data.svg_path) {
        circles.push(corner_data.svg_path);
      }
    }
    else {
      if (corner.corner_before_seam) {
        corner_data = drawFirstHalfPrintCutCorner(
          i,
          painter,
          corner.corner_type,
          previous_location,
          location,
          next_location,
          1,
          corner.corner_radius,
          corner.corner_length,
          corner.corner_depth
        );
      }
      else {
        corner_data = drawSecondHalfPrintCutCorner(
          i,
          painter,
          corner.corner_type,
          previous_location,
          location,
          next_location,
          1,
          corner.corner_radius,
          corner.corner_length,
          corner.corner_depth
        )
      }
    }

    if (i === 0) {
      if (corner_data && corner_data.first_point) {
        first_location = corner_data.first_point;
      }
      else {
        first_location = location;
      }
    }

    drawPrintCutBumps(
      painter,
      cut_shape.cut_corners[i].cut_bumps as OrderCutBump[],
      location,
      next_location,
      getAngle(location, next_location),
      zoom
    );
  }
  painter.lineTo(first_location.X, first_location.Y);
  for (let i = 0; i < circles.length; i++) {
    painter.path(circles[i]);
  }
}


export function drawPrintPreviewCutShapes(
  painter: any,
  cut_shapes: CutShape[],
  slab: Slab,
  zoom: number,
  base_offset: Vector2,
  offset: Vector2
) {
  for (let i = 0; i < cut_shapes.length; i++) {
    if (cut_shapes[i].slab_uuid !== slab.uuid) {
      continue;
    }
    drawPrintPreviewCutShape(painter, cut_shapes[i], zoom, base_offset, offset);
  }
}
