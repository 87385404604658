export const ALBERTA: string = "AB";
export const ONTARIO: string = "ON";
export const QUEBEC: string = "QC";
export const NOVA_SCOTIA: string = "NS";
export const NEW_BRUNSWICK: string = "NB";
export const MANITOBA: string = "MB";
export const BRITISH_COLUMBIA: string = "BC";
export const PRINCE_EDWARD_ISLAND: string = "PE";
export const SASKATCHEWAN: string = "SK";
export const NEWFOUNDLAND: string = "NL";
export const NORTHWEST_TERRITORIES: string = "NT";
export const YUKON: string = "YT";
export const NUNAVUT: string = "NU";

export const PROVINCES: string[] = [
    ALBERTA,
    ONTARIO,
    QUEBEC,
    NOVA_SCOTIA,
    NEW_BRUNSWICK,
    MANITOBA,
    BRITISH_COLUMBIA,
    PRINCE_EDWARD_ISLAND,
    SASKATCHEWAN,
    NEWFOUNDLAND,
    NORTHWEST_TERRITORIES,
    YUKON,
    NUNAVUT
]