/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopSlab } from "api/types/shop_quote/slab";
import { Area } from "api/types/quote";
import { CutShape } from "api/types/sketch";
import { Slab } from "api/types/quote";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { getSlabStartPoint } from "../get";
import { getCutShapePoints } from "../draw";
/// TURF DRAWING ///
import { intersect } from '@turf/turf';
import { polygon } from "@turf/turf";
/// VALUES ///
import { SLAB_ZOOM } from "values/values";

export interface SlabData {
  id: number,
  start_point: Vector2
}

export function checkQuoteFitted(cut_shapes: CutShape[]): boolean {
  for (let i = 0; i < cut_shapes.length; i++) {
    if (!cut_shapes[i].fitted) {
      return false;
    }
  }
  return true;
}

export function confirmCutShapesFitted(cut_shapes: CutShape[] | IShopCutShape[], areas: Area[] | IShopArea[]): boolean {
  for (let i = 0; i < areas.length; i++) {
    const fitted: boolean = confirmAreaFitted(
      cut_shapes.filter(shape => shape.area_uuid === areas[i].uuid),
      areas[i]?.slabs,
      areas[i]?.material_width,
      areas[i]?.material_length
    );
    if (!fitted) {
      return false;
    }
  }
  return true;
}

export function confirmAreaFitted(cut_shapes: CutShape[] | IShopCutShape[], slabs: Slab[] | IShopSlab[], width: number, length: number): boolean {
  for (let i = 0; i < cut_shapes.length; i++) {
    const points = getCutShapePoints(cut_shapes[i]);
    const slab: string | null = checkCutShapeInSlabs(points, slabs, width, length);

    if (!slab) {
      return false;
    }
    else {
      for (let j = 0; j < cut_shapes.length; j++) {
        if (cut_shapes[i].uuid === cut_shapes[j].uuid) {
          continue;
        }

        const compare_points = getCutShapePoints(cut_shapes[j]);
        const intersection = intersect(polygon([points]), polygon([compare_points]));

        if (intersection) {
          return false;
        }
      }
    }
  }
  return true;
}

export function checkCutShapesFitted(
  cut_shapes: CutShape[] | IShopCutShape[],
  slabs: Slab[] | IShopSlab[],
  width: number,
  height: number
): CutShape[] {
  const update_shapes: CutShape[] = [];

  for (let i = 0; i < cut_shapes.length; i++) {
    const current_cut_shape = { ...cut_shapes[i] };
    const current_clip_data: number[][] = getCutShapePoints(current_cut_shape);
    const slab_id: string | null = checkCutShapeInSlabs(current_clip_data, slabs, width, height);
    if (slab_id) {
      current_cut_shape.fitted = true;
      current_cut_shape.slab_uuid = slab_id;

      for (let j = 0; j < cut_shapes.length; j++) {
        if (current_cut_shape.uuid === cut_shapes[j].uuid) {
          continue;
        }
        const compare_points = getCutShapePoints(cut_shapes[j]);

        const intersection = intersect(polygon([current_clip_data]), polygon([compare_points]));

        if (intersection) {
          if (cut_shapes[i].fitted) {
            current_cut_shape.changed = true;
          }
          current_cut_shape.fitted = false;
          break;
        }
      }
      if (cut_shapes[i].fitted !== current_cut_shape.fitted) {
        current_cut_shape.changed = true;
      }
      update_shapes.push(current_cut_shape);
    }
    else {
      if (cut_shapes[i].fitted) {
        current_cut_shape.changed = true;
      }
      current_cut_shape.fitted = false;
      update_shapes.push(current_cut_shape);
    }
  }
  return update_shapes;
}

export function checkCutShapeInSlabs(clip_data: number[][], slabs: Slab[] | IShopSlab[], width: number, height: number): string | null {
  for (let j = 0; j < slabs.length; j++) {
    const slab_start: Vector2 = getSlabStartPoint(height, j);
    for (let i = 0; i < clip_data.length; i++) {
      const inside: boolean = pointInSlab(clip_data[i], width, height, slab_start);
      if (!inside) {
        break;
      }

      if (i === clip_data.length - 1) {
        return slabs[j].uuid;
      }
    }
  }
  return null;
}

export function pointInSlab(corner_point: number[], width: number, height: number, slab_start_point: Vector2): boolean {
  if (corner_point[0] < slab_start_point.X) {
    return false;
  }
  else if (corner_point[1] < slab_start_point.Y) {
    return false;
  }
  else if (corner_point[0] > slab_start_point.X + width * 4 * SLAB_ZOOM) {
    return false;
  }
  else if (corner_point[1] > slab_start_point.Y + height * 4 * SLAB_ZOOM) {
    return false;
  }
  return true;
}
