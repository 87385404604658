/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/authentication";
/// TYPES ///
import { User } from "api/types/authentication";

export const updateLocalUser = (user: User) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_LOCAL_USER,
      payload: user
    })
  }
}
