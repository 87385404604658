/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useGetDate } from "hooks/date/get";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { getEvents } from "api/actions/event";
import { getOrderEvents } from "api/actions/event";
import { getSelfOrderEvents } from "api/actions/event";
/// TYPES ///
import { IGetEventArgs } from "api/types/event";
import { ICustomEvent } from "api/types/event";
import { IOrderEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event";
/// DATETIME ///
import { DateTime } from "luxon";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// CUSTOM COMPONENTS ///
import DayCustomEvent from "./row/custom";
import DayOrderEvent from "./row/order";
import DaySelfOrderEvent from "./row/self_order";
/// COLORS ///
import { light_grey_color } from "styles/style";
/// VALUES ///
import { TEMPLATE } from "values/event";
import { ORDER_MATERIAL } from "values/event";
import { FABRICATION } from "values/event";
import { INSTALL } from "values/event";

const day_grid_style = {
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "15px",
  paddingBottom: "15px",
  borderBottom: `1px solid ${light_grey_color}`,
  borderLeft: `1px solid ${light_grey_color}`
}

export default function CalendarDay() {
  const dispatch = useAppDispatch();
  const {
    custom_events,
    filter,
    nav_date,
    order_events,
    self_order_events
  } = useAppSelector((state) => state.event);
  const [morning_custom_events, setMorningCustomEvents] = useState<ICustomEvent[]>([]);
  const [morning_order_events, setMorningOrderEvents] = useState<IOrderEvent[]>([]);
  const [morning_self_order_events, setMorningSelfOrderEvents] = useState<ISelfOrderEvent[]>([]);
  const [afternoon_custom_events, setAfternoonCustomEvents] = useState<ICustomEvent[]>([]);
  const [afternoon_order_events, setAfternoonOrderEvents] = useState<IOrderEvent[]>([]);
  const [afternoon_self_order_events, setAfternoonSelfOrderEvents] = useState<ISelfOrderEvent[]>([]);
  const [evening_custom_events, setEveningCustomEvents] = useState<ICustomEvent[]>([]);
  const [evening_order_events, setEveningOrderEvents] = useState<IOrderEvent[]>([]);
  const [evening_self_order_events, setEveningSelfOrderEvents] = useState<ISelfOrderEvent[]>([]);
  const loaded_date = useGetDate(nav_date);

  useEffect(() => {
    const filterEventType = (event: IOrderEvent | ISelfOrderEvent): boolean => {
      if (event?.event_type === TEMPLATE && filter.template)
        return false;
      else if (event?.event_type === ORDER_MATERIAL && filter.order_material)
        return false;
      else if (event?.event_type === FABRICATION && filter.fabricate_order)
        return false;
      else if (event?.event_type === INSTALL && filter.install)
        return false;
      return true;
    }

    const filterMorning = (event: IOrderEvent): boolean => {
      const date = DateTime.fromISO(event.time).toLocal();

      if (date.hour >= 0 && date.hour < 12)
        return true;
      return false;
    }

    const filterAfternoon = (event: IOrderEvent): boolean => {
      const date = DateTime.fromISO(event.time).toLocal();

      if (date.hour >= 12 && date.hour < 17)
        return true;
      return false;
    }

    const filterEvening = (event: IOrderEvent): boolean => {
      const date = DateTime.fromISO(event.time).toLocal();

      if (date.hour >= 17 && date.hour < 25)
        return true;
      return false;
    }

    const events: IOrderEvent[] = order_events.filter(e => filterEventType(e));

    setMorningOrderEvents(events.filter(e => filterMorning(e)));
    setAfternoonOrderEvents(events.filter(e => filterAfternoon(e)));
    setEveningOrderEvents(events.filter(e => filterEvening(e)));
  }, [order_events, loaded_date, filter]);

  useEffect(() => {
    const filterMorning = (event: ICustomEvent): boolean => {
      const date = DateTime.fromISO(event.time).toLocal();

      if (date.hour >= 0 && date.hour < 12)
        return true;
      return false;
    }

    const filterAfternoon = (event: ICustomEvent): boolean => {
      const date = DateTime.fromISO(event.time).toLocal();

      if (date.hour >= 12 && date.hour < 17)
        return true;
      return false;
    }

    const filterEvening = (event: ICustomEvent): boolean => {
      const date = DateTime.fromISO(event.time).toLocal();

      if (date.hour >= 17 && date.hour < 25)
        return true;
      return false;
    }

    if (filter?.custom) {
      setMorningCustomEvents([]);
      setAfternoonCustomEvents([]);
      setEveningCustomEvents([]);
    }
    else {
      setMorningCustomEvents(custom_events.filter(e => filterMorning(e)));
      setAfternoonCustomEvents(custom_events.filter(e => filterAfternoon(e)));
      setEveningCustomEvents(custom_events.filter(e => filterEvening(e)));
    }
  }, [custom_events, loaded_date, filter]);

  useEffect(() => {
    const filterEventType = (event: ISelfOrderEvent): boolean => {
      if (event?.event_type === TEMPLATE && filter.template)
        return false;
      else if (event?.event_type === ORDER_MATERIAL && filter.order_material)
        return false;
      else if (event?.event_type === FABRICATION && filter.fabricate_order)
        return false;
      else if (event?.event_type === INSTALL && filter.install)
        return false;
      return true;
    }

    const filterMorning = (event: ISelfOrderEvent): boolean => {
      const date = DateTime.fromISO(event.time).toLocal();

      if (date.hour >= 0 && date.hour < 12)
        return true;
      return false;
    }

    const filterAfternoon = (event: ISelfOrderEvent): boolean => {
      const date = DateTime.fromISO(event.time).toLocal();

      if (date.hour >= 12 && date.hour < 17)
        return true;
      return false;
    }

    const filterEvening = (event: ISelfOrderEvent): boolean => {
      const date = DateTime.fromISO(event.time).toLocal();

      if (date.hour >= 17 && date.hour < 25)
        return true;
      return false;
    }

    const events: ISelfOrderEvent[] = self_order_events.filter(e => filterEventType(e));

    setMorningSelfOrderEvents(events.filter(e => filterMorning(e)));
    setAfternoonSelfOrderEvents(events.filter(e => filterAfternoon(e)));
    setEveningSelfOrderEvents(events.filter(e => filterEvening(e)));
  }, [self_order_events, loaded_date, filter]);

  useEffect(() => {
    const args: IGetEventArgs = {
      start_time: loaded_date.startOf("day").toUTC().toISO(),
      end_time: loaded_date.endOf("day").toUTC().toISO(),
    };
    dispatch(getEvents(args));
    dispatch(getOrderEvents(args));
    dispatch(getSelfOrderEvents(args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded_date, dispatch, filter]);

  return (
    <Grid container sx={{ paddingTop: "15px", height: "80vh" }}>
      <Grid item xs={2} sx={[day_grid_style, { borderTop: `1px solid ${light_grey_color}` }]}>
        <Typography variant="body2">
          Morning <br />
          8AM - 12PM
        </Typography>
      </Grid>
      <Grid item xs={10} sx={[day_grid_style, { borderTop: `1px solid ${light_grey_color}`, borderRight: `1px solid ${light_grey_color}` }]}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {
            morning_order_events.map(event => <DayOrderEvent item={event} key={event.id} />)
          }
          {
            morning_self_order_events.map(event => <DaySelfOrderEvent item={event} key={event.id} />)
          }
          {
            morning_custom_events.map(event => <DayCustomEvent item={event} key={event.id} />)
          }
        </div>
      </Grid>
      <Grid item xs={2} sx={day_grid_style}>
        <Typography variant="body2">
          Afternoon <br />
          12PM - 5PM
        </Typography>
      </Grid>
      <Grid item xs={10} sx={[day_grid_style, { borderRight: `1px solid ${light_grey_color}` }]}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {
            afternoon_order_events.map(event => <DayOrderEvent item={event} key={event.id} />)
          }
          {
            afternoon_self_order_events.map(event => <DaySelfOrderEvent item={event} key={event.id} />)
          }
          {
            afternoon_custom_events.map(event => <DayCustomEvent item={event} key={event.id} />)
          }
        </div>
      </Grid>
      <Grid item xs={2} sx={day_grid_style}>
        <Typography variant="body2">
          Evening <br />
          5PM - 8PM
        </Typography>
      </Grid>
      <Grid item xs={10} sx={[day_grid_style, { borderRight: `1px solid ${light_grey_color}` }]}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {
            evening_order_events.map(event => <DayOrderEvent item={event} key={event.id} />)
          }
          {
            evening_self_order_events.map(event => <DaySelfOrderEvent item={event} key={event.id} />)
          }
          {
            evening_custom_events.map(event => <DayCustomEvent item={event} key={event.id} />)
          }
        </div>
      </Grid>
    </Grid>
  )
}
