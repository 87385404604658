/// TYPES ///
import { OrderArea } from "api/types/order/order";
import { SelfOrderArea } from "api/types/order/self_order";
import { OrderSlab } from "api/types/order/order";
import { SelfOrderSlab } from "api/types/order/self_order";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import DiagramSlab from "./slab";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";

interface Props {
  areas: OrderArea[] | SelfOrderArea[],
  slabs: OrderSlab[] | SelfOrderSlab[],
  shapes_loaded: boolean
}

export default function DiagramShapeAreas({ areas, slabs, shapes_loaded }: Props) {
  return (
    <Grid container item xs={12} sx={{ marginTop: "30px" }}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={title_style}>
          Slabs
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      {areas.map(area => {
        return (
          <Grid key={area.id} container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="body2" color="primary" sx={item_style(false)}>
                <b>{area.name}:</b> {area.color_data?.material_data?.name ?? ""} {area.color_data?.name ?? ""}
              </Typography>
            </Grid>
            {slabs.filter(slab => slab.area === area.id).map((slab, index) => {
              return (
                <DiagramSlab index={index} key={index} shapes_loaded={shapes_loaded} slab={slab} />
              )
            })
            }
          </Grid>
        )
      })}
    </Grid>
  )
}
