import { Vector2 } from "api/types/sketch";

export function adjustVectorToScale(point: Vector2, scale: number): Vector2 {
    return {
        X: Math.round(point.X * scale),
        Y: Math.round(point.Y * scale)
    }
}

export function adjustMeasurementToScale(measurement: number, zoom: number): number {
    return measurement * zoom;
}