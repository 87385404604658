/// EVENTS ///
import { KeyboardEvent } from "react";
import { MouseEvent } from "react";
/// HOOKS ///
import { useEffect } from "react";
import { useRef } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface Props {
  open: boolean,
  height: number,
  error: string,
  update: (height: number) => void,
  setOpen: (open: boolean) => void,
  save: () => void,
  cancel: () => void
}

export default function UpdateHeight({
  open,
  height,
  error,
  save,
  setOpen,
  cancel,
  update
}: Props) {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref !== null && ref.current !== null) {
        ref.current.focus();
        ref.current.select();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  const toggleDrawer = () => (event: KeyboardEvent | MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  const keyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      save();
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      cancel();
    }
  }

  return (
    <SwipeableDrawer
      BackdropProps={{ invisible: true }}
      disableAutoFocus
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      onKeyDown={keyDown}
      anchor="right"
      open={open}>
      <Box display="flex" flexDirection="column" justifyContent="center" sx={{ paddingTop: "25px", paddingLeft: "50px", paddingRight: "50px", width: "300px" }}>
        <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
          <IconButton onClick={cancel} onTouchStart={cancel}>
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Box>
        <Typography variant="h4" align="center" sx={{ marginTop: "50px" }}>
          Height
        </Typography>
        <Typography variant="body2" align="center" sx={{ paddingBottom: "50px" }}>
          Inches
        </Typography>
        <TextField
          sx={{ marginTop: "50px" }}
          inputRef={ref}
          type="number"
          error={error !== ""}
          helperText={error}
          autoComplete="off"
          inputProps={{
            autocomplete: "new-password",
            form: {
              autocomplete: "off"
            }
          }}
          value={height > 0 ? height : ""}
          onChange={e => update(Number(e.target.value))} />
        <Button
          sx={{ marginTop: "50px" }}
          onClick={save}>
          Save
        </Button>
      </Box>
    </SwipeableDrawer >
  );
}
