import { User } from "api/types/authentication";
import { Action } from "api/action_types/authentication";
import { ActionType } from "api/action_types/authentication";

const USER: string = "user";
const VIEW: string = "view";

interface State {
  user: User,
  shop_view: boolean,
  login_success: boolean,
  password_change_success: boolean,
  registration_complete: boolean,
  loading: boolean,
  updating: boolean,
  error: string | null
}

const initialState = {
  user: getUser(),
  shop_view: getView(),
  login_success: false,
  registration_complete: false,
  password_change_success: false,
  loading: false,
  updating: false,
  error: null
}

export const authenticationReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.LOGIN_SUCCESS:
      localStorage.setItem(USER, JSON.stringify(action.payload));
      getUser();
      return {
        ...state,
        loading: false,
        login_success: true,
        user: getUser(),
        error: null
      }
    case ActionType.LOGIN_PENDING:
      return {
        ...state,
        loading: true
      }
    case ActionType.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        user: {}
      }
    case ActionType.SET_SHOP_VIEW:
      localStorage.setItem(VIEW, action.shop_view.toString())
      return {
        ...state,
        shop_view: action.shop_view
      }
    case ActionType.CLEAR_ERRORS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    case ActionType.LOGOUT_SUCCESS:
      localStorage.removeItem(USER);
      return {
        ...state,
        user: {}
      }
    case ActionType.UPDATE_PROFILE_SUCCESS:
      const new_user: User = { ...state.user, ...action.payload };
      localStorage.setItem(USER, JSON.stringify(new_user));
      return {
        ...state,
        user: new_user,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_LOCAL_USER:
      return {
        ...state,
        user: action.payload
      }
    case ActionType.CHANGE_PASSWORD:
      return {
        ...state,
        password_change_success: true,
        loading: false,
        error: ""
      }
    case ActionType.SET_PAYMENT_INITIALIZED:
      return {
        ...state,
        user: { ...state.user, payment_initialized: true }
      }
    case ActionType.UPDATE_PROFILE_PENDING:
    case ActionType.CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        updating: true
      }
    case ActionType.UPDATE_PROFILE_FAIL:
    case ActionType.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case ActionType.AUTH_FAILED:
      localStorage.removeItem(USER);
      return {
        ...state,
        loading: false,
        user: {}
      }
    default:
      return state;
  }
}

function getUser(): User {
  const storage = localStorage.getItem(USER);

  if (storage) {
    const user: User | null = JSON.parse(localStorage.getItem(USER) || "");
    if (!user) {
      return {};
    }

    return user;
  }
  return {};
}

function getView(): boolean {
  const storage: string = localStorage.getItem(VIEW);

  if (storage) {
    if (storage === "false") {
      return false;
    }
  }
  return true;
}
