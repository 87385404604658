import { IGetOrderArgs } from "api/types/order/order";
import { Order } from "api/types/order";
import { OrderArea } from "api/types/order";
import { OrderSlab } from "api/types/order/order";
import { OrderCounter } from "api/types/order/order";
import { Action } from "api/action_types/order";
import { ActionType } from "api/action_types/order";

interface State {
  orders: Order[],
  order: Order,
  filter: IGetOrderArgs,
  areas: OrderArea[],
  counters: OrderCounter[],
  slabs: OrderSlab[],
  counters_loaded: boolean,
  areas_loaded: boolean,
  slabs_loaded: boolean,
  open_message: boolean,
  loading: boolean,
  updating: boolean,
  order_success: boolean,
  success_id: number,
  error: string
}

const initialState = {
  orders: [],
  order: null,
  filter: {},
  counters: [],
  areas: [],
  slabs: [],
  areas_loaded: false,
  counters_loaded: false,
  slabs_loaded: false,
  open_message: false,
  loading: false,
  updating: false,
  order_success: false,
  success_id: 0,
  error: ""
}

export const orderReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.CLEAR_ORDER:
      return {
        ...state,
        order: null,
        areas: [],
        counters: [],
        slabs: []
      }
    case ActionType.FILTER_ORDERS:
      return {
        ...state,
        filter: action.filter
      }
    case ActionType.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.GET_ORDER_COUNTERS_SUCCESS:
      return {
        ...state,
        counters: action.payload,
        counters_loaded: true,
        updating: false
      }
    case ActionType.GET_ORDER_AREAS_SUCCESS:
      return {
        ...state,
        areas: action.payload,
        areas_loaded: true,
        updating: false
      }
    case ActionType.GET_ORDER_SLABS_SUCCESS:
      return {
        ...state,
        slabs: action.payload,
        slabs_loaded: true,
        updating: false
      }
    case ActionType.GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: [...state.orders, action.payload],
        order: action.payload,
        order_success: true,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_LOCAL_ORDER:
      return {
        ...state,
        order: { ...state.order, ...action.payload }
      }
    case ActionType.RESET_ORDER_SUCCESS:
      return {
        ...state,
        order_success: false
      }
    case ActionType.ORDER_UPDATING:
      return {
        ...state,
        updating: true
      }
    case ActionType.ORDER_LOADING:
      return {
        ...state,
        loading: true
      }
    case ActionType.ORDER_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state;
  }
}



