/// TYPES ///
import { IOrderEvent } from "api/types/event";
/// REACT ///
import { Fragment } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// COMPONENTS ///
import OrderProgressColumn from "./column/order";
/// STYLES ///
import { btn_box_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";
import { title_style } from "styles/quote_preview/section";

interface Props {
  events: IOrderEvent[]
  selected_event_id: number | string,
}

export default function OrderEventProgress({ events, selected_event_id }: Props) {
  return (
    <Grid container item xs={12} alignItems="center" sx={{ paddingBottom: "50px" }}>
      <Grid item xs={6}>
        <Typography variant="h6" sx={title_style} >
          Order Progress
        </Typography>
      </Grid>
      <Grid item xs={6} sx={btn_box_style}>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ paddingLeft: "50px", paddingRight: "25px", paddingTop: "15px" }}>
          {
            events.map((event: IOrderEvent, index: number) => {
              return (
                <Fragment>
                  <OrderProgressColumn order_event={event} next_event={events?.[index + 1]} selected_event_id={selected_event_id} has_line={index !== events.length - 1} index={index} />
                </Fragment>
              )
            })
          }
        </Stack>
      </Grid>
    </Grid>
  )
}
