/// PDF ///
import { Canvas } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
/// TYPES ///
import { OrderSlab } from "api/types/order";
import { SelfOrderSlab } from "api/types/order";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { drawOrderPrintCutShapes } from "functions/sketch";
import { getOptimalSlabZoom } from "functions/sketch";
import { getSlabStartPoint } from "functions/sketch";
/// COLOR ///
import { light_grey_color } from "styles/style";
import { primary_color } from "styles/style";

const base_offset: Vector2 = { X: 0, Y: 0 };

interface Props {
  index: number,
  slab: OrderSlab | SelfOrderSlab,
}

const styles = StyleSheet.create({
  slab_area: {
    width: "225px",
    display: "flex",
    justifyContent: "center",
  }
})

export default function OrderPrintSlab({ index, slab }: Props) {
  const paintCanvas = (painter: any, availableWidth: number, availableHeight: number) => {
    const width: number = slab?.width * 4 ?? 0;
    const height: number = slab?.length * 4 ?? 0;
    let zoom: number = getOptimalSlabZoom(
      width,
      height,
      { X: availableWidth, Y: availableHeight }
    );
    const slab_start: Vector2 = getSlabStartPoint(height / 4, index, 1);

    if (width && height) {
      painter.moveTo(base_offset.X, base_offset.Y);
      painter.lineTo(base_offset.X + width * zoom, base_offset.Y);
      painter.lineTo(base_offset.X + width * zoom, base_offset.Y + height * zoom);
      painter.lineTo(base_offset.X, base_offset.Y + height * zoom);
      painter.lineTo(base_offset.X, base_offset.Y);
      painter.fill(light_grey_color);
      drawOrderPrintCutShapes(painter, slab, zoom, base_offset, slab_start);
      painter.fill(primary_color);
    }
    else {
      zoom = 1;
      painter.text("Must set slab material to get slab diagram", 10, -10);
    }
    return null;
  }

  return (
    <View style={styles.slab_area}>
      <Canvas style={{ height: "125px" }} paint={paintCanvas} />
    </View>
  );
}
