import { CornerData } from "api/types/sketch";
import { CornerClip } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { getDistance } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { getAngleOnSecondHalfCornerSeam } from "../seam";
import { constructClipCorner } from "../../corner/construct";

export function drawSecondHalfPrintCutClippedCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    zoom: number
): CornerData {
    const cut_angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const angle: number = getAngleOnSecondHalfCornerSeam(cut_angle, next_angle);
    const corner_clip: CornerClip = constructClipCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom
    );

    const diagonal_angle: number = getAngle(corner_clip.start_point, corner_clip.end_point);
    const distance: number = getDistance(corner_clip.start_point, corner_clip.end_point);
    const halfway_point: Vector2 = getDistantPoint(corner_clip.end_point, diagonal_angle - 90, distance / 2);

    if(i === 0){
        painter.moveTo(halfway_point.X, halfway_point.Y)
    }
    else{
        painter.lineTo(halfway_point.X, halfway_point.Y);
    }

    painter.lineTo(corner_clip.end_point.X, corner_clip.end_point.Y);
    
    return {
        first_point: halfway_point,
        last_point: corner_clip.end_point
    }
}