import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import MainRouter from "components/navigation/router";
import "./App.css";
/// PROVIDER ///
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
/// KEYS ///
import { STRIPE_PUBLIC_KEY } from "settings";
/// THEMES ///
import { theme } from "themes";

export default function App() {
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

  return (
    <CssVarsProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-us">
        <Elements stripe={stripePromise}>
          <MainRouter />
        </Elements>
      </LocalizationProvider>
    </CssVarsProvider>
  )
}
