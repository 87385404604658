/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
/// ACTIONS ///
import { clearMarketOrders } from "api/actions/order/market_order";
import { clearSelfOrders } from "api/actions/self_order/self_order/local/clear";
import { getEvents } from "api/actions/event";
import { getOrderEvents } from "api/actions/event";
import { getSelfOrderEvents } from "api/actions/event";
import { setBreadcrumbs } from "api/actions/ui/set";
import { setNavDate } from "api/actions/event/local/set_nav_date";
/// TYPES ///
import { IGetEventArgs } from "api/types/event";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import Grid from "@mui/material/Grid";
/// COMPONENTS ///
import CalendarBar from "../calendar_bar";
import CalendarSidebar from "../side_bar";
import WeekHeader from "./header";
import WeekBody from "./body";
/// DATE ///
import { DateTime } from "luxon";
/// VALUES ///
import { WEEK } from "values/event";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { calendar_content_style } from "styles/universal/page";

export default function CalendarWeek() {
  const dispatch = useAppDispatch();
  const { nav_date } = useAppSelector((state) => state.event);
  const { filter } = useAppSelector((state) => state.event);
  const loaded_date = DateTime.fromISO(nav_date);

  useEffect(() => {
    const args: IGetEventArgs = {
      start_time: loaded_date.startOf("week").toUTC().toISO(),
      end_time: loaded_date.endOf("week").toUTC().toISO(),
    };
    dispatch(getEvents(args));
    dispatch(getOrderEvents(args));
    dispatch(getSelfOrderEvents(args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nav_date, dispatch, filter]);


  useEffect(() => {
    return () => {
      dispatch(clearSelfOrders());
      dispatch(clearMarketOrders());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setBreadcrumbs([]));
  }, [dispatch]);

  const changeDate = (month_change: number, day_change: number) => {
    dispatch(setNavDate(DateTime.fromISO(nav_date).plus({ months: month_change, days: day_change }).toISO()))
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={calendar_content_style}>
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <CalendarBar changeDate={changeDate} event_view_type={WEEK} />
        <Grid container>
          <WeekHeader />
          <WeekBody />
        </Grid>
      </div>
      <CalendarSidebar />
    </motion.div>
  )
}
