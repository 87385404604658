/// REACT ///
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
/// TYPES ///
import { FabricatorQuote } from "api/types/market";
import { Order } from "api/types/order";
/// ACTIONS ///
import { createMarketOrder } from "api/actions/order/market_order";
/// LUXON ///
import { DateTime } from "luxon";
/// MUI ///
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  market_quote: FabricatorQuote
}

export default function MarketConfirmOrder({ open, setOpen, market_quote }: Props) {
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<DateTime>(DateTime.now());


  const handleCreate = () => {
    if (
      market_quote.fabricator &&
      market_quote.id &&
      market_quote.quote &&
      market_quote.end_customer &&
      market_quote.quote_data &&
      market_quote.quote_data.name &&
      market_quote.tax &&
      market_quote.total
    ) {
      const order: Order = {
        fabricator: market_quote.fabricator,
        end_customer: market_quote.end_customer,
        target_install_date: date.toLocal().toISO(),
        quote: market_quote.quote,
        fabricator_quote: market_quote.id,
        name: market_quote.quote_data.name,
        tax: market_quote.tax,
        total: market_quote.total
      }
      dispatch(createMarketOrder(order));
      setOpen(false);
    }
  }


  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Confirm Order</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Typography variant="body2">
          {`Are you sure you want to order with ${market_quote?.user?.business_name ?? ""} for $${((market_quote?.total ?? 0) + (market_quote?.tax ?? 0) + (market_quote?.second_tax ?? 0)).toFixed(2)}?`}
        </Typography>
        <DatePicker
          defaultValue={DateTime.now()}
          slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
          label="Order Date"
          disablePast={true}
          onChange={e => setDate(e)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleCreate}>Create Order</Button>
      </DialogActions>
    </Dialog>
  )
}
