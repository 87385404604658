/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { title_style } from "styles/quote_preview/section";
/// URLS ///
import { billing_url } from "components/navigation/endpoints";
import { org_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { org } from "values/breadcrumbs/organization";
import { org_billing } from "values/breadcrumbs/organization";
import { org_terms } from "values/breadcrumbs/organization";

export default function TermsAndConditions() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setBreadcrumbs([org, org_billing, org_terms]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start">
        <Grid container item xs={12} lg={6}>
          <PageTitle title="Terms & Conditions" endProps={
            <Box>
              <BaseButton text="Back" icon={faRightToBracket} clickEvent={() => navigate(`/${org_url}/${billing_url}`)} />
            </Box>
          } />
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Coming Soon
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </motion.div>
  )
}
