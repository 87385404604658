/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { create_url } from "components/navigation/endpoints";
import { detail_url } from "components/navigation/endpoints";
import { shops_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";

export const shops_list: IBreadcrumb = {
  id: 0,
  label: "Shops",
  url: `/${shops_url}`
}

export const shops_add: IBreadcrumb = {
  id: 1,
  label: "Add",
  url: `/${shops_url}/${create_url}`
}

export const shop: IBreadcrumb = {
  id: 2,
  label: "Shop",
  url: `/${shops_url}/${detail_url}/id`
}

export const shop_update: IBreadcrumb = {
  id: 3,
  label: "Update",
  url: `/${shops_url}/${detail_url}/id/${update_url}`
}
