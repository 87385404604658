import { CornerRadius } from "api/types/sketch";
import { InwardCornerRadius } from "api/types/sketch";
import { CornerNotch } from "api/types/sketch";
import { CornerClip } from "api/types/sketch";
import { CornerDiagonal } from "api/types/sketch";
import { CornerRecessedDiagonal } from "api/types/sketch";
import { CornerBumpOut } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { makeAnglePositive } from "functions/sketch/math";
import { constructRadiusCorner } from "../../corner/construct";
import { constructInwardRadiusCorner } from "../../corner/construct";
import { constructNotchCorner } from "../../corner/construct";
import { constructClipCorner } from "../../corner/construct";
import { constructDiagonalCorner } from "../../corner/construct";
import { constructBumpoutCorner } from "../../corner/construct";
import { constructRecessedDiagonalCorner } from "../../corner/construct";
import { RADIUS } from "values/quote";
import { INNER_RADIUS } from "values/quote";
import { NOTCH } from "values/quote";
import { BUMPOUT } from "values/quote";
import { CLIP } from "values/quote";
import { RECESSED_DIAGONAL } from "values/quote";
import { DIAGONAL } from "values/quote";

export function getCornerPoints(
    points: number[][],
    corner_type: string,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    zoom: number,
    radius: number,
    length: number,
    depth: number
): Vector2 {
    let first_point = {X: 0, Y: 0};
    if(corner_type === RADIUS){
    const current_angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
        const corner_radius: CornerRadius = constructRadiusCorner(
            current_angle,
            current_location,
            next_angle,
            radius * zoom
        );
        const angle: number = getAngle(corner_radius.center_point, current_location) + 90;
        const radius_top: Vector2 = getDistantPoint(corner_radius.center_point, angle, radius * 4);
        points.push([corner_radius.start_point.X, corner_radius.start_point.Y]);
        points.push([radius_top.X, radius_top.Y]);
        points.push([corner_radius.end_point.X, corner_radius.end_point.Y]);
        first_point = corner_radius.start_point;
    }
    else if(corner_type === INNER_RADIUS){
        const intitial_angle: number = getAngle(previous_location, current_location) + 90;
        const next_angle: number = getAngle(current_location, next_location) + 90;
        const corner_radius: InwardCornerRadius = constructInwardRadiusCorner(
            intitial_angle,
            current_location,
            next_angle,
            radius * zoom
        );
        const angle: number = getAngle(current_location, corner_radius.center_point) + 90;
        const radius_top: Vector2 = getDistantPoint(current_location, angle, radius * 4);
        points.push([corner_radius.start_point.X, corner_radius.start_point.Y]);
        points.push([radius_top.X, radius_top.Y]);
        points.push([corner_radius.end_point.X, corner_radius.end_point.Y]);
        first_point = corner_radius.start_point;
    }
    else if(corner_type === NOTCH){
        const angle: number = getAngle(previous_location, current_location) + 90;
        const next_angle: number = getAngle(current_location, next_location) + 90;
        const corner_notched: CornerNotch = constructNotchCorner(
            angle,
            current_location,
            next_angle,
            length * zoom,
            depth * zoom
        );
        points.push([corner_notched.start_point.X, corner_notched.start_point.Y]);
        points.push([corner_notched.inner_corner.X, corner_notched.inner_corner.Y]);
        points.push([corner_notched.end_point.X, corner_notched.end_point.Y]);
        first_point = corner_notched.start_point;
    }
    else if(corner_type === CLIP){
        const angle: number = getAngle(previous_location, current_location) + 90;
        const next_angle: number = getAngle(current_location, next_location) + 90;
        const corner_clip: CornerClip = constructClipCorner(
            angle,
            current_location,
            next_angle,
            length * zoom
        );
        points.push([corner_clip.start_point.X, corner_clip.start_point.Y]);
        points.push([corner_clip.end_point.X, corner_clip.end_point.Y]);
        first_point = corner_clip.start_point;
    }
    else if(corner_type === DIAGONAL){
        const angle: number = getAngle(previous_location, current_location) + 90;
        const next_angle: number = getAngle(current_location, next_location) + 90;
        const corner_diagonal: CornerDiagonal = constructDiagonalCorner(
            angle,
            current_location,
            next_angle,
            length * zoom
        );
        points.push([corner_diagonal.start_point.X, corner_diagonal.start_point.Y]);
        points.push([corner_diagonal.end_point.X, corner_diagonal.end_point.Y]);
        first_point = corner_diagonal.start_point;
    }
    else if(corner_type === BUMPOUT){
        const angle_A: number = makeAnglePositive(getAngle(previous_location, current_location) - 180, false);
        const angle_B: number = makeAnglePositive(getAngle(current_location, next_location) - 180, false);
        const outward_angle: number = Math.round((angle_A + angle_B) / 2 - (angle_A < angle_B ? 180 : 0));
        const corner_bumpout: CornerBumpOut = constructBumpoutCorner(
            previous_location,
            current_location,
            next_location,
            outward_angle,
            length * zoom,
            depth * zoom
        );
        points.push([corner_bumpout.A.X, corner_bumpout.A.Y]);
        points.push([corner_bumpout.B.X, corner_bumpout.B.Y]);
        points.push([corner_bumpout.C.X, corner_bumpout.C.Y]);
        points.push([corner_bumpout.D.X, corner_bumpout.D.Y]);
        first_point = corner_bumpout.A;
    }
    else if(corner_type === RECESSED_DIAGONAL){
        const angle_A: number = makeAnglePositive(getAngle(previous_location, current_location) - 180, false);
        const angle_B: number = makeAnglePositive(getAngle(current_location, next_location) - 180, false);
        const inward_angle: number = Math.round((angle_A + angle_B) / 2 - (angle_A < angle_B ? 180 : 0));
        const corner_recessed_diagonal: CornerRecessedDiagonal = constructRecessedDiagonalCorner(
            previous_location,
            current_location,
            next_location,
            inward_angle,
            length * zoom,
            depth * zoom
        );
        points.push([corner_recessed_diagonal.A.X, corner_recessed_diagonal.A.Y]);
        points.push([corner_recessed_diagonal.B.X, corner_recessed_diagonal.B.Y]);
        points.push([corner_recessed_diagonal.C.X, corner_recessed_diagonal.C.Y]);
        points.push([corner_recessed_diagonal.D.X, corner_recessed_diagonal.D.Y]);
        first_point = corner_recessed_diagonal.A;
    }
    else{
        points.push([current_location.X, current_location.Y]);
        first_point = current_location;
    }
    return first_point;
}