export enum ActionType {
  SHOP_QUOTE_ERROR = "SHOP_QUOTE_ERROR",
  SHOP_COUNTERS_ERROR = "SHOP_COUNTERS_ERROR",
  SHOP_SHAPES_ERROR = "SHOP_SHAPES_ERROR",
  SHOP_AREAS_ERROR = "SHOP_AREAS_ERROR"
}

interface IShopQuoteError {
  type: ActionType.SHOP_QUOTE_ERROR,
  payload: string
}

interface IShopCountersError {
  type: ActionType.SHOP_COUNTERS_ERROR,
  payload: string
}

interface IShopShapesError {
  type: ActionType.SHOP_SHAPES_ERROR,
  payload: string
}

interface IShopAreasError {
  type: ActionType.SHOP_AREAS_ERROR,
  payload: string
}

export type Action = IShopQuoteError | IShopCountersError | IShopShapesError | IShopAreasError;
