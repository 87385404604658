import { Corner } from "api/types/sketch";

export enum ActionType {
    UPDATE_CORNER_SUCCESS="UPDATE_CORNER_SUCCESS"
}

interface updateCornerSuccess {
    type: ActionType.UPDATE_CORNER_SUCCESS,
    payload: Corner
}

export type Action = updateCornerSuccess;