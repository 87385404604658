/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { createPortalSession } from "api/actions/billing";
import { getStripeCustomer } from "api/actions/billing";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import Loading from "components/universal/loading";
import LoadingPage from "components/universal/loading_page";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { title_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
/// URLS ///
import { billing_url } from "components/navigation/endpoints";
import { org_url } from "components/navigation/endpoints";
import { terms_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { org } from "values/breadcrumbs/organization";
import { org_billing } from "values/breadcrumbs/organization";

export default function OrgBilling() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    billing_customer,
    loading,
    session,
    updating
  } = useAppSelector((state) => state.billing);

  useEffect(() => {
    dispatch(setBreadcrumbs([org, org_billing]));
  }, [dispatch]);

  useEffect(() => {
    if (!(billing_customer?.id)) {
      dispatch(getStripeCustomer());
    }
  }, [dispatch, billing_customer?.id]);

  if (session && session.session_url) {
    window.location.href = session.session_url;
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={loading}>
        <Grid container alignItems="flex-start">
          <Grid container item xs={12} lg={6}>
            <PageTitle title="Arris Billing" endProps={
              <Box>
                <BaseButton text="Payment Method" icon={faCreditCard} clickEvent={() => dispatch(createPortalSession())} />
                <BaseButton text="Terms" icon={faFileContract} clickEvent={() => navigate(`/${org_url}/${billing_url}/${terms_url}`)} />
              </Box>
            } />
            <Grid item xs={12}>
              <Box sx={header_style}></Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" sx={title_style}>
                Payment Contact & Address
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <BaseButton text="Update" icon={faSave} clickEvent={() => navigate(`/${org_url}/${billing_url}/${update_url}`)} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={item_style()}>
                <b>Name</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {billing_customer?.name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={item_style()}>
                <b>Email</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {billing_customer?.email}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={item_style()}>
                <b>Phone Number</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {billing_customer?.phone}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={item_style()}>
                <b>Address</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {billing_customer?.address?.line1}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={item_style()}>
                <b>Address 2</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {billing_customer?.address?.line2}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={item_style()}>
                <b>City</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {billing_customer?.address?.city}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={item_style()}>
                <b>State/Province</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {billing_customer?.address?.state}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={item_style()}>
                <b>Country</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {billing_customer?.address?.country}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={item_style()}>
                <b>Postal Code</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {billing_customer?.address?.postal_code}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </LoadingPage>
      <Loading loading={updating} />
    </motion.div>
  )
}
