import { ICustomEvent } from "api/types/event";
import { IOrderEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event/self_order_event";
import { IEventFilter } from "api/types/event";

export enum ActionType {
  GET_CUSTOM_EVENTS = "GET_EVENTS",
  GET_CUSTOM_EVENT = "GET_EVENT",
  GET_ORDER_EVENTS = "GET_ORDER_EVENTS",
  GET_ORDER_EVENT = "GET_ORDER_EVENT",
  GET_SELF_ORDER_EVENT = "GET_SELF_ORDER_EVENT",
  GET_SELF_ORDER_EVENTS = "GET_SELF_ORDER_EVENTS",
  CREATE_CUSTOM_EVENT = "CREATE_CUSTOM_EVENT",
  CREATE_ORDER_EVENT = "CREATE_ORDER_EVENT",
  CREATE_SELF_ORDER_EVENT = "CREATE_SELF_ORDER_EVENT",
  UPDATE_CUSTOM_EVENT = "UPDATE_CUSTOM_EVENT",
  UPDATE_ORDER_EVENT = "UPDATE_ORDER_EVENT",
  UPDATE_SELF_ORDER_EVENT = "UPDATE_SELF_ORDER_EVENT",
  DELETE_CUSTOM_EVENT = "DELETE_CUSTOM_EVENT",
  DELETE_ORDER_EVENT = "DELETE_ORDER_EVENT",
  DELETE_SELF_ORDER_EVENT = "DELETE_SELF_ORDER_EVENT",
  EVENT_UPDATING = "EVENT_UPDATING",
  SET_NAV_DATE = "SET_NAV_DATE",
  SET_EVENT_FILTER = "SET_EVENT_FILTER",
  SET_ORDER_EVENT = "SET_ORDER_EVENT",
  SET_SELF_ORDER_EVENT = "SET_SELF_ORDER_EVENT",
  RESET_ACTION_SUCCESS = "RESET_ACTION_SUCCESS",
  EVENT_ERROR = "EVENT_ERROR"
}

interface IGetCustomEvents {
  type: ActionType.GET_CUSTOM_EVENTS,
  events: ICustomEvent[]
}

interface IGetCustomEvent {
  type: ActionType.GET_CUSTOM_EVENT,
  event: ICustomEvent
}

interface IGetOrderEvents {
  type: ActionType.GET_ORDER_EVENTS,
  order_events: IOrderEvent[]
}

interface IGetOrderEvent {
  type: ActionType.GET_ORDER_EVENT,
  order_event: IOrderEvent
}

interface IGetSelfOrderEvents {
  type: ActionType.GET_SELF_ORDER_EVENTS,
  self_order_events: ISelfOrderEvent[]
}

interface IGetSelfOrderEvent {
  type: ActionType.GET_SELF_ORDER_EVENT,
  self_order_event: ISelfOrderEvent
}

interface ICreateCustomEvent {
  type: ActionType.CREATE_CUSTOM_EVENT,
  event: ICustomEvent
}

interface ICreateOrderEvent {
  type: ActionType.CREATE_ORDER_EVENT,
  order_event: IOrderEvent
}

interface ICreateSelfOrderEvent {
  type: ActionType.CREATE_SELF_ORDER_EVENT,
  self_order_event: ISelfOrderEvent
}

interface IUpdateCustomEvent {
  type: ActionType.UPDATE_CUSTOM_EVENT,
  event: ICustomEvent
}

interface IUpdateOrderEvent {
  type: ActionType.UPDATE_ORDER_EVENT,
  order_event: IOrderEvent
}

interface IUpdateSelfOrderEvent {
  type: ActionType.UPDATE_SELF_ORDER_EVENT,
  self_order_event: ISelfOrderEvent
}

interface IDeleteCustomEvent {
  type: ActionType.DELETE_CUSTOM_EVENT,
  id: string | number
}

interface IDeleteOrderEvent {
  type: ActionType.DELETE_ORDER_EVENT,
  id: string | number
}

interface IDeleteSelfOrderEvent {
  type: ActionType.DELETE_SELF_ORDER_EVENT,
  id: string | number
}

interface ISetNavDate {
  type: ActionType.SET_NAV_DATE,
  date: string
}

interface ISetOrderEvent {
  type: ActionType.SET_ORDER_EVENT,
  event: IOrderEvent
}

interface ISetSelfOrderEvent {
  type: ActionType.SET_SELF_ORDER_EVENT,
  event: ISelfOrderEvent
}

interface IResetActionSuccess {
  type: ActionType.RESET_ACTION_SUCCESS
}

interface IEventUpdating {
  type: ActionType.EVENT_UPDATING
}

interface IEventError {
  type: ActionType.EVENT_ERROR,
  error: string
}

interface ISetEventFilter {
  type: ActionType.SET_EVENT_FILTER,
  filter: IEventFilter
}

export type Action =
  IGetCustomEvents |
  IGetCustomEvent |
  IGetOrderEvents |
  IGetOrderEvent |
  IGetSelfOrderEvents |
  IGetSelfOrderEvent |
  ICreateCustomEvent |
  ICreateOrderEvent |
  ICreateSelfOrderEvent |
  IUpdateCustomEvent |
  IUpdateOrderEvent |
  IUpdateSelfOrderEvent |
  IDeleteCustomEvent |
  IDeleteOrderEvent |
  IDeleteSelfOrderEvent |
  ISetNavDate |
  ISetOrderEvent |
  ISetSelfOrderEvent |
  IResetActionSuccess |
  IEventUpdating |
  IEventError |
  ISetEventFilter;
