import { Counter } from "api/types/sketch";
import { Sink } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { createRectangleSink } from "../sink/create";
import { createOvalSink } from "../sink/create";
import { createDoubleSink } from "../sink/create";
import { createThirtySeventySink } from "../sink/create";
import { createSeventyThirtySink } from "../sink/create";
import { createSixtyFortySink } from "../sink/create";
import { createFortySixtySink } from "../sink/create";
import { RECTANGLE } from "values/values";
import { OVAL } from "values/values";
import { DOUBLE } from "values/values";
import { THIRTY_OVER_SEVENTY } from "values/values";
import { SEVENTY_OVER_THIRTY } from "values/values";
import { SIXTY_OVER_FORTY } from "values/values";
import { FORTY_OVER_SIXTY } from "values/values";
import { highlightCanvas } from "functions/sketch/context";
import { getCounterColor } from "functions/sketch/get";
import { adjustVectorToScale } from "../scale";

export function drawSinks(
  context: CanvasRenderingContext2D,
  counters: Counter[],
  already_highlighted: boolean,
  mouse: Vector2,
  zoom: number,
  offset?: Vector2
): Sink | null {
  let has_selected: boolean = false;
  let selected_sink: Sink | null = null;
  for (let i = 0; i < counters.length; i++) {
    const counter: Counter = counters[i];
    for (let j = 0; j < counter.sinks.length; j++) {
      const sink_path = new Path2D();
      const sink: Sink = counter.sinks[j];

      const pre_location: Vector2 = adjustVectorToScale(sink.location, zoom);
      const location: Vector2 = { X: pre_location.X + offset?.X ?? 0, Y: pre_location.Y + offset?.Y ?? 0 };

      let highlight: boolean = false;
      if (sink.shape === RECTANGLE) {
        highlight = createRectangleSink(
          sink_path,
          sink.sink_type,
          location,
          sink.angle,
          sink.width,
          sink.length,
          sink.faucet_hole_count,
          mouse,
          zoom,
          has_selected,
          already_highlighted
        );
      }
      else if (sink.shape === OVAL) {
        highlight = createOvalSink(
          sink_path,
          sink.sink_type,
          location,
          sink.angle,
          sink.width,
          sink.length,
          sink.faucet_hole_count,
          mouse,
          zoom,
          has_selected,
          already_highlighted
        );
      }
      else if (sink.shape === DOUBLE) {
        highlight = createDoubleSink(
          sink_path,
          sink.sink_type,
          location,
          sink.angle,
          sink.width,
          sink.length,
          sink.faucet_hole_count,
          mouse,
          zoom,
          has_selected,
          already_highlighted
        );
      }
      else if (sink.shape === THIRTY_OVER_SEVENTY) {
        highlight = createThirtySeventySink(
          sink_path,
          sink.sink_type,
          location,
          sink.angle,
          sink.width,
          sink.length,
          sink.faucet_hole_count,
          mouse,
          zoom,
          has_selected,
          already_highlighted
        );
      }
      else if (sink.shape === SEVENTY_OVER_THIRTY) {
        highlight = createSeventyThirtySink(
          sink_path,
          sink.sink_type,
          location,
          sink.angle,
          sink.width,
          sink.length,
          sink.faucet_hole_count,
          mouse,
          zoom,
          has_selected,
          already_highlighted
        );
      }
      else if (sink.shape === FORTY_OVER_SIXTY) {
        highlight = createFortySixtySink(
          sink_path,
          sink.sink_type,
          location,
          sink.angle,
          sink.width,
          sink.length,
          sink.faucet_hole_count,
          mouse,
          zoom,
          has_selected,
          already_highlighted
        );
      }
      else if (sink.shape === SIXTY_OVER_FORTY) {
        highlight = createSixtyFortySink(
          sink_path,
          sink.sink_type,
          location,
          sink.angle,
          sink.width,
          sink.length,
          sink.faucet_hole_count,
          mouse,
          zoom,
          has_selected,
          already_highlighted
        );
      }

      if (highlight) {
        highlightCanvas(context);
        has_selected = true;
        selected_sink = sink;
      }
      else {
        context.strokeStyle = getCounterColor(counter.drawing_color);
      }
      context.stroke(sink_path);


    }
  }
  return selected_sink;
}
