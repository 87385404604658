/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCooktop } from "api/types/shop_quote/cooktop";
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopSink } from "api/types/shop_quote/sink";
import { IShopPoint } from "api/types/shop_quote/point";
import { Vector2 } from "api/types/sketch";
/// FUNCTION ///
import { getDistance } from "../math";
import { getDistantPoint } from "../math";
import { getAngle } from "../math";

export function moveShopCounter(selected_counter: IShopCounter, lift_point: Vector2, mouse: Vector2): IShopCounter | null {
  const distance: number = getDistance(lift_point, mouse);
  const angle: number = getAngle(lift_point, mouse) + 90;

  const counter: IShopCounter = { ...selected_counter };
  const points: IShopPoint[] = [];
  const corners: IShopCorner[] = [];

  for (let i = 0; i < counter.points.length; i++) {
    const point: IShopPoint = { ...counter.points[i] };
    point.location = getDistantPoint(counter.points[i].location, angle, distance);
    points.push(point);
  }

  for (let i = 0; i < counter.corners.length; i++) {
    const corner: IShopCorner = { ...counter.corners[i] };
    corner.location = getDistantPoint(counter.corners[i].location, angle, distance);
    if (counter.corners[i].location.X > 0 && counter.corners[i].location.Y > 0 && (corner.location.X < 0 || corner.location.Y < 0)) {
      return null;
    }
    corners.push(corner);
  }

  const cooktops: IShopCooktop[] = [];
  const sinks: IShopSink[] = [];

  for (let i = 0; i < counter.cooktops.length; i++) {
    const cooktop: IShopCooktop = { ...counter.cooktops[i] };
    cooktop.location = getDistantPoint(cooktop.location, angle, distance);
    cooktops.push(cooktop);
  }

  for (let i = 0; i < counter.sinks.length; i++) {
    const sink: IShopSink = { ...counter.sinks[i] };
    sink.location = getDistantPoint(sink.location, angle, distance);
    sinks.push(sink);
  }

  return { ...counter, points: points, corners: corners, cooktops: cooktops, sinks: sinks };
}
