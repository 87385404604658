import { useEffect } from "react";
import { useAppSelector } from "api/hooks/apiHook";
import { Text } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { MouseData } from "types/mouse_position";
import { drawSlabs } from "functions/sketch";
import { drawCutShapes } from "functions/sketch";
import { drawTexts } from "functions/sketch";
import { getCanvasHeight } from "functions/sketch";
import { getCanvas } from "functions/sketch/context";
import { getContext } from "functions/sketch/context";
import { detailCanvas } from "functions/sketch/context";
import { initializeSlabCanvas } from "functions/sketch/context";
import { grey_color } from "styles/style";

function useDrawCutShapes(
  index: number,
  mouse_data: MouseData,
  seams_open: boolean,
  value: number
) {
  const { areas } = useAppSelector((state) => state.quote);
  const { cut_shapes } = useAppSelector((state) => state.sketch);

  useEffect(() => {
    const mouse: Vector2 = mouse_data.position;
    const texts: Text[] = [];
    const slab_path = new Path2D();
    const indicator_path = new Path2D();

    if (!seams_open) {
      const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById("canvas"));
      const context: CanvasRenderingContext2D | null = getContext(canvas);
      if (canvas && context) {
        let height = canvas.height;
        let color_height: number = areas[value]?.material_length ?? 0;
        height = getCanvasHeight(color_height, areas[value]?.slabs?.length ?? 1);

        if (canvas && canvas.height !== height && height > canvas.height) {
          canvas.height = height;
        }

        drawSlabs(
          areas[value],
          slab_path,
          indicator_path,
          texts
        );

        initializeSlabCanvas(canvas, context);
        context.fill(slab_path);
        context.stroke(slab_path);

        drawCutShapes(context, areas[value].number - 1, cut_shapes.filter(cut_shape => cut_shape.area_uuid === areas[value].uuid));

        detailCanvas(context);
        drawTexts(context, grey_color, texts, mouse, false);
        context.stroke(indicator_path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas[value], cut_shapes, index, seams_open]);
};
export default useDrawCutShapes;
