import { Dispatch } from "redux";
import { Action } from "api/action_types/signature";
import { ActionType } from "api/action_types/signature";

export const openSignatureMessage = (open: boolean) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.OPEN_MESSAGE,
            open: open
        });
    }
}