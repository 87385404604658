import { Order } from "api/types/order";

export enum ActionType {
  SORT_MARKET_ORDER = "SORT_MARKET_ORDER"
}

interface ISortMarketOrder {
  type: ActionType.SORT_MARKET_ORDER,
  orders: Order[]
}

export type Action = ISortMarketOrder;
