/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// TYPES ///
import { IGetOrderArgs } from "api/types/order/order";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/order";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/order";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// URLS ///
import { url_prefix } from "settings";
import { order_url } from "../endpoint";

export const getMarketOrders = (args: IGetOrderArgs, customer?: boolean) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.ORDER_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${order_url}/?vendor=true&`;

    if (customer) {
      url += "customer=true&";
    }

    if (args?.order_name && args?.order_name !== "") {
      url += `order_name=${args?.order_name}&`;
    }

    if (args?.customer_name && args?.customer_name !== "") {
      url += `customer_name=${args?.customer_name}&`;
    }

    if (args?.start_date) {
      url += `start_date=${args?.start_date}&`;
    }

    if (args?.end_date) {
      url += `end_date=${args?.end_date}&`;
    }

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_ORDERS_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.ORDER_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const getMarketOrder = (id: number | string, customer?: boolean) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.ORDER_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${order_url}/${id}?`;

    if (customer) {
      url += "customer=true&";
    }

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_ORDER_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.ORDER_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
