import { useState } from "react";
import { useAppDispatch, useAppSelector } from 'api/hooks/apiHook';
import { setProduct } from "api/actions/billing";
import { createStripeCustomer } from "api/actions/billing";
import { IBillingCx } from "api/types/billing";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Steppers from "components/universal/stepper";
import ChoosePlan from "../tabs/plan";
import Billing from "../tabs/billing";
import PaymentForm from "../tabs/payment";
import PageTitle from "components/universal/page/title";
import { payment_setup_steps } from "values/steps";
import { mainContentAreaStyle } from "styles/style";

export default function SubscriptionSetup() {
  const dispatch = useAppDispatch();
  const [index, setIndex] = useState(0);
  const { user } = useAppSelector((state) => state.authentication);

  const choosePlan = (product_id: string, price_id: string) => {
    dispatch(setProduct(product_id, price_id));
    setIndex(1);
  }

  const submitBilling = (customer: IBillingCx) => {
    dispatch(createStripeCustomer(customer, user.id));
    setIndex(2);
  }

  return (
    <Box sx={[mainContentAreaStyle]}>
      <Grid container sx={{ marginTop: 1 }}>
        <PageTitle
          title="Sign Up"
          endProps={<div></div>} />
        <Grid item xs={10}>
          {
            index === 0 ?
              <ChoosePlan
                choosePlan={choosePlan} /> :
              null
          }
          {
            index === 1 ?
              <Billing
                submitBilling={submitBilling} /> :
              null
          }
          {
            index === 2 ?
              <PaymentForm /> :
              null
          }
        </Grid>
        <Grid item xs={2}>
          <Steppers current_step={index} steps={payment_setup_steps} />
        </Grid>
      </Grid>
    </Box>
  );
}
