/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// TYPES ///
import { ICustomEvent } from "api/types/event";
import { IOrderEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
/// CUSTOM COMPONENTS ///
import CalendarDay from "./day";
/// DATE ///
import { DateTime } from "luxon";
/// VALUES ///
import { TEMPLATE } from "values/event";
import { ORDER_MATERIAL } from "values/event";
import { FABRICATION } from "values/event";
import { INSTALL } from "values/event";

export default function CalendarDays() {
  const {
    custom_events,
    filter,
    nav_date,
    order_events,
    self_order_events
  } = useAppSelector((state) => state.event);

  const [current_order_events, setCurrentEvents] = useState<IOrderEvent[]>([]);
  const [current_self_order_events, setCurrentSelfOrderEvents] = useState<ISelfOrderEvent[]>([]);
  const [current_custom_events, setCurrentCustomEvents] = useState<ICustomEvent[]>([]);
  const nav_height: number = DateTime.fromISO(nav_date).startOf("month").weekday > 4 ? 6 : 5;

  useEffect(() => {
    const filterEventType = (event: IOrderEvent | ISelfOrderEvent): boolean => {
      if (event?.event_type === TEMPLATE && filter.template)
        return false;
      else if (event?.event_type === ORDER_MATERIAL && filter.order_material)
        return false;
      else if (event?.event_type === FABRICATION && filter.fabricate_order)
        return false;
      else if (event?.event_type === INSTALL && filter.install)
        return false;
      return true;
    }

    setCurrentEvents(order_events.filter(event => filterEventType(event)));
  }, [order_events, filter]);

  useEffect(() => {
    const filterEventType = (event: IOrderEvent | ISelfOrderEvent): boolean => {
      if (event?.event_type === TEMPLATE && filter.template)
        return false;
      else if (event?.event_type === ORDER_MATERIAL && filter.order_material)
        return false;
      else if (event?.event_type === FABRICATION && filter.fabricate_order)
        return false;
      else if (event?.event_type === INSTALL && filter.install)
        return false;
      return true;
    }

    setCurrentSelfOrderEvents(self_order_events.filter(event => filterEventType(event)));
  }, [self_order_events, filter]);

  useEffect(() => {
    const filterEventType = (event: IOrderEvent | ISelfOrderEvent): boolean => {
      if (event?.event_type === TEMPLATE && filter.template)
        return false;
      else if (event?.event_type === ORDER_MATERIAL && filter.order_material)
        return false;
      else if (event?.event_type === FABRICATION && filter.fabricate_order)
        return false;
      else if (event?.event_type === INSTALL && filter.install)
        return false;
      return true;
    }

    setCurrentCustomEvents(custom_events.filter(event => filterEventType(event)));
  }, [custom_events, filter]);

  const filterEventDates = (event: IOrderEvent | ICustomEvent | ISelfOrderEvent, index: number): boolean => {
    if (DateTime.fromISO((event?.time)).day === index + 1 - DateTime.fromISO(nav_date).startOf("month").weekday &&
      DateTime.fromISO((event?.time)).month === DateTime.fromISO(nav_date).month &&
      DateTime.fromISO((event?.time)).year === DateTime.fromISO(nav_date).year) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <Grid container>
      {
        Array.from(Array(DateTime.fromISO(nav_date).daysInMonth + DateTime.fromISO(nav_date).startOf("month").weekday)).map((x, index) => {
          return (
            <CalendarDay
              key={`${index}_${DateTime.now().month}_${DateTime.now().year}`}
              nav_height={nav_height}
              index={index}
              day={index + 1 - DateTime.fromISO(nav_date).startOf("month").weekday}
              end={index + 1 - DateTime.fromISO(nav_date).startOf("month").weekday === DateTime.fromISO(nav_date).daysInMonth || index === 6 || index === 13 || index === 20 || index === 27}
              top={index < 7}
              custom_events={current_custom_events.filter(event => filterEventDates(event, index))}
              order_events={current_order_events.filter(event => filterEventDates(event, index))}
              self_order_events={current_self_order_events.filter(event => filterEventDates(event, index))} />
          )
        })
      }
      <Grid item xs={12}>
      </Grid>
    </Grid>
  )
}
