/// EVENTS ///
import { MouseEvent } from "react";
import { KeyboardEvent } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  num_burners: number,
  setNumBurners: (num_burners: number) => void,
  length: number,
  setLength: (length: number) => void,
  length_error: string,
  length_has_error: boolean,
  width: number,
  setWidth: (width: number) => void,
  width_error: string,
  width_has_error: boolean,
  title: string,
  save: () => void,
  cancel: () => void
}

export default function SaveCooktop({
  open,
  setOpen,
  num_burners,
  setNumBurners,
  length,
  setLength,
  length_error,
  length_has_error,
  width,
  setWidth,
  width_error,
  width_has_error,
  save,
  cancel,
  title,
}: Props) {
  const toggleDrawer = () =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(!open);
    };

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      save();
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      cancel();
    }
  }

  return (
    <SwipeableDrawer
      BackdropProps={{ invisible: true }}
      disableAutoFocus
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      onKeyDown={keyDown}
      anchor="right"
      open={open} >
      <Box display="flex" flexDirection="column" justifyContent="center" sx={{ paddingTop: "25px", paddingLeft: "50px", paddingRight: "50px", width: "300px", gap: "10px" }
      }>
        <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
          <IconButton onClick={cancel} onTouchStart={cancel} >
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Box>
        < Typography variant="h4" align="center" sx={{ marginTop: "50px" }}>
          {title}
        </Typography>
        <TextField
          autoFocus
          fullWidth
          select
          value={num_burners ?? 4}
          onChange={(e) => setNumBurners(Number(e.target.value))}
          label="Number of Burners">
          <MenuItem value={4} key={4}> 4 </MenuItem>
          <MenuItem value={5} key={5}> 5 </MenuItem>
          <MenuItem value={6} key={6}> 6 </MenuItem>
        </TextField>
        <TextField
          fullWidth
          label="Length (inches)"
          error={length_has_error}
          helperText={length_error}
          type="number"
          onChange={e => setLength(Number(e.target.value))}
          value={length > 0 ? length : ""} />
        <TextField
          error={width_has_error}
          helperText={width_error}
          label="Width (inches)"
          type="number"
          onChange={e => setWidth(Number(e.target.value))}
          value={width ? width : ""} />
        <Button onClick={save}>
          Save
        </Button>
      </Box>
    </SwipeableDrawer>
  );
}
