/// TYPES ///
import { OrderArea } from "api/types/order";
import { OrderCounter } from "api/types/order";
import { Label } from "api/types/sketch";
import { SelfOrderArea } from "api/types/order";
import { SelfOrderCounter } from "api/types/order";
import { Vector2 } from "api/types/sketch";
/// COMPONENTS ///
import OrderPrintCounters from "./counter";
/// PDF COMPONENTS ///
import { View } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
/// VALUES ///
import { pointInQuad } from "functions/sketch/math";

interface Props {
  index: number,
  area: OrderArea | SelfOrderArea,
  counters: OrderCounter[] | SelfOrderCounter[],
  labels: Label[],
  dimensions: Vector2
}

export default function OrderPrintArea({ index, area, labels, counters, dimensions }: Props) {
  return (
    <View break={index > 0}>
      <View style={{ paddingLeft: "10px" }}>
        <Text style={{ fontFamily: "Montserrat", fontWeight: 400, fontSize: 10.5 }}>
          {area?.material_name ?? ""}
        </Text>
      </View>
      <View style={{ marginTop: "15px", height: dimensions.Y < 650 ? dimensions.Y : "650px", backgroundColor: "#fafafa", width: "100%" }}>
        <OrderPrintCounters counters={counters.filter(c => c.area_uuid === area.uuid)} labels={[]} />
      </View>
    </View>
  )
}
