/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { deleteShopArea } from "api/actions/shop_quote";
import { updateLocalShopCutShapesByArea } from "api/actions/shop_quote";
/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
/// COMPONENTS ///
import DeleteArea from "components/quote/dialog/area/delete";

interface Props {
  area: IShopArea,
  open: boolean,
  setOpen: (open: boolean) => void,
  current_area: number,
  setCurrentArea: (area: number) => void
}

export default function DeleteShopArea({ area, open, setOpen, current_area, setCurrentArea }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.quote);
  const { cut_shapes } = useAppSelector((state) => state.sketch);

  const remove = () => {
    setOpen(false);
    if (current_area === areas?.length - 1) {
      setCurrentArea(0);
    }

    if (areas[0] && areas[0]?.slabs[0]) {
      const update_shapes: IShopCutShape[] = [];
      for (let i = 0; i < cut_shapes.length; i++) {
        if (cut_shapes[i]?.area_uuid === area?.uuid) {
          update_shapes.push({
            ...cut_shapes[i],
            area_uuid: areas[0]?.uuid,
            slab_uuid: areas[0]?.slabs[0]?.uuid
          });
        }
      }
      dispatch(updateLocalShopCutShapesByArea(update_shapes, area?.uuid));
    }
    dispatch(deleteShopArea(area));
  }

  return (
    <DeleteArea
      name={area?.name ?? ""}
      open={open}
      setOpen={setOpen}
      remove={remove} />
  )
}
