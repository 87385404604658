import { Counter } from "api/types/sketch";
import { Cooktop } from "api/types/sketch";
import { Sink } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { Text } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { CornerData } from "api/types/sketch";
import { HighlightData } from "types";
import { DrawOptions } from "api/types/sketch";
import { drawDegrees } from "./degree";
import { getAngle } from "functions/sketch/math";
import { getDistance } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { needsEdge } from "./edge";
import { getLineHighlight } from "./highlight";
import { drawCooktops } from "./cooktop";
import { drawSinks } from "./sink";
import { drawCorner } from "../corner";
import { drawBumps } from "../bumps";
import { checkIndicatorSelected } from "functions/sketch/check/indicator";
import { constructMeasurementIndicator } from "../../create";
import { drawMeasurementIndicator } from "../indicator";
import { measurementText } from "../text";
import { edgeText } from "../text";
import { cornerText } from "../text";
import { drawText } from "../text";
import { dark_grey_color } from "styles/style";
import { grey_color } from "styles/style";
import { getCounterColor } from "functions/sketch/get/color";
import { detailCanvas } from "functions/sketch/context";
import { lineHighlightCanvas } from "functions/sketch/context";
import { highlightCanvas } from "functions/sketch/context";
import { standardCounterCanvas } from "functions/sketch/context";

export function drawCounters(
  context: CanvasRenderingContext2D,
  counters: Counter[],
  line_highlights_path: Path2D,
  zoom: number,
  offset: Vector2,
  mouse: Vector2,
  options: DrawOptions
): HighlightData {
  const highlight_data: HighlightData = { corner: null, cooktop: null, sink: null, text: null, texts: [] };
  const draw_options: DrawOptions = { ...options };

  for (let i = 0; i < counters.length; i++) {
    const highlight_text: Text | null = drawDegrees(context, counters[i], mouse, zoom, offset, options);
    if (highlight_text) {
      highlight_data.text = highlight_text;
    }

    const outline_data: HighlightData = drawCounterOutline(
      context,
      counters[i].corners,
      counters[i].drawing_color,
      line_highlights_path,
      counters[i].uuid,
      zoom,
      offset,
      mouse,
      options
    );

    highlight_data.texts = outline_data.texts;

    if (!highlight_data.corner && outline_data.corner) {
      highlight_data.corner = outline_data.corner;
      draw_options.ends_selectable = false;
      draw_options.lines_selectable = false;
      draw_options.text_selectable = false;
    }
    else if (!highlight_data.text && outline_data.text) {
      highlight_data.text = outline_data.text;
      draw_options.ends_selectable = false;
      draw_options.lines_selectable = false;
      draw_options.text_selectable = false;
    }

    const highlighted_cooktop: Cooktop | null = drawCooktops(
      context,
      counters,
      mouse,
      options?.appliances_selectable,
      zoom,
      offset
    );

    if (highlighted_cooktop && !highlight_data.cooktop) {
      highlight_data.cooktop = highlighted_cooktop;
    }

    const highlighted_sink: Sink | null = drawSinks(
      context,
      counters,
      highlighted_cooktop !== null || !options?.appliances_selectable,
      mouse,
      zoom,
      offset
    );

    if (highlighted_sink && !highlight_data.sink) {
      highlight_data.sink = highlighted_sink;
    }
  }
  return highlight_data;
}

export function drawCounterOutline(
  context: CanvasRenderingContext2D,
  corners: Corner[],
  color: number,
  line_highlights_path: Path2D | null,
  counter_uuid: string,
  zoom: number,
  offset: Vector2,
  mouse_pointer: Vector2,
  options: DrawOptions
): HighlightData {
  const path: Path2D = new Path2D();
  const edge_path: Path2D = new Path2D();
  const measurement_path: Path2D = new Path2D();
  const highlight_data: HighlightData = { text: null, cooktop: null, sink: null, corner: null, texts: [] };

  let first_point: Vector2 | null = null;
  for (let i = 0; i < corners.length; i++) {
    const previous_corner: Corner = corners[i - 1] ? corners[i - 1] : corners[corners.length - 1];
    const current_corner: Corner = corners[i];
    const next_corner: Corner = corners[i + 1] ? corners[i + 1] : corners[0];
    const previous_location: Vector2 = {
      X: Math.round(previous_corner.location.X * zoom) + offset.X,
      Y: Math.round(previous_corner.location.Y * zoom) + offset.Y
    };
    const current_location: Vector2 = {
      X: Math.round(current_corner.location.X * zoom) + offset.X,
      Y: Math.round(current_corner.location.Y * zoom) + offset.Y
    };
    const next_location: Vector2 = {
      X: Math.round(next_corner.location.X * zoom) + offset.X,
      Y: Math.round(next_corner.location.Y * zoom) + offset.Y
    }
    const angle: number = Math.round(getAngle(current_location, next_location) + 90);
    const distance: number = Math.round(getDistance(current_location, next_location));
    const midway_point: Vector2 = getDistantPoint(current_location, angle, distance / 2);
    const is_end: boolean = i === corners.length - 1 || i === Math.floor((corners.length - 1) / 2);
    const current_needs_edge: boolean = needsEdge(current_corner.edge_type);

    // GET TEXT //
    if (options.show_measurements) {
      const measurement_distance: number = getDistance(current_corner.location, next_corner.location);
      const text: Text = measurementText(
        midway_point,
        distance,
        angle,
        measurement_distance,
        current_corner.uuid,
        counter_uuid,
        i > Math.floor((corners.length - 1) / 2) ? next_corner.point_uuid : current_corner.point_uuid,
        is_end ? false : true,
        i > Math.floor((corners.length - 1) / 2)
      );
      highlight_data.texts.push(text);
      const selected: boolean = checkIndicatorSelected(
        text.location,
        mouse_pointer,
        options.text_selectable && !highlight_data.corner
      );
      drawText(context, dark_grey_color, text, selected);

      if (selected) {
        highlight_data.text = text;
      }
      if (text.real_value > 16) {
        const path: Path2D = constructMeasurementIndicator(
          midway_point,
          text.real_value,
          text.angle
        );
        if (selected) {
          drawMeasurementIndicator(context, grey_color, path, selected);
        }
        else {
          measurement_path.addPath(path);
        }
      }
    }

    if (options.show_edges) {
      const text: Text = edgeText(
        midway_point,
        distance,
        angle,
        current_corner.edge_type,
        current_corner.uuid,
        counter_uuid,
        current_corner.point_uuid,
        current_corner.splash_height,
        current_corner.finished_edge_type,
        options.show_edges_inside
      );
      highlight_data.texts.push(text);
      const selected: boolean = checkIndicatorSelected(
        text.location,
        mouse_pointer,
        options.text_selectable && !highlight_data.corner && !highlight_data.text
      );
      drawText(context, dark_grey_color, text, selected);
      if (selected) {
        highlight_data.text = text;
      }
    }

    if (options.show_corners) {
      const text: Text = cornerText(
        previous_location,
        current_location,
        next_location,
        current_corner.corner_type,
        current_corner.uuid,
        counter_uuid,
        current_corner.point_uuid,
        current_corner.corner_length,
        current_corner.corner_depth,
        current_corner.corner_radius
      );
      highlight_data.texts.push(text);
      const selected: boolean = checkIndicatorSelected(
        text.location,
        mouse_pointer,
        options.text_selectable && !highlight_data.corner && !highlight_data.text
      );
      drawText(context, dark_grey_color, text, selected);
      if (selected) {
        highlight_data.text = text;
      }
    }

    const highlighted: boolean = getLineHighlight(
      line_highlights_path,
      current_location,
      next_location,
      angle - 90,
      is_end,
      mouse_pointer,
      options
    );

    if (highlighted && !highlight_data.text) {
      highlight_data.corner = current_corner;
      const highlight_path: Path2D = new Path2D();
      highlight_path.moveTo(current_location.X, current_location.Y);
      highlight_path.lineTo(next_location.X, next_location.Y);
      lineHighlightCanvas(context);
      context.stroke(highlight_path);
    }

    // DRAW CORNER //
    const corner_data: CornerData | null = drawCorner(
      i,
      path,
      edge_path,
      current_corner.corner_type,
      previous_location,
      current_location,
      next_location,
      previous_corner.edge_type,
      zoom,
      current_corner.corner_radius,
      current_corner.corner_length,
      current_corner.corner_depth
    );

    if (corner_data) {
      if (corner_data.first_point) {
        first_point = corner_data.first_point;
      }
    }

    // DRAW BUMP //
    drawBumps(
      path,
      edge_path,
      current_corner.bumps,
      corner_data ? corner_data.last_point : current_location,
      next_location,
      angle - 90,
      zoom,
      current_needs_edge
    );
  }
  if (first_point) {
    if (!needsEdge(corners[corners.length - 1].edge_type)) {
      path.lineTo(first_point.X, first_point.Y);
    }
    else {
      edge_path.lineTo(first_point.X, first_point.Y);
    }
  }

  standardCounterCanvas(context, getCounterColor(color));
  context.stroke(path);
  highlightCanvas(context);
  context.stroke(edge_path);
  detailCanvas(context);
  drawMeasurementIndicator(context, grey_color, measurement_path, false);

  return highlight_data;
}
