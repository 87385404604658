/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/order";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/order";
/// TYPES ///
import { IGetOrderArgs } from "api/types/order/order";

export const filterWOs = (filter: IGetOrderArgs) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FILTER_ORDERS,
      filter
    });
  }
}
