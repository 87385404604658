/// EVENTS ///
import { KeyboardEvent } from "react";
import { MouseEvent } from "react";
/// TYPEs ///
import { Area } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";
/// MUI ///
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// ICONS ///
import CloseIcon from "@mui/icons-material/Close";
/// STYLES ///
import { drawerGridContainer } from "styles/drawer";
import { drawerTitleStyle } from "styles/drawer";
import { drawerGridSaveButtonAreaStyle } from "styles/drawer";
import { drawerIconBarStyle } from "styles/drawer";

interface Props {
  open: boolean,
  area_id: number,
  areas: Area[] | IShopArea[],
  error: boolean,
  update: (new_area: number) => void,
  close: () => void,
  save: () => void
}

export default function UpdateArea({ open, area_id, areas, error, update, close, save }: Props) {
  const toggleDrawer = () =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      close();
    };

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      save();
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      close();
    }
  }

  return (
    <SwipeableDrawer
      BackdropProps={{ invisible: true }}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      onKeyDown={keyDown}
      anchor="right"
      open={open}>
      <Grid container sx={drawerGridContainer}>
        <Grid item xs={12} sx={drawerIconBarStyle} justifyContent="end">
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={drawerTitleStyle}>
          <Typography variant="h4" align="center">
            Move Counter
          </Typography>
          <Typography variant="body2" align="center">
            Area
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <TextField
              value={area_id}
              select
              error={error}
              helperText={error ? "Must choose an area before saving" : ""}
              onChange={e => update(Number(e.target.value))}
              label="Area">
              <MenuItem key={0} value={0} disabled></MenuItem>
              {
                areas.map((area: Area | IShopArea) => {
                  return (
                    <MenuItem key={area?.id ?? 0} value={area?.id ?? 0}>
                      {area?.name ?? ""}
                    </MenuItem>
                  )
                })
              }
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={drawerGridSaveButtonAreaStyle} justifyContent="center">
          <Button onClick={save}>
            Save
          </Button>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
}
