/// TYPES ///
import { CornerError } from "api/types/quote/error";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateLocalCounter } from "api/actions/sketch/counter";
import { restore } from "api/actions/sketch/history";
/// TYPES ///
import { Counter } from "api/types/sketch";
import { Slab } from "api/types/quote";
import { Corner } from "api/types/sketch";
/// COMPONENTS ///
import UpdateInnerCorner from "components/quote/drawer/update_inner_corner";
/// FUNCTIONS ///
import { getFirstSlabInAreas } from "functions/sketch/get/slab";
import { handleUpdateCounter } from "handler/counter/update";
import { updateCorner } from "functions/sketch";
/// VALUES ///
import { DIAGONAL } from "values/quote";
import { INNER_RADIUS } from "values/quote";
import { RECESSED_DIAGONAL } from "values/quote";
import { STANDARD } from "values/quote";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  original_corner: Corner | null,
  selected_counter: Counter | null
}

export default function UpdateStoreInnerCorner({ open, setOpen, original_corner, selected_counter }: Props) {
  const dispatch = useAppDispatch();
  const { cut_shape_history } = useAppSelector((state) => state.history);
  const { counter_history } = useAppSelector((state) => state.history);
  const { history_position } = useAppSelector((state) => state.history);
  const { areas } = useAppSelector((state) => state.quote);
  const { counters } = useAppSelector((state) => state.sketch);
  const { cut_shapes } = useAppSelector((state) => state.sketch);
  const [error, setError] = useState<CornerError>({});
  const [corner, setCorner] = useState<Corner>({});

  useEffect(() => {
    if (open && original_corner) {
      setCorner(original_corner);
    }
  }, [open, original_corner]);


  const save = () => {
    let errors: CornerError = {};

    if (corner?.corner_type === INNER_RADIUS && corner?.corner_radius <= 0) {
      errors.radius_has_error = true;
      errors.radius_error = "Radius must be greater than 0";
    }

    if (corner?.corner_type === RECESSED_DIAGONAL || corner?.corner_type === DIAGONAL) {
      if (corner?.corner_length <= 0) {
        errors.length_error = "Length must be greater than 0";
        errors.length_has_error = true;
      }
    }

    if (corner?.corner_type === RECESSED_DIAGONAL && corner?.corner_depth <= 0) {
      errors.depth_error = "Depth must be greater than 0";
      errors.depth_has_error = true;
    }

    setError(errors);

    if (errors.depth_has_error || errors.length_has_error || errors.radius_has_error) {
      return;
    }

    if (selected_counter) {
      const counter: Counter = updateCorner(selected_counter, corner);
      const slab: Slab | null = getFirstSlabInAreas(areas, counter?.area_uuid);
      handleUpdateCounter(
        dispatch,
        counter,
        counters,
        cut_shapes,
        slab?.uuid,
        `Change ${corner.corner_type.toUpperCase()} Corner`
      );
    }
  }

  const cancel = () => {
    dispatch(restore(counter_history[history_position], cut_shape_history[history_position]));
    setError({});
    setOpen(false);
  }

  const update = (corner: Corner) => {
    const counter: Counter = updateCorner(selected_counter, corner);
    if (counter) {
      dispatch(updateLocalCounter(counter));
    }
    setCorner(corner);
  }

  return (
    <UpdateInnerCorner
      open={open}
      setOpen={setOpen}
      corner_type={corner?.corner_type ?? STANDARD}
      setCornerType={corner_type => update({ ...corner, corner_type })}
      corner_radius={corner?.corner_radius ?? 0}
      setCornerRadius={corner_radius => update({ ...corner, corner_radius })}
      radius_error={error?.radius_error ?? ""}
      radius_has_error={error?.radius_has_error ?? false}
      corner_length={corner?.corner_length ?? 0}
      setCornerLength={corner_length => update({ ...corner, corner_length })}
      length_error={error?.length_error ?? ""}
      length_has_error={error?.length_has_error ?? false}
      corner_depth={corner?.corner_depth ?? 0}
      setCornerDepth={corner_depth => update({ ...corner, corner_depth })}
      depth_error={error?.depth_error ?? ""}
      depth_has_error={error?.depth_has_error ?? false}
      save={save}
      cancel={cancel} />
  );
}
