/// HOOKS ///
import { useEffect } from "react";
import { useState } from "react";
/// MUI COMPONENTS ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  title: string,
  amount: number
  save: (amount: number) => void,
  open: boolean,
  setOpen: (open: boolean) => void,
}

export default function RemovalDialog({ title, amount, save, open, setOpen }: Props) {
  const [current_amt, setCurrentAmt] = useState<number>(0);

  useEffect(() => {
    setCurrentAmt(amount);
  }, [amount]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <TextField
          sx={{ marginTop: 2 }}
          fullWidth
          type="number"
          size="small"
          label="SQ FT"
          value={current_amt && current_amt > 0 ? current_amt : ""}
          onChange={e => setCurrentAmt(Number(e.target.value))} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={() => save(current_amt)}>Apply</Button>
      </DialogActions>
    </Dialog>
  )
}
