import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/event";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from "api/action_types/event";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { IGetEventArgs } from "api/types/event";
import { getEventsUrl } from "../../endpoint";
import { order_event_url } from "../../endpoint";
import { url_prefix } from "settings";

export const getOrderEvents = (args: IGetEventArgs) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.EVENT_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = getEventsUrl(`${url_prefix}${order_event_url}/?`, args);

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_ORDER_EVENTS,
        order_events: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.EVENT_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const getOrderEvent = (id: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.EVENT_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${order_event_url}/${id}/`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_ORDER_EVENT,
        order_event: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.EVENT_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
