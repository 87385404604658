import { OrderArea } from "api/types/order";

export enum ActionType {
  GET_WO_AREAS_SUCCESS = "GET_WO_AREAS_SUCCESS"
}

interface IGetWOAreasSuccess {
  type: ActionType.GET_WO_AREAS_SUCCESS,
  payload: OrderArea[]
}

export type Action = IGetWOAreasSuccess;
