/// REACT ///
import { CSSProperties } from "react";
/// COLORS ///
import { white_color } from "./style";

export const breadcrumb_bar_style: CSSProperties = {
  display: "flex",
  backgroundColor: white_color,
  flexDirection: "row",
  overflow: "hidden",
  position: "fixed",
  left: 50,
  right: 200,
  bottom: 0,
  paddingBottom: "20px",
  paddingTop: "10px",
  paddingLeft: "58px"
}
