import { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { text_primary } from "styles/style";

interface Props {
  well_color?: string,
  signature?: string | null,
  name?: string | null,
  date?: string | null
}

export default function SignatureArea({ well_color = "rgba(0, 0, 0, 0.035)", signature, name, date }: Props) {
  return (
    <Fragment>
      <Grid item xs={4} sx={{ paddingTop: "25px", marginTop: "25px" }}>
        <Typography variant="body2">
          Accepted By:
        </Typography>
      </Grid>
      <Grid item xs={4} sx={{ paddingTop: "25px", marginTop: "25px" }}>
        <Typography variant="body2">
          Signature:
        </Typography>
      </Grid>
      <Grid item xs={4} sx={{ paddingTop: "25px", marginTop: "25px" }}>
        <Typography variant="body2">
          Date:
        </Typography>
      </Grid>
      <Grid item xs={4} sx={{ paddingRight: 1, paddingBottom: "25px" }}>
        <Box sx={{ height: 100, width: '100%', backgroundColor: well_color, borderBottom: `1px solid ${text_primary}` }}>
          {
            signature && name ?
              <Typography variant="body1" sx={{ fontFamily: "Montserrat", paddingTop: 2, paddingLeft: 1 }}>{name}</Typography> :
              null
          }
        </Box>
      </Grid>
      <Grid item xs={4} sx={{ paddingRight: 1, paddingBottom: "25px" }}>
        <Box sx={{ height: 100, width: '100%', backgroundColor: well_color, borderBottom: `1px solid ${text_primary}` }}>
          {
            signature ?
              <img src={signature} style={{ width: "90%", height: 95 }} alt="Signature" /> :
              null
          }
        </Box>
      </Grid>
      <Grid item xs={4} sx={{ paddingBotto: "25px" }}>
        <Box sx={{ height: 100, width: '100%', backgroundColor: well_color, borderBottom: `1px solid ${text_primary}` }}>
          {
            signature && date ?
              <Typography variant="body1" sx={{ fontFamily: "Montserrat", paddingTop: 2, paddingLeft: 1 }}>{date}</Typography> :
              null
          }
        </Box>
      </Grid>
    </Fragment>
  )
}
