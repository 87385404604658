
export enum ActionType {
  QUOTE_LOADING = "QUOTE_LOADING",
  QUOTE_UPDATING = "QUOTE_UPDATING",
  QUOTE_ACTION_SUCCESS = "QUOTE_ACTION_SUCCESS",
  AREAS_LOADING = "AREAS_LOADING"
}

interface IQuoteLoading {
  type: ActionType.QUOTE_LOADING
}

interface IAreasLoading {
  type: ActionType.AREAS_LOADING
}

interface IQuoteUpdating {
  type: ActionType.QUOTE_UPDATING
}

interface IQuoteActionSuccess {
  type: ActionType.QUOTE_ACTION_SUCCESS
}

export type Action = IQuoteLoading | IAreasLoading | IQuoteUpdating | IQuoteActionSuccess;
