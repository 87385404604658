export enum ActionType {
  CLEAR_ORDER = "CLEAR_ORDER",
  CLEAR_ORDERS = "CLEAR_ORDERS"
}

interface IClearOrder {
  type: ActionType.CLEAR_ORDER
}

interface IClearOrders {
  type: ActionType.CLEAR_ORDERS
}

export type Action = IClearOrder | IClearOrders;
