/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// FUNCTIONS ///
import { handleUpdateShopCounter } from "handler/shop_counter/update";
import { updateShopAngle } from "functions/sketch/update/shop_angle";
import { getFirstShopSlabInAreas } from "functions/sketch/get/shop_slab";
/// ACTIONS ///
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { shopRestore } from "api/actions/shop_quote/history/local";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopSlab } from "api/types/shop_quote/slab";
/// COMPONENTS ///
import UpdateDegrees from "components/quote/drawer/update_degrees";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  original_value: number | null,
  point_uuid: string,
  selected_counter: IShopCounter | null
}

export default function UpdateShopDegrees({ open, setOpen, original_value, point_uuid, selected_counter }: Props) {
  const dispatch = useAppDispatch();
  const { cut_shape_history } = useAppSelector((state) => state.history);
  const { counter_history } = useAppSelector((state) => state.history);
  const { history_position } = useAppSelector((state) => state.history);
  const { areas } = useAppSelector((state) => state.shop_quote);
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { cut_shapes } = useAppSelector((state) => state.shop_quote);
  const [degrees, setDegrees] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const update = (new_degrees: number) => {
    let adjusted_degrees: number = 0;

    if (selected_counter) {
      if (new_degrees < 22.5) {
        adjusted_degrees = 22.5;
        setError("Must be at least 22.5 degrees");
      }
      else if (new_degrees > 135) {
        adjusted_degrees = 135;
        setError("Must be less than 135 degrees");
      }
      else {
        adjusted_degrees = new_degrees;
        setError("");
      }

      setDegrees(new_degrees);

      const update_counter: IShopCounter | null = updateShopAngle(selected_counter, point_uuid, adjusted_degrees);
      if (update_counter) {
        dispatch(updateLocalShopCounter(update_counter));
      }
    }
    else {
      setError("An error has occurred, please refresh the page and try again.");
    }
  }

  useEffect(() => {
    if (open && original_value) {
      setDegrees(original_value);
    }
  }, [open]);

  const save = () => {
    if (selected_counter) {
      if (degrees < 22.5) {
        setError("Must be at least 22.5 degrees");
        return;
      }
      else if (degrees > 135) {
        setError("Must be less than 135 degrees");
        return;
      }

      const update_counter: IShopCounter = updateShopAngle(selected_counter, point_uuid, degrees);

      const slab: IShopSlab | null = getFirstShopSlabInAreas(areas, update_counter.area_uuid);
      handleUpdateShopCounter(
        dispatch,
        update_counter,
        counters,
        cut_shapes,
        slab.uuid,
        "Change Counter Angle"
      );
    }
    else {
      setError("An error has occurred, please refresh the page and try again.");
    }
    setOpen(false);
  }

  const cancel = () => {
    dispatch(shopRestore(counter_history[history_position] as IShopCounter[], cut_shape_history[history_position] as IShopCutShape[]));
    setOpen(false);
  }



  return (
    <UpdateDegrees
      open={open}
      setOpen={setOpen}
      degrees={degrees}
      setDegrees={update}
      error={error}
      save={save}
      cancel={cancel} />
  );
}
