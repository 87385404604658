import { CornerData } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";

export function drawPrintCutStandardCorner(
    painter: any,
    i: number,
    current_location: Vector2
): CornerData {
    let first_point: Vector2 | null = null;

    if(i === 0){
        painter.moveTo(current_location.X, current_location.Y);
        first_point = current_location;
    }
    else{
        painter.lineTo(current_location.X, current_location.Y);
    }

    return {
        first_point: first_point,
        last_point: current_location
    }
}