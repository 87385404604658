import { OrderSlab } from "api/types/order";

export enum ActionType {
  SLABS_LOADING = "ORDER_SLABS_LOADING",
  GET_ORDER_SLABS_SUCCESS = "GET_ORDER_SLABS_SUCCESS"
}

interface ISlabsLoading {
  type: ActionType.SLABS_LOADING
}


interface IGetOrderSlabsSuccess {
  type: ActionType.GET_ORDER_SLABS_SUCCESS,
  payload: OrderSlab[]
}

export type Action = ISlabsLoading | IGetOrderSlabsSuccess;
