/// TYPES ///
import { Counter } from "api/types/sketch";
import { Point } from 'api/types/sketch';

export function updateWidth(
  counter: Counter,
  point_uuid: string,
  new_width: number
): Counter {
  let points: Point[] = [...counter.points];
  for (let j = 0; j < points.length; j++) {
    if (point_uuid === points[j].uuid) {
      const point: Point = { ...points[j] };
      point.width = new_width * 4;
      points[j] = point;
      if (j === points.length - 1 && points[j - 1]) {
        const second_last_point: Point = { ...points[j - 1] };
        second_last_point.width = new_width * 4;
        points[j - 1] = second_last_point;
      }
      if (j === 0 && points.length === 2) {
        const next_point: Point = { ...points[j + 1] };
        next_point.width = new_width * 4;
        points[j + 1] = next_point;
      }
      break;
    }
  }
  return { ...counter, points: points };
}
