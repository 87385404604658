import { StrictMode } from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { init } from "@sentry/react";
import { BrowserTracing } from "@sentry/react";
import { Replay } from "@sentry/react";
import { SENTRY_DSN } from "settings";
import { SENTRY_ENVIRONMENT } from "settings";
import { store } from "api/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  enabled: SENTRY_ENVIRONMENT !== "local",
  integrations: [
    new BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);

reportWebVitals();
