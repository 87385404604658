/// TYPES ///
import { Counter } from "api/types/sketch";
import { Cooktop } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { Sink } from "api/types/sketch";
import { Point } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { getDistance } from "../math";
import { getDistantPoint } from "../math";
import { getAngle } from "../math";

export function moveCounter(selected_counter: Counter, lift_point: Vector2, mouse: Vector2): Counter | null {
  const distance: number = getDistance(lift_point, mouse);
  const angle: number = getAngle(lift_point, mouse) + 90;

  const counter: Counter = { ...selected_counter };
  const points: Point[] = [];
  const corners: Corner[] = [];

  for (let i = 0; i < counter.points.length; i++) {
    const point: Point = { ...counter.points[i] };
    point.location = getDistantPoint(counter.points[i].location, angle, distance);
    points.push(point);
  }

  for (let i = 0; i < counter.corners.length; i++) {
    const corner: Corner = { ...counter.corners[i] };
    corner.location = getDistantPoint(counter.corners[i].location, angle, distance);
    if (counter.corners[i].location.X > 0 && counter.corners[i].location.Y > 0 && (corner.location.X < 0 || corner.location.Y < 0)) {
      return null;
    }
    corners.push(corner);
  }

  const cooktops: Cooktop[] = [];
  const sinks: Sink[] = [];

  for (let i = 0; i < counter.cooktops.length; i++) {
    const cooktop: Cooktop = { ...counter.cooktops[i] };
    cooktop.location = getDistantPoint(cooktop.location, angle, distance);
    cooktops.push(cooktop);
  }

  for (let i = 0; i < counter.sinks.length; i++) {
    const sink: Sink = { ...counter.sinks[i] };
    sink.location = getDistantPoint(sink.location, angle, distance);
    sinks.push(sink);
  }

  return { ...counter, points: points, corners: corners, cooktops: cooktops, sinks: sinks };
}
