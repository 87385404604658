/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateFabricatorColor } from "api/actions/material";
/// TYPES ///
import { FabricatorColor } from "api/types/fabricator";
/// MUI COMPONENTS ///
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// FM ///
import { motion } from "framer-motion";
/// LODASH ///
import { debounce } from "lodash";
/// ICONS ///
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";
/// VALUES ///
import { row_style } from "styles/universal/row";
import { slider_marks } from "values/price";

interface Props {
  index: number,
  color: FabricatorColor,
  updateLoading: (loading: boolean, id: number) => void,
}

export default function ShopColorItem({ index, color, updateLoading }: Props) {
  const dispatch = useAppDispatch();
  const [lock, setLock] = useState<boolean>(!color?.price_override ?? true);
  const [weight, setWeight] = useState<number>(color?.weight ?? 0);
  const [id, setId] = useState<number>(color?.id ?? 0);

  useEffect(() => {
    setWeight(color?.weight ?? 0);
    setLock(!color?.price_override ?? true);
    setId(color?.id ?? 0);
  }, [color.id]);

  const toggleLock = () => {
    const locked: boolean = !lock;
    setLock(locked);
    if (locked) {
      if (color?.base_weight) {
        changePrice(color.base_weight, false);
        updateLoading(true, id);
      }
    }
    else {
      changePrice(weight, true);
      updateLoading(true, id);
    }
  }
  const update = useCallback(debounce(function(weight: number, price_override: boolean, id: number) {
    dispatch(updateFabricatorColor({ id: id, weight: weight, price_override: price_override }, id));
    updateLoading(false, id);
  }, 1000), []);

  const changePrice = (weight: number, price_override: boolean) => {
    setWeight(weight);
    update(weight, price_override, id);
    updateLoading(true, id);
  }

  return (
    <Grid item container xs={12} sx={row_style}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.1 * index / 5 } }} style={{ width: "100%" }}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography variant="body2">
              {color?.name ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <Tooltip title={lock ? "Override Material Price" : "Lock to Material Price"}>
                <IconButton onClick={toggleLock}>
                  <FontAwesomeIcon icon={!lock ? faUnlock : faLock} size="2xs" />
                </IconButton>
              </Tooltip>
              <Typography variant="body2">
                ${(weight / 100 * (color?.base_price ?? 0) + (color?.base_price ?? 0)).toFixed(2)} SQ FT
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            {
              !lock ?
                <Slider
                  marks={slider_marks}
                  valueLabelDisplay="auto"
                  size="small"
                  value={weight}
                  onChange={(e, v) => changePrice(v as number, true)}
                  min={-30}
                  max={30} /> :
                null
            }
          </Grid>
        </Grid>
      </motion.div>
    </Grid>
  )
}

