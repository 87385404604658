import { Corner } from "api/types/sketch";
import { Point } from "api/types/sketch";
import { Counter } from "api/types/sketch";
import { Seam } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { CornerData } from "api/types/sketch";
import { needsEdge } from "./counter";
import { drawCorner } from "./corner";
import { getAngle } from "../math";
import { isPointInRectangle } from "../math";
import { getLateralLeftVertex } from "../math";
import { getLateralRightVertex } from "../math";
import { pointInQuad } from "../math";
import { drawCornerSeamPreview } from "./seam";
import { seamPreview } from "./seam";
import { drawCornerSeam } from "./seam";
import { drawSeam } from "./seam";
import { drawBumps } from "./bumps";

export function drawSeamLines(
  counter: Counter,
  corners: Corner[],
  path: Path2D,
  edge_path: Path2D,
  seam_path: Path2D,
  seam_highlight: Path2D,
  center_of_counter: Vector2,
  center_screen: Vector2,
  zoom: number,
  mouse: Vector2
): Seam | null {
  const offset: Vector2 = { X: center_screen.X - center_of_counter.X, Y: center_screen.Y - center_of_counter.Y };
  const vertices: Vector2[] = [];
  let first_point: Vector2 | null = null;
  for (let i = 0; i < corners.length; i++) {
    const previous_corner: Corner = corners[i - 1] ? corners[i - 1] : corners[corners.length - 1];
    const current_corner: Corner = corners[i];
    const next_corner: Corner = corners[i + 1] ? corners[i + 1] : corners[0];
    const angle: number = getAngle(current_corner.location, next_corner.location);
    const needs_edge: boolean = needsEdge(previous_corner.edge_type);
    const previous_location: Vector2 = {
      X: Math.round(previous_corner.location.X * zoom) + offset.X,
      Y: Math.round(previous_corner.location.Y * zoom) + offset.Y
    };
    const current_location: Vector2 = {
      X: Math.round(current_corner.location.X * zoom) + offset.X,
      Y: Math.round(current_corner.location.Y * zoom) + offset.Y
    };
    const next_location: Vector2 = {
      X: Math.round(next_corner.location.X * zoom) + offset.X,
      Y: Math.round(next_corner.location.Y * zoom) + offset.Y
    }
    vertices.push(current_location);

    // DRAW CORNER //  
    const corner_data: CornerData | null = drawCorner(
      i,
      path,
      edge_path,
      current_corner.corner_type,
      previous_location,
      current_location,
      next_location,
      previous_corner.edge_type,
      zoom,
      current_corner.corner_radius,
      current_corner.corner_length,
      current_corner.corner_depth
    );

    if (corner_data) {
      if (corner_data.first_point) {
        first_point = corner_data.first_point;
      }
    }

    // DRAW BUMP //
    drawBumps(
      path,
      edge_path,
      current_corner.bumps,
      corner_data ? corner_data.last_point : current_location,
      next_location,
      angle,
      zoom,
      needs_edge
    );
  }

  if (first_point) {
    if (!needsEdge(corners[corners.length - 1].edge_type)) {
      path.lineTo(first_point.X, first_point.Y);
    }
    else {
      edge_path.lineTo(first_point.X, first_point.Y);
    }
  }

  // Preview Seam
  let point_found: boolean = false;
  let seam_found: boolean = false;
  let point: Point | null = null;
  let location: Vector2 | null = null;
  let corner_seam_preview: Seam | null = null;
  let seam_preview: Seam | null = null;
  let is_on_corner: boolean = false;

  for (let i = 0; i < counter.points.length; i++) {
    const current_point: Point = counter.points[i];
    const current_location: Vector2 = {
      X: Math.round(current_point.location.X * zoom) + offset.X,
      Y: Math.round(current_point.location.Y * zoom) + offset.Y
    };
    const previous_point: Point | null = counter.points[i - 1] ? counter.points[i - 1] : null;
    const next_point: Point | null = counter.points[i + 1] ? counter.points[i + 1] : null;
    const next_location: Vector2 | null = next_point ?
      {
        X: Math.round(next_point.location.X * zoom) + offset.X,
        Y: Math.round(next_point.location.Y * zoom) + offset.Y
      } :
      null;

    if (next_point && !corner_seam_preview && !seam_found) {
      if (previous_point) {
        is_on_corner = isPointInRectangle(
          current_location,
          previous_point.angle,
          previous_point.width * zoom,
          current_point.width * zoom,
          mouse
        );

        if (is_on_corner && next_location) {
          const previous_location: Vector2 = {
            X: Math.round(previous_point.location.X * zoom) + offset.X,
            Y: Math.round(previous_point.location.Y * zoom) + offset.Y
          };
          corner_seam_preview = drawCornerSeamPreview(
            seam_path,
            previous_point,
            current_point,
            previous_location,
            next_location,
            zoom
          );
        }
      }

      if (!is_on_corner && next_location && point_found === false) {
        const bottom_left: Vector2 = getLateralLeftVertex(current_location, current_point.angle, current_point.width * zoom);
        const bottom_right: Vector2 = getLateralRightVertex(current_location, current_point.angle, current_point.width * zoom);
        const top_left: Vector2 = getLateralLeftVertex(next_location, current_point.angle, current_point.width * zoom);
        const top_right: Vector2 = getLateralRightVertex(next_location, current_point.angle, current_point.width * zoom);

        const inside: boolean = pointInQuad(
          bottom_left,
          top_left,
          top_right,
          bottom_right,
          mouse
        );

        if (inside) {
          point_found = true;
          point = current_point;
          location = current_location;
          if (i === counter.points.length - 2) {
            seam_preview = seamPreview(
              seam_path,
              point,
              location,
              mouse,
              zoom
            )
          }
        }
      }
      else if (!is_on_corner && point_found && point && location) {
        seam_found = true;
        seam_preview = seamPreview(
          seam_path,
          point,
          location,
          mouse,
          zoom
        )
      }
    }

    for (let j = 0; j < counter.points[i].seams.length; j++) {
      const seam: Seam = counter.points[i].seams[j];
      if (seam.is_on_corner && previous_point && next_point) {
        const previous_location: Vector2 = {
          X: Math.round(previous_point.location.X * zoom) + offset.X,
          Y: Math.round(previous_point.location.Y * zoom) + offset.Y
        };
        const next_location: Vector2 = {
          X: Math.round(next_point.location.X * zoom) + offset.X,
          Y: Math.round(next_point.location.Y * zoom) + offset.Y
        };
        drawCornerSeam(seam_path, previous_point, current_point, previous_location, next_location, zoom);
      }
      else {
        drawSeam(seam_path, current_location, seam.distance_from_point, seam.point_data.angle, seam.point_data.width * zoom);
      }
    }
  }
  if (corner_seam_preview) {
    return corner_seam_preview;
  }
  return seam_preview;
}
