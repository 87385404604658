import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/billing";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from "api/action_types/billing";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { url_prefix } from "settings";
import { Session } from "api/types/billing";

const billing_session_url = "billing_session";

export const createStripeSession = (customer_id: string, price_id: string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.SESSION_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${billing_session_url}/?customer_id=${customer_id}&price_id=${price_id}&`;

    try {
      const { data } = await axios.get(url, config);
      const session: Session = {
        session_id: data.session_id,
        session_url: data.portal_url
      }
      dispatch({
        type: ActionType.CREATE_STRIPE_SESSION_SUCCESS,
        payload: session
      });
      dispatch({
        type: AuthActionType.SET_PAYMENT_INITIALIZED
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SESSION_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
