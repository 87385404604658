/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";

export const shopRestore = (counters: IShopCounter[], cut_shapes: IShopCutShape[]) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SHOP_RESTORE,
      counters,
      cut_shapes
    });
  }
}
