/// TYPES ///
import { MotionStyle } from "framer-motion";
import { TargetAndTransition } from "framer-motion";
/// COLORS ///
import { dark_grey_color, primary_color } from "./style";

export const mat_tile_style: MotionStyle = {
  color: dark_grey_color,
  width: "100%",
  border: "none",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: "var(--mui-palette-background-paper)"
}

export const mat_tile_anim: TargetAndTransition = {
  color: primary_color,
  transition: {
    ease: "easeIn",
    duration: 0.2,
    type: "tween"
  }
}
