/// HOOKS ///
import { useAppDispatch } from 'api/hooks/apiHook';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
/// ACTIONS ///
import { resetPassword } from "api/actions/authentication";
/// MUI COMPONENTS ///
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
/// ICONS ///
import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
/// FM ///
import { motion } from "framer-motion";
/// VALIDATION ///
import { validateEmail } from "functions/value";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { sm_txt_btn_style } from "styles/universal/button";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { login_url } from "components/navigation/endpoints";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [complete, setComplete] = useState<boolean>(false);

  const handleEmail = (input: string) => {
    setEmail(input);
    setError("");
  }

  const send = () => {
    if (!validateEmail(email)) {
      setError("Enter a valid email");
      return;
    }

    dispatch(resetPassword(email));
    setComplete(true);
  }

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      send();
    }
  }

  if (complete) {
    return (
      <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
        <Typography variant="h6">
          Request Sent
        </Typography>
        <Typography variant="body2">
          If the email is in our records you will receive an email shortly with a link to reset your password. If you do not see the email, please check your junk or spam folder.
        </Typography>
      </motion.div>
    )
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style} onKeyDown={keyDown}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
        <Typography variant="h6" sx={{ marginLeft: "5px", marginBottom: "5px" }}>
          Forgot Password
        </Typography>
        <TextField
          type="email"
          error={error !== ""}
          helperText={error}
          onChange={(e) => handleEmail(e.target.value)}
          label="Email" />
        <Stack direction="row" justifyContent="space-between" sx={{ width: "265px", paddingLeft: "3px" }}>
          <Button sx={sm_txt_btn_style} onClick={() => navigate(`/${login_url}`)}>
            Login
          </Button>
          <BaseButton text="Reset Password" icon={faShareFromSquare} clickEvent={send} />
        </Stack>
      </Box>
    </motion.div>
  );
}
