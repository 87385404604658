/// TYPES ///
import { Area } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";
import { SlabRect } from "api/types/sketch";
import { Text } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { constructSlabRect } from "functions/sketch/create";
import { measurementText } from "../text";
import { constructMeasurementIndicator } from "functions/sketch/create";
import { getDistantPoint } from "../../math";
/// VALUES ///
import { inch_to_pixel } from "values/values";
import { SLAB_ZOOM } from "values/values";

function drawSlab(path: Path2D, i: number, id: string, height: number, width: number): SlabRect {
  const slab_rect: SlabRect = constructSlabRect(id, i, width, height);
  path.moveTo(slab_rect.A.X, slab_rect.A.Y);
  path.lineTo(slab_rect.B.X, slab_rect.B.Y);
  path.lineTo(slab_rect.C.X, slab_rect.C.Y);
  path.lineTo(slab_rect.D.X, slab_rect.D.Y);
  path.lineTo(slab_rect.A.X, slab_rect.A.Y);

  return slab_rect;
}

export function drawSlabs(
  area: Area | IShopArea,
  slab_path: Path2D,
  indicator_path: Path2D | null,
  texts: Text[]
) {
  const slab_rects: SlabRect[] = [];
  const length: number = (area?.material_length ?? 60) * SLAB_ZOOM;
  const width: number = (area?.material_width ?? 163) * SLAB_ZOOM;
  for (let i = 0; i < area?.slabs?.length; i++) {
    const slab_rect: SlabRect = drawSlab(slab_path, i, area?.slabs[i]?.uuid ?? "", length, width);
    slab_rects.push(slab_rect);
    if (indicator_path && area?.material_width && area?.material_length) {
      const width_midway_point: Vector2 = getDistantPoint(slab_rect.A, 90, width * 2);
      const width_text: Text = measurementText(width_midway_point, width * 4, 90, (area?.material_width ?? 163) * inch_to_pixel, "", "", "", false, false);
      const width_path: Path2D = constructMeasurementIndicator(
        width_midway_point,
        width_text.real_value, width_text.angle
      );
      indicator_path.addPath(width_path);
      texts.push(width_text);

      const length_midway_point: Vector2 = getDistantPoint(slab_rect.B, 180, length * 2);
      const length_text: Text = measurementText(length_midway_point, length * 4, 180, (area?.material_length ?? 60) * inch_to_pixel, "", "", "", false, false);
      const length_path: Path2D = constructMeasurementIndicator(
        length_midway_point,
        length_text.real_value,
        length_text.angle
      );
      indicator_path.addPath(length_path);
      texts.push(length_text);
    }
  }
}
