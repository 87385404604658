export const po_title_container_style = {
  backgroundColor: "var(--mui-palette-primary-main)",
  height: "80px",
  paddingTop: "10px",
  paddingBottom: "10px"
}

export const po_title_style = {
  fontFamily: "Montserrat",
  fontSize: 30,
  color: "var(--mui-palette-background-paper)"
}

export const po_subtitle_style = {
  fontFamily: "Montserrat",
  fontSize: 14,
  color: "var(--mui-palette-background-paper)"
}

export const po_info_container_style = {
  marginTop: "15px",
  height: "155px",
  paddingTop: "5px"
}

export const po_txt_style = {
  fontFamily: "Montserrat"
}
