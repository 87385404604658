/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getShopOrder } from "api/actions/order/shop_order";
import { getSelfOrderCounters } from "api/actions/self_order/counter";
import { getSelfOrderAreas } from "api/actions/self_order/area/api/get";
import { getSelfOrderSlabs } from "api/actions/self_order/slab";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
/// COMPONENTS ///
import LoadingPage from "components/universal/loading_page";
import POPrintHeader from "components/universal/print/header/po";
import PrintAddons from "components/universal/print/addons";
import PrintLineItems from "components/universal/print/line_items";
import PrintSummaryHeaders from "components/universal/print/summary_headers";
import PrintSummary from "components/universal/print/summary";
import PrintTerms from "components/universal/print/terms";
import OrderPrintAreas from "components/universal/diagrams/print_order_counter/areas";
import OrderPrintShapeAreas from "components/universal/diagrams/print_order_cut_shapes/areas";
import PrintSignature from "components/universal/print/signature";
/// PDF ///
import { PDFViewer } from "@react-pdf/renderer";
import { Page } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
/// IMAGE ///
import Logo from "static/logo_icon.png";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";

export default function ShopPOPrint() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [logo, setLogo] = useState<string | null>(null);
  const [logo_read, setLogoRead] = useState<boolean>(false);
  const {
    areas,
    areas_loading,
    counters,
    counters_loading,
    self_order,
    slabs,
    slabs_loading
  } = useAppSelector((state) => state.self_order);

  useEffect(() => {
    if (id) {
      dispatch(getShopOrder(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getSelfOrderAreas(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getSelfOrderCounters(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getSelfOrderSlabs(id));
    }
  }, [dispatch, id]);

  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
      },
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
        fontWeight: "bold"
      }
    ]
  });

  useEffect(() => {
    if (typeof (self_order?.fabricator_data?.logo) === "string") {
      fetch(self_order.fabricator_data.logo).then(response => {
        return response.blob();
      }).then(blob => {
        const blob_url: string = URL.createObjectURL(blob);
        setLogo(blob_url);
        setLogoRead(true);
      }).catch(() => {
        setLogoRead(true);
      })
    }
    else {
      setLogoRead(true);
    }
  }, [self_order?.fabricator_data?.logo]);

  const styles = StyleSheet.create({
    page: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "40px",
      paddingBottom: "40px"
    },
    title: {
      fontWeight: "bold",
      fontSize: 14
    },
    body2: {
      fontSize: 14
    },
    logo: {
      height: "40px",
      width: "80px",
      objectFit: "contain",
      objectPosition: "left"
    },
    row: {
      marginTop: "25px",
      display: "flex",
      flexDirection: "row"
    },
    font: {
      fontFamily: "Montserrat"
    },
    footer_image: {
      position: "absolute",
      right: 10,
      bottom: 5,
      height: "20px",
      width: "20px"
    }
  });

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={!self_order?.id || !logo_read || counters_loading || areas_loading || slabs_loading}>
        <Box display="flex" justifyContent="center">
          <PDFViewer width="795px" height="1123px">
            <Document>
              <Page size="A4" style={styles.page}>
                <POPrintHeader
                  title="PURCHASE ORDER"
                  number={self_order?.order_number ?? ""}
                  logo_url={logo ? logo : ""}
                  vendor_name={self_order?.fabricator_data?.business_name ?? ""}
                  vendor_address={self_order?.fabricator_data?.address ?? ""}
                  vendor_city={self_order?.fabricator_data?.city ?? ""}
                  vendor_province={self_order?.fabricator_data?.province ?? ""}
                  vendor_postal_code={self_order?.fabricator_data?.postal_code ?? ""}
                  vendor_phone_number={self_order?.fabricator_data?.phone_number ?? ""}
                  vendor_email={self_order?.fabricator_data?.email ?? ""}
                  bill_to_name={`${self_order?.end_customer_data?.first_name ?? ""} ${self_order?.end_customer_data?.last_name ?? ""}`}
                  bill_to_address={self_order?.end_customer_data?.address ?? ""}
                  bill_to_city={self_order?.end_customer_data?.city ?? ""}
                  bill_to_province={self_order?.end_customer_data?.province ?? ""}
                  bill_to_postal_code={self_order?.end_customer_data?.postal_code ?? ""}
                  bill_to_phone_number={self_order?.end_customer_data?.phone_number ?? ""}
                  bill_to_email={self_order?.end_customer_data?.email ?? ""}
                  job_site_name={self_order?.fabrication_job_data?.name ?? ""}
                  job_site_address={self_order?.fabrication_job_data?.address ?? ""}
                  job_site_city={self_order?.fabrication_job_data?.city ?? ""}
                  job_site_province={self_order?.fabrication_job_data?.province ?? ""}
                  job_site_postal_code={self_order?.fabrication_job_data?.postal_code ?? ""}
                  job_site_phone_number={self_order?.fabrication_job_data?.phone_number ?? ""}
                  job_site_email={self_order?.fabrication_job_data?.email ?? ""}
                  date={self_order?.created ?? ""} />
                <PrintSummaryHeaders />
                <PrintLineItems areas={areas} />
                {self_order?.addons?.length > 0 ? <PrintAddons addons={self_order?.addons ?? []} /> : null}
                <PrintSummary
                  total={self_order?.total ?? 0}
                  tax_name={self_order?.tax_name ?? "Tax"}
                  tax={self_order?.tax ?? 0}
                  second_tax_name={self_order?.second_tax_name ?? "Tax2"}
                  second_tax={self_order?.second_tax ?? 0}
                  discount={self_order?.discount ?? 0} />
                <PrintTerms payment_terms={self_order?.fabricator_data?.payment_terms ?? ""} title="Fabricator Terms & Conditions" />
                <OrderPrintAreas counters={counters} areas={areas} />
                <OrderPrintShapeAreas slabs={slabs} areas={areas} />
                <PrintSignature
                  signature={self_order?.signature_image ?? ""}
                  name={`${self_order?.end_customer_data?.first_name ?? ""} ${self_order?.end_customer_data?.last_name ?? ""}`}
                  date={self_order?.signature_date ?? ""} />
                <Image src={Logo} style={styles.footer_image} fixed />
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      </LoadingPage>
    </motion.div>
  );
}
