/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useNavigate } from "react-router-dom";
import { logout } from "api/actions/authentication";
/// MUI COMPONENTS ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
/// URLS ///
import { login_url } from "components/navigation/endpoints";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void
}

export default function Logout({ open, setOpen }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutUser = () => {
    dispatch(logout());
    navigate(login_url);
    setOpen(false);
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        Logout
      </DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Typography variant="body2">
          Are you sure you want to logout?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button onClick={logoutUser}>
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
}
