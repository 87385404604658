import { Counter } from "api/types/sketch";
import { OrderCounter } from "api/types/order/order";
import { SelfOrderCounter } from "api/types/order/self_order";
import { Cooktop } from "api/types/sketch";
import { OrderCooktop } from "api/types/order/order";
import { SelfOrderCooktop } from "api/types/order/self_order";
import { Vector2 } from "api/types/sketch";
import { getBottomLeftVertex } from "../../math";
import { getTopRightVertex } from "../../math";
import { getTopLeftVertex } from "../../math";
import { getBottomRightVertex } from "../../math";
import { getDistantPoint } from "../../math";
import { adjustVectorToScale } from "../scale";

export function drawOrderCooktops(
    path: Path2D,
    counters: OrderCounter[] | SelfOrderCounter[] | Counter[],
    offset: Vector2,
    zoom: number
){
    for(let i = 0; i < counters.length; i++){
        const cooktops_path: Path2D = drawOrderCooktop(counters[i], offset, zoom);
        path.addPath(cooktops_path);
    }
}

export function drawOrderCooktop(
    counter: OrderCounter | SelfOrderCounter | Counter,
    offset: Vector2,
    zoom: number
): Path2D {
    const cooktop_path: Path2D = new Path2D();
    for(let j = 0; j < counter.cooktops.length; j++){
        const cooktop: OrderCooktop | SelfOrderCooktop | Cooktop = counter.cooktops[j];
        const location: Vector2 = {
            X: cooktop.location.X * zoom + offset.X, 
            Y: cooktop.location.Y * zoom + offset.Y
        };
        const start_point: Vector2 = getBottomLeftVertex(
            location, cooktop.angle, cooktop.width * 4 * zoom, cooktop.length * 4 * zoom);
        const top_left: Vector2 = getTopLeftVertex(
            location, cooktop.angle, cooktop.width * 4 * zoom, cooktop.length * 4 * zoom);
        const top_right: Vector2 = getTopRightVertex(
            location, cooktop.angle, cooktop.width * 4 * zoom, cooktop.length * 4 * zoom);
        const bottom_right: Vector2 = getBottomRightVertex(
            location, cooktop.angle, cooktop.width * 4 * zoom, cooktop.length * 4 * zoom);

        cooktop_path.moveTo(start_point.X, start_point.Y);
        cooktop_path.lineTo(top_left.X, top_left.Y);
        cooktop_path.lineTo(top_right.X, top_right.Y);
        cooktop_path.lineTo(bottom_right.X, bottom_right.Y);
        cooktop_path.lineTo(start_point.X, start_point.Y);

        const burner_width: number = cooktop.width / 3 * 0.9;
        const first_location: Vector2 = getDistantPoint(location, cooktop.angle - 35, burner_width * 4 * 1.5 * zoom);
        const outer_burner_width: number = burner_width / 2 * 4 * zoom;
        const inner_burner_width: number = burner_width / 2 * 4 * zoom * 0.6;
        const first_c_1_start: Vector2 = getDistantPoint(first_location, 90, outer_burner_width);
        const first_c_2_start: Vector2 = getDistantPoint(first_location, 90, inner_burner_width);
        cooktop_path.moveTo(first_c_1_start.X, first_c_1_start.Y);
        cooktop_path.arc(first_location.X, first_location.Y, outer_burner_width, 0, 2 * Math.PI);
        cooktop_path.moveTo(first_c_2_start.X, first_c_2_start.Y);
        cooktop_path.arc(first_location.X, first_location.Y, inner_burner_width, 0, 2 * Math.PI);

        const second_location: Vector2 = getDistantPoint(location, cooktop.angle + 35, burner_width * 4 * 1.5 * zoom);
        const second_c_1_start: Vector2 = getDistantPoint(second_location, 90, outer_burner_width);
        const second_c_2_start: Vector2 = getDistantPoint(second_location, 90, inner_burner_width);
        cooktop_path.moveTo(second_c_1_start.X, second_c_1_start.Y);
        cooktop_path.arc(second_location.X, second_location.Y, outer_burner_width, 0, 2 * Math.PI);
        cooktop_path.moveTo(second_c_2_start.X, second_c_2_start.Y);
        cooktop_path.arc(second_location.X, second_location.Y, inner_burner_width, 0, 2 * Math.PI);

        const third_location: Vector2 = getDistantPoint(location, cooktop.angle + 145, burner_width * 4 * 1.5 * zoom);
        const third_c_1_start: Vector2 = getDistantPoint(third_location, 90, outer_burner_width);
        const third_c_2_start: Vector2 = getDistantPoint(third_location, 90, inner_burner_width);
        cooktop_path.moveTo(third_c_1_start.X, third_c_1_start.Y);
        cooktop_path.arc(third_location.X, third_location.Y, outer_burner_width, 0, 2 * Math.PI);
        cooktop_path.moveTo(third_c_2_start.X, third_c_2_start.Y);
        cooktop_path.arc(third_location.X, third_location.Y, inner_burner_width, 0, 2 * Math.PI);

        const fourth_location: Vector2 = getDistantPoint(location, cooktop.angle - 145, burner_width * 4 * 1.5 * zoom);
        const fourth_c_1_start: Vector2 = getDistantPoint(fourth_location, 90, outer_burner_width);
        const fourth_c_2_start: Vector2 = getDistantPoint(fourth_location, 90, inner_burner_width);
        cooktop_path.moveTo(fourth_c_1_start.X, fourth_c_1_start.Y);
        cooktop_path.arc(fourth_location.X, fourth_location.Y, outer_burner_width, 0, 2 * Math.PI);
        cooktop_path.moveTo(fourth_c_2_start.X, fourth_c_2_start.Y);
        cooktop_path.arc(fourth_location.X, fourth_location.Y, inner_burner_width, 0, 2 * Math.PI);

        if(cooktop.num_burners > 4){
            const fifth_location: Vector2 = adjustVectorToScale(
                getDistantPoint(location, cooktop.angle + 90, burner_width * 4 * 0.86155),
                zoom
            );
            const fifth_c_1_start: Vector2 = getDistantPoint(fifth_location, 90, outer_burner_width);
            const fifth_c_2_start: Vector2 = getDistantPoint(fifth_location, 90, inner_burner_width);
            cooktop_path.moveTo(fifth_c_1_start.X, fifth_c_1_start.Y);
            cooktop_path.arc(fifth_location.X, fifth_location.Y, outer_burner_width, 0, 2 * Math.PI);
            cooktop_path.moveTo(fifth_c_2_start.X, fifth_c_2_start.Y);
            cooktop_path.arc(fifth_location.X, fifth_location.Y, inner_burner_width, 0, 2 * Math.PI);
        }

        if(cooktop.num_burners > 5){
            const sixth_location: Vector2 = adjustVectorToScale(
                getDistantPoint(location, cooktop.angle - 90, burner_width * 4 * 0.86155),
                zoom
            );
            const sixth_c_1_start: Vector2 = getDistantPoint(sixth_location, 90, outer_burner_width);
            const sixth_c_2_start: Vector2 = getDistantPoint(sixth_location, 90, inner_burner_width);
            cooktop_path.moveTo(sixth_c_1_start.X, sixth_c_1_start.Y);
            cooktop_path.arc(sixth_location.X, sixth_location.Y, outer_burner_width, 0, 2 * Math.PI);
            cooktop_path.moveTo(sixth_c_2_start.X, sixth_c_2_start.Y);
            cooktop_path.arc(sixth_location.X, sixth_location.Y, inner_burner_width, 0, 2 * Math.PI);
        }
    }
    return cooktop_path;
}