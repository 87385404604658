export const url_prefix: string = process.env.REACT_APP_URL_PREFIX ? process.env.REACT_APP_URL_PREFIX : "http://127.0.0.1:8000/";//"http://192.168.50.35:8000/";
export const admin_url: string = process.env.REACT_APP_ADMIN_URL ? process.env.REACT_APP_ADMIN_URL : "";
export const socket_url: string = process.env.REACT_APP_SOCKET_URL ? process.env.REACT_APP_SOCKET_URL : "";
export const STRIPE_PUBLIC_KEY: string = process.env.REACT_APP_STRIPE_PK ? process.env.REACT_APP_STRIPE_PK : "";
export const SUBSCRIPTION_PRODUCT_ID: string = process.env.REACT_APP_SUBSCRIPTION_PRODUCT_ID ? process.env.REACT_APP_SUBSCRIPTION_PRODUCT_ID : "";
export const MONTHLY_PRICE_ID: string = process.env.REACT_APP_MONTHLY_PRICE_ID ? process.env.REACT_APP_MONTHLY_PRICE_ID : "";
export const YEARLY_PRICE_ID: string = process.env.REACT_APP_YEARLY_PRICE_ID ? process.env.REACT_APP_YEARLY_PRICE_ID : "";
export const QUOTING_ONLY_PRODUCT_ID: string = process.env.REACT_APP_QUOTING_ONLY_PRODUCT_ID ? process.env.REACT_APP_QUOTING_ONLY_PRODUCT_ID : "";
export const MONTHLY_QUOTING_ONLY_PRICE_ID: string = process.env.REACT_APP_MONTHLY_QUOTING_ONLY_PRICE_ID ? process.env.REACT_APP_MONTHLY_QUOTING_ONLY_PRICE_ID : "";
export const MARKET_ONLY_PRODUCT_ID: string = process.env.REACT_APP_MARKET_ONLY_PRODUCT_ID ? process.env.REACT_APP_MARKET_ONLY_PRODUCT_ID : "";
export const MONTHLY_MARKET_ONLY_PRICE_ID: string = process.env.REACT_APP_MONTHLY_MARKET_ONLY_PRICE_ID ? process.env.REACT_APP_MONTHLY_MARKET_ONLY_PRICE_ID : "";

export const SENTRY_ENVIRONMENT: string = process.env.REACT_APP_SENTRY_ENVIRONMENT ? process.env.REACT_APP_SENTRY_ENVIRONMENT : "";
export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN ? process.env.REACT_APP_SENTRY_DSN : "";
