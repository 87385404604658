/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/authentication";

export const setShopView = (shop_view: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SHOP_VIEW,
      shop_view
    })
  }
}
