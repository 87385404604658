/// MUI ///
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
/// ICONS ///
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
/// TYPES ///
import { Vector2 } from "api/types/sketch";
/// STYLES ///
import { menuItemStyle } from "styles/menu";

interface Props {
  open: boolean,
  menu_location: Vector2,
  openProperties: () => void,
  rotateCooktop: (rotation: number) => void,
  duplicateCooktop: () => void,
  deleteCooktop: () => void
}

export default function CooktopCutoutMenu(
  {
    open,
    menu_location,
    openProperties,
    rotateCooktop,
    duplicateCooktop,
    deleteCooktop
  }: Props
) {
  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{ top: menu_location.Y, left: menu_location.X }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={open}>
      <List>
        <ListItemButton onClick={openProperties} sx={menuItemStyle}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Properties
        </ListItemButton>
        <ListItemButton onClick={() => rotateCooktop(-22.5)} sx={menuItemStyle} id="menu_rotate_cooktop_left">
          <ListItemIcon id="menu_rotate_cooktop_left_icon">
            <RotateLeftIcon id="menu_rotate_cooktop_left_svg_icon" />
          </ListItemIcon>
          Rotate Cooktop Left
        </ListItemButton>
        <ListItemButton onClick={() => rotateCooktop(22.5)} sx={menuItemStyle} id="menu_rotate_cooktop_right">
          <ListItemIcon id="menu_rotate_cooktop_right_icon">
            <RotateRightIcon id="menu_rotate_cooktop_right_sv_icon" />
          </ListItemIcon>
          Rotate Cooktop Right
        </ListItemButton>
        <ListItemButton onClick={duplicateCooktop} sx={menuItemStyle} id="menu_duplicate_cooktop">
          <ListItemIcon id="menu_duplicate_cooktop_icon">
            <ContentCopyIcon id="menu_duplicate_cooktop_svg_icon" />
          </ListItemIcon>
          Duplicate Cooktop
        </ListItemButton>
        <ListItemButton onClick={deleteCooktop} sx={menuItemStyle} id="menu_delete_cooktop">
          <ListItemIcon id="menu_delete_cooktop_icon">
            <DeleteIcon id="menu_delete_cooktop_menu_icon" />
          </ListItemIcon>
          Delete Cooktop
        </ListItemButton>
      </List>
    </Popover>
  )
}
