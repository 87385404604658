/// REACT ///
import { KeyboardEvent } from "react";
import { MouseEvent } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// VALUES ///
import { UNDERMOUNT } from "values/values";
import { FARMER } from "values/values";
import { DROP_IN } from "values/values";
import { RECTANGLE } from "values/values";
import { OVAL } from "values/values";
import { DOUBLE } from "values/values";
import { SIXTY_OVER_FORTY } from "values/values";
import { FORTY_OVER_SIXTY } from "values/values";
import { SEVENTY_OVER_THIRTY } from "values/values";
import { THIRTY_OVER_SEVENTY } from "values/values";
import { getSinkTypes } from "values/values";
import { getCutoutShapes } from "values/values";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  sink_type: string,
  setSinkType: (sink_type: string) => void,
  shape: string,
  setShape: (shape: string) => void,
  faucet_hole_count: number,
  setFaucetHoleCount: (faucet_hole_count: number) => void,
  length: number,
  setLength: (length: number) => void,
  length_error: string,
  length_has_error: boolean,
  width: number,
  setWidth: (width: number) => void,
  width_error: string,
  width_has_error: boolean,
  save: () => void,
  cancel: () => void,
  title: string
}

export default function SaveSink({
  open,
  setOpen,
  sink_type,
  setSinkType,
  shape,
  setShape,
  faucet_hole_count,
  setFaucetHoleCount,
  length,
  setLength,
  length_error,
  length_has_error,
  width,
  setWidth,
  width_error,
  width_has_error,
  save,
  cancel,
  title
}: Props) {

  const toggleDrawer = () =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(!open);
    };

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      save();
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      cancel();
    }
  }

  return (
    <SwipeableDrawer
      BackdropProps={{ invisible: true }}
      disableAutoFocus
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      onKeyDown={keyDown}
      anchor="right"
      open={open}>
      <Box display="flex" flexDirection="column" justifyContent="center" sx={{ paddingTop: "25px", paddingLeft: "50px", paddingRight: "50px", width: "300px", gap: "10px" }}>
        <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
          <IconButton onClick={cancel} onTouchStart={cancel}>
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Box>
        <Typography variant="h4" align="center" sx={{ marginTop: "50px" }}>
          {title}
        </Typography>
        <TextField
          autoFocus
          fullWidth
          select
          value={sink_type ?? UNDERMOUNT}
          onChange={(e) => setSinkType(e.target.value)}
          label="Type">
          <MenuItem value={UNDERMOUNT} key={UNDERMOUNT}>
            {getSinkTypes(UNDERMOUNT)}
          </MenuItem>
          <MenuItem value={FARMER} key={FARMER}>
            {getSinkTypes(FARMER)}
          </MenuItem>
          <MenuItem value={DROP_IN} key={DROP_IN}>
            {getSinkTypes(DROP_IN)}
          </MenuItem>
        </TextField>
        <TextField
          fullWidth
          select
          value={shape ?? RECTANGLE}
          onChange={(e) => setShape(e.target.value)}
          label="Shape">
          <MenuItem value={RECTANGLE} key={RECTANGLE}>
            {getCutoutShapes(RECTANGLE)}
          </MenuItem>
          {
            sink_type !== FARMER ?
              <MenuItem value={OVAL} key={OVAL}>
                {getCutoutShapes(OVAL)}
              </MenuItem> :
              null
          }
          <MenuItem value={DOUBLE} key={DOUBLE}>
            {getCutoutShapes(DOUBLE)}
          </MenuItem>
          <MenuItem value={SIXTY_OVER_FORTY} key={SIXTY_OVER_FORTY}>
            {getCutoutShapes(SIXTY_OVER_FORTY)}
          </MenuItem>
          <MenuItem value={FORTY_OVER_SIXTY} key={FORTY_OVER_SIXTY}>
            {getCutoutShapes(FORTY_OVER_SIXTY)}
          </MenuItem>
          <MenuItem value={SEVENTY_OVER_THIRTY} key={SEVENTY_OVER_THIRTY}>
            {getCutoutShapes(SEVENTY_OVER_THIRTY)}
          </MenuItem>
          <MenuItem value={THIRTY_OVER_SEVENTY} key={THIRTY_OVER_SEVENTY}>
            {getCutoutShapes(THIRTY_OVER_SEVENTY)}
          </MenuItem>
        </TextField>
        <TextField
          fullWidth
          select
          value={faucet_hole_count ?? 1}
          onChange={(e) => setFaucetHoleCount(Number(e.target.value))}
          label="Faucet Holes">
          <MenuItem value={0} key={0}>0</MenuItem>
          <MenuItem value={1} key={1}>1</MenuItem>
          <MenuItem value={2} key={2}>2</MenuItem>
          <MenuItem value={3} key={3}>3</MenuItem>
          <MenuItem value={4} key={4}>4</MenuItem>
          <MenuItem value={5} key={5}>5</MenuItem>
        </TextField>
        <TextField
          fullWidth
          label="Length"
          error={length_has_error}
          helperText={length_error}
          type="number"
          onChange={e => setLength(Number(e.target.value))}
          value={length > 0 ? length : ""} />
        <TextField
          fullWidth
          label="Width"
          type="number"
          error={width_has_error}
          helperText={width_error}
          onChange={e => setWidth(Number(e.target.value))}
          value={width > 0 ? width : ""} />
        <Button
          onClick={save}>
          Save
        </Button>
      </Box>
    </SwipeableDrawer>
  );
}
