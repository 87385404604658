/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useState } from "react";
/// TYPES ///
import { Counter } from "api/types/sketch";
import { DrawOptions } from "api/types/sketch";
import { Label } from "api/types/sketch";
import { HighlightData } from "types";
import { MouseData } from "types/mouse_position";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { drawCounters } from "functions/sketch";
import { drawArrowPath } from "functions/sketch";
import { getCanvas } from "functions/sketch/context";
import { getContext } from "functions/sketch/context";
/// CANVAS ///
import { detailCanvas } from "functions/sketch/context";
import { highlightCanvas } from "functions/sketch/context";
import { initializeCanvas } from "functions/sketch/context";
import { defaultCanvas } from "functions/sketch/context";
/// FUNCTIONS ///
import { getCounterColor } from "functions/sketch";

function useDraw(
  index: number,
  selected_counter: Counter | null,
  current_area: string,
  mouse_data: MouseData,
  open: boolean,
  drawing: boolean,
  lifting: boolean,
  in_operation: boolean,
  zoom: number,
  draw_options: DrawOptions
): HighlightData {
  const { counters } = useAppSelector((state) => state.sketch);
  const { labels } = useAppSelector((state) => state.sketch);
  const [highlight_data, setHighlightData] = useState<HighlightData | null>(null);

  useEffect(() => {
    const mouse: Vector2 = mouse_data.position;
    const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById("canvas"));
    const context: CanvasRenderingContext2D | null = getContext(canvas);

    if (canvas && context) {
      initializeCanvas(canvas, context);
      const line_highlights_path = new Path2D();
      context.beginPath();

      const options: DrawOptions = { ...draw_options };
      if (drawing || lifting || open || in_operation) {
        options.text_selectable = false;
        options.lines_selectable = false;
        options.ends_selectable = false;
      }

      setHighlightData(drawCounters(
        context,
        counters.filter(counter => counter.area_uuid === current_area),
        line_highlights_path,
        zoom,
        { X: 0, Y: 0 },
        mouse,
        options
      ));

      detailCanvas(context);
      if (drawing && selected_counter && selected_counter.points.length > 1) {
        const arrow_path: Path2D = drawArrowPath(mouse, selected_counter.points[selected_counter.points.length - 2].angle, zoom);
        highlightCanvas(context);
        context.stroke(arrow_path);
      }

      defaultCanvas(context);

      const current_labels: Label[] = labels.filter(label => label.area_uuid === current_area);

      for (let i = 0; i < current_labels.length; i++) {
        context.fillStyle = getCounterColor(current_labels[i]?.area_position - 1 ?? 0);
        context.font = `${current_labels[i].size}px Arial`;
        context.fillText(current_labels[i].text, current_labels[i].location.X * zoom, current_labels[i].location.Y * zoom);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouse_data.position, zoom, counters, labels, index, current_area]);

  return highlight_data;
};
export default useDraw;
