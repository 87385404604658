/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// MUI ///
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";

export default function CalendarPageBar() {
  const navigate = useNavigate();

  return (
    <Toolbar disableGutters={true} variant="dense">
      <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ width: "100%" }}>
        <Button onClick={() => navigate(-1)} size="small" variant="outlined">
          Back
        </Button>
      </Stack>
    </Toolbar>
  )
}
