/// DATETIME ///
import { DateTime } from "luxon";
/// MUI COMPONENTS ///
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { dayOfMonthStyle } from "styles/calendar/day";

interface Props {
  index: number,
  day: number,
  nav_date: string,
}

export default function DayHeader({ index, day, nav_date }: Props) {
  const getDayOfWeek = () => {
    switch (index) {
      case 0:
        return "Sun";
      case 1:
        return "Mon";
      case 2:
        return "Tue";
      case 3:
        return "Wed";
      case 4:
        return "Thu";
      case 5:
        return "Fri";
      case 6:
        return "Sat";
    }
  }

  if (day < 1) {
    if (day > 6) {
      return null;
    }
    else {
      return (
        <ListItem key="header" sx={dayOfMonthStyle(false)}>
          <Stack justifyContent="center" direction="row" sx={{ width: "100%" }}>
            <Typography variant="body2" sx={{ fontSize: 12 }}>
              {getDayOfWeek()}
            </Typography>
          </Stack>
        </ListItem>
      )
    }
  }

  return (
    <ListItem key="header" sx={dayOfMonthStyle(DateTime.fromISO(nav_date).set({ day: day }).hasSame(DateTime.now(), "day"))}>
      <Stack justifyContent="space-between" direction="row" sx={{ width: "100%" }}>
        <Typography variant="body2" sx={{ fontSize: 12 }}>
          {day}
        </Typography>
        {
          index < 7 ?
            <Typography variant="body2" sx={{ fontSize: 12 }}>
              {getDayOfWeek()}
            </Typography> :
            <div></div>
        }
      </Stack>
    </ListItem>
  )
}
