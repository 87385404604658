import { Vector2 } from "api/types/sketch";
import { BumpArcConstruct } from "api/types/sketch";
import { constructFullArc } from "../construct";

export function drawFullWidth(
    path: Path2D, 
    edge_path: Path2D,
    start: Vector2, 
    end: Vector2, 
    depth: number, 
    angle: number,
    zoom: number,
    needs_edge: boolean
){
    const bump_construct: BumpArcConstruct = constructFullArc(start, end, angle, depth, zoom);

    if(!needs_edge){
        path.lineTo(bump_construct.start.X, bump_construct.start.Y);
        path.quadraticCurveTo(bump_construct.top.X, bump_construct.top.Y, bump_construct.end.X, bump_construct.end.Y);
        edge_path.moveTo(bump_construct.end.X, bump_construct.end.Y);
    }
    else{
        edge_path.lineTo(bump_construct.start.X, bump_construct.start.Y);
        edge_path.quadraticCurveTo(bump_construct.top.X, bump_construct.top.Y, bump_construct.end.X, bump_construct.end.Y);
        path.moveTo(bump_construct.end.X, bump_construct.end.Y);
    }
}