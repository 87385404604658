import { Vector2 } from "api/types/sketch";
import { CornerRadius } from "api/types/sketch";
import { InwardCornerRadius } from "api/types/sketch";
import { CornerNotch } from "api/types/sketch";
import { CornerBumpOut } from "api/types/sketch";
import { CornerRecessedDiagonal } from "api/types/sketch";
import { CornerClip } from "api/types/sketch";
import { CornerDiagonal } from "api/types/sketch";
import { getLineIntersection } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { toRadians } from "functions/sketch/math";
import { getAverageAngle } from "functions/sketch/math";
import { getHypoteneuse } from "functions/sketch/math";
import { inch_to_pixel } from "values/values";

export function constructRadiusCorner(
    angle: number,
    corner_point: Vector2,
    next_angle: number,
    radius: number
): CornerRadius {
    const angle_A: number = angle - 270;
    const angle_B: number = next_angle - 270;

    let a = next_angle - angle;
    a = (a + 180) % 360 - 180;
    a = Math.abs(a / 2);
    
    const inward_angle: number = (angle_A + angle_B) / 2;
    const h_length: number = radius * 4 / Math.sin(toRadians(90 - a));
    const center_point: Vector2 = getDistantPoint(corner_point, inward_angle, h_length);
    const start_point: Vector2 = getDistantPoint(center_point, angle - 90, radius * 4);
    const end_point: Vector2 = getDistantPoint(center_point, next_angle - 90, radius * 4);

    const start_angle: number = Math.atan2(start_point.Y - center_point.Y, start_point.X - center_point.X);
    const end_angle: number = Math.atan2(end_point.Y - center_point.Y, end_point.X - center_point.X);
    
    return {
        start_point: start_point,
        end_point: end_point,
        center_point: center_point,
        radius: radius * 4,
        start_angle: start_angle,
        end_angle: end_angle
    }
}

export function constructInwardRadiusCorner(angle: number, corner_point: Vector2, next_angle: number, radius: number): InwardCornerRadius {
    const angle_A: number = angle - 90;
    const angle_B: number = next_angle - 90;

    let a = next_angle - angle;
    a = (a + 180) % 360 - 180;
    a = Math.abs(a / 2);

    const inward_angle: number = getAverageAngle(angle_A, angle_B);
    const h_length: number = radius * 4 / Math.sin(toRadians(90 - a));
 
    const center_point: Vector2 = getDistantPoint(corner_point, inward_angle, h_length);
    const start_point: Vector2 = getDistantPoint(center_point, angle + 90, radius * 4);
    const end_point: Vector2 = getDistantPoint(center_point, next_angle + 90, radius * 4);

    const start_angle: number = Math.atan2(start_point.Y - center_point.Y, start_point.X - center_point.X);
    const end_angle: number = Math.atan2(end_point.Y - center_point.Y, end_point.X - center_point.X);
    
    return {
        start_point: start_point,
        end_point: end_point,
        center_point: center_point,
        radius: radius * 4,
        start_angle: start_angle,
        end_angle: end_angle
    }
}

export function constructHalfInwardRadiusCorner(angle: number, corner_point: Vector2, next_angle: number, inward_angle: number, radius: number): InwardCornerRadius {
    let a = next_angle - angle;
    a = (a + 180) % 360 - 180;
    a = Math.abs(a / 2);

    const h_length: number = Math.abs(radius * 4 / Math.sin(toRadians(90 - a)));
 
    const center_point: Vector2 = getDistantPoint(corner_point, inward_angle, h_length);
    const start_point: Vector2 = getDistantPoint(center_point, angle + 90, radius * 4);
    const end_point: Vector2 = getDistantPoint(center_point, next_angle + 90, radius * 4);

    const start_angle: number = Math.atan2(start_point.Y - center_point.Y, start_point.X - center_point.X);
    const end_angle: number = Math.atan2(end_point.Y - center_point.Y, end_point.X - center_point.X);
    
    return {
        start_point: start_point,
        end_point: end_point,
        center_point: center_point,
        radius: radius * 4,
        start_angle: start_angle,
        end_angle: end_angle
    }
}

export function constructNotchCorner(
    angle: number, 
    corner_point: Vector2, 
    next_angle: number, 
    length: number, 
    depth: number
): CornerNotch {
    if(!length || !depth || length === 0 || depth === 0){
        return {
            start_point: corner_point,
            inner_corner: corner_point,
            end_point: corner_point 
        };
    }
   
    const point_C: Vector2 = getDistantPoint(corner_point, next_angle, depth * 4);
    const inner_corner: Vector2 = getDistantPoint(point_C, angle - 180, length * 4);
    const point_A: Vector2 = getDistantPoint(corner_point, angle - 180, length * 4);
    
    return {
        start_point: point_A,
        inner_corner: inner_corner,
        end_point: point_C, 
    }
}

export function constructBumpoutCorner(
    previous_point: Vector2,
    corner_point: Vector2, 
    next_point: Vector2,
    outward_angle: number,
    length: number, 
    depth: number
): CornerBumpOut {
    const middle: Vector2 = getDistantPoint(corner_point, outward_angle, depth * inch_to_pixel);
    const B: Vector2 = getDistantPoint(middle, outward_angle - 90, length / 2 * inch_to_pixel);
    const C: Vector2 = getDistantPoint(middle, outward_angle + 90, length / 2 * inch_to_pixel);
    const B_start: Vector2 = getDistantPoint(B, outward_angle - 180, 1000);
    const C_start: Vector2 = getDistantPoint(C, outward_angle - 180, 1000);
    const A: Vector2 = getLineIntersection(previous_point, corner_point, B_start, B);
    const D: Vector2 = getLineIntersection(corner_point, next_point, C_start, C);

    return {
        A: A, 
        B: B,
        C: C,
        D: D,
        middle: middle
    }
}

export function constructRecessedDiagonalCorner(
    previous_point: Vector2, 
    corner_point: Vector2, 
    next_point: Vector2,
    inward_angle: number,
    length: number, 
    depth: number
): CornerRecessedDiagonal {
    const middle: Vector2 = getDistantPoint(corner_point, inward_angle, depth * inch_to_pixel);
    const B: Vector2 = getDistantPoint(middle, inward_angle + 90, length / 2 * inch_to_pixel);
    const C: Vector2 = getDistantPoint(middle, inward_angle - 90, length / 2 * inch_to_pixel);
    const B_start: Vector2 = getDistantPoint(B, inward_angle - 180, 1000);
    const C_start: Vector2 = getDistantPoint(C, inward_angle - 180, 1000);
    const A: Vector2 = getLineIntersection(previous_point, corner_point, B_start, B);
    const D: Vector2 = getLineIntersection(corner_point, next_point, C_start, C);

    return {
        A: A,
        B: B,
        C: C,
        D: D,
        middle: middle
    }
}

export function constructHalfRecessedDiagonalCorner(
    angle: number,
    next_angle: number,
    inward_angle: number,
    corner_point: Vector2,
    length: number, 
    depth: number
): CornerRecessedDiagonal {
    const distance: number = getHypoteneuse(depth * inch_to_pixel, length * inch_to_pixel / 2);

    const point_A: Vector2 = getDistantPoint(corner_point, angle - 180, distance);
    const point_D: Vector2 = getDistantPoint(corner_point, next_angle, distance);
    const point_B: Vector2 = getDistantPoint(point_A, inward_angle, depth * inch_to_pixel);
    const point_C: Vector2 = getDistantPoint(point_D, inward_angle, depth * inch_to_pixel);

    return {
        A: point_A, 
        B: point_B,
        C: point_C,
        D: point_D,
        middle: {X: 0, Y: 0}
    }
}


export function constructClipCorner(
    angle: number,
    corner_point: Vector2, 
    next_angle: number,
    length: number
): CornerClip {
    return {
        start_point: getDistantPoint(corner_point, angle - 180, length * 4 / 2),
        end_point: getDistantPoint(corner_point, next_angle, length * 4 / 2)
    }
}

export function constructDiagonalCorner(
    angle: number, 
    corner_point: Vector2, 
    next_angle: number,
    length: number
): CornerDiagonal {
    return {
        start_point: getDistantPoint(corner_point, angle - 180, length * 4 / 2),
        end_point: getDistantPoint(corner_point, next_angle, length * 4 / 2)
    }
}