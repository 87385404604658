import { Dispatch } from "redux";
import { Action } from "api/action_types/sketch";
import { ActionType } from "api/action_types/sketch";
import { Cooktop } from "api/types/sketch";

export const updateLocalCooktop = (cooktop: Cooktop) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_COOKTOP,
            payload: cooktop
        });
    }
}