/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
import { useNavigate } from "react-router-dom";
/// MUI COMPONENTS ///
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// URLS ///
import { profile_url } from "components/navigation/endpoints";

export default function UserThumbnail() {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.authentication);

  return (
    <Button onClick={() => navigate(profile_url)} size="small">
      <Stack direction="row" spacing={2}>
        <Stack justifyContent="center">
          <Typography align="left" variant="body2">{user.first_name} {user.last_name}</Typography>
        </Stack>
        {
          typeof user.logo_icon === "string" ?
            <Avatar src={user.logo_icon} variant="square" /> :
            <Avatar variant="circular" sx={{ height: 30, width: 30 }} />
        }
      </Stack>
    </Button>
  );
}
