/// TYPES ///
import { QuoteLineItem } from "api/types/quote";
import { IShopQuoteLineItem } from "api/types/shop_quote/quote_line_item";
/// MUI ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";

interface Props {
  item: QuoteLineItem | IShopQuoteLineItem
}

export default function QuoteSummaryItem({ item }: Props) {
  return (
    <Grid item xs={12}>
      <Typography variant="body2" sx={item_style()}>
        {item.name ?? ""}
      </Typography>
    </Grid>
  )
}
