/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// TYPES ///
import { IShopQuoteFilter } from "api/types/shop_quote/filter";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// FUNCTIONS ///
import { constructGetQuotesUrl } from "./params/filter";

export const getShopQuotes = (filter?: IShopQuoteFilter) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.SHOP_QUOTE_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = constructGetQuotesUrl(filter);

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_SHOP_QUOTES,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SHOP_QUOTE_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
