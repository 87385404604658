/// TYPES ///
import { CSSProperties } from "react";

export const wo_title_container_style: CSSProperties = {
  backgroundColor: "var(--mui-palette-primary-main)",
  height: "80px",
  paddingTop: "10px",
  paddingBottom: "10px"
}

export const wo_title_style: CSSProperties = {
  fontFamily: "Montserrat",
  color: "var(--mui-palette-background-paper)"
}

export const wo_txt_style: CSSProperties = {
  fontFamily: "Montserrat"
}
