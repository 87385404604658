import { ICustomer } from "api/types/customer";
import { FilterEndCustomer } from "api/types/customer";

export enum ActionType {
  GET_END_CUSTOMERS_PENDING = "GET_END_CUSTOMERS_PENDING",
  GET_END_CUSTOMERS_SUCCESS = "GET_END_CUSTOMERS_SUCCESS",
  GET_END_CUSTOMERS_FAIL = "GET_END_CUSTOMERS_FAIL",
  GET_END_CUSTOMER_PENDING = "GET_END_CUSTOMER_PENDING",
  GET_END_CUSTOMER_SUCCESS = "GET_END_CUSTOMER_SUCCESS",
  GET_END_CUSTOMER_FAIL = "GET_END_CUSTOMER_FAIL",
  CREATE_END_CUSTOMER_PENDING = "CREATE_END_CUSTOMER_PENDING",
  CREATE_END_CUSTOMER_SUCCESS = "CREATE_END_CUSTOMER_SUCCESS",
  CREATE_END_CUSTOMER_FAIL = "CREATE_END_CUSTOMER_FAIL",
  UPDATE_END_CUSTOMER_PENDING = "UPDATE_END_CUSTOMER_PENDING",
  UPDATE_END_CUSTOMER_SUCCESS = "UPDATE_END_CUSTOMER_SUCCESS",
  UPDATE_END_CUSTOMER_FAIL = "UPDATE_END_CUSTOMER_FAIL",
  DELETE_END_CUSTOMER_PENDING = "DELETE_END_CUSTOMER_PENDING",
  DELETE_END_CUSTOMER_SUCCESS = "DELETE_END_CUSTOMER_SUCCESS",
  DELETE_END_CUSTOMER_FAIL = "DELETE_END_CUSTOMER_FAIL",
  SET_END_CUSTOMER = "SET_END_CUSTOMER",
  SORT_CUSTOMERS = "SORT_CUSTOMERS",
  SET_CUSTOMER_FILTER = "SET_FILTER"
}

interface getCustomersPending {
  type: ActionType.GET_END_CUSTOMERS_PENDING
}

interface getCustomersSuccess {
  type: ActionType.GET_END_CUSTOMERS_SUCCESS,
  payload: ICustomer[]
}

interface getCustomersFail {
  type: ActionType.GET_END_CUSTOMERS_FAIL,
  payload: string
}

interface getCustomerPending {
  type: ActionType.GET_END_CUSTOMER_PENDING
}

interface getCustomerSuccess {
  type: ActionType.GET_END_CUSTOMER_SUCCESS,
  payload: ICustomer
}

interface getCustomerFail {
  type: ActionType.GET_END_CUSTOMER_FAIL,
  payload: string
}

interface createCustomerPending {
  type: ActionType.CREATE_END_CUSTOMER_PENDING
}

interface createCustomerSuccess {
  type: ActionType.CREATE_END_CUSTOMER_SUCCESS,
  payload: ICustomer
}

interface createCustomerFail {
  type: ActionType.CREATE_END_CUSTOMER_FAIL,
  payload: string
}

interface updateCustomerPending {
  type: ActionType.UPDATE_END_CUSTOMER_PENDING
}

interface updateCustomerSuccess {
  type: ActionType.UPDATE_END_CUSTOMER_SUCCESS,
  payload: ICustomer
}

interface updateCustomerFail {
  type: ActionType.UPDATE_END_CUSTOMER_FAIL,
  payload: string
}

interface deleteCustomerPending {
  type: ActionType.DELETE_END_CUSTOMER_PENDING
}

interface deleteCustomerSuccess {
  type: ActionType.DELETE_END_CUSTOMER_SUCCESS,
  payload: ICustomer
}

interface deleteCustomerFail {
  type: ActionType.DELETE_END_CUSTOMER_FAIL,
  payload: string
}

interface setCustomer {
  type: ActionType.SET_END_CUSTOMER,
  payload: ICustomer
}

interface ISortCustomer {
  type: ActionType.SORT_CUSTOMERS,
  customers: ICustomer[]
}

interface IFilterCustomer {
  type: ActionType.SET_CUSTOMER_FILTER,
  filter: FilterEndCustomer
}

export type Action =
  getCustomersFail |
  getCustomersSuccess |
  getCustomersPending |
  getCustomerFail |
  getCustomerSuccess |
  getCustomerPending |
  createCustomerFail |
  createCustomerSuccess |
  createCustomerPending |
  updateCustomerPending |
  updateCustomerSuccess |
  updateCustomerFail |
  deleteCustomerFail |
  deleteCustomerSuccess |
  deleteCustomerPending |
  setCustomer |
  ISortCustomer |
  IFilterCustomer;
