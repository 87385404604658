/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
import { IShopSlab } from "api/types/shop_quote/slab";
import { Vector2 } from "api/types/sketch";
/// VALUES ///
import { inch_to_pixel } from "values/values";
import { SLAB_ZOOM } from "values/values";

export function getFirstShopSlabInArea(area: IShopArea): IShopSlab | null {
  for (let i = 0; i < area.slabs.length; i++) {
    return area.slabs[i];
  }
  return null;
}

export function getFirstShopSlabInAreas(areas: IShopArea[], area_uuid: string): IShopSlab | null {
  for (let i = 0; i < areas.length; i++) {
    if (areas[i].uuid === area_uuid) {
      for (let j = 0; j < areas[i].slabs.length; j++) {
        return areas[i].slabs[j];
      }
    }
  }
  return null;
}

export function getShopSlabStartPoint(height: number, i: number, zoom: number = SLAB_ZOOM): Vector2 {
  return {
    X: 50,
    Y: 50 + i * height * zoom * inch_to_pixel + i * 25
  }
}

export function getShopSlabByUuid(uuid: string, areas: IShopArea[]): IShopSlab | null {
  for (let i = 0; i < areas.length; i++) {
    for (let j = 0; j < areas[i].slabs.length; j++) {
      if (areas[i].slabs[j].uuid === uuid) {
        return areas[i].slabs[j];
      }
    }
  }
  return null;
}
