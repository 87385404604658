import { Vector2 } from "api/types/sketch";
import { OrderCutBump } from "api/types/order/order";
import { SelfOrderCutBump } from "api/types/order/self_order";
import { drawCutFullWidth } from "../full_arc/cut_full_arc";
import { drawCutBumpIn } from "../bump_in/cut_bump_in";
import { drawCutBumpInArc } from "../bump_in_arc/cut_bump_in_arc";
import { drawCutBumpOut } from "../bump_out/cut_bump_out";
import { drawCutBumpOutArc } from "../bump_out_arc/cut_bump_out_arc";
import { FULL_ARC } from "values/values";
import { NO_BUMP } from "values/values";
import { BUMP_IN } from "values/values";
import { BUMP_IN_ARC } from "values/values";
import { BUMP_OUT } from "values/values";
import { BUMP_OUT_ARC } from "values/values";
import { adjustMeasurementToScale } from "../../scale";
import { inch_to_pixel } from "values/values";

export function drawOrderCutBumps(
  path: Path2D,
  unsorted_bumps: OrderCutBump[] | SelfOrderCutBump[],
  current_location: Vector2,
  next_location: Vector2,
  angle: number,
  zoom: number
) {
  let is_full_arc: boolean = false;
  const bumps: OrderCutBump[] | SelfOrderCutBump[] = [...unsorted_bumps];
  bumps.sort(function (a, b) {
    if (a.distance < b.distance) {
      return -1;
    }
    else if (a.distance > b.distance) {
      return 1;
    }
    else {
      return 0;
    }
  });

  for (let j = 0; j < bumps.length; j++) {
    if (bumps[j].bump_type === FULL_ARC) {
      is_full_arc = true;
      drawCutFullWidth(
        path,
        current_location,
        next_location,
        bumps[j].depth * inch_to_pixel * zoom,
        angle,
        zoom
      );
    }
  }

  if (!is_full_arc) {
    for (let j = 0; j < bumps.length; j++) {
      if (bumps[j].bump_type === NO_BUMP) {
        continue;
      }
      else if (bumps[j].bump_type === BUMP_IN) {
        drawCutBumpIn(
          path,
          current_location,
          next_location,
          bumps[j].distance * inch_to_pixel * zoom,
          bumps[j].width * inch_to_pixel * zoom,
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          bumps[j].left_side_angle,
          bumps[j].right_side_angle,
          zoom
        );
      }
      else if (bumps[j].bump_type === BUMP_OUT) {
        drawCutBumpOut(
          path,
          current_location,
          next_location,
          adjustMeasurementToScale(bumps[j].distance * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          bumps[j].left_side_angle,
          bumps[j].right_side_angle,
          zoom
        );
      }
      else if (bumps[j].bump_type === BUMP_IN_ARC) {
        drawCutBumpInArc(
          path,
          current_location,
          next_location,
          adjustMeasurementToScale(bumps[j].distance * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          zoom
        );
      }
      else if (bumps[j].bump_type === BUMP_OUT_ARC) {
        drawCutBumpOutArc(
          path,
          current_location,
          next_location,
          adjustMeasurementToScale(bumps[j].distance * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].width * inch_to_pixel, zoom),
          adjustMeasurementToScale(bumps[j].depth * inch_to_pixel, zoom),
          angle,
          zoom
        );
      }
    }
  }
}
