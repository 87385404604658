export enum ActionType {
  SHOP_QUOTE_UPDATING = "SHOP_QUOTE_UPDATING",
  SHOP_QUOTE_LOADING = "SHOP_QUOTE_LOADING",
  SHOP_QUOTE_ACTION_SUCCESS = "SHOP_QUOTE_ACTION_SUCCESS",
  SHOP_AREAS_LOADING = "SHOP_AREAS_LOADING",
  SHOP_COUNTERS_LOADING = "SHOP_COUNTERS_LOADING",
  SHOP_SHAPES_LOADING = "SHOP_SHAPES_LOADING"
}

interface IShopQuoteUpdating {
  type: ActionType.SHOP_QUOTE_UPDATING
}

interface IShopQuoteLoading {
  type: ActionType.SHOP_QUOTE_LOADING
}

interface IShopQuoteActionSuccess {
  type: ActionType.SHOP_QUOTE_ACTION_SUCCESS
}

interface IShopCountersLoading {
  type: ActionType.SHOP_COUNTERS_LOADING
}

interface IShopShapesLoading {
  type: ActionType.SHOP_SHAPES_LOADING
}

interface IShopAreasLoading {
  type: ActionType.SHOP_AREAS_LOADING
}

export type Action = IShopQuoteLoading | IShopQuoteUpdating | IShopQuoteActionSuccess | IShopCountersLoading | IShopShapesLoading | IShopAreasLoading;
