/// AXIOS ///
import axios from "axios";
/// URLS ///
import { url_prefix } from "settings";
import { reset_password_url } from "../endpoint";

export const resetPassword = (email: string) => {
  return async () => {

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    const url = `${url_prefix}${reset_password_url}/?email=${email}&`;

    try {
      await axios.post(url, {}, config);
    }
    catch (err) { }
  }
}
