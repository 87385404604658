import { SlabRect } from "api/types/sketch";
import { inch_to_pixel } from "values/values";

export function constructSlabRect(id: string, i: number, length: number, height: number): SlabRect {
  const start_Y: number = 50 + i * height * inch_to_pixel + i * 25;
  const rect: SlabRect = {
    id: id,
    A: {
      X: 50,
      Y: start_Y
    },
    B: {
      X: 50 + length * inch_to_pixel,
      Y: start_Y
    },
    C: {
      X: 50 + length * inch_to_pixel,
      Y: start_Y + height * inch_to_pixel
    },
    D: {
      X: 50,
      Y: start_Y + height * inch_to_pixel
    }
  }
  return rect;
}

