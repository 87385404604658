export enum ActionType {
    CLEAR_SELF_ORDER="CLEAR_SELF_ORDER",
    CLEAR_SELF_ORDERS="CLEAR_SELF_ORDERS"
}

interface IClearSelfOrder {
    type: ActionType.CLEAR_SELF_ORDER
}

interface IClearSelfOrders {
    type: ActionType.CLEAR_SELF_ORDERS
}

export type Action = IClearSelfOrder | IClearSelfOrders;