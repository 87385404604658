/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/event";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/event";
/// TYPES ///
import { IOrderEvent } from "api/types/event";

export const setOrderEvent = (event: IOrderEvent) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_ORDER_EVENT,
      event
    })
  }
}
