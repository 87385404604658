import { CornerData } from "api/types/sketch";
import { CornerNotch } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { getLineIntersection } from "functions/sketch/math";
import { getAngleOnSecondHalfCornerSeam } from "../seam";
import { constructNotchCorner } from "../../corner/construct";

export function drawSecondHalfCutNotchedCorner(
  path: Path2D,
  i: number,
  previous_location: Vector2,
  current_location: Vector2,
  next_location: Vector2,
  corner_length: number,
  corner_depth: number,
  zoom: number
): CornerData {
  const cut_angle: number = Math.round((getAngle(previous_location, current_location) + 90) * 100) / 100;
  const next_angle: number = Math.round(getAngle(current_location, next_location) + 90);
  const angle: number = getAngleOnSecondHalfCornerSeam(cut_angle, next_angle);
  const corner_notched: CornerNotch = constructNotchCorner(
    angle,
    current_location,
    next_angle,
    corner_length * zoom,
    corner_depth * zoom
  );

  const start_point: Vector2 = getLineIntersection(corner_notched.inner_corner, corner_notched.end_point, previous_location, current_location);
  if (i === 0) {
    path.moveTo(start_point.X, start_point.Y);
  }
  else {
    path.lineTo(start_point.X, start_point.Y);
  }

  path.lineTo(corner_notched.end_point.X, corner_notched.end_point.Y);

  return {
    first_point: start_point,
    last_point: corner_notched.end_point
  }
}
