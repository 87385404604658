/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
/// ACTIONS ///
import { getEvents } from "api/actions/event";
import { getOrderEvents } from "api/actions/event";
import { getSelfOrderEvents } from "api/actions/event";
/// TYPES ///
import { IGetEventArgs } from "api/types/event";
/// CUSTOM COMPONENTS ///
import CalendarDays from "./days";
/// DATETIME ///
import { DateTime } from "luxon";

export default function Calendar() {
  const dispatch = useAppDispatch();
  const { nav_date } = useAppSelector((state) => state.event);

  useEffect(() => {
    const args: IGetEventArgs = {
      "start_time": DateTime.fromISO(nav_date).startOf("month").toISO(),
      "end_time": DateTime.fromISO(nav_date).endOf("month").toISO()
    }

    dispatch(getEvents(args));
    dispatch(getOrderEvents(args));
    dispatch(getSelfOrderEvents(args));
  }, [dispatch, nav_date]);

  return (
    <CalendarDays />
  )
}
