import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/quote";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from "api/action_types/quote";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { Area } from "api/types/quote";
import { url_prefix } from "settings";
import { area_url } from "../endpoint";

export const updateArea = (area: Area, id?: number | string, local: boolean = true) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.QUOTE_UPDATING
    });

    if (local) {
      dispatch({
        type: ActionType.UPDATE_LOCAL_AREA,
        payload: area
      });

    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${area_url}/${id ? id : 0}/?uuid=${area.uuid}&`;

    try {
      const { data } = await axios.patch(url, area, config);

      dispatch({
        type: ActionType.QUOTE_ACTION_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.QUOTE_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
