/// HOOKS ///
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// TYPES ///
import { Quote } from "api/types/quote";
/// MUI COMPONENTS ///
import Box from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// CUSTOM COMPONENTS ///
import BaseButton from "components/universal/button/base";
/// DIALOGS ///
import EmailQuote from "./dialog/email";
import EmailSignatureRequest from "components/universal/dialog/signature";
/// ICONS ///
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { btn_box_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";
/// URLS ///
import { assign_url } from "components/navigation/endpoints";
import { detail_url } from "components/navigation/endpoints";
import { end_customer_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";

interface Props {
  quote: Quote
  base_url: string,
}

export default function StoreCustomerInfo({ quote, base_url }: Props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [open_send, setOpenSend] = useState<boolean>(false);
  const [open_signature, setOpenSignature] = useState<boolean>(false);

  return (
    <Grid container item xs={12} lg={6} alignItems="center">
      <Grid item xs={6}>
        <Typography variant="h6" sx={title_style} >
          Customer
        </Typography>
      </Grid>
      <Grid item xs={6} sx={btn_box_style}>
        <BaseButton text="Send" icon={faPaperPlane} clickEvent={() => setOpenSend(true)} />
        <BaseButton text="Sign" icon={faSignature} clickEvent={() => setOpenSignature(true)} />
        <BaseButton text="Assign" icon={faPlus} clickEvent={() => navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${assign_url}`)} />
        <BaseButton text="Edit" hide={!quote?.customer_data} icon={faEdit} clickEvent={() => navigate(`/${base_url}/${quote_url}/${detail_url}/${id}/${end_customer_url}/${quote?.customer}/${update_url}`)} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Name</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {`${quote?.customer_data?.first_name ?? ""} ${quote?.customer_data?.last_name ?? ""}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Address</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {quote?.customer_data?.address ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>City</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {quote?.customer_data?.city ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Province</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {quote?.customer_data?.province ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Postal Code</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {quote?.customer_data?.postal_code ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Email</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {quote?.customer_data?.email ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Phone Number</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {quote?.customer_data?.phone_number ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={item_style(false)}>
          <b>Notes</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={item_style(false)}>
          {quote?.customer_data?.entry_notes ?? ""}
        </Typography>
      </Grid>
      <EmailQuote open={open_send} setOpen={setOpenSend} />
      <EmailSignatureRequest
        open={open_signature}
        setOpen={setOpenSignature}
        default_email={quote?.customer_data?.email}
        name={`${quote?.customer_data?.first_name ?? ""} ${quote?.customer_data?.last_name ?? ""}`}
        customer={quote?.customer ?? null}
        quote_id={quote?.id ?? 0}
        exists={quote && quote.signature_image !== null && quote.signature_image !== undefined} />
    </Grid>
  )
}
