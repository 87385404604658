import { Quote } from "api/types/quote";
import { QuoteFilter } from "api/types/quote";

export enum ActionType {
  GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS",
  GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS",
  CREATE_QUOTE_SUCCESS = "CREATE_QUOTE_SUCCESS",
  UPDATE_QUOTE_SUCCESS = "UPDATE_QUOTE_SUCCESS",
  DELETE_QUOTE_SUCCESS = "DELETE_QUOTE_SUCCESS",
  UPDATE_LOCAL_QUOTE = "UPDATE_LOCAL_QUOTE",
  SEND_QUOTE_SUCCESS = "SEND_QUOTE_SUCCESS",
  CLEAR_QUOTES = "CLEAR_QUOTES",
  CLEAR_QUOTE = "CLEAR_QUOTE",
  MARK_QUOTE_CHANGE = "MARK_QUOTE_CHANGED",
  SET_QUOTE_FILTER = "SET_QUOTE_FILTER"
}

interface IClearQuotes {
  type: ActionType.CLEAR_QUOTES
}

interface IClearQuote {
  type: ActionType.CLEAR_QUOTE
}

interface IGetQuoteSuccess {
  type: ActionType.GET_QUOTE_SUCCESS,
  payload: Quote
}

interface IGetQuotesSuccess {
  type: ActionType.GET_QUOTES_SUCCESS,
  payload: Quote[]
}

interface ICreateQuoteSuccess {
  type: ActionType.CREATE_QUOTE_SUCCESS,
  payload: Quote
}

interface IUpdateQuoteSuccess {
  type: ActionType.UPDATE_QUOTE_SUCCESS,
  payload: Quote
}

interface IDeleteQuoteSuccess {
  type: ActionType.DELETE_QUOTE_SUCCESS,
  payload: number
}

interface IUpdateLocalQuote {
  type: ActionType.UPDATE_LOCAL_QUOTE,
  payload: Quote
}

interface ISendQuoteSuccess {
  type: ActionType.SEND_QUOTE_SUCCESS
}

interface IMarkQuoteChanged {
  type: ActionType.MARK_QUOTE_CHANGE
}

interface ISetQuoteFilter {
  type: ActionType.SET_QUOTE_FILTER,
  filter: QuoteFilter
}

export type Action =
  IClearQuotes |
  IClearQuote |
  IGetQuoteSuccess |
  IGetQuotesSuccess |
  ICreateQuoteSuccess |
  IUpdateQuoteSuccess |
  IDeleteQuoteSuccess |
  IUpdateLocalQuote |
  ISendQuoteSuccess |
  IMarkQuoteChanged |
  ISetQuoteFilter;
