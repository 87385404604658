/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";
import { row_header_style } from "styles/universal/row";

export default function MaterialHeader() {
  return (
    <Fragment>
      <Grid item xs={6} sx={{ ...row_header_style, paddingTop: "2px" }}>
        <Typography variant="body2" sx={item_style(false)}>
          <b>Material</b>
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ ...row_header_style, paddingTop: "2px" }}>
        <Typography variant="body2" sx={item_style(false)}>
          <b>Default Markup</b>
        </Typography>
      </Grid>
    </Fragment>
  )
}
