/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getFabricatorPriceList } from "api/actions/fabricator";
import { setBreadcrumbs } from "api/actions/ui/set";
import { updateFabricatorPriceList } from "api/actions/fabricator";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import ConfirmDisablePriceList from "./dialog/confirm_disable";
import DashboardTile from "components/universal/tiles/dashboard";
import PageTitle from "components/universal/page/title";
/// FA ICONS ///
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faStop } from "@fortawesome/free-solid-svg-icons";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { item_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// TYPE VALUES ///
import { getMaterialTypeDisplay } from "values/material";
/// URLS ///
import { installation_url } from "components/navigation/endpoints";
import { fabrication_url } from "components/navigation/endpoints";
import { miter_url } from "components/navigation/endpoints";
import { splash_url } from "components/navigation/endpoints";
import { waterfall_url } from "components/navigation/endpoints";
import { finished_url } from "components/navigation/endpoints";
import { appliance_url } from "components/navigation/endpoints";
import { clipped_url } from "components/navigation/endpoints";
import { notched_url } from "components/navigation/endpoints";
import { bumped_out_url } from "components/navigation/endpoints";
import { radius_url } from "components/navigation/endpoints";
import { inside_radius_url } from "components/navigation/endpoints";
import { recessed_diagonal_url } from "components/navigation/endpoints";
import { inside_diagonal_url } from "components/navigation/endpoints";
import { bump_in_url } from "components/navigation/endpoints";
import { bump_in_arc_url } from "components/navigation/endpoints";
import { bump_out_url } from "components/navigation/endpoints";
import { bump_out_arc_url } from "components/navigation/endpoints";
import { full_arc_url } from "components/navigation/endpoints";
import { cooktop_url } from "components/navigation/endpoints";
import { sink_url } from "components/navigation/endpoints";
import { faucet_url } from "components/navigation/endpoints";
import { outlet_url } from "components/navigation/endpoints";
import { removal_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { list } from "values/breadcrumbs/market/pl";
import { overview } from "values/breadcrumbs/market/pl";

export default function MarketPl() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const { id } = useParams();
  const { fabricator_price_list: fab_pl } = useAppSelector((state) => state.fabricator);

  useEffect(() => {
    if (id && (isNaN(Number(id)) === false)) {
      dispatch(getFabricatorPriceList(Number(id)));
    }
    dispatch(setBreadcrumbs([
      list,
      { ...overview, label: fab_pl?.price_list_data?.name ?? "Overview", url: overview.url.replace("id", id) }]));
  }, [dispatch, id, fab_pl?.price_list_data?.name]);

  const toggleActive = () => {
    if (fab_pl.id && fab_pl.enabled) {
      setOpen(true);
    }
    else if (!fab_pl.enabled) {
      dispatch(updateFabricatorPriceList({ enabled: true }, fab_pl.id));
    }
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title={fab_pl?.price_list_data?.name ?? ""} endProps={<BaseButton
          text={fab_pl && fab_pl.enabled ? "Disable" : "Enable"}
          icon={fab_pl && fab_pl.enabled ? faStop : faPlay}
          clickEvent={toggleActive} />
        } />
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
        {
          fab_pl && fab_pl.price_list_data && !fab_pl.price_list_data.status ?
            <Grid item xs={12}>
              <Typography variant="body2" color="error" sx={item_style()}>
                This price list has been disabled by Arris admin.
              </Typography>
            </Grid> :
            null
        }
        {
          fab_pl && !fab_pl.enabled ?
            <Grid item xs={12}>
              <Typography variant="body2" color="error" sx={item_style()}>
                You have disabled this market price List
              </Typography>
            </Grid> :
            null
        }
        <Grid item xs={6}>
          <Typography variant="body2" sx={item_style()}>
            <b>Region</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" align="right" sx={item_style()}>
            {fab_pl?.price_list_data?.region_data?.name ?? ""}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={item_style()}>
            <b>Material Type</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" align="right" sx={item_style()}>
            {getMaterialTypeDisplay(fab_pl?.price_list_data?.material_type ?? "")}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ marginBottom: "30px" }}>
          <Typography variant="body2" sx={item_style()}>
            <b>Status</b>
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ marginBottom: "30px" }}>
          <Typography variant="body2" align="right" sx={item_style()}>
            {!fab_pl.enabled ? "Disabled" : "Active"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <DashboardTile index={0} title="Installation" url={installation_url} />
            <DashboardTile index={1} title="Fabrication" url={fabrication_url} />
            <DashboardTile index={2} title="Countertop Removal" url={removal_url} />
          </Stack>
          <Stack sx={{ paddingTop: "10px" }}>
            <DashboardTile index={3} title="Finished Edges" url={finished_url} />
            <DashboardTile index={4} title="Appliance Edges" url={appliance_url} />
            <DashboardTile index={5} title="Miters" url={miter_url} />
            <DashboardTile index={6} title="Splashes" url={splash_url} />
            <DashboardTile index={7} title="Waterfall" url={waterfall_url} />
          </Stack>
          <Stack sx={{ paddingTop: "10px" }}>
            <DashboardTile index={8} title="Cooktop Cutouts" url={cooktop_url} />
            <DashboardTile index={9} title="Sink Cutouts" url={sink_url} />
            <DashboardTile index={10} title="Faucet Cutouts" url={faucet_url} />
            <DashboardTile index={11} title="Outlet Cutouts" url={outlet_url} />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <DashboardTile index={0} title="Clipped Corners" url={clipped_url} />
            <DashboardTile index={1} title="Notched Corners" url={notched_url} />
            <DashboardTile index={2} title="Bumped Out Corners" url={bumped_out_url} />
            <DashboardTile index={3} title="Radius Corners" url={radius_url} />
            <DashboardTile index={4} title="Inside Radius Corners" url={inside_radius_url} />
            <DashboardTile index={5} title="Recessed Diagonal Corners" url={recessed_diagonal_url} />
            <DashboardTile index={6} title="Inside Diagonal Corners" url={inside_diagonal_url} />
          </Stack>
          <Stack sx={{ paddingTop: "10px" }}>
            <DashboardTile index={7} title="Bump Ins" url={bump_in_url} />
            <DashboardTile index={8} title="Bump In Arcs" url={bump_in_arc_url} />
            <DashboardTile index={9} title="Bump Outs" url={bump_out_url} />
            <DashboardTile index={10} title="Bump Out Arcs" url={bump_out_arc_url} />
            <DashboardTile index={11} title="Full Arcs" url={full_arc_url} />
          </Stack>
        </Grid>
        <ConfirmDisablePriceList open={open} setOpen={setOpen} />
      </Grid>
    </motion.div >
  )
}
