/// TYPES ///
import { CutShape } from "api/types/sketch";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";

export function getCutShapeByCounterUuid(cut_shapes: CutShape[], counter_uuid: string): CutShape | null {
  for (let i = 0; i < cut_shapes.length; i++) {
    if (cut_shapes[i].counter_uuid === counter_uuid) {
      return cut_shapes[i];
    }
  }
  return null;
}

export function getCutShapesByCounterUuid(cut_shapes: CutShape[], counter_uuid: string): CutShape[] {
  const shapes: CutShape[] = [];
  for (let i = 0; i < cut_shapes.length; i++) {
    if (cut_shapes[i].counter_uuid === counter_uuid) {
      shapes.push(cut_shapes[i]);
    }
  }
  return shapes;
}

export function getShopCutShapesByCounterUuid(cut_shapes: IShopCutShape[], counter_uuid: string): IShopCutShape[] {
  const shapes: CutShape[] = [];
  for (let i = 0; i < cut_shapes.length; i++) {
    if (cut_shapes[i].counter_uuid === counter_uuid) {
      shapes.push(cut_shapes[i]);
    }
  }
  return shapes;
}

export function getCutShapeByUuid(cut_shapes: CutShape[], cut_shape_uuid: string): CutShape | null {
  for (let i = 0; i < cut_shapes.length; i++) {
    if (cut_shapes[i].uuid === cut_shape_uuid) {
      return cut_shapes[i];
    }
  }
  return null;
}
