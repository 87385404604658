/// TYPES ///
import { FabricatorQuote } from "api/types/market";
import { QuoteTotals } from "api/types/quote";

export default function useMarketQuoteTotals(quote: FabricatorQuote): QuoteTotals {
  function getSubtotal() {
    return quote?.total ?? 0;
  }

  function getTax1() {
    return quote?.tax ?? 0;
  }


  function getTax2() {
    return quote?.second_tax ?? 0;
  }

  return {
    subtotal: getSubtotal(),
    discount: 0,
    tax1: getTax1(),
    tax2: getTax2(),
  }
};
