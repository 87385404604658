import { Vector2 } from "api/types/sketch";
import { getDistantPoint } from "../../math";
import { toRadians } from "../../math";
import { makeAnglePositive } from "../../math";

export function constructMeasurementIndicator(location: Vector2, value: number, angle: number): Path2D {
    
    const path: Path2D = new Path2D();
    const length: number = (value / 4).toString.length + 1;
    let adjusted_angle: number = makeAnglePositive(angle + 90, false);
    adjusted_angle = adjusted_angle > 180 ? adjusted_angle - 180 : adjusted_angle;
    const horizontal_factor: number = Math.round(Math.abs(Math.cos(adjusted_angle)) * 100) / 100;
    
    const horizontal_adjust_location: Vector2 = getDistantPoint(location, angle - 90, 4 * length * Math.abs(Math.sin(toRadians(angle - 90))));
    const adjusted_location: Vector2 = getDistantPoint(horizontal_adjust_location, angle - 90, 10 * Math.abs(Math.sin(toRadians(angle))));
    const forward_start: Vector2 = getDistantPoint({X: adjusted_location.X, Y: adjusted_location.Y}, angle, length * 10 * horizontal_factor + 3);
    const forward_end: Vector2 = getDistantPoint({X: adjusted_location.X, Y: adjusted_location.Y}, angle, (value * 4 / 2) - 15);
    const forward_back_left: Vector2 = getDistantPoint(forward_end, angle - 135, 5);
    const forward_back_right: Vector2 = getDistantPoint(forward_end, angle + 135, 5);
    const backward_start: Vector2 = getDistantPoint({X: adjusted_location.X, Y: adjusted_location.Y}, angle - 180, length * 10 * horizontal_factor + 3);
    const backward_end: Vector2 = getDistantPoint({X: adjusted_location.X, Y: adjusted_location.Y}, angle - 180, (value * 4 / 2) - 15);
    const backward_back_left: Vector2 = getDistantPoint(backward_end, angle - 135 - 180, 5);
    const backward_back_right: Vector2 = getDistantPoint(backward_end, angle + 135 - 180, 5);
 
    path.moveTo(forward_start.X, forward_start.Y);
    path.lineTo(forward_end.X, forward_end.Y);
    path.lineTo(forward_back_left.X, forward_back_left.Y);
    path.moveTo(forward_end.X, forward_end.Y);
    path.lineTo(forward_back_right.X, forward_back_right.Y);
    path.moveTo(backward_start.X, backward_start.Y);
    path.lineTo(backward_end.X, backward_end.Y);
    path.lineTo(backward_back_left.X, backward_back_left.Y);
    path.moveTo(backward_end.X, backward_end.Y);
    path.lineTo(backward_back_right.X, backward_back_right.Y);
    return path;
}

export function constructWidthMeasurementIndicator(
    location: Vector2,
    value: number,
    angle: number
): Path2D {
    const path: Path2D = new Path2D();
    const length: number = (value / 4).toString.length + 1;
    const horizontal_factor: number = Math.round(Math.abs(Math.sin(toRadians(angle))));
    const forward_start: Vector2 = getDistantPoint({X: location.X, Y: location.Y}, angle, length * 10 * horizontal_factor);
    const forward_end: Vector2 = getDistantPoint({X: location.X, Y: location.Y}, angle, (value * 4 / 2) - 15);
    const forward_back_left: Vector2 = getDistantPoint(forward_end, angle - 135, 5);
    const forward_back_right: Vector2 = getDistantPoint(forward_end, angle + 135, 5);
    const backward_start: Vector2 = getDistantPoint({X: location.X, Y: location.Y}, angle - 180, length * 10);
    const backward_end: Vector2 = getDistantPoint({X: location.X, Y: location.Y}, angle - 180, (value * 4 / 2) - 15);
    const backward_back_left: Vector2 = getDistantPoint(backward_end, angle - 135 - 180, 5);
    const backward_back_right: Vector2 = getDistantPoint(backward_end, angle + 135 - 180, 5);

    path.moveTo(forward_start.X, forward_start.Y);
    path.lineTo(forward_end.X, forward_end.Y);
    path.lineTo(forward_back_left.X, forward_back_left.Y);
    path.moveTo(forward_end.X, forward_end.Y);
    path.lineTo(forward_back_right.X, forward_back_right.Y);
    path.moveTo(backward_start.X, backward_start.Y);
    path.lineTo(backward_end.X, backward_end.Y);
    path.lineTo(backward_back_left.X, backward_back_left.Y);
    path.moveTo(backward_end.X, backward_end.Y);
    path.lineTo(backward_back_right.X, backward_back_right.Y);

    return path;
}