/// HOOKS ///
import useQuoteTotals from "hooks/quote/totals";
/// TYPES ///
import { Area } from "api/types/quote";
import { IAddon } from "api/types/quote";
import { IShopAddon } from "api/types/shop_quote/addon";
import { IShopArea } from "api/types/shop_quote/area";
import { IShopQuote } from "api/types/shop_quote/quote";
import { Quote } from "api/types/quote";
import { QuoteTotals } from "api/types/quote";
/// REACT FRAGMENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// COMPONENTS ///
import AddonRow from "components/universal/quote/addon_row";
import BaseButton from "components/universal/button/base";
import CallbackDialog from "components/universal/callback_dialog";
import QuoteSummaryHeader from "components/universal/quote/summary_header";
import QuoteSummaryRow from "components/universal/quote/summary_row";
import ShopSaveAddon from "components/shop_quote/quote/dialog/addon/save";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";

interface Props {
  quote: Quote | IShopQuote,
  areas: Area[] | IShopArea[],
  addon: IAddon | IShopAddon,
  setAddon: (addon: IAddon | IShopAddon) => void,
  open_save: boolean,
  setOpenSave: (open: boolean) => void,
  open_delete: boolean,
  setOpenDelete: (open: boolean) => void,
  is_fitted: boolean,
  addAddon: () => void,
  openDeleteAddon: (addon: IAddon | IShopAddon) => void,
  editAddon: (addon: IAddon | IShopAddon) => void,
  deleteAddon: () => void,
  generateQuote: () => void
}

export default function QuoteSummary({
  quote,
  areas,
  is_fitted,
  addon,
  setAddon,
  open_save,
  setOpenSave,
  open_delete,
  setOpenDelete,
  addAddon,
  editAddon,
  openDeleteAddon,
  deleteAddon,
  generateQuote
}: Props) {
  const totals: QuoteTotals = useQuoteTotals(quote);

  if (quote?.changed) {
    return (
      <Stack>
        <Typography variant="h6" color="error" sx={{ marginTop: "20px" }}>
          Quote has been changed and must be priced again.
        </Typography>
        {
          is_fitted && quote?.customer_data && quote?.job_data ?
            <Typography variant="h6" color="primary" sx={{ cursor: "pointer" }} onClick={generateQuote}>
              Click here to get a new quote.
            </Typography> :
            null
        }
      </Stack>
    )
  }

  return (
    <Fragment>
      <Grid item xs={8} sx={{ marginTop: "20px" }}>
        <Typography variant="h6" sx={item_style(false)}>
          Summary
        </Typography>
      </Grid>
      <Grid item xs={4} sx={{ marginTop: "20px" }}>
        <Box display="flex" justifyContent="end">
          <BaseButton clickEvent={addAddon} text="Add Item" icon={faPlus} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      {
        areas.map((area: Area | IShopArea) =>
          <Fragment key={area.id}>
            <QuoteSummaryHeader name={area?.name ?? ""} />
            {area.line_items.map((item, index) => <QuoteSummaryRow key={index} item={item} />)}
          </Fragment>
        )
      }
      {
        quote?.addons?.length > 0 ?
          <Grid item xs={12}>
            <Typography variant="body2" sx={item_style()}>
              <b>Addons</b>
            </Typography>
          </Grid> :
          null
      }
      {
        quote?.addons?.map((add, index) => <AddonRow key={index} addon={add} editAddon={editAddon} deleteAddon={() => openDeleteAddon(add)} />)
      }
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>Subtotal</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${totals.subtotal.toFixed(2)}
        </Typography>
      </Grid>
      {
        quote?.discount ?
          <Fragment>
            <Grid item xs={8}>
              <Typography variant="body2" align="right" sx={item_style()}>
                <b>Discount %</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" align="right" sx={item_style()}>
                {(quote?.discount ?? 0).toFixed(2)}%
              </Typography>
            </Grid>
          </Fragment> : null
      }
      {
        quote?.discount && quote?.total ?
          <Fragment>
            <Grid item xs={8}>
              <Typography variant="body2" align="right" sx={item_style()}>
                <b>Discount</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" align="right" sx={item_style()}>
                ${totals.discount.toFixed(2)}
              </Typography>
            </Grid>
          </Fragment> : null
      }
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>{quote?.tax_name ?? "Tax"}</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${totals.tax1.toFixed(2)}
        </Typography>
      </Grid>
      {
        quote?.second_tax ?
          <Fragment>
            <Grid item xs={8}>
              <Typography variant="body2" align="right" sx={item_style()}>
                <b>{quote?.second_tax_name ?? "Tax2"}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" align="right" sx={item_style()}>
                ${totals.tax2.toFixed(2)}
              </Typography>
            </Grid>
          </Fragment> : null
      }
      <Grid item xs={8}>
        <Typography variant="body2" align="right" sx={item_style()}>
          <b>Total</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" align="right" sx={item_style()}>
          ${(totals.subtotal + totals.tax1 + totals.tax2 - totals.discount).toFixed(2)}
        </Typography>
      </Grid>
      <ShopSaveAddon quote={quote} open={open_save} setOpen={setOpenSave} item={addon} setItem={setAddon} />
      <CallbackDialog
        open={open_delete}
        close={() => setOpenDelete(false)}
        callback={deleteAddon}
        confirm_text="Delete"
        title="Delete Line Item"
        body={`Are you sure you wanted to delete ${addon.name} for $${(addon?.price ?? 0).toFixed(2)}?`} />
    </Fragment>
  )
}
