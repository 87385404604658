/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";

export default function ColorHeader() {
  return (
    <Fragment>
      <Grid item xs={4}>
        <Typography variant="body2" sx={item_style()}>
          <b>Color</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" sx={item_style()}>
          <b>Price</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" sx={item_style()}>
          <b>Markup</b>
        </Typography>
      </Grid>
    </Fragment>
  )
}
