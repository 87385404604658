import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "api/reducers";
import Socket from "socket";
import { socketMiddleware } from "socket/middleware";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketMiddleware(new Socket()))
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
