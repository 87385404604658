/// REACT ///
import { Fragment } from "react";
/// MUI ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// STYLES ///
import { row_header_style } from "styles/universal/row";
import { row_text_item_style } from "styles/universal/row";

export default function ColorOptsHeader() {
  return (
    <Fragment>
      <Grid item xs={3} sx={row_header_style}>
        <Typography variant="body2" sx={row_text_item_style}>
          <b>Area</b>
        </Typography>
      </Grid>
      <Grid item xs={6} sx={row_header_style}>
        <Typography variant="body2" sx={row_text_item_style}>
          <b>Color</b>
        </Typography>
      </Grid>
      <Grid item xs={3} sx={row_header_style}>
        <Typography variant="body2" align="right" sx={row_text_item_style}>
          <b>Subtotal</b>
        </Typography>
      </Grid>
    </Fragment>
  )
}

