/// AXIOS ///
import axios from "axios";
/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/authentication";
/// TYPES ///
import { User } from "api/types/authentication";
/// URLS ///
import { url_prefix } from "settings";
import { login_url } from "../endpoint";

export const login = (user: User) => {
  return async (dispatch: Dispatch<Action>) => {

    dispatch({
      type: ActionType.LOGIN_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    const url = `${url_prefix}${login_url}`;

    try {
      const { data } = await axios.post(url, user, config);

      dispatch({
        type: ActionType.LOGIN_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: ActionType.LOGIN_FAIL,
          payload: "The username/password is not correct"
        });
      }

    }
  }
}
