import { CornerData } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { drawSecondHalfPrintCutRadiusCorner } from "./radius_corner";
import { drawSecondHalfPrintCutInsideRadiusCorner } from "./inside_radius_corner";
import { drawSecondHalfPrintCutNotchedCorner } from "./notched_corner";
import { drawSecondHalfPrintCutClippedCorner } from "./clipped_corner";
import { drawSecondHalfPrintCutDiagonalCorner } from "./diagonal_corner";
import { drawSecondHalfPrintCutBumpoutCorner } from "./bumpout_corner";
import { drawSecondHalfPrintCutRecessedDiagonalCorner } from "./recessed_diagonal_corner";
import { drawPrintCutStandardCorner } from "../print";
import { RADIUS } from "values/quote";
import { INNER_RADIUS } from "values/quote";
import { NOTCH } from "values/quote";
import { BUMPOUT } from "values/quote";
import { CLIP } from "values/quote";
import { RECESSED_DIAGONAL } from "values/quote";
import { DIAGONAL } from "values/quote";

export function drawSecondHalfPrintCutCorner(
    i: number,
    path: Path2D,
    corner_type: string,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    zoom: number,
    radius: number,
    length: number,
    depth: number
): CornerData | null {
    let corner_data: CornerData | null = null;
    if(corner_type === RADIUS){
        corner_data = drawSecondHalfPrintCutRadiusCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            radius,
            zoom
        );
    }
    else if(corner_type === INNER_RADIUS){
        corner_data = drawSecondHalfPrintCutInsideRadiusCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            radius,
            zoom
        );
    }
    else if(corner_type === NOTCH){
        corner_data = drawSecondHalfPrintCutNotchedCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else if(corner_type === CLIP){
        corner_data = drawSecondHalfPrintCutClippedCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            zoom
        );
    }
    else if(corner_type === DIAGONAL){
        corner_data = drawSecondHalfPrintCutDiagonalCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            zoom
        );
    }
    else if(corner_type === BUMPOUT){
        corner_data = drawSecondHalfPrintCutBumpoutCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else if(corner_type === RECESSED_DIAGONAL){
        corner_data = drawSecondHalfPrintCutRecessedDiagonalCorner(
            path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else{
        corner_data = drawPrintCutStandardCorner(
            path,
            i,
            current_location
        )
    }
    return corner_data;
}
