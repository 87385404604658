/// TYPES ///
import { Area } from "api/types/quote";
import { Quote } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";
import { IShopQuote } from "api/types/shop_quote/quote";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import SummaryTotal from "./totals";
import QuoteSummaryArea from "./area";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";

interface Props {
  quote: Quote | IShopQuote,
  areas: Area[] | IShopArea[]
}

export default function QuoteSummary({ areas, quote }: Props) {
  return (
    <Grid container item xs={12} lg={6}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={title_style}>
          Quote Summary
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      {
        areas.map((area: Area | IShopArea) => <QuoteSummaryArea area={area} />)
      }
      <SummaryTotal quote={quote} />
    </Grid>
  )
}
