export const starter_width: number = 104;
export const area_drawer_width: number = 150;
export const area_drawer_padding: number = 25;

export enum Direction {
  NONE = "NONE",
  LEFT = "LEFT",
  UP = "UP",
  DOWN = "DOWN",
  RIGHT = "RIGHT"
} 
