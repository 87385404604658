/// MUI ///
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
/// ICONS ///
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from "@mui/icons-material/Delete";
/// TYPES ///
import { Vector2 } from "api/types/sketch";
/// STYLES ///
import { menuItemStyle } from "styles/menu";

interface Props {
  open: boolean,
  menu_location: Vector2,
  openEditLabel: () => void,
  duplicateLabel: () => void,
  deleteLabel: () => void
}

export default function LabelMenu(
  {
    open,
    menu_location,
    openEditLabel,
    duplicateLabel,
    deleteLabel
  }: Props
) {
  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{ top: menu_location.Y, left: menu_location.X }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={open}>
      <List>
        <ListItemButton onClick={openEditLabel} sx={menuItemStyle} id="menu_open_edit_label">
          <ListItemIcon id="menu_open_edit_label_icon">
            <UpdateIcon id="menu_open_edit_label_svg_icon" />
          </ListItemIcon>
          Change Label
        </ListItemButton>
        <ListItemButton onClick={duplicateLabel} sx={menuItemStyle} id="menu_dup_label">
          <ListItemIcon id="menu_dup_label_icon">
            <UpdateIcon id="menu_dup_label_svg_icon" />
          </ListItemIcon>
          Duplicate Label
        </ListItemButton>
        <ListItemButton onClick={deleteLabel} sx={menuItemStyle} id="menu_delete_label">
          <ListItemIcon id="menu_delete_label_icon">
            <DeleteIcon id="menu_delete_label_svg_icon" />
          </ListItemIcon>
          Delete Label
        </ListItemButton>
      </List>
    </Popover>
  )
}
