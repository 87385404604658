/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
/// FM ///
import { motion } from "framer-motion";
/// COMPONENTS ///
import BreadcrumbItem from "./item";
/// STYLES ///
import { breadcrumb_bar_style } from "styles/breadcrumb";

export default function Breadcrumbs() {
  const { breadcrumbs } = useAppSelector((state) => state.ui);

  if (breadcrumbs.length === 0) {
    return null;
  }

  return (
    <motion.div style={breadcrumb_bar_style}>
      {breadcrumbs.map((crumb, index) => <BreadcrumbItem key={index} crumb={crumb} last_item={index === breadcrumbs.length - 1} />)}
    </motion.div>
  )
}
