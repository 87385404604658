import { Vector2 } from "api/types/sketch";
import { getBottomLeftVertex } from "../../math";
import { getTopRightVertex } from "../../math";
import { getTopLeftVertex } from "../../math";
import { getBottomRightVertex } from "../../math";
import { isPointInRectangle } from "../../math";
import { pointInQuad } from "../../math";
import { getDistantPoint } from "../../math";
import { toRadians } from "../../math";
import { UNDERMOUNT } from "values/values";
import { FARMER } from "values/values";
import { DROP_IN } from "values/values";
import { FAUCET_RADIUS } from "./data";
import { FAUCET_BETWEEN_WIDTH } from "./data";
import { Start } from "./data";


export function createFaucets(path: Path2D, location: Vector2, angle: number, width: number, num_holes: number, start_side: string, zoom: number){
    let start_location: Vector2 = {X: 0, Y: 0};
    if(start_side === Start.CENTER){
        start_location = getDistantPoint(location, angle + 90, width / 2 * 4 * zoom + 8);
    }
    else if(start_side === Start.LEFT){

    }
    else if(start_side === Start.RIGHT){

    }

    const TO_NEXT: number = FAUCET_BETWEEN_WIDTH + FAUCET_RADIUS;
    const start: Vector2 = getDistantPoint(start_location, angle - 180, (TO_NEXT * (num_holes - 1) * zoom) / 2);
    
    for(let i = 0; i < num_holes; i++){
        createFaucet(path, getDistantPoint(start, angle, i * TO_NEXT * zoom), zoom);
    }
}

export function createFaucet(path: Path2D, center: Vector2, zoom: number){
    const adjust_faucet_radius: number = FAUCET_RADIUS * zoom;
    const circle_start: Vector2 = getDistantPoint(center, 90, adjust_faucet_radius);
    path.moveTo(circle_start.X, circle_start.Y);
    path.arc(center.X, center.Y, adjust_faucet_radius, 0, 2 * Math.PI);
}

export function createRectangle(
    path: Path2D,
    location: Vector2,
    angle: number,
    width: number,
    length: number,
    mouse: Vector2,
    zoom: number,
    has_selected: boolean,
    already_highlighted: boolean
): boolean {
    const start_point: Vector2 = getBottomLeftVertex(location, angle, width * 4 * zoom, length * 4 * zoom);
    const top_left: Vector2 = getTopLeftVertex(location, angle, width * 4 * zoom, length * 4 * zoom);
    const top_right: Vector2 = getTopRightVertex(location, angle, width * 4 * zoom, length * 4 * zoom);
    const bottom_right: Vector2 = getBottomRightVertex(location, angle, width * 4 * zoom, length * 4 * zoom);

    path.moveTo(start_point.X, start_point.Y);
    path.lineTo(top_left.X, top_left.Y);
    path.lineTo(top_right.X, top_right.Y);
    path.lineTo(bottom_right.X, bottom_right.Y);
    path.lineTo(start_point.X, start_point.Y);

    return !has_selected && !already_highlighted &&
    pointInQuad(
        start_point,
        top_left,
        top_right,
        bottom_right,
        mouse
    );
}

export function createOval(
    path: Path2D,
    location: Vector2,
    angle: number,
    width: number,
    length: number,
    mouse: Vector2,
    zoom: number,
    has_selected: boolean,
    already_highlighted: boolean
): boolean {
    const adjusted_length: number = length * 2 * zoom;
    const adjusted_width: number = width * 2 * zoom;
    const start_point: Vector2 = getDistantPoint(location, angle - 180, adjusted_length);
    path.moveTo(start_point.X, start_point.Y);
    path.ellipse(location.X, location.Y, adjusted_length, adjusted_width, toRadians(angle + 90), 0, 2 * Math.PI);

    return !has_selected && !already_highlighted &&
    isPointInRectangle(
        location,
        angle + 90,
        adjusted_length,
        adjusted_width,
        mouse
    );
}

export function createRectangleSink(
    path: Path2D,
    sink_type: string,
    location: Vector2,
    angle: number,
    width: number,
    length: number,
    faucet_hole_count: number,
    mouse: Vector2,
    zoom: number,
    has_selected: boolean,
    already_highlighted: boolean
): boolean {
    const highlight: boolean = createRectangle(path, location, angle, width, length, mouse, zoom, has_selected, already_highlighted);
    if(sink_type === UNDERMOUNT){
        createFaucet(path, getDistantPoint(location, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }
    else if(sink_type === DROP_IN){
        const faucet_location: Vector2 = getDistantPoint(location, angle + 90, width / 3);
        createRectangle(path, faucet_location, angle, width / 1.5, length / 1.15, mouse, zoom, has_selected, already_highlighted);
        createFaucet(path, location, zoom);
    }
    else if(sink_type === FARMER){
        createFaucet(path, getDistantPoint(location, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);

        const start_inset: Vector2 = getBottomLeftVertex(location, angle, (width - 2.25) * 4 * zoom, length * 4 * zoom);
        const end_inset: Vector2 = getTopLeftVertex(location, angle, (width - 2.25) * 4 * zoom, length * 4 * zoom);
        path.moveTo(start_inset.X, start_inset.Y);
        path.lineTo(end_inset.X, end_inset.Y);
    }

    return highlight;
}

export function createOvalSink(
    path: Path2D,
    sink_type: string,
    location: Vector2,
    angle: number,
    width: number,
    length: number,
    faucet_hole_count: number,
    mouse: Vector2,
    zoom: number,
    has_selected: boolean,
    already_highlighted: boolean
): boolean {
    const highlight: boolean = createOval(path, location, angle, width, length, mouse, zoom, has_selected, already_highlighted);
    if(sink_type === UNDERMOUNT){
        createFaucet(path, getDistantPoint(location, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }
    else if(sink_type === DROP_IN){
        const faucet_location: Vector2 = getDistantPoint(location, angle + 90, width / 3);
        createOval(path, faucet_location, angle, width / 1.5, length / 1.15, mouse, zoom, has_selected, already_highlighted);
        createFaucet(path, location, zoom);
    }

    return highlight;
}

export function createDoubleSink(
    path: Path2D,
    sink_type: string,
    location: Vector2,
    angle: number,
    width: number,
    length: number,
    faucet_hole_count: number,
    mouse: Vector2,
    zoom: number,
    has_selected: boolean,
    already_highlighted: boolean
): boolean {
    const highlight: boolean = createRectangle(path, location, angle, width, length, mouse, zoom, has_selected, already_highlighted);

    if(sink_type === UNDERMOUNT){
        const location_A: Vector2 = getDistantPoint(location, angle, length * zoom);
        const location_B: Vector2 = getDistantPoint(location, angle - 180, length * zoom);
        createRectangle(path, location_A, angle, width, length / 2, mouse, zoom, false, true);
        createRectangle(path, location_B, angle, width, length / 2, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }
    else if(sink_type === DROP_IN){
        const location_A: Vector2 = getDistantPoint(location, angle, length * zoom);
        const location_B: Vector2 = getDistantPoint(location, angle - 180, length * zoom);

        const A: Vector2 = getDistantPoint(location_A, angle + 90, width / 3);
        const B: Vector2 = getDistantPoint(location_B, angle + 90, width / 3);
        createRectangle(path, A, angle, width / 1.5, length / 2 / 1.15, mouse, zoom, false, true);
        createRectangle(path, B, angle, width / 1.5, length / 2 / 1.15, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(B, angle + 90, width * 0.4), zoom);
    }
    else if(sink_type === FARMER){
        let location_A: Vector2 = getDistantPoint(location, angle, (length + 2) * zoom);
        location_A = getDistantPoint(location_A, angle + 90, 2);
        let location_B: Vector2 = getDistantPoint(location, angle - 180, (length + 2) * zoom);
        location_B = getDistantPoint(location_B, angle + 90, 2);
        createRectangle(path, location_A, angle, (width - 1.5), (length / 2) - 1, mouse, zoom, false, true);
        createRectangle(path, location_B, angle, (width - 1.5), (length / 2) - 1, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }

    return highlight;
}

export function createSixtyFortySink(
    path: Path2D,
    sink_type: string,
    location: Vector2,
    angle: number,
    width: number,
    length: number,
    faucet_hole_count: number,
    mouse: Vector2,
    zoom: number,
    has_selected: boolean,
    already_highlighted: boolean
): boolean {
    const location_A: Vector2 = getDistantPoint(location, 0, 0);
    const location_B: Vector2 = getDistantPoint(location, 0, 0);
    const left_highlight: boolean = createRectangle(path, location_A, angle, width, length, mouse, zoom, has_selected, already_highlighted);
    const right_highlight: boolean = createRectangle(path, location_B, angle, width, length, mouse, zoom, has_selected, already_highlighted);

    if(sink_type === UNDERMOUNT){
        const location_A: Vector2 = getDistantPoint(location, angle, length * 0.85 * zoom);
        const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 1.1 * zoom);

        const A: Vector2 = getDistantPoint(location_A, angle + 90, width / 2);
        const B: Vector2 = getDistantPoint(location_B, angle + 90, -width / 2);
        createRectangle(path, location_A, angle, width * 0.9, length * 0.5, mouse, zoom, false, true);
        createRectangle(path, B, angle, width * 0.65, length * 0.4, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(B, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }
    else if(sink_type === DROP_IN){
        const location_A: Vector2 = getDistantPoint(location, angle, length * 0.85 * zoom);
        const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 1.1 * zoom);

        const A: Vector2 = getDistantPoint(location_A, angle + 90, width / 2);
        const B: Vector2 = getDistantPoint(location_B, angle + 90, -width / 2);
        createRectangle(path, location_A, angle, width * 0.9, length * 0.5, mouse, zoom, false, true);
        createRectangle(path, B, angle, width * 0.65, length * 0.4, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(B, angle + 90, width * 0.4), zoom);
    }
    else if(sink_type === FARMER){
        let location_A: Vector2 = getDistantPoint(location, angle, (length + 4) * zoom);
        location_A = getDistantPoint(location_A, angle + 90, 2);
        let location_B: Vector2 = getDistantPoint(location, angle - 180, (length - 2) * zoom);
        location_B = getDistantPoint(location_B, angle + 90, 2);
        createRectangle(path, location_A, angle, (width - 1.5), (length / 2) - 2, mouse, zoom, false, true);
        createRectangle(path, location_B, angle, (width - 1.5), (length / 2) + 1, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }

    return left_highlight || right_highlight;
}

export function createFortySixtySink(
    path: Path2D,
    sink_type: string,
    location: Vector2,
    angle: number,
    width: number,
    length: number,
    faucet_hole_count: number,
    mouse: Vector2,
    zoom: number,
    has_selected: boolean,
    already_highlighted: boolean
): boolean {
    const location_A: Vector2 = getDistantPoint(location, 0, 0);
    const location_B: Vector2 = getDistantPoint(location, 0, 0);
    const left_highlight: boolean = createRectangle(path, location_A, angle, width, length, mouse, zoom, has_selected, already_highlighted);
    const right_highlight: boolean = createRectangle(path, location_B, angle, width, length, mouse, zoom, has_selected, already_highlighted);

    if(sink_type === UNDERMOUNT){
        const location_A: Vector2 = getDistantPoint(location, angle, length * 1.1 * zoom);
        const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 0.85 * zoom);

        const A: Vector2 = getDistantPoint(location_A, angle - 90, width / 2);
        createRectangle(path, A, angle, width * 0.65, length * 0.4, mouse, zoom, false, true);
        createRectangle(path, location_B, angle, width * 0.9, length * 0.5, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }
    else if(sink_type === DROP_IN){
        const location_A: Vector2 = getDistantPoint(location, angle, length * 1.1 * zoom);
        const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 0.85 * zoom);

        const A: Vector2 = getDistantPoint(location_A, angle - 90, width / 2);
        createRectangle(path, A, angle, width * 0.65, length * 0.4, mouse, zoom, false, true);
        createRectangle(path, location_B, angle, width * 0.9, length * 0.5, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
    }
    else if(sink_type === FARMER){
        let location_A: Vector2 = getDistantPoint(location, angle, (length - 2) * zoom);
        location_A = getDistantPoint(location_A, angle + 90, 2);
        let location_B: Vector2 = getDistantPoint(location, angle - 180, (length + 4) * zoom);
        location_B = getDistantPoint(location_B, angle + 90, 2);
        createRectangle(path, location_A, angle, (width - 1.5), (length / 2) + 1, mouse, zoom, false, true);
        createRectangle(path, location_B, angle, (width - 1.5), (length / 2) - 2, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }

    return left_highlight || right_highlight;
}

export function createSeventyThirtySink(
    path: Path2D,
    sink_type: string,
    location: Vector2,
    angle: number,
    width: number,
    length: number,
    faucet_hole_count: number,
    mouse: Vector2,
    zoom: number,
    has_selected: boolean,
    already_highlighted: boolean
): boolean {
    const location_A: Vector2 = getDistantPoint(location, 0, 0);
    const location_B: Vector2 = getDistantPoint(location, 0, 0);
    const left_highlight: boolean = createRectangle(path, location_A, angle, width, length, mouse, zoom, has_selected, already_highlighted);
    const right_highlight: boolean = createRectangle(path, location_B, angle, width, length, mouse, zoom, has_selected, already_highlighted);

    if(sink_type === UNDERMOUNT){
        const location_A: Vector2 = getDistantPoint(location, angle, length * 0.65 * zoom);
        const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 1.25 * zoom);

        const A: Vector2 = getDistantPoint(location_A, angle + 90, width / 2);
        const B: Vector2 = getDistantPoint(location_B, angle + 90, -width / 2);
        createRectangle(path, location_A, angle, width * 0.9, length * 0.6, mouse, zoom, false, true);
        createRectangle(path, B, angle, width * 0.65, length * 0.3, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(B, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }
    else if(sink_type === DROP_IN){
        const location_A: Vector2 = getDistantPoint(location, angle, length * 0.65 * zoom);
        const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 1.25 * zoom);

        const A: Vector2 = getDistantPoint(location_A, angle + 90, width / 2);
        const B: Vector2 = getDistantPoint(location_B, angle + 90, -width / 2);
        createRectangle(path, location_A, angle, width * 0.9, length * 0.6, mouse, zoom, false, true);
        createRectangle(path, B, angle, width * 0.65, length * 0.3, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(B, angle + 90, width * 0.4), zoom);
    }
    else if(sink_type === FARMER){
        let location_A: Vector2 = getDistantPoint(location, angle, (length + 8) * zoom);
        location_A = getDistantPoint(location_A, angle + 90, 2);
        let location_B: Vector2 = getDistantPoint(location, angle - 180, (length - 6) * zoom);
        location_B = getDistantPoint(location_B, angle + 90, 2);
        createRectangle(path, location_A, angle, (width - 1.5), (length / 2) - 4, mouse, zoom, false, true);
        createRectangle(path, location_B, angle, (width - 1.5), (length / 2) + 3, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }

    return left_highlight || right_highlight;
}

export function createThirtySeventySink(
    path: Path2D,
    sink_type: string,
    location: Vector2,
    angle: number,
    width: number,
    length: number,
    faucet_hole_count: number,
    mouse: Vector2,
    zoom: number,
    has_selected: boolean,
    already_highlighted: boolean
): boolean {
    const location_A: Vector2 = getDistantPoint(location, 0, 0);
    const location_B: Vector2 = getDistantPoint(location, 0, 0);
    const left_highlight: boolean = createRectangle(path, location_A, angle, width, length, mouse, zoom, has_selected, already_highlighted);
    const right_highlight: boolean = createRectangle(path, location_B, angle, width, length, mouse, zoom, has_selected, already_highlighted);

    if(sink_type === UNDERMOUNT){
        const location_A: Vector2 = getDistantPoint(location, angle, length * 1.25 * zoom);
        const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 0.65 * zoom);

        const A: Vector2 = getDistantPoint(location_A, angle - 90, width / 2);
        createRectangle(path, A, angle, width * 0.65, length * 0.3, mouse, zoom, false, true);
        createRectangle(path, location_B, angle, width * 0.9, length * 0.6, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }
    else if(sink_type === DROP_IN){
        const location_A: Vector2 = getDistantPoint(location, angle, length * 1.25 * zoom);
        const location_B: Vector2 = getDistantPoint(location, angle - 180, length * 0.65 * zoom);

        const A: Vector2 = getDistantPoint(location_A, angle - 90, width / 2);
        createRectangle(path, A, angle, width * 0.65, length * 0.3, mouse, zoom, false, true);
        createRectangle(path, location_B, angle, width * 0.9, length * 0.6, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
    }
    else if(sink_type === FARMER){
        let location_A: Vector2 = getDistantPoint(location, angle, (length - 6) * zoom);
        location_A = getDistantPoint(location_A, angle + 90, 2);
        let location_B: Vector2 = getDistantPoint(location, angle - 180, (length + 8) * zoom);
        location_B = getDistantPoint(location_B, angle + 90, 2);
        createRectangle(path, location_A, angle, (width - 1.5), (length / 2) + 3, mouse, zoom, false, true);
        createRectangle(path, location_B, angle, (width - 1.5), (length / 2) - 4, mouse, zoom, false, true);
        createFaucet(path, getDistantPoint(location_A, angle + 90, width * 0.4), zoom);
        createFaucet(path, getDistantPoint(location_B, angle + 90, width * 0.4), zoom);
        createFaucets(path, location, angle, width, faucet_hole_count, Start.CENTER, zoom);
    }

    return left_highlight || right_highlight;
}