/// TYPES ///
import { IShopErrors } from "api/types/shop";
import { User } from "api/types/authentication";

export function checkGeo(user: User): IShopErrors {
  let errors: IShopErrors = {};

  if (user.radius && isNaN(user.radius)) {
    errors.radius_error = "Enter a valid number";
    errors.radius_has_error = true;
  }

  if (user.mileage_rate && isNaN(user.mileage_rate)) {
    errors.mil_rate_error = "Enter a valid number";
    errors.mil_rate_has_error = true;
  }

  if ((user.lat || user.lng || user.geo_address) && (isNaN(user.lat) || isNaN(user.lng) || user.lat === 0 || user.lng === 0)) {
    errors.geo_error = "Geo location not found for this address"
    errors.geo_has_error = true;
  }

  return errors;
}
