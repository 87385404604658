import { Cooktop } from "api/types/sketch";

export enum ActionType {
    ADD_LOCAL_COOKTOP="ADD_LOCAL_COOKTOP",
    UPDATE_LOCAL_COOKTOP="UPDATE_LOCAL_COOKTOP",
    UPDATE_AND_MOVE_COOKTOP="UPDATE_AND_MOVE_COOKTOP",
    DELETE_LOCAL_COOKTOP="DELETE_LOCAL_COOKTOP",
}

interface addLocalCooktop {
    type: ActionType.ADD_LOCAL_COOKTOP,
    payload: Cooktop
}

interface updateLocalCooktop {
    type: ActionType.UPDATE_LOCAL_COOKTOP,
    payload: Cooktop
}

interface updateAndMoveCooktop {
    type: ActionType.UPDATE_AND_MOVE_COOKTOP,
    payload: Cooktop,
    old_counter_uuid: string
}

interface deleteLocalCooktop {
    type: ActionType.DELETE_LOCAL_COOKTOP,
    payload: Cooktop
}

export type Action =
    addLocalCooktop |
    updateLocalCooktop |
    updateAndMoveCooktop |
    deleteLocalCooktop;