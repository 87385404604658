export const QUARTZ = "Q";
export const NATURAL_STONE = "N";
export const ULTRA_COMPACT = "U";

export function getMaterialTypeDisplay(material_type: string | undefined) {
  if (!material_type) {
    return "";
  }

  switch (material_type) {
    case QUARTZ:
      return "Quartz";
    case NATURAL_STONE:
      return "Natural Stone";
    case ULTRA_COMPACT:
      return "Ultra Compact";
    default:
      return "";
  }
}

export const CM2 = "2";
export const CM3 = "3";
export const PORCELAIN = "P";
export const CM12 = "12"
export const CM1 = "1";
export const CM60 = "06";
export const CM08 = "08";
export const MM6 = "M6";
export const MM8 = "M8";
export const MM12 = "M12";
export const MM20 = "20";
export const MM30 = "30";

export function getMaterialHeightDisplay(material_thickness: string | undefined) {
  if (!material_thickness) {
    return "";
  }

  switch (material_thickness) {
    case CM2:
      return "2CM";
    case CM3:
      return "3CM";
    case PORCELAIN:
      return "Porcelain";
    case CM12:
      return "1.2CM"
    case CM1:
      return "1CM";
    case CM60:
      return "0.6CM";
    case CM08:
      return "0.8CM";
    case MM6:
      return "6MM";
    case MM8:
      return "8MM";
    case MM12:
      return "12MM";
    case MM20:
      return "20MM";
    case MM30:
      return "30MM";
    default:
      return "";
  }
}
