/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { changePassword } from "api/actions/authentication";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { ChangePassword } from "api/types/authentication";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { Tooltip } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// URLS ///
import { profile_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { profile } from "values/breadcrumbs/profile";
import { change_password } from "values/breadcrumbs/profile";

export default function ProfilePassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { error } = useAppSelector((state) => state.authentication);
  const [change_pass, setChangePass] = useState<ChangePassword>({ old: "", pass: "", pass2: "''" });
  const [show_old, setShowOld] = useState<boolean>(false);
  const [show_pass, setShowPass] = useState<boolean>(false);
  const [show_pass2, setShowPass2] = useState<boolean>(false);
  const [local_error, setLocalError] = useState<string>("");

  useEffect(() => {
    dispatch(setBreadcrumbs([profile, change_password]));
  }, [dispatch]);

  useEffect(() => {
    //TODO clear error, loading, pass change success
  }, []);

  const save = () => {
    if (change_pass.pass.length < 8) {
      setLocalError("Password must be longer than 8 characters");
      return;
    }

    if (change_pass.pass !== change_pass.pass2) {
      setLocalError("Passwords do not match.");
      return;
    }

    setLocalError("");

    dispatch(changePassword(change_pass));
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start">
        <Grid container item xs={12} lg={6}>
          <PageTitle title="Update Profile" endProps={
            <Box>
              <BaseButton text="Cancel" icon={faXmark} clickEvent={() => navigate(`/${profile_url}`)} />
              <BaseButton text="Save" icon={faSave} clickEvent={save} />
            </Box>
          } />
        </Grid>
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <TextField
            type={show_old ? "text" : "password"}
            onChange={e => setChangePass({ ...change_pass, old: e.target.value })}
            label="Old Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={`${show_old ? "Hide" : "Show"} Old Password`}>
                    <IconButton size="small" onClick={() => setShowOld(prev => !prev)}>
                      <FontAwesomeIcon icon={show_old ? faEyeSlash : faEye} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }} />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <TextField
            error={(local_error && local_error !== "") || (error && error !== "")}
            helperText={error ? error : local_error}
            type={show_pass ? "text" : "password"}
            onChange={e => setChangePass({ ...change_pass, pass: e.target.value })}
            label="New Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={`${show_pass ? "Hide" : "Show"} Password`}>
                    <IconButton size="small" onClick={() => setShowPass(prev => !prev)}>
                      <FontAwesomeIcon icon={show_pass ? faEyeSlash : faEye} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }} />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <TextField
            type={show_pass2 ? "text" : "password"}
            onChange={e => setChangePass({ ...change_pass, pass2: e.target.value })}
            label="Confirm New Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={`${show_pass2 ? "Hide" : "Show"} Confirm Password`}>
                    <IconButton size="small" onClick={() => setShowPass2(prev => !prev)}>
                      <FontAwesomeIcon icon={show_pass2 ? faEyeSlash : faEye} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }} />
        </Grid>
      </Grid>
    </motion.div >

  )
}
