import { useEffect } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { updateLocalUser } from "api/actions/authentication";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Steppers from "components/universal/stepper";
import { payment_setup_steps } from "values/steps";
import {DateTime} from "luxon";
import { mainContentAreaStyle } from "styles/style";
  
export default function SubscriptionSetupConfirmation() {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.authentication);
    const date = DateTime.now().plus({months: 1});

    useEffect(() => {
        dispatch(updateLocalUser({...user, payment_initialized: true, subscription_active: true}));
    }, []);

    return (
        <Box sx={mainContentAreaStyle}>
            <Grid container sx={{width: "100%", paddingLeft: 3.5, paddingRight: 3.5}}>
                <Grid item xs={10}>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <b>Thank You</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{marginTop: 1}}>
                                Quoting functions are available, but marketplace will not send you orders until the account is verified. You will be contacted in 24-72 hours to fully activate this account.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{marginTop: 1}}>
                                Your first billing date will be {`${date.toLocaleString(DateTime.DATE_MED)}`}.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Steppers current_step={3} steps={payment_setup_steps}/>
                </Grid>
            </Grid>
        </Box>
    );
}