import { Order } from "api/types/order";

export enum ActionType {
  GET_WOS_SUCCESS = "GET_WOS_SUCCESS",
  GET_WO_SUCCESS = "GET_WO_SUCCESS",
}

interface IGetWOsSuccess {
  type: ActionType.GET_WOS_SUCCESS,
  payload: Order[]
}

interface IGetWOSuccess {
  type: ActionType.GET_WO_SUCCESS,
  payload: Order
}

export type Action = IGetWOsSuccess | IGetWOSuccess;
