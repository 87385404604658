/// MUI ///
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
/// ICONS ///
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
/// TYPES ///
import { Vector2 } from "api/types/sketch";
/// STYLES ///
import { menuItemStyle } from "styles/menu";

interface Props {
  open: boolean,
  menu_location: Vector2,
  changeArea: () => void,
  deleteCounter: () => void,
  duplicateCounter: () => void,
  rotate: (rotation: number) => void
}

export default function SketchMenu(
  {
    open,
    menu_location,
    changeArea,
    deleteCounter,
    duplicateCounter,
    rotate
  }: Props
) {
  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{ top: menu_location.Y, left: menu_location.X }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={open}>
      <List>
        <ListItemButton onClick={changeArea} sx={menuItemStyle} id="menu_area">
          <ListItemIcon id="menu_area_icon">
            <RotateLeftIcon id="menu_area_svg_icon" />
          </ListItemIcon>
          Change Area
        </ListItemButton>
        <ListItemButton onClick={() => rotate(-22.5)} sx={menuItemStyle} id="menu_rotate_left" onTouchStart={() => rotate(-22.5)}>
          <ListItemIcon id="menu_rotate_left_icon">
            <RotateLeftIcon id="menu_rotate_left_svg_icon" />
          </ListItemIcon>
          Rotate Left
        </ListItemButton>
        <ListItemButton onClick={() => rotate(22.5)} sx={menuItemStyle} id="menu_rotate_right" onTouchStart={() => rotate(22.5)}>
          <ListItemIcon id="menu_rotate_right_icon">
            <RotateRightIcon id="menu_rotate_right_svg_icon" />
          </ListItemIcon>
          Rotate Right
        </ListItemButton>
        <ListItemButton onClick={duplicateCounter} sx={menuItemStyle} id="menu_duplicate" onTouchStart={duplicateCounter}>
          <ListItemIcon id="menu_duplicate">
            <ContentCopyIcon id="menu_duplicate_icon" />
          </ListItemIcon>
          Duplicate Counter
        </ListItemButton>
        <ListItemButton onClick={deleteCounter} sx={menuItemStyle} id="menu_delete" onTouchStart={deleteCounter}>
          <ListItemIcon id="menu_delete_icon">
            <DeleteIcon id="menu_delete_svg_icon" />
          </ListItemIcon>
          Delete Counter
        </ListItemButton>
      </List>
    </Popover>
  )
}
