/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateFabricatorPriceList } from "api/actions/fabricator";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void
}

export default function ConfirmDisablePriceList({ open, setOpen }: Props) {
  const dispatch = useAppDispatch();
  const { fabricator_price_list } = useAppSelector((state) => state.fabricator);
  const { loading } = useAppSelector((state) => state.fabricator);

  const handleArchive = () => {
    if (fabricator_price_list && fabricator_price_list.id) {
      dispatch(updateFabricatorPriceList({ enabled: false }, fabricator_price_list.id));
    }
    setOpen(false);
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Disable Price List</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <FormControl fullWidth>
          <Typography variant="body2">
            Are you sure you want to disable this price list? You will not be able to receive any orders from this region for these materials. You are still responsible for fulfilling any outstanding orders in the system.
          </Typography>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {
          loading ?
            <CircularProgress /> :
            <Fragment>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button onClick={handleArchive}>Disable</Button>
            </Fragment>
        }
      </DialogActions>
    </Dialog>
  )
}
