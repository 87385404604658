import { IShopAddon } from "api/types/shop_quote/addon";

export enum ActionType {
  CREATE_SHOP_ADDON = "CREATE_SHOP_ADDON",
  UPDATE_SHOP_ADDON = "UPDATE_SHOP_ADDON",
  DELETE_SHOP_ADDON = "DELETE_SHOP_ADDON"
}

interface ICreateShopAddon {
  type: ActionType.CREATE_SHOP_ADDON,
  addon: IShopAddon
}

interface IUpdateShopAddon {
  type: ActionType.UPDATE_SHOP_ADDON,
  addon: IShopAddon
}

interface IDeleteShopAddon {
  type: ActionType.DELETE_SHOP_ADDON,
  addon: IShopAddon
}

export type Action = ICreateShopAddon | IUpdateShopAddon | IDeleteShopAddon;
