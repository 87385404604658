import { Feedback } from "api/types/feedback";

export enum ActionType {
  CREATE_FEEDBACK_SUCCESS = "CREATE_FEEDBACK_SUCCESS",
  FEEDBACK_PENDING = "FEEDBACK_PENDING",
  FEEDBACK_FAIL = "FEEDBACK_FAIL"
}

interface createFeedbackSuccess {
  type: ActionType.CREATE_FEEDBACK_SUCCESS,
  payload: Feedback
}

interface feedbackPending {
  type: ActionType.FEEDBACK_PENDING
}

interface feedbackFail {
  type: ActionType.FEEDBACK_FAIL,
  payload: string
}

export type Action =
  createFeedbackSuccess |
  feedbackPending |
  feedbackFail;
