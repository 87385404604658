import { Dispatch } from "redux";
import { Action } from "api/action_types/billing";
import { ActionType } from "api/action_types/billing";

export const setProduct = (product_id: string, price_id: string) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_PRODUCT,
            product_id: product_id,
            price_id: price_id
        });
    }
}

export const setUpdateSuccess = (success: string) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_UPDATE_SUCCESS,
            payload: success
        });
    }
}

export const setUpdateCancel = (error: string) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_UPDATE_FAIL,
            payload: error
        });
    }
}

export const setBillingCustomerCreated = (created: boolean) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_BILLING_CUSTOMER_CREATED,
            payload: created
        })
    }
}    