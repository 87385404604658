/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/sketch";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/sketch";
/// TYPES ///
import { CutShape } from "api/types/sketch";

export const updateLocalCutShapesByArea = (cut_shapes: CutShape[], area_uuid: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_CUT_SHAPES_BY_AREA,
            payload: cut_shapes,
            area_uuid: area_uuid
        })
    }
}
