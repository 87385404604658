import { Vector2 } from "api/types/sketch";
import { getDistance } from "functions/sketch/math";
import { getAngle } from "functions/sketch/math";

export function getDirection(dimensions: Vector2): number{
    if(
        Math.abs(dimensions.X) < 
        Math.abs(dimensions.Y)
    ){
        if(dimensions.Y < 0){
            return 0;
        }
        else{
            return 180;
        }
    }
    else{
        if(dimensions.X < 0){
            return 270;
        }
        else{
            return 90;
        }
    }
}

export function getBranchAngle(point: Vector2, last_angle: number, last_width: number, mouse: Vector2): number | null {
    const magnitude: number = getDistance(point, mouse);
    if(magnitude < last_width){
        return null;
    }
    return Math.round(getAngle(point, mouse)) + 90;
}