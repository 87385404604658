/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopPoint } from "api/types/shop_quote/point";
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopCooktop } from "api/types/shop_quote/cooktop";
import { IShopSink } from "api/types/shop_quote/sink";
import { Counter } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { Point } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { Cooktop } from "api/types/sketch";
import { Sink } from "api/types/sketch";
/// FUNCTIONS ///
import { getDistance } from "../math";
import { getDistantPoint } from "../math";
import { getAngle } from "../math";

function getFirstPoint(first_point: Vector2, axis: Vector2, delta_angle: number): Vector2 {
  const angle: number = getAngle(axis, first_point);
  const distance: number = getDistance(axis, first_point);
  const new_angle: number = angle + delta_angle + 90;
  return getDistantPoint(axis, new_angle, distance);
}

export function rotateCounter(counter: Counter, angle: number, axis: Vector2): Counter {
  const distances: number[] = [];
  const points: Point[] = [...counter.points];
  for (let i = 0; i < points.length - 1; i++) {
    const point: Point = { ...points[i] };
    const next_point: Point = points[i + 1];

    distances.push(getDistance(point.location, next_point.location));
    point.angle = (point.angle + angle) % 360;
    points[i] = point;
  }

  points[0].location = getFirstPoint(points[0].location, axis, angle);

  for (let i = 1; i < points.length; i++) {
    const last_point: Point = points[i - 1];
    const point: Point = { ...points[i] };
    point.location = getDistantPoint(last_point.location, last_point.angle, distances[i - 1]);
    points[i] = point;
  }

  const cooktops: Cooktop[] = []
  const sinks: Sink[] = [];

  for (let i = 0; i < counter.cooktops.length; i++) {
    const cooktop: Cooktop = { ...counter.cooktops[i] };
    cooktop.location = getFirstPoint(cooktop.location, axis, angle);
    cooktop.angle += angle;
    cooktops.push(cooktop);
  }

  for (let i = 0; i < counter.sinks.length; i++) {
    const sink: Sink = { ...counter.sinks[i] };
    sink.location = getFirstPoint(sink.location, axis, angle);
    sink.angle += angle;
    sinks.push(sink);
  }

  const corners: Corner[] = [];

  corners.push({ ...counter.corners[0], location: getFirstPoint(counter.corners[0].location, axis, angle) });

  for (let i = 1; i < counter.corners.length; i++) {
    const original_corner: Corner = counter.corners[i];
    const last_corner: Corner = counter.corners[i - 1] ? counter.corners[i - 1] : counter.corners[counter.corners.length - 1];
    const last_new_corner: Corner = { ...corners[i - 1] };
    const distance: number = getDistance(last_corner.location, original_corner.location);
    const line_angle: number = getAngle(last_corner.location, original_corner.location) + 90;
    const new_corner_location: Vector2 = getDistantPoint(last_new_corner.location, line_angle + angle, distance);
    corners.push({ ...counter.corners[i], location: new_corner_location });
  }

  return { ...counter, points: points, corners: corners, cooktops: cooktops, sinks: sinks };
}

export function rotateShopCounter(counter: IShopCounter, angle: number, axis: Vector2): IShopCounter {
  const distances: number[] = [];
  const points: IShopPoint[] = [...counter.points];
  for (let i = 0; i < points.length - 1; i++) {
    const point: IShopPoint = { ...points[i] };
    const next_point: IShopPoint = points[i + 1];

    distances.push(getDistance(point.location, next_point.location));
    point.angle = (point.angle + angle) % 360;
    points[i] = point;
  }

  points[0].location = getFirstPoint(points[0].location, axis, angle);

  for (let i = 1; i < points.length; i++) {
    const last_point: IShopPoint = points[i - 1];
    const point: IShopPoint = { ...points[i] };
    point.location = getDistantPoint(last_point.location, last_point.angle, distances[i - 1]);
    points[i] = point;
  }

  const cooktops: IShopCooktop[] = []
  const sinks: IShopSink[] = [];

  for (let i = 0; i < counter.cooktops.length; i++) {
    const cooktop: IShopCooktop = { ...counter.cooktops[i] };
    cooktop.location = getFirstPoint(cooktop.location, axis, angle);
    cooktop.angle += angle;
    cooktops.push(cooktop);
  }

  for (let i = 0; i < counter.sinks.length; i++) {
    const sink: Sink = { ...counter.sinks[i] };
    sink.location = getFirstPoint(sink.location, axis, angle);
    sink.angle += angle;
    sinks.push(sink);
  }

  const corners: Corner[] = [];

  corners.push({ ...counter.corners[0], location: getFirstPoint(counter.corners[0].location, axis, angle) });

  for (let i = 1; i < counter.corners.length; i++) {
    const original_corner: IShopCorner = counter.corners[i];
    const last_corner: IShopCorner = counter.corners[i - 1] ? counter.corners[i - 1] : counter.corners[counter.corners.length - 1];
    const last_new_corner: IShopCorner = { ...corners[i - 1] };
    const distance: number = getDistance(last_corner.location, original_corner.location);
    const line_angle: number = getAngle(last_corner.location, original_corner.location) + 90;
    const new_corner_location: Vector2 = getDistantPoint(last_new_corner.location, line_angle + angle, distance);
    corners.push({ ...counter.corners[i], location: new_corner_location });
  }

  return { ...counter, points: points, corners: corners, cooktops: cooktops, sinks: sinks };
}
