/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopLabel } from "api/types/shop_quote/label";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import DiagramCounterAreas from "./counter";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";

interface Props {
  areas: IShopArea[],
  counters: IShopCounter[],
  labels: IShopLabel[],
  counters_loaded: boolean
}

export default function DiagramShopAreaCounters({ areas, counters, labels, counters_loaded }: Props) {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={title_style}>
          Counters
        </Typography>
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
      </Grid>
      {areas.map(area => {
        return (
          <Grid key={area.id} container item xs={12} sx={{ padding: "5px" }} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2" color="primary" sx={item_style(false)}>
                <b>{area.name}:</b> {area?.material_name ?? ""} {area?.color_name ?? ""} {area?.material_thickness ?? ""}
              </Typography>
            </Grid>
            <DiagramCounterAreas
              area_uuid={area.uuid}
              counters={
                counters.filter(count => count.area_uuid === area.uuid)
              }
              labels={labels.filter(label => label.area_uuid === area.uuid)}
              counters_loaded={counters_loaded} />
          </Grid>
        )
      })}
    </Grid>
  )
}
