/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS //
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopBump } from "api/types/shop_quote/bump";

export const createLocalShopBump = (bump: IShopBump, corner_uuid: string, counter_uuid: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CREATE_LOCAL_SHOP_BUMP,
      payload: bump,
      corner_uuid: corner_uuid,
      counter_uuid: counter_uuid
    });
  }
}
