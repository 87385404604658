import { SelfOrder } from "api/types/order";

export enum ActionType {
  SORT_SHOP_ORDER = "SORT_SHOP_ORDER"
}

interface ISortShopOrder {
  type: ActionType.SORT_SHOP_ORDER,
  orders: SelfOrder[]
}

export type Action = ISortShopOrder;
