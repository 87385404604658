/// TYPES ///
import { CSSProperties } from "react";
import { Variants } from "framer-motion";

export const loading_container_style: CSSProperties = {
  position: "fixed",
  bottom: 50,
  right: 80,
  width: "60px",
  height: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  boxShadow: "0 0 10px 5px rgba(0, 99, 243, 0.3)",
}

export const loading_page_style: CSSProperties = {
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "10px"
}

export const loading_ico_motion: Variants = {
  show: {
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    }
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 2,
      type: "tween",
      ease: "easeOut"
    },
    transitionEnd: {
      display: "none"
    }
  },
  initial: {
    opacity: 0,
  }
}
