/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopCutShape } from "api/types/shop_quote/cut_shape";

export const setSelectedShopCutShape = (cut_shape: IShopCutShape | null) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SELECTED_SHOP_CUT_SHAPE,
      payload: cut_shape
    });
  }
}
