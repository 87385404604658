/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// MUI ///
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
/// TYPES ///
import { IOrderEvent } from "api/types/event";
/// URLS ///
import { event_url } from "components/navigation/endpoints";
import { market_url } from "components/navigation/endpoints";

interface Props {
  event: IOrderEvent
}

export default function OrderEventBarItem({ event }: Props) {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(`/${event_url}/${market_url}/${event?.order}`)} sx={{ display: "flex", justifyContent: "flex-start", paddingLeft: 0, paddingRight: 0 }}>
      <Typography variant="body2" sx={{ textTransform: "none" }}>{event?.title}</Typography>
    </Button>
  )
}
