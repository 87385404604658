import type { Action as AreaAction } from "./area";
import type { Action as ClearAction } from "./clear";
import type { Action as CounterAction } from "./counter";
import type { Action as ErrorAction } from "./error";
import type { Action as LoadingAction } from "./loading";
import type { Action as MessageAction } from "./message";
import type { Action as SelfOrderAction } from "./self_order";
import type { Action as SlabAction } from "./slab";
import type { Action as SortAction } from "./sort";

import { ActionType as AreaActionType } from "./area";
import { ActionType as ClearActionType } from "./clear";
import { ActionType as CounterActionType } from "./counter";
import { ActionType as ErrorActionType } from "./error";
import { ActionType as LoadingActionType } from "./loading";
import { ActionType as MessageActionType } from "./message";
import { ActionType as SelfOrderActionType } from "./self_order";
import { ActionType as SlabActionType } from "./slab";
import { ActionType as SortActionType } from "./sort";

export const ActionType = {
    ...AreaActionType,
    ...ClearActionType,
    ...CounterActionType,
    ...ErrorActionType,
    ...LoadingActionType,
    ...MessageActionType,
    ...SortActionType,
    ...SelfOrderActionType,
    ...SlabActionType
}

export type Action =
    AreaAction |
    ClearAction |
    CounterAction |
    ErrorAction |
    LoadingAction |
    MessageAction |
    SortAction |
    SelfOrderAction |
    SlabAction;

