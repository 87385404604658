import { Area } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";

export function checkQuoteLineItemErrors(areas: Area[] | IShopArea[]): boolean {
  for (let i = 0; i < areas.length; i++) {
    for (let j = 0; j < areas[i].line_items.length; j++) {
      if (areas[i].line_items[j].price_found === false) {
        return true;
      }
    }
  }
  return false;
}
