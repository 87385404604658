import { useRef } from "react";
import { useEffect } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { setHistoryWarning } from "api/actions/history/local/warning";
import Snackbar from "@mui/material/Snackbar";

interface Props {
  current_time: number
}

export default function TimedSnackbar({ current_time }: Props) {
  const dispatch = useAppDispatch();
  const { history_warning_active } = useAppSelector((state) => state.history);
  const { current_description } = useAppSelector((state) => state.history);

  useInterval(() => {
    const time = Math.round(new Date().getTime() / 1000);
    if (time - current_time > 3) {
      dispatch(setHistoryWarning(false));
    }

  }, 1000);

  function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef<() => void>();
    useEffect(() => {
      if (savedCallback) {
        savedCallback.current = callback;
      }
    }, [callback]);

    useEffect(() => {
      function tick() {
        if (savedCallback && savedCallback.current) {
          savedCallback.current();
        }
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  return (
    <Snackbar
      open={history_warning_active}
      message={current_description} />
  );
}
