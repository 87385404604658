import { OrderCounter } from "api/types/order";
import { SelfOrderCounter } from "api/types/order";
import { OrderCorner } from "api/types/order/order";
import { Text } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { CornerData } from "api/types/sketch";
import { drawOrderPoints } from "./points";
import { getAngle } from "functions/sketch/math";
import { getDistance } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { needsEdge } from "../counter";
import { constructMeasurementIndicator } from "functions/sketch/create";
import { drawCorner } from "../corner";
import { drawOrderBumps } from "../bumps";
import { edgeText } from "../text";
import { measurementText } from "../text";

export function drawOrderCounter(
  counter: OrderCounter | SelfOrderCounter,
  path: Path2D,
  edge_path: Path2D,
  measurement_path: Path2D,
  texts: Text[],
  center: Vector2,
  zoom: number
) {
  drawOrderPoints(counter, measurement_path, texts, zoom, center);
  drawOrderLines(
    counter.corners,
    path,
    edge_path,
    measurement_path,
    texts,
    center,
    zoom
  );
}

function drawOrderLines(
  corners: OrderCorner[],
  path: Path2D,
  edge_path: Path2D,
  measurement_path: Path2D,
  texts: Text[],
  center: Vector2,
  zoom: number
) {
  let first_point: Vector2 | null = null;
  for (let i = 0; i < corners.length; i++) {
    const previous_corner: OrderCorner = corners[i - 1] ? corners[i - 1] : corners[corners.length - 1];
    const corner: OrderCorner = corners[i];
    const next_corner: OrderCorner = corners[i + 1] ? corners[i + 1] : corners[0];
    const previous_location: Vector2 = {
      X: Math.round(previous_corner.location.X * zoom) + center.X,
      Y: Math.round(previous_corner.location.Y * zoom) + center.Y
    };
    const current_location: Vector2 = {
      X: Math.round(corner.location.X * zoom) + center.X,
      Y: Math.round(corner.location.Y * zoom) + center.Y
    };
    const next_location: Vector2 = {
      X: Math.round(next_corner.location.X * zoom) + center.X,
      Y: Math.round(next_corner.location.Y * zoom) + center.Y
    }
    const angle: number = Math.round(getAngle(current_location, next_location) + 90);
    const distance: number = Math.round(getDistance(current_location, next_location));
    const midway_point: Vector2 = getDistantPoint(current_location, angle, distance / 2);
    const is_end: boolean = i === corners.length - 1 || i === Math.floor((corners.length - 1) / 2);
    const current_needs_edge: boolean = needsEdge(corner.edge_type);

    const measurement_distance: number = getDistance(corner.location, next_corner.location);
    const text: Text = measurementText(
      midway_point,
      distance,
      angle,
      measurement_distance,
      "",
      "",
      "",
      is_end ? false : true,
      i > Math.floor((corners.length - 1) / 2)
    );

    if (text.real_value > 16) {
      const indicator_path: Path2D = constructMeasurementIndicator(
        midway_point,
        text.real_value,
        text.angle
      );

      measurement_path.addPath(indicator_path);
    }
    text.text = `${text.text}`;
    texts.push(text);

    const edge_text: Text = edgeText(
      midway_point,
      distance,
      angle,
      corner.edge_type,
      "",
      "",
      "",
      corner.splash_height,
      corner.finished_edge_type,
      true
    )
    texts.push(edge_text);

    const corner_data: CornerData | null = drawCorner(
      i,
      path,
      edge_path,
      corner.corner_type,
      previous_location,
      current_location,
      next_location,
      previous_corner.edge_type,
      zoom,
      corner.corner_radius,
      corner.corner_length,
      corner.corner_depth
    );

    if (corner_data) {
      if (corner_data.first_point) {
        first_point = corner_data.first_point;
      }
    }

    // DRAW BUMP //
    drawOrderBumps(
      path,
      edge_path,
      corner.bumps,
      corner_data ? corner_data.last_point : current_location,
      next_location,
      angle - 90,
      zoom,
      current_needs_edge
    );
  }

  if (first_point) {
    if (!needsEdge(corners[corners.length - 1].edge_type)) {
      path.lineTo(first_point.X, first_point.Y);
    }
    else {
      edge_path.lineTo(first_point.X, first_point.Y);
    }
  }
}
