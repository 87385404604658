export const slider_marks = [
  {
    value: -30,
    label: "-30%"
  },
  {
    value: 0,
    label: "0%"
  },
  {
    value: 30,
    label: "30%"
  }
]
