/// HOOKS ///
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useDrawDiagramCounters } from "hooks/draw/diagram_counters";
/// TYPES ///
import { Counter } from "api/types/sketch";
import { Label } from "api/types/sketch";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopLabel } from "api/types/shop_quote/label";
import { Vector2 } from "api/types/sketch";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
/// VALUE FUNCTIONS ///
import { getCanvas } from "functions/sketch/context";
import { getDiagramDimensions } from "functions/sketch/get/diagram";
/// STLYES ///
import { counter_container_style } from "styles/quote_preview/sketch";

interface Props {
  area_uuid: string,
  counters: Counter[] | IShopCounter[],
  labels: Label[] | IShopLabel[],
  counters_loaded: boolean
}

export default function DiagramCounterAreas({ area_uuid, counters, labels, counters_loaded }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [have_drawing_dimensions, setHaveDrawingDimensions] = useState<boolean>(false);
  const [load_drawing, setLoadDrawing] = useState<boolean>(false);
  const [drawing_dimensions, setDrawingDimensions] = useState<Vector2>({ X: 0, Y: 0 });
  const [dimensions, setDimensions] = useState<Vector2>({ X: 0, Y: 0 });

  useDrawDiagramCounters(area_uuid, counters, labels, dimensions, {}, counters_loaded && have_drawing_dimensions && load_drawing);

  useEffect(() => {
    setDrawingDimensions(getDiagramDimensions(counters));
    setHaveDrawingDimensions(true);
  }, [counters]);

  useEffect(() => {
    if (ref && ref.current && have_drawing_dimensions) {
      const height: number = ref.current.clientHeight;
      const width: number = ref.current.clientWidth;

      const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById(`diagram_ac_${area_uuid}`));

      if (canvas) {
        canvas.width = width;
        canvas.height = height;
      }
      setDimensions({ X: width, Y: height });
      setLoadDrawing(true);
    }
  }, [ref, counters.length, area_uuid, have_drawing_dimensions]);

  if (!counters || !(counters.length > 0)) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Box sx={counter_container_style(drawing_dimensions.Y)} ref={ref}>
        <canvas id={`diagram_ac_${area_uuid}`} />
      </Box>
    </Grid>
  )

}
