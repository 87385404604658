/// TYPES ///
import { Area } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";

export function containsColorOption(areas: Area[] | IShopArea[]): boolean {
  for (let i = 0; i < areas.length; i++) {
    if (areas[i].color_options?.length > 0) {
      return true;
    }
  }
  return false;
}
