/// TYPES ///
import { Area } from "api/types/quote";
import { Counter } from "api/types/sketch";
import { Label } from "api/types/sketch";
/// COMPONENTS ///
import QuotePrintArea from "./area";
/// PDF COMPONENTS ///
import { View } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
/// STYLES ///
import { print_header } from "styles/print";
import { subtitle } from "styles/print";
/// VALUES ///
import { getDiagramDimensions } from "functions/sketch/get/diagram";

interface Props {
  areas: Area[],
  counters: Counter[],
  labels: Label[]
}

export default function QuotePrintAreas({ areas, counters, labels }: Props) {
  return (
    <View style={{ marginTop: "25px" }} break>
      <View style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <Text style={{ ...subtitle, textTransform: "uppercase", marginBottom: "2px" }}>
          Counters
        </Text>
      </View>
      <View style={print_header}></View>
      {
        areas.map((area, index) => {
          return (
            <QuotePrintArea
              key={index}
              index={index}
              area={area}
              labels={labels}
              counters={counters.filter(c => c.area_uuid === area.uuid)}
              dimensions={getDiagramDimensions(counters.filter(c => c.area_uuid === area.uuid))} />
          )
        })
      }
    </View>
  );
}
