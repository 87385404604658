import { primary_color } from "./style";

export const print_header = {
  backgroundColor: primary_color,
  height: "11px",
  width: "100%",
  marginBottom: "5px",
  marginTop: "10px"
}

export const subtitle = {
  fontFamily: "Montserrat",
  fontSize: 10.8,
  fontWeight: 600
}
