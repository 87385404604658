/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopPoint } from "api/types/shop_quote/point";
import { IShopCorner } from "api/types/shop_quote/corner";
/// FUNCTIONS ///
import { createShopCorners } from "../create/shop_corner";
import { clockwise } from "../math";
import { getDistance } from "../math";
import { getDistantPoint } from "../math";

export function updateShopAngle(
  counter: IShopCounter,
  point_uuid: string,
  relative_angle: number
): IShopCounter {
  let delta_angle: number = 0;
  let angle_modified: boolean = false;

  const distances: number[] = [];
  const points: IShopPoint[] = [...counter.points];

  for (let j = 0; j < points.length - 1; j++) {
    const last_point: IShopPoint = points[j - 1];
    const point: IShopPoint = { ...points[j] };
    const next_point: IShopPoint = points[j + 1];
    distances.push(getDistance(point.location, next_point.location));

    if (j !== 0 && j !== points.length - 1 && point_uuid === point.uuid) {
      const is_clockwise: boolean = clockwise(last_point.angle, point.angle);
      const new_angle = is_clockwise ? last_point.angle + relative_angle : last_point.angle - relative_angle;
      delta_angle = new_angle - point.angle;
      angle_modified = true;
      point.angle = new_angle;
      points[j] = point;
      continue;
    }

    if (angle_modified) {
      point.angle = point.angle + delta_angle;
      points[j] = point;
    }
  }

  for (let j = 1; j < points.length; j++) {
    const last_point: IShopPoint = points[j - 1];
    const point: IShopPoint = { ...points[j] };
    point.location = getDistantPoint(last_point.location, last_point.angle, distances[j - 1]);
    points[j] = point;
  }

  const corners: IShopCorner[] = createShopCorners(points, [...counter.corners], counter.uuid);

  return { ...counter, points: points, corners: corners };
}
