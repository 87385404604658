/// TYPES ///
import { QuoteLineItem } from "api/types/quote";
import { OrderLineItem } from "api/types/order";
import { SelfOrderLineItem } from "api/types/order";
/// MUI COMPONENTS ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";

interface Props {
  item: QuoteLineItem | OrderLineItem | SelfOrderLineItem,
  show_qty?: boolean
}

export default function QuoteSummaryRow({ item, show_qty = false }: Props) {
  return (
    <Grid item xs={12}>
      <Typography variant="body2" sx={item_style()}>
        {item?.name ?? ""}
      </Typography>
    </Grid>
  )
}
