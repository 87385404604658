/// REACT ///
import { ReactNode } from "react";
/// MUI COMPONENTS ///
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
/// STLYES ///
import { act_btn_style } from "styles/universal/button";
import { act_btn_icon_style } from "styles/universal/button";

interface Props {
  text: string,
  icon: IconDefinition,
  tooltip: string,
  disabled?: boolean,
  hide?: boolean,
  children?: ReactNode,
  clickEvent?: () => void,
  label?: boolean,
}

export default function TooltipButton({ text, tooltip, icon, clickEvent, disabled = false, hide = false, children, label = false }: Props) {
  if (hide) {
    return null;
  }

  return (
    <Tooltip title={tooltip}>
      <Button sx={act_btn_style} disabled={disabled} onClick={clickEvent} component={label ? "label" : "button"} endIcon={<FontAwesomeIcon icon={icon} style={act_btn_icon_style} />}>
        {text}
        {children}
      </Button>
    </Tooltip>
  )
}
