/// EVENT ///
import { ChangeEvent } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { updateProfile } from "api/actions/authentication";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { User } from "api/types/authentication";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { item_style } from "styles/quote_preview/section";
/// URLS ///
import { org_url } from "components/navigation/endpoints";
/// VALIDATION ///
import { JSONIsEmpty } from "functions/value";
/// BREADCRUMBS ///
import { org } from "values/breadcrumbs/organization";
import { org_update } from "values/breadcrumbs/organization";

export default function OrgUpdate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    loading,
    user
  } = useAppSelector((state) => state.authentication);
  const [name, setName] = useState<string>("");
  const [logo, setLogo] = useState<File | null>(null);
  const [logo_icon, setLogoIcon] = useState<File | null>(null);
  const [logo_path, setLogoPath] = useState<string | null>(null);
  const [logo_icon_path, setLogoIconPath] = useState<string | null>(null);

  useEffect(() => {
    dispatch(setBreadcrumbs([org, org_update]));
  }, [dispatch]);

  const changeLogo = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      setLogo(target.files[0]);
      setLogoPath(URL.createObjectURL(target.files[0]));
    };
  }

  const changeLogoIcon = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      setLogoIcon(target.files[0]);
      setLogoIconPath(URL.createObjectURL(target.files[0]));
    };
  }

  const save = () => {
    const updates: User = {};

    if (logo) {
      updates.logo = logo;
    }

    if (logo_icon) {
      updates.logo_icon = logo_icon;
    }

    if (name !== "") {
      updates.business_name = name;
    }


    if (!JSONIsEmpty(updates)) {
      dispatch(updateProfile(updates, user.id));
    }
    navigate(`/${org_url}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start">
        <Grid container item xs={12} lg={6}>
          <PageTitle title="Organization" endProps={
            <Box>
              <BaseButton text="Cancel" icon={faXmark} clickEvent={() => navigate(`/${org_url}`)} />
              <BaseButton text="Save" icon={faSave} clickEvent={save} />
            </Box>
          } />
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "15px" }}>
            <TextField
              defaultValue={user.business_name}
              onChange={e => setName(e.target.value)}
              label="Business Name" />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "15px" }}>
            <BaseButton text="Change Logo" icon={faFileImage} label={true}>
              <input type="file" hidden name="image" onChange={e => changeLogo(e)} />
            </BaseButton>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "15px" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {
                logo_path || user.logo ?
                  <img
                    style={{ objectFit: "contain" }}
                    src={logo_path ? logo_path : user.logo as string}
                    width="200px"
                    height="100px"
                    alt="Logo" /> :
                  <Typography variant="body2" align="right" sx={item_style(false)}>
                    Logo Not Found
                  </Typography>
              }
            </div>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "15px" }}>
            <BaseButton text="Change Logo Icon" icon={faFileImage} label={true}>
              <input type="file" hidden name="logo_icon" onChange={e => changeLogoIcon(e)} />
            </BaseButton>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "15px" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {
                logo_icon_path || user.logo_icon ?
                  <img
                    style={{ objectFit: "contain" }}
                    src={logo_icon_path ? logo_icon_path : user.logo_icon as string}
                    width="40px"
                    height="40px"
                    alt="Logo Icon" /> :
                  <Typography variant="body2" align="right" sx={item_style(false)}>
                    Logo Icon Not Found
                  </Typography>
              }
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Loading loading={loading} />
    </motion.div>
  )
}
