/// HOOKS ///
import { useEffect } from "react";
import { useState } from "react";
/// DATE TIME ///
import { DateTime } from "luxon";

export function useGetDate(date: string): DateTime {
  const [date_time, setDateTime] = useState<DateTime>(DateTime.now());

  useEffect(() => {
    setDateTime(DateTime.fromISO(date).toLocal());
  }, [date]);

  return date_time;
}
