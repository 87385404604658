/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// TYPES ///
import { FilterEndCustomer } from "api/types/customer";
/// ACTIONS ///
import { filterCustomers } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { TextField } from "@mui/material";
/// PAGE COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { end_customer_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { cx_list } from "values/breadcrumbs/customer";
import { cx_filter } from "values/breadcrumbs/customer";

export default function FilterCustomers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<FilterEndCustomer>({});

  const applyFilter = () => {
    dispatch(filterCustomers(filter));
    navigate(`/${end_customer_url}`);
  }

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      applyFilter();
    }
  }

  useEffect(() => {
    dispatch(setBreadcrumbs([cx_list, cx_filter]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style} onKeyDown={keyDown}>
      <Grid container>
        <PageTitle title="Filter Customers" endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${end_customer_url}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={() => setFilter({})} text="Clear" icon={faEraser} />
            <BaseButton clickEvent={applyFilter} text="Apply" icon={faFilter} />
          </Box>
        } />
        <Grid item xs={12}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <Stack direction="row" spacing={1}>
              <TextField
                onChange={e => setFilter({ ...filter, first_name: e.target.value })}
                value={filter?.first_name ?? ""}
                label="First Name" />
              <TextField
                onChange={e => setFilter({ ...filter, last_name: e.target.value })}
                value={filter?.last_name ?? ""}
                label="Last Name" />
            </Stack>
            <TextField
              onChange={e => setFilter({ ...filter, address: e.target.value })}
              value={filter?.address ?? ""}
              label="Address" />
            <TextField
              onChange={e => setFilter({ ...filter, city: e.target.value })}
              value={filter?.city ?? ""}
              label="City" />
            <TextField
              onChange={e => setFilter({ ...filter, email: e.target.value })}
              value={filter?.email ?? ""}
              label="Email" />
            <TextField
              onChange={e => setFilter({ ...filter, phone: e.target.value })}
              value={filter?.phone ?? ""}
              label="Phone Number" />
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}
