import { CornerData } from "api/types/sketch";
import { CornerBumpOut } from "api/types/sketch";
import { CornerClip } from "api/types/sketch";
import { CornerNotch } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { CornerRadius } from "api/types/sketch";
import { CornerRecessedDiagonal } from "api/types/sketch";
import { CornerDiagonal } from "api/types/sketch";
import { InwardCornerRadius } from "api/types/sketch";
import { constructBumpoutCorner } from "./construct";
import { constructClipCorner } from "./construct";
import { constructDiagonalCorner } from "./construct";
import { constructInwardRadiusCorner } from "./construct";
import { constructNotchCorner } from "./construct";
import { constructRadiusCorner } from "./construct";
import { constructRecessedDiagonalCorner } from "./construct";
import { getAngle } from "functions/sketch/math";
import { makeAnglePositive } from "functions/sketch/math";
import { describeArc } from "functions/sketch/path";
import { describeArcWithPoints } from "functions/sketch/path";
import { needsEdge } from "../counter/edge";
import { RADIUS } from "values/quote";
import { BUMPOUT } from "values/quote";
import { CLIP } from "values/quote";
import { NOTCH } from "values/quote";
import { INNER_RADIUS } from "values/quote";
import { DIAGONAL } from "values/quote";
import { RECESSED_DIAGONAL } from "values/quote";

export function drawCorner(
    i: number,
    path: Path2D,
    edge_path: Path2D,
    corner_type: string,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    previous_edge_type: string,
    zoom: number,
    radius: number,
    length: number,
    depth: number
): CornerData | null {
    let corner_data: CornerData | null = null;
    const needs_edge: boolean = needsEdge(previous_edge_type);
    if(corner_type === RADIUS){
        corner_data = drawRadiusCorner(
            path,
            edge_path,
            i,
            previous_location,
            current_location,
            next_location,
            radius,
            zoom,
            needs_edge
        );
    }
    else if(corner_type === INNER_RADIUS){
        corner_data = drawInsideRadiusCorner(
            path,
            edge_path,
            i,
            previous_location,
            current_location,
            next_location,
            radius,
            zoom,
            needs_edge
        );
    }
    else if(corner_type === NOTCH){
        corner_data = drawNotchedCorner(
            path,
            edge_path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom,
            needs_edge
        );
    }
    else if(corner_type === CLIP){
        corner_data = drawClippedCorner(
            path,
            edge_path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            zoom,
            needs_edge
        );
    }
    else if(corner_type === DIAGONAL){
        corner_data = drawDiagonalCorner(
            path,
            edge_path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            zoom,
            needs_edge
        );
    }
    else if(corner_type === BUMPOUT){
        corner_data = drawBumpoutCorner(
            path,
            edge_path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom,
            needs_edge
        );
    }
    else if(corner_type === RECESSED_DIAGONAL){
        corner_data = drawRecessedDiagonalCorner(
            path,
            edge_path,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom,
            needs_edge
        );
    }
    else{
        corner_data = drawStandardCorner(
            path,
            edge_path,
            i,
            current_location,
            needs_edge
        )
    }
    
    return corner_data;
}

export function drawRadiusCorner(
    path: Path2D,
    edge_path: Path2D,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    radius: number,
    zoom: number,
    needs_edge: boolean
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_radius: CornerRadius = constructRadiusCorner(
        angle,
        current_location,
        next_angle,
        radius * zoom
    );
    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_radius.start_point;
        path.moveTo(corner_radius.start_point.X, corner_radius.start_point.Y);
        edge_path.moveTo(corner_radius.start_point.X, corner_radius.start_point.Y);
    }
    else{
        if(!needs_edge){
            path.lineTo(corner_radius.start_point.X, corner_radius.start_point.Y);
        }
        else{
            edge_path.lineTo(corner_radius.start_point.X, corner_radius.start_point.Y);
        }
    }

    if(!needs_edge){
        path.arc(
            corner_radius.center_point.X, 
            corner_radius.center_point.Y, 
            corner_radius.radius,
            corner_radius.start_angle,
            corner_radius.end_angle
        );
        edge_path.moveTo(corner_radius.end_point.X, corner_radius.end_point.Y);
    }
    else{
        edge_path.arc(
            corner_radius.center_point.X, 
            corner_radius.center_point.Y, 
            corner_radius.radius,
            corner_radius.start_angle,
            corner_radius.end_angle
        );
        path.moveTo(corner_radius.end_point.X, corner_radius.end_point.Y);
    }
    

    return {
        first_point: first_point,
        last_point: corner_radius.end_point
    }
}

export function drawInsideRadiusCorner(
    path: Path2D,
    edge_path: Path2D,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    radius: number,
    zoom: number,
    needs_edge: boolean
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_radius: InwardCornerRadius = constructInwardRadiusCorner(
        angle,
        current_location,
        next_angle,
        radius * zoom
    );
    let first_point: Vector2 | null = null;
    
    if(i === 0){
        first_point = corner_radius.start_point;
        path.moveTo(corner_radius.start_point.X, corner_radius.start_point.Y);
        edge_path.moveTo(corner_radius.start_point.X, corner_radius.start_point.Y);
    }
    else{
        if(!needs_edge){
            path.lineTo(corner_radius.start_point.X, corner_radius.start_point.Y);
        }
        else{
            edge_path.lineTo(corner_radius.start_point.X, corner_radius.start_point.Y);
        }
    }

    if(!needs_edge){
        path.arc(
            corner_radius.center_point.X, 
            corner_radius.center_point.Y, 
            corner_radius.radius, 
            corner_radius.start_angle,
            corner_radius.end_angle,
            true
        );
        edge_path.moveTo(corner_radius.end_point.X, corner_radius.end_point.Y);
    }
    else{
        edge_path.arc(
            corner_radius.center_point.X, 
            corner_radius.center_point.Y, 
            corner_radius.radius, 
            corner_radius.start_angle,
            corner_radius.end_angle,
            true
        );
        path.moveTo(corner_radius.end_point.X, corner_radius.end_point.Y);
    }
    
    
    return {
        first_point: first_point,
        last_point: corner_radius.end_point
    }
}

export function drawNotchedCorner(
    path: Path2D,
    edge_path: Path2D,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number,
    needs_edge: boolean
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_notched: CornerNotch = constructNotchCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom,
        corner_depth * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_notched.start_point;
        path.moveTo(corner_notched.start_point.X, corner_notched.start_point.Y);
        edge_path.moveTo(corner_notched.start_point.X, corner_notched.start_point.Y);
    }
    else{
        if(!needs_edge){
            path.lineTo(corner_notched.start_point.X, corner_notched.start_point.Y);
        }
        else{
            edge_path.lineTo(corner_notched.start_point.X, corner_notched.start_point.Y);
        }
        
    }

    if(!needs_edge){
        path.lineTo(corner_notched.inner_corner.X, corner_notched.inner_corner.Y);
        path.lineTo(corner_notched.end_point.X, corner_notched.end_point.Y);
        edge_path.moveTo(corner_notched.end_point.X, corner_notched.end_point.Y);
    }
    else{
        edge_path.lineTo(corner_notched.inner_corner.X, corner_notched.inner_corner.Y);
        edge_path.lineTo(corner_notched.end_point.X, corner_notched.end_point.Y);
        path.moveTo(corner_notched.end_point.X, corner_notched.end_point.Y);
    }

    return {
        first_point: first_point,
        last_point: corner_notched.end_point
    }
}

export function drawClippedCorner(
    path: Path2D,
    edge_path: Path2D,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    zoom: number,
    needs_edge: boolean
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_clip: CornerClip = constructClipCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_clip.start_point;
        path.moveTo(corner_clip.start_point.X, corner_clip.start_point.Y)
        edge_path.moveTo(corner_clip.start_point.X, corner_clip.start_point.Y);
    }
    else{
        if(!needs_edge){
            path.lineTo(corner_clip.start_point.X, corner_clip.start_point.Y);
        }
        else{
            edge_path.lineTo(corner_clip.start_point.X, corner_clip.start_point.Y);
        }
    }

    if(!needs_edge){
        path.lineTo(corner_clip.end_point.X, corner_clip.end_point.Y);
        edge_path.moveTo(corner_clip.end_point.X, corner_clip.end_point.Y);
    }
    else{
        edge_path.lineTo(corner_clip.end_point.X, corner_clip.end_point.Y);
        path.moveTo(corner_clip.end_point.X, corner_clip.end_point.Y);
    }
    
    return {
        first_point: first_point,
        last_point: corner_clip.end_point
    }
}

export function drawDiagonalCorner(
    path: Path2D,
    edge_path: Path2D,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    zoom: number,
    needs_edge: boolean
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_diagonal: CornerDiagonal = constructDiagonalCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom
    );
    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_diagonal.start_point;
        path.moveTo(corner_diagonal.start_point.X, corner_diagonal.start_point.Y);
        edge_path.moveTo(corner_diagonal.start_point.X, corner_diagonal.start_point.Y);
    }
    else{
        if(!needs_edge){
            path.lineTo(corner_diagonal.start_point.X, corner_diagonal.start_point.Y);
        }
        else{
            edge_path.lineTo(corner_diagonal.start_point.X, corner_diagonal.start_point.Y);
        }
    }

    if(!needs_edge){
        path.lineTo(corner_diagonal.end_point.X, corner_diagonal.end_point.Y);
        edge_path.moveTo(corner_diagonal.end_point.X, corner_diagonal.end_point.Y);
    }
    else{
        edge_path.lineTo(corner_diagonal.end_point.X, corner_diagonal.end_point.Y);
        path.moveTo(corner_diagonal.end_point.X, corner_diagonal.end_point.Y);
    }

    return {
        first_point: first_point,
        last_point: corner_diagonal.end_point
    }
}

export function drawBumpoutCorner(
    path: Path2D,
    edge_path: Path2D,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number,
    needs_edge: boolean
){
    const angle_A: number = makeAnglePositive(getAngle(previous_location, current_location) - 180, false);
    const angle_B: number = makeAnglePositive(getAngle(current_location, next_location) - 180, false);
    const outward_angle: number = Math.round((angle_A + angle_B) / 2 - (angle_A < angle_B ? 180 : 0));
    const corner_bumpout: CornerBumpOut = constructBumpoutCorner(
        previous_location,
        current_location,
        next_location,
        outward_angle,
        corner_length * zoom,
        corner_depth * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_bumpout.A;
        path.moveTo(corner_bumpout.A.X, corner_bumpout.A.Y);
        edge_path.moveTo(corner_bumpout.A.X, corner_bumpout.A.Y);
    }
    else{
        if(!needs_edge){
            path.lineTo(corner_bumpout.A.X, corner_bumpout.A.Y);
        }
        else{
            edge_path.lineTo(corner_bumpout.A.X, corner_bumpout.A.Y);
        }
    }
    
    if(!needs_edge){
        path.lineTo(corner_bumpout.B.X, corner_bumpout.B.Y);
        path.lineTo(corner_bumpout.C.X, corner_bumpout.C.Y);
        path.lineTo(corner_bumpout.D.X, corner_bumpout.D.Y);
        edge_path.moveTo(corner_bumpout.D.X, corner_bumpout.D.Y);
    }
    else{
        edge_path.lineTo(corner_bumpout.B.X, corner_bumpout.B.Y);
        edge_path.lineTo(corner_bumpout.C.X, corner_bumpout.C.Y);
        edge_path.lineTo(corner_bumpout.D.X, corner_bumpout.D.Y);
        path.moveTo(corner_bumpout.D.X, corner_bumpout.D.Y);
    }
    
    return {
        first_point: first_point,
        last_point: corner_bumpout.D
    }
}

export function drawRecessedDiagonalCorner(
    path: Path2D,
    edge_path: Path2D,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number,
    needs_edge: boolean
): CornerData {
    const angle_A: number = makeAnglePositive(getAngle(previous_location, current_location) - 180, false);
    const angle_B: number = makeAnglePositive(getAngle(current_location, next_location) - 180, false);
    const inward_angle: number = Math.round((angle_A + angle_B) / 2 - (angle_A < angle_B ? 180 : 0));
    const corner_recessed_diagonal: CornerRecessedDiagonal = constructRecessedDiagonalCorner(
        previous_location,
        current_location,
        next_location,
        inward_angle,
        corner_length * zoom,
        corner_depth * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_recessed_diagonal.A;
        path.moveTo(corner_recessed_diagonal.A.X, corner_recessed_diagonal.A.Y);
        edge_path.moveTo(corner_recessed_diagonal.A.X, corner_recessed_diagonal.A.Y);
    }
    else{
        if(!needs_edge){
            path.lineTo(corner_recessed_diagonal.A.X, corner_recessed_diagonal.A.Y);
        }
        else{
            edge_path.lineTo(corner_recessed_diagonal.A.X, corner_recessed_diagonal.A.Y);
        }
    }

    if(!needs_edge){
        path.lineTo(corner_recessed_diagonal.B.X, corner_recessed_diagonal.B.Y);
        path.lineTo(corner_recessed_diagonal.C.X, corner_recessed_diagonal.C.Y);
        path.lineTo(corner_recessed_diagonal.D.X, corner_recessed_diagonal.D.Y);
        edge_path.moveTo(corner_recessed_diagonal.D.X, corner_recessed_diagonal.D.Y);
    }
    else{
        edge_path.lineTo(corner_recessed_diagonal.B.X, corner_recessed_diagonal.B.Y);
        edge_path.lineTo(corner_recessed_diagonal.C.X, corner_recessed_diagonal.C.Y);
        edge_path.lineTo(corner_recessed_diagonal.D.X, corner_recessed_diagonal.D.Y);
        path.moveTo(corner_recessed_diagonal.D.X, corner_recessed_diagonal.D.Y);
    }
    
    return {
        first_point: first_point,
        last_point: corner_recessed_diagonal.D
    }
}

export function drawStandardCorner(
    path: Path2D,
    edge_path: Path2D,
    i: number,
    current_location: Vector2,
    needs_edge: boolean
): CornerData {
    let first_point: Vector2 | null = null;

    if(i === 0){
        path.moveTo(current_location.X, current_location.Y);
        edge_path.moveTo(current_location.X, current_location.Y); 
        first_point = current_location;
    }
    else{
        if(!needs_edge){
            path.lineTo(current_location.X, current_location.Y);
            edge_path.moveTo(current_location.X, current_location.Y);
        }
        else{
            edge_path.lineTo(current_location.X, current_location.Y);
            path.moveTo(current_location.X, current_location.Y);
        }
        
    }

    return {
        first_point: first_point,
        last_point: current_location
    }
}

export function drawPrintCorner(
    i: number,
    painter: any,
    corner_type: string,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    zoom: number,
    radius: number,
    length: number,
    depth: number
): CornerData | null {
    let corner_data: CornerData | null = null;
    if(corner_type === RADIUS){
        corner_data = drawPrintRadiusCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            radius,
            zoom
        );
    }
    else if(corner_type === INNER_RADIUS){
        corner_data = drawPrintInsideRadiusCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            radius,
            zoom
        );
    }
    else if(corner_type === NOTCH){
        corner_data = drawPrintNotchedCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else if(corner_type === CLIP){
        corner_data = drawPrintClippedCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            zoom
        );
    }
    else if(corner_type === DIAGONAL){
        corner_data = drawPrintDiagonalCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            zoom
        );
    }
    else if(corner_type === BUMPOUT){
        corner_data = drawPrintBumpoutCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else if(corner_type === RECESSED_DIAGONAL){
        corner_data = drawPrintRecessedDiagonalCorner(
            painter,
            i,
            previous_location,
            current_location,
            next_location,
            length,
            depth,
            zoom
        );
    }
    else{
        corner_data = drawPrintStandardCorner(
            painter,
            i,
            current_location
        )
    }
    return corner_data;
}

export function drawPrintRadiusCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    radius: number,
    zoom: number
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_radius: CornerRadius = constructRadiusCorner(
        angle,
        current_location,
        next_angle,
        radius * zoom
    );
    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_radius.start_point;
        painter.moveTo(corner_radius.start_point.X, corner_radius.start_point.Y);
    }
    else{
        painter.lineTo(corner_radius.start_point.X, corner_radius.start_point.Y);
    }

    const circle_path: string = describeArc(
        corner_radius.center_point.X,
        corner_radius.center_point.Y,
        corner_radius.radius,
        corner_radius.radius,
        corner_radius.start_angle,
        corner_radius.end_angle,
        1
    );

    painter.path(circle_path);
    painter.moveTo(corner_radius.end_point.X, corner_radius.end_point.Y);
    
    return {
        first_point: first_point,
        last_point: corner_radius.end_point
    }
}

export function drawPrintInsideRadiusCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    radius: number,
    zoom: number
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_radius: InwardCornerRadius = constructInwardRadiusCorner(
        angle,
        current_location,
        next_angle,
        radius * zoom
    );
    let first_point: Vector2 | null = null;
    
    if(i === 0){
        first_point = corner_radius.start_point;
        painter.moveTo(corner_radius.start_point.X, corner_radius.start_point.Y);
    }
    else{
        painter.lineTo(corner_radius.start_point.X, corner_radius.start_point.Y);
    }

    const circle_path: string = describeArcWithPoints(
        corner_radius.start_point,
        corner_radius.end_point,
        corner_radius.radius,
        corner_radius.radius,
        corner_radius.end_angle,
        corner_radius.start_angle,
        0
    );

    painter.path(circle_path);
    painter.moveTo(corner_radius.end_point.X, corner_radius.end_point.Y);
  
    return {
        first_point: first_point,
        last_point: corner_radius.end_point
    }
}

export function drawPrintNotchedCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_notched: CornerNotch = constructNotchCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom,
        corner_depth * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_notched.start_point;
        painter.moveTo(corner_notched.start_point.X, corner_notched.start_point.Y);
    }
    else{
        painter.lineTo(corner_notched.start_point.X, corner_notched.start_point.Y);
    }

    painter.lineTo(corner_notched.inner_corner.X, corner_notched.inner_corner.Y);
    painter.lineTo(corner_notched.end_point.X, corner_notched.end_point.Y);

    return {
        first_point: first_point,
        last_point: corner_notched.end_point
    }
}

export function drawPrintClippedCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    zoom: number
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_clip: CornerClip = constructClipCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_clip.start_point;
        painter.moveTo(corner_clip.start_point.X, corner_clip.start_point.Y);
    }
    else{
        painter.lineTo(corner_clip.start_point.X, corner_clip.start_point.Y);
    }

    painter.lineTo(corner_clip.end_point.X, corner_clip.end_point.Y);
    
    return {
        first_point: first_point,
        last_point: corner_clip.end_point
    }
}

export function drawPrintDiagonalCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    zoom: number
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_diagonal: CornerDiagonal = constructDiagonalCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom
    );
    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_diagonal.start_point;
        painter.moveTo(corner_diagonal.start_point.X, corner_diagonal.start_point.Y);
    }
    else{
        painter.lineTo(corner_diagonal.start_point.X, corner_diagonal.start_point.Y);
    }

    painter.lineTo(corner_diagonal.end_point.X, corner_diagonal.end_point.Y);

    return {
        first_point: first_point,
        last_point: corner_diagonal.end_point
    }
}

export function drawPrintBumpoutCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number
){
    const angle_A: number = makeAnglePositive(getAngle(previous_location, current_location) - 180, false);
    const angle_B: number = makeAnglePositive(getAngle(current_location, next_location) - 180, false);
    const outward_angle: number = Math.round((angle_A + angle_B) / 2 - (angle_A < angle_B ? 180 : 0));
    const corner_bumpout: CornerBumpOut = constructBumpoutCorner(
        previous_location,
        current_location,
        next_location,
        outward_angle,
        corner_length * zoom,
        corner_depth * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_bumpout.A;
        painter.moveTo(corner_bumpout.A.X, corner_bumpout.A.Y);
    }
    else{
        painter.lineTo(corner_bumpout.A.X, corner_bumpout.A.Y);
    }

    painter.lineTo(corner_bumpout.B.X, corner_bumpout.B.Y);
    painter.lineTo(corner_bumpout.C.X, corner_bumpout.C.Y);
    painter.lineTo(corner_bumpout.D.X, corner_bumpout.D.Y);
    
    return {
        first_point: first_point,
        last_point: corner_bumpout.D
    }
}

export function drawPrintRecessedDiagonalCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number
): CornerData {
    const angle_A: number = makeAnglePositive(getAngle(previous_location, current_location) - 180, false);
    const angle_B: number = makeAnglePositive(getAngle(current_location, next_location) - 180, false);
    const inward_angle: number = Math.round((angle_A + angle_B) / 2 - (angle_A < angle_B ? 180 : 0));
    const corner_recessed_diagonal: CornerRecessedDiagonal = constructRecessedDiagonalCorner(
        previous_location,
        current_location,
        next_location,
        inward_angle,
        corner_length * zoom,
        corner_depth * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_recessed_diagonal.A;
        painter.moveTo(corner_recessed_diagonal.A.X, corner_recessed_diagonal.A.Y);
    }
    else{
        painter.lineTo(corner_recessed_diagonal.A.X, corner_recessed_diagonal.A.Y);
    }

    painter.lineTo(corner_recessed_diagonal.B.X, corner_recessed_diagonal.B.Y);
    painter.lineTo(corner_recessed_diagonal.C.X, corner_recessed_diagonal.C.Y);
    painter.lineTo(corner_recessed_diagonal.D.X, corner_recessed_diagonal.D.Y);
    
    return {
        first_point: first_point,
        last_point: corner_recessed_diagonal.D
    }
}


export function drawPrintStandardCorner(
    painter: any,
    i: number,
    current_location: Vector2
): CornerData {
    let first_point: Vector2 | null = null;

    if(i === 0){
        painter.moveTo(current_location.X, current_location.Y);
        first_point = current_location;
    }
    else{
        painter.lineTo(current_location.X, current_location.Y);
    }

    return {
        first_point: first_point,
        last_point: current_location
    }
}