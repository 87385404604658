/// TYPES ///
import { OrderArea } from "api/types/order";
import { OrderSlab } from "api/types/order";
import { SelfOrderArea } from "api/types/order";
import { SelfOrderSlab } from "api/types/order";
/// COMPONENTS ///
import QuotePrintSlab from "./slab";
/// PDF ///
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
/// STYLES ///
import { subtitle } from "styles/print";

interface Props {
  index: number,
  area: OrderArea | SelfOrderArea,
  slabs: OrderSlab[] | SelfOrderSlab[]
}

export default function OrderPrintSlabs({ index, area, slabs }: Props) {
  return (
    <View style={{ display: "flex", flexWrap: "wrap", marginTop: "10px", flexDirection: "row", justifyContent: "space-between" }} wrap={index === 0}>
      <View style={{ paddingLeft: "10px", width: "100%", paddingBottom: "10px" }}>
        <Text style={subtitle}>
          {area.material_name}
        </Text>
      </View>

      {slabs.filter(sl => sl.area === area.id).map((slab, index) => <QuotePrintSlab key={index} index={index} slab={slab} />)}
    </View>
  )
}
