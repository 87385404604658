/// HOOKS ///
import { useNavigate } from "react-router-dom";
/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// COMPONENTS ///
import { Typography } from "@mui/material";
/// FM ///
import { motion } from "framer-motion";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { primary_color } from "styles/style";
import { white_color } from "styles/style";

interface Props {
  crumb: IBreadcrumb,
  last_item: boolean
}

export default function BreadcrumbItem({ crumb, last_item }: Props) {
  const navigate = useNavigate();

  return (
    <motion.button
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.2 } }}
      whileHover={{ color: !last_item ? primary_color : "default" }}
      style={{ padding: 0, height: "100%", alignItems: "center", border: "none", backgroundColor: white_color, display: "flex", flexDirection: "row", cursor: last_item ? "default" : "pointer" }}
      onClick={!last_item ? () => navigate(crumb.url) : void 0}>
      <Typography variant="body2" sx={{ fontSize: "0.8rem", paddingLeft: "5px", color: "inherit" }}>
        {crumb.label}
      </Typography>
      {
        !last_item ?
          <FontAwesomeIcon icon={faChevronRight} style={{ height: "11px", color: "inherit", opacity: 0.6, width: "11px", marginLeft: "15px", marginRight: "9px" }} /> :
          null
      }
    </motion.button>
  )
}
