import { IShopSink } from "api/types/shop_quote/sink";

export enum ActionType {
  CREATE_LOCAL_SHOP_SINK = "CREATE_LOCAL_SHOP_SINK",
  UPDATE_LOCAL_SHOP_SINK = "UPDATE_LOCAL_SHOP_SINK",
  DELETE_LOCAL_SHOP_SINK = "DELETE_LOCAL_SHOP_SINK",
}

interface ICreateLocalShopSink {
  type: ActionType.CREATE_LOCAL_SHOP_SINK,
  sink: IShopSink
}

interface IUpdateLocalShopSink {
  type: ActionType.UPDATE_LOCAL_SHOP_SINK,
  payload: IShopSink
}

interface IDeleteLocalShopSink {
  type: ActionType.DELETE_LOCAL_SHOP_SINK,
  payload: IShopSink
}

export type Action =
  ICreateLocalShopSink |
  IUpdateLocalShopSink |
  IDeleteLocalShopSink;
