/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopCutShape } from "api/types/shop_quote/cut_shape";

export const updateLocalShopCutShapesByArea = (cut_shapes: IShopCutShape[], area_uuid: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_LOCAL_SHOP_CUT_SHAPES_BY_AREA,
      payload: cut_shapes,
      area_uuid: area_uuid
    })
  }
}
