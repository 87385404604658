/// TYPES ///
import { Area } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";
/// REACT ///
import { Fragment } from "react";
/// PDF ///
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
/// STYLES ///
import { print_header } from "styles/print";
import { subtitle } from "styles/print";
/// FUNCTION ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  areas: Area[] | IShopArea[]
}

export default function PrintColorOptions({ areas }: Props) {
  const styles = StyleSheet.create({
    body2: {
      fontSize: 10.8,
      fontFamily: "Montserrat"
    },
    body2_bold: {
      fontSize: 10.8,
      fontFamily: "Montserrat",
      fontWeight: "bold"
    }
  });

  return (
    <View wrap={false}>
      <View style={{ marginTop: "10px", paddingTop: "20px", paddingLeft: "10px" }}>
        <Text style={{ ...subtitle, textTransform: "uppercase" }}>
          Color Options
        </Text>
      </View>
      <View style={print_header}></View>
      {
        areas?.map(area => {
          return (
            <Fragment key={area.id}>
              {
                area.color_options.map(opt => {
                  return (
                    <View style={{ paddingLeft: "10px", display: "flex", justifyContent: "space-between", flexDirection: "row" }} key={opt.id}>
                      <Text style={styles.body2}>{area?.name ?? ""}</Text>
                      <Text style={styles.body2}>{`${opt?.material_name ?? ""} ${opt?.color_name ?? ""} ${getMaterialHeightDisplay(opt?.material_thickness ?? "")}`}</Text>
                      <Text style={styles.body2}>${(opt?.subtotal ?? 0)}</Text>
                    </View>
                  )
                })
              }
            </Fragment>
          )
        })
      }
    </View>
  );
}
