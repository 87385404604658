import { CutShape } from "api/types/sketch";

export enum ActionType {
  DELETE_CUT_SHAPE = "DELETE_CUT_SHAPE",
  DELETE_LOCAL_CUT_SHAPE = "DELETE_LOCAL_CUT_SHAPE",
  GET_CUT_SHAPES_SUCCESS = "GET_CUT_SHAPES_SUCCESS",
  UPDATE_LOCAL_CUT_SHAPE = "UPDATE_LOCAL_CUT_SHAPE",
  UPDATE_LOCAL_CUT_SHAPES = "UPDATE_LOCAL_CUT_SHAPES",
  UPDATE_LOCAL_CUT_SHAPES_BY_AREA = "UPDATE_LOCAL_CUT_SHAPES_BY_AREA",
  SET_CUT_SHAPES = "SET_CUT_SHAPES",
  SET_SELECTED_CUT_SHAPE = "SET_SELECTED_CUT_SHAPE",
}

interface IDeleteCutShape {
  type: ActionType.DELETE_CUT_SHAPE,
  payload: CutShape
}

interface IDeleteLocalCutShape {
  type: ActionType.DELETE_LOCAL_CUT_SHAPE,
  payload: string
}

interface IGetCutShapesSuccess {
  type: ActionType.GET_CUT_SHAPES_SUCCESS,
  payload: CutShape[]
}

interface IUpdateLocalCutShape {
  type: ActionType.UPDATE_LOCAL_CUT_SHAPE,
  payload: CutShape
}

interface IUpdateLocalCutShapes {
  type: ActionType.UPDATE_LOCAL_CUT_SHAPES,
  payload: CutShape[],
  counter_uuid: string
}

interface IUpdateLocalCutShapesByArea {
  type: ActionType.UPDATE_LOCAL_CUT_SHAPES_BY_AREA,
  payload: CutShape[],
  area_uuid: string
}

interface ISetCutShapes {
  type: ActionType.SET_CUT_SHAPES,
  payload: CutShape[]
}

interface ISetSelectedCutShape {
  type: ActionType.SET_SELECTED_CUT_SHAPE,
  payload: CutShape | null
}

export type Action =
  IDeleteCutShape |
  IDeleteLocalCutShape |
  IGetCutShapesSuccess |
  IUpdateLocalCutShape |
  IUpdateLocalCutShapes |
  IUpdateLocalCutShapesByArea |
  ISetCutShapes |
  ISetSelectedCutShape;
