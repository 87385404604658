/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
/// TYPES ///
import { ICustomer } from "api/types/customer";
/// MUI COMPONENTS ///
import { ClickAwayListener } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Popper from "@mui/material/Popper";
/// COLORS ///
import { well_color } from "styles/style";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  anchorEl: HTMLDivElement,
  chooseCustomer: (customer: ICustomer) => void
}

export default function CustomerResults({ open, setOpen, anchorEl, chooseCustomer }: Props) {
  const { end_customers } = useAppSelector((state) => state.end_customer);

  const choose = (customer: ICustomer) => {
    chooseCustomer(customer);
    setOpen(false);
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Popper
        sx={{ backgroundColor: well_color, zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-start"}>
        <List>
          {
            end_customers.map((cx: ICustomer, index: number) => {
              return (
                <ListItemButton key={index} onClick={() => choose(cx)}>
                  {cx.last_name} - {cx.email} - {cx.address}
                </ListItemButton>
              )
            })
          }
        </List>
      </Popper>
    </ClickAwayListener>
  )
}
