export interface StepInterface {
  label: string,
  description: string
}

export const payment_setup_steps: StepInterface[] = [
  {
    label: "Choose a Plan",
    description: "Choose any of the 4 available plans"
  },
  {
    label: "Add Billing Details",
    description: "Add the billing account details for the billing processor"
  },
  {
    label: "Add Payment",
    description: "Add payment details through the payment processor"
  }
];
