/// TYPES ///
import { Transition } from "framer-motion"

export const m_init_page = {
  opacity: 0
}

export const m_anim_page = {
  opacity: 1
}

export const m_exit_page = {
  opacity: 0
}

export const page_trans: Transition = {
  duration: 0.22
}
