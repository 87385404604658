/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { material_url } from "components/navigation/endpoints";
import { market_url } from "components/navigation/endpoints";

export const material_list: IBreadcrumb = {
  id: 0,
  label: "Materials",
  url: `/${market_url}/${material_url}`
}

export const material_type: IBreadcrumb = {
  id: 1,
  label: "",
  url: `/${market_url}/${material_url}/material_type`
}

export const c_material: IBreadcrumb = {
  id: 2,
  label: "",
  url: `/${market_url}/${material_url}/material_type/id`
}
