/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getShopMaterials } from "api/actions/shop_material";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { IShopMaterial } from "api/types/shop_material";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import Loading from "components/universal/loading";
import MaterialHeader from "components/material/header";
import MaterialRow from "./row";
import PageTitle from "components/universal/page/title";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { row_header_style } from "styles/universal/row";
import { title_style } from "styles/quote_preview/section";
/// LODASH ///
import { filter } from "lodash";
/// VALUES ///
import { getMaterialTypeDisplay } from "values/material";
import { getMaterialHeightDisplay } from "values/material";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { material_list } from "values/breadcrumbs/materials/shop";
import { material_type } from "values/breadcrumbs/materials/shop";

export default function ShopMaterialType() {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<number[]>([]);
  const [filtered_materials, setFilteredMaterials] = useState<IShopMaterial[]>([]);
  const { type } = useParams();
  const { materials } = useAppSelector((state) => state.shop_material);

  useEffect(() => {
    const mats = filter(materials, function (mat: IShopMaterial) {
      if (
        (`${mat?.name} ${getMaterialHeightDisplay(mat?.thickness)}`)
          .toLowerCase().includes(search.toLowerCase())) {
        return mat;
      }
    });
    setFilteredMaterials(mats as IShopMaterial[]);
  }, [materials, search]);

  const updateLoading = (is_loading: boolean, id: number) => {
    if (is_loading) {
      setLoading([...loading, id]);
    }
    else {
      setLoading([...loading.filter(i => i !== id)]);
    }
  }

  useEffect(() => {
    dispatch(getShopMaterials(type));
    dispatch(setBreadcrumbs([material_list, { ...material_type, label: getMaterialTypeDisplay(type), url: material_type.url.replace("material_type", type) }]));
  }, [type, dispatch]);

  const searchKeyDown = (e: any) => {
    if (e.key === "Escape") {
      e.preventDefault();
      setSearch("");
    }
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title={getMaterialTypeDisplay(type)} endProps={<></>} />
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
        <Grid item xs={6} sx={row_header_style}>
          <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <Typography variant="h6" sx={{ ...title_style, marginTop: "5px", paddingTop: 0 }}>
              {filtered_materials.length} results
            </Typography>
            {
              materials.length === 2000 ?
                <Typography variant="body2" sx={{ marginLeft: "5px" }} color="error">
                  Limit of 2000 reached. Use filter to narrow down results.
                </Typography> :
                null
            }
          </Box>
        </Grid>
        <Grid item xs={6} sx={row_header_style}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <TextField
              label="Search Within Results"
              onKeyDown={searchKeyDown}
              value={search}
              placeholder="Name or Thickness"
              size="small"
              sx={{ height: "30px", marginRight: "20px" }}
              InputProps={{
                style: { height: "30px", },
                endAdornment: <InputAdornment position="end">
                  {

                    search.length > 0 ?
                      <IconButton onClick={() => setSearch("")}>
                        <FontAwesomeIcon icon={faXmark} size="xs" />
                      </IconButton> :
                      <FontAwesomeIcon icon={faMagnifyingGlass} size="xs" />
                  }
                </InputAdornment>
              }}
              onChange={e => setSearch(e.target.value)} />
          </Box>
        </Grid>
        <MaterialHeader />
        <Grid item xs={12}>
          <Typography variant="h6" sx={title_style}>
            Material
          </Typography>
        </Grid>
        {filtered_materials.map((mat: IShopMaterial, index: number) => <MaterialRow key={index} index={index} mat={mat} />)}
      </Grid>
      <Loading loading={loading.length > 0} />
    </motion.div>
  )
}
