/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// FM ///
import { motion } from "framer-motion";
/// TYPES ///
import { ICustomEvent } from "api/types/event";
/// STYLES ///
import {
  dayEventItemStyle
} from "styles/calendar/day";
/// URLS ///
import { event_url } from "components/navigation/endpoints";
import { detail_url } from "components/navigation/endpoints";

interface Props {
  custom_event: ICustomEvent
}

export default function CustomDayItem({ custom_event }: Props) {
  const navigate = useNavigate();

  return (
    <motion.button
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.05 }}
      onClick={() => navigate(`/${event_url}/${detail_url}/${custom_event?.id}`)}
      style={dayEventItemStyle(custom_event?.color)}>
      {custom_event?.title ?? ""}
    </motion.button>
  )
}
