/// TYPES ///
import { FabricatorWaterfall } from "api/types/fabricator";
/// MUI COMPONENTS ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import FabricatorWaterfallRangeItem from "./waterfall_range";
/// FM ///
import { motion } from "framer-motion";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  item: FabricatorWaterfall,
  updateLoading: (is_loading: boolean, id: number) => void
}

export default function FabricatorWaterfallItem({ index, item, updateLoading }: Props) {
  return (
    <Grid item xs={12}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.4 * index } }}>
        <Grid container alignItems="center" sx={{ padding: 1 }}>
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>{getMaterialHeightDisplay(item?.thickness ?? "")}</b>
            </Typography>
          </Grid>
          {
            item?.fabricator_waterfall_ranges.map((range, i) => <FabricatorWaterfallRangeItem key={index} index={i + index} item={range} updateLoading={updateLoading} />)
          }
        </Grid>
      </motion.div>
    </Grid>
  )
}

