import { IShopCorner } from "api/types/shop_quote/corner";

export enum ActionType {
  UPDATE_SHOP_CORNER = "UPDATE_SHOP_CORNER"
}

interface IUpdateShopCorner {
  type: ActionType.UPDATE_SHOP_CORNER,
  payload: IShopCorner
}

export type Action = IUpdateShopCorner;
