export const planCardStyle = {
    width: {
        "xs": 300,
        "lg": 350
    },
    padding: 4,
    paddingTop: 4,
    paddingBottom: 4
}

export const planCardWithWellStyle = {
    width: {
        "xs": 380,
        "lg": 420
    },
    paddingBottom: 5
}