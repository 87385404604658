/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { deleteCustomer } from "api/actions/end_customer";
import { getEndCustomer } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
/// PAGE COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { item_style } from "styles/quote_preview/section";
import { title_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { end_customer_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { cx_list } from "values/breadcrumbs/customer";
import { cx } from "values/breadcrumbs/customer";
import { cx_delete } from "values/breadcrumbs/customer";

export default function DeleteCustomer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { end_customer } = useAppSelector((state) => state.end_customer);

  const deleteCx = () => {
    dispatch(deleteCustomer(id));
    navigate(`/${end_customer_url}`);
  }

  useEffect(() => {
    if (!end_customer?.id || end_customer.id.toString() !== id) {
      dispatch(getEndCustomer(id));
    }
  }, []);

  useEffect(() => {
    if (end_customer?.id) {
      dispatch(setBreadcrumbs([
        cx_list,
        { ...cx, label: `${end_customer?.first_name ?? ""} ${end_customer?.last_name ?? "Customer"}`, url: cx.url.replace("id", id) },
        { ...cx_delete, url: cx.url.replace("id", id) }
      ]));
    }
  }, [dispatch, end_customer.id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start">
        <PageTitle title="Delete Customer" size_lg={6} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${end_customer_url}/${detail_url}/${id}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={deleteCx} text="Confirm" icon={faTrash} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid container item xs={12} lg={6}>
          <Grid item xs={12}>
            <Box style={header_style}></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Permanent Delete
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="error" sx={{ paddingLeft: "6px", paddingRight: "6px", paddingTop: "3px" }}>
              Are you sure you want to delete {end_customer?.first_name ?? ""} {end_customer?.last_name ?? ""}? Data for this customer will be permanently deleted.
            </Typography></Grid>
          <Grid item xs={6} sx={{ marginTop: "30px" }}>
            <Typography variant="body2" sx={item_style()}>
              <b>Name</b>
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "30px" }}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {`${end_customer?.first_name ?? ""} ${end_customer?.last_name ?? ""}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Email</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {end_customer?.email ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Phone</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {end_customer?.phone_number ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Service Address</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {end_customer?.geo_address ?? ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
      </Grid>
    </motion.div>
  )
}
