import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { User } from "api/types/authentication";

interface Props {
  user: User
}

export default function UserDetails({ user }: Props) {
  return (
    <Grid container>
      <Grid item xs={6} >
        <Typography variant="body2">
          Template
        </Typography>
      </Grid>
      <Grid item xs={6} >
        <Typography variant="body2" align="right">
          {user.template_timeline} Business Days
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2">
          Installation
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right">
          {user.installation_timeline} Business Days
        </Typography>
      </Grid>
      <Grid item xs={6} >
        <Typography variant="body2">
          Warranty
        </Typography>
      </Grid>
      <Grid item xs={6} >
        <Typography variant="body2" align="right">
          {user.warranty_days} Days
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2">
          Payment Terms
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body2" align="left">
          {user.payment_terms}
        </Typography>
      </Grid>
    </Grid>
  )
}
