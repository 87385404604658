import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Vector2 } from "api/types/sketch";
import { MouseData } from "types/mouse_position";
import { getCanvas } from "functions/sketch/context";

function useMousePosition(): MouseData {
  const [mouse_position, setMousePosition] = useState<Vector2>({ X: 0, Y: 0 });
  const [screen_position, setScreenPosition] = useState<Vector2>({ X: 0, Y: 0 });
  const [scroll_dimensions, setScrollDimensions] = useState<Vector2>({ X: 0, Y: 0 });
  const [saved_offset, setOffset] = useState<Vector2>({ X: 0, Y: 0 });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const offset: Vector2 = ({ X: 0, Y: 0 });
    if (ref && ref.current) {
      const width = ref.current.scrollWidth;
      const height = ref.current.scrollHeight;
      const offset_width = ref.current.getBoundingClientRect().x;
      const offset_height = ref.current.getBoundingClientRect().y;
      offset.X = offset_width;
      offset.Y = offset_height;
      setOffset({ X: offset.X, Y: offset.Y });

      const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById("canvas"));

      if (canvas) {
        canvas.width = width;
        canvas.height = height;
      }
      setScrollDimensions({ X: width, Y: height });
    }

    const updateMousePosition = (event: MouseEvent) => {
      setMousePosition({ X: event.clientX - offset.X, Y: event.clientY - offset.Y + window.scrollY });
      setScreenPosition({ X: event.clientX, Y: event.clientY });
    };

    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, [ref]);

  return {
    position: mouse_position,
    screen_position: screen_position,
    scroll_dimensions: scroll_dimensions,
    offset: saved_offset,
    ref: ref
  };
};

export default useMousePosition;
