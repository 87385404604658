import { SeamLine } from "api/types/sketch";
import { Seam } from "api/types/sketch";
import { Point } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getDistantPoint } from "../math";
import { getDistance } from "../math";

export function getSeamLine(seam: Seam, point: Point, corner: Corner): SeamLine {
    const seam_center: Vector2 = getDistantPoint(point.location, point.angle, seam.distance_from_point);
    const vector_1: Vector2 = getDistantPoint(seam_center, point.angle + 90, point.width / 2);
    const vector_2: Vector2 = getDistantPoint(seam_center, point.angle - 90, point.width / 2);
    const distance_1: number = Math.abs(getDistance(vector_1, corner.location));
    const distance_2: number = Math.abs(getDistance(vector_2, corner.location));

    if(distance_1 > distance_2){
        return {
            start: {
                X: vector_2.X,
                Y: vector_2.Y
            },
            end: {
                X: vector_1.X,
                Y: vector_1.Y
            }
        }
    }
    else{
        return {
            start: {
                X: vector_1.X,
                Y: vector_1.Y
            },
            end: {
                X: vector_2.X,
                Y: vector_2.Y
            }
        }
    }
}

export function getCornerSeamLine(first_corner: Corner, second_corner: Corner): SeamLine {
    return {
        start: {
            X: first_corner.location.X,
            Y: first_corner.location.Y
        },
        end: {
            X: second_corner.location.X,
            Y: second_corner.location.Y
        }
    }
}