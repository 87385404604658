/// TYPES ///
import { Area } from "api/types/quote";
import { Slab } from "api/types/quote";
import { Vector2 } from "api/types/sketch";
/// VALUES ///
import { inch_to_pixel } from "values/values";
import { SLAB_ZOOM } from "values/values";

export function getFirstSlabInArea(area: Area): Slab | null {
  for (let i = 0; i < area.slabs.length; i++) {
    return area.slabs[i];
  }
  return null;
}

export function getFirstSlabInAreas(areas: Area[], area_uuid: string): Slab | null {
  for (let i = 0; i < areas.length; i++) {
    if (areas[i].uuid === area_uuid) {
      for (let j = 0; j < areas[i].slabs.length; j++) {
        return areas[i].slabs[j];
      }
    }
  }
  return null;
}

export function getSlabStartPoint(height: number, i: number, zoom: number = SLAB_ZOOM): Vector2 {
  return {
    X: 50,
    Y: 50 + i * height * zoom * inch_to_pixel + i * 25
  }
}

export function getSlabByUuid(uuid: string, areas: Area[]): Slab | null {
  for (let i = 0; i < areas.length; i++) {
    for (let j = 0; j < areas[i].slabs.length; j++) {
      if (areas[i].slabs[j].uuid === uuid) {
        return areas[i].slabs[j];
      }
    }
  }
  return null;
}
