import { Dispatch } from "redux";
import { Action } from "api/action_types/quote";
import { ActionType } from "api/action_types/quote";

export const markQuoteChanged = () => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.MARK_QUOTE_CHANGE
        });
    }
}