/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMagnet } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { actionBarStyle } from "styles/sketch";

interface Props {
  pullCutShapes: () => void,
  addSlab: () => void
}

export default function SlabActions({ pullCutShapes, addSlab }: Props) {

  return (
    <Box sx={actionBarStyle}>
      <Tooltip title="Add Slab">
        <Fab
          size="small"
          color="primary"
          aria-label="zoom-in"
          onClick={addSlab}>
          <FontAwesomeIcon icon={faPlus} />
        </Fab>
      </Tooltip>
      <Tooltip title="Center Shapes">
        <Fab
          size="small"
          color="primary"
          aria-label="zoom-out"
          onClick={pullCutShapes}>
          <FontAwesomeIcon icon={faMagnet} />
        </Fab>
      </Tooltip>
    </Box>
  )
}
