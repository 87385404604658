/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
/// ACTIONS ///
import { getFabricationJobs } from "api/actions/end_customer";
/// TYPES ///
import { IFabricationJob } from "api/types/customer";
/// REACT COMPONENT ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import { TextField } from "@mui/material";
/// GEO COMPONENTS ///
import AutocompleteResults from "./results";
/// LODASH ///
import { debounce } from "lodash";

interface Props {
  chooseJob: (job: IFabricationJob) => void,
  customer_id?: number,
  width?: number,
  height?: number | string,
  input_label?: string,
}

export default function FabJobAutocomplete({ chooseJob, width = 540, height = "default", input_label = "Use Previous Job", customer_id }: Props) {
  const dispatch = useAppDispatch();
  const [res_open, setResOpen] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const ref = useRef();

  useEffect(() => {
    if (ref) {
      setAnchor(ref.current);
    }
  }, [ref]);

  const updateJob = (job: IFabricationJob) => {
    setInput(`${job.name} - ${job.geo_address}`);
    chooseJob(job);
  }

  function query(input: string) {
    dispatch(getFabricationJobs({ name: input, address: input, any: true, limit: "5", end_customer: customer_id ?? null }));
  }

  const debounced = useCallback(
    debounce(query, 500),
    []
  );

  useEffect(() => {
    debounced(input);
  }, [input, dispatch, debounced]);

  const updateQuery = (input: string) => {
    setInput(input);
    setResOpen(true);
  }

  const keyDown = (e: any) => {
    if (e.key === "Escape") {
      e.preventDefault();
      setResOpen(false);
    }
  }

  return (
    <Fragment>
      <TextField
        sx={{ width: `${width}px`, padding: 0 }}
        onKeyDown={keyDown}
        ref={ref}
        value={input}
        size="small"
        placeholder="Search Previous Job Name or Site Address"
        label={input_label}
        onChange={e => updateQuery(e.target.value)} />
      <AutocompleteResults
        anchorEl={anchor}
        open={res_open}
        setOpen={setResOpen}
        chooseJob={updateJob} />
    </Fragment>
  )
}

