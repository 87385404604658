import { Sink } from "api/types/sketch";

export enum ActionType {
    CREATE_LOCAL_SINK="CREATE_LOCAL_SINK",
    UPDATE_LOCAL_SINK="UPDATE_LOCAL_SINK",
    DELETE_LOCAL_SINK="DELETE_LOCAL_SINK",
}

interface ICreateLocalSink {
    type: ActionType.CREATE_LOCAL_SINK,
    payload: Sink
}

interface IUpdateLocalSink {
    type: ActionType.UPDATE_LOCAL_SINK,
    payload: Sink
}

interface IDeleteLocalSink {
    type: ActionType.DELETE_LOCAL_SINK,
    payload: Sink
}

export type Action =
    ICreateLocalSink |
    IUpdateLocalSink |
    IDeleteLocalSink;