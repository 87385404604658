/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// TYPES ///
import { IShopBump } from "api/types/shop_quote/bump";
/// URLS //
import { url_prefix } from "settings";
import { shop_bump_url } from "../../endpoints";

export const createShopBump = (bump: IShopBump) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.SHOP_QUOTE_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${shop_bump_url}/`;

    try {
      await axios.post(url, bump, config);

      dispatch({
        type: ActionType.SHOP_QUOTE_ACTION_SUCCESS
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.SHOP_QUOTE_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
