/// TYPES ///
import { OrderArea } from "api/types/order/order";
import { OrderCounter } from "api/types/order/order";
import { SelfOrderArea } from "api/types/order/self_order";
import { SelfOrderCounter } from "api/types/order/self_order";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// UNI COMPONENTS ///
import DiagramOrderCounters from "./counter";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
import { header_style } from "styles/universal/section_header";

interface Props {
  areas: OrderArea[] | SelfOrderArea[],
  counters: OrderCounter[] | SelfOrderCounter[],
  counters_loaded: boolean
}

export default function DiagramOrderAreasCounter({ areas, counters, counters_loaded }: Props) {
  return (
    <Grid container item xs={12} sx={{ marginTop: "30px" }}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={title_style}>
          Counters
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      {areas.map((area: OrderArea | SelfOrderArea) => {
        return (
          <Grid key={area.id} container item xs={12} sx={{ padding: "5px" }} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2" color="primary" sx={item_style(false)}>
                <b>{area.name}:</b> {area?.material_name ?? ""}
              </Typography>
            </Grid>
            <DiagramOrderCounters area_uuid={area.uuid} counters={counters} counters_loaded={counters_loaded} />
          </Grid>
        )
      })}
    </Grid>
  )
}
