/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/wo";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/wo";
/// TYPES ///
import { Order } from "api/types/order";
/// LODASH ///
import { sortBy } from "lodash";
/// SORT VALUES ///
import { NAME } from "values/sort/quote";
import { ADDRESS } from "values/sort/quote";
import { NUMBER } from "values/sort/quote";
import { EMAIL } from "values/sort/quote";
import { DATE } from "values/sort/quote";

function sort(wos: Order[], sort_type: number, sort_up: boolean): Order[] {
  switch (sort_type) {
    case NAME:
      if (sort_up) {
        return sortBy(wos, ["name"]);
      }
      else {
        return sortBy(wos, ["name", "desc"]);
      }
    default:
      return wos;
  };
}

export const sortWOs = (sort_type: number, sort_up: boolean) => {
  return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const { orders } = getState().order;

    dispatch({
      type: ActionType.SORT_WOS,
      payload: sort(orders, sort_type, sort_up)
    });
  }
}
