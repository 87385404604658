import { CornerData } from "api/types/sketch";
import { CornerDiagonal } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { getDistance } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { getAngleOnSecondHalfCornerSeam } from "../seam";
import { constructDiagonalCorner } from "../../corner/construct";

export function drawSecondHalfCutDiagonalCorner(
    path: Path2D,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    zoom: number
): CornerData {
    const cut_angle: number = Math.round((getAngle(previous_location, current_location) + 90) * 100) / 100;
    const next_angle: number = Math.round(getAngle(current_location, next_location) + 90);
    const angle: number = getAngleOnSecondHalfCornerSeam(cut_angle, next_angle);

    const corner_diagonal: CornerDiagonal = constructDiagonalCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom
    );

    const diagonal_angle: number = getAngle(corner_diagonal.start_point, corner_diagonal.end_point);
    const distance: number = getDistance(corner_diagonal.start_point, corner_diagonal.end_point);
    const midway_point: Vector2 = getDistantPoint(corner_diagonal.end_point, diagonal_angle - 90, distance / 2);

    if(i === 0){
        path.moveTo(midway_point.X, midway_point.Y);
    }
    else{
        path.lineTo(midway_point.X, midway_point.Y);
    }

    path.lineTo(corner_diagonal.end_point.X, corner_diagonal.end_point.Y);

    return {
        first_point: midway_point,
        last_point: corner_diagonal.end_point
    }
}