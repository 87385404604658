/// TYPES ///
import { IAddon } from "api/types/quote";
import { OrderAddon } from "api/types/order/order";
import { SelfOrderAddon } from "api/types/order/self_order";
/// PDF ///
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
/// STYLES ///
import { subtitle } from "styles/print";
/// COLOR ///
import { light_grey_color } from "styles/style";

interface Props {
  addons: IAddon[] | OrderAddon[] | SelfOrderAddon[],
}

export default function PrintAddons({ addons }: Props) {
  const styles = StyleSheet.create({
    text: {
      fontFamily: "Montserrat",
      fontSize: 10.8
    },
    line_container: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "10px",
      paddingRight: "10px",
      borderBottom: `1px solid ${light_grey_color}`
    }
  });

  return (
    <View style={{ width: "100%" }}>
      <View style={styles.line_container}>
        <Text style={subtitle}>
          Addons
        </Text>
      </View>
      {
        addons.map((addon: IAddon) => {
          return (
            <View key={addon.id} style={styles.line_container}>
              <View style={{ width: "50%" }}>
                <Text style={styles.text}>
                  {addon.name}
                </Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={{ ...styles.text, textAlign: "right" }}>
                  ${addon.price}
                </Text>
              </View>
            </View>
          )
        })
      }
    </View>
  )
}
