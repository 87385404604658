/// TYPES ///
import { IOrderEvent } from "api/types/event";
import { ICustomEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event";
import { IEventFilter } from "api/types/event";
/// ACTION TYPES ///
import { Action } from "api/action_types/event";
import { ActionType } from "api/action_types/event";
/// DATETIME ///
import { DateTime } from "luxon";

interface State {
  custom_event: ICustomEvent,
  custom_events: ICustomEvent[],
  order_event: IOrderEvent,
  order_events: IOrderEvent[],
  self_order_event: ISelfOrderEvent,
  self_order_events: ISelfOrderEvent[],
  filter: IEventFilter,
  action_success: boolean,
  nav_date: string,
  updating: boolean,
  error: string | null
}

const initialState = {
  custom_event: {},
  custom_events: [],
  order_event: {},
  order_events: [],
  self_order_event: {},
  self_order_events: [],
  filter: {},
  action_success: false,
  nav_date: DateTime.now().toISO(),
  updating: false,
  error: null
}

export const eventReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_NAV_DATE:
      return {
        ...state,
        nav_date: action.date,
      }
    case ActionType.GET_CUSTOM_EVENTS:
      return {
        ...state,
        custom_events: action.events,
        updating: false,
        error: ""
      }
    case ActionType.GET_CUSTOM_EVENT:
      return {
        ...state,
        custom_event: action.event,
        updating: false,
        error: ""
      }
    case ActionType.GET_ORDER_EVENTS:
      return {
        ...state,
        order_events: action.order_events,
        updating: false,
        error: ""
      }
    case ActionType.GET_ORDER_EVENT:
      return {
        ...state,
        order_event: action.order_event,
        updating: false,
        error: ""
      }
    case ActionType.GET_SELF_ORDER_EVENTS:
      return {
        ...state,
        self_order_events: action.self_order_events,
        updating: false,
        error: ""
      }
    case ActionType.GET_SELF_ORDER_EVENT:
      return {
        ...state,
        self_order_event: action.self_order_event,
        updating: false,
        error: ""
      }
    case ActionType.CREATE_CUSTOM_EVENT:
      return {
        ...state,
        action_success: true,
        updating: false,
        error: ""
      }
    case ActionType.CREATE_ORDER_EVENT:
      return {
        ...state,
        action_success: true,
        updating: false,
        error: ""
      }
    case ActionType.CREATE_SELF_ORDER_EVENT:
      return {
        ...state,
        action_success: true,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_ORDER_EVENT:
      return {
        ...state,
        order_events: [...state.order_events.map(e => e.id === action.order_event.id ? action.order_event : e)],
        order_event: state?.order_event?.id === action?.order_event?.id ? action?.order_event : state.order_event,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_SELF_ORDER_EVENT:
      return {
        ...state,
        self_order_events: [...state.self_order_events.map(e => e.id === action?.self_order_event?.id ? action?.self_order_event : e)],
        self_order_event: state?.self_order_event?.id === action?.self_order_event?.id ? action.self_order_event : state.self_order_event,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_CUSTOM_EVENT:
      return {
        ...state,
        custom_event: action.event,
        updating: false,
        error: ""
      }
    case ActionType.RESET_ACTION_SUCCESS:
      return {
        ...state,
        action_success: false
      }
    case ActionType.EVENT_UPDATING:
      return {
        ...state,
        updating: true
      }
    case ActionType.EVENT_ERROR:
      return {
        ...state,
        error: action.error,
        updating: false
      }
    case ActionType.SET_EVENT_FILTER:
      return {
        ...state,
        filter: action.filter
      }
    case ActionType.SET_SELF_ORDER_EVENT:
      return {
        ...state,
        self_order_event: action.event
      }
    case ActionType.SET_ORDER_EVENT:
      return {
        ...state,
        order_event: action.event
      }
    default:
      return state;
  }
}
