import { Vector2 } from "api/types/sketch";
import { BumpArcConstruct } from "api/types/sketch";
import { constructBumpInArc } from "../construct";

export function drawCutBumpInArc(
    path: Path2D, 
    start: Vector2,
    end: Vector2,
    distance: number,
    width: number,
    depth: number,
    angle: number,
    zoom: number
){
    const bump_construct: BumpArcConstruct = constructBumpInArc(start, end, angle, distance, width, depth, zoom);
    path.lineTo(bump_construct.start.X, bump_construct.start.Y);
    path.quadraticCurveTo(bump_construct.top.X, bump_construct.top.Y, bump_construct.end.X, bump_construct.end.Y);
}