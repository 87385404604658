import { IShopCutShape } from "api/types/shop_quote/cut_shape";

export enum ActionType {
  DELETE_SHOP_CUT_SHAPE = "DELETE_SHOP_CUT_SHAPE",
  DELETE_LOCAL_SHOP_CUT_SHAPE = "DELETE_LOCAL_SHOP_CUT_SHAPE",
  GET_SHOP_CUT_SHAPES = "GET_SHOP_CUT_SHAPES",
  UPDATE_LOCAL_SHOP_CUT_SHAPE = "UPDATE_LOCAL_SHOP_CUT_SHAPE",
  UPDATE_LOCAL_SHOP_CUT_SHAPES = "UPDATE_LOCAL_SHOP_CUT_SHAPES",
  UPDATE_LOCAL_SHOP_CUT_SHAPES_BY_AREA = "UPDATE_LOCAL_SHOP_CUT_SHAPES_BY_AREA",
  SET_SHOP_CUT_SHAPES = "SET_SHOP_CUT_SHAPES",
  SET_SELECTED_SHOP_CUT_SHAPE = "SET_SELECTED_SHOP_CUT_SHAPE",
}

interface IDeleteCutShape {
  type: ActionType.DELETE_SHOP_CUT_SHAPE,
  payload: IShopCutShape
}

interface IDeleteLocalCutShape {
  type: ActionType.DELETE_LOCAL_SHOP_CUT_SHAPE,
  payload: string
}

interface IGetCutShapesSuccess {
  type: ActionType.GET_SHOP_CUT_SHAPES,
  payload: IShopCutShape[]
}

interface IUpdateLocalCutShape {
  type: ActionType.UPDATE_LOCAL_SHOP_CUT_SHAPE,
  payload: IShopCutShape
}

interface IUpdateLocalCutShapes {
  type: ActionType.UPDATE_LOCAL_SHOP_CUT_SHAPES,
  payload: IShopCutShape[],
  counter_uuid: string
}

interface IUpdateLocalCutShapesByArea {
  type: ActionType.UPDATE_LOCAL_SHOP_CUT_SHAPES_BY_AREA,
  payload: IShopCutShape[],
  area_uuid: string
}

interface ISetCutShapes {
  type: ActionType.SET_SHOP_CUT_SHAPES,
  payload: IShopCutShape[]
}

interface ISetSelectedCutShape {
  type: ActionType.SET_SELECTED_SHOP_CUT_SHAPE,
  payload: IShopCutShape | null
}

export type Action =
  IDeleteCutShape |
  IDeleteLocalCutShape |
  IGetCutShapesSuccess |
  IUpdateLocalCutShape |
  IUpdateLocalCutShapes |
  IUpdateLocalCutShapesByArea |
  ISetCutShapes |
  ISetSelectedCutShape;
