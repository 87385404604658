/// HOOKS ///
import { useEffect } from "react";
/// TYPES ///
import { DiagramProps } from "api/types/sketch";
import { OrderCounter } from "api/types/order/order";
import { SelfOrderCounter } from "api/types/order/self_order";
import { Text } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
/// SKETCH FUNCTIONS ///
import { drawOrderCounter, drawText } from "functions/sketch";
import { drawOrderCooktops } from "functions/sketch";
import { drawOrderSinks } from "functions/sketch";
import { getCanvas } from "functions/sketch/context";
import { getContext } from "functions/sketch/context";
import { getDiagramProps } from "functions/sketch/get/diagram";
/// CANVAS ///
import { detailCanvas } from "functions/sketch/context";
import { highlightCanvas } from "functions/sketch/context";
import { initializeCanvas } from "functions/sketch/context";
/// COLORS ///
import { dark_grey_color } from "styles/style";

export default function useDrawDiagramOrderCounters(area_uuid: string, counters: OrderCounter[] | SelfOrderCounter[], dimensions: Vector2, counters_loaded: boolean) {
  useEffect(() => {
    const canvas: HTMLCanvasElement | null = getCanvas(document.getElementById(`diagram_ac_${area_uuid}`));
    const context: CanvasRenderingContext2D | null = getContext(canvas);

    if (canvas && context && counters_loaded) {
      initializeCanvas(canvas, context);
      const path: Path2D = new Path2D();
      const edge_path: Path2D = new Path2D();
      const measurement_path: Path2D = new Path2D();
      const texts: Text[] = [];
      const active_counters: OrderCounter[] | SelfOrderCounter[] = counters.filter(counter => counter.area_uuid === area_uuid);
      const props: DiagramProps = getDiagramProps(active_counters, dimensions);

      for (let i = 0; i < active_counters.length; i++) {
        drawOrderCounter(
          counters[i],
          path,
          edge_path,
          measurement_path,
          texts,
          props.offset,
          props.zoom
        );

        drawOrderCooktops(
          path,
          [counters[i]],
          props.offset,
          props.zoom
        );

        drawOrderSinks(
          path,
          [counters[i]],
          props.offset,
          props.zoom
        );

      }

      context.beginPath();
      context.stroke(path);
      detailCanvas(context);
      context.stroke(measurement_path);
      for (let i = 0; i < texts.length; i++) {
        drawText(context, dark_grey_color, texts[i], false);
      }
      highlightCanvas(context);
      context.stroke(edge_path);

    }
  }, [counters, area_uuid, counters_loaded, dimensions]);
}
