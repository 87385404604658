/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getShopColors } from "api/actions/shop_material";
import { getShopMaterial } from "api/actions/shop_material";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { Pagination } from "@mui/material";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import ShopColorHeader from "./header";
import Loading from "components/universal/loading";
import MarketColorItem from "./row/color";
import PageTitle from "components/universal/page/title";
import PaginationSelector from "components/universal/ui/pagination_selector";
import MaterialTable from "./table";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// FM ///
import { motion } from "framer-motion";
/// ICON ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// TYPES ///
import { IShopColor } from "api/types/shop_material";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { row_header_style } from "styles/universal/row";
import { title_style } from "styles/universal/page";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// LODASH ///
import { filter } from "lodash";
/// VALUES ///
import { default_pagination_length } from "styles/table";
import { getMaterialHeightDisplay } from "values/material";
import { getMaterialTypeDisplay } from "values/material";
/// BREADCRUMBS ///
import { material_list } from "values/breadcrumbs/materials/shop";
import { material_type } from "values/breadcrumbs/materials/shop";
import { c_material } from "values/breadcrumbs/materials/shop";
/// URLS ///
import { create_url } from "components/navigation/endpoints";
import { material_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";

export default function ShopMaterial() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { colors } = useAppSelector(state => state.shop_material);
  const { material } = useAppSelector(state => state.shop_material);
  const { loading } = useAppSelector(state => state.shop_material);

  useEffect(() => {
    if (!material.id || material.id.toString() !== id) {
      dispatch(getShopMaterial(id));
    }
  }, [dispatch, id, material]);

  useEffect(() => {
    dispatch(getShopColors(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (material?.name) {
      dispatch(setBreadcrumbs([
        material_list,
        { ...material_type, label: getMaterialTypeDisplay(type), url: material_type.url.replace("material_type", type) },
        {
          ...c_material,
          label: `${material?.name} ${getMaterialHeightDisplay((material?.thickness ?? ""))}`,
          url: c_material.url.replace("material_type", type).replace("id", id)
        }
      ]));
    }
  }, [type, dispatch, id, material]);

  // TODO bug it's cutting it off
  // TODO bug it's bringing way too many colors
  // TODO this probably affects the market colors too, it may not seems like a shop specific bug still check
  // TODO this might be affecting other lists for the pagination issue

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <Grid container item xs={12} lg={6}>
          <PageTitle title={`${material?.name} ${getMaterialHeightDisplay((material?.thickness ?? ""))}`} endProps={
            <BaseButton icon={faPlus} clickEvent={() => navigate(`/${shop_url}/${material_url}/${type}/${id}/${create_url}`)} text="Add Colors" />
          } />
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={12} sx={{ ...row_header_style, paddingTop: "7px", paddingLeft: "2px" }}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <Typography variant="h6" sx={title_style}>
                {colors?.length} results
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable />
        </Grid>
      </Grid>
      <Loading loading={loading} />
    </motion.div>
  )
}

/*          <ShopColorHeader />
          {filt_colors.map((color, index) => <MarketColorItem key={index} index={index} color={color} updateLoading={updateLoading} />)}
          <Grid item xs={10} sx={{ marginTop: 3 }}>
            <Box display="flex" justifyContent="center">
              <Pagination onChange={(e, v) => setPage(v)} count={page_count} shape="rounded" />
            </Box>
          </Grid>
          <Grid item xs={1} sx={{ marginTop: 3 }}>
            <PaginationSelector num_per_page={num_per_page} setNumPerPage={(setNumPerPage)} />
          </Grid>
*/
