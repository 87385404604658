import { Counter } from "api/types/sketch";

export const empty_counter: Counter = {
    points: [],
    uuid: "",
    area_uuid: "",
    corners: [],
    sinks: [],
    cooktops: [],
    bumps: []
}