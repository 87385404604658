/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/sketch";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/sketch";
/// TYPES ///
import { CutShape } from "api/types/sketch";

export const updateLocalCutShapes = (cut_shapes: CutShape[], counter_uuid: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_CUT_SHAPES,
            payload: cut_shapes,
            counter_uuid: counter_uuid
        })
    }
}
