/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { item_style } from "styles/quote_preview/section";
/// URLS ///
import { profile_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { profile } from "values/breadcrumbs/profile";

export default function Profile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(setBreadcrumbs([profile]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start">
        <Grid container item xs={12} lg={6}>
          <PageTitle title="Profile" endProps={
            <Box>
              <BaseButton text="Change Password" icon={faShieldHalved} clickEvent={(() => navigate(`/${profile_url}/change_password`))} />
              <BaseButton text="Update Profile" icon={faSave} clickEvent={() => navigate(`/${profile_url}/update`)} />
            </Box>
          } />
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Name</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {`${user.first_name} ${user.last_name}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </motion.div>
  )
}
