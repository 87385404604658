import { Material } from "api/types/material";
import { Color } from "api/types/material";

export enum ActionType {
  STORE_MATERIAL_LOADING = "STORE_MATERIAL_LOADING",
  STORE_MATERIAL_ERROR = "STORE_MATERIAL_ERROR",
  CLEAR_STORE_MATERIALS = "CLEAR_STORE_MATERIALS",
  STORE_SKETCH_MATERIAL = "STORE_SKETCH_MATERIAL",
  STORE_SKETCH_COLORS = "STORE_SKETCH_COLORS"
}

interface IClearMaterial {
  type: ActionType.CLEAR_STORE_MATERIALS
}

interface IMaterialLoading {
  type: ActionType.STORE_MATERIAL_LOADING
}

interface IMaterialError {
  type: ActionType.STORE_MATERIAL_ERROR,
  payload: string
}

interface ISketchMaterial {
  type: ActionType.STORE_SKETCH_MATERIAL,
  payload: Material[]
}

interface ISketchColor {
  type: ActionType.STORE_SKETCH_COLORS,
  payload: Color[]
}

export type Action = IClearMaterial | IMaterialLoading | IMaterialError | ISketchMaterial | ISketchColor;
