import { Quote } from "api/types/quote";

export enum ActionType {
  SORT_QUOTES = "SORT_QUOTES"
}

interface ISortQuotes {
  type: ActionType.SORT_QUOTES,
  payload: Quote[]
}

export type Action = ISortQuotes;
