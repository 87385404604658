/// HOOKS ///
import { useNavigate } from "react-router-dom";
/// REACT COMPONENTS ///
import { ReactElement } from "react";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
/// FM ///
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
/// STYLES ///
import { tile_btn_style } from "styles/dashboard";
import { tile_animate_style } from "styles/dashboard";
import { tile_text_style } from "styles/dashboard";

interface Props {
  index: number,
  title: string,
  url: string,
  show?: boolean,
  icon?: ReactElement,
  external?: boolean
}

export default function DashboardTile({ index, title, url, show = true, icon, external = false }: Props) {
  const navigate = useNavigate();

  if (!show) {
    return null;
  }

  const goTo = () => {
    if (external) {
      window.open(url, "_blank").focus();
    }
    else {
      navigate(url);
    }
  }

  return (
    <Grid item xs={12}>
      <AnimatePresence>
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.4 * index / 5 } }}
          style={tile_btn_style}
          onClick={goTo}
          whileHover={tile_animate_style}
          whileTap={{ scale: 0.95 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {icon ? icon : null}
            <h6 style={tile_text_style}>
              {title}
            </h6>
          </Stack>
        </motion.button>
      </AnimatePresence>
    </Grid>
  )
}
