import { Vector2 } from "api/types/sketch";
import { Text } from "api/types/sketch";
import { WIDTH } from "values/text";

export function widthText(
    text_location: Vector2,
    width: number,
    point_uuid: string,
    counter_uuid: string
): Text {
    const text: string = '"';
    return {
        location: text_location,
        value: width / 4,
        real_value: width,
        text: text,
        corner_uuid: "",
        point_uuid: point_uuid,
        counter_uuid: counter_uuid,
        type: WIDTH,
        angle: 0,
        mirror: false
    };
}
