import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/billing";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from "api/action_types/billing";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { IBillingCx } from "api/types/billing";
import { url_prefix } from "settings";

const billing_customer_url = "billing_customer";

export const getStripeCustomer = () => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.STRIPE_CUSTOMER_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${billing_customer_url}/`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_STRIPE_CUSTOMER_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.STRIPE_CUSTOMER_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const createStripeCustomer = (customer: IBillingCx, user_id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.STRIPE_CUSTOMER_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${billing_customer_url}/?user_id=${user_id}`;

    try {
      const { data } = await axios.post(url, customer, config);

      dispatch({
        type: ActionType.CREATE_STRIPE_CUSTOMER_SUCCESS,
        payload: data.customer_id
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.STRIPE_CUSTOMER_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const updateStripeCustomer = (customer: IBillingCx) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.STRIPE_CUSTOMER_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${billing_customer_url}/?update=true&`;

    try {
      await axios.post(url, customer, config);

      dispatch({
        type: ActionType.UPDATE_STRIPE_CUSTOMER_SUCCESS,
        billing_cx: customer
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.STRIPE_CUSTOMER_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
