import { Counter } from "api/types/sketch";
import { SelfOrderCounter } from "api/types/order/self_order";
import { OrderCounter } from "api/types/order/order";
import { Sink } from "api/types/sketch";
import { SelfOrderSink } from "api/types/order/self_order";
import { OrderSink } from "api/types/order/order";
import { Vector2 } from "api/types/sketch";
import { createRectangleSink } from "./create";
import { createOvalSink } from "./create";
import { createDoubleSink } from "./create";
import { createThirtySeventySink } from "./create";
import { createSeventyThirtySink } from "./create";
import { createSixtyFortySink } from "./create";
import { createFortySixtySink } from "./create";
import { RECTANGLE } from "values/values";
import { OVAL } from "values/values";
import { DOUBLE } from "values/values";
import { THIRTY_OVER_SEVENTY } from "values/values";
import { SEVENTY_OVER_THIRTY } from "values/values";
import { SIXTY_OVER_FORTY } from "values/values";
import { FORTY_OVER_SIXTY } from "values/values";

export function drawOrderSinks(
  path: Path2D,
  counters: OrderCounter[] | SelfOrderCounter[] | Counter[],
  offset: Vector2,
  zoom: number
) {
  for (let i = 0; i < counters.length; i++) {
    drawOrderSink(path, counters[i], offset, zoom);
  }
}

export function drawOrderSink(
  sink_path: Path2D,
  counter: OrderCounter | SelfOrderCounter | Counter,
  offset: Vector2,
  zoom: number
) {
  for (let j = 0; j < counter.sinks.length; j++) {
    const sink: OrderSink | SelfOrderSink | Sink = counter.sinks[j];
    const location: Vector2 = {
      X: Math.round(sink.location.X * zoom) + offset.X,
      Y: Math.round(sink.location.Y * zoom) + offset.Y
    }

    if (sink.shape === RECTANGLE) {
      createRectangleSink(
        sink_path,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === OVAL) {
      createOvalSink(
        sink_path,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === DOUBLE) {
      createDoubleSink(
        sink_path,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === THIRTY_OVER_SEVENTY) {
      createThirtySeventySink(
        sink_path,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === SEVENTY_OVER_THIRTY) {
      createSeventyThirtySink(
        sink_path,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === FORTY_OVER_SIXTY) {
      createFortySixtySink(
        sink_path,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
    else if (sink.shape === SIXTY_OVER_FORTY) {
      createSixtyFortySink(
        sink_path,
        sink.sink_type,
        location,
        sink.angle,
        sink.width,
        sink.length,
        sink.faucet_hole_count,
        { X: 0, Y: 0 },
        zoom,
        true,
        true
      );
    }
  }
}
