import { FabricatorPriceList } from "api/types/fabricator";
import { FabricatorMiter } from "api/types/fabricator";
import { FabricatorSplash } from "api/types/fabricator";
import { FabricatorWaterfall } from "api/types/fabricator";
import { FabricatorFabricationCharge } from "api/types/fabricator";
import { FabricatorInstallationCharge } from "api/types/fabricator";
import { FabricatorFinishedEdge } from "api/types/fabricator";
import { FabricatorApplianceEdge } from "api/types/fabricator";
import { FabricatorFaucetCutout } from "api/types/fabricator";
import { FabricatorOutletCutout } from "api/types/fabricator";
import { FabricatorSinkCutout } from "api/types/fabricator";
import { FabricatorCooktopCutout } from "api/types/fabricator";
import { FabricatorClippedCorner } from "api/types/fabricator";
import { FabricatorNotchedCorner } from "api/types/fabricator";
import { FabricatorBumpedOutCorner } from "api/types/fabricator";
import { FabricatorRadiusCorner } from "api/types/fabricator";
import { FabricatorInsideRadiusCorner } from "api/types/fabricator";
import { FabricatorInsideDiagonalCorner } from "api/types/fabricator";
import { FabricatorRecessedDiagonalCorner } from "api/types/fabricator";
import { FabricatorBumpIn } from "api/types/fabricator";
import { FabricatorBumpOut } from "api/types/fabricator";
import { FabricatorBumpInArc } from "api/types/fabricator";
import { FabricatorBumpOutArc } from "api/types/fabricator";
import { FabricatorFullArc } from "api/types/fabricator";
import { FabricatorStoneCountertopRemoval } from "api/types/fabricator";
import { FabricatorLaminateCountertopRemoval } from "api/types/fabricator";
import { Action } from "api/action_types/fabricator";
import { ActionType } from "api/action_types/fabricator";

interface State {
  fabricator_price_list: FabricatorPriceList,
  fabricator_price_lists: FabricatorPriceList[],
  fabricator_miters: FabricatorMiter[],
  fabricator_splashes: FabricatorSplash[],
  fabricator_waterfalls: FabricatorWaterfall[],
  fabricator_fabrication_charges: FabricatorFabricationCharge[],
  fabricator_installation_charges: FabricatorInstallationCharge[],
  fabricator_finished_edges: FabricatorFinishedEdge[],
  fabricator_appliance_edges: FabricatorApplianceEdge[],
  fabricator_faucet_cutouts: FabricatorFaucetCutout[],
  fabricator_outlet_cutouts: FabricatorOutletCutout[],
  fabricator_sink_cutouts: FabricatorSinkCutout[],
  fabricator_cooktop_cutouts: FabricatorCooktopCutout[],
  fabricator_clipped_corners: FabricatorClippedCorner[],
  fabricator_notched_corners: FabricatorNotchedCorner[],
  fabricator_bumped_out_corners: FabricatorBumpedOutCorner[],
  fabricator_radius_corners: FabricatorRadiusCorner[],
  fabricator_inside_radius_corners: FabricatorInsideRadiusCorner[],
  fabricator_inside_diagonal_corners: FabricatorInsideDiagonalCorner[],
  fabricator_recessed_diagonal_corners: FabricatorRecessedDiagonalCorner[],
  fabricator_bump_ins: FabricatorBumpIn[],
  fabricator_bump_outs: FabricatorBumpOut[],
  fabricator_bump_in_arcs: FabricatorBumpInArc[],
  fabricator_bump_out_arcs: FabricatorBumpOutArc[],
  fabricator_full_arcs: FabricatorFullArc[],
  fabricator_stone_countertop_removals: FabricatorStoneCountertopRemoval[],
  fabricator_laminate_countertop_removals: FabricatorLaminateCountertopRemoval[],
  loading: boolean,
  error: string
}

const initialState = {
  fabricator_price_list: {},
  fabricator_price_lists: [],
  fabricator_miters: [],
  fabricator_splashes: [],
  fabricator_waterfalls: [],
  fabricator_fabrication_charges: [],
  fabricator_installation_charges: [],
  fabricator_finished_edges: [],
  fabricator_appliance_edges: [],
  fabricator_faucet_cutouts: [],
  fabricator_outlet_cutouts: [],
  fabricator_sink_cutouts: [],
  fabricator_cooktop_cutouts: [],
  fabricator_clipped_corners: [],
  fabricator_notched_corners: [],
  fabricator_bumped_out_corners: [],
  fabricator_radius_corners: [],
  fabricator_inside_radius_corners: [],
  fabricator_inside_diagonal_corners: [],
  fabricator_recessed_diagonal_corners: [],
  fabricator_bump_ins: [],
  fabricator_bump_outs: [],
  fabricator_bump_in_arcs: [],
  fabricator_bump_out_arcs: [],
  fabricator_full_arcs: [],
  fabricator_stone_countertop_removals: [],
  fabricator_laminate_countertop_removals: [],
  loading: false,
  error: ""
}

export const fabricatorReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_FABRICATOR_PRICE_LIST_SUCCESS:
      return {
        ...state,
        fabricator_price_list: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.GET_FABRICATOR_PRICE_LISTS_SUCCESS:
      return {
        ...state,
        fabricator_price_lists: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.GET_FABRICATOR_MITERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_miters: action.payload
      }
    case ActionType.GET_FABRICATOR_SPLASHES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_splashes: action.payload
      }
    case ActionType.GET_FABRICATOR_WATERFALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_waterfalls: action.payload
      }
    case ActionType.GET_FABRICATOR_FABRICATION_CHARGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_fabrication_charges: action.payload
      }
    case ActionType.GET_FABRICATOR_INSTALLATION_CHARGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_installation_charges: action.payload
      }
    case ActionType.GET_FABRICATOR_FINISHED_EDGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_finished_edges: action.payload
      }
    case ActionType.GET_FABRICATOR_APPLIANCE_EDGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_appliance_edges: action.payload
      }
    case ActionType.GET_FABRICATOR_FAUCET_CUTOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_faucet_cutouts: action.payload
      }
    case ActionType.GET_FABRICATOR_OUTLET_CUTOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_outlet_cutouts: action.payload
      }
    case ActionType.GET_FABRICATOR_SINK_CUTOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_sink_cutouts: action.payload
      }
    case ActionType.GET_FABRICATOR_COOKTOP_CUTOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_cooktop_cutouts: action.payload
      }
    case ActionType.GET_FABRICATOR_CLIPPED_CORNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_clipped_corners: action.payload
      }
    case ActionType.GET_FABRICATOR_NOTCHED_CORNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_notched_corners: action.payload
      }
    case ActionType.GET_FABRICATOR_BUMPED_OUT_CORNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_bumped_out_corners: action.payload
      }
    case ActionType.GET_FABRICATOR_OUTSIDE_RADIUS_CORNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_radius_corners: action.payload
      }
    case ActionType.GET_FABRICATOR_INSIDE_RADIUS_CORNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_inside_radius_corners: action.payload
      }
    case ActionType.GET_FABRICATOR_INSIDE_DIAGONAL_CORNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_inside_diagonal_corners: action.payload
      }
    case ActionType.GET_FABRICATOR_RECESSED_DIAGONAL_CORNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_recessed_diagonal_corners: action.payload
      }
    case ActionType.GET_FABRICATOR_BUMP_INS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_bump_ins: action.payload
      }
    case ActionType.GET_FABRICATOR_BUMP_OUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_bump_outs: action.payload
      }
    case ActionType.GET_FABRICATOR_BUMP_IN_ARCS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_bump_in_arcs: action.payload
      }
    case ActionType.GET_FABRICATOR_BUMP_OUT_ARCS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_bump_out_arcs: action.payload
      }
    case ActionType.GET_FABRICATOR_FULL_ARCS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_full_arcs: action.payload
      }
    case ActionType.GET_FABRICATOR_STONE_COUNTERTOP_REMOVALS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_stone_countertop_removals: action.payload
      }
    case ActionType.GET_FABRICATOR_LAMINATE_COUNTERTOP_REMOVALS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_laminate_countertop_removals: action.payload
      }
    case ActionType.UPDATE_FABRICATOR_PRICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        fabricator_price_list: action.payload
      }
    case ActionType.UPDATE_FABRICATOR_SPLASH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_SPLASH_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_FABRICATION_CHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_INSTALLATION_CHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_FINISHED_EDGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_APPLIANCE_EDGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_FAUCET_CUTOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_OUTLET_CUTOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_SINK_CUTOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_SINK_CUTOUT_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_COOKTOP_CUTOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_COOKTOP_CUTOUT_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_INSIDE_RADIUS_CORNER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_OUTSIDE_RADIUS_CORNER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_FULL_ARC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_INSIDE_DIAGONAL_CORNER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_RECESSED_DIAGONAL_CORNER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_BUMPED_OUT_CORNER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_STONE_COUNTERTOP_REMOVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATOR_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      }
    case ActionType.CLEAR_FABRICATOR_PRICE_LIST:
      return {
        ...state,
        fabricator_price_list: null,
        fabricator_splashes: [],
        fabricator_fabrication_charges: [],
        fabricator_installation_charges: [],
        fabricator_finished_edges: [],
        fabricator_faucet_cutouts: [],
        fabricator_outlet_cutouts: [],
        fabricator_sink_cutouts: [],
        fabricator_cooktop_cutouts: [],
        fabricator_clipped_corners: [],
        fabricator_notched_corners: [],
        fabricator_bumped_out_corners: [],
        fabricator_recessed_diagonal_corners: [],
        fabricator_inside_diagonal_corners: [],
        fabricator_inside_radius_corners: [],
        fabricator_radius_corners: [],
        fabricator_bump_ins: [],
        fabricator_bump_outs: [],
        fabricator_bump_in_arcs: [],
        fabricator_bump_out_arcs: [],
        fabricator_full_arcs: [],
        fabricator_laminate_countertop_removals: [],
        fabricator_stone_countertop_removals: [],
      }
    default:
      return state;
  }
}



