/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/self_order";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/self_order";
/// TYPES ///
import { SelfOrder } from "api/types/order/self_order";
/// LODASH ///
import { orderBy } from "lodash";
/// SORT VALUES ///
import { NAME } from "values/sort/order";
import { CUSTOMER } from "values/sort/order";
import { DATE } from "values/sort/order";
import { TOTAL } from "values/sort/order";
import { NUMBER } from "values/sort/order";

function sort(sos: SelfOrder[], sort_type: number, sort_up: boolean): SelfOrder[] {
  let sort_field: string = "name";

  switch (sort_type) {
    case NAME:
      sort_field = "name";
      break;
    case CUSTOMER:
      sort_field = "end_customer_data.last_name";
      break;
    case DATE:
      sort_field = "end_customer_data.email";
      break;
    case TOTAL:
      sort_field = "total";
      break;
    case NUMBER:
      sort_field = "order_number";
      break;
    default:
      sort_field = "name";

  };

  return orderBy(sos, sort_field, sort_up ? "asc" : "desc");
}

export const sortShopOrders = (sort_type: number, sort_up: boolean) => {
  return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const { self_orders } = getState().self_order;

    dispatch({
      type: ActionType.SORT_SHOP_ORDER,
      orders: sort(self_orders, sort_type, sort_up)
    });
  }
}

