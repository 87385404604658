export const BUG: string = "B";
export const FEATURE: string = "F";
export const OTHER: string = "O";

export function getFeedbackTypeDisplay(feature_type: string): string {
  if (feature_type === BUG) {
    return "Bug";
  }
  else if (feature_type === FEATURE) {
    return "Feature";
  }
  else if (feature_type === OTHER) {
    return "Other";
  }
  return "Other";
}
