/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// REACT ///
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// UUID ///
import { v4 } from "uuid";
/// ACTIONS ///
import { createColorOption } from "api/actions/quotes/color_option";
import { updateArea } from "api/actions/quotes/area";
/// TYPES ///
import { Area } from "api/types/quote";
import { ColorOption } from "api/types/quote";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import StoreColorOptionItem from "./row/color";
import StoreMainColorItem from "./row/main_color";
import TooltipButton from "components/universal/button/tooltip";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
/// LODASH ///
import { debounce } from "lodash";
/// VALUES ///
import { header_style } from "styles/universal/section_header";

interface Props {
  area: Area,
  updateLoading: (is_loading: boolean, id: number | string) => void,
}

export default function StoreColorOptionArea({ area, updateLoading }: Props) {
  const dispatch = useAppDispatch();
  const [local_area, setLocalArea] = useState<Area>({});

  useEffect(() => {
    setLocalArea(area);
  }, [area]);

  const update = useCallback(debounce(function (name: string, uuid: string, id: string | number) {
    dispatch(updateArea({ uuid, name }, id, false));
    updateLoading(false, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 1000), []);

  const updateName = (name: string, uuid: string, id: string | number) => {
    setLocalArea({ ...local_area, name });
    update(name, uuid, id);
    updateLoading(true, id);
  }

  const addColorOption = () => {
    const color_option: ColorOption = {
      number: local_area?.color_options?.length ?? 0 + 1,
      uuid: v4(),
      area_uuid: local_area?.uuid,
      material: local_area?.material,
      color: local_area?.color,
      material_name: local_area?.material_name ?? "",
      material_type: local_area?.material_type ?? "",
      material_length: local_area?.material_length ?? 0,
      material_width: local_area?.material_length ?? 0,
      subtotal: 0,
    }
    dispatch(createColorOption(color_option));
  }

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6">
          Materials
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Area Name"
          value={local_area?.name ?? ""}
          onChange={(e) => updateName(e.target.value, local_area?.uuid, local_area?.id)} />
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          <TooltipButton text="Color Option" icon={faPlus} clickEvent={addColorOption} tooltip="Add Alternate Price to Estimate" />
        </div>
      </Grid>
      <StoreMainColorItem
        key="MAIN"
        area={local_area}
        description="Main Color" />
      {
        local_area.color_options ?
          local_area?.color_options?.map((color_option, index) => {
            return (
              <StoreColorOptionItem
                key={color_option?.uuid}
                color_option={color_option}
                description={"Color Option #" + (index + 1)} />
            )
          }) :
          null
      }
    </Grid>
  )
}
