import { IShopSlab } from "api/types/shop_quote/slab"

export enum ActionType {
  CREATE_LOCAL_SHOP_SLAB = "CREATE_LOCAL_SHOP_SLAB",
  DELETE_LOCAL_SHOP_SLAB = "DELETE_LOCAL_SHOP_SLAB"
}

interface ICreateLocalShopSlab {
  type: ActionType.CREATE_LOCAL_SHOP_SLAB,
  payload: IShopSlab
}

interface IDeleteLocalShopSlab {
  type: ActionType.DELETE_LOCAL_SHOP_SLAB,
  payload: IShopSlab
}

export type Action =
  ICreateLocalShopSlab |
  IDeleteLocalShopSlab;
