import { Area } from "api/types/quote";

export enum ActionType {
  GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS",
  CREATE_AREA = "CREATE_AREA",
  CREATE_LOCAL_AREA = "CREATE_LOCAL_AREA",
  UPDATE_LOCAL_AREA = "UPDATE_LOCAL_AREA",
  DELETE_LOCAL_AREA = "DELETE_LOCAL_AREA"
}

interface IGetAreasSuccess {
  type: ActionType.GET_AREAS_SUCCESS,
  payload: Area[]
}

interface ICreateArea {
  type: ActionType.CREATE_AREA,
  payload: Area
}

interface ICreateLocalArea {
  type: ActionType.CREATE_LOCAL_AREA,
  payload: Area
}

interface IUpdateLocalArea {
  type: ActionType.UPDATE_LOCAL_AREA,
  payload: Area
}

interface IDeleteLocalArea {
  type: ActionType.DELETE_LOCAL_AREA,
  payload: string
}

export type Action = IGetAreasSuccess | ICreateArea | ICreateLocalArea | IUpdateLocalArea | IDeleteLocalArea;
