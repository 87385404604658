/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateCounter } from "api/actions/sketch/counter";
import { updateCutShape } from "api/actions/sketch/cut_shape";
import { updateLocalCounter } from "api/actions/sketch/counter";
import { updateLocalCutShape } from "api/actions/sketch/cut_shape";
import { restore } from "api/actions/sketch/history";
/// TYPES ///
import { Area } from "api/types/quote";
import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
/// COMPONENTS ///
import UpdateArea from "components/quote/drawer/update_area";
/// FUNCTIONS ///
import { getAreaById } from "functions/sketch/get/area";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  counter: Counter
}

export default function UpdateStoreArea({ open, setOpen, counter }: Props) {
  const dispatch = useAppDispatch();
  const { cut_shape_history } = useAppSelector((state) => state.history);
  const { counter_history } = useAppSelector((state) => state.history);
  const { history_position } = useAppSelector((state) => state.history);
  const { areas } = useAppSelector((state) => state.quote);
  const { cut_shapes } = useAppSelector((state) => state.sketch);
  const [area, setArea] = useState<Area>({});
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const counter_area: Area | null = getAreaById(areas, counter.area);
    setArea(counter_area);
  }, [areas, counter?.area]);

  const save = () => {
    if (area.id > 0) {
      dispatch(updateCounter({ ...counter, area_uuid: area.uuid, area: area.id, drawing_color: area.number - 1 }));
      for (let i = 0; i < cut_shapes.length; i++) {
        if (cut_shapes[i].counter_uuid === counter.uuid) {
          dispatch(updateLocalCutShape({ ...cut_shapes[i], area_uuid: area.uuid, area: area.id }));
          dispatch(updateCutShape({ ...cut_shapes[i], area_uuid: area.uuid, area: area.id }));
        }
      }
      setOpen(false);
    }
    else {
      setError(true);
    }
  }

  const update = (area_id: number) => {
    const area: Area = getAreaById(areas, area_id);
    setArea(area);
    dispatch(updateLocalCounter({ ...counter, area_uuid: area.uuid, area: area.id, drawing_color: area.number - 1 }));
    setError(false);
  }

  const close = () => {
    dispatch(restore(counter_history[history_position] as Counter[], cut_shape_history[history_position] as CutShape[]));
    setOpen(false);
  }

  return (
    <UpdateArea
      open={open}
      area_id={area?.id ?? 0}
      areas={areas}
      error={error}
      update={update}
      close={close}
      save={save} />
  );
}
