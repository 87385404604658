/// TYPES ///
import { IAddon } from "api/types/quote";
/// MUI COMPONENTS ///
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { line_item_motion } from "motion/quote";
/// ICONS ///
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";
import { edit_icn_btn_style } from "styles/sketch";
import { edit_icn_style } from "styles/sketch";
import { del_icn_btn_style } from "styles/sketch";
import { del_icn_style } from "styles/sketch";

interface Props {
  addon: IAddon,
  deleteAddon: (item: IAddon) => void,
  editAddon: (item: IAddon) => void,
  show_qty?: boolean
}

export default function AddonRow({ addon, deleteAddon, editAddon, show_qty = false }: Props) {
  return (
    <Grid item xs={12}>
      <motion.div whileHover="show" initial="no_show" style={{ display: "flex", justifyContent: "space-between" }}>
        <Stack direction="row" justifyContent="flex-start" sx={item_style()}>
          <Typography variant="body2">
            {addon?.name ?? ""}
          </Typography>
          <motion.div variants={line_item_motion} style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => editAddon(addon)} sx={edit_icn_btn_style}>
              <FontAwesomeIcon icon={faEdit} style={edit_icn_style} />
            </IconButton>
            <IconButton onClick={() => deleteAddon(addon)} sx={del_icn_btn_style}>
              <FontAwesomeIcon icon={faEraser} style={del_icn_style} />
            </IconButton>
          </motion.div>
        </Stack>
        <Typography variant="body2" align="right" sx={{ ...item_style(), flexGrow: 1 }}>
          ${(addon?.price ?? 0).toFixed(2)}
        </Typography>
      </motion.div>
    </Grid >
  )
}
