import { QuoteLineItem } from "api/types/quote";

export enum ActionType {
  SET_SELECTED_LINE_ITEM = "SET_SELECTED_LINE_ITEM"
}

interface ISetSelectedLineItem {
  type: ActionType.SET_SELECTED_LINE_ITEM,
  payload: QuoteLineItem
}

export type Action = ISetSelectedLineItem;
