/// REACT ///
import { MouseEvent as ReactMouseEvent } from "react";
import { SyntheticEvent } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import useDrawShop from "hooks/draw_shop";
import useMousePosition from "hooks/mouse_position";
/// UUID ///
import { v4 } from "uuid";
/// HANDLERS ///
import { handleCreateShopCounter } from "handler/counter/create";
import { handleDeleteShopCounter } from "handler/counter/delete";
import { handleRotateShopCounter } from "handler/counter/rotate";
/// TYPES ///
import { HighlightData } from "types/draw";
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopLabel } from "api/types/shop_quote/label";
import { IShopPoint } from "api/types/shop_quote/point";
import { IShopSlab } from "api/types/shop_quote/slab";
import { MouseData } from "types/mouse_position";
import { Vector2 } from "api/types/sketch";
/// ACTIONS ///
import { updateShopCounter } from "api/actions/shop_quote";
import { createShopLabel } from "api/actions/shop_quote";
import { deleteShopLabel } from "api/actions/shop_quote";
import { updateShopLabel } from "api/actions/shop_quote";
import { updateLocalShopLabel } from "api/actions/shop_quote";
import { createLocalShopCounter } from "api/actions/shop_quote";
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { createBulkShopCutShapes } from "api/actions/shop_quote";
import { updateLocalShopCutShapes } from "api/actions/shop_quote";
import { createRestorePoint } from "api/actions/history";
import { markShopQuoteChanged } from "api/actions/shop_quote";
/// FUNCTIONS ///
import { getDirection } from "functions/sketch";
import { getNextPoint } from "functions/sketch";
import { getBranchAngle } from "functions/sketch";
import { isPointInRectangle } from "functions/sketch/math";
import { getDistance } from "functions/sketch/math";
import { getDimensions } from "functions/sketch/math";
import { checkShopCounterClicked } from "functions/sketch/check/counter";
import { getCutShapeByCounterUuid } from "functions/sketch";
import { getFirstShopSlabInAreas } from "functions/sketch/get/shop_slab";
import { moveShopCounter } from "functions/sketch/move/shop_counter";
import { reverseShopCounter } from "functions/sketch/update/shop_counter";
import { checkIfCornerIsBeginning } from "functions/sketch/check";
import { duplicateShopCounter } from "functions/sketch/duplicate/counter";
import { getShopCounterByUuid } from "functions/sketch/get/counter";
import { createCutShapes } from "functions/sketch";
import { getMouseAtZoom } from "functions/sketch";
import { moveVector } from "functions/sketch/move/vector";
import { createShopCorners } from "functions/sketch/create/shop_corner";
/// MUI ///
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// COMPONENTS ///
import ShopAreaBar from "../bar/area";
import LabelMenu from "components/quote/menu/label";
import SketchMenu from "components/quote/menu/sketch";
import UpdateShopArea from "../drawer/update_area";
import UpdateShopDegrees from "components/shop_quote/quote/drawer/update_degrees";
import UpdateShopHeight from "../drawer/update_height";
import UpdateShopWidth from "../drawer/update_width";
import UpdateShopLabel from "../dialog/label/update";
/// FUNCTIONS ///
import { checkIndicatorSelected } from "functions/sketch/check/indicator";
import { updateShopAngle } from "functions/sketch/update/shop_angle";
import { updateShopHeight } from "functions/sketch/update/shop_height";
import { updateShopWidth } from "functions/sketch/update/shop_width";
/// EMPTY VALUES ///
import { empty_counter } from "values/empty/quote/counter";
import { empty_point } from "values/empty/quote/point";
/// STYLES ///
import { sketch_style } from "styles/sketch";
import { canvasStyle } from "styles/sketch";
/// TEXT VALUES ///
import { HEIGHT } from "values/text";
import { WIDTH } from "values/text";
import { ANGLE } from "values/text";
/// VALUES ///
import { getCopyMessage } from "values/text";

interface Props {
  index: number,
  zoom: number,
  setDisabled: (disabled: boolean) => void,
  current_area: number,
  setCurrentArea: (area: number) => void,
  copying: boolean,
  setCopying: (copying: boolean) => void
}

export default function ShopMeasurements({
  index,
  zoom,
  setDisabled,
  current_area,
  setCurrentArea,
  copying,
  setCopying
}: Props) {
  const dispatch = useAppDispatch();
  const [selected_counter, setSelectedCounter] = useState<IShopCounter | null>(null);
  const mouse_data: MouseData = useMousePosition();
  const ref = mouse_data.ref;
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { cut_shapes } = useAppSelector((state) => state.shop_quote);
  const { labels } = useAppSelector((state) => state.shop_quote);
  const { quote } = useAppSelector((state) => state.shop_quote);
  const { areas } = useAppSelector((state) => state.shop_quote);
  const [lifting_label, setLiftingLabel] = useState<boolean>(false);
  const [selected_label, setSelectedLabel] = useState<IShopLabel | null>(null);
  const [in_operation, setInOperation] = useState<boolean>(false);
  const [drawing, setDrawing] = useState<boolean>(false);
  const [lifting, setLifting] = useState<boolean>(false);
  const [lift_point, setLiftPoint] = useState<Vector2>({ X: 0, Y: 0 });
  const [menu_location, setMenuLocation] = useState<Vector2>({ X: 0, Y: 0 });
  const [menu_open, setMenuOpen] = useState<boolean>(false);
  const [label_menu_open, setLabelMenuOpen] = useState<boolean>(false);
  const [edit_label_open, setEditLabelOpen] = useState<boolean>(false);
  const [height_open, setHeightOpen] = useState<boolean>(false);
  const [width_open, setWidthOpen] = useState<boolean>(false);
  const [degrees_open, setDegreesOpen] = useState<boolean>(false);
  const [area_open, setAreaOpen] = useState<boolean>(false);
  const [redrawing, setRedrawing] = useState<boolean>(false);
  const [create_area_open, setCreateAreaOpen] = useState<boolean>(false);
  const [current_uuid, setCurrentUuid] = useState<string>("");
  const [original_value, setOriginalValue] = useState<number | null>(null);
  const [copy, setCopy] = useState<HighlightData | null>(null);

  const highlight_data: HighlightData = useDrawShop(
    index,
    selected_counter,
    areas[current_area]?.uuid,
    mouse_data,
    height_open || width_open || degrees_open || area_open || create_area_open || create_area_open || label_menu_open || edit_label_open,
    drawing,
    lifting,
    in_operation,
    zoom,
    {
      show_measurements: true,
      ends_selectable: true,
      show_degrees: true,
      text_selectable: !drawing && !height_open && !width_open && !degrees_open && !area_open && !lifting && !menu_open && !create_area_open && !label_menu_open && !edit_label_open
    }
  );

  const handleEvent = (event: SyntheticEvent) => {
    event.preventDefault();

    const mouse_event: MouseEvent | null = event.nativeEvent instanceof MouseEvent ? event.nativeEvent as MouseEvent : null;

    const mouse: Vector2 = mouse_data.position;
    if ((mouse_event?.type === "mouseup" && mouse_event?.button === 0)) {
      if (copying) {
        setInOperation(false);
        return;
      }
      if (drawing) {
        if (selected_counter && quote?.id && redrawing) {
          const counter: IShopCounter = { ...selected_counter };
          const cut_shape: IShopCutShape | null = getCutShapeByCounterUuid(cut_shapes, counter.uuid);
          const generated_cut_shapes: IShopCutShape[] = createCutShapes(
            counter,
            cut_shape?.area_uuid ?? areas[0]?.uuid,
            cut_shape?.slab_uuid ?? areas[0]?.slabs[0]?.uuid,
            counter?.points,
            counter?.corners,
            []
          );
          dispatch(updateShopCounter(counter));
          dispatch(updateLocalShopCutShapes(generated_cut_shapes, counter.uuid));
          dispatch(createBulkShopCutShapes(generated_cut_shapes));
          dispatch(createRestorePoint(
            [...counters.filter(c => c.uuid !== counter.uuid), counter],
            [...cut_shapes],
            "Move Counter"
          ));
          dispatch(markShopQuoteChanged());
        }
        else {
          if (
            quote &&
            areas?.length > 0 &&
            selected_counter?.uuid &&
            selected_counter?.points?.length > 1 &&
            selected_counter?.corners?.length > 3
          ) {
            const counter: IShopCounter = { ...selected_counter, drawing_color: current_area };
            handleCreateShopCounter(
              dispatch,
              quote.id,
              counter,
              counters,
              cut_shapes,
              areas[current_area]?.uuid ?? areas[0]?.uuid ?? "could not find an area",
              areas[current_area]?.slabs[0]?.uuid ?? areas[0]?.uuid ?? "could not find a slab"
            );
          }
          setSelectedCounter(null);
        }
        setDrawing(false);
        setDisabled(false);
      }
      else if (lifting) {
        if (selected_counter) {
          const counter: IShopCounter | null = getShopCounterByUuid(selected_counter.uuid, counters);
          if (counter) {
            dispatch(updateShopCounter(counter));
            dispatch(createRestorePoint(
              [...counters.filter(c => c.uuid !== counter.uuid), counter],
              [...cut_shapes],
              "Move Counter"
            ));
          }
        }
        setLifting(false);
      }
      else if (lifting_label && selected_label) {
        const label: IShopLabel = { ...selected_label };
        const vector: Vector2 = moveVector(selected_label.location, getMouseAtZoom(mouse, zoom));
        dispatch(updateShopLabel({ ...label, location: vector }, label.id));
        dispatch(updateLocalShopLabel({ ...label, location: vector }));
        setSelectedLabel(null);
        setLiftingLabel(false);
      }
      setInOperation(false);
      return;
    }
    if (in_operation) {
      return;
    }

    setInOperation(true);
    if ((mouse_event?.type === "mousedown" && mouse_event?.button === 0)) {
      if (copying && !copy) {
        if (highlight_data?.text) {
          if (
            highlight_data?.text?.type === WIDTH ||
            highlight_data?.text?.type === HEIGHT ||
            highlight_data?.text?.type === ANGLE
          ) {
            setCopy(highlight_data);
          }
        }
        setInOperation(false);
        return;
      }
      else if (copying && copy) {
        if (highlight_data?.text?.type === WIDTH && (copy?.text?.type === WIDTH || copy?.text?.type === HEIGHT)) {
          const counter: IShopCounter | null = getShopCounterByUuid(highlight_data?.text?.counter_uuid, counters);
          const update_counter: IShopCounter | null = updateShopWidth(counter, highlight_data?.text?.point_uuid, copy?.text?.value);
          const new_corners: IShopCorner[] = createShopCorners(update_counter.points, [...update_counter.corners], counter.uuid);
          if (update_counter) {
            dispatch(updateLocalShopCounter({ ...update_counter, corners: new_corners }));
            dispatch(updateShopCounter({ ...update_counter, corners: new_corners }));
          }
        }
        else if (highlight_data?.text?.type === HEIGHT && (copy?.text?.type === HEIGHT || copy?.text?.type === WIDTH)) {
          const counter: IShopCounter | null = getShopCounterByUuid(highlight_data?.text?.counter_uuid, counters);
          const update_counter: IShopCounter | null = updateShopHeight(counter, copy?.text?.value, highlight_data?.text?.value, highlight_data?.text?.point_uuid);
          const new_corners: IShopCorner[] = createShopCorners(update_counter.points, [...update_counter.corners], counter.uuid);
          if (update_counter) {
            dispatch(updateLocalShopCounter({ ...update_counter, corners: new_corners }));
            dispatch(updateShopCounter({ ...update_counter, corners: new_corners }));
          }
        }
        else if (highlight_data?.text?.type === ANGLE && copy?.text?.type === ANGLE) {
          const counter: IShopCounter | null = getShopCounterByUuid(highlight_data?.text?.counter_uuid, counters);
          const update_counter: IShopCounter | null = updateShopAngle(counter, highlight_data?.text?.point_uuid, copy?.text?.value);
          if (update_counter) {
            dispatch(updateLocalShopCounter(update_counter));
            dispatch(updateShopCounter(update_counter));
          }
        }
        setInOperation(false);
        return;
      }
      if (!height_open && !width_open && !degrees_open && !area_open && !create_area_open && !menu_open && !lifting && !drawing && !label_menu_open && !edit_label_open) {
        for (let i = 0; i < labels.length; i++) {
          if (checkIndicatorSelected(labels[i].location, getMouseAtZoom(mouse, zoom), true, 55)) {
            setSelectedLabel(labels[i]);
            setLiftingLabel(true);
            return;
          }
        }

        if (highlight_data && highlight_data.corner) {
          setDisabled(true);
          const counter: IShopCounter | null = getShopCounterByUuid(highlight_data.corner.counter_uuid, counters);
          if (counter) {
            if (checkIfCornerIsBeginning(counter, highlight_data.corner)) {
              const reversed_counter: IShopCounter = reverseShopCounter(counter);
              setSelectedCounter(reversed_counter);
              dispatch(updateLocalShopCounter(reversed_counter));
            }
            else {
              setSelectedCounter(counter);
            }
          }
          setRedrawing(true);
          setDrawing(true);
          setInOperation(false);
          return;
        }

        if (highlight_data && highlight_data.text) {
          if (highlight_data.text.type === WIDTH) {
            setWidthOpen(true);
            const counter: IShopCounter | null = getShopCounterByUuid(highlight_data.text.counter_uuid, counters);
            setSelectedCounter(counter);
            setCurrentUuid(highlight_data.text.point_uuid);
            setOriginalValue(highlight_data.text.value);
            setInOperation(false);
            return;
          }
          else if (highlight_data.text.type === HEIGHT) {
            setHeightOpen(true);
            const counter: IShopCounter | null = getShopCounterByUuid(highlight_data.text.counter_uuid, counters);
            setSelectedCounter(counter);
            setCurrentUuid(highlight_data.text.point_uuid);
            setOriginalValue(highlight_data.text.value)
            setInOperation(false);
            return;
          }
          else if (highlight_data.text.type === ANGLE) {
            setDegreesOpen(true);
            const counter: IShopCounter | null = getShopCounterByUuid(highlight_data.text.counter_uuid, counters);
            setSelectedCounter(counter);
            setCurrentUuid(highlight_data.text.point_uuid);
            setOriginalValue(highlight_data.text.value);
            setInOperation(false);
            return;
          }
        }
        let lifting: boolean = false;

        const counter: IShopCounter | null = checkShopCounterClicked(counters.filter(c => c.area_uuid === areas[current_area].uuid), getMouseAtZoom(mouse, zoom));
        if (counter && !in_operation) {
          setLiftPoint(mouse);
          setSelectedCounter(counter);
          lifting = true;
          setLifting(true);
        }
        if (!lifting && quote) {
          setRedrawing(false);
          const counter_uuid: string = v4();
          const new_counter: IShopCounter = {
            ...empty_counter,
            quote: quote.id,
            uuid: counter_uuid,
            drawing_color: current_area ?? 0,
            area: areas[current_area]?.id ?? areas[0]?.id ?? 0,
            area_uuid: areas[current_area]?.uuid ?? "area not detected",
            points: [{ ...empty_point, location: getMouseAtZoom(mouse, zoom), uuid: v4() }]
          }
          dispatch(createLocalShopCounter(new_counter));
          setSelectedCounter(new_counter);
          setDrawing(true);
          setDisabled(true);
        }
      }
      else {
        const target: HTMLDivElement = event.target as HTMLDivElement;
        if (!target.id.startsWith("menu")) {
          setMenuOpen(false);
          setLabelMenuOpen(false);
        }
      }
    }
    else if ((mouse_event?.type === "mousedown" && mouse_event?.button === 2)) {
      if (copying) {
        setCopying(false);
        setCopy(null);
        setInOperation(false);
        return;
      }
      for (let i = 0; i < labels.length; i++) {
        if (checkIndicatorSelected(labels[i].location, getMouseAtZoom(mouse, zoom), true)) {
          setSelectedLabel(labels[i]);
          setMenuLocation({
            X: mouse_event.pageX,
            Y: mouse_event.pageY
          });
          setLabelMenuOpen(true);
          setInOperation(false);
          return;
        }
      }

      const counter: IShopCounter | null = checkShopCounterClicked(counters.filter(c => c.area_uuid === areas[current_area].uuid), getMouseAtZoom(mouse, zoom));

      if (counter && !in_operation) {
        setSelectedCounter(counter);
        setMenuLocation({
          X: mouse_event.pageX,
          Y: mouse_event.pageY
        });
        setMenuOpen(true);
        setInOperation(false);
        return;
      }
    }
    setInOperation(false);
  }

  useEffect(() => {
    const mouse: Vector2 = getMouseAtZoom(mouse_data.position, zoom);

    if (drawing && selected_counter !== null) {
      const counter: IShopCounter = { ...selected_counter };
      const num_points: number = counter.points.length;
      const position: number = num_points - 1;

      if (num_points === 1) {
        const dimensions: Vector2 = getDimensions(counter.points[position].location, mouse);
        const angle: number = getDirection(dimensions);
        const next_location: Vector2 = getNextPoint(counter.points[position].location, angle, dimensions);
        const next_point: IShopPoint = { ...empty_point, uuid: v4(), location: next_location, angle: angle, position: position + 1 };
        const points: IShopPoint[] = [...counter.points, next_point];
        const corners: IShopCorner[] = createShopCorners(points, selected_counter.corners, selected_counter.uuid);
        const new_counter: IShopCounter = { ...counter, corners, points };
        dispatch(updateLocalShopCounter(new_counter));
        setSelectedCounter(new_counter);
        return;
      }
      else if (num_points === 2) {
        const last_point: IShopPoint = { ...counter.points[position - 1] };
        const current_point: IShopPoint = { ...counter.points[position] };
        const dimensions: Vector2 = getDimensions(last_point.location, mouse);
        const angle: number = getDirection(dimensions);
        last_point.angle = angle;
        const new_location: Vector2 = getNextPoint(last_point.location, angle, dimensions);
        current_point.location = new_location;
        let points: IShopPoint[] = [last_point, current_point];
        const distance = getDistance(last_point.location, current_point.location);
        const branch_angle: number | null = getBranchAngle(current_point.location, angle, last_point.width, mouse);
        if (distance > 150 && branch_angle !== null) {
          const next_location: Vector2 = getNextPoint(points[position].location, branch_angle, dimensions);
          points = [...points.slice(0, -1),
          { ...current_point, angle: branch_angle },
          {
            ...empty_point,
            location: next_location,
            uuid: v4(),
            angle: branch_angle,
            width: last_point.width,
            position: position + 1
          }
          ];
        }
        const corners: IShopCorner[] = createShopCorners(points, selected_counter.corners, selected_counter.uuid);
        const new_counter: IShopCounter = { ...counter, points, corners };
        dispatch(updateLocalShopCounter(new_counter));
        setSelectedCounter(new_counter);
        return;
      }
      else if (num_points > 2) {
        const last_point: IShopPoint = { ...counter.points[position - 1] };
        const current_point: IShopPoint = { ...counter.points[position] };
        const dimensions: Vector2 = getDimensions(last_point.location, mouse);

        const new_location: Vector2 = getNextPoint(last_point.location, last_point.angle, dimensions);
        current_point.location = new_location;

        let points: IShopPoint[] = [...counter.points.slice(0, -2), last_point, current_point];

        if (isPointInRectangle(last_point.location, last_point.angle, last_point.width, last_point.width, current_point.location)) {
          points.pop();
          const corners: IShopCorner[] = createShopCorners(points, selected_counter.corners, selected_counter.uuid);
          const new_counter: IShopCounter = { ...counter, points, corners };
          dispatch(updateLocalShopCounter(new_counter));
          setSelectedCounter(new_counter);
          return;
        }

        const distance = getDistance(last_point.location, current_point.location);
        const branch_angle: number | null = getBranchAngle(current_point.location, last_point.angle, last_point.width, mouse);

        if (distance > 150 && branch_angle !== null && (
          branch_angle === last_point.angle + 90 ||
          branch_angle === last_point.angle - 90 ||
          (branch_angle + last_point.angle + 90) % 360 === 0 ||
          (branch_angle + last_point.angle - 90) % 360 === 0
        )) {
          const next_location: Vector2 = getNextPoint(points[position].location, branch_angle, dimensions);
          points = [...points.slice(0, -1),
          { ...current_point, angle: branch_angle },
          {
            ...empty_point,
            location: next_location,
            uuid: v4(),
            angle: branch_angle,
            width: last_point.width,
            position: position + 1
          }
          ];
        }

        const corners: IShopCorner[] = createShopCorners(points, selected_counter.corners, selected_counter.uuid);
        const new_counter: IShopCounter = { ...counter, points, corners };
        dispatch(updateLocalShopCounter(new_counter));
        setSelectedCounter(new_counter);
      }
      else {
        return;
      }
    }
    else if (lifting && selected_counter !== null) {
      if (selected_counter) {
        const counter: IShopCounter | null = moveShopCounter(selected_counter, getMouseAtZoom(lift_point, zoom), mouse);
        if (counter) {
          dispatch(updateLocalShopCounter(counter));
        }
      }
    }
    else if (selected_label && lifting_label) {
      const vector: Vector2 = moveVector(selected_label.location, getMouseAtZoom(mouse_data.position, zoom));
      dispatch(updateLocalShopLabel({ ...selected_label, location: vector }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouse_data.position]);

  const deleteSelectedCounter = () => {
    setInOperation(true);
    if (selected_counter) {
      const counter_to_delete: IShopCounter = { ...selected_counter };
      const uuid: string = counter_to_delete.uuid;
      const counter_id: number = counter_to_delete.id;
      handleDeleteShopCounter(
        dispatch,
        setSelectedCounter,
        uuid,
        counter_id,
        counters,
        cut_shapes
      );

    }
    setInOperation(false);
    setMenuOpen(false);
  }

  const rotate = (rotation: number) => {
    if (selected_counter) {
      handleRotateShopCounter(
        dispatch,
        setSelectedCounter,
        { ...selected_counter },
        counters,
        cut_shapes,
        rotation
      );
    }
  }

  const handleDuplicateCounter = () => {
    setInOperation(true);
    if (selected_counter) {
      const counter_copy: IShopCounter = { ...selected_counter };
      const counter: IShopCounter = duplicateShopCounter(counter_copy);
      const slab: IShopSlab | null = getFirstShopSlabInAreas(areas, counter.area_uuid);
      handleCreateShopCounter(
        dispatch,
        quote.id,
        counter,
        counters,
        cut_shapes,
        counter.area_uuid,
        slab ? slab.uuid : areas[0].slabs[0].uuid
      );
    }
    setMenuOpen(false);
    setInOperation(false);
  }

  const handleChangeAreaOpen = () => {
    setAreaOpen(true);
    setMenuOpen(false);
  }

  const handleOpenEditLabel = () => {
    setEditLabelOpen(true);
    setLabelMenuOpen(false);
  }

  const duplicateLabel = () => {
    if (selected_label) {
      dispatch(createShopLabel({ text: selected_label.text, area: selected_label.area, size: selected_label.size, location: { X: 450, Y: 450 }, quote: quote.id }));
    }
    setLabelMenuOpen(false);
  }

  const handleDeleteLabel = () => {
    const id: number = selected_label.id;
    dispatch(deleteShopLabel(id));
    setLabelMenuOpen(false);
  }

  return (
    <div>
      <ShopAreaBar current_area={current_area} setArea={setCurrentArea} />
      <Box
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        sx={sketch_style(copy ? "crosshair" : copying ? "grab" : "normal")}
        ref={ref}
        onMouseDown={(event: ReactMouseEvent) => handleEvent(event)}
        onMouseUp={(event: ReactMouseEvent) => handleEvent(event)} >
        <SketchMenu
          open={menu_open}
          menu_location={menu_location}
          changeArea={handleChangeAreaOpen}
          deleteCounter={deleteSelectedCounter}
          duplicateCounter={handleDuplicateCounter}
          rotate={rotate} />
        <LabelMenu
          open={label_menu_open}
          menu_location={menu_location}
          openEditLabel={handleOpenEditLabel}
          duplicateLabel={duplicateLabel}
          deleteLabel={handleDeleteLabel} />
        <canvas id="canvas" className={JSON.stringify(canvasStyle)} />
        <UpdateShopHeight
          open={height_open}
          setOpen={setHeightOpen}
          original_value={original_value}
          point_uuid={current_uuid}
          selected_counter={selected_counter} />
        <UpdateShopWidth
          open={width_open}
          setOpen={setWidthOpen}
          original_value={original_value}
          point_uuid={current_uuid}
          selected_counter={selected_counter} />
        <UpdateShopDegrees
          open={degrees_open}
          setOpen={setDegreesOpen}
          original_value={original_value}
          point_uuid={current_uuid}
          selected_counter={selected_counter} />
        <UpdateShopArea
          open={area_open}
          setOpen={setAreaOpen}
          counter={selected_counter} />
        <UpdateShopLabel
          open={edit_label_open}
          setOpen={setEditLabelOpen}
          selected_label={selected_label} />
      </Box>
      {
        copying && copy ?
          <Stack sx={{ position: "absolute", top: mouse_data.position.Y + 30, left: mouse_data.position.X + 20, zIndex: 2000, backgroundColor: "#fff", border: "1px solid #666666", paddingLeft: "10px", paddingRight: "10px", paddingTop: "3px", paddingBottom: "3px" }}>
            <Typography variant="body2">
              {getCopyMessage(copy)}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 10 }} color="error">
              Right click to cancel
            </Typography>
          </Stack> :
          null
      }
    </div>
  )
}
