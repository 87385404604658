/// TYPES ///
import { CSSProperties } from "react";
/// COLORS ///
import { light_grey_color } from "styles/style";
import { text_primary } from "styles/style";

export const title_style: CSSProperties = {
  color: text_primary,
  paddingTop: "5px",
  paddingBottom: "0px",
  paddingLeft: "5px"
}

export const btn_box_style: CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
}

export const item_style = (underline: boolean = true, height: string = "20px"): CSSProperties => ({
  paddingLeft: "6px",
  paddingRight: "6px",
  paddingBottom: "3px",
  paddingTop: "3px",
  height: height,
  borderBottom: underline ? `1px solid ${light_grey_color}` : "none",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap"
});

export const multiline_item_style: CSSProperties = {
  paddingLeft: "6px",
  paddingRight: "6px",
  paddingBottom: "3px",
  paddingTop: "3px",
}

export const autocomplete_item_style: CSSProperties = {
  marginTop: "-5px",
  borderBottom: `1px solid ${light_grey_color}`,
  width: "100%",
  paddingTop: "2px",
  paddingBottom: "6px"
}
