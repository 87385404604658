/// EVENT TYPE ///
export const INSTALL: string = "I";
export const TEMPLATE: string = "T";
export const SELF_CREATED: string = "S";
export const FABRICATION: string = "F";
export const ORDER_MATERIAL: string = "OM";
export const SERVICE_CALL: string = "C";
/// EVENT COLOR ///
export const FABRICATION_COLOR: string = "#6da462";
export const INSTALL_COLOR: string = "#00632f";
export const ORDER_MATERIAL_COLOR: string = "#099cc3";
export const TEMPLATE_COLOR: string = "#0061ff";
export const SERVICE_COLOR: string = "#e6403b";

export function getEventType(event_type: string) {
  switch (event_type) {
    case INSTALL:
      return "Install";
    case TEMPLATE:
      return "Template";
    case SELF_CREATED:
      return "Custom";
    case FABRICATION:
      return "Fabrication";
    case ORDER_MATERIAL:
      return "Material";
    case SERVICE_CALL:
      return "Service Call";
    default:
      return "Custom";
  }
}

export function getEventColor(event_type: string) {
  switch (event_type) {
    case INSTALL:
      return INSTALL_COLOR;
    case TEMPLATE:
      return TEMPLATE_COLOR;
    case FABRICATION:
      return FABRICATION_COLOR;
    case ORDER_MATERIAL:
      return ORDER_MATERIAL_COLOR;
    case SERVICE_CALL:
      return SERVICE_COLOR;
    default:
      return "#e6194b";
  }
}

/// EVENT VIEW TYPE ///
export const MONTH: string = "M";
export const DAY: string = "D";
export const WEEK: string = "W";
