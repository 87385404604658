import { Dispatch } from "redux";
import { Action } from "api/action_types/quote";
import { ActionType } from "api/action_types/quote";
import { Quote } from "api/types/quote";

export const updateLocalQuote = (quote: Quote) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_QUOTE,
            payload: quote
        });
    }
}