import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface Props {
    num_per_page: number,
    setNumPerPage: (num_per_page: number) => void
}

export default function PaginationSelector({num_per_page, setNumPerPage}: Props){
    return (
        <Stack justifyContent="center" direction="column" sx={{height: "100%"}}>
            <Select
                size="small"
                value={num_per_page}
                onChange={e => setNumPerPage(Number(e.target.value))}
                fullWidth
                sx={{fontSize: 14}}>
                <MenuItem key="30" value={30}>
                    30
                </MenuItem>
                <MenuItem key="50" value={50}>
                    50
                </MenuItem>
                <MenuItem key="100" value={100}>
                    100
                </MenuItem>
                <MenuItem key="200" value={200}>
                    200
                </MenuItem>
            </Select>
        </Stack>
    )
}