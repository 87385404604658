import { IShopBump } from "api/types/shop_quote/bump";
import { IShopCutBump } from "api/types/shop_quote/cut_bump";

export enum ActionType {
  CREATE_LOCAL_SHOP_CUT_BUMP = "CREATE_LOCAL_SHOP_CUT_BUMP",
  UPDATE_LOCAL_SHOP_CUT_BUMP = "UPDATE_LOCAL_SHOP_CUT_BUMP",
  DELETE_LOCAL_SHOP_CUT_BUMP = "DELETE_LOCAL_SHOP_CUT_BUMP"
}

interface ICreateLocalShopCutBump {
  type: ActionType.CREATE_LOCAL_SHOP_CUT_BUMP,
  payload: IShopCutBump,
  bump: IShopBump
}

interface IUpdateLocalShopCutBump {
  type: ActionType.UPDATE_LOCAL_SHOP_CUT_BUMP,
  payload: IShopCutBump,
  bump: IShopBump
}

interface IDeleteLocalShopCutBump {
  type: ActionType.DELETE_LOCAL_SHOP_CUT_BUMP,
  payload: IShopBump
}

export type Action =
  ICreateLocalShopCutBump |
  IUpdateLocalShopCutBump |
  IDeleteLocalShopCutBump;
