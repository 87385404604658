/// TYPES ///
import { Bump } from "api/types/sketch";
import { IShopBump } from "api/types/shop_quote/bump";
/// VALUES ///
import { BUMP_IN } from "values/values";

export const empty_bump: Bump | IShopBump = {
  bump_type: BUMP_IN,
  corner_uuid: "",
  counter_uuid: "",
  width: 0,
  depth: 0,
  distance: 0,
  uuid: "",
  left_side_angle: 0,
  right_side_angle: 0
}
