/// REACT ///
import { MouseEvent } from "react";
import { KeyboardEvent } from "react";
import { useEffect } from "react";
import { useRef } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  degrees: number,
  setDegrees: (degrees: number) => void,
  error: string,
  save: () => void,
  cancel: () => void
}

export default function UpdateDegrees({ open, setOpen, degrees, setDegrees, error, save, cancel }: Props) {
  const ref = useRef<HTMLInputElement | null>(null);

  const toggleDrawer = () =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(!open);
    };

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      save();
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      cancel();
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref !== null && ref.current !== null) {
        ref.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  return (
    <SwipeableDrawer
      BackdropProps={{ invisible: true }}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      onKeyDown={keyDown}
      anchor="right"
      open={open}>
      <Box display="flex" flexDirection="column" justifyContent="center" sx={{ paddingTop: "25px", paddingLeft: "50px", paddingRight: "50px", width: "300px" }}>
        <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
          <IconButton onClick={cancel}>
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Box>
        <Typography variant="h4" align="center" sx={{ marginTop: "50px" }}>
          Angle
        </Typography>
        <Typography variant="body2" align="center" sx={{ paddingBottom: "25px" }}>
          Degrees
        </Typography>
        <FormControl fullWidth sx={{ paddingBottom: "25px" }}>
          <RadioGroup
            aria-labelledby="range-group-application"
            value={degrees}
            name="range-group-application"
            onChange={e => setDegrees(Number(e.target.value))}
          >
            <FormControlLabel value={22.5} control={<Radio />} label={`22.5${"\u00b0"}`} />
            <FormControlLabel value={45} control={<Radio />} label={`45${"\u00b0"}`} />
            <FormControlLabel value={67.5} control={<Radio />} label={`67.5${"\u00b0"}`} />
            <FormControlLabel value={90} control={<Radio />} label={`90${"\u00b0"}`} />
          </RadioGroup>
        </FormControl>
        <TextField
          type="number"
          inputRef={ref}
          helperText={error}
          error={error !== ""}
          value={degrees > 0 ? degrees : ""}
          onChange={e => setDegrees(Number(e.target.value))}
          label="Degrees"
        />
        <Button onClick={save} sx={{ marginTop: "50px" }}>
          Save
        </Button>
      </Box>
    </SwipeableDrawer >
  );
}
