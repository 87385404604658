/// PDF ///
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
/// STYLES ///
import { print_header } from "styles/print";
import { subtitle } from "styles/print";

interface Props {
  payment_terms: string
  title?: string,
}

export default function PrintTerms({ payment_terms, title = "Terms & Conditions" }: Props) {
  const styles = StyleSheet.create({
    body2: {
      fontSize: 10.8,
      fontFamily: "Montserrat"
    },
    body2_bold: {
      fontSize: 10.8,
      fontFamily: "Montserrat",
      fontWeight: "bold"
    }
  });

  return (
    <View wrap={false} style={{ paddingTop: "20px" }}>
      <View style={{ paddingLeft: "10px" }}>
        <Text style={{ ...subtitle, textTransform: "uppercase" }}>
          {title}
        </Text>
      </View>
      <View style={print_header}></View>
      <View style={{ marginTop: "5px", paddingLeft: "10px", paddingRight: "10px", display: "flex", flexDirection: "row" }} >
        <Text style={styles.body2_bold}>
          This quote is subject to the following terms and conditions:
        </Text>
      </View>
      <View style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <Text style={styles.body2}>
          {payment_terms}
        </Text>
      </View>
    </View>
  );
}
