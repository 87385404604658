import { OrderCorner } from "api/types/order/order";
import { SelfOrderCorner } from "api/types/order/self_order";
import { OrderPoint } from "api/types/order/order";
import { SelfOrderPoint } from "api/types/order/self_order";
import { Vector2 } from "api/types/sketch";
import { Text } from "api/types/sketch";
import { CornerData } from "api/types/sketch";
import { MeasurementIndicator } from "api/types/sketch";
import { degreeText } from "../text";
import { widthText } from "../text";
import { edgeText } from "../text";
import { measurementText } from "../text";
import { getMidpoint } from "functions/sketch/math";
import { getAngle } from "functions/sketch/math";
import { getDistance } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { drawOrderPrintBumps } from "../bumps/bumps/print_bumps";
import { drawPrintCorner } from "../corner/corner";
import { constructPrintMeasurementIndicator } from "functions/sketch/create";

export function drawPrintOrderCounter(
  corners: OrderCorner[] | SelfOrderCorner[],
  points: OrderPoint[] | SelfOrderPoint[],
  painter: any,
  offset: Vector2,
  zoom: number
) {
  let first_point: Vector2 | null = null;
  const point_texts: Text[] = [];
  const texts: Text[] = [];
  const measurement_indicators: MeasurementIndicator[] = [];
  for (let i = 0; i < points.length; i++) {
    if (i === 0 || i === points.length - 1) {
      continue;
    }
    const current_location: Vector2 = {
      X: Math.round(points[i].location.X * zoom) + offset.X,
      Y: Math.round(points[i].location.Y * zoom) + offset.Y
    };
    const next_location: Vector2 = {
      X: Math.round(points[i + 1].location.X * zoom) + offset.X,
      Y: Math.round(points[i + 1].location.Y * zoom) + offset.Y
    };
    let degrees: number = Math.abs(points[i].angle - points[i - 1].angle);
    if (degrees > 180) {
      degrees -= 360;
    }

    const degree_text: Text = degreeText(
      current_location,
      Math.abs(degrees),
      "",
      ""
    );
    point_texts.push(degree_text);

    if (i !== points.length - 2) {
      const text_location: Vector2 = getMidpoint(current_location, next_location);

      const width_text: Text = widthText(
        text_location,
        points[i].width,
        "",
        ""
      );
      point_texts.push(width_text);

      if (points[i].width > 26) {
        measurement_indicators.push({
          location: text_location,
          value: points[i].width / 4,
          angle: points[i].angle + 90
        });
      }
    }
  }

  for (let i = 0; i < corners.length; i++) {
    const previous_corner: OrderCorner | SelfOrderCorner = corners[i - 1] ? corners[i - 1] : corners[corners.length - 1];
    const corner: OrderCorner | SelfOrderCorner = corners[i];
    const next_corner: OrderCorner | SelfOrderCorner = corners[i + 1] ? corners[i + 1] : corners[0];
    const previous_location: Vector2 = {
      X: Math.round(previous_corner.location.X * zoom) + offset.X,
      Y: Math.round(previous_corner.location.Y * zoom) + offset.Y
    };
    const current_location: Vector2 = {
      X: Math.round(corner.location.X * zoom) + offset.X,
      Y: Math.round(corner.location.Y * zoom) + offset.Y
    };
    const next_location: Vector2 = {
      X: Math.round(next_corner.location.X * zoom) + offset.X,
      Y: Math.round(next_corner.location.Y * zoom) + offset.Y
    }
    const angle: number = Math.round(getAngle(current_location, next_location) + 90);
    const distance: number = Math.round(getDistance(current_location, next_location));
    const midway_point: Vector2 = getDistantPoint(current_location, angle, distance / 2);
    const is_end: boolean = i === corners.length - 1 || i === Math.floor((corners.length - 1) / 2);

    const measurement_distance: number = getDistance(corner.location, next_corner.location);
    const text: Text = measurementText(
      midway_point,
      distance,
      angle,
      measurement_distance,
      "",
      "",
      "",
      is_end ? false : true,
      i > Math.floor((corners.length - 1) / 2)
    );
    if (text.real_value * zoom > 26) {
      measurement_indicators.push({ location: midway_point, value: text.real_value, angle: text.angle });
    }
    text.text = `${text.value}${text.text}`;
    texts.push(text);

    const edge_text: Text = edgeText(
      midway_point,
      distance,
      angle,
      corner.edge_type,
      "",
      "",
      "",
      corner.splash_height,
      corner.finished_edge_type,
      true
    )
    texts.push(edge_text);

    const corner_data: CornerData | null = drawPrintCorner(
      i,
      painter,
      corner.corner_type,
      previous_location,
      current_location,
      next_location,
      zoom,
      corner.corner_radius,
      corner.corner_length,
      corner.corner_depth
    );

    if (corner_data && corner_data.first_point && i === 0) {
      first_point = corner_data.first_point;
    }

    drawOrderPrintBumps(
      painter,
      corner.bumps,
      corner_data ? corner_data.last_point : current_location,
      next_location,
      angle - 90,
      zoom
    );
  }

  if (first_point) {
    painter.lineTo(first_point.X, first_point.Y);
  }

  for (let i = 0; i < texts.length; i++) {
    const length = texts[i].text[0].length;
    painter.text(texts[i].text, texts[i].location.X - 5 * length, texts[i].location.Y - 5);
  }
  for (let i = 0; i < point_texts.length; i++) {
    const text: string = point_texts[i].real_value + point_texts[i].text;
    painter.text(text, point_texts[i].location.X, point_texts[i].location.Y);
  }
  painter.stroke("#000000");

  for (let i = 0; i < measurement_indicators.length; i++) {
    constructPrintMeasurementIndicator(
      painter,
      measurement_indicators[i].location,
      measurement_indicators[i].value,
      measurement_indicators[i].angle
    );
  }

  painter.stroke("#666666");
}
