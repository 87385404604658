/// TYPES ///
import { IFabricationJob } from "api/types/customer";
import { ICustomer } from "api/types/customer";
/// REACT ///
import { Fragment } from "react";
import { useState } from "react";
/// MUI ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
/// COMPONENTS ///
import CustomerAutocomplete from "components/universal/autocomplete/customer";
import RemovalDialog from "components/quote/dialog/removal";
import QuoteJob from "components/quote/dialog/job";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// STYLES ///
import { autocomplete_item_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
/// ICONS ///
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface Props {
  customer?: ICustomer | null,
  job?: IFabricationJob | null,
  stone_open: boolean,
  setStoneOpen: (open: boolean) => void,
  laminate_open: boolean,
  setLaminateOpen: (open: boolean) => void,
  material_area: number,
  stone_removal_sq_ft: number,
  laminate_removal_sq_ft: number,
  distance: number,
  chooseCustomer: (customer: ICustomer | null) => void,
  fitted: boolean,
  saveStoneRemoval: (amount: number) => void,
  saveLaminateRemoval: (amount: number) => void,
  saveJob: (job: IFabricationJob) => void
}

export default function QuoteDetail({
  customer,
  job,
  stone_open,
  setStoneOpen,
  laminate_open,
  setLaminateOpen,
  material_area,
  stone_removal_sq_ft,
  laminate_removal_sq_ft,
  distance,
  chooseCustomer,
  fitted,
  saveStoneRemoval,
  saveLaminateRemoval,
  saveJob
}: Props) {
  const [job_open, setJobOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <Grid item xs={4}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={customer ? item_style() : item_style(true, "40px")}>
          <Typography variant="body2" color={customer ? "default" : "error"}>
            <b>{customer ? "Customer" : "Must Add Customer"}</b>
          </Typography>
          {
            customer ?
              <IconButton size="small" sx={{ marginLeft: "5px" }} onClick={() => chooseCustomer(null)}>
                <FontAwesomeIcon icon={faMinus} size="xs" />
              </IconButton> :
              null
          }
        </Stack>
      </Grid>
      <Grid item xs={8}>
        {
          customer ?
            <Typography variant="body2" align="right" sx={item_style()}>
              {customer?.first_name ?? ""} {customer?.last_name ?? ""} {customer ? "-" : ""} {customer?.email ?? ""}
            </Typography> :
            <Box display="flex" justifyContent="flex-end" sx={autocomplete_item_style}>
              <CustomerAutocomplete chooseCustomer={chooseCustomer} input_label="" height="10px" fullWidth={true} />
            </Box>
        }
      </Grid>
      <Grid item xs={6}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={item_style()}>
          <Typography variant="body2">
            <b>Job Site</b>
          </Typography>
          {
            !job ?
              <Fragment>
                {
                  customer ?
                    <IconButton size="small" sx={{ marginLeft: "5px" }} onClick={() => setJobOpen(true)}>
                      <FontAwesomeIcon icon={faPlus} size="xs" />
                    </IconButton> :
                    null
                }
              </Fragment> :
              <IconButton size="small" sx={{ marginLeft: "5px" }} onClick={() => setJobOpen(true)}>
                <FontAwesomeIcon icon={faEdit} size="xs" />
              </IconButton>
          }
        </Stack>
      </Grid>
      <Grid item xs={6}>
        {
          job ?
            <Typography variant="body2" align="right" sx={item_style()}>
              {job?.address ?? ""}, {job?.city ?? ""}
            </Typography> :
            <Typography variant="body2" align="right" sx={item_style()} color="error">
              Must add a job before creating order
            </Typography>
        }
        <QuoteJob
          quote_job={job}
          customer={customer}
          open={job_open}
          setOpen={setJobOpen}
          dispatchSave={saveJob} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Material Area</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" color={fitted ? "inherit" : "error"} sx={item_style()}>
          {fitted ? `${material_area ?? 0} SQ FT` : "Must fit shapes before creating order"}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={item_style()}>
          <Typography variant="body2">
            <b>Stone Counter Removal</b>
          </Typography>
          <IconButton size="small" sx={{ marginLeft: "5px" }} onClick={() => setStoneOpen(true)}>
            <FontAwesomeIcon icon={faPlus} size="xs" />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {stone_removal_sq_ft ?? 0} SQ FT
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={item_style()}>
          <Typography variant="body2">
            <b>Laminate Counter Removal</b>
          </Typography>
          <IconButton size="small" sx={{ marginLeft: "5px" }} onClick={() => setLaminateOpen(true)}>
            <FontAwesomeIcon icon={faPlus} size="xs" />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {laminate_removal_sq_ft ?? 0} SQ FT
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" sx={item_style()}>
          <b>Travel Distance</b>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" align="right" sx={item_style()}>
          {distance ?? 0} KM
        </Typography>
      </Grid>
      <RemovalDialog
        title="Stone Countertop Removal"
        amount={stone_removal_sq_ft ?? 0}
        save={amt => saveStoneRemoval(amt)}
        open={stone_open}
        setOpen={setStoneOpen} />
      <RemovalDialog
        title="Laminate Countertop Removal"
        amount={laminate_removal_sq_ft ?? 0}
        save={amt => saveLaminateRemoval(amt)}
        open={laminate_open}
        setOpen={setLaminateOpen} />
    </Fragment>
  )
}
