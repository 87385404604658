import { SelfOrder } from "api/types/order";
import { SelfOrderCounter } from "api/types/order/self_order";
import { SelfOrderArea } from "api/types/order/self_order";
import { SelfOrderSlab } from "api/types/order/self_order";
import { Action } from "api/action_types/self_order";
import { ActionType } from "api/action_types/self_order";
import { IGetOrderArgs } from "api/types/order/order";

interface State {
  self_orders: SelfOrder[],
  self_order: SelfOrder,
  filter: IGetOrderArgs,
  areas: SelfOrderArea[],
  slabs: SelfOrderSlab[],
  counters: SelfOrderCounter[],
  counters_loading: boolean,
  areas_loading: boolean,
  slabs_loading: boolean,
  loading: boolean,
  updating: boolean,
  message_open: boolean,
  order_success: boolean,
  success_id: number,
  error: string
}

const initialState = {
  self_orders: [],
  self_order: {},
  filter: {},
  counters: [],
  areas: [],
  slabs: [],
  areas_loading: false,
  counters_loading: false,
  slabs_loading: false,
  loading: false,
  updating: false,
  message_open: false,
  order_success: false,
  success_id: 0,
  error: ""
}

export const selfOrderReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.CLEAR_SELF_ORDER:
      return {
        ...state,
        self_order: {},
        areas: [],
        counters: [],
        slabs: [],
      }
    case ActionType.FILTER_SELF_ORDER:
      return {
        ...state,
        filter: action.filter
      }
    case ActionType.GET_SELF_ORDERS_SUCCESS:
      return {
        ...state,
        self_orders: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.GET_SELF_ORDER_COUNTERS_SUCCESS:
      return {
        ...state,
        counters: action.payload,
        counters_loading: false,
        updating: false
      }
    case ActionType.GET_SELF_ORDER_AREAS_SUCCESS:
      return {
        ...state,
        areas: action.payload,
        areas_loading: false,
        updating: false
      }
    case ActionType.GET_SELF_ORDER_SLABS_SUCCESS:
      return {
        ...state,
        slabs: action.payload,
        slabs_loading: false,
        updating: false
      }
    case ActionType.GET_SELF_ORDER_SUCCESS:
      return {
        ...state,
        self_order: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.CREATE_SELF_ORDER_SUCCESS:
      return {
        ...state,
        self_orders: [...state.self_orders, action.payload],
        order_success: true,
        success_id: action.payload.id,
        error: "",
        updating: false
      }
    case ActionType.RESET_SELF_ORDER_SUCCESS:
      return {
        ...state,
        order_success: false
      }
    case ActionType.OPEN_MESSAGE:
      return {
        ...state,
        message_open: action.open
      }
    case ActionType.AREAS_LOADING:
      return { ...state, areas_loading: true };
    case ActionType.COUNTERS_LOADING:
      return { ...state, counters_loading: true };
    case ActionType.SLABS_LOADING:
      return { ...state, slabs_loading: true };
    case ActionType.SELF_ORDER_UPDATING:
      return {
        ...state,
        updating: true
      }
    case ActionType.SELF_ORDER_LOADING:
      return {
        ...state,
        loading: true
      }
    case ActionType.SELF_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        updating: false,
        error: action.payload
      }
    default:
      return state;
  }
}
