/// REDUX ///
import { useAppSelector } from "api/hooks/apiHook";
/// TYPES ///
import { IShopArea } from "api/types/shop_quote/area";
/// MUI ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import ColorOptsHeader from "components/quote/color_options/header";
import ColorOptsRow from "components/quote/color_options/row";
/// STYLES ///
import { header_style } from "styles/universal/section_header";
import { title_style } from "styles/quote_preview/section";
/// FUNCTION ///
import { containsColorOption } from "components/quote/color_options/validate";

interface Props {
  areas: IShopArea[]
}

export default function ShopColorOptionsTable({ areas }: Props) {
  const { quote } = useAppSelector((state) => state.shop_quote);

  if (!containsColorOption(areas) || quote.changed) {
    return null;
  }
  return (
    <Grid container item xs={12} lg={6}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={title_style}>
          Color Options
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={header_style}></Box>
      </Grid>
      <ColorOptsHeader />
      {
        areas.map(area => area.color_options?.map(opt =>
          <ColorOptsRow key={area.id} area={area} option={opt} />
        ))
      }
    </Grid>
  )
}
