/// HOOKS ///
import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { createShopQuoteSignatureRequest } from "api/actions/signature";
/// COMPONENTS ///
import EmailSignatureRequest from "components/quote/quote_preview/customer_info/dialog/signature";
/// VALIDATE ///
import { validateEmail } from "functions/value";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  default_email: string,
  customer: number | undefined | null,
  id: number,
}

export default function ShopEmailSignatureRequest({
  open,
  setOpen,
  default_email,
  customer,
  id,
}: Props) {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<boolean>(false);

  const sendQuote = (email: string) => {
    if (validateEmail(email) && id) {
      dispatch(createShopQuoteSignatureRequest(email, id));
    }
    else {
      setError(true);
    }

    setOpen(false);
  }
  return (
    <EmailSignatureRequest
      open={open}
      setOpen={setOpen}
      default_email={default_email}
      customer={customer}
      sendQuote={sendQuote}
      error={error} />
  )
}
