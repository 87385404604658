import { Vector2 } from "api/types/sketch";
import { BumpArcConstruct } from "api/types/sketch";
import { BumpConstruct } from "api/types/sketch";
import { getDistance, getLineIntersection } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { adjustMeasurementToScale } from "../scale";

export function constructBumpInArc(
    start: Vector2,
    end: Vector2,
    angle: number,
    distance: number,
    width: number,
    depth: number,
    zoom: number
): BumpArcConstruct {
    const full_width: number = getDistance(start, end);
    const center: Vector2 = getDistantPoint(start, angle + 90, full_width / 2);
    const bump_center: Vector2 = getDistantPoint(center, angle + 90, adjustMeasurementToScale(distance, zoom));

    return {
        start: getDistantPoint(bump_center, (angle - 90), adjustMeasurementToScale(width, zoom)),
        top: getDistantPoint(bump_center, angle - 180, adjustMeasurementToScale(depth, zoom)),
        end: getDistantPoint(bump_center, (angle + 90), adjustMeasurementToScale(width, zoom))
    }
}

export function constructBumpOutArc(
    start: Vector2,
    end: Vector2,
    angle: number,
    distance: number,
    width: number,
    depth: number,
    zoom: number
): BumpArcConstruct {
    const full_width: number = getDistance(start, end);
    const center: Vector2 = getDistantPoint(start, angle + 90, full_width / 2);
    const bump_center: Vector2 = getDistantPoint(center, angle + 90, adjustMeasurementToScale(distance, zoom));

    return {
        top: getDistantPoint(bump_center, angle, adjustMeasurementToScale(depth, zoom)),
        start: getDistantPoint(bump_center, (angle - 90), adjustMeasurementToScale(width, zoom)),
        end: getDistantPoint(bump_center, (angle + 90), adjustMeasurementToScale(width, zoom))
    }
}

export function constructBumpIn(
    start: Vector2,
    end: Vector2,
    angle: number,
    distance: number,
    width: number,
    depth: number,
    left_side_angle: number,
    right_side_angle: number,
    zoom: number
): BumpConstruct {
    const adjusted_width: number = adjustMeasurementToScale(width, zoom);
    const adjusted_depth: number = adjustMeasurementToScale(depth, zoom);
    const full_width: number = getDistance(start, end);
    const center: Vector2 = getDistantPoint(start, angle + 90, full_width / 2);
    const bump_center: Vector2 = getDistantPoint(center, angle + 90, adjustMeasurementToScale(distance, zoom));

    const bump_start: Vector2 = getDistantPoint(bump_center, angle + 90, - adjusted_width / 2);
    const bump_end: Vector2 = getDistantPoint(bump_center, angle + 90, adjusted_width / 2);

    const B: Vector2 = getDistantPoint(bump_start, angle - 180, adjusted_depth);
    const C: Vector2 = getDistantPoint(bump_end, angle - 180, adjusted_depth);

    const A: Vector2 = getLineIntersection(
        start,
        end,
        B,
        getDistantPoint(B, angle + 90 + (90 - left_side_angle) - 180, 200));

    const D: Vector2 = getLineIntersection(
        start,
        end,
        C,
        getDistantPoint(C, angle + 90 - (90 - right_side_angle) - 180, 200));

    return {
        A: A,
        B: B,
        C: C,
        D: D
    }
}

export function constructBumpOut(
    start: Vector2,
    end: Vector2,
    angle: number,
    distance: number,
    width: number,
    depth: number,
    left_side_angle: number,
    right_side_angle: number,
    zoom: number
): BumpConstruct {
    const adjusted_width: number = adjustMeasurementToScale(width, zoom);
    const adjusted_depth: number = adjustMeasurementToScale(depth, zoom);
    const full_width: number = getDistance(start, end);
    const center: Vector2 = getDistantPoint(start, angle + 90, full_width / 2);
    const bump_center: Vector2 = getDistantPoint(center, angle + 90, adjustMeasurementToScale(distance, zoom));

    const bump_start: Vector2 = getDistantPoint(bump_center, angle + 90, - adjusted_width / 2);
    const bump_end: Vector2 = getDistantPoint(bump_center, angle + 90, adjusted_width / 2);

    const B: Vector2 = getDistantPoint(bump_start, angle, adjusted_depth);
    const C: Vector2 = getDistantPoint(bump_end, angle, adjusted_depth);

    const A: Vector2 = getLineIntersection(
        start,
        end,
        B,
        getDistantPoint(B, angle + 90 - (90 - left_side_angle) - 180, 200));

    const D: Vector2 = getLineIntersection(
        start,
        end,
        C,
        getDistantPoint(C, angle + 90 + (90 - right_side_angle) - 180, 200));

    return {
        A: A,
        B: B,
        C: C,
        D: D
    }
}

export function constructFullArc(
    start: Vector2,
    end: Vector2,
    angle: number,
    depth: number,
    zoom: number
): BumpArcConstruct {
    const full_width: number = getDistance(start, end);
    const center: Vector2 = getDistantPoint(start, angle + 90, full_width / 2);

    return {
        top: getDistantPoint(center, angle, adjustMeasurementToScale(depth, zoom)),
        start: start,
        end: end
    }
}