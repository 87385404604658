/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/sketch";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/sketch";

export const deleteLocalCutShapes = (counter_uuid: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DELETE_LOCAL_CUT_SHAPE,
      payload: counter_uuid
    });
  }
}
