/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// TYPES ///
import { QuoteFilter } from "api/types/quote";
/// ACTIONS ///
import { Action } from "api/action_types/quote";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/quote";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// FUNCTIONS ///
import { constructGetQuotesUrl } from "./params/filter";
/// URLS ///
import { url_prefix } from "settings";
import { quote_url } from "../endpoint";

export const getQuotes = (filter?: QuoteFilter) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.QUOTE_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = constructGetQuotesUrl(filter);

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_QUOTES_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.QUOTE_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const getQuote = (id: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.QUOTE_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${quote_url}/${id}/`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_QUOTE_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.QUOTE_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const getQuotePreview = (id: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.QUOTE_LOADING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${quote_url}/${id}/?preview=true&`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_QUOTE_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.QUOTE_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
