import { FINISHED } from "values/values";
import { EASED } from "values/values";
import { BULLNOSE } from "values/values";
import { BEVEL } from "values/values";
import { SPECIALTY } from "values/values";
import { MITER } from "values/values";

export const EASED_DISPLAY: string = "EE";
export const BULLNOSE_DISPLAY: string = "BN";
export const BEVEL_DISPLAY: string = "BVL";
export const SPECIALTY_DISPLAY: string = "SPEC";
export const MITER_DISPLAY: string = "MTR";

export function getEdgeText(edge_type: string, finished_edge_type: string): string {
  if (edge_type === FINISHED) {
    if (finished_edge_type === EASED) {
      return "EE";
    }
    else if (finished_edge_type === BULLNOSE) {
      return "BN";
    }
    else if (finished_edge_type === BEVEL) {
      return "BVL";
    }
    else if (finished_edge_type === SPECIALTY) {
      return "SPEC";
    }
  }
  else if (edge_type === MITER) {
    return "MTR";
  }
  return edge_type;
}
