import { Counter } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCorner } from "api/types/shop_quote/corner";
import { Line } from "api/types/sketch";

export function getCornerByUuid(uuid: string, counter: Counter): Corner | null {
  for (let i = 0; i < counter.corners.length; i++) {
    if (counter.corners[i].uuid === uuid) {
      return counter.corners[i];
    }
  }
  return null;
}

export function getShopCornerByUuid(uuid: string, counter: IShopCounter): IShopCorner | null {
  for (let i = 0; i < counter.corners.length; i++) {
    if (counter.corners[i].uuid === uuid) {
      return counter.corners[i];
    }
  }
  return null;
}

export function getNextCorner(counters: Counter[], corner: Corner): Line | null {
  for (let i = 0; i < counters.length; i++) {
    for (let j = 0; j < counters[i].corners.length; j++) {
      if (corner.id === counters[i].corners[j].id) {
        const next_corner: Corner = counters[i].corners[j + 1] ? counters[i].corners[j + 1] : counters[i].corners[0];
        return {
          start: corner,
          end: next_corner,
          counter: counters[i]
        }
      }
    }
  }
  return null;
}

export function getNextShopCorner(counters: IShopCounter[], corner: IShopCorner): Line | null {
  for (let i = 0; i < counters.length; i++) {
    for (let j = 0; j < counters[i].corners.length; j++) {
      if (corner.id === counters[i].corners[j].id) {
        const next_corner: IShopCorner = counters[i].corners[j + 1] ? counters[i].corners[j + 1] : counters[i].corners[0];
        return {
          start: corner,
          end: next_corner,
          counter: counters[i]
        }
      }
    }
  }
  return null;
}
