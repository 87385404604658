/// REACT ///
import { useState } from "react";
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { createSelfOrder } from "api/actions/self_order/self_order";
/// LUXON ///
import { DateTime } from "luxon";
/// MUI ///
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  all_items_found: boolean
}

export default function ConfirmShopOrder({ open, setOpen, all_items_found }: Props) {
  const dispatch = useAppDispatch();
  const { quote } = useAppSelector((state) => state.shop_quote);
  const { user } = useAppSelector((state) => state.authentication);
  const [date, setDate] = useState<DateTime>(DateTime.now());

  const handleCreateSelfOrder = () => {
    if (quote && quote.id && user.id) {
      dispatch(createSelfOrder(
        {
          fabricator: user.id,
          end_customer: quote.customer ? quote.customer : 0,
          quote: quote.id,
          target_install_date: date.toLocal().toISO(),
          name: quote.name ? quote.name : "",
          tax: quote.tax ? quote.tax : 0.00,
          total: quote.total ? quote.total : 0.00
        }
      ));
    }
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Confirm Order</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Stack>
          <Typography variant="body2">
            {
              all_items_found ?
                "Are you sure you want to convert this quote to an order?" :
                "Are you sure you want to convert to order? Some pricing items were not found, the price for line items in red is set to $0. The overall price on the order will not be accurate."
            }
          </Typography>
          <DatePicker
            defaultValue={DateTime.now()}
            slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
            label="Order Date"
            disablePast={true}
            onChange={e => setDate(e)} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleCreateSelfOrder}>Create Order</Button>
      </DialogActions>
    </Dialog>
  )
}
