/// TYPES ///
import { CSSProperties } from "react";
/// SIZES ///
import { page_left_gutter } from "values/size";
import { page_right_gutter } from "values/size";
import { page_top_gutter } from "values/size";
import { page_bottom_gutter } from "values/size";

export const title_section_style: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "5px"
}

export const title_style: CSSProperties = {
  paddingLeft: "2px"
}

export const content_container_style: CSSProperties = {
  position: "absolute",
  top: page_top_gutter,
  bottom: page_bottom_gutter,
  left: page_left_gutter,
  right: page_right_gutter,
}

export const calendar_content_style: CSSProperties = {
  padding: "15px",
  paddingTop: "55px",
  paddingLeft: "75px",
  paddingRight: "75px",
  display: "flex",
  flexDirection: "row"
}

export const list_container_style: CSSProperties = {
  paddingBottom: "80px"
}
