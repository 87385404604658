/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// TYPES ///
import { Color } from "api/types/material";
import { ColorOption } from "api/types/quote";
import { Material } from "api/types/material";
/// ACTIONS ///
import { updateColorOption } from "api/actions/quotes/color_option";
import { markQuoteChanged } from "api/actions/quotes/quote";
/// COMPONENTS ///
import MainColor from "components/quote/color_options/area/row/color";
/// LODASH ///
import { filter } from "lodash";
/// VALUES ///
import { QUARTZ } from "values/material";

interface Props {
  color_option: ColorOption,
  description: string,
}

export default function ShopColorOptionItem({ color_option, description }: Props) {
  const dispatch = useAppDispatch();
  const { colors } = useAppSelector((state) => state.dealer_material);
  const { materials } = useAppSelector((state) => state.dealer_material);
  const [filt_mats, setFiltMats] = useState<Material[]>([]);
  const [filt_colors, setFiltColors] = useState<Color[]>([]);
  const [selected_type, setSelectedType] = useState<string>(color_option?.material_type ?? QUARTZ);
  const [selected_material, setSelectedMaterial] = useState<number>(0);
  const [selected_color, setSelectedColor] = useState<number>(0);

  useEffect(() => {
    setSelectedType(color_option?.material_type);
  }, [color_option?.material_type]);

  useEffect(() => {
    for (let i = 0; i < materials.length; i++) {
      if (materials[i].id === color_option?.material) {
        setSelectedMaterial(materials[i].id);
      }
    }
  }, [materials, color_option?.material]);

  useEffect(() => {
    for (let i = 0; i < colors.length; i++) {
      if (colors[i].id === color_option?.color) {
        setSelectedColor(colors[i].id);
      }
    }
  }, [colors, color_option?.color]);

  useEffect(() => {
    setFiltMats(filter(materials, { "type": selected_type }));
  }, [setFiltMats, materials, selected_type]);

  useEffect(() => {
    setFiltColors(filter(colors, { "material": selected_material }));
  }, [colors, selected_material, setFiltColors]);

  const saveColor = (
    color_id: number,
    material_id: number,
    material_type: string,
    material_name: string,
    color_name: string,
    color_length: number,
    color_width: number
  ) => {
    dispatch(updateColorOption({
      ...color_option,
      color: color_id,
      material: material_id,
      material_type: material_type,
      material_name: `${material_name} ${color_name}`,
      material_length: color_length,
      material_width: color_width
    }));
    dispatch(markQuoteChanged());

  }

  return (
    <MainColor
      description={description}
      colors={filt_colors}
      materials={filt_mats}
      saveColor={saveColor}
      selected_type={selected_type}
      setSelectedType={setSelectedType}
      selected_material={selected_material}
      setSelectedMaterial={setSelectedMaterial}
      selected_color={selected_color}
      setSelectedColor={setSelectedColor}
      setCreateColorOpen={() => void 0}
      setCreateMaterialOpen={() => void 0}
      hideAdd />
  );
}
