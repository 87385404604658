/// TYPES ///
import { OrderArea } from "api/types/order";
import { OrderSlab } from "api/types/order";
import { SelfOrderArea } from "api/types/order";
import { SelfOrderSlab } from "api/types/order";
/// COMPONENTS ///
import OrderPrintSlabs from "./slabs";
/// PDF ///
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
/// STYLES ///
import { print_header } from "styles/print";
import { subtitle } from "styles/print";

interface Props {
  areas: OrderArea[] | SelfOrderArea[],
  slabs: OrderSlab[] | SelfOrderSlab[]
}

export default function OrderPrintShapeAreas({ areas, slabs }: Props) {
  return (
    <View style={{ width: "100%" }} break>
      <View style={{ paddingLeft: "10px" }}>
        <Text style={{ ...subtitle, textTransform: "uppercase" }}>
          Slabs
        </Text>
      </View>
      <View style={print_header}></View>
      {areas.map((area: OrderArea | SelfOrderArea, index: number) => {
        return <OrderPrintSlabs key={index} index={index} slabs={slabs} area={area} />
      })}
    </View>
  );
}
