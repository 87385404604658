import { CSSProperties } from "@emotion/serialize";
import { light_grey_color } from "styles/style";
import { primary_color } from "styles/style";
import { text_primary } from "styles/style";
import { white_color } from "styles/style";

export const dayBoxStyle = (top: boolean, end: boolean, nav_height: number) => ({
  height: `calc((100vh - 170px) / ${nav_height})`,
  width: "calc((100% / 7) - 10px)",
  padding: "4px",
  borderLeft: `1px solid ${light_grey_color}`,
  borderTop: top ? `1px solid ${light_grey_color}` : "none",
  borderBottom: `1px solid ${light_grey_color}`,
  borderRight: end ? `1px solid ${light_grey_color}` : "none",
  position: "relative"
});

export const day_inner_style = (is_selected: boolean) => ({
  height: "100%",
  width: "100%",
  border: is_selected ? `2px solid ${primary_color}` : "none"
});

export const scrollableBoxStyle: CSSProperties = {
  height: "calc(100% - 25px)",
  overflowY: "scroll",
}

export const dayOfMonthStyle = (is_current_day: boolean) => ({
  fontSize: 13,
  color: is_current_day ? primary_color : text_primary,
  paddingTop: 0.5,
  paddingBottom: 0.5
});

export const dayEventItemStyle = (color: string) => ({
  border: "none",
  width: "100%",
  paddingLeft: "1px",
  paddingRight: "1px",
  paddingTop: "2px",
  paddingBottom: "2px",
  marginBottom: "3px",
  backgroundColor: color,
  borderRadius: 3,
  color: white_color,
  justifyContent: "flex-start",
  fontSize: 13,
  cursor: "pointer"
});
