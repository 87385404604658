/// REACT ///
import { useEffect } from "react";
import { useRef } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  title: string,
  text: string,
  setText: (text: string) => void,
  size: number,
  setSize: (size: number) => void,
  save: () => void,
  close: () => void,
  error: boolean
}

export default function LabelDialog({
  open,
  title,
  save,
  close,
  text,
  setText,
  size,
  setSize,
  error
}: Props) {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref !== null && ref.current !== null) {
        ref.current.focus();
        ref.current.select();
      }

    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [open, ref]);


  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Box display="flex" flexDirection="column" sx={{ gap: "10px" }}>
          <TextField
            autoFocus
            label="Label"
            inputRef={ref}
            value={text}
            error={error}
            helperText={error ? "Label must not be blank" : ""}
            onChange={e => setText(e.target.value)} />
          <TextField
            select
            label="Size"
            value={size}
            error={error}
            onChange={e => setSize(Number(e.target.value))}>
            <MenuItem key={10} value={10}>
              10
            </MenuItem>
            <MenuItem key={12} value={12}>
              12
            </MenuItem>
            <MenuItem key={14} value={14}>
              14
            </MenuItem>
            <MenuItem key={16} value={16}>
              16
            </MenuItem>
            <MenuItem key={18} value={18}>
              18
            </MenuItem>
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={save}>Save</Button>
      </DialogActions>
    </Dialog >
  )
}
