/// TYPES ///
import { Color } from "api/types/material";
import { Material } from "api/types/material";
/// ACTIONS ///
import { Action } from "api/action_types/material/dealer";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/material/dealer";

interface State {
  materials: Material[],
  colors: Color[],
  loading: boolean,
  error: string | null
}

const initialState = {
  materials: [],
  colors: [],
  loading: false,
  error: null
}

export const dealerMaterialReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.STORE_SKETCH_MATERIAL:
      return {
        ...state,
        materials: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.STORE_SKETCH_COLORS:
      return {
        ...state,
        colors: action.payload
      }
    case ActionType.CLEAR_STORE_MATERIALS:
      return {
        ...state,
        materials: [],
        colors: []
      }
    case ActionType.STORE_MATERIAL_LOADING:
      return {
        ...state,
        loading: true
      }
    case ActionType.STORE_MATERIAL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}



