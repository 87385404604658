/// ROUTER ///
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
/// URLS ///
import { login_url } from "./endpoints";

export default function PrivateRoute() {
  const { user } = useAppSelector((state) => state.authentication);

  return user.id ? <Outlet /> : <Navigate to={login_url} />;
}
