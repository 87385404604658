import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { createShopColor } from "api/actions/shop_material";
import { getShopMaterial } from "api/actions/shop_material";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { IShopColor, IShopColorError } from "api/types/shop_material";
/// MUI ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import CreateColorHeader from "./header";
import CreateColorRow from "./row";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///

export default function CreateShopColor() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { material } = useAppSelector((state) => state.shop_material);
  const [colors, setColors] = useState<IShopColor[]>([{}]);
  const [created_colors, setCreatedColors] = useState<IShopColor[]>([]);
  const [errors, setErrors] = useState<IShopColorError[]>([{}]);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    dispatch(getShopMaterial(id));
  }, [dispatch, id]);

  const addRow = () => {
    setColors([...colors, {}]);
    setErrors([...errors, {}]);
  }

  const create = () => {
    let new_errors: IShopColorError[] = [];
    let new_colors: IShopColor[] = [];
    let new_created_colors: IShopColor[] = [];
    let new_count: number = 0;

    for (let i = 0; i < colors.length; i++) {
      let error: IShopColorError = {};
      if (!colors[i].name || colors[i].name === "") {
        error.name_error = "Must enter a name";
        error.name_has_error = true;
      }
      if (!colors[i].price) {
        error.price_error = "Must enter a price";
        error.price_has_error = true;
      }
      if (!colors[i].width) {
        error.width_error = "Must enter a width";
        error.width_has_error = true;
      }
      if (!colors[i].length) {
        error.length_error = "Must enter a length";
        error.length_has_error = true;
      }

      if (error.name_has_error || error.price_has_error || error.width_has_error || error.length_has_error) {
        new_colors.push({ ...colors[i] });
        new_errors.push(error);
        continue;
      }
      else {
        new_count++;
        new_created_colors.push({ ...colors[i] });
        dispatch(createShopColor({ ...colors[i], material: Number(id) }));
      }
    }

    if (new_errors.length === 0) {
      navigate(-1);
    }
    else {
      setCount(prev => prev + new_count);
      setErrors(new_errors)
      setColors(new_colors);
      setCreatedColors(new_created_colors);
    }
  }

  const setColor = (index: number, color: IShopColor) => {
    setColors([...colors.map((c, i) => index === i ? color : c)]);
  }

  useEffect(() => {
    dispatch(setBreadcrumbs([]));
  }, [dispatch]);

  /// TODO on cancel prompt, do you want to delete already created colors or leave them and just go back or stay on page

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title={material?.name ? `Create Colors For ${material.name}` : ""} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(-1)} text="Cancel" icon={faXmark} />
            <BaseButton clickEvent={create} text="Save" icon={faSave} />
          </Box>
        } />
        <Grid item xs={12}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={6} sx={{ paddingTop: "20px" }}>
          <Typography variant="body2" color="primary">
            {count > 0 ? `${count} Colors Created` : ""}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <BaseButton icon={faPlus} text="Add Color" clickEvent={addRow} />
          </Box>
        </Grid>
        <CreateColorHeader />
        {
          colors.map((color, index) => <CreateColorRow color={color} index={index} setColor={setColor} errors={errors[index]} />)
        }
        {
          created_colors.map((color, index) => <CreateColorRow color={color} index={index} setColor={setColor} errors={{}} disabled={true} />)
        }
      </Grid>
    </motion.div>
  )
}
