import { Dispatch } from "redux";
import { Action } from "api/action_types/sketch";
import { ActionType } from "api/action_types/sketch";
import { Sink } from "api/types/sketch";

export const updateLocalSink = (sink: Sink) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_SINK,
            payload: sink
        });
    }
}