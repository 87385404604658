/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useState } from "react";
/// UUID ///
import { v4 } from "uuid";
/// ACTIONS ///
import { handleUpdateShopCounter } from "handler/shop_counter/update";
import { createShopBump } from "api/actions/shop_quote";
import { createLocalShopBump } from "api/actions/shop_quote";
import { createLocalShopCutBump } from "api/actions/shop_quote";
import { updateLocalShopCounter } from "api/actions/shop_quote";
import { deleteLocalShopBump } from "api/actions/shop_quote";
import { deleteLocalShopCutBump } from "api/actions/shop_quote";
import { deleteShopBump } from "api/actions/shop_quote";
import { markShopQuoteChanged } from "api/actions/shop_quote";
import { shopRestore } from "api/actions/shop_quote/history/local";
/// TYPES ///
import { IShopBump } from "api/types/shop_quote/bump";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCutBump } from "api/types/shop_quote/cut_bump";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopSlab } from "api/types/shop_quote/slab";
import { CutCornerAndShape } from "api/types/sketch";
import { Line } from "api/types/sketch";
/// COMPONENTS ///
import UpdateBump from "components/quote/drawer/update_bump";
import UpdateShopBumpRow from "./row";
/// FUNCTIONS ///
import { updateShopBump } from "functions/sketch/update";
import { getFirstShopSlabInAreas } from "functions/sketch/get/shop_slab";
import { getCutCornerByCornerUuid } from "functions/sketch";
/// VALUES ///
import { empty_bump } from "values/empty/sketch";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  selected_line: Line,
  setSelectedLine: (line: Line | null) => void
}

export default function UpdateShopBump({ open, setOpen, selected_line, setSelectedLine }: Props) {
  const dispatch = useAppDispatch();
  const { cut_shape_history } = useAppSelector((state) => state.history);
  const { counter_history } = useAppSelector((state) => state.history);
  const { history_position } = useAppSelector((state) => state.history);
  const { areas } = useAppSelector((state) => state.shop_quote);
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { cut_shapes } = useAppSelector((state) => state.shop_quote);
  const [expanded, setExpanded] = useState<number>(-1);
  const [bumps, setBumps] = useState<IShopBump[]>([]);

  useEffect(() => {
    if (open && selected_line?.start.bumps) {
      setBumps(selected_line.start.bumps as IShopBump[]);
    }
  }, [open]);

  const cancel = () => {
    dispatch(shopRestore(counter_history[history_position] as IShopCounter[], cut_shape_history[history_position] as IShopCutShape[]));
    setBumps([]);
    setOpen(false);
  }

  const save = () => {
    if (selected_line && bumps) {
      const counter: IShopCounter = updateShopBump(selected_line.counter as IShopCounter, bumps);
      const slab: IShopSlab | null = getFirstShopSlabInAreas(areas, counter.area_uuid);
      handleUpdateShopCounter(
        dispatch,
        counter,
        counters,
        cut_shapes, slab.uuid,
        "Change Bumps"
      );
    }
    setOpen(false);
    setBumps([]);
  }

  const update = (bump: IShopBump) => {
    const new_bumps: IShopBump[] = [...bumps.filter(b => b.uuid !== bump.uuid), bump];
    const counter: IShopCounter = updateShopBump(selected_line.counter as IShopCounter, bumps);
    dispatch(updateLocalShopCounter(counter));
    setBumps(new_bumps);
    if (selected_line && new_bumps) {
      setSelectedLine(
        {
          ...selected_line,
          start: {
            ...selected_line.start, bumps: selected_line.start.bumps.map(b => b.uuid === bump.uuid ?
              bump :
              b
            )
          }
        }
      );
    }
  }

  const add = () => {
    if (selected_line && selected_line.start) {
      const new_bump: IShopBump = {
        ...empty_bump,
        uuid: v4(),
        corner_uuid: selected_line.start.uuid,
        counter_uuid: selected_line.start.counter_uuid,
        corner: selected_line.start.id
      };
      dispatch(createLocalShopBump(
        new_bump,
        selected_line.start.uuid,
        selected_line.start.counter_uuid
      ));
      setSelectedLine({ ...selected_line, start: { ...selected_line.start, bumps: [...selected_line.start.bumps, new_bump] } });
      const corner_and_shape: CutCornerAndShape | null = getCutCornerByCornerUuid(
        new_bump.counter_uuid,
        new_bump.corner_uuid,
        cut_shapes
      );

      if (corner_and_shape) {
        const cut_bump: IShopCutBump = {
          uuid: v4(),
          bump_uuid: new_bump.uuid,
          counter_uuid: new_bump.counter_uuid,
          cut_corner_uuid: corner_and_shape.cut_corner.uuid,
          cut_shape_uuid: corner_and_shape.cut_shape.uuid,
          bump_type: new_bump.bump_type,
          depth: new_bump.depth,
          distance: new_bump.distance,
          left_side_angle: new_bump.left_side_angle,
          right_side_angle: new_bump.right_side_angle,
          width: new_bump.width
        }
        dispatch(createShopBump(new_bump));
        dispatch(createLocalShopCutBump(new_bump, cut_bump));
      }
      dispatch(markShopQuoteChanged());
    }
  }

  const remove = (index: number) => {
    const bump: IShopBump = bumps[index];

    if (selected_line && selected_line.start) {
      setSelectedLine({ ...selected_line, start: { ...selected_line.start, bumps: [...selected_line.start.bumps.filter(b => b.uuid !== bump.uuid)] } });
    }
    if (bumps) {
      setBumps(bumps.filter(b => b.uuid !== bump.uuid));
    }
    if (bump.id) {

    }
    dispatch(deleteShopBump(bump));
    dispatch(deleteLocalShopBump(bump));
    dispatch(deleteLocalShopCutBump(bump));
    dispatch(markShopQuoteChanged());
  }

  const toggleAccordion = (index: number) => {
    if (expanded === index) {
      setExpanded(-1);
    }
    else {
      setExpanded(index);
    }
  }

  return (
    <UpdateBump open={open} setOpen={setOpen} cancel={cancel} save={save} add={add}>
      {
        bumps.map((bump: IShopBump, index: number) => {
          return (
            <UpdateShopBumpRow
              index={index}
              expanded={expanded === index}
              toggle={toggleAccordion}
              bump={bump}
              cancel={cancel}
              close={() => setOpen(false)}
              save={save}
              add={add}
              update={update}
              remove={remove}
            />
          )
        })}
    </UpdateBump>
  )
}


