import { Dispatch } from "redux";
import { Action } from "api/action_types/sketch";
import { ActionType } from "api/action_types/sketch";
import { Bump } from "api/types/sketch";

export const createLocalBump = (bump: Bump, corner_uuid: string, counter_uuid: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CREATE_LOCAL_BUMP,
            payload: bump,
            corner_uuid: corner_uuid,
            counter_uuid: counter_uuid
        });
    }
}