import { sketch_color_1 } from "styles/sketch";
import { sketch_color_2 } from "styles/sketch";
import { sketch_color_3 } from "styles/sketch";
import { sketch_color_4 } from "styles/sketch";
import { sketch_color_5 } from "styles/sketch";
import { sketch_color_6 } from "styles/sketch";
import { sketch_color_7 } from "styles/sketch";
import { sketch_color_8 } from "styles/sketch";
import { sketch_color_9 } from "styles/sketch";
import { sketch_color_10 } from "styles/sketch";
import { sketch_color_11 } from "styles/sketch";
import { sketch_color_12 } from "styles/sketch";
import { sketch_color_13 } from "styles/sketch";
import { sketch_color_14 } from "styles/sketch";
import { sketch_color_15 } from "styles/sketch";
import { sketch_color_16 } from "styles/sketch";
import { sketch_color_amount } from "styles/sketch";

export function getCounterColor(color: number): string {
    switch(color % sketch_color_amount){
        case 0:
            return sketch_color_1;
        case 1:
            return sketch_color_2;
        case 2:
            return sketch_color_3;
        case 3:
            return sketch_color_4;
        case 4:
            return sketch_color_5;
        case 5:
            return sketch_color_6;
        case 6:
            return sketch_color_7;
        case 7:
            return sketch_color_8;
        case 8:
            return sketch_color_9;
        case 9:
            return sketch_color_10;
        case 10:
            return sketch_color_11;
        case 11:
            return sketch_color_12;
        case 12:
            return sketch_color_13;
        case 13: 
            return sketch_color_14;
        case 14:
            return sketch_color_15;
        case 15:
            return sketch_color_16;
        default:
            return sketch_color_1;
    }
}