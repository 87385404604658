/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// REACT ///
import { Fragment } from "react";
/// ACTION ///
import { getEndCustomer } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import CxJobItem from "./row/job";
import CxOrderItem from "./row/order";
import CxSOItem from "./row/self_order";
import CxQuoteItem from "./row/quote";
import CxWOItem from "./row/wo";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { item_style } from "styles/quote_preview/section";
import { title_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { delete_url } from "components/navigation/endpoints";
import { detail_url } from "components/navigation/endpoints";
import { end_customer_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { cx_list } from "values/breadcrumbs/customer";
import { cx } from "values/breadcrumbs/customer";

export default function CustomerDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { end_customer } = useAppSelector((state) => state.end_customer);
  const { user } = useAppSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(getEndCustomer(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (end_customer?.id) {
      dispatch(setBreadcrumbs([cx_list, { ...cx, label: `${end_customer?.first_name ?? ""} ${end_customer?.last_name ?? "Customer"}`, url: cx.url.replace("id", id) }]));
    }
  }, [dispatch, end_customer.id, end_customer?.first_name, end_customer?.last_name, id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start" sx={list_container_style}>
        <PageTitle title="Customer" size_lg={6} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${end_customer_url}/${detail_url}/${id}/${delete_url}`)} text="Delete" icon={faTrash} />
            <BaseButton clickEvent={() => navigate(`/${end_customer_url}/${detail_url}/${id}/${update_url}`)} text="Update" icon={faSave} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid container item xs={12} lg={6}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box style={header_style}></Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Name</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {`${end_customer?.first_name ?? ""} ${end_customer?.last_name ?? ""}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Email</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {end_customer?.email ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Phone</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {end_customer?.phone_number ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Service Address</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {end_customer?.geo_address ?? ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        <Grid container item xs={12} lg={6} sx={{ marginTop: "30px" }}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Billing
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box style={header_style}></Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Address</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {end_customer?.address ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>City</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {end_customer?.city ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Province</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {end_customer?.province ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Postal Code</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {end_customer?.postal_code ?? ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        <Grid container item xs={12} lg={6} sx={{ marginTop: "30px" }}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Quotes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box style={header_style}></Box>
          </Grid>
          <Grid item xs={12}>
            {end_customer?.quotes?.map(quote => <CxQuoteItem quote={quote} />)}
          </Grid>
        </Grid>
        {
          user.is_fabricator ?
            <Fragment>
              <Grid item xs={0} lg={6}></Grid>
              <Grid container item xs={12} lg={6}>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={title_style}>
                    Orders
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box style={header_style}></Box>
                </Grid>
                <Grid item xs={12}>
                  {end_customer?.self_orders?.map(so => <CxSOItem self_order={so} />)}
                </Grid>
              </Grid>
            </Fragment> :
            null
        }
        {
          user?.is_distributor ?
            <Fragment>
              <Grid item xs={0} lg={6}></Grid>
              <Grid container item xs={12} lg={6}>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={title_style}>
                    Orders
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box style={header_style}></Box>
                </Grid>
                <Grid item xs={12}>
                  {end_customer?.orders?.map(order => <CxOrderItem order={order} />)}
                </Grid>
              </Grid>
            </Fragment> :
            null
        }
        {
          user?.is_fabricator ?
            <Fragment>
              <Grid item xs={0} lg={6}></Grid>
              <Grid container item xs={12} lg={6}>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={title_style}>
                    Work Orders
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box style={header_style}></Box>
                </Grid>
                <Grid item xs={12}>
                  {end_customer?.orders?.map(wo => <CxWOItem wo={wo} />)}
                </Grid>
              </Grid>
            </Fragment> :
            null
        }
        <Grid item xs={0} lg={6}></Grid>
        <Grid container item xs={12} lg={6}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Job Sites
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box style={header_style}></Box>
          </Grid>
          <Grid item xs={12}>
            {end_customer?.jobs?.map(job => <CxJobItem job={job} />)}
          </Grid>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        <Grid container item xs={12} lg={6}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Notes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box style={header_style}></Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style(false)}>
              {end_customer?.entry_notes ?? ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </motion.div>
  )
}
