import { IFabricationJob } from "api/types/customer";

export enum ActionType {
  CREATE_FABRICATION_JOB_SUCCESS = "CREATE_FABRICTION_JOB_SUCCESS",
  DELETE_FABRICATION_JOB_SUCCESS = "DELETE_FABRICATION_JOB_SUCCESS",
  FABRICATION_JOB_UPDATING = "FABRICATION_JOB_UPDATING",
  FABRICATION_JOB_ERROR = "FABRICATION_JOB_ERROR",
  GET_FABRICATION_JOBS_SUCCESS = "GET_FABRICATION_JOBS_SUCCESS",
}

interface ICreateFabricationJobSuccess {
  type: ActionType.CREATE_FABRICATION_JOB_SUCCESS,
  fabrication_job: IFabricationJob
}

interface IDeleteFabricationJobSuccess {
  type: ActionType.DELETE_FABRICATION_JOB_SUCCESS
}

interface IFabricationJobUpdating {
  type: ActionType.FABRICATION_JOB_UPDATING
}

interface IFabricationJobError {
  type: ActionType.FABRICATION_JOB_ERROR,
  error: string
}

interface IGetFabricationJobsSuccess {
  type: ActionType.GET_FABRICATION_JOBS_SUCCESS,
  fabrication_jobs: IFabricationJob[]
}

export type Action =
  ICreateFabricationJobSuccess |
  IDeleteFabricationJobSuccess |
  IFabricationJobUpdating |
  IFabricationJobError |
  IGetFabricationJobsSuccess;
