/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateFabricatorRecessedDiagonalCorner } from "api/actions/fabricator";
/// TYPES ///
import { FabricatorRecessedDiagonalCorner } from "api/types/fabricator";
/// MUI COMPONENTS ///
import { Grid } from "@mui/material";
import { Slider } from "@mui/material";
import { Typography } from "@mui/material";
/// FM ///
import { motion } from "framer-motion";
/// LODASH ///
import { debounce } from "lodash";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";
import { slider_marks } from "values/price";

interface Props {
  index: number,
  item: FabricatorRecessedDiagonalCorner,
  updateLoading: (is_loading: boolean, id: number) => void
}

export default function FabricatorRecessedDiagonalCornerItem({ index, item, updateLoading }: Props) {
  const dispatch = useAppDispatch();
  const [current_weight, setCurrentWeight] = useState<number>(item?.weight ?? 0);

  useEffect(() => {
    setCurrentWeight(item?.weight ?? 0);
  }, [item.weight]);

  const update = useCallback(debounce(function(weight: number) {
    dispatch(updateFabricatorRecessedDiagonalCorner({ weight: weight }, item.id));
    if (item.id) {
      updateLoading(false, item.id);
    }
  }, 1000), []);

  const handlePrice = (weight: number) => {
    setCurrentWeight(weight);
    update(weight);
    if (item?.id) {
      updateLoading(true, item.id);
    }
  }

  return (
    <Grid item xs={12}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.4 * index } }}>
        <Grid container alignItems="center" sx={{ padding: 1 }}>
          <Grid item xs={4}>
            <Typography variant="body2">
              <b>{getMaterialHeightDisplay(item?.thickness ?? "")}</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">
              ${(current_weight / 100 * (item?.base_price ?? 0) + (item?.base_price ?? 0)).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Slider
              marks={slider_marks}
              valueLabelDisplay="auto"
              size="small"
              min={-30}
              max={30}
              aria-label="Price"
              value={current_weight}
              onChange={(e, v) => handlePrice(v as number)} />
          </Grid>
        </Grid>
      </motion.div>
    </Grid>
  )
}
