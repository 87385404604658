import { FabricatorPriceList } from "api/types/fabricator";
import { FabricatorApplianceEdge } from "api/types/fabricator";
import { FabricatorBumpIn } from "api/types/fabricator";
import { FabricatorBumpInArc } from "api/types/fabricator";
import { FabricatorBumpOut } from "api/types/fabricator";
import { FabricatorBumpOutArc } from "api/types/fabricator";
import { FabricatorBumpedOutCorner } from "api/types/fabricator";
import { FabricatorClippedCorner } from "api/types/fabricator";
import { FabricatorCooktopCutout } from "api/types/fabricator";
import { FabricatorFabricationCharge } from "api/types/fabricator";
import { FabricatorFaucetCutout } from "api/types/fabricator";
import { FabricatorInstallationCharge } from "api/types/fabricator";
import { FabricatorFinishedEdge } from "api/types/fabricator";
import { FabricatorFullArc } from "api/types/fabricator";
import { FabricatorInsideDiagonalCorner } from "api/types/fabricator";
import { FabricatorInsideRadiusCorner } from "api/types/fabricator";
import { FabricatorMiter } from "api/types/fabricator";
import { FabricatorWaterfall } from "api/types/fabricator";
import { FabricatorNotchedCorner } from "api/types/fabricator";
import { FabricatorOutletCutout } from "api/types/fabricator";
import { FabricatorRadiusCorner } from "api/types/fabricator";
import { FabricatorRecessedDiagonalCorner } from "api/types/fabricator";
import { FabricatorSinkCutout } from "api/types/fabricator";
import { FabricatorSplash } from "api/types/fabricator";
import { FabricatorStoneCountertopRemoval } from "api/types/fabricator";
import { FabricatorLaminateCountertopRemoval } from "api/types/fabricator";

export enum ActionType {
  FABRICATOR_PRICE_LIST_PENDING = "FABRICATOR_PRICE_LIST_PENDING",
  FABRICATOR_PRICE_LIST_FAIL = "FABRICATOR_PRICE_LIST_FAIL",
  GET_FABRICATOR_PRICE_LISTS_SUCCESS = "GET_FABRICATOR_PRICE_LISTS_SUCCESS",
  GET_FABRICATOR_PRICE_LIST_SUCCESS = "GET_FABRICATOR_PRICE_LIST_SUCCESS",
  UPDATE_FABRICATOR_PRICE_LIST_SUCCESS = "UPDATE_FABRICATOR_PRICE_LIST_SUCCESS",
  GET_FABRICATOR_MITERS_SUCCESS = "GET_FABRICATOR_MITERS_SUCCESS",
  GET_FABRICATOR_SPLASHES_SUCCESS = "GET_FABRICATOR_SPLASHES_SUCCESS",
  GET_FABRICATOR_WATERFALLS_SUCCESS = "GET_FABRICTOR_WATERFALLS_SUCCESS",
  GET_FABRICATOR_FABRICATION_CHARGES_SUCCESS = "GET_FABRICATOR_FABRICATION_CHARGES_SUCCESS",
  GET_FABRICATOR_INSTALLATION_CHARGES_SUCCESS = "GET_FABRICATOR_INSTALLATION_CHARGES_SUCCESS",
  GET_FABRICATOR_FINISHED_EDGES_SUCCESS = "GET_FABRICATOR_FINISHED_EDGES_SUCCESS",
  GET_FABRICATOR_APPLIANCE_EDGES_SUCCESS = "GET_FABRICATOR_APPLIANCE_EDGES_SUCCESS",
  GET_FABRICATOR_FAUCET_CUTOUTS_SUCCESS = "GET_FABRICATOR_FAUCET_CUTOUTS_SUCCESS",
  GET_FABRICATOR_OUTLET_CUTOUTS_SUCCESS = "GET_FABRICATOR_OUTLET_CUTOUTS_SUCCESS",
  GET_FABRICATOR_SINK_CUTOUTS_SUCCESS = "GET_FABRICATOR_SINK_CUTOUTS_SUCCESS",
  GET_FABRICATOR_COOKTOP_CUTOUTS_SUCCESS = "GET_FABRICATOR_COOKTOP_CUTOUTS_SUCCESS",
  GET_FABRICATOR_BUMP_INS_SUCCESS = "GET_FABRIACTOR_BUMP_INS_SUCCESS",
  GET_FABRICATOR_BUMP_IN_ARCS_SUCCESS = "GET_FABRICATOR_BUMP_IN_ARCS_SUCCESS",
  GET_FABRICATOR_BUMP_OUT_ARCS_SUCCESS = "GET_FABRICATOR_BUMP_OUT_ARCS_SUCCESS",
  GET_FABRICATOR_BUMP_OUTS_SUCCESS = "GET_FABRICATOR_BUMP_OUTS_SUCCESS",
  GET_FABRICATOR_BUMPED_OUT_CORNERS_SUCCESS = "GET_FABRICATOR_BUMPED_OUT_CORNERS_SUCCESS",
  GET_FABRICATOR_CLIPPED_CORNERS_SUCCESS = "GET_FABRICATOR_CLIPPED_CORNERS_SUCCESS",
  GET_FABRICATOR_NOTCHED_CORNERS_SUCCESS = "GET_FABRICATOR_NOTCHED_CORNERS_SUCCESS",
  GET_FABRICATOR_FULL_ARCS_SUCCESS = "GET_FABRICATOR_FULL_ARCS_SUCCESS",
  GET_FABRICATOR_INSIDE_DIAGONAL_CORNERS_SUCCESS = "GET_FABRICATOR_INSIDE_DIAGONAL_CORNERS_SUCCESS",
  GET_FABRICATOR_INSIDE_RADIUS_CORNERS_SUCCESS = "GET_FABRICATOR_INSIDE_RADIUS_CORNERS_SUCCESS",
  GET_FABRICATOR_OUTSIDE_RADIUS_CORNERS_SUCCESS = "GET_FABRICATOR_OUTSIDE_RADIUS_CORNERS_SUCCESS",
  GET_FABRICATOR_RECESSED_DIAGONAL_CORNERS_SUCCESS = "GET_FABRICATOR_RECESSED_DIAGONAL_CORNERS_SUCCESS",
  GET_FABRICATOR_STONE_COUNTERTOP_REMOVALS_SUCCESS = "GET_FABRICATOR_STONE_COUNTERTOP_REMOVALS_SUCCESS",
  GET_FABRICATOR_LAMINATE_COUNTERTOP_REMOVALS_SUCCESS = "GET_FABRICATOR_LAMINATE_COUNTERTOP_REMOVALS_SUCCESS",
  UPDATE_FABRICATOR_MATERIAL = "UPDATE_FABRICATOR_MATERIAL",
  UPDATE_FABRICATOR_SPLASH_SUCCESS = "UPDATE_FABRICATOR_SPLASH_SUCCESS",
  UPDATE_FABRICATOR_SPLASH_RANGE_SUCCESS = "UPDATE_FABRICATOR_SPLASH_RANGE_SUCCESS",
  UPDATE_FABRICATOR_WATERFALL_SUCCESS = "UPDATE_FABRICATOR_WATERFALL_SUCCESS",
  UPDATE_FABRICATOR_WATERFALL_RANGE_SUCCESS = "UPDATE_FABRICATOR_WATERFALL_RANGE_SUCCESS",
  UPDATE_FABRICATOR_FABRICATION_CHARGE_SUCCESS = "UPDATE_FABRICATOR_FABRICATION_CHARGE_SUCCESS",
  UPDATE_FABRICATOR_INSTALLATION_CHARGE_SUCCESS = "UPDATE_FABRICATOR_INSTALLATION_CHARGE_SUCCESS",
  UPDATE_FABRICATOR_FINISHED_EDGE_SUCCESS = "UPDATE_FABRICATOR_FINISHED_EDGE_SUCCESS",
  UPDATE_FABRICATOR_APPLIANCE_EDGE_SUCCESS = "UPDATE_FABRICATOR_APPLIANCE_EDGE_SUCCESS",
  UPDATE_FABRICATOR_FAUCET_CUTOUT_SUCCESS = "UPDATE_FABRICATOR_FAUCET_CUTOUT_SUCCESS",
  UPDATE_FABRICATOR_OUTLET_CUTOUT_SUCCESS = "UPDATE_FABRICATOR_OUTLET_CUTOUT_SUCCESS",
  UPDATE_FABRICATOR_SINK_CUTOUT_SUCCESS = "UPDATE_FABRICATOR_SINK_CUTOUT_SUCCESS",
  UPDATE_FABRICATOR_SINK_CUTOUT_RANGE_SUCCESS = "UPDATE_FABRICATOR_SINK_CUTOUT_RANGE_SUCCESS",
  UPDATE_FABRICATOR_COOKTOP_CUTOUT_SUCCESS = "UPDATE_FABRICATOR_COOKTOP_CUTOUT_SUCCESS",
  UPDATE_FABRICATOR_COOKTOP_CUTOUT_RANGE_SUCCESS = "UPDATE_FABRICATOR_COOKTOP_CUTOUT_RANGE_SUCCESS",
  UPDATE_FABRICATOR_BUMP_IN_ARC_SUCCESS = "UPDATE_FABRICATOR_BUMP_IN_ARC_SUCCESS",
  UPDATE_FABRICATOR_BUMP_IN_SUCCESS = "UPDATE_FABRICATOR_BUMP_IN_SUCCESS",
  UPDATE_FABRICATOR_BUMP_OUT_ARC_SUCCESS = "UPDATE_FABRICATOR_BUMP_OUT_ARC_SUCCESS",
  UPDATE_FABRICATOR_BUMP_OUT_SUCCESS = "UPDATE_FABRICATOR_BUMP_OUT_SUCCESS",
  UPDATE_FABRICATOR_BUMPED_OUT_CORNER_SUCCESS = "UPDATE_FABRICATOR_BUMPED_OUT_CORNER_SUCCESS",
  UPDATE_FABRICATOR_CLIPPED_CORNER_SUCCESS = "UPDATE_FABRICATOR_CLIPPED_CORNER_SUCCESS",
  UPDATE_FABRICATOR_FULL_ARC_SUCCESS = "UPDATE_FABRICATOR_FULL_ARC_SUCCESS",
  UPDATE_FABRICATOR_INSIDE_DIAGONAL_CORNER_SUCCESS = "UPDATE_FABRICATOR_INSIDE_DIAGONAL_CORNER_SUCCESS",
  UPDATE_FABRICATOR_INSIDE_RADIUS_CORNER_SUCCESS = "UPDATE_FABRICATOR_INSIDE_RADIUS_CORNER_SUCCESS",
  UPDATE_FABRICATOR_INSIDE_RADIUS_CORNER_RANGE_SUCCESS = "UPDATE_FABRICATOR_INSIDE_RADIUS_CORNER_RANGE_SUCCESS",
  UPDATE_FABRICATOR_OUTSIDE_RADIUS_CORNER_SUCCESS = "UPDATE_FABRICATOR_OUTSIDE_RADIUS_CORNER_SUCCESS",
  UPDATE_FABRICATOR_OUTSIDE_RADIUS_CORNER_RANGE_SUCCESS = "UPDATE_FABRICATOR_OUTSIDE_RADIUS_CORNER_RANGE_SUCCESS",
  UPDATE_FABRICATOR_RECESSED_DIAGONAL_CORNER_SUCCESS = "UPDATE_FABRICATOR_RECESSED_DIAGONAL_CORNER_SUCCESS",
  UPDATE_FABRICATOR_STONE_COUNTERTOP_REMOVAL_SUCCESS = "UPDATE_FABRICATOR_STONE_COUNTERTOP_REMOVAL_SUCCESS",
  UPDATE_FABRICATOR_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS = "UPDATE_FABRICATOR_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS",
  UPDATE_FABRICATOR_MITER_SUCCESS = "UPDATE_FABRICATOR_MITER_SUCCESS",
  UPDATE_FABRICATOR_MITER_RANGE_SUCCESS = "UPDATE_FABRICATOR_MITER_RANGE_SUCCESS",
  CLEAR_FABRICATOR_PRICE_LIST = "CLEAR_FABRICATOR_PRICE_LIST",
  CLEAR_FABRICATOR_PRICE_LISTS = "CLEAR_FABRICATOR_PRICE_LISTS",
  FABRICATOR_PENDING = "FABRICATOR_PENDING",
  FABRICATOR_FAIL = "FABRICATOR_FAIL"
}

interface getFabricatorPriceListsSuccess {
  type: ActionType.GET_FABRICATOR_PRICE_LISTS_SUCCESS,
  payload: FabricatorPriceList[]
}

interface getFabricatorPriceListSuccess {
  type: ActionType.GET_FABRICATOR_PRICE_LIST_SUCCESS,
  payload: FabricatorPriceList
}

interface fabricatorPriceListPending {
  type: ActionType.FABRICATOR_PRICE_LIST_PENDING
}

interface fabricatorPriceListFail {
  type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
  payload: string
}

interface IGetFabricatorMitersSuccess {
  type: ActionType.GET_FABRICATOR_MITERS_SUCCESS,
  payload: FabricatorMiter[]
}

interface getFabricatorSplashesSuccess {
  type: ActionType.GET_FABRICATOR_SPLASHES_SUCCESS,
  payload: FabricatorSplash[]
}

interface IGetFabricatorWaterfallSuccess {
  type: ActionType.GET_FABRICATOR_WATERFALLS_SUCCESS,
  payload: FabricatorWaterfall[]
}

interface getFabricatorFabricationChargesSuccess {
  type: ActionType.GET_FABRICATOR_FABRICATION_CHARGES_SUCCESS,
  payload: FabricatorFabricationCharge[]
}

interface getFabricatorInstallationChargesSuccess {
  type: ActionType.GET_FABRICATOR_INSTALLATION_CHARGES_SUCCESS,
  payload: FabricatorInstallationCharge[]
}

interface getFabricatorFinishedEdgesSuccess {
  type: ActionType.GET_FABRICATOR_FINISHED_EDGES_SUCCESS,
  payload: FabricatorFinishedEdge[]
}

interface getFabricatorFaucetCutoutsSuccess {
  type: ActionType.GET_FABRICATOR_FAUCET_CUTOUTS_SUCCESS,
  payload: FabricatorFaucetCutout[]
}

interface getFabricatorOutletCutoutsSuccess {
  type: ActionType.GET_FABRICATOR_OUTLET_CUTOUTS_SUCCESS,
  payload: FabricatorOutletCutout[]
}

interface getFabriatorSinkCutoutsSuccess {
  type: ActionType.GET_FABRICATOR_SINK_CUTOUTS_SUCCESS,
  payload: FabricatorSinkCutout[]
}

interface getFabricatorCooktopCutoutsSuccess {
  type: ActionType.GET_FABRICATOR_COOKTOP_CUTOUTS_SUCCESS,
  payload: FabricatorCooktopCutout[]
}

interface getFabricatorBumpInsSuccess {
  type: ActionType.GET_FABRICATOR_BUMP_INS_SUCCESS,
  payload: FabricatorBumpIn[]
}

interface getFabricatorBumpInArcsSuccess {
  type: ActionType.GET_FABRICATOR_BUMP_IN_ARCS_SUCCESS,
  payload: FabricatorBumpInArc[]
}

interface getFabricatorBumpOutsSuccess {
  type: ActionType.GET_FABRICATOR_BUMP_OUTS_SUCCESS,
  payload: FabricatorBumpOut[]
}

interface getFabricatorBumpOutArcsSuccess {
  type: ActionType.GET_FABRICATOR_BUMP_OUT_ARCS_SUCCESS,
  payload: FabricatorBumpOutArc[]
}

interface getFabricatorBumpedOutCornersSuccess {
  type: ActionType.GET_FABRICATOR_BUMPED_OUT_CORNERS_SUCCESS,
  payload: FabricatorBumpedOutCorner[]
}

interface getFabricatorClippedCornersSuccess {
  type: ActionType.GET_FABRICATOR_CLIPPED_CORNERS_SUCCESS,
  payload: FabricatorClippedCorner[]
}

interface getFabricatorNotchedCornersSuccess {
  type: ActionType.GET_FABRICATOR_NOTCHED_CORNERS_SUCCESS,
  payload: FabricatorNotchedCorner[]
}

interface getFabricatorFullArcsSuccess {
  type: ActionType.GET_FABRICATOR_FULL_ARCS_SUCCESS,
  payload: FabricatorFullArc[]
}

interface getFabricatorInsideDiagonalCornersSuccess {
  type: ActionType.GET_FABRICATOR_INSIDE_DIAGONAL_CORNERS_SUCCESS,
  payload: FabricatorInsideDiagonalCorner[]
}

interface getFabricatorInsideRadiusCornersSuccess {
  type: ActionType.GET_FABRICATOR_INSIDE_RADIUS_CORNERS_SUCCESS,
  payload: FabricatorInsideRadiusCorner[]
}

interface getFabricatorOutsideRadiusCornersSuccess {
  type: ActionType.GET_FABRICATOR_OUTSIDE_RADIUS_CORNERS_SUCCESS,
  payload: FabricatorRadiusCorner[]
}

interface getFabricatorRecessedDiagonalCorners {
  type: ActionType.GET_FABRICATOR_RECESSED_DIAGONAL_CORNERS_SUCCESS,
  payload: FabricatorRecessedDiagonalCorner[]
}

interface getFabricatorStoneCountertopRemovalsSuccess {
  type: ActionType.GET_FABRICATOR_STONE_COUNTERTOP_REMOVALS_SUCCESS,
  payload: FabricatorStoneCountertopRemoval[]
}

interface getFabricatorLaminateCountertopRemovalsSuccess {
  type: ActionType.GET_FABRICATOR_LAMINATE_COUNTERTOP_REMOVALS_SUCCESS,
  payload: FabricatorLaminateCountertopRemoval[]
}

interface updateFabricatorPriceListSuccess {
  type: ActionType.UPDATE_FABRICATOR_PRICE_LIST_SUCCESS,
  payload: FabricatorPriceList
}

interface IUpdateFabricatorMiterSuccess {
  type: ActionType.UPDATE_FABRICATOR_MITER_SUCCESS
}

interface IUpdateFabricatorMiterRangeSuccess {
  type: ActionType.UPDATE_FABRICATOR_MITER_RANGE_SUCCESS
}

interface IUpdateFabricatorWaterfallSuccess {
  type: ActionType.UPDATE_FABRICATOR_WATERFALL_SUCCESS
}

interface IUpdateFabricatorWaterfallRangeSuccess {
  type: ActionType.UPDATE_FABRICATOR_WATERFALL_RANGE_SUCCESS
}

interface updateFabricatorSplashSuccess {
  type: ActionType.UPDATE_FABRICATOR_SPLASH_SUCCESS
}

interface updateFabricatorSplashRangeSuccess {
  type: ActionType.UPDATE_FABRICATOR_SPLASH_RANGE_SUCCESS
}

interface updateFabricatorFabricationChargeSuccess {
  type: ActionType.UPDATE_FABRICATOR_FABRICATION_CHARGE_SUCCESS
}

interface updateFabricatorInstallationChargeSuccess {
  type: ActionType.UPDATE_FABRICATOR_INSTALLATION_CHARGE_SUCCESS
}

interface updateFabricatorFinishedEdgeSuccess {
  type: ActionType.UPDATE_FABRICATOR_FINISHED_EDGE_SUCCESS
}

interface getFabricatorApplianceEdgesSuccess {
  type: ActionType.GET_FABRICATOR_APPLIANCE_EDGES_SUCCESS,
  payload: FabricatorApplianceEdge[]
}

interface updateFabricatorApplianceEdgeSuccess {
  type: ActionType.UPDATE_FABRICATOR_APPLIANCE_EDGE_SUCCESS
}

interface updateFabricatorFaucetCutoutSuccess {
  type: ActionType.UPDATE_FABRICATOR_FAUCET_CUTOUT_SUCCESS
}

interface updateFabricatorOutletCutoutSuccess {
  type: ActionType.UPDATE_FABRICATOR_OUTLET_CUTOUT_SUCCESS
}

interface updateFabricatorOutletCutoutSuccess {
  type: ActionType.UPDATE_FABRICATOR_OUTLET_CUTOUT_SUCCESS
}

interface updateFabricatorSinkCutoutSuccess {
  type: ActionType.UPDATE_FABRICATOR_SINK_CUTOUT_SUCCESS
}

interface updateFabricatorSinkCutoutRangeSuccess {
  type: ActionType.UPDATE_FABRICATOR_SINK_CUTOUT_RANGE_SUCCESS
}

interface updateFabricatorCooktopCutoutSuccess {
  type: ActionType.UPDATE_FABRICATOR_COOKTOP_CUTOUT_SUCCESS
}

interface updateFabricatorCooktopCutoutRangeSuccess {
  type: ActionType.UPDATE_FABRICATOR_COOKTOP_CUTOUT_RANGE_SUCCESS
}

interface updateFabricatorBumpInArcSuccess {
  type: ActionType.UPDATE_FABRICATOR_BUMP_IN_ARC_SUCCESS,
}

interface updateFabricatorBumpInSuccess {
  type: ActionType.UPDATE_FABRICATOR_BUMP_IN_SUCCESS,
}

interface updateFabricatorBumpOutArcSuccess {
  type: ActionType.UPDATE_FABRICATOR_BUMP_OUT_ARC_SUCCESS,
}

interface updateFabricatorBumpOutSuccess {
  type: ActionType.UPDATE_FABRICATOR_BUMP_OUT_SUCCESS,
}

interface updateFabricatorBumpedOutCornerSuccess {
  type: ActionType.UPDATE_FABRICATOR_BUMPED_OUT_CORNER_SUCCESS,
}

interface updateFabricatorClippedCornerSuccess {
  type: ActionType.UPDATE_FABRICATOR_CLIPPED_CORNER_SUCCESS
}

interface updateFabricatorFullArcSuccess {
  type: ActionType.UPDATE_FABRICATOR_FULL_ARC_SUCCESS
}

interface updateFabricatorInsideDiagonalCornerSuccess {
  type: ActionType.UPDATE_FABRICATOR_INSIDE_DIAGONAL_CORNER_SUCCESS
}

interface updateFabricatorInsideRadiusCornerSuccess {
  type: ActionType.UPDATE_FABRICATOR_INSIDE_RADIUS_CORNER_SUCCESS
}

interface updateFabricatorInsideRadiusCornerRangeSuccess {
  type: ActionType.UPDATE_FABRICATOR_INSIDE_RADIUS_CORNER_RANGE_SUCCESS
}

interface updateFabricatorOutsideRadiusCornerSuccess {
  type: ActionType.UPDATE_FABRICATOR_OUTSIDE_RADIUS_CORNER_SUCCESS
}

interface updateFabricatorOutsideRadiusCornerRangeSuccess {
  type: ActionType.UPDATE_FABRICATOR_OUTSIDE_RADIUS_CORNER_RANGE_SUCCESS
}

interface updateFabricatorRecessedDiagonalCornerSuccess {
  type: ActionType.UPDATE_FABRICATOR_RECESSED_DIAGONAL_CORNER_SUCCESS
}

interface updateFabricatorStoneCountertopRemovalSuccess {
  type: ActionType.UPDATE_FABRICATOR_STONE_COUNTERTOP_REMOVAL_SUCCESS,
  payload: FabricatorStoneCountertopRemoval
}

interface updateFabricatorLaminateCountertopRemovalSuccess {
  type: ActionType.UPDATE_FABRICATOR_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS,
  payload: FabricatorLaminateCountertopRemoval
}

interface clearFabricatorPriceList {
  type: ActionType.CLEAR_FABRICATOR_PRICE_LIST
}

interface IClearFabrictorPriceLists {
  type: ActionType.CLEAR_FABRICATOR_PRICE_LISTS
}

export type Action =
  fabricatorPriceListPending |
  fabricatorPriceListFail |
  getFabricatorPriceListsSuccess |
  getFabricatorPriceListSuccess |
  IGetFabricatorMitersSuccess |
  IGetFabricatorWaterfallSuccess |
  getFabricatorSplashesSuccess |
  getFabricatorFabricationChargesSuccess |
  getFabricatorInstallationChargesSuccess |
  getFabricatorFinishedEdgesSuccess |
  getFabricatorFaucetCutoutsSuccess |
  getFabricatorOutletCutoutsSuccess |
  getFabriatorSinkCutoutsSuccess |
  getFabricatorCooktopCutoutsSuccess |
  getFabricatorBumpInsSuccess |
  getFabricatorBumpInArcsSuccess |
  getFabricatorBumpOutsSuccess |
  getFabricatorBumpOutArcsSuccess |
  getFabricatorBumpedOutCornersSuccess |
  getFabricatorClippedCornersSuccess |
  getFabricatorNotchedCornersSuccess |
  getFabricatorFullArcsSuccess |
  getFabricatorInsideDiagonalCornersSuccess |
  getFabricatorInsideRadiusCornersSuccess |
  getFabricatorOutsideRadiusCornersSuccess |
  getFabricatorRecessedDiagonalCorners |
  getFabricatorStoneCountertopRemovalsSuccess |
  getFabricatorLaminateCountertopRemovalsSuccess |
  updateFabricatorPriceListSuccess |
  IUpdateFabricatorMiterSuccess |
  IUpdateFabricatorMiterRangeSuccess |
  IUpdateFabricatorWaterfallSuccess |
  IUpdateFabricatorWaterfallRangeSuccess |
  updateFabricatorSplashSuccess |
  updateFabricatorSplashRangeSuccess |
  updateFabricatorFabricationChargeSuccess |
  updateFabricatorInstallationChargeSuccess |
  updateFabricatorFinishedEdgeSuccess |
  getFabricatorApplianceEdgesSuccess |
  updateFabricatorApplianceEdgeSuccess |
  updateFabricatorFaucetCutoutSuccess |
  updateFabricatorOutletCutoutSuccess |
  updateFabricatorSinkCutoutSuccess |
  updateFabricatorSinkCutoutRangeSuccess |
  updateFabricatorCooktopCutoutSuccess |
  updateFabricatorCooktopCutoutRangeSuccess |
  updateFabricatorBumpInArcSuccess |
  updateFabricatorBumpInSuccess |
  updateFabricatorBumpOutArcSuccess |
  updateFabricatorBumpOutSuccess |
  updateFabricatorBumpedOutCornerSuccess |
  updateFabricatorClippedCornerSuccess |
  updateFabricatorFullArcSuccess |
  updateFabricatorInsideDiagonalCornerSuccess |
  updateFabricatorInsideRadiusCornerSuccess |
  updateFabricatorInsideRadiusCornerRangeSuccess |
  updateFabricatorOutsideRadiusCornerSuccess |
  updateFabricatorOutsideRadiusCornerRangeSuccess |
  updateFabricatorRecessedDiagonalCornerSuccess |
  updateFabricatorStoneCountertopRemovalSuccess |
  updateFabricatorLaminateCountertopRemovalSuccess |
  clearFabricatorPriceList |
  IClearFabrictorPriceLists;
