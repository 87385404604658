/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopQuoteFilter } from "api/types/shop_quote/filter";

export const filterShopQuotes = (filter: IShopQuoteFilter) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_SHOP_QUOTE_FILTER,
      filter
    });
  }
}
