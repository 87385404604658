/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getEndCustomer } from "api/actions/end_customer";
import { setEndCustomer } from "api/actions/end_customer";
import { updateEndCustomer } from "api/actions/end_customer";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { ICustomerErrors } from "api/types/customer";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
/// PAGE COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// COMPONENTS ///
import AddressAutocomplete from "components/universal/autocomplete/geo";
import ProvinceSelector from "components/universal/select_field/province";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
/// VALIDATE ///
import { checkSaveCustomer } from "values/check/customer";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { detail_url } from "components/navigation/endpoints";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { end_customer_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { cx_list } from "values/breadcrumbs/customer";
import { cx } from "values/breadcrumbs/customer";
import { cx_update } from "values/breadcrumbs/customer";

export default function UpdateCustomer() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { end_customer } = useAppSelector((state) => state.end_customer);
  const [errors, setErrors] = useState<ICustomerErrors>({});

  useEffect(() => {
    if (!end_customer?.id || end_customer.id.toString() !== id) {
      dispatch(getEndCustomer(id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (end_customer?.id) {
      dispatch(setBreadcrumbs([
        cx_list,
        { ...cx, label: `${end_customer?.first_name ?? ""} ${end_customer?.last_name ?? "Customer"}`, url: cx.url.replace("id", id) },
        { ...cx_update, url: cx.url.replace("id", id) }
      ]));
    }
  }, [dispatch, end_customer.id]);

  const saveString = (key: string, value: string) => {
    dispatch(setEndCustomer({ ...end_customer, [key]: value }));
    setErrors({ ...errors, [`${key}_error`]: "", [`${key}_has_error`]: false });
  }

  const updateGeo = (label: string, lat: number, lng: number, address: string, city: string, province: string) => {
    dispatch(setEndCustomer({
      ...end_customer,
      geo_address: label,
      lat: lat,
      lng: lng,
      address: end_customer.address && end_customer.address !== "" ? end_customer.address : address,
      city: end_customer.city && end_customer.city !== "" ? end_customer.city : city,
      province: end_customer.province && end_customer.province !== "" ? end_customer.province : province
    }));
  }

  const geoError = () => {
    setErrors({ ...errors, geo_error: "Unable to retrieve coordinates of this address.", geo_has_error: true });
  }

  const update = () => {
    const errors: ICustomerErrors = checkSaveCustomer(end_customer);

    if (errors.email_has_error || errors.first_name_has_error || errors.last_name_has_error || errors.geo_has_error) {
      setErrors(errors);
      return;
    }

    dispatch(updateEndCustomer(end_customer, id));
    navigate(`/${end_customer_url}/${detail_url}/${id}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Update Customer" size_lg={6} endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${end_customer_url}/${detail_url}/${id}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={update} text="Update" icon={faSave} />
          </Box>
        } />
        <Grid item xs={0} lg={6}></Grid>
        <Grid item xs={12} lg={6}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
        {
          end_customer ?
            <Fragment>
              <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
                  <Stack direction="row" spacing={1}>
                    <TextField
                      value={end_customer.first_name}
                      onChange={e => saveString("first_name", e.target.value)}
                      error={errors?.first_name_has_error}
                      helperText={errors?.first_name_error ?? ""}
                      label="First Name" />
                    <TextField
                      value={end_customer.last_name}
                      onChange={e => saveString("last_name", e.target.value)}
                      error={errors?.last_name_has_error}
                      helperText={errors?.last_name_error ?? ""}
                      label="Last Name" />
                  </Stack>
                  <TextField
                    value={end_customer.email}
                    onChange={e => saveString("email", e.target.value)}
                    error={errors?.email_has_error}
                    helperText={errors?.email_error ?? ""}
                    label="Email" />
                  <TextField
                    value={end_customer.phone_number}
                    onChange={e => saveString("phone_number", e.target.value)}
                    label="Phone Number" />
                  <AddressAutocomplete
                    input_label="Service Address"
                    label={end_customer.geo_address}
                    error={errors?.geo_error ?? ""}
                    has_error={errors?.geo_has_error}
                    geoError={geoError}
                    updateGeo={updateGeo} />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px", marginTop: "30px" }}>
                  <TextField
                    value={end_customer.address}
                    onChange={e => saveString("address", e.target.value)}
                    label="Billing Address" />
                  <TextField
                    value={end_customer.city}
                    onChange={e => saveString("city", e.target.value)}
                    label="Billing City" />
                  <ProvinceSelector
                    province={end_customer.province}
                    setProvince={province => saveString("province", province)} />
                  <TextField
                    value={end_customer.postal_code}
                    onChange={e => saveString("postal_code", e.target.value)}
                    label="Billing Postal Code" />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px", marginTop: "30px" }}>
                  <TextField
                    sx={{ width: "540px" }}
                    multiline={process.env.NODE_ENV === "development" ? false : true}
                    minRows={3}
                    maxRows={3}
                    onChange={e => saveString("entry_notes", e.target.value)}
                    value={end_customer.entry_notes}
                    label="Notes" />
                </Box>
              </Grid>
            </Fragment> :
            null
        }
      </Grid>
    </motion.div>
  )
}
