import { Vector2 } from "api/types/sketch";
import { toDegrees } from "./math";
import { toRadians } from "./math";

function polarToCartesian(centerX: number, centerY: number, radius: number, angle: number): Vector2 {
    return {
        X: centerX + (radius * Math.cos(angle)),
        Y: centerY + (radius * Math.sin(angle))
    }
}

export function describeArc(
    X: number,
    Y: number,
    radius_width: number,
    radius_depth: number,
    start_angle: number,
    end_angle: number,
    sweep: number = 0,
    rotation: number = 0
): string {
    const start: Vector2 = polarToCartesian(X, Y, radius_width, start_angle);
    const end: Vector2 = polarToCartesian(X, Y, radius_width, end_angle);

    const large_arc_flag = toDegrees(end_angle) - toDegrees(start_angle) <= 180 ? "0" : "1";
    return [
        `M ${start.X}, ${start.Y}`,
        `A ${radius_width},${radius_depth} ${rotation} ${large_arc_flag} ${sweep} ${end.X} ${end.Y}`
    ].join(" ");
}

export function describeArcWithPoints(
    start: Vector2,
    end: Vector2,
    radius_width: number,
    radius_depth: number,
    start_angle: number,
    end_angle: number,
    sweep: number = 0,
    rotation: number = 0
): string {

    const large_arc_flag = toDegrees(end_angle) - toDegrees(start_angle) <= 180 ? "0" : "1";
    return [
        `M ${start.X}, ${start.Y}`,
        `A ${radius_width},${radius_depth} ${rotation} ${large_arc_flag} ${sweep} ${end.X} ${end.Y}`
    ].join(" ");
}

export function describeOval(
    X: number,
    Y: number,
    radius_width: number,
    radius_depth: number,
    start_angle: number,
    end_angle: number,
    rotation: number = 0
): string {
    const start: Vector2 = polarToCartesian(X, Y, radius_width, toRadians(rotation));
    const end: Vector2 = polarToCartesian(X, Y, radius_width, toRadians(rotation - 1));

    const large_arc_flag = toDegrees(end_angle) - toDegrees(start_angle) <= 180 ? "0" : "1";
    return [
        `M ${end.X}, ${end.Y}`,
        `A ${radius_width},${radius_depth} ${rotation} ${large_arc_flag} 0 ${start.X} ${start.Y}`
    ].join(" ");
}