/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateProfile } from "api/actions/authentication";
/// MUI ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void
}

export default function ConfirmNetworkFee({ open, setOpen }: Props) {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.authentication);

  const declineNetworkFee = () => {
    dispatch(updateProfile({ declined_network_fee: true, prompt_network_fee: false }, user?.id));
    setOpen(false);
  }

  const acceptNetworkFee = () => {
    dispatch(updateProfile({ accepted_network_fee: true, prompt_network_fee: false }, user?.id));
    setOpen(false);
  }

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>Network Fee</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Typography variant="body2">
          Arris will now apply a network fee of {user?.region_data?.network_fee}% to orders in {user?.region_data?.name ?? "your region"}. This fee will be applied when you receive an order from a dealer through the marketplace. Only orders generated by the Arris market will have the fee applied. Orders that you generate yourself will have no fees.
          <br />
          <br />
          The network fee will be included in the order total, so ultimately the fee will be paid by the customer purchasing the order, not by the fabricator shops. At the time the order is finalized, {user?.region_data?.network_fee}% of the subtotal will be charged to your payment method on file.
          <br />
          <br />
          This fee will help expand the Arris network to sign up more dealers, create more orders and generate more revenue for your business. Please hit "Accept" below to accept this fee and remain in the market.
          <br />
          <br />
          You will be notified prior to any changes to this system in the future.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={declineNetworkFee}>Decline</Button>
        <Button onClick={acceptNetworkFee}>Accept</Button>
      </DialogActions>
    </Dialog>
  )
}
