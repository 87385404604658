/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";

export const createLocalShopCounter = (counter: IShopCounter) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CREATE_LOCAL_SHOP_COUNTER,
      payload: counter
    });
  }
}
