/// REACT ///
import { Fragment } from "react";
/// MUI ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { light_grey_color } from "styles/style";

const grid_header_style = {
  padding: "5px",
  borderTop: `1px solid ${light_grey_color}`,
  borderBottom: `1px solid ${light_grey_color}`,
  borderRight: `1px solid ${light_grey_color}`
}

export default function WeekHeader() {
  return (
    <Fragment>
      <Grid item xs={1.71} sx={[grid_header_style, { borderLeft: `1px solid ${light_grey_color}` }]}>
        <Typography variant="body2">
          Sun
        </Typography>
      </Grid>
      <Grid item xs={1.71}>
        <Typography variant="body2" sx={grid_header_style}>
          Mon
        </Typography>
      </Grid>
      <Grid item xs={1.71} sx={grid_header_style}>
        <Typography variant="body2">
          Tue
        </Typography>
      </Grid>
      <Grid item xs={1.71} sx={grid_header_style}>
        <Typography variant="body2">
          Wed
        </Typography>
      </Grid>
      <Grid item xs={1.71} sx={grid_header_style}>
        <Typography variant="body2">
          Thu
        </Typography>
      </Grid>
      <Grid item xs={1.71} sx={grid_header_style}>
        <Typography variant="body2">
          Fri
        </Typography>
      </Grid>
      <Grid item xs={1.71} sx={grid_header_style}>
        <Typography variant="body2">
          Sat
        </Typography>
      </Grid>
    </Fragment >
  )
}
