import { IAddon } from "api/types/quote";

export enum ActionType {
  CREATE_ADDON = "CREATE_ADDON",
  UPDATE_ADDON = "UPDATE_ADDON",
  DELETE_ADDON = "DELETE_ADDON"
}

interface ICreateAddon {
  type: ActionType.CREATE_ADDON,
  addon: IAddon
}

interface IUpdateAddon {
  type: ActionType.UPDATE_ADDON,
  addon: IAddon
}

interface IDeleteAddon {
  type: ActionType.DELETE_ADDON,
  addon: IAddon
}

export type Action = ICreateAddon | IUpdateAddon | IDeleteAddon;
