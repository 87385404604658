/// TYPES ///
import { HighlightData } from "types";
/// VALUES ///
import { SPLASH } from "./values";
import { WATERFALL } from "./values";
import { UNFINISHED } from "./values";
import { APPLIANCE } from "./values";
import { SPECIALTY_DISPLAY } from "./text/edge";
import { MITER_DISPLAY } from "./text/edge";
import { EASED_DISPLAY } from "./text/edge";
import { BULLNOSE_DISPLAY } from "./text/edge";
import { BEVEL_DISPLAY } from "./text/edge";
import { NOTCH } from "./quote";
import { CLIP } from "./quote";
import { RADIUS } from "./quote";
import { STANDARD } from "./quote";
import { BUMPOUT } from "./quote";
import { INNER_RADIUS } from "./quote";
import { RECESSED_DIAGONAL } from "./quote";
import { DIAGONAL } from "./quote";

export const WIDTH: string = "W";
export const HEIGHT: string = "H";
export const ANGLE: string = "A";
export const EDGE: string = "E";
export const CORNER: string = "C";

export function getCopyMessage(data: HighlightData): string {
  if (data?.text?.type === WIDTH) {
    return `${data?.text?.value ?? ""}"`;
  }
  else if (data?.text?.type === HEIGHT) {
    return `${data?.text?.value ?? ""}"`;
  }
  else if (data?.text?.type === ANGLE) {
    return `${data?.text?.value ?? ""}\u00B0`;
  }
  else if (data?.text?.type === EDGE) {
    if (
      data?.text?.text === BULLNOSE_DISPLAY ||
      data?.text?.text === BEVEL_DISPLAY ||
      data?.text?.text === EASED_DISPLAY ||
      data?.text?.text === SPECIALTY_DISPLAY ||
      data?.text?.text === UNFINISHED ||
      data?.text?.text === APPLIANCE
    ) {
      return `${data?.text.text}`;
    }
    else if (
      data?.text?.text === MITER_DISPLAY ||
      data?.text?.text === WATERFALL ||
      data?.text?.text === SPLASH
    ) {
      return `${data?.text?.text ?? ""} ${data?.text?.real_value ?? ""}"`;
    }
  }
  else if (data?.text?.type === CORNER) {
    if (data?.text?.text === STANDARD) {
      return `${data?.text?.text ?? ""}`;
    }
    else if (data?.text?.text === CLIP || data?.text?.text === DIAGONAL) {
      return `${data?.text?.text} ${data?.text?.value}"`;
    }
    else if (data?.text?.text === RADIUS || data?.text?.text === INNER_RADIUS) {
      return `${data?.text?.text}${data?.text?.value}"`;
    }
    else if (data?.text?.text === NOTCH || data?.text?.text === BUMPOUT || data?.text?.text === RECESSED_DIAGONAL) {
      return `${data?.text?.text} ${data?.text?.value}"x${data?.text?.real_value}"`;
    }
  }
  else if (data?.sink) {
    return `${data?.sink?.sink_type ?? ""} ${data?.sink?.length ?? ""}"x${data?.sink?.width ?? ""}"`;
  }
  else if (data?.cooktop) {
    return `Cooktop ${data?.cooktop?.length ?? ""}"x${data?.cooktop?.width ?? ""}"`;
  }
  return "";
}
