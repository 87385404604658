import { OrderCounter } from "api/types/order";
import { SelfOrderCounter } from "api/types/order";
import { Text } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { degreeText } from "../text";
import { getMidpoint } from "functions/sketch/math";
import { widthText } from "../text";
import { constructWidthMeasurementIndicator } from "functions/sketch";

export function drawOrderPoints(
    counter: OrderCounter | SelfOrderCounter,
    measurement_path: Path2D,
    texts: Text[],
    zoom: number,
    offset: Vector2
){
    for(let j = 0; j < counter.points.length; j++){
        if(j !== 0 && j !== counter.points.length - 1){
            const current_location: Vector2 = {
                X: Math.round(counter.points[j].location.X * zoom) + offset.X,
                Y: Math.round(counter.points[j].location.Y * zoom) + offset.Y
            };
            const next_location: Vector2 = {
                X: Math.round(counter.points[j+1].location.X * zoom) + offset.X,
                Y: Math.round(counter.points[j+1].location.Y * zoom) + offset.Y
            };
            let degrees: number = Math.abs(counter.points[j].angle - counter.points[j - 1].angle);
            if(degrees > 180){
                degrees -= 360;
            }

            const degree_text: Text = degreeText(
                current_location,
                Math.abs(degrees),
                "",
                ""
            );
            texts.push(degree_text);

            if(j !== counter.points.length - 2){
                const text_location: Vector2 = getMidpoint(current_location, next_location);

                const width_text: Text = widthText(
                    text_location,
                    counter.points[j].width,
                    "",
                    ""
                );
                texts.push(width_text);

                if(counter.points[j].width / 4 > 20){
                    const path: Path2D = constructWidthMeasurementIndicator(
                        text_location, counter.points[j].width / 4, counter.points[j].angle + 90);

                    measurement_path.addPath(path);
                }
            }
        }
    }
}