import { Vector2 } from "api/types/sketch";
import { BumpConstruct } from "api/types/sketch";
import { constructBumpOut } from "../construct";

export function drawPrintBumpOut(
    painter: any,
    start: Vector2,
    end: Vector2,
    distance: number,
    width: number,
    depth: number,
    angle: number,
    left_side_angle: number,
    right_side_angle: number,
    zoom: number
){
    const bump_construct: BumpConstruct = constructBumpOut(
        start, end, angle, distance, width, depth, left_side_angle, right_side_angle, zoom
    );

    painter.lineTo(bump_construct.A.X, bump_construct.A.Y);
    painter.lineTo(bump_construct.B.X, bump_construct.B.Y);
    painter.lineTo(bump_construct.C.X, bump_construct.C.Y);
    painter.lineTo(bump_construct.D.X, bump_construct.D.Y);
}