/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateLocalCounter } from "api/actions/sketch/counter";
import { restore } from "api/actions/sketch/history";
/// TYPES ///
import { Counter } from "api/types/sketch";
import { Slab } from "api/types/quote";
import { Corner } from "api/types/sketch";
/// COMPONENTS ///
import UpdateWidth from "components/quote/drawer/update_width";
/// FUNCTIONS ///
import { createCorners } from "functions/sketch";
import { getFirstSlabInAreas } from "functions/sketch/get/slab";
import { handleUpdateCounter } from "handler/counter/update";
import { updateWidth } from "functions/sketch";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  original_value: number | null,
  point_uuid: string,
  selected_counter: Counter | null
}

export default function UpdateStoreWidth({ open, setOpen, original_value, point_uuid, selected_counter }: Props) {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.quote);
  const { counters } = useAppSelector((state) => state.sketch);
  const { cut_shapes } = useAppSelector((state) => state.sketch);
  const { cut_shape_history } = useAppSelector((state) => state.history);
  const { counter_history } = useAppSelector((state) => state.history);
  const { history_position } = useAppSelector((state) => state.history);
  const [error, setError] = useState<string>("");
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (open && original_value) {
      setWidth(original_value);
    }
  }, [open]);

  const update = (width: number) => {
    setWidth(Number(width));
    if (isNaN(Number(width)) === false && Number(width) >= 1) {
      setError("");
      let new_width: number = Number(width);
      if (selected_counter) {
        const counter: Counter | null = updateWidth(selected_counter, point_uuid, new_width);
        if (counter) {
          const new_corners: Corner[] = createCorners(counter.points, [...counter.corners], counter.uuid);
          dispatch(updateLocalCounter({ ...counter, corners: new_corners }));
        }
      }
    }
  }

  const cancel = () => {
    dispatch(restore(counter_history[history_position], cut_shape_history[history_position]));
    setOpen(false);
    setError("");
  }

  const save = () => {
    if (isNaN(Number(width)) || width < 1) {
      setError('Measurement must be larger than 1"');
      return;
    }
    if (selected_counter && width) {
      const new_counter: Counter = updateWidth(selected_counter, point_uuid, width);
      const new_corners: Corner[] = createCorners(new_counter.points, [...new_counter.corners], new_counter.uuid);
      const counter: Counter = { ...new_counter, corners: new_corners };
      const slab: Slab | null = getFirstSlabInAreas(areas, counter.area_uuid);
      handleUpdateCounter(
        dispatch,
        counter,
        counters,
        cut_shapes,
        slab.uuid,
        "Change Counter Width"
      );
    }
    else {
      setError("An error has occurred, please refresh the page and try again.");
    }
    setOpen(false);
    setError("");
  }

  return (
    <UpdateWidth
      open={open}
      width={width}
      error={error}
      setOpen={setOpen}
      update={update}
      save={save}
      cancel={cancel} />
  );
}
