/// TYPES ///
import { IShopQuote } from "api/types/shop_quote/quote";
import { Quote } from "api/types/quote";
import { Order } from "api/types/order";
import { SelfOrder } from "api/types/order";
import { QuoteTotals } from "api/types/quote";

export default function useQuoteTotals(quote: Quote | IShopQuote | Order | SelfOrder): QuoteTotals {
  function getSubtotal() {
    return quote?.total ?? 0;
  }

  function getDiscount() {
    return (quote?.total ?? 0) * (quote?.discount ?? 0) / 100;
  }

  function getTax1() {
    return (quote?.tax ?? 0) - (Math.round((quote?.tax ?? 0) * (quote?.discount ?? 0)) / 100);
  }


  function getTax2() {
    return (quote?.second_tax ?? 0) - (Math.round((quote?.second_tax ?? 0) * (quote?.discount ?? 0)) / 100)
  }

  return {
    subtotal: getSubtotal(),
    discount: getDiscount(),
    tax1: getTax1(),
    tax2: getTax2(),
  }
};
