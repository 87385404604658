/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
/// COMPONENTS ///
import DashboardErrors from "./account";
import DealerDashboard from "./dealer";
import MarketDashboard from "./fabricator/market";
import ShopDashboard from "./fabricator/shop";
import PageTitle from "components/universal/page/title";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { login_url } from "components/navigation/endpoints";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shop_view, user } = useAppSelector((state) => state.authentication);

  useEffect(() => {
    if (!user.id) {
      return navigate(login_url);
    }
  }, [user, navigate]);

  useEffect(() => {
    dispatch(setBreadcrumbs([]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start">
        <PageTitle title="Dashboard" endProps={<div></div>} />
        <DashboardErrors />
        {user.is_distributor ? <DealerDashboard /> : null}
        {user.is_fabricator && shop_view ? <ShopDashboard /> : null}
        {user.is_fabricator && !shop_view ? <MarketDashboard /> : null}
      </Grid>
    </motion.div>
  )
}
