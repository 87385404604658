import axios from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/fabricator";
import { ActionType } from "api/action_types/fabricator";
import { AxiosError } from "axios";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { url_prefix } from "settings";
import { FabricatorPriceList } from "api/types/fabricator";

const fabricator_price_list_url: string = "fabricator_price_list";

export const getFabricatorPriceLists = () => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATOR_PRICE_LIST_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${fabricator_price_list_url}/`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_FABRICATOR_PRICE_LISTS_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const getFabricatorPriceList = (id: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATOR_PRICE_LIST_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${fabricator_price_list_url}/${id}/`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_FABRICATOR_PRICE_LIST_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const updateFabricatorPriceList = (fabricator_price_list: FabricatorPriceList, id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATOR_PRICE_LIST_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${fabricator_price_list_url}/${id}/`;

    try {
      const { data } = await axios.patch(url, fabricator_price_list, config);

      dispatch({
        type: ActionType.UPDATE_FABRICATOR_PRICE_LIST_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATOR_PRICE_LIST_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const clearFabricatorPriceList = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_FABRICATOR_PRICE_LIST
    });
  }
}

export const clearFabricatorPriceLists = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_FABRICATOR_PRICE_LISTS
    });
  }
}
