import { GoogleMap } from "@react-google-maps/api";
import { CircleF } from "@react-google-maps/api";
import { useMemo } from "react";

interface MapsProps {
  lat: number;
  lng: number;
  isStatic: boolean;
  zoom?: number;
  radius?: number;
  isDrawable?: boolean;
}

export default function Map({
  lat,
  lng,
  isStatic,
  zoom = 15,
  radius = 300
}: MapsProps): JSX.Element | null {
  const places = [
    {
      id: 1,
      name: "Your Business",
      latitude: lat,
      longitude: lng,
      circle: {
        radius: radius,
        options: {
          strokeColor: "#ff0000",
          fillColor: "#ff0000"
        }
      }
    }
  ]

  const mapStyles = {
    height: "50vh",
    width: "100%"
  };

  const getMapOptions = useMemo(
    () => ({
      zoomControl: !isStatic,
      scrollwheel: !isStatic,
      rotateControl: !isStatic,
      clickableIcons: !isStatic,
      mapTypeControl: !isStatic,
      keyboardShortcuts: !isStatic,
      fullscreenControl: !isStatic,
      streetViewControl: !isStatic,
      disableDoubleClickZoom: isStatic,
      isFractionalZoomEnabled: !isStatic,
      gestureHandling: isStatic ? 'none' : 'auto',
    }),
    [isStatic]
  );

  return (
    <GoogleMap
      zoom={zoom}
      center={{ lat: lat, lng: lng }}
      options={getMapOptions}
      mapContainerStyle={mapStyles}>
      <CircleF
        center={{
          lat: places[0].latitude,
          lng: places[0].longitude
        }}
        radius={places[0].circle.radius * 1000}
        options={places[0].circle.options} />
    </GoogleMap>
  )
};
