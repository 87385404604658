/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
import { filterMarketOrders } from "api/actions/self_order/self_order/local/filter";
/// TYPES ///
import { IGetOrderArgs } from "api/types/order/order";
/// DATETIME ///
import { DateTime } from "luxon";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
// FM //
import { motion } from "framer-motion";
/// ICONS ///
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { order_url } from "components/navigation/endpoints";
import { store_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { order_list } from "values/breadcrumbs/order/shop";
import { order_filter } from "values/breadcrumbs/order/shop";

export default function StoreFilterOrders() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<IGetOrderArgs>({});

  useEffect(() => {
    dispatch(setBreadcrumbs([order_list, order_filter]));
  }, [dispatch]);


  const clear = () => {
    setFilter({});
    dispatch(filterMarketOrders({}));
  }

  const filterOrders = () => {
    dispatch(filterMarketOrders(filter));
    navigate(`/${store_url}/${order_url}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Filter Orders" endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(`/${store_url}/${order_url}`)} text="Cancel" icon={faTimes} />
            <BaseButton clickEvent={clear} text="Clear" icon={faEraser} />
            <BaseButton clickEvent={filterOrders} text="Apply" icon={faFilter} />
          </Box>
        } />
        <Grid item xs={12}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
            <TextField
              onChange={e => setFilter({ ...filter, order_name: e.target.value })}
              value={filter?.order_name ?? ""}
              label="Order Name"
              size="small" />
            <TextField
              onChange={e => setFilter({ ...filter, customer_name: e.target.value })}
              value={filter?.customer_name ?? ""}
              label="Customer Name"
              size="small" />
            <DatePicker
              slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
              value={DateTime.fromISO(filter.start_date)}
              label="Start Date"
              onChange={e => setFilter({ ...filter, start_date: e.toUTC().toISO() })} />
            <DatePicker
              slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true }, fullWidth: true } }}
              value={DateTime.fromISO(filter.end_date)}
              label="End Date"
              onChange={e => setFilter({ ...filter, end_date: e.toUTC().toISO() })} />
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}
