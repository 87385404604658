/// TYPES ///
import { ICustomer } from "api/types/customer";
/// COMPONENTS ///
import CustomerAutocomplete from "components/universal/autocomplete/customer";

interface Props {
  setCustomer: (customer: ICustomer) => void,
  error: boolean
}

export default function ExistingCustomer({ setCustomer, error }: Props) {
  return (
    <CustomerAutocomplete
      has_error={error}
      input_label="Search Customer"
      chooseCustomer={(customer: ICustomer) => setCustomer(customer)} />
  )
}
