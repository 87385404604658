/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { change_password_url } from "components/navigation/endpoints";
import { profile_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";

export const profile: IBreadcrumb = {
  id: 0,
  label: "Profile",
  url: `/${profile_url}`
}

export const update_profile: IBreadcrumb = {
  id: 1,
  label: "Update",
  url: `/${profile_url}/${update_url}`
}

export const change_password: IBreadcrumb = {
  id: 2,
  label: "Change Password",
  url: `/${profile_url}/${change_password_url}`
}
