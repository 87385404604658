/// TYPES ///
import { Counter } from "api/types/sketch";
import { DiagramProps } from "api/types/sketch";
import { OrderCounter } from "api/types/order";
import { SelfOrderCounter } from "api/types/order";
import { Vector2 } from "api/types/sketch";

export function getDiagramDimensions(counters: Counter[] | OrderCounter[] | SelfOrderCounter[]): Vector2 {
  let top: Vector2 | null = null;
  let bottom: Vector2 | null = null;
  let left: Vector2 | null = null;
  let right: Vector2 | null = null;

  for (let i = 0; i < counters.length; i++) {
    for (let j = 0; j < counters[i].corners.length; j++) {
      const corner: Vector2 = counters[i].corners[j].location;
      if (!top || corner.Y < top.Y) {
        top = corner;
      }
      if (!bottom || corner.Y > bottom.Y) {
        bottom = corner;
      }
      if (!left || corner.X < left.X) {
        left = corner;
      }
      if (!right || corner.X > right.X) {
        right = corner;
      }
    }
  }

  if (top && bottom && left && right) {
    return {
      X: right.X - left.X + 50,
      Y: bottom.Y - top.Y + 50
    }
  }
  else {
    return { X: 1, Y: 1 };
  }
}

export function getDiagramProps(counters: Counter[] | OrderCounter[] | SelfOrderCounter[], canvas_dimensions: Vector2): DiagramProps {
  let width: number = 0;
  let height: number = 0;
  let top: Vector2 | null = null;
  let bottom: Vector2 | null = null;
  let left: Vector2 | null = null;
  let right: Vector2 | null = null;

  for (let i = 0; i < counters.length; i++) {
    for (let j = 0; j < counters[i].corners.length; j++) {
      const corner: Vector2 = counters[i].corners[j].location;

      if (!top || corner.Y < top.Y) {
        top = corner;
      }
      if (!bottom || corner.Y > bottom.Y) {
        bottom = corner;
      }
      if (!left || corner.X < left.X) {
        left = corner;
      }
      if (!right || corner.X > right.X) {
        right = corner;
      }
    }
  }

  if (top && bottom && left && right) {
    width = right.X - left.X + 50;
    height = bottom.Y - top.Y + 50;
  }

  const drawing_center: Vector2 = { X: 1, Y: 1 };

  if (right && left && top && right) {
    drawing_center.X = (right.X + left.X) / 2;
    drawing_center.Y = (bottom.Y + top.Y) / 2;
  }

  const width_ratio: number = canvas_dimensions.X / width;
  const height_ratio: number = canvas_dimensions.Y / height;

  let zoom: number = 1;

  if (width_ratio < height_ratio) {
    zoom = width_ratio;
  }
  else {
    zoom = height_ratio;
  }

  if (zoom > 1.5) {
    zoom = 1.5;
  }

  return {
    width: width,
    height: height,
    zoom: zoom,
    offset: {
      X: Math.round(canvas_dimensions.X / 2) - drawing_center.X * zoom,
      Y: Math.round(canvas_dimensions.Y / 2) - drawing_center.Y * zoom
    }
  }
}
