/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
import { useState } from "react";
/// MUI COMPONENTS ///
import Drawer from "@mui/material/Drawer";
/// CUSTOM COMPONENTS ///
import Logout from "components/authentication/logout";
import MiniDealerDrawer from "./dealer";
import MiniLogout from "components/authentication/mini_logout";
import MiniMarketDrawer from "./fabricator/market";
import MiniShopDrawer from "./fabricator/shop";
/// STYLES ///
import { mini_draw_style } from "styles/drawer";

export default function MiniDrawer() {
  const { shop_view, user } = useAppSelector((state) => state.authentication);
  const [logout_open, setLogoutOpen] = useState<boolean>(false);

  return (
    <Drawer variant="permanent" sx={mini_draw_style}>
      {user.is_distributor ? <MiniDealerDrawer /> : null}
      {user.is_fabricator && shop_view ? <MiniShopDrawer /> : null}
      {user.is_fabricator && !shop_view ? <MiniMarketDrawer /> : null}
      <MiniLogout setOpen={setLogoutOpen} />
      <Logout open={logout_open} setOpen={setLogoutOpen} />
    </Drawer>
  );
}
