import { CornerData } from "api/types/sketch";
import { CornerClip } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { constructClipCorner } from "../../corner/construct";

export function drawPrintCutClippedCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    zoom: number
): CornerData {
    const angle: number = getAngle(previous_location, current_location) + 90;
    const next_angle: number = getAngle(current_location, next_location) + 90;
    const corner_clip: CornerClip = constructClipCorner(
        angle,
        current_location,
        next_angle,
        corner_length * zoom
    );

    let first_point: Vector2 | null = null;

    if(i === 0){
        first_point = corner_clip.start_point;
        painter.moveTo(corner_clip.start_point.X, corner_clip.start_point.Y)
    }
    else{
        painter.lineTo(corner_clip.start_point.X, corner_clip.start_point.Y);
    }

    painter.lineTo(corner_clip.end_point.X, corner_clip.end_point.Y);
    
    return {
        first_point: first_point,
        last_point: corner_clip.end_point
    }
}