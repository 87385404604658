/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/end_customer";
import { Action as AuthAction } from "api/action_types/authentication";
import { Action as QuoteAction } from "api/action_types/quote";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/end_customer";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { ActionType as QuoteActionType } from "api/action_types/quote";
/// TYPES ///
import { IFabricationJob } from "api/types/customer";
/// URLS ///
import { url_prefix } from "settings";
import { fabrication_job_url } from "../../endpoint";

export const updateFabricationJob = (job: IFabricationJob, id: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction | QuoteAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.FABRICATION_JOB_UPDATING
    });


    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${fabrication_job_url}/${id}/?`;

    try {
      const { data } = await axios.patch(url, job, config);

      dispatch({
        type: ActionType.CREATE_FABRICATION_JOB_SUCCESS,
        fabrication_job: data
      });

      dispatch({
        type: QuoteActionType.SET_FABRICATION_JOB_ON_QUOTE,
        job: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.FABRICATION_JOB_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
