import { combineReducers } from "redux";
import { authenticationReducer } from "./authentication";
import { billingReducer } from "./billing";
import { dealerMaterialReducer } from "./dealer_material";
import { endCustomerReducer } from "./end_customer";
import { eventReducer } from "./event";
import { fabricatorReducer } from "./fabricator";
import { historyReducer } from "./history";
import { marketMaterialReducer } from "./market_material";
import { marketReducer } from "./market";
import { orderReducer } from "./order";
import { quoteReducer } from "./quote";
import { selfOrderReducer } from "./self_order";
import { shopMaterialReducer } from "./shop_material";
import { shopQuoteReducer } from "./shop_quote";
import { signatureReducer } from "./signature";
import { sketchReducer } from "./sketch";
import { uiReducer } from "./ui";

const reducers = combineReducers({
  authentication: authenticationReducer,
  billing: billingReducer,
  dealer_material: dealerMaterialReducer,
  end_customer: endCustomerReducer,
  event: eventReducer,
  fabricator: fabricatorReducer,
  history: historyReducer,
  market_material: marketMaterialReducer,
  market: marketReducer,
  order: orderReducer,
  quote: quoteReducer,
  shop_material: shopMaterialReducer,
  shop_quote: shopQuoteReducer,
  self_order: selfOrderReducer,
  signature: signatureReducer,
  sketch: sketchReducer,
  ui: uiReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
