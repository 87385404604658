
/// HOOKS ///
import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
/// COMPONENTS ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Fragment } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";
/// VALIDATE ///
import { validateEmail } from "functions/value";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  default_email: string,
  name: string,
  customer: number | undefined | null,
  quote_id?: number,
  order_id?: number,
  self_order_id?: number,
  exists?: boolean
}

export default function EmailSignatureRequest({
  open,
  setOpen,
  default_email,
  name,
  customer,
  quote_id,
  order_id,
  self_order_id,
  exists
}: Props) {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  const sendQuote = () => {
    let send_email: string = "";

    if (email) {
      if (validateEmail(email)) {
        send_email = email;
      }
      else {
        setError(true);
      }
    }
    else {
      if (validateEmail(default_email)) {
        send_email = default_email;
      }
      else {
        setError(false);
      }
    }

    if (quote_id || order_id || self_order_id) {
    }
    setOpen(false);
  }
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Request Signature</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Stack sx={{ paddingTop: "10px" }}>
          {
            exists ?
              <Typography variant="body2" color="error">
                Signature has already been received, are you sure you want to send again?
              </Typography> :
              null
          }
          {
            !customer ?
              <Typography variant="body2" color="error">
                Must enter a customer for this quote before sending an email
              </Typography> :
              <Fragment>
                <TextField
                  error={error}
                  helperText={error ? "Please enter a valid email" : ""}
                  variant="outlined"
                  label="Email"
                  defaultValue={default_email}
                  onChange={(e) => setEmail(e.target.value)} />
              </Fragment>
          }
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} size="small">Cancel</Button>
        <Button onClick={sendQuote} disabled={!customer} size="small">Send</Button>
      </DialogActions>
    </Dialog>
  )
}
