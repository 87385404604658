/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getShopAreas } from "api/actions/shop_quote";
import { getShopCounters } from "api/actions/shop_quote";
import { getShopLabels } from "api/actions/shop_quote";
import { getShopQuote } from "api/actions/shop_quote";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
/// COMPONENTS ///
import LoadingPage from "components/universal/loading_page";
import QuotePrintPreviewBody from "./print_preview";
/// PDF ///
import { PDFViewer } from "@react-pdf/renderer";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";

export default function ShopQuotePrintPreview() {
  const dispatch = useAppDispatch();
  const [logo, setLogo] = useState<string | null>(null);
  const [logo_read, setLogoRead] = useState<boolean>(false);
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.authentication);
  const { areas } = useAppSelector(state => state.shop_quote);
  const { areas_loaded } = useAppSelector(state => state.shop_quote);
  const { counters } = useAppSelector(state => state.shop_quote);
  const { counters_loaded } = useAppSelector((state) => state.shop_quote);
  const { labels } = useAppSelector((state) => state.shop_quote);
  const { quote } = useAppSelector((state) => state.shop_quote);

  useEffect(() => {
    if (id) {
      dispatch(getShopQuote(id));
      dispatch(getShopCounters(id));
      dispatch(getShopAreas(id));
      dispatch(getShopLabels(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (user?.logo && typeof (user?.logo) === "string") {
      fetch(user.logo).then(response => {
        return response.blob();
      }).then(blob => {
        const blob_url: string = URL.createObjectURL(blob);
        setLogo(blob_url);
        setLogoRead(true);
      }).catch(() => {
        setLogoRead(true);
      })
    }
    else {
      setLogoRead(true);
    }
  }, [user]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={!quote?.id || !user?.id || !logo_read || !counters_loaded || !areas_loaded}>
        <Box display="flex" justifyContent="center">
          <PDFViewer width="795px" height="1123px">
            <QuotePrintPreviewBody areas={areas} counters={counters} user={user} quote={quote} labels={labels} logo={logo} />
          </PDFViewer>
        </Box>
      </LoadingPage>
    </motion.div>
  );
}
