/// REACT ROUTER ///
import { useNavigate } from "react-router-dom";
/// TYPE ///
import { ISelfOrderEvent } from "api/types/event";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import { Typography } from "@mui/material";
/// DATE ///
import { DateTime } from "luxon";
/// URLS ///
import { event_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";

interface Props {
  event: ISelfOrderEvent
}

export default function WeekSelfOrderEvent({ event }: Props) {
  const navigate = useNavigate();

  return (
    <motion.button
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.03 }}
      style={{
        backgroundColor: `${event?.color ?? "blue"}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px",
        paddingRight: "15px",
        marginRight: "5px",
        marginLeft: "5px",
        cursor: "pointer",
        border: "none"
      }}
      onClick={() => navigate(`/${event_url}/${shop_url}/${event?.self_order}`)}>
      <div style={{ flexDirection: "column", display: "flex" }}>
        <Typography variant="body1" sx={{ color: "#fff" }}>
          {event?.title ?? ""}
        </Typography>
        <Typography variant="body2" sx={{ marginTop: "25px", color: "#fff" }}>
          {DateTime.fromISO(event?.time).toLocal().toFormat("EEE hh:mm")}
        </Typography>
      </div>
    </motion.button>
  )
}
