/// TYPES ///
import { Area } from "api/types/quote";
import { IShopArea } from "api/types/shop_quote/area";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// UNI COMPONENTS ///
import List from "components/universal/list";
import QuoteSummaryItem from "../item";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";

interface Props {
  area: Area | IShopArea
}

export default function QuoteSummaryArea({ area }: Props) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography color="primary" variant="body2" sx={item_style()}>
          {area.name ?? ""}
        </Typography>
      </Grid>
      <List items={area.line_items ?? []} Item={QuoteSummaryItem} />
    </Fragment>
  )
}
