/// REACT ///
import { Fragment } from "react";
import { KeyboardEvent } from "react";
import { MouseEvent } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
/// COMPONENTS ///
import FinishedEdgeImage from "components/universal/finished_edge_image";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// VALUES ///
import { FINISHED } from "values/values";
import { UNFINISHED } from "values/values";
import { APPLIANCE } from "values/values";
import { SPLASH } from "values/values";
import { WATERFALL } from "values/values";
import { EASED } from "values/values";
import { BEVEL } from "values/values";
import { SPECIALTY } from "values/values";
import { MITER } from "values/values";
import { BULLNOSE } from "values/values";

interface Props {
  open: boolean,
  edge_type: string,
  setEdgeType: (edge_type: string) => void,
  finished_edge_type: string,
  setFinishedEdgeType: (finished_edge_type: string) => void,
  height_error: string,
  height_has_error: boolean,
  splash_height: number,
  setSplashHeight: (splash_height: number) => void,
  save: () => void,
  cancel: () => void
}

export default function UpdateEdge({
  open,
  edge_type,
  setEdgeType,
  finished_edge_type,
  setFinishedEdgeType,
  splash_height,
  setSplashHeight,
  height_error,
  height_has_error,
  save,
  cancel
}: Props) {

  const toggleDrawer = () => (event: KeyboardEvent | MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    cancel();
  };

  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      save();
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      cancel();
    }
  };

  return (
    <SwipeableDrawer
      BackdropProps={{ invisible: true }}
      disableAutoFocus
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      onKeyDown={keyDown}
      anchor="right"
      open={open}>
      <Box display="flex" flexDirection="column" justifyContent="center" sx={{ paddingTop: "25px", paddingLeft: "50px", paddingRight: "50px", width: "300px", gap: "10px" }}>
        <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
          <IconButton onClick={cancel}>
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Box>
        <Typography variant="h4" align="center" sx={{ marginTop: "50px" }}>
          Edge
        </Typography>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <RadioGroup
            sx={{ paddingLeft: 4, paddingRight: 4 }}
            aria-labelledby="edge-type-selection"
            value={edge_type ?? FINISHED}
            name="edge-type-selection"
            onChange={(e) => setEdgeType(e.target.value)}>
            <FormControlLabel value={FINISHED} control={<Radio />} label="Finished" />
            <FormControlLabel value={APPLIANCE} control={<Radio />} label="Appliance" />
            <FormControlLabel value={SPLASH} control={<Radio />} label="Splash" />
            <FormControlLabel value={MITER} control={<Radio />} label="Miter" />
            <FormControlLabel value={WATERFALL} control={<Radio />} label="Waterfall" />
            <FormControlLabel value={UNFINISHED} control={<Radio />} label="Unfinished" />
          </RadioGroup>
        </FormControl>
        {
          edge_type === FINISHED ?
            <Fragment>
              <TextField
                label="Edge Type"
                value={finished_edge_type ?? EASED}
                onChange={e => setFinishedEdgeType(e.target.value)}
                select>
                <MenuItem key={EASED} value={EASED}>
                  Eased
                </MenuItem>
                <MenuItem key={BEVEL} value={BEVEL}>
                  Bevel
                </MenuItem>
                <MenuItem key={BULLNOSE} value={BULLNOSE}>
                  Bullnose
                </MenuItem>
                <MenuItem key={SPECIALTY} value={SPECIALTY}>
                  Specialty
                </MenuItem>
              </TextField>
              <Box display="flex" justifyContent="center" sx={{ paddingTop: 1 }}>
                <FinishedEdgeImage
                  type={finished_edge_type ?? EASED}
                  width={130}
                  height={65} />
              </Box>
            </Fragment> :
            null
        }
        {
          edge_type === SPLASH || edge_type === MITER || edge_type === WATERFALL ?
            <TextField
              sx={{ marginTop: "50px" }}
              type="number"
              error={height_has_error}
              helperText={height_error}
              label="Height"
              value={splash_height > 0 ? splash_height : ""}
              onChange={e => setSplashHeight(Number(e.target.value))} />
            : null
        }
        <Button
          disabled={(edge_type === SPLASH || edge_type === MITER) && !(splash_height > 0)}
          onClick={save}>
          Save
        </Button>
      </Box>
    </SwipeableDrawer>
  );
}

