export const inch_to_pixel: number = 4;
export const SLAB_ZOOM: number = 0.6;

export const NONE: string = "NO";
export const DOUBLE: string = "DO";

export const CIRCLE: string = "CI";
export const RECTANGLE: string = "RE";
export const OVAL: string = "OV";
export const SIXTY_OVER_FORTY: string = "64";
export const FORTY_OVER_SIXTY: string = "46";
export const SEVENTY_OVER_THIRTY: string = "73";
export const THIRTY_OVER_SEVENTY: string = "37";

export const UNDERMOUNT: string = "UN";
export const DROP_IN: string = "DI";
export const FARMER: string = "FA";

export const FINISHED: string = "F";
export const UNFINISHED: string = "U";
export const SPLASH: string = "S";
export const MITER: string = "M";
export const WATERFALL: string = "W";
export const APPLIANCE: string = "A";

export const NO_BUMP: string = "NO";
export const BUMP_OUT: string = "BO";
export const BUMP_IN: string = "BI";
export const BUMP_OUT_ARC: string = "OA";
export const BUMP_IN_ARC: string = "IN";
export const FULL_ARC: string = "FA";

export const LEFT: string = "L";
export const CENTER: string = "C";
export const RIGHT: string = "R";

export const BEVEL: string = "BE";
export const BULLNOSE: string = "BN";
export const EASED: string = "EA";
export const SPECIALTY: string = "SP";

export function getSinkTypes(sink_type: string | undefined) {
  if (sink_type === undefined || sink_type === null) {
    return "Unknown";
  }

  switch (sink_type) {
    case UNDERMOUNT:
      return "Undermount";
    case DROP_IN:
      return "Drop-in";
    case FARMER:
      return "Farmer";
    default:
      return "Unknown";
  }
}

export function getCutoutShapes(cutout_shape: string | undefined) {
  if (cutout_shape === undefined || cutout_shape === null) {
    return "Unknown";
  }

  switch (cutout_shape) {
    case CIRCLE:
      return "Circle";
    case RECTANGLE:
      return "Rectangle";
    case OVAL:
      return "Oval";
    case DOUBLE:
      return "Double";
    case SIXTY_OVER_FORTY:
      return "60/40";
    case FORTY_OVER_SIXTY:
      return "40/60";
    case SEVENTY_OVER_THIRTY:
      return "70/30";
    case THIRTY_OVER_SEVENTY:
      return "30/70";
    default:
      return "Unknown";
  }
}

export function getBump(bump: string | undefined) {
  if (!bump) {
    return "Unknown";
  }

  switch (bump) {
    case NO_BUMP:
      return "No Bump";
    case BUMP_OUT:
      return "Bump Out";
    case BUMP_IN:
      return "Bump In";
    case BUMP_OUT_ARC:
      return "Bump Out Arc";
    case BUMP_IN_ARC:
      return "Bump In Arc";
    case FULL_ARC:
      return "Full Arc";
    default:
      return "Unknown";
  }
}
