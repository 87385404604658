/// TYPES ///
import { Counter } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { Point } from 'api/types/sketch';
/// FUNCTIONS ///
import { getDistance } from "../math";
import { getDistantPoint } from "../math";

export function updateHeight(
  counter: Counter,
  new_height: number,
  original_height: number,
  point_uuid: string
): Counter {
  let new_points: Point[] = [...counter.points];
  let update_rest: boolean = false;
  let update_next: boolean = false;

  for (let j = 0; j < counter.points.length; j++) {
    if (update_rest) {
      const prev_point: Point = counter.points[j - 1];
      const current_point: Point = counter.points[j];
      const distance: number = getDistance(prev_point.location, current_point.location);
      const new_location: Vector2 = getDistantPoint(new_points[j - 1].location, counter.points[j - 1].angle, distance);
      const new_point: Point = { ...current_point, location: new_location };
      new_points[j] = new_point;
      continue;
    }

    if (update_next) {
      const prev_point: Point = counter.points[j - 1];
      const current_point: Point = counter.points[j];
      const added_distance: number = new_height * 4 - original_height * 4;
      const new_location: Vector2 = getDistantPoint(current_point.location, prev_point.angle, added_distance);
      new_points[j] = { ...current_point, location: new_location };
      update_rest = true;
      continue;
    }

    if (point_uuid === counter.points[j].uuid) {
      update_next = true;
    }

  }
  return { ...counter, points: new_points };
}
