import { Vector2 } from "api/types/sketch";
import { BumpArcConstruct } from "api/types/sketch";
import { constructFullArc } from "../construct";
import { AnyAction } from "@reduxjs/toolkit";

export function drawPrintCutFullWidth(
    painter: AnyAction,
    start: Vector2, 
    end: Vector2, 
    depth: number, 
    angle: number,
    zoom: number
){
    const bump_construct: BumpArcConstruct = constructFullArc(start, end, angle, depth, zoom);
    painter.lineTo(bump_construct.start.X, bump_construct.start.Y);
    painter.quadraticCurveTo(bump_construct.top.X, bump_construct.top.Y, bump_construct.end.X, bump_construct.end.Y);
}