import { Corner } from "api/types/sketch";
import { EASED } from "values/values";

export const empty_corner: Corner = {
  uuid: "",
  counter_uuid: "",
  point_uuid: "",
  location: { X: 0, Y: 0 },
  edge_type: "S",
  splash_height: 4,
  finished_edge_type: EASED,
  corner_type: "S",
  corner_length: 0,
  corner_depth: 0,
  corner_radius: 0,
  bumps: [],
  position: 0
}
