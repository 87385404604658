/// TYPES ///
import { Counter } from "api/types/sketch";
import { Sink } from "api/types/sketch";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopSink } from "api/types/shop_quote/sink";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { adjustVectorToScale } from "../draw/scale";
import { isPointInRectangle } from "../math";

export function checkSinkClicked(counters: Counter[], mouse: Vector2, zoom: number): Sink | null {
  for (let i = 0; i < counters.length; i++) {
    for (let j = 0; j < counters[i].sinks.length; j++) {
      const zoom_location: Vector2 = adjustVectorToScale(counters[i].sinks[j].location, zoom);
      if (isPointInRectangle(
        zoom_location,
        counters[i].sinks[j].angle,
        counters[i].sinks[j].width * 4,
        counters[i].sinks[j].length * 4,
        mouse)) {
        return counters[i].sinks[j];
      }
    }
  }
  return null;
}

export function checkShopSinkClicked(counters: IShopCounter[], mouse: Vector2, zoom: number): IShopSink | null {
  for (let i = 0; i < counters.length; i++) {
    for (let j = 0; j < counters[i].sinks.length; j++) {
      const zoom_location: Vector2 = adjustVectorToScale(counters[i].sinks[j].location, zoom);
      if (isPointInRectangle(
        zoom_location,
        counters[i].sinks[j].angle,
        counters[i].sinks[j].width * 4,
        counters[i].sinks[j].length * 4,
        mouse)) {
        return counters[i].sinks[j];
      }
    }
  }
  return null;
}
