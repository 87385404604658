/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/end_customer";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/end_customer";
/// TYPES ///
import { FilterEndCustomer } from "api/types/customer";

export const filterCustomers = (filter: FilterEndCustomer) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CUSTOMER_FILTER,
      filter
    });
  }
}
