import { DateTime } from "luxon";

export function getExpiryDate(date: string | undefined): string {
  if (date) {
    const date_value = DateTime.fromISO(date).toLocal();
    const new_date = date_value.plus({ months: 1 });
    return new_date.toLocaleString(DateTime.DATE_MED);
  } else {
    return "";
  }
}

export function getDate(date: string | undefined): string {
  if (date === null || date === undefined || date === "") {
    return "";
  }
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
}

export function getDateOrTime(date: string | undefined): string {
  if (!date) {
    return "";
  }

  const server_date = DateTime.fromISO(date);
  const current_date = DateTime.now();

  if (server_date.day === current_date.day && server_date.month === current_date.month && server_date.year === current_date.year) {
    return server_date.toLocaleString(DateTime.TIME_SIMPLE);
  }
  return server_date.toLocaleString(DateTime.DATE_MED);
}
