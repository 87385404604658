/// TYPES ///
import { Counter } from "api/types/sketch";
import { Cooktop } from "api/types/sketch";
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopCooktop } from "api/types/shop_quote/cooktop";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { adjustVectorToScale } from "../draw/scale";
import { isPointInRectangle } from "../math";

export function checkCooktopClicked(counters: Counter[], mouse: Vector2, zoom: number): Cooktop | null {
  for (let i = 0; i < counters.length; i++) {
    for (let j = 0; j < counters[i].cooktops.length; j++) {
      const zoom_location: Vector2 = adjustVectorToScale(counters[i].cooktops[j].location, zoom);
      if (isPointInRectangle(
        zoom_location,
        counters[i].cooktops[j].angle,
        counters[i].cooktops[j].width * 4,
        counters[i].cooktops[j].length * 4,
        mouse)) {
        return counters[i].cooktops[j];
      }
    }
  }
  return null;
}

export function checkShopCooktopClicked(counters: IShopCounter[], mouse: Vector2, zoom: number): IShopCooktop | null {
  for (let i = 0; i < counters.length; i++) {
    for (let j = 0; j < counters[i].cooktops.length; j++) {
      const zoom_location: Vector2 = adjustVectorToScale(counters[i].cooktops[j].location, zoom);
      if (isPointInRectangle(
        zoom_location,
        counters[i].cooktops[j].angle,
        counters[i].cooktops[j].width * 4,
        counters[i].cooktops[j].length * 4,
        mouse)) {
        return counters[i].cooktops[j];
      }
    }
  }
  return null;
}
