/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useState } from "react";
/// UUID ///
import { v4 } from "uuid";
/// ACTIONS ///
import { createCooktop } from "api/actions/sketch/cooktop";
import { markQuoteChanged } from "api/actions/quotes/quote";
import { createRestorePoint } from "api/actions/history";
/// TYPES ///
import { Counter } from "api/types/sketch";
import { Cooktop } from "api/types/sketch";
import { CutoutError } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
/// COMPONENTS ///
import SaveCooktop from "components/quote/drawer/cooktop";
/// FUNCTIONS ///
import { getCounterByUuid } from "functions/sketch/get/counter";
/// EMPTY ///
import { empty_cooktop } from "values/empty/quote/cooktop";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  selected_counter: Counter,
  mouse: Vector2
}

export default function CreateStoreCooktop({ open, setOpen, selected_counter, mouse }: Props) {
  const dispatch = useAppDispatch();
  const { counters } = useAppSelector((state) => state.shop_quote);
  const { cut_shapes } = useAppSelector((state) => state.shop_quote);
  const [cooktop, setCooktop] = useState<Cooktop>({ ...empty_cooktop });

  const [error, setError] = useState<CutoutError>({});

  const close = () => {
    setOpen(false);
    setCooktop({ ...empty_cooktop });
    setError({});
  }

  const save = () => {
    let error: CutoutError = {};
    if (!cooktop?.length) {
      error.length_error = "Length must be greater than 0";
      error.length_has_error = true;
    }

    if (!cooktop?.width) {
      error.width_error = "Width must be greater than 0";
      error.width_has_error = true;
    }

    if (error.width_error || error.length_error) {
      setError(error);
      return;
    }

    if (selected_counter) {
      dispatch(createCooktop({
        ...cooktop,
        uuid: v4(),
        counter_uuid: selected_counter?.uuid,
        location: {
          X: Math.round(mouse.X),
          Y: Math.round(mouse.Y)
        },
        x: Math.round(mouse.X),
        y: Math.round(mouse.Y)
      }));
      dispatch(markQuoteChanged());

      const new_counter: Counter = getCounterByUuid(cooktop.counter_uuid, counters);

      if (new_counter) {
        dispatch(createRestorePoint(
          [
            ...counters.filter(c => c.uuid !== new_counter.uuid),
            { ...new_counter, cooktops: [...new_counter.cooktops.filter(c => c.uuid !== cooktop.uuid), cooktop] }
          ],
          cut_shapes,
          "Change Sink"
        ));
      }
      close();
    }
  }


  return (
    <SaveCooktop
      open={open}
      setOpen={setOpen}
      num_burners={cooktop?.num_burners ?? 0}
      setNumBurners={(num_burners => setCooktop({ ...cooktop, num_burners }))}
      length={cooktop?.length ?? 30}
      setLength={length => setCooktop({ ...cooktop, length })}
      length_error={error?.length_error ?? ""}
      length_has_error={error?.length_has_error ?? false}
      width={cooktop?.width ?? 26}
      setWidth={width => setCooktop({ ...cooktop, width })}
      width_error={error?.width_error ?? ""}
      width_has_error={error?.width_has_error ?? false}
      save={save}
      cancel={close}
      title="Create Cooktop Cutout" />
  );
}

