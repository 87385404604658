/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/event";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/event";
/// TYPES ///
import { IEventFilter } from "api/types/event";

export const setEventFilter = (filter: IEventFilter) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_EVENT_FILTER,
      filter
    })
  }
}
