import { OrderCutShape } from "api/types/order/order";
import { SelfOrderCutShape } from "api/types/order/self_order";
import { OrderCutCorner } from "api/types/order/order";
import { SelfOrderCutCorner } from "api/types/order/self_order";
import { Vector2 } from "api/types/sketch";
import { CornerData } from "api/types/sketch";
import { drawCutCorner } from "../cut_corner";
import { drawOrderCutBumps } from "../bumps";
import { adjustVectorToScale } from "../scale";
import { drawFirstHalfCutCorner } from "../cut_corner";
import { drawSecondHalfCutCorner } from "../cut_corner";
import { getAngle } from "../../math";

function drawPOCutShape(
    path: Path2D,
    cut_shape: OrderCutShape | SelfOrderCutShape,
    zoom: number,
    base_offset: Vector2,
    offset: Vector2
) {
    let first_location: Vector2 = {X: 0, Y: 0};
    for(let i = 0; i < cut_shape.cut_corners.length; i++){
        const previous_corner: OrderCutCorner | SelfOrderCutCorner = 
            cut_shape.cut_corners[i-1] ? 
            cut_shape.cut_corners[i-1] : 
            cut_shape.cut_corners[cut_shape.cut_corners.length - 1];
        const corner: OrderCutCorner | SelfOrderCutCorner = cut_shape.cut_corners[i];
        const next_corner: OrderCutCorner | SelfOrderCutCorner = 
            cut_shape.cut_corners[i+1] ? cut_shape.cut_corners[i+1] : cut_shape.cut_corners[0];
        const zoom_adjusted_previous_location: Vector2 =
            adjustVectorToScale(
                {
                    X: previous_corner.location.X - offset.X,
                    Y: previous_corner.location.Y - offset.Y
                },
                zoom
            );
        const zoom_adjusted_location: Vector2 =
            adjustVectorToScale(
                {
                    X: corner.location.X - offset.X,
                    Y: corner.location.Y - offset.Y
                },
                zoom
            );
        const zoom_adjusted_next_location: Vector2 =
            adjustVectorToScale(
                {
                    X: next_corner.location.X - offset.X,
                    Y: next_corner.location.Y - offset.Y
                },
                zoom
            );
        const previous_location: Vector2 = {
            X: zoom_adjusted_previous_location.X + base_offset.X,
            Y: zoom_adjusted_previous_location.Y + base_offset.Y
        };
        const location: Vector2 = {
            X: zoom_adjusted_location.X + base_offset.X,
            Y: zoom_adjusted_location.Y + base_offset.Y
        };
        const next_location: Vector2 = {
            X: zoom_adjusted_next_location.X + base_offset.X,
            Y: zoom_adjusted_next_location.Y + base_offset.Y
        };

        let corner_data: CornerData | null = null;
        if(!corner.is_corner_seam){
            corner_data = drawCutCorner(
                i,
                path,
                corner.corner_type,
                previous_location,
                location,
                next_location,
                1,
                corner.corner_radius,
                corner.corner_length,
                corner.corner_depth
            );
        }
        else{
            if(corner.corner_before_seam){
                corner_data = drawFirstHalfCutCorner(
                    i,
                    path,
                    corner.corner_type,
                    previous_location,
                    location,
                    next_location,
                    1,
                    corner.corner_radius,
                    corner.corner_length,
                    corner.corner_depth
                );
            }
            else{
                corner_data = drawSecondHalfCutCorner(
                    i,
                    path,
                    corner.corner_type,
                    previous_location,
                    location,
                    next_location,
                    1,
                    corner.corner_radius,
                    corner.corner_length,
                    corner.corner_depth
                )
            }
        }

        if(i === 0){
            if(corner_data && corner_data.first_point){
                first_location = corner_data.first_point;
            }
            else{
                first_location=location;
            }
        }

        drawOrderCutBumps(path, corner.cut_bumps, location, next_location, getAngle(location, next_location), zoom);
    }

    path.lineTo(first_location.X, first_location.Y);
}

export function drawPOCutShapes(
    cut_shapes: OrderCutShape[] | SelfOrderCutShape[],
    zoom: number,
    base_offset: Vector2,
    offset: Vector2,
): Path2D {
    const path = new Path2D();
    for(let i = 0; i < cut_shapes.length; i++){
        drawPOCutShape(path, cut_shapes[i], zoom, base_offset, offset);
    }
    return path;
}
