/// TYPES ///
import { Area } from "api/types/quote";
import { FabricatorLineItem } from "api/types/market";
import { FabricatorPrice } from "api/types/market";
import { QuoteLineItem } from "api/types/quote";
/// PDF ///
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
/// STYLES ///
import { subtitle } from "styles/print";
/// COLOR ///
import { light_grey_color } from "styles/style";

interface Props {
  areas: Area[] | FabricatorPrice[],
}

export default function PrintLineItems({ areas }: Props) {
  const styles = StyleSheet.create({
    text: {
      fontFamily: "Montserrat",
      fontSize: 10.5,
      paddingRight: "5px"
    },
    line_container: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "10px",
      paddingRight: "10px",
      borderBottom: `1px solid ${light_grey_color}`
    }
  });

  return (
    <View style={{ width: "100%" }}>
      {
        areas.map((area: Area | FabricatorPrice) => {
          return (
            <View style={{ width: "100%" }} key={area.id}>
              <View style={styles.line_container}>
                <Text style={subtitle}>
                  {area?.name ?? ""}
                </Text>
              </View>
              {
                area?.line_items?.map((line_item: QuoteLineItem | FabricatorLineItem) => {
                  return (
                    <View key={line_item.id} style={styles.line_container}>
                      <View style={{ width: "50%" }}>
                        <Text style={styles.text}>
                          {line_item.name}
                        </Text>
                      </View>
                      <View style={{ width: "50%" }}>
                        <Text style={styles.text}>
                          {line_item.description}
                        </Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>
          )
        })
      }
    </View>
  );
}
