/// TYPES ///
import { IBreadcrumb } from "api/types/ui";
/// URLS ///
import { assign_url } from "components/navigation/endpoints";
import { create_url } from "components/navigation/endpoints";
import { detail_url } from "components/navigation/endpoints";
import { end_customer_url } from "components/navigation/endpoints";
import { fabrication_job_url } from "api/actions/end_customer/endpoint";
import { filter_url } from "components/navigation/endpoints";
import { preview_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";
import { market_url } from "components/navigation/endpoints";

export const quote_list: IBreadcrumb = {
  id: 0,
  label: "Quotes",
  url: `/base_url/${quote_url}`
}

export const quote_filter: IBreadcrumb = {
  id: 1,
  label: "Filter",
  url: `/base_url/${quote_url}/${filter_url}`
}

export const quote_create: IBreadcrumb = {
  id: 2,
  label: "Create",
  url: `/base_url/${quote_url}/${create_url}`
}

export const quote_preview: IBreadcrumb = {
  id: 3,
  label: "Quote",
  url: `/base_url/${quote_url}/${detail_url}/id/${preview_url}`
}

export const quote_assign: IBreadcrumb = {
  id: 4,
  label: "Assign Customer",
  url: `/base_url/${quote_url}/${detail_url}/id/${assign_url}`
}

export const quote_update: IBreadcrumb = {
  id: 5,
  label: "Update",
  url: `/base_url/${quote_url}/${detail_url}/id/${update_url}`
}

export const quote_draw: IBreadcrumb = {
  id: 6,
  label: "Draw",
  url: `/base_url/${quote_url}/${detail_url}/id`
}

export const quote_update_cx: IBreadcrumb = {
  id: 7,
  label: "Update Customer",
  url: `/base_url/${quote_url}/${detail_url}/id/${end_customer_url}/customer_id/${update_url}`
}

export const quote_update_job: IBreadcrumb = {
  id: 8,
  label: "Update Job Site",
  url: `/base_url/${quote_url}/${detail_url}/id/${fabrication_job_url}`
}

export const market_quotes: IBreadcrumb = {
  id: 9,
  label: "Market",
  url: `/${market_url}/id`
}

export const market_quote: IBreadcrumb = {
  id: 10,
  label: "Market Quote",
  url: `/${market_url}/id/${detail_url}/market_id`
}
