import { Dispatch } from "redux";
import { Action } from "api/action_types/shop_material";
import { ActionType } from "api/action_types/shop_material";

export const clearShopMaterials = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_SHOP_MATERIALS
    });
  }
}
