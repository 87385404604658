/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/sketch";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/sketch";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// TYPES ///
import { Label } from "api/types/sketch";
/// URLS ///
import { url_prefix } from "settings";
import { label_url } from "../endpoint";

export const createLabel = (label: Label) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        const { user } = getState().authentication;

        dispatch({
            type: ActionType.SKETCH_UPDATING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${label_url}/`;

        try
        {
            const { data } = await axios.post(url, label, config);

            dispatch({
                type: ActionType.CREATE_LABEL,
                label: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.SKETCH_ERROR,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}