import { IShopMaterial } from "api/types/shop_material";
import { IShopColor } from "api/types/shop_material";

export enum ActionType {
  GET_SHOP_MATERIALS = "GET_SHOP_MATERIALS",
  GET_SHOP_MATERIAL = "GET_SHOP_MATERIAL",
  CREATE_SHOP_MATERIAL_COLOR = "CREATE_SHOP_MATERIAL_COLOR",
  CREATE_SHOP_MATERIAL = "CREATE_SHOP_MATERIAL",
  UPDATE_SHOP_MATERIAL = "UPDATE_SHOP_MATERIAL",
  UPDATE_LOCAL_SHOP_MATERIAL = "UPDATE_LOCAL_SHOP_MATERIAL",
  DELETE_SHOP_MATERIAL = "DELETE_SHOP_MATERIAL",
  GET_SHOP_COLORS = "GET_SHOP_COLORS",
  CREATE_SHOP_COLOR = "CREATE_SHOP_COLOR",
  UPDATE_SHOP_COLOR = "UPDATE_SHOP_COLOR",
  UPDATE_LOCAL_SHOP_COLOR = "UPDATE_LOCAL_SHOP_COLOR",
  DELETE_SHOP_COLOR = "DELETE_SHOP_COLOR",
  SHOP_MATERIAL_LOADING = "SHOP_MATERIAL_LOADING",
  SHOP_MATERIAL_ERROR = "SHOP_MATERIAL_ERROR",
  RESET_CREATE_SHOP_MATERIAL_SUCCESS = "RESET_CREATE_SHOP_MATERIAL_SUCCESS",
  CLEAR_SHOP_MATERIALS = "CLEAR_SHOP_MATERIALS",
}

interface IGetShopMaterials {
  type: ActionType.GET_SHOP_MATERIALS,
  materials: IShopMaterial[]
}

interface IGetShopMaterial {
  type: ActionType.GET_SHOP_MATERIAL,
  material: IShopMaterial
}

interface ICreateShopMaterialColor {
  type: ActionType.CREATE_SHOP_MATERIAL_COLOR,
  material: IShopMaterial,
  color: IShopColor
}

interface ICreateShopMaterial {
  type: ActionType.CREATE_SHOP_MATERIAL,
  material: IShopMaterial
}

interface IUpdateShopMaterial {
  type: ActionType.UPDATE_SHOP_MATERIAL
}

interface IUpdateLocalShopMaterial {
  type: ActionType.UPDATE_LOCAL_SHOP_MATERIAL,
  material: IShopMaterial
}

interface IDeleteShopMaterial {
  type: ActionType.DELETE_SHOP_MATERIAL,
  id: number
}

interface IGetShopColors {
  type: ActionType.GET_SHOP_COLORS,
  colors: IShopColor[]
}

interface ICreateShopColor {
  type: ActionType.CREATE_SHOP_COLOR,
  color: IShopColor
}

interface IUpdateShopColor {
  type: ActionType.UPDATE_SHOP_COLOR
}

interface IUpdateLocalShopColor {
  type: ActionType.UPDATE_LOCAL_SHOP_COLOR,
  color: IShopColor
}

interface IDeleteShopColor {
  type: ActionType.DELETE_SHOP_COLOR,
  id: number
}

interface IMaterialLoading {
  type: ActionType.SHOP_MATERIAL_LOADING
}

interface IMaterialError {
  type: ActionType.SHOP_MATERIAL_ERROR,
  error: string
}

interface IResetCreateSuccess {
  type: ActionType.RESET_CREATE_SHOP_MATERIAL_SUCCESS
}

interface IClearMaterial {
  type: ActionType.CLEAR_SHOP_MATERIALS
}

export type Action =
  IGetShopMaterials |
  IGetShopMaterial |
  ICreateShopMaterialColor |
  ICreateShopMaterial |
  IUpdateShopMaterial |
  IUpdateLocalShopMaterial |
  IDeleteShopMaterial |
  IGetShopColors |
  ICreateShopColor |
  IUpdateShopColor |
  IUpdateLocalShopColor |
  IDeleteShopColor |
  IClearMaterial |
  IMaterialLoading |
  IResetCreateSuccess |
  IMaterialError;
