/// MUI COMPONENTS ///
import { motion } from "framer-motion";
import Typography from "@mui/material/Typography";
/// ICONS ///
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
/// STYLES ///
import { sort_btn_style } from "styles/universal/button";
import { icon_style } from "styles/universal/button";
/// ANIM ///
import { sort_btn_motion } from "styles/universal/button";
import { press_motion } from "styles/universal/button";

interface Props {
  text: string,
  handleSort: () => void,
  is_current: boolean,
  is_up: boolean
}

export default function SortButton({ text, handleSort, is_current, is_up }: Props) {

  return (
    <motion.button
      whileTap={press_motion}
      style={sort_btn_style}
      initial="rest"
      whileHover="hover"
      animate={is_current ? "hover" : "rest"}
      onClick={handleSort}>
      <Typography variant="body2">
        <b>{text}</b>
      </Typography >
      <motion.div variants={sort_btn_motion}>
        {
          is_up ?
            <ArrowUpward sx={icon_style} /> :
            <ArrowDownward sx={icon_style} />
        }
      </motion.div>
    </motion.button>
  )
}
