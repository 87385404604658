/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/sketch";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/sketch";
/// TYPES ///
import { CutShape } from "api/types/sketch";

export const setSelectedCutShape = (cut_shape: CutShape | null) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_SELECTED_CUT_SHAPE,
            payload: cut_shape
        });
    }
}
