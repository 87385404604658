import { Vector2 } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { CutCorner } from "api/types/sketch";
import { getDistance } from "../math";
import { getDistantPoint } from "../math";
import { getAngle } from "../math";
import { SLAB_ZOOM } from "values/values";

export function moveCutShape(cut_shape: CutShape, current_cut_shape: CutShape, lift_point: Vector2, mouse: Vector2): CutShape {
    const updated_shape: CutShape = {...cut_shape};
    const updated_corners: CutCorner[] = [];
    const distance: number = getDistance(lift_point, mouse) / SLAB_ZOOM;
    const angle: number = getAngle(lift_point, mouse) + 90;

    for(let i = 0; i < cut_shape.cut_corners.length; i++){
        const relative_point: Vector2 = getDistantPoint(cut_shape.cut_corners[i].location, angle, distance);
        
        const new_location: Vector2 = {
            X: relative_point.X, 
            Y: relative_point.Y, 
        };

        if(
            cut_shape.cut_corners[i].location.X > 0 && 
            cut_shape.cut_corners[i].location.Y &&
            (relative_point.X < 0 || relative_point.Y < 0)
        ){
            return current_cut_shape;
        }

        updated_corners.push(
            {
                ...cut_shape.cut_corners[i],
                location: new_location
            }
        )
    }
    return {...updated_shape, cut_corners: updated_corners};
}