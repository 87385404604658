import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/history";
import { ActionType } from "api/action_types/history";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { url_prefix } from "settings";
import { bulk_counter_url } from "../endpoint";
import { bulk_cut_shape_url } from "../endpoint";

export const updateHistory = (quote_id: number, counters: Counter[], cut_shapes: CutShape[]) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        const { user } = getState().authentication;

        dispatch({
            type: ActionType.HISTORY_UPDATING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${bulk_counter_url}/?quote_id=${quote_id}`;
        const shape_url = `${url_prefix}${bulk_cut_shape_url}/`;

        try
        {
            await axios.post(url, counters, config);
            await axios.post(shape_url, cut_shapes, config);

            dispatch({
                type: ActionType.HISTORY_ACTION_SUCCESS
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.HISTORY_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}