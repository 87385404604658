import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { primary_color } from "styles/style";
import { light_grey_color } from "styles/style";

interface Props {
  discount: number,
  tax_name: string,
  tax: number,
  second_tax_name: string,
  second_tax: number,
  total: number
}

export default function PrintSummary({ discount, tax_name, tax, second_tax_name, second_tax, total }: Props) {
  const styles = StyleSheet.create({
    body2: {
      fontFamily: "Montserrat",
      fontSize: 10.8,
      fontWeight: "bold",
      whiteSpace: "no-wrap"
    },
    body2_right: {
      fontFamily: "Montserrat",
      fontSize: 10.8,
      fontWeight: "bold",
      textAlign: "right"
    },
    total: {
      fontFamily: "Montserrat",
      fontSize: 12,
      fontWeight: "bold",
      color: primary_color
    },
    total_right: {
      fontFamily: "Montserrat",
      fontSize: 12,
      fontWeight: "bold",
      textAlign: "right",
      color: primary_color
    },
    line_container: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "10px",
      paddingRight: "10px",
      borderBottom: `1px solid ${light_grey_color}`
    }
  });

  return (
    <View style={{ display: "flex", flexDirection: "column" }}>
      <View style={styles.line_container}>
        <View style={{ width: "50%" }}></View>
        <View style={{ width: "25%" }}>
          <Text style={styles.body2}>
            Subtotal
          </Text>
        </View>
        <View style={{ width: "25%" }}>
          <Text style={styles.body2_right}>
            ${total.toFixed(2)}
          </Text>
        </View>
      </View>
      {
        discount ?
          <View style={styles.line_container}>
            <View style={{ width: "50%" }}></View>
            <View style={{ width: "25%" }}>
              <Text style={styles.body2}>
                Discount %
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text style={styles.body2_right}>
                {discount.toFixed(2)}%
              </Text>
            </View>
          </View> :
          null
      }
      {
        discount ?
          <View style={styles.line_container}>
            <View style={{ width: "50%" }}></View>
            <View style={{ width: "25%" }}>
              <Text style={styles.body2}>
                Discount
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text style={styles.body2_right}>
                ${(discount / 100 * total).toFixed(2)}
              </Text>
            </View>
          </View> :
          null

      }
      <View style={styles.line_container}>
        <View style={{ width: "50%" }}></View>
        <View style={{ width: "25%" }}>
          <Text style={styles.body2}>
            {tax_name !== "" ? tax_name : "Tax"}
          </Text>
        </View>
        <View style={{ width: "25%" }}>
          <Text style={styles.body2_right}>
            ${(tax - (tax * (discount / 100))).toFixed(2)}
          </Text>
        </View>
      </View>
      {
        second_tax ?
          <View style={styles.line_container}>
            <View style={{ width: "50%" }}></View>
            <View style={{ width: "25%" }}>
              <Text style={styles.body2}>
                {second_tax_name !== "" ? second_tax_name : "Second Tax"}
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text style={styles.body2_right}>
                ${(second_tax - (second_tax * (discount / 100))).toFixed(2)}
              </Text>
            </View>
          </View> :
          null
      }
      <View style={styles.line_container}>
        <View style={{ width: "50%" }}></View>
        <View style={{ width: "25%" }}>
          <Text style={styles.body2}>
            Total
          </Text>
        </View>
        <View style={{ width: "25%" }}>
          <Text style={styles.body2_right}>
            ${(
              total +
              tax +
              second_tax -
              Math.round(total * discount) / 100 -
              Math.round(tax * discount) / 100 -
              Math.round(second_tax * discount) / 100).toFixed(2)
            }
          </Text>
        </View>
      </View>
    </View>
  );
}
