/// REDUX ///
import { Dispatch } from "redux";
/// ACTIONS ///
import { Action } from "api/action_types/shop_quote";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/shop_quote";
/// TYPES ///
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopCutCorner } from "api/types/shop_quote/cut_corner";
import { Vector2 } from "api/types/sketch";
/// FUNCTIONS ///
import { getDistance } from "functions/sketch/math";
import { getAngle } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";

export const repositionLocalShopCutShape = (
  cut_shape: IShopCutShape,
  lift_point: Vector2,
  mouse: Vector2
) => {
  return async (dispatch: Dispatch<Action | AuthAction>) => {
    const updated_shape: IShopCutShape = { ...cut_shape };
    const updated_corners: IShopCutCorner[] = [];
    const distance: number = Math.round(getDistance(lift_point, mouse));
    const angle: number = getAngle(lift_point, mouse) + 90;

    for (let i = 0; i < cut_shape.cut_corners.length; i++) {
      const relative_point: Vector2 = getDistantPoint(cut_shape.cut_corners[i].location, angle, distance);

      const new_location: Vector2 = {
        X: Math.round(relative_point.X),
        Y: Math.round(relative_point.Y),
      };

      if (
        cut_shape.cut_corners[i].location.X > 0 &&
        cut_shape.cut_corners[i].location.Y &&
        (relative_point.X < 0 || relative_point.Y < 0)
      ) {
        return;
      }

      updated_corners.push(
        {
          ...cut_shape.cut_corners[i],
          location: new_location
        }
      )
    }

    dispatch({
      type: ActionType.UPDATE_LOCAL_SHOP_CUT_SHAPE,
      payload: { ...updated_shape, cut_corners: updated_corners }
    });
  }
}
