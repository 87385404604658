import { IShopColorOption } from "api/types/shop_quote/color_option";

export enum ActionType {
  CREATE_LOCAL_SHOP_COLOR_OPTION = "CREATE_LOCAL_SHOP_COLOR_OPTION",
  UPDATE_LOCAL_SHOP_COLOR_OPTION = "UPDATE_LOCAL_SHOP_COLOR_OPTION",
  DELETE_LOCAL_SHOP_COLOR_OPTION = "DELETE_LOCAL_SHOP_COLOR_OPTION"
}

interface ICreateLocalShopColorOption {
  type: ActionType.CREATE_LOCAL_SHOP_COLOR_OPTION,
  payload: IShopColorOption
}

interface IUpdateLocalShopColorOption {
  type: ActionType.UPDATE_LOCAL_SHOP_COLOR_OPTION,
  payload: IShopColorOption
}

interface IDeleteLocalShopColorOption {
  type: ActionType.DELETE_LOCAL_SHOP_COLOR_OPTION,
  payload: IShopColorOption
}

export type Action = ICreateLocalShopColorOption | IUpdateLocalShopColorOption | IDeleteLocalShopColorOption;
