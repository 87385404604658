/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// ACTIONS ///
import { updateFabricatorMarketMaterial } from "api/actions/material";
/// TYPES ///
import { FabricatorMaterial } from "api/types/fabricator"
/// MUI COMPONENTS ///
import { Slider } from "@mui/material";
import { Stack } from "@mui/material";
/// COMPONENTS ///
import DashboardTile from "components/universal/tiles/dashboard";
/// LODASH ///
import { debounce } from "lodash";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";
import { slider_marks } from "values/price";

interface Props {
  index: number,
  mat: FabricatorMaterial,
  updateLoading: (is_loading: boolean, id: number) => void
}

export default function MarketMaterialRow({ index, mat, updateLoading }: Props) {
  const dispatch = useAppDispatch();
  const [weight, setWeight] = useState<number>(mat?.weight ?? 0);
  const [id, setId] = useState<number>(mat?.id ?? 0);

  useEffect(() => {
    setWeight(mat.weight);
    setId(mat?.id ?? 0);
  }, [mat?.weight, mat?.id]);

  const update = useCallback(debounce(function(weight: number, id: number) {
    dispatch(updateFabricatorMarketMaterial({ id: id, weight: weight }, id));
    updateLoading(false, id);
  }, 1000), []);

  const changePrice = (weight: number) => {
    setWeight(weight);
    update(weight, id);
    updateLoading(true, id);
  }

  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      <DashboardTile
        key={mat.id}
        title={`${mat?.name} ${getMaterialHeightDisplay(mat?.thickness)} `}
        index={index}
        url={`${mat.id} `} />
      <Slider
        marks={slider_marks}
        valueLabelDisplay="auto"
        size="small"
        value={weight}
        onChange={(e, v) => changePrice(v as number)}
        min={-30}
        max={30} />
    </Stack>

  )
}
