/// MUI ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";

interface Props {
  name: string
}

export default function QuoteSummaryHeader({ name }: Props) {
  return (
    <Grid item xs={12}>
      <Typography variant="body2" sx={item_style()}>
        <b>{name} Items</b>
      </Typography>
    </Grid>
  )
}
