/// HOOKS ///
import { useAppSelector } from "api/hooks/apiHook";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// CUSTOM COMPONENTS ///
import DashboardTile from "components/universal/tiles/dashboard";
/// URLS ///
import { shops_url } from "components/navigation/endpoints";
import { org_url } from "components/navigation/endpoints";
import { billing_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";
import { payment_setup_url } from "components/navigation/endpoints";

export default function DashboardErrors() {
  const { user } = useAppSelector((state) => state.authentication);

  return (
    <Fragment>
      <DashboardTile
        title="Geo Error"
        index={0}
        url={shops_url}
        show={!user.geo_found} />
      <DashboardTile
        title="Past Due"
        index={1}
        url={`/${org_url}/${billing_url}/${update_url}`}
        show={user.past_due} />
      <DashboardTile
        title="Unpaid"
        index={2}
        url={`/${org_url}/${billing_url}/${update_url}`}
        show={user.unpaid} />
      <DashboardTile
        title="Canceled"
        index={3}
        url={`/${org_url}/${billing_url}/${update_url}`}
        show={user.canceled} />
      <DashboardTile
        title="Setup Subscription"
        index={4}
        url={payment_setup_url}
        show={!user.payment_initialized} />
    </Fragment>
  )
}
