import { Counter } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { updateLocalCutShapes } from "api/actions/sketch/cut_shape";
import { updateCounter } from "api/actions/sketch/counter";
import { createBulkCutShapes } from "api/actions/sketch/cut_shape";
import { createRestorePoint } from "api/actions/history";
import { markQuoteChanged } from "api/actions/quotes/quote";
import { createCutShapes } from "functions/sketch";

export function handleUpdateCounter(
    dispatch: (({...any}) => void),
    counter: Counter,
    counters: Counter[],
    cut_shapes: CutShape[],
    slab_uuid: string,
    update_history_message: string
){
    dispatch(updateCounter(counter));
    const new_cut_shapes: CutShape[] = createCutShapes(
        counter,
        counter.area_uuid,
        slab_uuid,
        counter.points,
        counter.corners,
        []
    );
    dispatch(updateLocalCutShapes(new_cut_shapes, counter.uuid));
    dispatch(createBulkCutShapes(new_cut_shapes));
    dispatch(createRestorePoint(
        [...counters.filter(c => c.uuid !== counter.uuid), counter],
        [...cut_shapes.filter(c => c.counter_uuid !== counter.uuid), ...new_cut_shapes],
        update_history_message
    ));
    dispatch(markQuoteChanged());
}