import { CornerBumpOut } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { getAngle } from "functions/sketch/math";
import { constructBumpoutCorner } from "../../corner/construct";

export function drawSecondHalfPrintCutBumpoutCorner(
    painter: any,
    i: number,
    previous_location: Vector2,
    current_location: Vector2,
    next_location: Vector2,
    corner_length: number,
    corner_depth: number,
    zoom: number
){
    const cut_angle: number = Math.round((getAngle(previous_location, current_location) + 90) * 100) / 100;

    const corner_bumpout: CornerBumpOut = constructBumpoutCorner(
        previous_location,
        current_location,
        next_location,
        cut_angle,
        corner_length * zoom,
        corner_depth * zoom
    );

    if(i === 0){
        painter.moveTo(corner_bumpout.middle.X, corner_bumpout.middle.Y);
    }
    else{
        painter.lineTo(corner_bumpout.middle.X, corner_bumpout.middle.Y);
    }
    painter.lineTo(corner_bumpout.C.X, corner_bumpout.C.Y);
    painter.lineTo(corner_bumpout.D.X, corner_bumpout.D.Y);
    
    return {
        first_point: corner_bumpout.middle,
        last_point: corner_bumpout.D
    }
}