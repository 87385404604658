import { drawPrintPreviewCutShapes } from "functions/sketch";
import { getOptimalSlabZoom } from "functions/sketch";
import { getSlabStartPoint } from "functions/sketch";
import { Vector2 } from "api/types/sketch";
import { Area } from "api/types/quote";
import { Slab } from "api/types/quote";
import { CutShape } from "api/types/sketch";
import { View } from "@react-pdf/renderer";
import { Canvas } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { light_grey_color } from "styles/style";
import { primary_color } from "styles/style";
import { SLAB_ZOOM } from "values/values";

const base_offset: Vector2 = { X: 0, Y: 0 };

interface Props {
  index: number,
  area: Area,
  slab: Slab,
  shapes: CutShape[]
}

const styles = StyleSheet.create({
  slab_area: {
    width: "225px",
    display: "flex",
    justifyContent: "center",
  }
})

export default function QuotePrintSlab({ index, area, slab, shapes }: Props) {
  const paintCanvas = (painter: any, availableWidth: number, availableHeight: number) => {
    const width: number = area?.material_width * 4 ?? 0;
    const height: number = area?.material_length * 4 ?? 0;
    let zoom: number = getOptimalSlabZoom(
      width,
      height,
      { X: availableWidth, Y: availableHeight }
    );
    const slab_start: Vector2 = getSlabStartPoint(height / 4, index);
    slab_start.X = slab_start.X / SLAB_ZOOM;
    slab_start.Y = slab_start.Y / SLAB_ZOOM;

    if (width && height) {
      painter.moveTo(base_offset.X, base_offset.Y);
      painter.lineTo(base_offset.X + width * zoom, base_offset.Y);
      painter.lineTo(base_offset.X + width * zoom, base_offset.Y + height * zoom);
      painter.lineTo(base_offset.X, base_offset.Y + height * zoom);
      painter.lineTo(base_offset.X, base_offset.Y);
      painter.fill(light_grey_color);
      drawPrintPreviewCutShapes(painter, shapes, slab, zoom, base_offset, slab_start);
      painter.fill(primary_color);
    }
    else {
      zoom = 1;
      painter.text("Must set slab material to get slab diagram", 10, -10);
    }
    return null;
  }

  return (
    <View style={styles.slab_area} wrap={false}>
      <Canvas style={{ height: "225px" }} paint={paintCanvas} />
    </View>
  );
}
