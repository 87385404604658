import { OrderCounter } from "api/types/order";

export enum ActionType {
  GET_WO_COUNTERS_SUCCESS = "GET_WO_COUNTERS_SUCCESS"
}

interface IGetWOCountersSuccess {
  type: ActionType.GET_WO_COUNTERS_SUCCESS,
  payload: OrderCounter[]
}

export type Action = IGetWOCountersSuccess;
