import type { Action as EndCustomerAction } from "./end_customer";
import type { Action as FabricationJobAction } from "./fabricator_job";

import { ActionType as EndCustomerActionType } from "./end_customer";
import { ActionType as FabricationJobActionType } from "./fabricator_job";

export const ActionType = {
  ...EndCustomerActionType,
  ...FabricationJobActionType
}

export type Action =
  EndCustomerAction |
  FabricationJobAction;

