export const side_cont_style = {
  width: {
    "lg": "300px",
    "md": "300px",
    "xs": "300px"
  },
  paddingTop: "9px",
  paddingLeft: {
    "lg": "10px",
    "xs": "10px"
  },
  paddingRight: {
    "lg": "25px",
    "xs": "10px"
  },
  display: "flex",
  flexDirection: "column"
}
