/// MUI ///
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// FM ///
import { motion } from "framer-motion";
import { useMotionValue } from "framer-motion";
import { useTransform } from "framer-motion";
/// DATE TIME ///
import { DateTime } from "luxon";
/// TYPES ///
import { IOrderEvent } from "api/types/event";
import { ISelfOrderEvent } from "api/types/event";
/// STYLES ///
import { item_style } from "styles/quote_preview/section";
/// VALUES ///
import { getEventType } from "values/event";
/// STYLES ///
import { progress_ind_style } from "styles/calendar/progress";

interface Props {
  event: IOrderEvent | ISelfOrderEvent,
  next_event?: IOrderEvent | ISelfOrderEvent,
  selected_event_id: number | string,
  setEvent: () => void,
  has_line?: boolean,
  index: number
}

export default function ProgressColumn({ event, next_event, selected_event_id, setEvent, index, has_line = true }: Props) {
  return (
    <div style={{ width: "100%", position: "relative", paddingTop: "20px" }}>
      <motion.button
        style={{
          width: "100px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "rgba(255, 255, 255, 0.0)",
          alignItems: "center",
          overflow: "visible",
          border: "none"
        }}
        onClick={setEvent}>
        <motion.div style={{ width: "100px" }}>
          <Typography variant="body2" align="center">
            {DateTime.fromISO(event?.time ?? "").toLocal().toFormat("LLL dd")}
          </Typography>
        </motion.div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", overflow: "visible" }}>
          <div style={{ ...progress_ind_style, backgroundColor: event?.color ?? "blue", padding: "1px", zIndex: 5000 }}>
            {
              event?.completed ?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  strokeWidth={2}
                  stroke="#fff"
                  fill="none"
                  viewBox="0 0 24 24">
                  <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1, transition: { duration: 0.3 } }}
                    d="M4.5 12.75l6 6 9-13.5" />
                </svg> :
                null
            }
          </div>
        </div>
        <motion.div >
          <Typography variant="body2" align="left" sx={{ marginTop: "35px" }}>
            <b>{getEventType(event?.event_type)}</b>
          </Typography>

        </motion.div>
        {
          selected_event_id === event?.id ?
            <motion.div
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              style={{ width: "100%", height: "5px", backgroundColor: event?.color ?? "blue", marginTop: "5px" }}>
            </motion.div> :
            <div style={{ width: "100%", height: "5px", backgroundColor: "#fff", marginTop: "5px" }}></div>
        }
      </motion.button>
      {
        event?.completed && has_line ?
          <motion.div
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1, transition: { duration: 0.3, delay: 0.3 } }}
            style={{ originX: 0, position: "absolute", top: 77, left: 50, right: -50, height: "15px", backgroundImage: `linear-gradient(to right, ${event?.color}, ${next_event?.color} )` }}>
          </motion.div> :
          null
      }
    </div >
  )
}

// has to be a grid top, then middle, then bottom, separate loops
//

