/// FM ///
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
/// REACT ///
import { ReactNode } from "react";
/// COMPONENTS ///
import { Typography } from "@mui/material";
/// IMAGES ///
import LoadingAnim from "static/loading.gif";
/// STYLES ///
import { loading_page_style } from "styles/loading";

interface Props {
  children?: ReactNode,
  loading: boolean
}

export default function LoadingPage({ children, loading }: Props) {
  //TODO have to change logic because doesn't render at all to have the the wait occur
  // also argument change causes the component to re render
  if (loading) {
    return (
      <motion.div style={loading_page_style} initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 4 } }}>
        <img src={LoadingAnim} width="100" height="100" alt="Loading..." />
        <Typography variant="h4" color="primary">
          LOADING
        </Typography>
      </motion.div>
    )
  }

  return (
    <motion.div>{children}</motion.div>
  )

}
